import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  useDisclosure,
  Placement,
} from '@chakra-ui/react';
import SvgShareV1 from 'src/components/icons/share-v1';
import { ShareButtons } from 'src/components/share-buttons';

export const SharePopover = ({
  hashtags = [],
  url,
  shareButton = <SvgShareV1 fill="none" fontSize={20} />,
  heading = 'Share this folio',
  popupPosition = 'right',
  buttonClassname = '',
}: {
  hashtags?: Array<string>;
  url?: string;
  shareButton?: any;
  heading?: string;
  popupPosition?: Placement;
  buttonClassname?: string;
}) => {
  const shareButtonsModal = useDisclosure();
  return (
    <Popover
      placement={popupPosition}
      gutter={14}
      autoFocus={false}
      onOpen={shareButtonsModal.onOpen}
      onClose={shareButtonsModal.onClose}
      isOpen={shareButtonsModal.isOpen}
    >
      <PopoverTrigger>
        <div className={`h-max ${buttonClassname}`}>{shareButton}</div>
      </PopoverTrigger>
      <PopoverContent bg="white">
        <PopoverArrow
          style={{
            width: '20px',
            height: '10px',
            boxShadow: '0px 1px 0px #E6E8E9, 0px 3px 6px rgba(0, 0, 0, 0.08)',
          }}
        />
        <PopoverBody
          bg="white"
          padding="16px"
          borderRadius="8px"
          overflow="hidden"
          opacity="1"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex flex-col justify-center items-center bg-white">
            <h1 className="text-center font-semibold text-sm font-medium mb-2 text-gray-900">
              {heading}
            </h1>
            {shareButtonsModal.isOpen && (
              <ShareButtons
                facebook
                twitter
                copyLink
                hashtags={hashtags}
                url={url}
              />
            )}
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
