import * as React from 'react';
import { Svg } from '../svg';

function SvgShare({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.65 16.419a2.631 2.631 0 011.771-.696 2.642 2.642 0 012.639 2.638A2.642 2.642 0 0117.42 21a2.642 2.642 0 01-2.638-2.639c0-.198.027-.397.072-.587l-6.434-3.759a2.7 2.7 0 01-1.843.732c-1.5 0-2.71-1.21-2.71-2.71s1.21-2.712 2.71-2.712a2.7 2.7 0 011.843.732l6.37-3.714a2.957 2.957 0 01-.08-.632C14.71 4.21 15.92 3 17.42 3s2.711 1.21 2.711 2.71-1.21 2.712-2.71 2.712a2.7 2.7 0 01-1.844-.732l-6.37 3.714c.045.207.08.415.08.632 0 .217-.035.425-.08.633l6.442 3.75zM18.325 5.71a.906.906 0 00-.904-.904.906.906 0 00-.903.904c0 .497.406.903.903.903a.906.906 0 00.904-.903zM6.578 12.94a.906.906 0 01-.904-.904c0-.497.407-.903.904-.903.497 0 .904.406.904.903a.906.906 0 01-.904.904zm9.94 5.44c0 .497.406.903.903.903a.906.906 0 00.904-.904.906.906 0 00-.904-.903.906.906 0 00-.903.904z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgShare);
export default ForwardRef;
