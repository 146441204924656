import { FILTER_NAME } from '../filter/constants';
import { FILTERS } from '../filter/filterConfiguration';
import { GENRES } from '../genreConfiguration';
import { FILTER_TYPES } from '../constants';
import { ResultType, GenreType } from '../types';

const getGenresFilter = (genres) => {
  return genres.map((genre) => {
    const { filter } = GENRES[genre];
    return Object.values(FILTERS).filter(({ name }) => filter.includes(name));
  });
};

function updateFilterResult(
  state,
  { result, genre }: { result: ResultType; genre: GenreType }
) {
  const filterResult = Object.entries(result).reduce(
    (acc, [genreKey, genreResult]) => {
      return {
        ...acc,
        [genreKey]: [...genreResult],
      };
    },
    {}
  );

  /**
   * filters of genre
   * selected values in filters
   * values of filters of data
   * two array has intersection (*skills need transfer value to id)
   */
  const genres = genre === 'ALL' ? Object.keys(GENRES) : [genre];
  const genresFilters = getGenresFilter(genres);

  genresFilters.forEach((genreFilters) => {
    filterResult[FILTER_TYPES[genre]] = result[FILTER_TYPES[genre]].filter(
      (data) => {
        return genreFilters.some(({ name, filterList }) => {
          if (name === FILTER_NAME.SKILLS) {
            return (
              data[name] &&
              Object.values(data[name]).some((skillName) => {
                return filterList.some((group) => {
                  const { id: skillId } =
                    group.items.find(
                      (groupFilter) => groupFilter.value === skillName
                    ) || {};
                  return state.skills[group.id]?.includes(skillId);
                });
              })
            );
          }
          return data[name]?.some((filterValue) => {
            const { id } =
              filterList.find(({ value }) => value === filterValue) || {};
            return state[name].includes(id);
          });
        });
      }
    );
  });

  const hasFilter =
    genresFilters.reduce(
      (accFilterLength, genreFilters) =>
        accFilterLength +
        genreFilters.reduce((acc, { name }) => {
          let length = state[name]?.length || 0;
          if (name === FILTER_NAME.SKILLS) {
            length = Object.keys(state[name]).length;
          }
          return acc + length;
        }, 0),
      0
    ) > 0;

  const filterResultTotalCount = Object.values(filterResult).reduce(
    (acc: number, genreResult: any[]) => acc + genreResult.length,
    0
  );

  return {
    ...state,

    hasFilter,
    result: filterResult,
    totalResult: filterResultTotalCount,
  };
}

export default updateFilterResult;
