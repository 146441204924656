import { INITIAL_RESULT_STATE } from '../constants';

function clearSearch(state, isSearchLite) {
  const initResult = Object.keys(INITIAL_RESULT_STATE).reduce(
    (acc, key) => ({ ...acc, [key]: [] }),
    {}
  );

  const result = isSearchLite
    ? { resultLite: [] }
    : {
        result: {
          ...initResult,
        },
        totalResult: 0,
      };
  return {
    ...state,
    isFetching: false,
    isError: false,

    searchTerm: '',
    ...result,
  };
}

export default clearSearch;
