const baseStyle = {
  fontFamily: 'heading',
  lineHeight: 'shorter',
  fontWeight: 'bold',
};

const sizes = {
  '3xl': {
    fontSize: ['3xl'],
  },
  '2xl': {
    fontSize: ['2xl'],
  },
  xl: {
    fontSize: ['xl'],
  },
  lg: {
    fontSize: ['lg'],
  },
  md: {
    fontSize: ['md'],
  },
  sm: {
    fontSize: ['sm'],
  },
};

const defaultProps = {
  size: '2xl',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
