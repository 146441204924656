import * as React from 'react';
import { Svg } from '../svg';

function SvgFilter({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M15.5.333V2h-.833L10.5 8.25v7.083h-5V8.25L1.333 2H.5V.333h15zM3.337 2l3.83 5.745v5.921h1.666V7.745L12.663 2H3.337z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgFilter);
export default ForwardRef;
