import * as React from 'react';
import { Svg } from '../svg';

function SvgCcSa({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#cc-sa_svg__clip0_7994_98843)">
        <path
          d="M20 4.083c9.2 0 16.667 7.467 16.667 16.667 0 9.2-7.467 16.667-16.667 16.667-9.2 0-16.666-7.467-16.666-16.667C3.334 11.55 10.8 4.083 20 4.083zm0 3.334c-7.358 0-13.333 5.975-13.333 13.333 0 7.358 5.975 13.333 13.333 13.333 7.358 0 13.334-5.975 13.334-13.333 0-7.358-5.976-13.333-13.334-13.333zm0 3.333c4.602 0 8.334 4.477 8.334 10s-3.732 10-8.334 10c-3.628 0-6.715-2.783-7.858-6.667l3.528.002c.864 1.992 2.48 3.332 4.33 3.332 2.762 0 5-2.984 5-6.667s-2.238-6.667-5-6.667c-1.85 0-3.466 1.342-4.332 3.334h2.666l-4.167 5-4.167-5h2.14c1.145-3.884 4.232-6.667 7.86-6.667z"
          fill="#4F5356"
        />
      </g>
      <defs>
        <clipPath id="cc-sa_svg__clip0_7994_98843">
          <path fill="#fff" transform="translate(0 .75)" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCcSa);
export default ForwardRef;
