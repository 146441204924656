import { axios } from 'src/lib/axios';
import type { RestPageResponse, RestResponse } from 'src/types';

// ref: https://www.notion.so/1tmio/API-doc-e45b8b2b2cf54533bb59fb1c3b140e0d?p=00b2d90305804fc4ae672a766356894b
export type LabelType = 'COLLECTION' | 'TAG' | 'CATEGORY';
export type AreaType = 'STUDIO' | 'REPPL';

export enum AreaLabelText {
  'STUDIO' = 'creations',
  'REPPL' = 'episodes',
}

export type Label = {
  id: number;
  ownerID: number;
  areaID: string;
  areaType: AreaType;
  name: string;
  coverImgURL: string;
  visible: boolean;
  feature?: boolean;
  slug: string;
  numOfPost: number;
  description: string;
  contentID: string;
  isOwner?: boolean;
  uuid?: string;
  /** on from `label` studio/ episode inspiration list */
  alternateImages?: string[];
  createdAt?: Date;
  updatedAt?: Date;
  ownerImgUrl?: string;
  ownerUsername?: string;
};

export interface LabelListRequestQuery {
  pageNum?: number;
  pageSize?: number;
  keyword?: string;
  username?: string;
  sortType?: 'RECENCY' | 'TIME_ORDER' | 'FAVOR' | 'UNFAVOR';
}
export interface LabelListRequest {
  areaType: AreaType;
  areaID: string;
  labelType: LabelType;
}

export function fetchLabelList(
  { areaID, areaType, labelType }: LabelListRequest,
  params: LabelListRequestQuery
) {
  if (!areaID || !areaType || !labelType) return;

  return axios
    .get<RestPageResponse<Label[]>>(
      `label/${areaType}/${areaID}/${labelType}`,
      {
        params: {
          ...params,
          keyword: params.keyword && encodeURIComponent(params.keyword),
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      return {
        ...res.data,
        pageNum: params.pageNum,
      };
    });
}

export interface CreateLabelRequest {
  areaID: string;
  areaType: AreaType;
  name: string;
  visible: boolean;
  description?: string;
  coverImgURL?: string;
}

export function createLabel(data: CreateLabelRequest) {
  return axios.post<RestResponse<any>>('/label', data).then((res) => res.data);
}

export interface UpdateLabelRequest {
  id: number;
  name: string;
  description: string;
  visible?: boolean;
  coverImgURL?: string;
}

export function updateLabel(data: UpdateLabelRequest) {
  return axios.put<RestResponse>('/label', data).then((res) => res.data);
}

export function deleteLabel(id: { id: number }) {
  return axios
    .delete<RestResponse>('/label', { data: id })
    .then((res) => res.data);
}

export function getLabelDetail<T>({ id: labelId }: { id: string }) {
  return axios
    .get<RestResponse<T>>(`/label-detail/${labelId}`)
    .then((res) => res.data)
    .then((res) => res.data);
}

interface AddCurationRequest {
  labelID: number;
  contentID: string[];
  contentType: 'STUDIO' | 'EPISODE';
}
interface DeleteCurationRequest {
  labelID: number;
  contentID: string;
  contentType: 'EPISODE';
}

export const addCuration = (req: AddCurationRequest) =>
  axios.post<RestResponse>('/curate', req).then((res) => res.data);

export const deleteCuration = (data: DeleteCurationRequest) => {
  return axios
    .delete<RestResponse>('/curate', { data })
    .then((res) => res.data);
};

export function getLabelDetailList<T>({ id }: { id: string }, params) {
  return axios
    .get<RestPageResponse<T[]>>(`/collection/${id}`, { params })
    .then((res) => res.data)
    .then((res) => {
      return {
        ...res.data,
        pageNum: params.pageNum,
      };
    });
}

// ref: https://github.com/1tmio/Gilgamesh/blob/refactor/post/constant/PlatformConst.go
export interface CreationLabelRequest {
  areaID: string;
  areaType: 'REPPL' | 'STUDIO';
  contentID: string;
  contentType: 'EPISODE';
}

export interface CreationLabelParams {
  pageNum?: number;
  pageSize?: number;
  keyword?: string;
}

export function getCreationLabel(
  req: CreationLabelRequest,
  params?: CreationLabelParams
) {
  return axios
    .get<RestPageResponse<Label[], { addedLabel: Label[] }>>(
      `/creation-label/${req.areaType}/${req.areaID}/${req.contentType}/${req.contentID}`,
      { params }
    )
    .then((res) => res.data)
    .then((res) => {
      return { ...res.data, pageNum: params.pageNum };
    });
}

export interface CuratableListParams {
  pageNum?: number;
  pageSize?: number;
  keyword?: string;
}

export interface CuratableListResponse {
  contentID: string;
  title: string;
  description: string;
  coverImgURL: string;
}

export function getCuratableList(
  { id }: { id: number },
  params: CuratableListParams
) {
  return axios
    .get<RestPageResponse<CuratableListResponse[]>>(
      `/curatable/creation/${id}`,
      { params }
    )
    .then((res) => res.data)
    .then((res) => {
      return {
        ...res.data,
        pageNum: params.pageNum,
      };
    });
}

// ref: https://github.com/1tmio/Gilgamesh/blob/8b2a5e9ed975a2809d5febb45bb7023ba5d717d6/services/actionservice/LabelService.go#L165-L184
interface ChangeLabelOrderRequest {
  IDs: number[];
  labelType: 'LABEL' | 'COLLECTION';
}

export function changeLabelOrder(data: ChangeLabelOrderRequest) {
  return axios
    .post<RestResponse>('/label-order', data)
    .then((res) => res.data)
    .then((res) => res.data);
}

interface FeatureCollectionRequest {
  rid: string;
  labelID: number;
}
export const featureCollection = ({
  rid,
  labelID,
}: FeatureCollectionRequest) => {
  return axios
    .post<RestPageResponse>(`/space/${rid}/feature`, { labelID })
    .then((res) => res.data);
};
