import * as React from 'react';
import { Svg } from '../svg';

function SvgCalendly({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16.61 15.553c-.757.673-1.704 1.51-3.42 1.51h-1.027c-1.243 0-2.373-.451-3.182-1.27-.79-.8-1.225-1.894-1.225-3.082v-1.405c0-1.188.435-2.283 1.225-3.082.81-.819 1.94-1.27 3.181-1.27h1.027c1.719 0 2.664.837 3.422 1.51.787.693 1.466 1.3 3.277 1.3.276 0 .552-.023.825-.066l-.006-.016a6.222 6.222 0 00-.381-.783l-1.21-2.097a6.22 6.22 0 00-5.386-3.109H11.31a6.218 6.218 0 00-5.386 3.11l-1.21 2.096a6.22 6.22 0 000 6.219l1.21 2.097a6.22 6.22 0 005.386 3.108h2.421a6.218 6.218 0 005.386-3.108l1.21-2.094c.145-.252.272-.513.38-.782l.007-.016a5.121 5.121 0 00-.825-.069c-1.81 0-2.49.603-3.277 1.3"
        fill="#006BFF"
      />
      <path
        d="M13.19 8.228h-1.027c-1.89 0-3.133 1.35-3.133 3.079v1.404c0 1.729 1.242 3.08 3.133 3.08h1.027c2.755 0 2.538-2.81 6.699-2.81.394 0 .787.036 1.175.108a6.213 6.213 0 000-2.16c-.388.072-.781.108-1.175.108-4.162 0-3.944-2.81-6.7-2.81z"
        fill="#006BFF"
      />
      <path
        d="M23.454 14.116a5.8 5.8 0 00-2.39-1.027v.02c-.074.415-.19.82-.346 1.21.713.111 1.39.39 1.975.814 0 .006-.003.013-.005.02a10.652 10.652 0 110-6.287c0 .006.003.013.005.019a4.54 4.54 0 01-1.975.812c.156.392.272.798.346 1.212v.02a5.8 5.8 0 002.39-1.027c.681-.504.55-1.074.446-1.41a11.94 11.94 0 100 7.035c.104-.337.235-.907-.446-1.411z"
        fill="#006BFF"
      />
      <path
        d="M20.713 9.697a5.133 5.133 0 01-.824.069c-1.811 0-2.49-.603-3.277-1.3-.759-.673-1.704-1.51-3.423-1.51h-1.026c-1.243 0-2.372.452-3.181 1.27-.79.8-1.225 1.894-1.225 3.083v1.404c0 1.189.435 2.283 1.225 3.083.809.818 1.938 1.27 3.18 1.27h1.027c1.72 0 2.664-.837 3.423-1.51.786-.694 1.466-1.3 3.277-1.3.276 0 .552.022.824.066a6.18 6.18 0 00.347-1.21.18.18 0 000-.021 6.424 6.424 0 00-1.175-.108c-4.162 0-3.944 2.81-6.699 2.81h-1.027c-1.89 0-3.133-1.351-3.133-3.08v-1.407c0-1.729 1.242-3.08 3.133-3.08h1.027c2.755 0 2.539 2.81 6.7 2.81.393 0 .787-.036 1.174-.108v-.02a6.272 6.272 0 00-.347-1.211z"
        fill="#0AE8F0"
      />
      <path
        d="M20.713 9.697a5.133 5.133 0 01-.824.069c-1.811 0-2.49-.603-3.277-1.3-.759-.673-1.704-1.51-3.423-1.51h-1.026c-1.243 0-2.372.452-3.181 1.27-.79.8-1.225 1.894-1.225 3.083v1.404c0 1.189.435 2.283 1.225 3.083.809.818 1.938 1.27 3.18 1.27h1.027c1.72 0 2.664-.837 3.423-1.51.786-.694 1.466-1.3 3.277-1.3.276 0 .552.022.824.066a6.18 6.18 0 00.347-1.21.18.18 0 000-.021 6.424 6.424 0 00-1.175-.108c-4.162 0-3.944 2.81-6.699 2.81h-1.027c-1.89 0-3.133-1.351-3.133-3.08v-1.407c0-1.729 1.242-3.08 3.133-3.08h1.027c2.755 0 2.539 2.81 6.7 2.81.393 0 .787-.036 1.174-.108v-.02a6.272 6.272 0 00-.347-1.211z"
        fill="#0AE8F0"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCalendly);
export default ForwardRef;
