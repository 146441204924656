/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { css } from '@hexx/theme';
import { useModal } from 'src/hooks/use-modal';
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  UseDisclosureReturn,
  Box,
  Image,
  Flex,
  Text,
} from '@chakra-ui/react';
import SvgMagnifying from 'src/components/icons/magnifying';
import Carousel from 'nuka-carousel';
import SvgArrowLeft from 'src/components/icons/arrow-left';
import SvgArrowRight from 'src/components/icons/arrow-right';
import { imageOptimize } from 'src/service/image';
import ShowMoreText from 'react-show-more-text';
import { useIsMobile } from 'src/hooks/use-is-mobile';

export type Gallery = {
  type: 'gallery';
  data: {
    caption?: string;
    files: Asset[];
    stretched: boolean;
  };
};

export type Asset = {
  height?: number;
  url: string;
  width?: number;
  displayWidth?: number;
};

export function calcImageWidthPerRow(
  imgs: Asset[],
  totalWidth: number,
  gap = 0
) {
  const totalAspectRatioPerRow = getTotalAspectRatio(imgs);

  const eachGrid =
    (totalWidth - gap * (imgs.length - 1)) / totalAspectRatioPerRow;

  for (const img of imgs) {
    if ('width' in img && 'height' in img) {
      img.displayWidth = (img.width! / img.height!) * eachGrid;
    }
  }

  return imgs;
}

function getTotalAspectRatio(imgs: Asset[]) {
  let totalAspectRatio = 0;

  for (const img of imgs) {
    totalAspectRatio += img.width! / img.height!;
  }

  return totalAspectRatio;
}

export function GalleryRenderer({
  data,
  renderImage,
}: {
  data: any;
  renderImage?: (asset: Asset, index: number) => ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const imageRows = data.files?.filter((f) => !!f.url) ?? [];
  const [width, setWidth] = useState(720);

  useEffect(() => {
    const wrapper = ref.current;
    // @ts-ignore
    const resizeObserver = new ResizeObserver((entries) => {
      requestAnimationFrame(() => {
        for (const entry of entries) {
          if (entry.contentRect) {
            setWidth(entry.contentRect.width);
          }
        }
      });
    });
    resizeObserver.observe(wrapper);

    return () => {
      if (resizeObserver && 'unobserve' in resizeObserver && wrapper) {
        resizeObserver.unobserve(wrapper);
      }
    };
  }, []);

  return (
    <>
      <div
        className={`${css({
          display: 'grid',
          gridAutoFlow: 'column',
          marginBottom: 8,
          gap: '8px',
        })}${data.stretched ? ' max-w-none' : ' max-w-screen-sm m-auto'}${
          data.withBorder ? ' border' : ''
        }`}
        ref={ref}
      >
        <ImageRow
          renderImage={renderImage}
          containerWidth={width}
          data={imageRows}
          caption={data.caption}
        />
      </div>
      <div
        className={
          'block text-center text-gray-400 text-sm' +
          `${data.stretched ? ' max-w-none' : ' max-w-screen-sm m-auto'}`
        }
      >
        {data.caption}
      </div>
    </>
  );
}

function ImageRow({
  data,
  containerWidth,
  renderImage,
  caption,
}: {
  data: Asset[];
  containerWidth: number;
  renderImage?: (asset: Asset, index: number) => ReactNode;
  caption?: string;
}) {
  const assets = calcImageWidthPerRow(data, containerWidth, 8);
  const galleryModal = useModal();
  const [currAsset, setCurrAsset] = useState();

  const getImageData = (imageData) => {
    setCurrAsset(imageData);
    galleryModal.onSelect(true);
  };

  return (
    <>
      {assets.map((asset, i) => (
        <div key={i} className="relative">
          {i === 0 && (
            <SvgMagnifying
              position="absolute"
              left={3}
              top={3}
              fontSize="24px"
              zIndex="50"
              cursor="pointer"
              onClick={() => getImageData(asset)}
            />
          )}
          {renderImage ? (
            renderImage(asset, i)
          ) : (
            <AspectRatioImage key={i} data={asset} />
          )}
        </div>
      ))}
      {galleryModal.selected && (
        <GalleryModal
          {...galleryModal.modal}
          assets={assets}
          data={currAsset}
          caption={caption}
        />
      )}
    </>
  );
}
export function GalleryModal({
  isOpen,
  onClose,
  data,
  assets,
  caption,
}: UseDisclosureReturn & {
  data: Asset;
  assets: any;
  caption?: string;
}) {
  const [activeLightroomSlide, setActiveLightroomSlide] = useState({
    description: caption,
    title: caption,
    index: 1,
  });

  const [isShowMore, setIsShowMore] = useState(false);

  const executeOnClick = (isExpanded) => {
    setIsShowMore(isExpanded);
  };

  const isMobile = useIsMobile();

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      isCentered
    >
      <ModalContent
        w="100vw"
        maxW="100vw"
        h="100vh"
        position="fixed"
        background="rgba(25,25,25,0.9)"
        borderRadius="0"
        py="45px"
      >
        <ModalCloseButton
          color="$mode.50"
          _focus={{ outline: 'none' }}
          zIndex="99"
        />
        <ModalBody p={0} class="alignCenter fullHeightInMobile">
          {assets?.length === 1 && (
            <Box
              overflow="hidden"
              position="relative"
              borderRadius={isMobile ? 0 : '7px'}
              w="100%"
              h={isMobile ? '100%' : '650px'}
              display="flex"
              justifyContent="center"
              alignItems={isMobile ? 'center' : 'stretch'}
              opacity={isShowMore && 0.8}
            >
              {/* eslint-disable */}
              <Image
                alt="cover-image"
                src={imageOptimize(data.url) || imageOptimize(data[0].imgURL)}
                objectFit="cover"
                layout="fill"
                w={isMobile ? '100%' : 'auto'}
                h={isMobile ? (data.width > data.height ? '66vw' : '150vw') : 'auto'}
                maxH="100%"
              />
              {/* eslint-enable */}
            </Box>
          )}
          {assets?.length > 1 && (
            <Carousel
              slideIndex={activeLightroomSlide.index - 1}
              slidesToShow={1.25}
              cellAlign="center"
              wrapAround
              cellSpacing={21}
              opacityScale={0.5}
              renderCenterLeftControls={({ previousSlide }) => (
                <Box
                  w="300%"
                  h="inherit"
                  _hover={{ opacity: '1' }}
                  onClick={previousSlide}
                  className="animate-fadeOut cursor-pointer text-white"
                  opacity={0}
                >
                  <SvgArrowLeft w="30%" h="inherit" />
                </Box>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <Box
                  w="300%"
                  h="inherit"
                  _hover={{ opacity: '1' }}
                  onClick={nextSlide}
                  className="animate-fadeOut cursor-pointer text-white"
                  opacity={0}
                >
                  <SvgArrowRight w="30%" h="inherit" margin="0 0 0 auto" />
                </Box>
              )}
              renderBottomCenterControls={() => null}
              afterSlide={(slideIndex) => {
                setActiveLightroomSlide({
                  description: caption,
                  title: caption,
                  index: slideIndex + 1,
                });
              }}
            >
              {assets.map((file) => {
                return (
                  <Box
                    key={file.url}
                    overflow="hidden"
                    borderRadius="7px"
                    position="relative"
                  >
                    <Image
                      alt="cover-image"
                      src={
                        imageOptimize(file.url) || imageOptimize(file.imgURL)
                      }
                      objectFit="cover"
                      layout="fill"
                    />
                  </Box>
                );
              })}
            </Carousel>
          )}
          {isMobile ? (
            <Box
              w="100%"
              maxW="849px"
              mx="auto"
              pt={3}
              pb={6}
              px="6.6%"
              pos="absolute"
              bottom="0"
              background="rgba(36,37,38,0.5)"
            >
              <ShowMoreText
                /* Default options */
                lines={1}
                more="more"
                less="less"
                className="content-css"
                anchorClass="my-anchor-css-class"
                onClick={executeOnClick}
                expanded={false}
                width={280}
              >
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="18px"
                  color="white"
                >
                  {activeLightroomSlide.title}
                </Text>
              </ShowMoreText>
            </Box>
          ) : (
            <Box
              display="block"
              w="100%"
              gridTemplateColumns="80px 1fr"
              maxW="849px"
              mx="auto"
              pt={4}
              pb={4}
            >
              {assets.length > 1 && (
                <Flex
                  color="$mode.200"
                  fontSize="14px"
                  fontWeight="bold"
                  lineHeight="18px"
                  h="100%"
                  pr={3}
                  mr={3}
                  borderRight="2px solid #E6E8E9"
                >
                  {activeLightroomSlide.index < 10
                    ? `0${activeLightroomSlide.index}`
                    : activeLightroomSlide.index}
                  <Text color="$mode.400">
                    &nbsp;/&nbsp;
                    {assets.length < 10 ? `0${assets.length}` : assets.length}
                  </Text>
                </Flex>
              )}
              <Box>
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="18px"
                  color="$mode.400"
                >
                  {activeLightroomSlide.description}
                </Text>
              </Box>
            </Box>
          )}
          <style jsx global>{`
            .alignCenter {
              margin: auto 0;
            }
            .fullHeightInMobile {
              height: ${isMobile ? '100%' : 'auto'};
            }
            .slider-list {
              height: 650px !important;
            }
            .slider-slide {
              opacity: 0.5;
              height: 650px !important;
            }
            .slider-slide > div {
              width: 100%;
              height: 100%;
            }
            .slider-slide > div img {
              height: 100%;
              margin: 0 auto;
            }
            .slide-current {
              opacity: 1;
            }
            .slider-control-centerright {
              position: fixed !important;
              height: 100%;
              width: 10%;
              right: 20% !important;
            }
            .slider-control-centerleft {
              position: fixed !important;
              height: 100%;
              width: 10%;
            }
            .content-css {
              color: #74787d;
              font-weight: 400;
              color: white;
              font-size: 14px;
            }
            .my-anchor-css-class {
              color: #74787d;
              font-size: 14px;
              font-weight: 400;
            }
          `}</style>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export function AspectRatioImage({ data }: { data: Asset }) {
  const aspectRatio = data.height! / data.width!;
  return (
    <div
      className={css({
        display: 'flex',
        height: 0,
        position: 'relative',
      })}
      style={{
        width: data.displayWidth ? `${data.displayWidth}px` : '100%',
        paddingTop: `calc(${
          Number.isNaN(aspectRatio) ? 0.5 : aspectRatio
        } * 100%)`,
      }}
    >
      <img
        src={imageOptimize(data.url)}
        width={data.displayWidth}
        className={css({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        })}
        alt="gallery"
      />
    </div>
  );
}
