import * as React from 'react';
import { Svg } from '../svg';

function SvgArrowRightV3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8.59 5.41L10 4l8 8-8 8-1.41-1.41L15.17 12 8.59 5.41z"
        fill="#fff"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowRightV3);
export default ForwardRef;
