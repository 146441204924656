import { useMutation, useQueryClient } from 'react-query';
import { standaloneToast } from 'src/components/toast';
import { CacheKey } from 'src/constants/cache-key';
import { axios } from 'src/lib/axios';

interface ArticlePinRequest {
  dataset: {
    contentID: string;
    pinned?: boolean;
    pick?: boolean;
    pinnedMessage?: string;
    type?: 'pin' | 'pick';
  }[];
  contentType: 'EPISODE';
  projectID?: string;
}

const pinArticle = (req: ArticlePinRequest) => {
  return axios.post('/article/pin', req).then((res) => res.data);
};

const pickArticle = (req: ArticlePinRequest) => {
  return axios.post('/article/pick', req).then((res) => res.data);
};

export function useArticlePin() {
  const queryClient = useQueryClient();
  return useMutation(pinArticle, {
    onSettled: () => {
      queryClient.invalidateQueries(CacheKey.episodeList);
      queryClient.invalidateQueries(CacheKey.creationList);
      queryClient.invalidateQueries(CacheKey.collectionList);
    },
    onError: () => {
      standaloneToast({
        title: 'Failed 😢',
        description:
          "⚠️ The maximum Starter's picks are 4, if you want to pick more please remove the current one of picks.",
        status: 'error',
        duration: 5000,
      });
    },
  });
}

export function useArticlePick() {
  const queryClient = useQueryClient();
  return useMutation(pickArticle, {
    onSettled: () => {
      queryClient.invalidateQueries(CacheKey.episodeList);
      queryClient.invalidateQueries(CacheKey.creationList);
      queryClient.invalidateQueries(CacheKey.collectionList);
    },
    onError: () => {
      standaloneToast({
        title: 'Failed 😢',
        description:
          '⚠️ The maximum Featured Episode are 8, if you want to pick more please remove the current one of picks.',
        status: 'error',
        duration: 5000,
      });
    },
  });
}
