import * as React from 'react';
import { Svg } from '../svg';

function SvgIcMusicrecord({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M18.564 21.392a2 2 0 102.828-2.828 2 2 0 00-2.828 2.828z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.435 33.435c-7.42 7.42-19.45 7.42-26.87 0-7.42-7.42-7.42-19.45 0-26.87 7.42-7.42 19.45-7.42 26.87 0 7.42 7.42 7.42 19.45 0 26.87zM28.817 7.604l3.535 3.535-5.067 3.418-1.886-1.886 3.418-5.067zM24.22 24.22a6 6 0 11-8.486-8.485 6 6 0 018.486 8.485zm-9.664 3.064l-3.418 5.067-3.535-3.535 5.067-3.418 1.886 1.886z"
        fill="#fff"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgIcMusicrecord);
export default ForwardRef;
