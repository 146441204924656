import { Transition } from '@headlessui/react';
import { Fragment, useContext } from 'react';
import SvgClose from 'src/components/icons/close';
import SvgModalSuccessIcon from 'src/components/icons/modal-success-icon';
import { ToastContext, ToastContextType } from 'src/contexts/ToastContext';

const Toast = () => {
  const { show, toastText, className, toastHandle } = useContext(
    ToastContext
  ) as ToastContextType;

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`shadow-md z-50 bg-white fixed items-center flex p-4 rounded-md ${className}`}
      >
        <div>
          <SvgModalSuccessIcon fontSize="30px" />
        </div>
        <p className="text-sm font-medium text-gray-900 ml-2">{toastText}</p>
        <div className="ml-4 flex">
          <span
            aria-hidden="true"
            className="text-xs cursor-pointer"
            onClick={() => {
              toastHandle(toastText, false);
            }}
          >
            <SvgClose />
          </span>
        </div>
      </div>
    </Transition>
  );
};
export default Toast;
