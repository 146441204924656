import * as React from 'react';
import { Svg } from '../svg';

function SvgCheckedCircle({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <rect width={24} height={24} rx={12} fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 5.376 5.376 0 12 0s12 5.376 12 12-5.376 12-12 12S0 18.624 0 12zm9.6 2.604l7.908-7.908L19.2 8.4 9.6 18l-4.8-4.8 1.692-1.692L9.6 14.604z"
        fill="#2BC3A8"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCheckedCircle);
export default ForwardRef;
