import * as React from 'react';
import { Svg } from '../svg';

function SvgCopy({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <rect width={32} height={32} rx={16} fill="#74787D" />
      <path
        d="M14.334 16.833a4.166 4.166 0 006.283.45l2.5-2.5a4.166 4.166 0 00-5.892-5.891l-1.433 1.425"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.667 15.167a4.164 4.164 0 00-4.793-1.409 4.166 4.166 0 00-1.49.959l-2.5 2.5a4.167 4.167 0 005.891 5.891l1.425-1.425"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCopy);
export default ForwardRef;
