/**
 * External dependencies
 */
import { useContext } from 'react';

/**
 * Internal dependencies
 */
import SearchContext from './context';
import { SearchContextType } from './types';

export default () => useContext(SearchContext) as SearchContextType;
