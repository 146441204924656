import * as React from 'react';
import { Svg } from '../svg';

function SvgNcNs({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.62 28.064A13.312 13.312 0 0120 33.334c-7.364 0-13.333-5.97-13.333-13.334 0-1.834.37-3.582 1.04-5.173l6.74 3.891c-1.096 2.157-.767 3.939-.718 4.169h-2.286l3.925 5.599 3.926-5.6h-2.226c.006-2.273.856-3.153 1.96-4.298.188-.195.384-.398.584-.617 1.893-2.067 2.107-3.696 2.107-6.304h-3.485c0 2.552-.349 2.914-1.511 4.12l-.246.255-7.101-4.1A13.312 13.312 0 0120 6.667c7.364 0 13.333 5.97 13.333 13.333 0 1.834-.37 3.582-1.04 5.173l-3.762-2.172.08-.114h-2.358c.062-.294.578-3.107-1.977-6.007-.393-.447-.73-.796-1.017-1.094-.447-.463-.774-.802-1.006-1.188a7.182 7.182 0 01-1.745 3.523c.152.163.301.317.446.467 1.104 1.145 1.954 2.025 1.96 4.299H20.76l3.926 5.599 1.914-2.73 4.02 2.308zM36.666 20c0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.667-7.462-16.667-16.667 0-9.205 7.462-16.667 16.667-16.667 9.205 0 16.667 7.462 16.667 16.667z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNcNs);
export default ForwardRef;
