import * as React from 'react';
import { Svg } from '../svg';

function SvgCcNd({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#cc-nd_svg__clip0_7994_98869)">
        <path
          d="M20.5 4.083c9.205 0 16.667 7.462 16.667 16.667 0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.667-7.462-16.667-16.667 0-9.205 7.462-16.667 16.667-16.667zm0 3.334a13.333 13.333 0 100 26.667 13.333 13.333 0 000-26.667zm6.667 15v3.333H13.833v-3.333h13.334zm0-6.667v3.333H13.833V15.75h13.334z"
          fill="#4F5356"
        />
      </g>
      <defs>
        <clipPath id="cc-nd_svg__clip0_7994_98869">
          <path fill="#fff" transform="translate(.5 .75)" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCcNd);
export default ForwardRef;
