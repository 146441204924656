import * as React from 'react';
import { Svg } from '../svg';

function SvgMailbox({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M18.834 10.207h-5l-1.667 2.5H8.834l-1.667-2.5h-5"
        stroke="#9B9FA4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.042 4.465l-2.875 5.742v5a1.667 1.667 0 001.667 1.666h13.333a1.667 1.667 0 001.667-1.666v-5l-2.875-5.742a1.667 1.667 0 00-1.492-.925H6.534a1.667 1.667 0 00-1.492.925z"
        stroke="#9B9FA4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgMailbox);
export default ForwardRef;
