import {
  Button,
  Center,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import SvgWarning from 'src/components/icons/warning';
import SvgError from 'src/components/icons/error';
import SvgIllustrationSculpting from 'src/components/icons/illustration-sculpting';
import { useTranslation } from 'react-i18next';

function WarningModal({
  isOpen,
  onClose,
  title,
  subTitle,
  text,
  cancelBtnText = 'Cancel',
  cancelBtnColor = 'warning',
  cancelBtnScheme = '#FF6633',
  okBtnText = 'Remove',
  onOk,
  onDecline,
  onOkBtnColor = '#FF6633',
  warningType,
  noCancel = false,
  iconColor = '#FFD557',
  fullScreenOnMobile = false,
  isFooterCaption = false,
  footerCaption = '*Your folio will remain published in your studio.',
  additionalModal,
}: UseDisclosureReturn & {
  title: string;
  subTitle?: string;
  text?: string;
  cancelBtnText?: string;
  cancelBtnColor?: string;
  cancelBtnScheme?: string;
  okBtnText?: string;
  onOkBtnColor?: string;
  warningType?: string;
  onOk: () => void;
  onDecline?: () => void;
  noCancel?: boolean;
  iconColor?: string;
  fullScreenOnMobile?: boolean;
  isFooterCaption?: boolean;
  footerCaption?: string;
  additionalModal?: any;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      size={`${fullScreenOnMobile ? 'full' : '2xl'}`}
      isOpen={isOpen}
      onClose={() => {
        if (additionalModal) {
          additionalModal.onClose();
        }
        onClose();
      }}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay>
        <ModalContent
          bg="$mode.50"
          pt="10px"
          px={{ base: '15px', md: '30px' }}
          pb="40px"
          w="480px"
        >
          <Center
            flexDirection="column"
            margin={`${fullScreenOnMobile ? 'auto' : 'initial'}`}
            padding={`${fullScreenOnMobile ? '0 0.7rem' : 'initial'}`}
          >
            {!warningType && (
              <div className="relative p-5">
                <SvgWarning
                  fontSize="26px"
                  position="absolute"
                  top="calc(50% - 13px)"
                  left="calc(50% - 13px)"
                />
                <Box
                  bgColor="warning"
                  borderRadius={99}
                  opacity="0.1"
                  margin="auto"
                  w="75px"
                  h="75px"
                />
              </div>
            )}
            {warningType === 'warning2' && (
              <SvgError fontSize="75px" className="m-5" color={iconColor} />
            )}
            {warningType === 'warning3' && (
              <SvgIllustrationSculpting fontSize="186px" />
            )}
            <Heading fontSize="1.5rem" color="black">
              <Text
                fontWeight="600"
                noOfLines={2}
                textAlign="center"
                className={`${
                  fullScreenOnMobile ? 'text-2xl font-semibold' : ''
                }`}
              >
                {title}
              </Text>
              <Text fontWeight="600" textAlign="center">
                {subTitle}
              </Text>
            </Heading>
            {text ? (
              <Text
                textAlign="center"
                textStyle="body0"
                color={`${fullScreenOnMobile ? '$mode.700' : '$mode.500'}`}
                className={`${fullScreenOnMobile ? 'text-sm font-medium' : ''}`}
                pt={3}
                pb={`${fullScreenOnMobile ? '16px' : '48px'}`}
              >
                {text}
              </Text>
            ) : (
              <Box mt={3} mb={6} />
            )}
            <Box>
              {!noCancel && (
                <Button
                  mx={2}
                  width="calc(50% - 16px)"
                  colorScheme={cancelBtnScheme}
                  color={cancelBtnColor}
                  variant="ghost"
                  onClick={() => {
                    if (cancelBtnText === t('decline') || onDecline) {
                      onDecline();
                    }
                    onClose();
                  }}
                >
                  {cancelBtnText}
                </Button>
              )}
              <Button
                mx={2}
                width={`${!noCancel ? 'calc(50% - 16px)' : '100%'}`}
                colorScheme={onOkBtnColor}
                bgColor={onOkBtnColor}
                rounded="ellipse"
                onClick={() => {
                  onOk();
                  if (!fullScreenOnMobile) {
                    onClose();
                  }
                }}
              >
                {okBtnText}
              </Button>
            </Box>
            {isFooterCaption && (
              <div className="w-full px-3">
                <div className="border-dashed border-t my-8 mb-2 w-full" />
                <div className="text-gray-400 font-medium text-xs w-full">
                  {footerCaption}
                </div>
              </div>
            )}
          </Center>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default WarningModal;
