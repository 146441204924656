import * as React from 'react';
import { Svg } from '../svg';

function SvgTimer({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8.334 1.667h3.333M10 11.667l2.5-2.5M10 18.333A6.667 6.667 0 1010 5a6.667 6.667 0 000 13.333z"
        stroke="#4F5356"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTimer);
export default ForwardRef;
