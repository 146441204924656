export const WhatIsAgoraBody = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Discover Agora
      </h1>
      <img
        src="/images/assets/tutorial/what-is-agora.png"
        alt="what is agora"
        className="w-full"
      />
    </>
  );
};

export const WhatIsAgoraFooter = ({ setNextModal }: { setNextModal?: any }) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Wait... what is Agora?
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Agora is a discovery hub where you can meet new people and find
        inspiration. Check out updates from people and projects you already
        follow. Find new projects to join, new creators to follow, or see
        trending creations in 1TM. Discover the world with Agora!
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('feedArea');
        }}
        className="px-9 py-2.5 mb-6 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Start
      </button>
    </>
  );
};
