import * as React from 'react';
import { Svg } from '../svg';

function SvgSettingsV3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.183 1.667h-.366A1.667 1.667 0 008.15 3.334v.15a1.667 1.667 0 01-.833 1.441l-.359.209a1.667 1.667 0 01-1.666 0l-.125-.067a1.667 1.667 0 00-2.275.608l-.184.317a1.667 1.667 0 00.609 2.275l.125.083a1.667 1.667 0 01.833 1.434v.425a1.666 1.666 0 01-.833 1.45l-.125.075a1.667 1.667 0 00-.609 2.275l.184.316a1.667 1.667 0 002.275.609l.125-.067a1.666 1.666 0 011.666 0l.359.208a1.667 1.667 0 01.833 1.442v.15a1.667 1.667 0 001.667 1.667h.366a1.666 1.666 0 001.667-1.667v-.15a1.668 1.668 0 01.833-1.442l.359-.208a1.666 1.666 0 011.666 0l.125.067a1.667 1.667 0 002.275-.609l.184-.325a1.667 1.667 0 00-.609-2.275l-.125-.066a1.668 1.668 0 01-.833-1.45v-.417a1.667 1.667 0 01.833-1.45l.125-.075a1.667 1.667 0 00.609-2.275l-.184-.317a1.667 1.667 0 00-2.275-.608l-.125.067a1.666 1.666 0 01-1.666 0l-.359-.209a1.667 1.667 0 01-.833-1.441v-.15a1.667 1.667 0 00-1.667-1.667v0z"
        stroke="#242526"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        stroke="#242526"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgSettingsV3);
export default ForwardRef;
