import * as React from 'react';
import { Svg } from '../svg';

function SvgVectorGreenUp({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M1.635 9a1 1 0 01-.768-1.64L6.232.922a1 1 0 011.536 0l5.365 6.438A1 1 0 0112.365 9H1.635z"
        fill="#2BC3A8"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgVectorGreenUp);
export default ForwardRef;
