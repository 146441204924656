import chakraTheme from '@chakra-ui/theme';
import { toRem } from '../tools';

const radii = {
  ...chakraTheme.radii,

  ellipse: toRem(40),
};

export default radii;
