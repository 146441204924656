/* eslint-disable react/no-array-index-key */
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import uniqBy from 'lodash.uniqby';

import { useState, useContext, useEffect } from 'react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';

import { LabelMutationModal } from 'src/app/label/components/label-mutation-modal';
import {
  useCreationLabelQuery,
  useCurationMutation,
} from 'src/app/label/hooks';
import {
  AreaLabelText,
  CreationLabelRequest,
  Label,
} from 'src/app/label/services';

import SvgAdd from 'src/components/icons/add';
import SvgCheck from 'src/components/icons/check';
import SvgSave from 'src/components/icons/save';

import { SelectList, useSelectList } from 'src/components/select-list';
import { standaloneToast } from 'src/components/toast';
import type { Article } from 'src/app/article/types';
import type { ListType } from 'src/service/creation';
import { useDebounce } from 'use-debounce';

const config = {
  STUDIO: {
    title: 'Save to inspiration',
    create: {
      text:
        'Found something inspiring? Save it into personalized inspirations.',
    },
  },
  REPPL: {
    title: 'Add to your inspiration',
    create: {
      text: 'Curate episodes by adding them into inspirations.',
    },
  },
};

export function AddToLabelModal({
  areaType,
  collectionItem,
  areaID,
  collectionData,
  dataChecker,
  ...props
}: UseDisclosureReturn & {
  collectionItem: Article | ListType;
  areaID: string;
  areaType: CreationLabelRequest['areaType'];
  collectionData?: any;
  dataChecker?: any;
}) {
  const { user } = useContext(UserContext) as UserContextType;
  const [selectedLabel, setSelectedLabel] = useState<Label>(null);
  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword] = useDebounce(keyword, 300);
  const [currCollectionItem, setCollectionItem] = useState<any>(null);

  const {
    data: creationLabelData,
    hasNextPage,
    fetchNextPage,
    refetch,
    isLoading,
  } = useCreationLabelQuery(currCollectionItem, {
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (collectionItem) {
      setCollectionItem({
        areaType,
        areaID: areaID || user.id,
        contentID: collectionItem.contentID,
        contentType: 'EPISODE',
      });
    }
  }, [collectionItem]);

  const selectList = useSelectList({
    setKeyword,
    enabled: hasNextPage,
    fetchNextPage,
  });

  const { mutateAsync: createCurate } = useCurationMutation();
  const createLabelModal = useDisclosure({
    onClose: () => {
      refetch();
    },
  });
  const addedLabel = uniqBy(
    creationLabelData?.pages?.flatMap((s) => s?.addedLabel),
    'id'
  ) as Label[];
  const creationLabel = creationLabelData?.pages?.flatMap((s) => s?.data);

  useEffect(() => {
    if (dataChecker?.length === 0 && addedLabel.length > 0 && collectionData) {
      collectionData(addedLabel);
    }
  });

  const hasNoLabelYet =
    keyword === '' &&
    (creationLabelData?.pages?.[0]?.total ?? 1) === 0 &&
    !isLoading;

  return (
    <Modal {...props} isCentered scrollBehavior="inside" autoFocus={false}>
      <ModalOverlay>
        <ModalContent
          maxH="700px"
          h="100%"
          maxW="100%"
          width={{
            base: '100vw',
            md: '640px',
          }}
        >
          <SelectList
            {...selectList}
            emptySection={
              hasNoLabelYet && (
                <Center flexDir="column" w="270px" py="60px" m="auto">
                  <Text textStyle="h4" mb={3}>
                    Create your first collection
                  </Text>
                  <Text textStyle="body0" mb={4} color="$mode.500">
                    {config[areaType].create.text}
                  </Text>
                  <Button
                    rounded="full"
                    variant="outline"
                    onClick={createLabelModal.onOpen}
                  >
                    Create a collection
                  </Button>
                </Center>
              )
            }
            innerWrapperProps={{
              mt: '24px',
              mb: '16px',
              layerStyle: 'layer3',
              px: '24px',
              border: '1px solid',
              borderColor: 'gray.200',
              borderRadius: '4px',
            }}
            keyword={keyword}
            title={config[areaType].title}
            subTitle={
              addedLabel?.length > 0 && (
                <Text color="$mode.500" pt="16px">
                  This {AreaLabelText[areaType]} has been already added to:{' '}
                  {addedLabel.map((label) => `”${label.name}”`).join(', ')}
                </Text>
              )
            }
            listHeader={
              <Text color="$mode.400" textStyle="labelLight" mb="12px">
                Current collections
              </Text>
            }
            footer={
              <>
                {!hasNoLabelYet && (
                  <Center justifyContent="flex-start">
                    <Button
                      borderColor="gray.300"
                      variant="outline"
                      leftIcon={<SvgAdd />}
                      onClick={createLabelModal.onOpen}
                    >
                      New collection
                    </Button>
                  </Center>
                )}
                <LabelMutationModal
                  areaType={areaType}
                  modal={createLabelModal}
                  areaID={areaID}
                />
                <ButtonGroup mt="32px" justifyContent="flex-end">
                  <Button
                    variant="ghost"
                    rounded="ellipse"
                    onClick={props.onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    isDisabled={!selectedLabel}
                    rounded="ellipse"
                    onClick={() => {
                      createCurate({
                        contentID: [collectionItem.contentID],
                        labelID: selectedLabel.id,
                        contentType: 'EPISODE',
                      }).then((res) => {
                        if (res.code === 1000) {
                          if (collectionData) {
                            collectionData([selectedLabel]);
                          }
                          props.onClose();
                          standaloneToast({
                            render: () => (
                              <Alert status="success">
                                <AlertIcon />
                                <AlertTitle
                                  as={Link}
                                  to={
                                    areaType === 'REPPL'
                                      ? `/space/${areaID}/collections/${selectedLabel.uuid}`
                                      : `/${user}`
                                  }
                                >
                                  {`${collectionItem.title} added to ${selectedLabel.name}`}
                                </AlertTitle>
                              </Alert>
                            ),
                            status: 'success',
                          });
                        } else {
                          standaloneToast({
                            title: 'Error',
                            status: 'error',
                          });
                        }
                      });
                    }}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </>
            }
          >
            <Stack spacing={3}>
              {creationLabel?.map((label) => {
                const isActive = label.id === selectedLabel?.id;
                return (
                  <Flex
                    cursor="pointer"
                    key={label.id}
                    py="18px"
                    px="24px"
                    border="1px solid"
                    borderColor={isActive ? 'blue.500' : 'gray.200'}
                    borderRadius="4px"
                    layerStyle="layer3"
                    backgroundColor={isActive ? 'blue.50' : 'inherit'}
                    align="center"
                    color={isActive ? 'blue.500' : '$mode.700'}
                    onClick={() => setSelectedLabel(label)}
                  >
                    <Box
                      fontSize="24px"
                      color={isActive ? 'blue.500' : '$mode.200'}
                    >
                      {isActive ? <SvgCheck /> : <SvgSave color="$mode.700" />}
                    </Box>
                    <Text flex={1} noOfLines={1} textStyle="h5" ml={3}>
                      {label.name}
                    </Text>
                  </Flex>
                );
              })}
            </Stack>
          </SelectList>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
