import * as React from 'react';
import { Svg } from '../svg';

function SvgEditorEdit({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        clipPath="url(#editor-edit_svg__clip0_9894_110264)"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.167 3.334H3.333a1.667 1.667 0 00-1.666 1.667v11.666a1.667 1.667 0 001.666 1.667H15a1.667 1.667 0 001.667-1.667v-5.833" />
        <path d="M15.417 2.084a1.768 1.768 0 012.5 2.5L10 12.501l-3.333.833.833-3.333 7.917-7.917z" />
      </g>
      <defs>
        <clipPath id="editor-edit_svg__clip0_9894_110264">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEditorEdit);
export default ForwardRef;
