import { Box, Center, CenterProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { DropzoneState } from 'react-dropzone';
import { LIMIT_UPLOAD_IMAGE_FILE_ACCEPT } from 'src/constants/limit';

export interface UploaderProps {
  dropzone: DropzoneState;
  src?: string;
  enable?: boolean;
  uploading?: boolean;
  onDelete?: () => void;
  children: ReactNode;
  overlay?: ReactNode;
}

export function MediaPostImageUploader({
  dropzone,
  children,
  onDelete,
  src,
  uploading = false,
  enable = true,
  overlay,
  ...props
}: UploaderProps & CenterProps) {
  return (
    <div {...dropzone.getRootProps()}>
      <Box
        position="relative"
        sx={{
          '&:hover > button': {
            opacity: 1,
          },
        }}
        {...props}
      >
        <Center
          height="100%"
          width="100%"
          position="relative"
          overflow="hidden"
          flexDir="column"
          cursor={enable && uploading ? 'not-allowed' : 'default'}
        >
          <input
            {...dropzone.getInputProps({
              accept: LIMIT_UPLOAD_IMAGE_FILE_ACCEPT,
            })}
            data-testid="hidden-upload-input"
          />
          {children}
        </Center>
      </Box>
    </div>
  );
}
