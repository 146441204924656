function setSearchTerm(state, genre) {
  if (genre === state.genre) {
    return state;
  }

  return {
    ...state,
    genre,
  };
}

export default setSearchTerm;
