import chakraTheme from '@chakra-ui/theme';
import components from './components';
import foundations from './foundations';
import styles from './styles';

const theme = {
  ...chakraTheme,
  ...foundations,
  styles,
  components: {
    ...chakraTheme.components,
    ...components,
  },
};

export type Theme = typeof theme;

export default theme;
