import * as React from 'react';
import { Svg } from '../svg';

function SvgVisibilityOutlined({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
        <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
      </g>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgVisibilityOutlined);
export default ForwardRef;
