import { COOKIES_AUTH_TOKEN } from 'src/constants/cookies';
import { RestResponse } from 'src/types';
import { axios } from 'src/lib/axios';
import { getCookie } from 'src/lib/cookies';

export type UserInfoResponse = {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  profileImgURL: string;
  email: string;
  isActivated: boolean;
  userRole?: string;
};

const fetchUser = async () => {
  const data = await axios
    .get<RestResponse<UserInfoResponse>>(`/user/userinfo`)
    .then((res) => res.data);

  return data;
};

// Auth User information. This is what we transform to client
export interface AuthUserInfo {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  isActivated: boolean;
  userRole?: string;
}

// authenticate return AuthUserInfo if no user is return that means is not auth.
async function authenticate(): Promise<AuthUserInfo | null> {
  const hasToken = getCookie(COOKIES_AUTH_TOKEN) !== '';

  if (!hasToken) {
    return null;
  }

  try {
    const data = await fetchUser();

    if (data.code === 1000) {
      const { data: userInfo } = data;

      const user: AuthUserInfo = {
        id: userInfo.id,
        username: userInfo.username,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        avatar: userInfo.profileImgURL,
        isActivated: userInfo.isActivated,
      };

      return user;
    }

    return null;
  } catch (err) {
    return null;
  }
}

export default authenticate;
