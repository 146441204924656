/**
 * External dependencies
 */
import { createContext } from 'react';

/**
 * Internal dependencies
 */
import { FolioContextType } from './types';

export default createContext({} as FolioContextType);
