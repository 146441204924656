export const INITIAL_STATE = {
  isFetching: true,
  isError: false,
  isSearchLite: true,

  searchTerm: '',

  resultLite: [],
  totalResult: 0,

  similarSuggestions: [],
  trendingSuggestions: [],
  recentSearchTerms: [],
};
