import * as React from 'react';
import { Svg } from '../svg';

function SvgCcType({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4.526C9.664 4.526 4.527 9.663 4.527 16c0 6.337 5.137 11.473 11.473 11.473 6.336 0 11.473-5.136 11.473-11.473 0-6.336-5.137-11.473-11.473-11.473zM2.666 16C2.666 8.636 8.636 2.666 16 2.666s13.333 5.97 13.333 13.333c0 7.364-5.97 13.334-13.333 13.334-7.364 0-13.334-5.97-13.334-13.334zm7.066-4.308a5.101 5.101 0 013.091-.187 5.17 5.17 0 012.64 1.636.93.93 0 11-1.407 1.216 3.309 3.309 0 00-1.69-1.048 3.24 3.24 0 00-1.964.119 3.337 3.337 0 00-1.56 1.246 3.474 3.474 0 00-.594 1.946c0 .698.208 1.377.594 1.947.386.57.93 1.004 1.56 1.246a3.24 3.24 0 001.964.119 3.308 3.308 0 001.69-1.048.93.93 0 011.407 1.216 5.17 5.17 0 01-2.64 1.636 5.101 5.101 0 01-3.091-.187 5.197 5.197 0 01-2.43-1.94 5.334 5.334 0 01-.915-2.989c0-1.066.318-2.108.914-2.989a5.197 5.197 0 012.43-1.939zm9.922 0a5.101 5.101 0 013.092-.187 5.17 5.17 0 012.64 1.636.93.93 0 01-1.408 1.216 3.308 3.308 0 00-1.69-1.048 3.24 3.24 0 00-1.964.119 3.336 3.336 0 00-1.56 1.246 3.474 3.474 0 00-.594 1.946c0 .698.208 1.377.595 1.947.386.57.93 1.004 1.559 1.246a3.24 3.24 0 001.965.119 3.307 3.307 0 001.69-1.048.93.93 0 011.407 1.216 5.17 5.17 0 01-2.64 1.636 5.101 5.101 0 01-3.092-.187 5.197 5.197 0 01-2.43-1.94 5.335 5.335 0 01-.914-2.989c0-1.066.318-2.108.914-2.989a5.197 5.197 0 012.43-1.939z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCcType);
export default ForwardRef;
