import React from 'react';
import SvgLogo from 'src/components/icons/logo';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { Box } from '@chakra-ui/react';

interface PreloadImgProps {
  width?: number;
  height?: number;
  className?: string;
}

const PreloadImg = ({ width, height, className = '' }: PreloadImgProps) => {
  const wrapW = width ? `${width}px` : '100%';
  const wrapH = height ? `${height}px` : '100%';
  const isMobile = useIsMobile();

  return (
    <Box
      style={{ width: wrapW, height: wrapH }}
      className={`${className} flex justify-center items-center bg-teal-200`}
      borderRadius={isMobile ? '4px' : '0'}
    >
      <SvgLogo className="svg-logo-size text-white" />
    </Box>
  );
};
export default React.memo(PreloadImg);
