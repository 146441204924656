/**
 * External dependencies
 */
import { useRef, useCallback, useReducer } from 'react';

/**
 * Internal dependencies
 */
import reducer from './reducer';
import * as types from './reducers/types';
import { fetchSearchResult, fetchTopics } from './algoliaSearch';

import { INITIAL_STATE } from './constants';

function useSearchReducer() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const searchInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onSearchChange = useCallback(() => {
    const inputValue = searchInputRef.current?.value || '';

    /** <-- push squery */
    if (inputValue) {
      window.history.replaceState(
        { searchTerm: inputValue },
        '',
        `/search/${inputValue}`
      );
    }
    /** push squery--> */

    dispatch({
      type: types.SET_SEARCH_TERM,
      payload: { searchTerm: inputValue },
    });
    return inputValue;
  }, []);

  const setGenre = useCallback(
    (value) => dispatch({ type: types.SET_GENRE, payload: { genre: value } }),
    []
  );

  const search = useCallback(
    async (searchTerm, more = false, callbackAfterFetchSuccess) => {
      try {
        dispatch({
          type: types.FETCH_SEARCH_START,
          payload: { isSearchLite: false },
        });

        const { result, total: resultTotal } = await fetchSearchResult(
          searchTerm
        );
        const [topicGenreKey, topics] = await fetchTopics(searchTerm);

        dispatch({
          type: types.FETCH_SEARCH_SUCCESS,
          payload: {
            result: { ...result, [topicGenreKey as string]: topics },
            total: resultTotal + topics.length,
            callbackAfterFetchSuccess,
            more,
          },
        });
      } catch (error) {
        dispatch({ type: types.FETCH_SEARCH_ERROR, payload: {} });
      }
    },
    []
  );

  const clear = () => {
    searchInputRef.current.value = '';

    dispatch({
      type: types.CLEAR_SEARCH,
      payload: { isSearchLite: false },
    });
  };

  const actions = {
    onSearchChange,
    setGenre,
    search,
    clear,
  };

  return {
    state,
    actions,
    searchInputRef,
  };
}

export default useSearchReducer;
