import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, Input, Text } from '@chakra-ui/react';
import { animated, useTransition } from 'react-spring';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { logger } from 'src/lib/logger';
import SvgForgotPasswordLogo from 'src/components/icons/forgot-password-logo';
import { forgetPassword } from '../service';

import ModalResendPassword from './modal-resend-password';

interface ForgetPasswordFormProps {
  onEmailSubmit: (email: string) => void;
  errorForgotPassword?: boolean;
  setErrorForgotPassword?: (type: boolean) => void;
}

function ForgetPasswordForm({
  onEmailSubmit,
  errorForgotPassword,
  setErrorForgotPassword,
}: ForgetPasswordFormProps) {
  const { register, handleSubmit, errors, watch } = useForm<{
    email: string;
  }>();
  const { t } = useTranslation();
  const [disableBtn, setDisableBtn] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setErrorForgotPassword(false);
      onEmailSubmit(data.email);
    } catch (err) {
      logger.error(err);
    }
  });
  useEffect(() => {
    if (!validateEmail(watch('email'))) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [watch]);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col	items-center">
        <SvgForgotPasswordLogo fontSize="80px" mb={1} />
        <h1 className="mb-1 text-xl lg:text-4xl text-gray-900">
          Forgot password?
        </h1>
        <p className="mb-6 lg:mb-8 text-gray-700 text-xs ls:text-sm max-w-xs lg:max-w-md text-center">
          Please enter the email linked to your 1TM account and we’ll send you
          instructions on how to reset your password.
        </p>

        <div className="w-full max-w-xs lg:max-w-md mb-8">
          <FormControl id="email">
            <Input
              name="email"
              type="email"
              border="1px solid"
              _focus={{
                borderColor: errorForgotPassword ? 'red.500' : 'gray.400',
              }}
              _hover={{
                borderColor: errorForgotPassword ? 'red.500' : 'gray.400',
              }}
              borderColor={errorForgotPassword ? 'red.500' : 'gray.200'}
              background={
                errors?.email || errorForgotPassword
                  ? 'rgba(255, 102, 51, 0.1)'
                  : 'white'
              }
              focusBorderColor="black"
              placeholder="welcome@1tm.io"
              isInvalid={!!errors?.email}
              ref={register({
                required: true,
                // ref: https://ui.dev/validate-email-address-javascript/
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              })}
            />
            {errorForgotPassword && (
              <Text
                mt="8px"
                color="red.500"
                fontSize="xs"
                data-testid="invalid-email"
              >
                {t('This email doesn’t exist.')}
              </Text>
            )}
          </FormControl>
        </div>

        <Button
          rounded="ellipse"
          type="submit"
          data-testid="resend-pwd"
          width="max-content"
          px="32px"
          py="10px"
          isDisabled={disableBtn}
        >
          <span className="text-sm lg:text-base">Send</span>
        </Button>
      </div>
    </form>
  );
}

export interface ForgetPasswordProps {
  toggleForm: () => void;
}

function ForgetPassword({ toggleForm }: ForgetPasswordProps) {
  const [isSent, setIsSent] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [errorForgotPassword, setErrorForgotPassword] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(30);

  const transitions = useTransition(isSent, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { position: 'absolute', opacity: 0 },
  });

  const Timer = () => {
    let seconds = 30;
    setTimerSeconds(seconds);

    const timer = setInterval(() => {
      if (seconds > 0) {
        seconds -= 1;
        setTimerSeconds(seconds);
      } else {
        clearInterval(timer);
      }
    }, 1000);
  };

  const handleEmailSubmit = async (email: string) => {
    try {
      await forgetPassword({ email });
      setIsSent(true);
      setUserEmail(email);
      Timer();
    } catch (error) {
      setErrorForgotPassword(true);

      logger.error(error);
      // TODO: error handle
    }
  };

  return (
    <Box width="100%">
      {transitions.map(({ item, key, props }) => (
        <animated.div key={key} style={props}>
          {item ? (
            <ModalResendPassword
              email={userEmail}
              toggleForm={toggleForm}
              onEmailSubmit={handleEmailSubmit}
              Timer={Timer}
              timerSeconds={timerSeconds}
            />
          ) : (
            <ForgetPasswordForm
              errorForgotPassword={errorForgotPassword}
              setErrorForgotPassword={setErrorForgotPassword}
              onEmailSubmit={handleEmailSubmit}
            />
          )}
          {item && (
            <style jsx global>{`
              .only-in-forgot-password-page {
                display: none;
              }
            `}</style>
          )}
        </animated.div>
      ))}
    </Box>
  );
}

export default ForgetPassword;
