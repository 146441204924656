import cn from 'classnames';
import { useRouter } from 'next/router';
import {
  Box,
  Flex,
  // Heading,
  // Skeleton,
  // Text,
  useDisclosure,
  // Image,
  // Button,
  // Modal,
  // ModalContent,
  // ModalCloseButton,
  // ModalBody,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import dayHepler from 'src/heplers/day';
// import { EditorRenderer } from '@hexx/renderer';
import React, { useRef, useState, useEffect, useContext, useMemo } from 'react';
import { ArticleComment } from 'src/app/article/article-comment';
import { PublishIn } from 'src/app/article/components/publisher/author';
import { StickyIconButtonList } from 'src/app/article/components/sticky-icon-button-list/sticky-icon-button-list';
import {
  useArticleCommentMutation,
  useArticleEditCommentMutation,
  useCreationsComment,
} from 'src/app/article/hooks/use-article-comment';
import { useCommentEnthuseMutation } from 'src/app/article/hooks/use-enthuse';
import { Article, Author, FullArticle } from 'src/app/article/types';
import { Avatar } from 'src/components/avatar';
import { Commenter, CommenterHandler } from 'src/components/comment';
// import { rendererBlockMaps } from 'src/lib/hexx/render-blocks';
import { arrayHasData } from 'src/utils/array';

import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { MemberCard } from 'src/app/reppl/team/member-card';
import { getUser } from 'src/app/reppl/service';
import { useFollowUserMutation } from 'src/app/profile/use-follow-user.mutation';
import { logger } from 'src/lib/logger/logger.server';
import { CacheKey } from 'src/constants/cache-key';
import type { TeamMember } from 'src/app/reppl/service';

// import ReactPlayer from 'react-player';
// import Carousel from 'nuka-carousel';
// import SvgNextSlide from 'src/components/icons/next-slide';
// import SvgPrevSlide from 'src/components/icons/prev-slide';
// import SvgMagnifying from 'src/components/icons/magnifying';
// import SvgNFTholder from 'src/components/icons/nft-placeholder';
// import SvgArrowLeft from 'src/components/icons/arrow-left';
// import SvgArrowRight from 'src/components/icons/arrow-right';
// import SvgFlag from 'src/components/icons/flag';
// import SvgCommentsGray from 'src/components/icons/comments-gray';
// import SvgEnthuseGray from 'src/components/icons/enthuse-gray';
import algolia from 'src/lib/algolia';
import { PopupCard } from 'src/app/article/popup-card';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { imageOptimize } from 'src/service/image';
import { useCreationListQuery } from 'src/service/creation';

// import ShowMoreText from 'react-show-more-text';
// import SvgEllipsis from 'src/components/icons/ellipsis';
// import SvgAddAvatar from 'src/components/icons/add-avatar';
// import SvgStartersPick from 'src/components/icons/starters-pick';
// import SvgBookmark from 'src/components/icons/bookmark';
// import SvgEpisodeCreators from 'src/components/icons/episode-creators';
// import SvgEllipse from 'src/components/icons/ellipse';
// import SvgEpisodeData from 'src/components/icons/episode-data';

import MintExplainerModal from 'src/app/asset/MintExplainerModal';
import { getTaggedMention } from 'src/app/article/utils';
import { userMention } from 'src/app/explore/service';
import { useEditor } from 'src/lib/editor';
import { useIntersectionObserver } from 'src/hooks';
import { BlockRenderer } from 'src/lib/editor/editor';

// import { AddToLabelModal } from '../label/components/add-to-label-modal';
import { getProfileFullNameOrUsername } from '../profile/utils';
import { CopyrightFooter } from '../reppl/copyright-footer';
import { StudioMoreFrom } from '../studio/components/studio-more-from';
// import { EpisodeMoreFrom } from '../reppl/episode/more-from';

import { Creator, CreatorRole } from './components/publisher/creators-grid';
import { ArticleSource } from './enum';
import { DetailCommentModal } from './detail-comment-modal';
// import { CollapsibleText } from './components/collapsible-text';

import section2Styles from './Post_detail_section2.module.scss';
import section3Styles from './Post_detail_section3.module.scss';
import styles from './Post_detail.module.scss';

export function ArticleDetail({
  id,
  article,
  publish,
  onEnthuse,
  isLoading,
  source,
  modal,
  handleModalChange,
  refetch,
  ...props
}: {
  id: string;
  article?: Article | FullArticle;
  author: Author;
  modal: UseDisclosureReturn;
  onEnthuse?: () => void;
  isLoading?: boolean;
  source: ArticleSource;
  handleModalChange?: (value: any) => void;
  refetch?: any;
  publish?: boolean; // TODO: for view episode, we should split component for view and preview
}) {
  /* eslint-disable */
  const router = useRouter();
  const labelModal = useDisclosure();
  const mintExplainerModal = useDisclosure();
  const [selectedArticle, setSelectedArticle] = useState<any>();
  const { user: me, isLogin } = useContext(UserContext) as UserContextType;
  const [isMyEpisode, setIsMyEpisode] = useState<any>(false);
  // const addToLabelModal = useDisclosure();
  const type = source === ArticleSource.REPPL ? 'EPISODE' : 'STUDIO';
  const commentRef = useRef<CommenterHandler>();
  const { mutateAsync: createComment } = useArticleCommentMutation(id);
  const { mutateAsync: updateComment } = useArticleEditCommentMutation(id);
  const { mutateAsync: enthuseComment } = useCommentEnthuseMutation(id);
  const { data: fetchedComent, refetch: refetchComment } = useCreationsComment(
    id
  );
  // const [collectData, setCollectData] = useState([]);
  const { rid, sid } = router.query;

  // const urlLoadingGif = '/images/assets/loading-gif2.gif';
  // const lightroomModal = useDisclosure();

  const isMobile = useIsMobile();
  const stickyButtonRef = useRef();

  const [showStickyButton, setShowStickyButton] = useState<boolean>(true);

  const isOwner = me.id === article?.author?.userID && isLogin;

  const contentId = useMemo(() => rid || sid || id, [id, rid, sid]);
  const { data: authorProjects } = useCreationListQuery({
    creationType: 'ALL',
    username: props?.author?.username,
    params: {
      pageNum: 0,
    },
  });
  const projects = authorProjects?.pages
    ?.flatMap((p) => p.data)
    ?.filter(
      (p) =>
        p.contentID !== contentId &&
        p.author.username === props?.author.username
    );

  useIntersectionObserver({
    target: stickyButtonRef,
    onIntersect: () => setShowStickyButton(false),
    onUnIntersect: () => setShowStickyButton(true),
    enabled: true,
  });

  useEffect(() => {
    if (me?.id === props?.author?.userID) {
      setIsMyEpisode(true);
    }
  }, [me, props]);

  useEffect(() => {
    if (router.asPath.includes('#')) {
      const command = router.asPath.split('#')[1];
      useCommand(command);
    }
  }, [router]);

  useEffect(() => {
    const command = window.history.state;
    if (command === 'comments') {
      useCommand(command);
    }
  }, []);

  const useCommand = (command) => {
    if (command === 'comments') {
      commentModal.onOpen();
    }
  };

  const mentionUsers = (mention) => {
    const taggedUsers = {
      userNames: getTaggedMention(mention.data),
      postID: mention.id,
      place: 'COMMENT',
    };

    userMention(taggedUsers);
  };

  // TODO: make it inside commenter instead
  const handleComment = ({
    event,
    blockId,
  }: {
    event: any;
    blockId?: string;
  }) => {
    const currValue = event.value;
    const currEvent = event.event;

    if (currValue !== '' && currEvent.key === 'Enter') {
      toComment({
        parentId: blockId,
        objectId: id,
        objectType: blockId ? 'COMMENT' : 'EPISODE',
        content: currValue,
      });
    }
  };

  const toComment = async (data) => {
    const createdComment = await createComment(data);
    if (createdComment.code === 1000) {
      mentionUsers({ data: createdComment.data, id: article.contentID });
      refetchComment();
    }
  };

  const commentModal = useDisclosure();

  const [activeSlide, setActiveSlide] = useState<any>({
    description: checkUploaded('description'),
    title: checkUploaded('title'),
    createdBy: checkUploaded('file'),
    index: 1,
  });
  const [activeLightroomSlide, setActiveLightroomSlide] = useState<any>({
    description: checkUploaded('description'),
    title: checkUploaded('title'),
    createdBy: checkUploaded('file'),
    index: 1,
  });

  useEffect(() => {
    // setActiveSlide({
    //   description: checkUploaded('description'),
    //   title: checkUploaded('title'),
    //   createdBy: checkUploaded('file'),
    //   index: 1,
    // });
    if (article.content?.uploadedFiles?.length > 0) {
      setActiveSlide({
        ...article.content.uploadedFiles[0],
        index: 1,
      });
      setActiveLightroomSlide({
        ...article.content.uploadedFiles[0],
        index: 1,
      });
    }
  }, [article]);

  let isEnthuse = false;
  let creators: Creator[] = [];
  let ownerID = props.author?.userID;
  const [isUser, setIsUser] = useState<any>();
  const [currReppl, setCurrReppl] = useState<any>('');
  useEffect(() => {
    setIsUser(article?.author?.username);
    setCurrReppl(article);
    if (typeof article.content === 'string' && article.content) {
      // eslint-disable-next-line no-param-reassign
      article.content = JSON.parse(article.content);
    }
  }, [article]);

  if (article && 'enthuseCount' in article && 'isEnthused' in article) {
    // enthuseCount = article.enthuseCount;
    isEnthuse = article.isEnthused;
    if (article?.coAuthors) {
      creators = [
        ...(article?.coAuthors.map((c) => ({
          ...c,
          role: CreatorRole.coAuthors,
        })) ?? []),
        ...(article?.contributors?.map((c) => ({
          ...c,
          role: CreatorRole.contributor,
        })) ?? []),
      ];
      ownerID = article.author?.userID;
    }
  }

  const [toolTip, setToolTip] = useState(false);
  const [projToolTip, setProjToolTip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ x: 0, y: 0 });
  const [currUser, setCurrUser] = useState<TeamMember>({});
  const [currProj, setCurrProj] = useState('');
  const [isShowMore, setIsShowMore] = useState(false);

  const { mutateAsync: followUser } = useFollowUserMutation([
    CacheKey.profile,
    currUser?.username,
  ]);

  const onFollowUser = async () => {
    try {
      await followUser({
        targetID: props?.author?.userID,
        group: 'USER',
        follow: !props?.author?.isFollowed,
      });
    } catch (err) {
      logger.error(err);
    }
  };

  const toolTipRef = useRef(null);
  const projToolTipRef = useRef(null);

  const executeOnClick = (isExpanded) => {
    setIsShowMore(isExpanded);
  };

  const checkIfContentParsed = (data) => {
    if (typeof data === 'string' && data) {
      // eslint-disable-next-line no-param-reassign
      return JSON.parse(data);
    }
    return data;
  };

  useEditor({
    editorId: `postDetail${article.contentID ? article.contentID : 'preview'}`,
    uniqueId: 'preview123',
    data: checkIfContentParsed(article.content),
    areaType: 'EPISODE',
    imgType: 'episode_img',
    readOnly: true,
  });

  async function attachToolTip(data) {
    const users = await getUser(data.innerHTML.substring(1));
    let proj;
    let isProject = false;
    const regTest = /\/space\/(.)*/;
    if (regTest.test(data.href)) {
      isProject = true;
      proj = await algolia.search(
        data.innerHTML.substring(1),
        30,
        'filterType:Project'
      );
    }
    data.addEventListener('mouseover', (e) => {
      if (users.data.length > 0 || proj) {
        if (isProject) {
          // setProjToolTip(true);
        } else {
          // setToolTip(true);
        }

        const posX = e.clientX;
        let posY = e.clientY;
        if (e.clientY > window.innerHeight / 2) {
          if (isProject) {
            posY -= projToolTipRef.current.offsetHeight;
          } else {
            posY -= toolTipRef.current.offsetHeight;
          }
        }

        if (isProject) {
          proj.forEach((list) => {
            if (list.objectID === data.href.split('reppl/')[1]) {
              setCurrProj(list.objectID);
            }
          });
        } else {
          setCurrUser(users.data[0]);
        }
        setToolTipPos({ x: posX, y: posY });
      }
    });
  }

  function hasId(data) {
    let has = false;
    if (data === 'toolTipUser' || data === 'toolTipProj') {
      has = true;
    }
    return has;
  }

  if (typeof document !== 'undefined') {
    const anchors = document
      .querySelectorAll('#studioForm')[0]
      ?.getElementsByTagName('a');
    for (let i = 0; i < anchors?.length; i++) {
      if (!anchors[i].hasAttribute('addListener')) {
        anchors[i].setAttribute('target', '_blank');
        anchors[i].classList.add('anchorLink');
        if (anchors[i].innerHTML.substring(0, 1) === '@') {
          attachToolTip(anchors[i]);
          const toolTipClass = document.querySelectorAll('.toolTip');
          toolTipClass.forEach((tooltip) => {
            tooltip?.addEventListener('mouseleave', () => {
              setToolTip(false);
              setProjToolTip(false);
            });
          });
          anchors[i].addEventListener('mouseleave', (e) => {
            let checkToElement = false;
            if ('toElement' in e) {
              checkToElement = true;
            }
            const toElement = checkToElement ? e['toElement'] : false;
            if (checkToElement && !hasId(toElement.parentNode.id)) {
              setToolTip(false);
              setProjToolTip(false);
            }
          });
          anchors[i].setAttribute('addListener', 'true');
        }
      }
    }
  }
  /* eslint-enable */

  // function callBackData(data) {
  //   setCollectData(data);
  // }
  function checkUploaded(filter) {
    if (
      article.content?.uploadedFiles &&
      article.content?.uploadedFiles.length > 0
    ) {
      if (filter === 'file') {
        if (article.content?.uploadedFiles[0]?.file?.createdBy) {
          return article.content?.uploadedFiles[0]?.file?.createdBy;
        }
      } else if (article.content?.uploadedFiles[0][filter]) {
        return article.content?.uploadedFiles[0][filter];
      }
      return null;
    }
    return null;
  }

  const linkCloser = (linkPath) => {
    modal.onClose();
    router.push(linkPath);
  };

  return (
    <div className={`post-detail ${cn(styles.group, styles.group_layout4)}`}>
      <div
        style={
          {
            '--src': `url(${require('public/assets_pxcode/3b179967d82b1ced32ebac18bd639573.svg')})`,
          } as React.CSSProperties
        }
        className={cn(styles.icon1, styles.icon1_layout)}
      />

      <main className={cn(styles.main, styles.main_layout)}>
        {/* {isOwner && (
          <div
            style={{ marginTop: '76px' }}
            className="authorship-section bg-gray-200 py-5 px-6 sm:flex sm:gap-2.5 md:py-0 justify-center"
          >
            <div className="text-xs font-medium text-gray-900 my-auto pb-2.5 sm:p-0 md:text-base text-center sm:text-left">
              As one of the authors, you have option of minting an Authorship
              Token to protect your ownership rights.
            </div>
            <div className="flex gap-2.5 justify-center">
              <div
                style={{ height: 'fit-content' }}
                className="font-medium text-blue-500 text-xs py-1.5 px-7 border border-blue-500 rounded-full md:my-5 cursor-pointer whitespace-nowrap"
              >
                Mint now
              </div>
              <div
                role="button"
                tabIndex={0}
                style={{ height: 'fit-content' }}
                className="font-medium text-blue-500 text-xs py-1.5 px-7 border border-blue-500 rounded-full md:my-5 cursor-pointer whitespace-nowrap"
                onClick={mintExplainerModal.onOpen}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    mintExplainerModal.onOpen();
                  }
                }}
              >
                Learn more
              </div>
            </div>
          </div>
        )} */}
        <div
          className={cn(
            styles.main_item,
            `fixed w-full bottom-6 z-20 ${!showStickyButton && 'hidden'} ${
              !article.contentID && 'hidden'
            }`
          )}
        >
          <StickyIconButtonList
            left="0"
            commentable={article.commentable}
            authorID={ownerID}
            refetch={refetch}
            authorUsername={article?.author?.username}
            disabled={article.status === 'UNPUBLISHED'}
            onCommentClick={commentModal.onOpen}
            commentCount={fetchedComent?.length || 0}
            // onSaveClick={() =>
            //   isLogin
            //     ? addToLabelModal.onOpen()
            //     : router.push({
            //         query: { ...router.query, page: 'signIn' },
            //       })
            // }
            contentID={article?.contentID}
            isEnthused={isEnthuse}
            articleType={type}
            // collection={collectData}
            data={article}
            isFixedTab={!isMobile}
            isFloater
          />
        </div>
        <div className={cn(styles.main_item)}>
          <div className={cn(styles.group, styles.group_layout3)}>
            <div className={cn(styles.block28, styles.block28_layout)}>
              <div className={cn(styles.block28_item)}>
                <div
                  style={
                    {
                      '--src': `url(${require('public/assets_pxcode/162b79c460592c01fb411a220b4a2a6e.png')})`,
                    } as React.CSSProperties
                  }
                  className={cn(styles.block29, styles.block29_layout)}
                />
              </div>
              <div className={cn(styles.block28_item1)}>
                <div className={cn(styles.block1, styles.block1_layout)}>
                  <div className={cn(styles.block1_item)}>
                    <div
                      style={
                        {
                          '--src': `url(${require('public/assets_pxcode/bc71cf55c6f43cb960463fff82aaf090.png')})`,
                        } as React.CSSProperties
                      }
                      className={cn(
                        styles.cover_block1,
                        styles.cover_block1_layout,
                        'sticky top-0 h-auto'
                      )}
                    >
                      <div
                        className={cn(styles.block19, styles.block19_layout)}
                      >
                        <div className={cn(styles.flex, styles.flex_layout)}>
                          <div className="font-semibold text-gray-700 text-xl">
                            Author
                          </div>
                          <div
                            onClick={() => {
                              linkCloser(`/${props?.author?.username}`);
                            }}
                            onKeyDown={(e) => {
                              if (e.code === 'Enter') {
                                linkCloser(`/${props?.author?.username}`);
                              }
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            <div className="relative cursor-pointer">
                              <Avatar
                                src={imageOptimize(
                                  props?.author?.profileImgURL
                                )}
                                name={props?.author?.username}
                                author={props?.author}
                                width={56}
                                height={56}
                                borderRadius="40"
                                wrapperProps={{
                                  m: 'auto',
                                }}
                                className={cn(styles.icon, styles.icon_layout)}
                              />
                              <div className="font-medium text-xl text-gray-700 py-2">
                                {getProfileFullNameOrUsername(props?.author)}
                              </div>
                            </div>
                          </div>
                          {props?.author?.motto &&
                            props?.author?.motto !== '' && (
                              <div className="font-medium text-sm text-gray-500">
                                {props?.author?.motto}
                              </div>
                            )}
                          {!isMyEpisode && publish && (
                            <div
                              className={cn(styles.flex_item)}
                              role="button"
                              tabIndex={0}
                              onClick={async () => {
                                await onFollowUser();
                                refetch();
                              }}
                              onKeyDown={async (e) => {
                                if (e.code === 'Enter') {
                                  await onFollowUser();
                                  refetch();
                                }
                              }}
                            >
                              <div
                                className={cn(
                                  styles.block2,
                                  styles.block2_layout
                                )}
                              >
                                <div
                                  className={cn(
                                    styles.text_body,
                                    styles.text_body_layout
                                  )}
                                >
                                  {props?.author?.isFollowed
                                    ? 'Following'
                                    : 'Follow'}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {!article?.isInDraftReppl &&
                          article?.classType === 'REPPL' && (
                            <div
                              className={cn(styles.group, styles.group_layout)}
                            >
                              <PublishIn reppl={currReppl?.sourceID} />
                            </div>
                          )}

                        {article?.coAuthors?.length > 0 && (
                          <>
                            <div
                              className={cn(
                                styles.block21,
                                styles.block21_layout
                              )}
                            >
                              <div className="font-semibold text-xl text-gray-700">
                                Co-authors
                              </div>
                              <div className="mt-4 overflow-hidden">
                                {article?.coAuthors
                                  ?.slice(0, 5)
                                  .map((coAuthor) => (
                                    <div
                                      onClick={() => {
                                        if (article.contentID) {
                                          linkCloser(`/${coAuthor?.username}`);
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        if (
                                          e.code === 'Enter' &&
                                          article.contentID
                                        ) {
                                          linkCloser(`/${coAuthor?.username}`);
                                        }
                                      }}
                                      role="button"
                                      tabIndex={0}
                                    >
                                      <div
                                        className={cn(
                                          styles.flex6,
                                          styles.flex6_layout
                                        )}
                                      >
                                        <div className={cn(styles.flex6_item)}>
                                          <Avatar
                                            src={imageOptimize(
                                              coAuthor?.profileImgURL
                                            )}
                                            name={coAuthor?.username}
                                            author={coAuthor}
                                            width={40}
                                            height={40}
                                            wrapperProps={{
                                              m: 'auto',
                                            }}
                                            borderRadius="40"
                                            className={cn(
                                              styles.icon1,
                                              styles.icon1_layout3
                                            )}
                                            border="1px solid #ffffff66"
                                            svgClassName="bg-unset"
                                          />
                                        </div>
                                        <div
                                          className={cn(styles.flex6_spacer)}
                                        />
                                        <div
                                          className={cn(
                                            styles.flex6_item1,
                                            'w-full break-all'
                                          )}
                                        >
                                          <div
                                            className={cn(
                                              styles.flex7,
                                              styles.flex7_layout
                                            )}
                                          >
                                            <div
                                              className={cn(
                                                styles.text_body1,
                                                styles.text_body1_layout3,
                                                'line-clamp-1'
                                              )}
                                            >
                                              {getProfileFullNameOrUsername(
                                                coAuthor
                                              )}
                                            </div>
                                            <div
                                              className={cn(
                                                styles.text_body23,
                                                styles.text_body23_layout,
                                                'hidden'
                                              )}
                                            >
                                              Photographer
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <Flex
                                flexDirection="row-reverse"
                                justifyContent="flex-end"
                                className="mt-4"
                              >
                                {article?.coAuthors?.slice(5).length === 1 && (
                                  <Avatar
                                    src={imageOptimize(
                                      article?.coAuthors?.slice(5)[0]
                                        ?.profileImgURL
                                    )}
                                    name={
                                      article?.coAuthors?.slice(5)[0]?.username
                                    }
                                    author={article?.coAuthors?.slice(5)[0]}
                                    width={40}
                                    height={40}
                                    wrapperProps={{
                                      m: 'auto',
                                    }}
                                    borderRadius="40"
                                    border="1px solid #ffffff66"
                                    svgClassName="bg-unset"
                                  />
                                )}

                                {article?.coAuthors?.slice(5).length > 1 && (
                                  <>
                                    <div
                                      style={
                                        {
                                          '--src': `url(${
                                            require('public/assets_pxcode/b0302db965dbcfb45eb76da17c427746.svg')
                                              .default
                                          })`,
                                        } as React.CSSProperties
                                      }
                                      className={cn(
                                        styles.block4,
                                        styles.block4_layout
                                      )}
                                    />
                                    {article?.coAuthors
                                      ?.slice(5)
                                      .slice(0, 3)
                                      ?.map((coAuthor) => {
                                        return (
                                          <div
                                            key={coAuthor?.userID}
                                            className="inline-block -mr-3.5"
                                          >
                                            <Avatar
                                              src={imageOptimize(
                                                coAuthor?.profileImgURL
                                              )}
                                              name={coAuthor?.username}
                                              author={coAuthor}
                                              width={40}
                                              height={40}
                                              wrapperProps={{
                                                m: 'auto',
                                              }}
                                              borderRadius="40"
                                              border="1px solid #ffffff66"
                                              svgClassName="bg-unset"
                                            />
                                          </div>
                                        );
                                      })}
                                  </>
                                )}
                              </Flex>
                            </div>
                          </>
                        )}

                        {article?.contributors?.length > 0 && (
                          <div
                            className={cn(
                              styles.block22,
                              styles.block22_layout
                            )}
                          >
                            <div className="font-semibold text-xl text-gray-700">
                              Contributor
                            </div>
                            <div className="mt-4 overflow-hidden">
                              {article?.contributors
                                ?.slice(0, 5)
                                .map((contributor) => (
                                  <div
                                    onClick={() => {
                                      if (article.contentID) {
                                        linkCloser(`/${contributor?.username}`);
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        e.code === 'Enter' &&
                                        article.contentID
                                      ) {
                                        linkCloser(`/${contributor?.username}`);
                                      }
                                    }}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    <div
                                      className={cn(
                                        styles.flex8,
                                        styles.flex8_layout
                                      )}
                                    >
                                      <div className={cn(styles.flex8_item)}>
                                        <Avatar
                                          src={imageOptimize(
                                            contributor?.profileImgURL
                                          )}
                                          name={contributor?.username}
                                          author={contributor}
                                          width={40}
                                          height={40}
                                          wrapperProps={{
                                            m: 'auto',
                                          }}
                                          className={cn(
                                            styles.icon1,
                                            styles.icon1_layout4
                                          )}
                                          borderRadius="40"
                                          border="1px solid #ffffff66"
                                          svgClassName="bg-unset"
                                        />
                                      </div>
                                      <div
                                        className={cn(styles.flex8_spacer)}
                                      />
                                      <div
                                        className={cn(
                                          styles.flex8_item1,
                                          'w-full break-all'
                                        )}
                                      >
                                        <div
                                          className={cn(
                                            styles.flex9,
                                            styles.flex9_layout
                                          )}
                                        >
                                          <div
                                            className={cn(
                                              styles.text_body1,
                                              styles.text_body1_layout4,
                                              'line-clamp-1'
                                            )}
                                          >
                                            {getProfileFullNameOrUsername(
                                              contributor
                                            )}
                                          </div>
                                          <div
                                            className={cn(
                                              styles.text_body24,
                                              styles.text_body24_layout,
                                              'hidden'
                                            )}
                                          >
                                            Image post-production
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                            <Flex
                              flexDirection="row-reverse"
                              justifyContent="flex-end"
                              className="mt-4"
                            >
                              {article?.contributors?.slice(5).length === 1 && (
                                <Avatar
                                  src={imageOptimize(
                                    article?.contributors?.slice(5)[0]
                                      ?.profileImgURL
                                  )}
                                  name={
                                    article?.contributors?.slice(5)[0]?.username
                                  }
                                  author={article?.contributors?.slice(5)[0]}
                                  width={40}
                                  height={40}
                                  wrapperProps={{
                                    m: 'auto',
                                  }}
                                  borderRadius="40"
                                  border="1px solid #ffffff66"
                                  svgClassName="bg-unset"
                                />
                              )}

                              {article?.contributors?.slice(5).length > 1 && (
                                <>
                                  <div
                                    style={
                                      {
                                        '--src': `url(${
                                          require('public/assets_pxcode/b0302db965dbcfb45eb76da17c427746.svg')
                                            .default
                                        })`,
                                      } as React.CSSProperties
                                    }
                                    className={cn(
                                      styles.block4,
                                      styles.block4_layout
                                    )}
                                  />
                                  {article?.contributors
                                    ?.slice(5)
                                    .slice(0, 3)
                                    ?.map((contributor) => {
                                      return (
                                        <div
                                          key={contributor?.userID}
                                          className="inline-block -mr-4"
                                        >
                                          <Avatar
                                            src={imageOptimize(
                                              contributor?.profileImgURL
                                            )}
                                            name={contributor?.username}
                                            author={contributor}
                                            width={40}
                                            height={40}
                                            wrapperProps={{
                                              m: 'auto',
                                            }}
                                            borderRadius="40"
                                            border="1px solid #ffffff66"
                                            svgClassName="bg-unset"
                                          />
                                        </div>
                                      );
                                    })}
                                </>
                              )}
                            </Flex>
                          </div>
                        )}

                        {arrayHasData(article?.categories) && (
                          <>
                            <div
                              className={cn(
                                styles.block23,
                                styles.block23_layout
                              )}
                            >
                              <div className="font-semibold text-xl text-gray-700">
                                Categories
                              </div>
                              <div
                                className={cn(
                                  styles.flex18,
                                  styles.flex18_layout
                                )}
                              >
                                {article.categories.map((s) => (
                                  <div
                                    className={cn(styles.flex18_item)}
                                    key={s}
                                    role="button"
                                    onClick={() => {
                                      if (router.pathname === '/feed/[tribe]') {
                                        modal.onClose();
                                      }
                                      const name = s.replace(/ /g, '-');
                                      router.push(`/feed/${name}`);
                                    }}
                                    onKeyDown={() => {
                                      const name = s.replace(/ /g, '-');
                                      router.push(`/feed/${name}`);
                                    }}
                                    tabIndex={0}
                                  >
                                    <div
                                      className={cn(
                                        styles.block5,
                                        styles.block5_layout
                                      )}
                                    >
                                      <div
                                        className={cn(
                                          styles.text_body3,
                                          styles.text_body3_layout
                                        )}
                                      >
                                        {s}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}

                        {arrayHasData(article?.tags) && (
                          <>
                            <div
                              className={cn(
                                styles.block24,
                                styles.block24_layout
                              )}
                            >
                              <div className="font-semibold text-xl text-gray-700">
                                Hashtags
                              </div>
                              <div
                                className={cn(
                                  styles.flex19,
                                  styles.flex19_layout
                                )}
                              >
                                {article.tags.map((s) => (
                                  <div
                                    className={cn(styles.flex19_item)}
                                    key={s}
                                    role="button"
                                    onClick={() => {
                                      if (router.pathname === '/feed/[tribe]') {
                                        modal.onClose();
                                      }
                                      const name = s.replace(/ /g, '-');
                                      router.push(`/feed/${name}`);
                                    }}
                                    onKeyDown={() => {
                                      const name = s.replace(/ /g, '-');
                                      router.push(`/feed/${name}`);
                                    }}
                                    tabIndex={0}
                                  >
                                    <div
                                      className={cn(
                                        styles.block8,
                                        styles.block8_layout
                                      )}
                                    >
                                      <div
                                        className={cn(
                                          styles.text_body3,
                                          styles.text_body3_layout
                                        )}
                                      >
                                        #{s}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}

                        <div className={cn(styles.flex1, styles.flex1_layout)}>
                          <div className="font-semibold text-xl text-gray-700">
                            Copyright
                          </div>
                          <div className={cn(styles.flex1_item)}>
                            <div
                              className={cn(
                                styles.flex20,
                                styles.flex20_layout
                              )}
                            >
                              <Copyright
                                copyright={article?.copyright}
                                article={currReppl}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={cn(styles.block1_item1)}>
                    <div
                      style={
                        {
                          '--src': `url(${
                            require('public/assets_pxcode/ce47e5bdf01e9bd44e115ea0ea183f33.png')
                              .default
                          })`,
                        } as React.CSSProperties
                      }
                      className={cn(styles.image2, styles.image2_layout)}
                    />
                  </div>
                  {/* <div className={cn(styles.block1_spacer)} /> */}
                  <div className={cn(styles.block1_item2)}>
                    <div className={cn(styles.group, styles.group_layout2)}>
                      <div className={cn(styles.flex22, styles.flex22_layout)}>
                        <div className={cn(styles.flex22_item)}>
                          <div
                            className={cn(styles.flex16, styles.flex16_layout)}
                          >
                            <div className={cn(styles.flex16_item)}>
                              <div
                                className={cn(
                                  styles.flex12,
                                  styles.flex12_layout1
                                )}
                              >
                                <div className={cn(styles.flex12_item)}>
                                  <Avatar
                                    src={imageOptimize(
                                      props?.author?.profileImgURL
                                    )}
                                    borderRadius="40"
                                    name={props?.author?.username}
                                    author={props?.author}
                                    className={cn(
                                      styles.icon1,
                                      styles.icon1_layout11
                                    )}
                                  />
                                </div>
                                <div className={cn(styles.flex12_spacer)} />
                                <div className={cn(styles.flex12_item2)}>
                                  <div
                                    className={cn(
                                      styles.flex13,
                                      styles.flex13_layout
                                    )}
                                  >
                                    <div
                                      className={cn(
                                        styles.text_body1,
                                        styles.text_body1_layout8
                                      )}
                                    >
                                      {getProfileFullNameOrUsername(
                                        props?.author
                                      )}
                                    </div>
                                    <div
                                      className={cn(
                                        styles.text_body28,
                                        styles.text_body28_layout
                                      )}
                                    >
                                      Author
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={cn(styles.flex16_item1)}>
                              <h1
                                className={cn(
                                  styles.hero_title,
                                  styles.hero_title_layout
                                )}
                              >
                                {article?.title}
                              </h1>
                            </div>
                            {article?.createdAt && (
                              <div className={cn(styles.flex16_item2)}>
                                <div
                                  className={cn(
                                    styles.text_body29,
                                    styles.text_body29_layout
                                  )}
                                >
                                  {dayHepler.localizedFormat(
                                    article.createdAt,
                                    'll'
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mt-10">
                          <BlockRenderer blockData={article.content} readOnly />
                        </div>

                        {article.description && (
                          <>
                            <div
                              style={{ height: '44px' }}
                              className="w-full flex justify-center relative"
                            >
                              <span
                                style={{ color: '#C4C4C4', fontSize: '43px' }}
                                className="absolute"
                              >
                                . . .
                              </span>
                            </div>
                            <div className={cn(styles.flex22_item)}>
                              <div
                                className={cn(
                                  styles.flex17,
                                  styles.flex17_layout
                                )}
                              >
                                <div className={cn(styles.flex17_item)}>
                                  <div
                                    className={cn(
                                      styles.text_body212,
                                      styles.text_body212_layout
                                    )}
                                  >
                                    A few words from{' '}
                                    {getProfileFullNameOrUsername(
                                      props?.author
                                    )}
                                  </div>
                                </div>

                                <div
                                  className={cn(
                                    styles.block13,
                                    styles.block13_layout
                                  )}
                                >
                                  <h1
                                    className={cn(
                                      styles.hero_title1,
                                      styles.hero_title1_layout
                                    )}
                                  >
                                    “
                                  </h1>
                                  <div className={cn(styles.block13_spacer)} />
                                  <div className={cn(styles.block13_item)}>
                                    <h3
                                      className={cn(
                                        styles.subtitle,
                                        styles.subtitle_layout
                                      )}
                                    >
                                      {article.description}
                                    </h3>
                                  </div>
                                  <div className={cn(styles.block13_spacer)} />
                                  <h1
                                    className={cn(
                                      styles.hero_title1,
                                      styles.hero_title1_layout
                                    )}
                                  >
                                    ”
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={cn(styles.block28_spacer)} />
            </div>
          </div>
        </div>
        <Box
          ref={toolTipRef}
          id="toolTipUser"
          className="toolTip"
          position="fixed"
          top={`${toolTipPos.y}px`}
          left={`${toolTipPos.x}px`}
          zIndex="99"
          width="326px"
          display={toolTip ? 'inherit' : 'none'}
        >
          <MemberCard
            isTooltip
            hideSettings
            isLoaded
            info={currUser}
            onFollow={async () => {
              try {
                followUser({
                  targetID: currUser?.id,
                  group: 'USER',
                  follow: !currUser?.isFollowed,
                });
              } catch (err) {
                logger.error(err);
              }
            }}
          />
        </Box>
        <Box
          ref={projToolTipRef}
          id="toolTipProj"
          className="toolTip"
          position="fixed"
          top={`${toolTipPos.y}px`}
          left={`${toolTipPos.x}px`}
          zIndex="99"
          width="326px"
          display={projToolTip ? 'block' : 'none'}
        >
          {currProj && <PopupCard rid={currProj} />}
        </Box>
        {/* <AddToLabelModal
          {...addToLabelModal}
          areaID={String(me?.id)}
          areaType="STUDIO"
          collectionItem={article}
          collectionData={(e) => callBackData(e)}
          dataChecker={collectData}
        />
        <AddToLabelModal
          {...labelModal}
          areaID={`${rid}`}
          areaType="REPPL"
          collectionItem={selectedArticle}
          onClose={() => {
            labelModal.onClose();
            setSelectedArticle(undefined);
          }}
        /> */}
        <DetailCommentModal id="detailComment" modal={commentModal}>
          <Box className="w-full" maxW="704px">
            <div>
              {article?.commentable && me && (
                <div className="px-0 w-full commenter">
                  <Commenter
                    disabled={!publish}
                    ref={commentRef}
                    user={me}
                    editorIndex="editorDetail"
                    onKeyDown={(event) => handleComment({ event })}
                  />
                </div>
              )}
              {'comments' in article && (
                <div className="px-0">
                  <ArticleComment
                    comments={fetchedComent || []}
                    modalId="detailComment"
                    onComment={({ blockId, value, commentID }) => {
                      if (commentID) {
                        updateComment({
                          commentID,
                          Assets: [],
                          content: value,
                          parentId: blockId,
                        });
                        refetchComment();
                      } else {
                        createComment({
                          objectId: id,
                          objectType: blockId ? 'COMMENT' : 'EPISODE',
                          parentId: blockId,
                          content: value,
                        });
                        refetchComment();
                      }
                    }}
                    onEnthuse={({
                      targetId,
                      targetParentId,
                      targetType,
                      isEnthused,
                      targetOwnerId,
                    }) => {
                      enthuseComment({
                        objectId: targetId,
                        objectParentId: targetParentId,
                        objectType: targetType,
                        enthuse: !isEnthused,
                        ownerID: targetOwnerId,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </Box>
        </DetailCommentModal>
        <div
          ref={stickyButtonRef}
          className={cn(
            styles.main_item,
            'z-5',
            `${!article.contentID && 'hidden'}`,
            `${showStickyButton && 'opacity-0'}`
          )}
        >
          <StickyIconButtonList
            left="0"
            commentable={article.commentable}
            authorID={ownerID}
            refetch={refetch}
            disabled={article.status === 'UNPUBLISHED'}
            authorUsername={article?.author?.username}
            onCommentClick={commentModal.onOpen}
            commentCount={fetchedComent?.length || 0}
            // onSaveClick={() =>
            //   isLogin
            //     ? addToLabelModal.onOpen()
            //     : router.push({
            //         query: { ...router.query, page: 'signIn' },
            //       })
            // }
            contentID={article?.contentID}
            isEnthused={isEnthuse}
            articleType={type}
            // collection={collectData}
            data={article}
            isFixedTab={!isMobile}
          />
        </div>
        {projects?.length > 0 && (
          <div
            className={cn(
              styles.main_item,
              'z-10',
              `${!article.contentID && 'hidden'}`
            )}
          >
            <RenderSection3
              projects={projects}
              handleModalChange={handleModalChange}
              isUser={isUser}
              author={props.author}
            />
          </div>
        )}
      </main>
      <MintExplainerModal
        closeModal={() => mintExplainerModal.onClose()}
        isOpen={mintExplainerModal.isOpen}
      />
    </div>
  );
}

export function renderSection2() {
  return (
    <section
      className={cn(section2Styles.section2, section2Styles.section2_layout)}
    >
      <div
        className={cn(section2Styles.block14, section2Styles.block14_layout)}
      >
        <hr className={cn(section2Styles.line, section2Styles.line_layout)} />

        <div
          className={cn(section2Styles.block15, section2Styles.block15_layout)}
        >
          <hr
            className={cn(section2Styles.line1, section2Styles.line1_layout)}
          />

          <div
            className={cn(section2Styles.flex28, section2Styles.flex28_layout)}
          >
            <div
              className={cn(
                section2Styles.flex26,
                section2Styles.flex26_layout
              )}
            >
              <div className={cn(section2Styles.flex26_item)}>
                <div
                  style={
                    {
                      '--src': `url(${
                        require('public/assets_pxcode/e2afc5e005775031464d182f1b7a1e7d.svg')
                          .default
                      })`,
                    } as React.CSSProperties
                  }
                  className={cn(
                    section2Styles.image6,
                    section2Styles.image6_layout
                  )}
                />
              </div>
              <div className={cn(section2Styles.flex26_spacer)} />
              <div
                className={cn(
                  section2Styles.paragraph_body,
                  section2Styles.paragraph_body_layout
                )}
              >
                102
              </div>
            </div>

            <div className={cn(section2Styles.flex28_spacer)} />

            <div
              className={cn(
                section2Styles.flex27,
                section2Styles.flex27_layout
              )}
            >
              <div className={cn(section2Styles.flex27_item)}>
                <div
                  style={
                    {
                      '--src': `url(${
                        require('public/assets_pxcode/46e8a26b2b87556081a45b7da1bcb941.svg')
                          .default
                      })`,
                    } as React.CSSProperties
                  }
                  className={cn(
                    section2Styles.icon4,
                    section2Styles.icon4_layout
                  )}
                />
              </div>
              <div className={cn(section2Styles.flex27_spacer)} />
              <div
                className={cn(
                  section2Styles.paragraph_body,
                  section2Styles.paragraph_body_layout
                )}
              >
                35
              </div>
            </div>

            <div className={cn(section2Styles.flex28_spacer)} />
            <div
              style={
                {
                  '--src': `url(${
                    require('public/assets_pxcode/39ad4dc2fc5c564a9d73103a03a2de97.svg')
                      .default
                  })`,
                } as React.CSSProperties
              }
              className={cn(
                section2Styles.image7,
                section2Styles.image7_layout
              )}
            />
            <div className={cn(section2Styles.flex28_spacer)} />
            <div
              style={
                {
                  '--src': `url(${
                    require('public/assets_pxcode/f642972ed222fd7b02b5494125465273.svg')
                      .default
                  })`,
                } as React.CSSProperties
              }
              className={cn(
                section2Styles.image15,
                section2Styles.image15_layout
              )}
            />
            <div className={cn(section2Styles.flex28_spacer)} />
            <div
              style={
                {
                  '--src': `url(${
                    require('public/assets_pxcode/f9a8eda469aeee04f6e432309e27bb6f.svg')
                      .default
                  })`,
                } as React.CSSProperties
              }
              className={cn(
                section2Styles.image9,
                section2Styles.image9_layout
              )}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export function RenderSection3({
  projects,
  handleModalChange,
  isUser,
  author,
}: {
  projects: any;
  handleModalChange?: (value: any) => void;
  isUser?: any;
  author?: any;
}) {
  return (
    <section
      className={cn(section3Styles.section3, section3Styles.section3_layout)}
    >
      <div
        className={cn(section3Styles.block16, section3Styles.block16_layout)}
      >
        <div
          className={cn(section3Styles.block34, section3Styles.block34_layout)}
        >
          <div
            className={cn(section3Styles.flex29, section3Styles.flex29_layout)}
          >
            <div className={cn(section3Styles.flex29_item)}>
              <h3
                className={cn(
                  section3Styles.subtitle1,
                  section3Styles.subtitle1_layout
                )}
              >
                More from this creator
              </h3>
            </div>
            <div
              className={cn(
                section3Styles.paragraph_body2,
                section3Styles.paragraph_body2_layout,
                'mb-6'
              )}
            >
              {author?.motto}
            </div>

            <StudioMoreFrom
              background="#191919"
              type="carousel"
              author={isUser}
              projects={projects}
              modalChange={handleModalChange}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function Copyright(props: { copyright?: string; article?: any }) {
  // if (!props.article?.sourceID && !props.article?.contentID) {
  //   return null;
  // }

  return <CopyrightFooter copyright={props.copyright} />;
}
