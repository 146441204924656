import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localized from 'dayjs/plugin/localizedFormat';

const fromNow = (date, withoutSuffix = false) => {
  if (!date) return date;

  dayjs.extend(relativeTime);
  return dayjs(date).fromNow(withoutSuffix);
};
const fromNowDays = (date, withoutSuffix = false) => {
  if (!date) return date;

  dayjs.extend(relativeTime);
  const format = dayjs(date).fromNow(withoutSuffix).split(' ')[1];
  const number =
    dayjs(date).fromNow(withoutSuffix).split(' ')[0] === 'an' ||
    dayjs(date).fromNow(withoutSuffix).split(' ')[0] === 'a'
      ? '1'
      : dayjs(date).fromNow(withoutSuffix).split(' ')[0];
  if (format === 'second' || format === 'seconds' || format === 'few') {
    return 'now';
  }
  if (format === 'minute' || format === 'minutes') {
    return `${number}m`;
  }
  if (format === 'hour' || format === 'hours') {
    return `${number}h`;
  }
  if (format === 'day' || format === 'days') {
    return `${number}d`;
  }
  if (format === 'month' || format === 'months') {
    return `${number}m`;
  }
  if (format === 'year' || format === 'years') {
    return `${number}y`;
  }
};
const format = (date, formatString) => {
  if (!date) return date;
  return dayjs(date).format(formatString);
};
const localizedFormat = (date, formatString) => {
  if (!date) return date;
  dayjs.extend(localized);
  return dayjs(date).format(formatString);
};
const day = {
  fromNow,
  fromNowDays,
  format,
  localizedFormat,
};

export default day;
