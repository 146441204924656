import * as React from 'react';
import { Svg } from '../svg';

function SvgNotCheckedCircle({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle
        cx={10}
        cy={10}
        r={9}
        fill="#fff"
        stroke="#D3D6D8"
        strokeWidth={2}
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNotCheckedCircle);
export default ForwardRef;
