import {
  Modal,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { ElementRef, useEffect, useRef, useState } from 'react';
import * as ga from 'src/lib/ga';
import SvgCloseModal from 'src/components/icons/close-modal';
import { useAssetFullQuery } from 'src/app/asset/hooks/use-asset-query';
import { NftDetail, CreativeAsset } from 'src/app/asset/detail';
import { fetchMetaData, fetchNftOwners } from 'src/hooks/use-alchemy';
import { useRouter } from 'next/router';

export function AssetModal({
  defaultAsset,
  modal,
  currentLocation,
  draft,
  isLazy,
  refetchAsset,
  isLoading,
}: {
  defaultAsset?: any;
  modal: UseDisclosureReturn;
  currentLocation?: string;
  draft?: boolean;
  isLazy?: boolean;
  refetchAsset: () => void;
  isLoading?: any;
}) {
  const modalRef = useRef<ElementRef<typeof ModalContent>>();
  const router = useRouter();
  function closeModal() {
    if (!draft) {
      if (currentLocation === window.location.href) {
        router.push('/feed');
      } else {
        window.history.pushState('', '', `${currentLocation || '../feed'}`);
      }
    }
    modal.onClose();
  }

  useEffect(() => {
    const handlePopState = () => {
      if (modal.isOpen) {
        modal.onClose();
      }
    };
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [modal]);

  useEffect(
    function scrollToTopWhenOpeningModal() {
      window.scrollTo({
        top: window.scrollY,
      });
      if (modalRef.current) {
        modalRef.current.scrollTop = 0;
      }
    },
    [modalRef]
  );

  return (
    <Modal
      isCentered
      autoFocus={false}
      {...modal}
      blockScrollOnMount
      motionPreset="none"
    >
      <ModalOverlay>
        {Object.keys(defaultAsset).length > 0 && (
          <ModalContent
            ref={modalRef}
            borderRadius={0}
            width="100vw"
            maxWidth="100vw"
            h="100vh"
            paddingBottom={{ base: '58px', sm: '58px', lg: '0' }}
            opacity={isLoading ? '0' : '100'}
            overflowX="hidden"
          >
            <button
              type="button"
              className="p-2.5 fixed top-6 right-6 z-50"
              style={{
                background: 'rgba(36, 37, 38, 0.3)',
                borderRadius: '22px',
              }}
              onClick={() => {
                closeModal();
              }}
            >
              <SvgCloseModal fontSize="24px" />
            </button>
            {isLazy ? (
              <CreativeAsset nft={defaultAsset} isLazy />
            ) : (
              <NftDetail defaultAsset={defaultAsset} refetch={refetchAsset} />
            )}
          </ModalContent>
        )}
      </ModalOverlay>
    </Modal>
  );
}

export function AssetDetailModal({
  modal,
  defaultAsset,
  currentLocation,
  draft = false,
  id,
  contractAddress,
  tokenID,
}: {
  modal: UseDisclosureReturn;
  defaultAsset?: any;
  currentLocation?: string;
  draft?: boolean;
  id?: string;
  contractAddress?: string;
  tokenID?: string;
}) {
  const [currNFTData, setNFTData] = useState<any>({});
  const { data: fetchedAsset, refetch, isLoading } = useAssetFullQuery(
    id || ''
  );

  useEffect(() => {
    async function NFTData() {
      const fetchedNFT = await fetchMetaData(contractAddress, tokenID);
      const fetchedTotalOwners = await fetchNftOwners(contractAddress, tokenID);
      setNFTData({
        ...fetchedNFT,
        token_address: fetchedNFT.contract.address,
        token_id: fetchedNFT.tokenId,
        totalOwners: fetchedTotalOwners.owners.length,
        owner:
          fetchedTotalOwners.owners.length === 1
            ? fetchedTotalOwners.owners[0]
            : '',
      });
    }

    if (contractAddress && tokenID) {
      NFTData();
    }
  }, [contractAddress, tokenID]);

  useEffect(() => {
    if (fetchedAsset) {
      setNFTData(fetchedAsset);
    } else {
      setNFTData({});
    }
  }, [fetchedAsset, id]);

  useEffect(() => {
    if (!contractAddress && !tokenID && !id) {
      const data = {
        title: 'Creative Asset',
        location: `/asset/${id || `${contractAddress}:${tokenID}`}`,
        path: `/asset/${id || `${contractAddress}:${tokenID}`}`,
      };
      ga.manualpageview(data);
    }
  }, [contractAddress, tokenID, id]);

  return (
    <AssetModal
      // @ts-ignore
      isLazy={!(id || tokenID)}
      isLoading={isLoading}
      modal={modal}
      defaultAsset={defaultAsset || currNFTData}
      currentLocation={currentLocation}
      draft={draft}
      refetchAsset={refetch}
    />
  );
}
