import * as React from 'react';
import { Svg } from '../svg';

function SvgMessage({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        clipPath="url(#Message_svg__clip0_7477_79357)"
        stroke="#4F5356"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M18.333 1.667l-9.166 9.166M18.333 1.667L12.5 18.332l-3.334-7.5-7.5-3.333 16.667-5.833z" />
      </g>
      <defs>
        <clipPath id="Message_svg__clip0_7477_79357">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgMessage);
export default ForwardRef;
