import * as React from 'react';
import { Svg } from '../svg';

function SvgLogo({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 58 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.108.4V20H3.5V5.384H.14V.4h9.968zm8.847 5.124h-5.74V.4h18.088v5.124h-5.74V20h-6.608V5.524zM51.92 20l-.056-8.932-4.256 7.168h-2.912l-4.256-6.86V20h-6.02V.4h5.432l6.384 10.444L52.451.4h5.432L57.94 20h-6.02z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgLogo);
export default ForwardRef;
