import { createStandaloneToast } from '@chakra-ui/react';
import SvgWarning3 from 'src/components/icons/svg-warning3';
import SvgClearCircle from 'src/components/icons/clear-circle';

export const standaloneToast = createStandaloneToast();

export const CustomToast = ({
  icon,
  title,
  description,
  onClose,
}: {
  icon?: string;
  title: string;
  description?: string;
  onClose?: () => void;
}) => {
  return (
    <div
      style={{ width: '436px', height: '76px' }}
      className="relative text-sm border p-4 rounded-lg bg-white flex"
    >
      <div
        className="absolute top-4 right-4 cursor-pointer"
        role="button"
        tabIndex={0}
        onClick={() => onClose()}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            onClose();
          }
        }}
      >
        <SvgClearCircle className="text-gray-400" />
      </div>
      <div className="pr-2">
        {icon === 'error' && <SvgWarning3 height="20px" width="20px" />}
      </div>
      <div className="pr-4">
        <div
          style={{ lineHeight: '18px', paddingBottom: '4px' }}
          className="font-bold text-gray-700"
        >
          {title}
        </div>
        <div
          style={{ lineHeight: '20px' }}
          className="font-medium text-gray-500"
        >
          {description}
        </div>
      </div>
    </div>
  );
};
