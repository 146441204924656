import * as React from 'react';
import { Svg } from '../svg';

function SvgCheck({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.523 15.172l9.603-9.193 1.478 1.414L10.523 18l-6.65-6.364 1.478-1.414 5.172 4.95z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCheck);
export default ForwardRef;
