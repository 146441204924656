import * as React from 'react';
import { Svg } from '../svg';

function SvgTrello({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#trello_svg__clip0_4815_67675)">
        <path
          d="M22.406 0H3.094A2.344 2.344 0 00.75 2.344v19.312A2.344 2.344 0 003.094 24h19.312a2.344 2.344 0 002.344-2.344V2.344A2.344 2.344 0 0022.406 0z"
          fill="url(#trello_svg__paint0_linear_4815_67675)"
        />
        <path
          d="M20.505 3.12h-5.07c-.621 0-1.125.504-1.125 1.125v8.25c0 .621.504 1.125 1.125 1.125h5.07c.621 0 1.125-.504 1.125-1.125v-8.25c0-.621-.504-1.125-1.125-1.125zM10.065 3.12h-5.07c-.621 0-1.125.504-1.125 1.125v14.25c0 .621.504 1.125 1.125 1.125h5.07c.621 0 1.125-.504 1.125-1.125V4.245c0-.621-.504-1.125-1.125-1.125z"
          fill="#fff"
        />
      </g>
      <defs>
        <linearGradient
          id="trello_svg__paint0_linear_4815_67675"
          x1={12.75}
          y1={0}
          x2={12.75}
          y2={24}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0091E6" />
          <stop offset={1} stopColor="#0079BF" />
        </linearGradient>
        <clipPath id="trello_svg__clip0_4815_67675">
          <path fill="#fff" transform="translate(.75)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTrello);
export default ForwardRef;
