import * as React from 'react';
import { Svg } from '../svg';

function SvgPostUnsplash({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M6.023 35.5c-2.494 0-4.523-1.995-4.523-4.448V13.886c0-2.453 2.029-4.448 4.523-4.448h3.955a.5.5 0 00.489-.397l.547-2.594c.36-1.708 1.964-2.947 3.813-2.947H25.17c1.85 0 3.454 1.24 3.815 2.948l.547 2.592a.5.5 0 00.489.397h3.955c2.494 0 4.523 1.996 4.523 4.448v17.166c0 2.453-2.029 4.448-4.523 4.448H6.023v.001z"
        fill="#DFF0FE"
      />
      <path
        d="M25.171 4c1.615 0 3.014 1.073 3.325 2.551l.547 2.593a1 1 0 00.978.794h3.955c2.218 0 4.023 1.771 4.023 3.948v17.166C38 33.229 36.195 35 33.977 35H6.023C3.805 35 2 33.229 2 31.052V13.886c0-2.177 1.805-3.948 4.023-3.948h3.955a.999.999 0 00.978-.794l.547-2.594C11.815 5.073 13.214 4 14.828 4h10.343zm0-1H14.828c-2.092 0-3.893 1.4-4.303 3.344l-.547 2.594H6.023C3.249 8.938 1 11.153 1 13.886v17.166C1 33.785 3.249 36 6.023 36h27.954C36.751 36 39 33.785 39 31.052V13.886c0-2.733-2.249-4.948-5.023-4.948h-3.955l-.547-2.593C29.065 4.4 27.263 3 25.171 3z"
        fill="#4788C7"
      />
      <path d="M20 13.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17z" fill="#98CCFD" />
      <path
        d="M20 14c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8zm0-1a9 9 0 00-9 9 9 9 0 009 9 9 9 0 009-9 9 9 0 00-9-9z"
        fill="#4788C7"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPostUnsplash);
export default ForwardRef;
