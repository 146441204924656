import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';

export function useModal<T>() {
  const [selected, setSelected] = useState<T>();

  const disclosure = useDisclosure({
    onClose: () => setSelected(undefined),
  });

  return {
    selected,
    onSelect: (s?: T) => {
      setSelected(s);
      if (s) {
        disclosure.onOpen();
      } else {
        disclosure.onClose();
      }
    },
    modal: disclosure,
  };
}
