import * as React from 'react';
import { Svg } from '../svg';

function SvgCamera({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.083 3.333H7.916l-2.083 2.5h-2.5A1.667 1.667 0 001.666 7.5V15a1.667 1.667 0 001.667 1.667h13.334A1.667 1.667 0 0018.332 15V7.5a1.667 1.667 0 00-1.666-1.667h-2.5l-2.084-2.5z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.334a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCamera);
export default ForwardRef;
