import { FILTER_NAME } from '../filter/constants';

function updateFilter(state, { name, value, groupId }) {
  const filterTypeName = FILTER_NAME[name];

  if (filterTypeName === FILTER_NAME.SKILLS) {
    const isUpdateSkillGroup = !(groupId < 0);
    const isUpdateSkill = !(value < 0);

    if (!(isUpdateSkillGroup || isUpdateSkill)) {
      /** reset filter skills */
      return { ...state, skills: {} };
    }

    const skills = { ...state.skills };

    if (isUpdateSkillGroup) {
      const hasSkillGroup = state.skills[groupId];

      if (hasSkillGroup) {
        if (!isUpdateSkill) {
          /** reset skill group */
          skills[groupId] = [];
        } else {
          const skillIndex = state.skills[groupId].indexOf(value);
          if (skillIndex < 0) {
            /** add value in skill group */
            skills[groupId] = [...skills[groupId], value];
          } else {
            /** remove value in skill group */
            skills[groupId].splice(skillIndex, 1);
          }
        }
      } else {
        skills[groupId] = [value];
      }
    }

    const withoutEmptyGroupSkills = Object.entries(
      skills as Record<number, number[]>
    ).reduce((acc, [skillGroupId, group]) => {
      if (group.length > 0) {
        return { ...acc, [skillGroupId]: group };
      }
      return { ...acc };
    }, {});

    return { ...state, skills: withoutEmptyGroupSkills };
  }

  const newFilter = [];
  if (!(value < 0)) {
    newFilter.push(...state[filterTypeName]);

    const dataIndex = state[filterTypeName].indexOf(value);
    if (dataIndex < 0) {
      /** add value in filter */
      newFilter.push(value);
    } else {
      /** remove value in filter */
      newFilter.splice(dataIndex, 1);
    }
  }

  return { ...state, [filterTypeName]: newFilter };
}

export default updateFilter;
