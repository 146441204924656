import {
  Flex,
  Badge,
  AvatarBadgeProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import { DropzoneState, useDropzone } from 'react-dropzone';
import * as React from 'react';

import { isBrowser } from 'src/constants/env';

import { LIMIT_UPLOAD_IMAGE_FILE_ACCEPT } from 'src/constants/limit';
import SvgCamera from 'src/components/icons/camera';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import {
  ImageAvatarCropModal,
  useImageCropModal,
} from '../image-crop/image-crop';

export interface AvatarUploadProps extends AvatarBadgeProps {
  /** indicate uploading  */
  loading?: boolean;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  cropper: ReturnType<typeof useAvatarUpload>['cropper'];
  onAvatarSubmit: (file: File) => void;
  dropzone: DropzoneState;
  chldren?: React.ReactNode;
}

/**
 * @description the hook just simplify all the `ref` and event props, takes a callback when avatar submit with a File object.
 * In general, `onAvatarSubmit` can just be a function like (file) => fetch('/image', file)
 */
export function useAvatarUpload(
  /** file when a file from input submitted */
  onAvatarSubmit: (file: File) => void
) {
  const cropperSize = useBreakpointValue({
    base: {
      border: [
        isBrowser()
          ? (window?.innerWidth -
              160 - // width
              48) / // padding
            2
          : 120,
        33,
      ],
    },
    sm: {
      border: [120, 33],
    },
  });

  const { cropper, getFileToCrop } = useImageCropModal({
    editor: {
      width: 160,
      height: 160,
      border: cropperSize?.border,
      borderRadius: 80,
    },
    onSave: onAvatarSubmit,
  });
  const dropzone = useDropzone({
    onDrop: async (files) => {
      if (files[0]) {
        getFileToCrop(files[0]);
      }
    },
  });
  return {
    cropper,
    dropzone,
    onAvatarSubmit,
  };
}

export function AvatarUpload({
  inputProps,
  loading,
  dropzone,
  onAvatarSubmit,
  cropper,
  ...props
}: AvatarUploadProps) {
  return (
    <div
      className="absolute"
      style={{
        right: '-5px',
        bottom: '-5px',
      }}
      {...dropzone.getRootProps()}
    >
      <Badge
        px={1}
        color="white"
        bg="gray.900"
        data-testid="upload-avatar"
        width="32px"
        height="32px"
        borderWidth={0}
        pos="relative"
        borderRadius="full"
        cursor={loading ? 'not-allowed' : 'pointer'}
        {...props}
      >
        <SvgCamera color="white" fontSize="20px" margin="5px auto" />
      </Badge>
      <input
        type="file"
        data-testid="upload-avatar-hidden-input"
        {...dropzone.getInputProps({
          accept: LIMIT_UPLOAD_IMAGE_FILE_ACCEPT,
        })}
      />
      <ImageAvatarCropModal
        title="Update profile image"
        {...cropper}
        content={{ maxW: 480, height: 480 }}
      />
    </div>
  );
}

export function ButtonAvatarUpload({
  inputProps,
  loading,
  dropzone,
  onAvatarSubmit,
  cropper,
  children,
  ...props
}: AvatarUploadProps) {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        right: `${isMobile ? '-6px' : '-4px'}`,
        bottom: `${isMobile ? '0' : '-4px'}`,
      }}
      {...dropzone.getRootProps()}
    >
      <Flex
        w="full"
        alignItems="center"
        cursor={loading ? 'not-allowed' : 'pointer'}
        {...props}
      >
        {children}
      </Flex>
      <input
        type="file"
        data-testid="upload-avatar-hidden-input"
        {...dropzone.getInputProps({
          accept: LIMIT_UPLOAD_IMAGE_FILE_ACCEPT,
        })}
      />
      <ImageAvatarCropModal
        title="Update profile image"
        {...cropper}
        content={{ maxW: 480, height: 480 }}
      />
    </div>
  );
}
