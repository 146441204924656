import { getColor, mode } from '@chakra-ui/theme-tools';

const parts = ['tablist', 'tab', 'tabpanel', 'indicator'];

function baseStyleTab(props) {
  const { isFitted } = props;

  return {
    flex: isFitted ? 1 : undefined,
    transition: 'all 0.2s',
    _focus: {
      zIndex: 1,
      boxShadow: 'none',
    },
  };
}

function baseStyleTablist(props) {
  const { align = 'start', orientation } = props;

  const alignments = {
    end: 'flex-end',
    center: 'center',
    start: 'flex-start',
  };

  return {
    justifyContent: alignments[align],
    flexDirection: orientation === 'vertical' ? 'column' : 'row',
  };
}

const baseStyleTabpanel = { p: 4 };

const baseStyle = (props) => {
  return {
    tab: baseStyleTab(props),
    tablist: baseStyleTablist(props),
    tabpanel: baseStyleTabpanel,
  };
};

const sizes = {
  sm: {
    tab: {
      py: '0.25rem',
      px: '1rem',
      fontSize: '0.85rem',
    },
  },
  md: {
    tab: {
      fontSize: '1rem',
      py: '0.5rem',
      px: '1rem',
    },
  },
  lg: {
    tab: {
      fontSize: '1.15rem',
      py: '0.75rem',
      px: '1rem',
    },
  },
};

function variantLine(props) {
  const { colorScheme: c, orientation } = props;
  const borderProp = orientation === 'vertical' ? 'borderLeft' : 'borderBottom';

  return {
    tablist: {
      [borderProp]: '2px solid',
      borderColor: 'inherit',
    },
    tab: {
      [borderProp]: '2px solid',
      borderColor: 'transparent',
      mb: '-2px',
      _selected: {
        color: mode(`${c}.600`, `${c}.300`)(props),
        borderColor: 'currentColor',
      },
      _active: {
        bg: mode('gray.200', 'whiteAlpha.300')(props),
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  };
}

function variantEnclosed(props) {
  const { colorScheme: c } = props;
  return {
    tab: {
      borderTopRadius: 'md',
      border: '1px solid',
      borderColor: 'transparent',
      mb: '-1px',
      _selected: {
        color: mode(`${c}.600`, `${c}.300`)(props),
        borderColor: 'inherit',
        borderBottomColor: mode(`white`, `gray.800`)(props),
      },
    },
    tablist: {
      mb: '-1px',
      borderBottom: '1px solid',
      borderColor: 'inherit',
    },
  };
}

function variantEnclosedColored(props) {
  const { colorScheme: c } = props;
  return {
    tab: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: mode(`gray.50`, `whiteAlpha.50`)(props),
      mb: '-1px',
      _notLast: {
        mr: '-1px',
      },
      _selected: {
        bg: mode(`#fff`, 'gray.800')(props),
        color: mode(`${c}.600`, `${c}.300`)(props),
        borderColor: 'inherit',
        borderTopColor: 'currentColor',
        borderBottomColor: 'transparent',
      },
    },
    tablist: {
      mb: '-1px',
      borderBottom: '1px solid',
      borderColor: 'inherit',
    },
  };
}

function variantSoftRounded(props) {
  const { colorScheme: c, theme } = props;
  return {
    tab: {
      borderRadius: 'full',
      fontWeight: 'semibold',
      color: 'gray.600',
      _selected: {
        color: getColor(theme, `${c}.700`),
        bg: getColor(theme, `${c}.100`),
      },
    },
  };
}

function variantSolidRounded(props) {
  const { colorScheme: c } = props;
  return {
    tab: {
      borderRadius: 'full',
      fontWeight: 'semibold',
      color: mode('$mode.600', 'inherit')(props),
      _selected: {
        color: mode(`#fff`, '$mode.800')(props),
        bg: mode(`${c}.600`, `${c}.300`)(props),
      },
    },
  };
}

const variantUnstyled = {};

const variants = {
  line: variantLine,
  enclosed: variantEnclosed,
  'enclosed-colored': variantEnclosedColored,
  'soft-rounded': variantSoftRounded,
  'solid-rounded': variantSolidRounded,
  unstyled: variantUnstyled,
};

const defaultProps = {
  size: 'md',
  variant: 'line',
  colorScheme: 'blue',
};

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
