// import { ImageProps } from 'next/image';
import {
  AspectRatio,
  AspectRatioProps,
  Box,
  BoxProps,
  Image as ChakraImage,
} from '@chakra-ui/react';

import { ReactNode } from 'react';

interface ImageBackgroundProps {
  loading?: boolean;
  children?: ReactNode;
  src: string;
}

export function ImageBackgroundRatio({
  src,
  children,
  loading,
  ratio = 1,
  ...props
}: ImageBackgroundProps & AspectRatioProps) {
  return (
    <AspectRatio
      position="relative"
      className="next-image-wrapper"
      ratio={ratio}
      {...props}
    >
      <div>
        {!loading && (
          <ChakraImage
            fit="cover"
            height="100%"
            width="100%"
            src={src || '/images/assets/reppl/cover.png'}
          />
        )}
        {children}
        <style jsx global>{`
          .css-s9pped {
            height: 100%;
          }
        `}</style>
      </div>
    </AspectRatio>
  );
}

export function ImageBackground({
  src,
  children,
  loading,
  ...props
}: ImageBackgroundProps & BoxProps) {
  return (
    <Box
      position="relative"
      className="next-image-wrapper"
      w="100%"
      h="100%"
      {...props}
    >
      {!loading && (
        <ChakraImage
          fit="cover"
          src={src || '/images/assets/reppl/cover.png'}
        />
      )}
      {children}
    </Box>
  );
}
