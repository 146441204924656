/**
 * External dependencies
 */
import { useCallback, useReducer } from 'react';

/**
 * Internal dependencies
 */
import { INITIAL_STATE } from './constants';
import { FILTERS } from './filterConfiguration';

import { INITIAL_RESULT_STATE } from '../constants';
import reducer from './reducer';
import * as types from '../reducers/types';

function useFilterReducer(searchState) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const handleFilter = useCallback(
    ({ type, value, groupId = null }) => {
      if (type === 'ALL') {
        const filters = value;

        if (Object.keys(filters).length === 0) {
          /** reset filters */
          Object.values(FILTERS).forEach(({ name }) => {
            filters[name] = [];
          });
        }

        dispatch({
          type: types.UPDATE_ALL_FILTERS,
          payload: {
            filters,
          },
        });
      } else {
        dispatch({
          type: types.UPDATE_FILTER,
          payload: {
            name: type,
            value,
            groupId,
          },
        });
      }
    },
    [dispatch]
  );

  const updateFilterResult = useCallback(
    ({ result, genre = 'ALL' }) =>
      dispatch({
        type: types.UPDATE_FILTER_RESULT,
        payload: {
          result,
          genre,
        },
      }),
    []
  );

  const onFilterChange = useCallback(
    ({ type, value, groupId = null }) => {
      handleFilter({ type, value, groupId });
      updateFilterResult({
        result: searchState.result,
        genre: searchState.genre,
      });
    },
    [handleFilter, updateFilterResult, searchState.result, searchState.genre]
  );

  const resetFilter = useCallback(() => {
    const initResult = Object.keys(INITIAL_RESULT_STATE).reduce(
      (acc, key) => ({ ...acc, [key]: [] }),
      {}
    );

    handleFilter({ type: 'ALL', value: {} });
    updateFilterResult({
      result: { ...initResult },
      genre: searchState.genre,
    });
  }, [searchState.genre, handleFilter, updateFilterResult]);

  const actions = {
    handleFilter,
    updateFilterResult,
    onFilterChange,
    resetFilter,
  };

  return {
    state,
    actions,
  };
}

export default useFilterReducer;
