import * as React from 'react';
import { Svg } from '../svg';

function SvgWarningV3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16 29.333c7.364 0 13.334-5.97 13.334-13.333 0-7.364-5.97-13.334-13.334-13.334C8.637 2.666 2.667 8.636 2.667 16S8.637 29.333 16 29.333zM16 10.666V16M16 21.334h.013"
        stroke="#F63"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgWarningV3);
export default ForwardRef;
