export default {
  // heading
  h1: {
    fontSize: '3xl',
    fontWeight: '500',
    lineHeight: '48px',
  },
  h2: {
    fontSize: '2xl',
    fontWeight: '500',
    lineHeight: '38px',
  },
  h3: {
    fontSize: 'xl',
    fontWeight: '600',
    lineHeight: '24px',
  },
  h4: {
    fontSize: 'lg',
    fontWeight: '600',
    lineHeight: '24px',
  },
  h5: {
    fontSize: 'md',
    fontWeight: '600',
    lineHeight: '20px',
  },
  h4Light: {
    fontSize: 'lg',
    lineHeight: '24px',
    fontWeight: '500',
  },
  h5Light: {
    fontSize: 'md',
    lineHeight: '20px',
    fontWeight: '500',
  },
  h5Body: {
    fontSize: 'md',
    fontWeight: '500',
    lineHeight: '22px',
  },
  h6: {
    fontSize: 'sm',
    fontWeight: '600',
    lineHeight: '18px',
    letterSpacing: '0.75px',
  },
  h6Light: {
    fontSize: 'sm',
    fontWeight: '500',
    lineHeight: '19px',
  },
  // text
  caption: {
    fontWeight: '700',
    fontSize: '11px',
    lineHeight: '14px',
  },
  body0: {
    fontWeight: 'medium',
    fontSize: 'md',
    lineHeight: '24px',
  },
  body2: {
    fontSize: 'sm',
    fontWeight: '400',
    lineHeight: '20px',
  },
  body3: {
    fontSize: 'sm',
    lineHeight: '19px',
    letterSpacing: '0.1px',
    fontWeight: '500',
  },
  body4: {
    fontSize: 'md',
    lineHeight: '24px',
    letterSpacing: '0.01em',
  },
  bodyCaption: {
    fontSize: 'sm',
    lineHeight: '14px',
    fontWeight: 'bold',
  },
  bodyQuote0: {
    fontSize: 'md',
    lineHeight: '20px',
    fontWeight: '400',
    fontStyle: 'italic',
  },
  bodyQuote: {
    fontSize: 'lg',
    lineHeight: '32px',
    fontWeight: '400',
    fontStyle: 'italic',
  },
  bodyMobile: {
    fontWeight: 'medium',
    fontSize: 'xs',
    lineHeight: '17px',
  },
  subheading: {
    fontSize: 'lg',
    lineHeight: '40px',
    fontWeight: 'bold',
  },
  labelBold: {
    fontWeight: 'bold',
    fontSize: 'xs',
    lineHeight: '17px',
  },
  labelLight: {
    fontWeight: 'normal',
    fontSize: 'xs',
    lineHeight: '16px',
  },
  label: {
    fontWeight: 'bold',
    fontSize: 'xs',
    lineHeight: '14px',
  },
  buttonText: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '18px',
  },
};
