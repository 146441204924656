import { useEffect } from 'react';
import { useWindowEventListener } from './use-window-event-listener';

export function useIOSResizeHeight() {
  useEffect(() => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }, []);

  useWindowEventListener('resize', () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  });
}
