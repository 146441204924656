import {
  Button,
  Center,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { SvgClose, SvgModalSuccessIcon } from 'src/components/icons';
import { useRepplQuery } from 'src/app/reppl/hooks';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

function ConfirmModal({
  isOpen,
  onClose,
  title,
  cancelBtnText = 'Cancel',
  okBtnText = 'Remove',
  article,
  onOk,
  rid,
}: UseDisclosureReturn & {
  title: string;
  cancelBtnText?: string;
  okBtnText?: string;
  article?: any;
  onOk?: () => void;
  rid?: string;
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { data: repplData } = useRepplQuery(rid);
  let text = '';
  if (okBtnText === t('confirmApprove')) {
    text = `"${article.title}" has been successfully added to your Space "${repplData?.reppl?.title}".`;
  } else if (okBtnText === 'hidden') {
    text = `The submission "${article.title}" by ${article.author.username} has been declined. `;
  } else if (okBtnText === 'unpublish') {
    text = `The submission "${article.title}" by ${article.author.username} has been unpublished. `;
  }

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay>
        <ModalContent
          bg="$mode.50"
          pt="10px"
          px={{ base: '15px', md: '30px' }}
          pb="40px"
          w="480px"
        >
          <Center flexDirection="column">
            {title === 'Episode Declined' ? (
              <div className="relative p-5">
                <SvgClose
                  color="blue.500"
                  fontSize="20px"
                  position="absolute"
                  top="calc(50% - 10px)"
                  left="calc(50% - 10px)"
                />
                <Box
                  bgColor="blue.50"
                  borderRadius={99}
                  margin="auto"
                  w="75px"
                  h="75px"
                />
              </div>
            ) : (
              <SvgModalSuccessIcon fontSize="108px" />
            )}
            <Heading as="h3" textStyle="h3" color="gray.900">
              <Text noOfLines={2}>{title}</Text>
            </Heading>
            {text ? (
              <Text
                textAlign="center"
                textStyle="body0"
                color="$mode.500"
                pt={3}
                pb="48px"
              >
                {text}
              </Text>
            ) : (
              <Box mt={3} mb={6} />
            )}
            <div className="flex">
              <Button
                width="100%"
                mx={2}
                color={okBtnText !== t('confirmApprove') ? 'white' : 'gray.500'}
                bgColor={okBtnText !== t('confirmApprove') && 'blue.500'}
                rounded={okBtnText !== t('confirmApprove') && 'ellipse'}
                variant={okBtnText !== t('confirmApprove') ? 'solid' : 'ghost'}
                onClick={() => router.reload()}
              >
                {cancelBtnText}
              </Button>
              <Button
                width="100%"
                mx={2}
                display={okBtnText !== t('confirmApprove') ? 'none' : 'block'}
                bgColor="blue.500"
                rounded="ellipse"
                onClick={() => {
                  if (onOk) {
                    onOk();
                  }
                  onClose();
                }}
              >
                {okBtnText}
              </Button>
            </div>
          </Center>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default ConfirmModal;
