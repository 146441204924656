const service = [
  {
    id: 0,
    value: '3D Printing & Prototyping',
    displayName: '3D Printing & Prototyping',
  },
  {
    id: 1,
    value: '3D Scanning & Rendering',
    displayName: '3D Scanning & Rendering',
  },
  { id: 2, value: 'Animation', displayName: 'Animation' },
  { id: 3, value: 'Cartoons & Comics', displayName: 'Cartoons & Comics' },
  { id: 4, value: 'Distribution', displayName: 'Distribution' },
  {
    id: 5,
    value: 'Illustration & Drawing',
    displayName: 'Illustration & Drawing',
  },
  { id: 6, value: 'Logistics', displayName: 'Logistics' },
  { id: 7, value: 'Manufacturing', displayName: 'Manufacturing' },
  { id: 8, value: 'Marketing', displayName: 'Marketing' },
  { id: 9, value: 'Packaging', displayName: 'Packaging' },
  { id: 10, value: 'Print on Demand', displayName: 'Print on Demand' },
  { id: 11, value: 'Project Management', displayName: 'Project Management' },
  { id: 12, value: 'Publishing', displayName: 'Publishing' },
  { id: 13, value: 'Retail', displayName: 'Retail' },
  {
    id: 14,
    value: 'Software Development',
    displayName: 'Software Development',
  },
  { id: 15, value: 'System Integration', displayName: 'System Integration' },
  { id: 16, value: 'Video editing', displayName: 'Video editing' },
  { id: 17, value: 'Writing', displayName: 'Writing' },
  { id: 18, value: 'Photograph', displayName: 'Photograph' },
];

export default service;
