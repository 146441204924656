/**
 * External dependencies
 */
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';

/**
 * Internal dependencies
 */
import { useFolio } from 'src/app/folio';

import { AssetDetailModal } from 'src/app/asset/asset-modal';

import { GenreType, GenreConfig } from 'src/app/search/types';
import { FILTER_TYPES } from 'src/app/search/constants';

interface SearchResultListProps {
  searchResult: any;
  type: string;
  genres: [string, GenreConfig][] | Array<GenreConfig>;
  onResultClick?: (value: GenreType) => void;
}

const SearchResultList = ({
  searchResult,
  type,
  genres,
  onResultClick,
}: SearchResultListProps) => {
  const { folioModal, currentLocation } = useFolio();
  /** <!-- Modal Opener */
  const assetModal = useDisclosure();
  const [currentContentID, setCurrentContentID] = useState('');

  const [nftContent, setNftContent] = useState(null);

  const modalOpener = useCallback(
    (data) => {
      setCurrentContentID(data.contentID);

      if (data.nft) {
        window.history.pushState('', '', `../asset/${data.contentID}`);
        setNftContent({ ...data.nft });
        assetModal.onOpen();
      } else {
        const onOpenModal = folioModal.onOpen;
        onOpenModal({ id: data.contentID });
      }
    },
    [assetModal, folioModal.onOpen]
  );
  /** Modal Opener --> */

  const getPosition = () => {
    const isWindowAvailable = typeof window !== 'undefined';
    return isWindowAvailable ? window.pageYOffset : undefined;
  };

  const [showItemsLength, setShowItemsLength] = useState(12);

  const handleScroll = useCallback(() => {
    if (type !== 'ALL') {
      const scrollPosition = getPosition();
      if (
        document.body.scrollHeight ===
        scrollPosition + document.body.offsetHeight
      ) {
        if (showItemsLength + 12 <= searchResult[FILTER_TYPES[type]].length) {
          setShowItemsLength(showItemsLength + 12);
        } else {
          setShowItemsLength(searchResult[FILTER_TYPES[type]].length);
        }
      }
    }
  }, [type, showItemsLength, searchResult]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const resultList = useMemo(() => {
    if (!searchResult[FILTER_TYPES[type]]?.length) return;

    switch (type) {
      case 'ALL': {
        return (genres as [GenreType, GenreConfig][]).map(
          ([genreKey, genre]) => {
            const Result = genre.cardComponent;
            const ResultLayout = genre.cardComponent['Layout'];
            return (
              <ResultLayout
                key={`result_layout_${genre.filterType}`}
                onClick={() => onResultClick(genreKey)}
                showViewAll={searchResult[FILTER_TYPES[type]].length > 3}
              >
                <Result
                  result={searchResult[FILTER_TYPES[type]]}
                  resultLimit={genre.viewAllShowLimit}
                  openModal={modalOpener}
                  isAllResult
                />
                ;
              </ResultLayout>
            );
          }
        );
      }
      case 'CREATIONS':
      case 'USERS':
      case 'SPACES':
      case 'COLLECTIONS':
      case 'CREATIVE_ASSETS': {
        if (!searchResult[FILTER_TYPES[type]]?.length) return null;
        const genre = (genres as GenreConfig[])[0];
        const Result = genre.cardComponent;
        return (
          <Result
            key={`result_${genre.filterType}`}
            result={searchResult[FILTER_TYPES[type]]}
            resultLimit={showItemsLength}
            openModal={modalOpener}
          />
        );
      }
      case 'TOPICS': {
        const genre = (genres as GenreConfig[])[0];
        const Result = ({ result, ...props }) => {
          const [currentTag, setCurrentTag] = useState('');
          const filterTagResult = useMemo(
            () => result.filter((topic) => topic.type === currentTag),
            [result, currentTag]
          );
          const onChangeTag = useCallback((tag) => setCurrentTag(tag), []);
          return (
            <genre.cardComponent
              currentTag={currentTag}
              onChangeTag={onChangeTag}
              result={filterTagResult}
              {...props}
            />
          );
        };
        return (
          <Result
            key={`result_${genre.filterType}`}
            result={searchResult[FILTER_TYPES[type]]}
            resultLimit={showItemsLength}
            openModal={modalOpener}
          />
        );
      }
      default:
        return null;
    }
  }, [type, genres, modalOpener, searchResult, onResultClick, showItemsLength]);

  return (
    <div className="mb-6">
      {assetModal.isOpen && (
        <AssetDetailModal
          modal={assetModal}
          id={currentContentID}
          defaultAsset={nftContent}
          currentLocation={currentLocation}
        />
      )}
      {resultList}
    </div>
  );
};
export default SearchResultList;
