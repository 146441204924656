import { useQuery } from 'react-query';
import { CacheKey } from 'src/constants/cache-key';

import { axios } from 'src/lib/axios';
import { logger } from 'src/lib/logger';
import { RestResponse } from 'src/types';

import type { UserProfile } from './types';

type UserProfileResponse = RestResponse<UserProfile>;

export const fetchProfile = async (username: string) => {
  const { data } = await axios
    .get<UserProfileResponse>(`/profile/${username}`)
    .then((res) => res.data);

  return data;
};

function useProfileQuery(username?: string) {
  return useQuery([CacheKey.profile, username], () => fetchProfile(username), {
    enabled: !!username,
    onError: (error) => {
      logger.error(error);
    },
  });
}

export default useProfileQuery;
