import { Box, Text } from '@chakra-ui/react';
import { useIsXl } from 'src/hooks/use-is-mobile';
import { AnnouncementUpdates } from './types';

export function AnnouncementFeed({
  dataAnnouncement,
}: {
  dataAnnouncement: AnnouncementUpdates;
}) {
  const description1 = dataAnnouncement?.description.split('\n')[0];
  const description2 = dataAnnouncement?.description.split('\n')[1];
  const isXl = useIsXl();

  return (
    <Box
      className="md:block my-4 w-full rounded-3xl overflow-hidden mb-6"
      borderRadius={{ base: '0', md: '16px', sm: '16px' }}
      style={{
        display: 'flex',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06)',
        background:
          'linear-gradient(140.98deg, #1E3CA6 18.97%, rgba(149, 62, 199, 0.72) 93.18%)',
      }}
    >
      {/* left side starts here */}
      {/* remove false && to bring back left side image */}
      {false && !isXl && (
        <Box
          style={{
            width: '71%',
            backgroundImage: `url(${dataAnnouncement?.image_url})`, // Start Doing Together Banner Stuff is here
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      )}
      {/* right side starts here */}
      <Box
        className="w-full pb-9 text-center flex flex-col justify-center items-center"
        p={{ base: '40px 24px', md: '32px 28px' }}
      >
        <Text
          className="text-white font-semibold mb-2"
          fontSize={{ base: '20px', md: '24px' }}
          lineHeight={{ base: '24px', md: '30px' }}
        >
          {dataAnnouncement?.title}
        </Text>
        <Text
          fontWeight="600"
          fontSize="md"
          lineHeight="20px"
          color="white"
          pb="16px"
        >
          {dataAnnouncement?.subtitle}
        </Text>
        <Text
          className="text-white text-xs font-medium text-center"
          lineHeight="18px"
          mb={{ base: '16px', md: '24px' }}
        >
          {description1}
        </Text>
        <Text
          className="text-white text-xs font-medium text-center mb-6"
          lineHeight="18px"
        >
          {description2}
        </Text>
        {dataAnnouncement?.cta_button_text && (
          <a
            target="_blank"
            rel="noreferrer"
            href={`${dataAnnouncement?.cta_link}`}
          >
            <button
              type="button"
              className=" bg-white rounded-3xl font-semibold text-grey-700  flex items-center"
            >
              <Text
                color="#D567C4"
                lineHeight={{ base: '18px', md: '20px' }}
                fontSize={{ base: 'sm', md: 'md' }}
                p={{ base: '7px 28px', md: '10px 32px' }}
              >
                {dataAnnouncement?.cta_button_text}
              </Text>
            </button>
          </a>
        )}
      </Box>
    </Box>
  );
}
