import * as React from 'react';
import { Svg } from '../svg';

function SvgFigma({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 17 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#figma_svg__clip0_4815_67562)">
        <path
          d="M4.75 24c2.208 0 4-1.792 4-4v-4h-4c-2.208 0-4 1.792-4 4s1.792 4 4 4z"
          fill="#0ACF83"
        />
        <path
          d="M.75 12c0-2.208 1.792-4 4-4h4v8h-4c-2.208 0-4-1.792-4-4z"
          fill="#A259FF"
        />
        <path
          d="M.75 4c0-2.208 1.792-4 4-4h4v8h-4c-2.208 0-4-1.792-4-4z"
          fill="#F24E1E"
        />
        <path
          d="M8.75 0h4c2.208 0 4 1.792 4 4s-1.792 4-4 4h-4V0z"
          fill="#FF7262"
        />
        <path
          d="M16.75 12c0 2.208-1.792 4-4 4s-4-1.792-4-4 1.792-4 4-4 4 1.792 4 4z"
          fill="#1ABCFE"
        />
      </g>
      <defs>
        <clipPath id="figma_svg__clip0_4815_67562">
          <path fill="#fff" transform="translate(.749)" d="M0 0h16.003v24H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgFigma);
export default ForwardRef;
