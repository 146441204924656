import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  Slider,
  SliderThumb,
  SliderTrack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
  forwardRef,
  useRef,
  useState,
  CSSProperties,
  useEffect,
  ReactNode,
} from 'react';
import AvatarEditor, { AvatarEditorProps } from 'react-avatar-editor';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { isFileTypeNotResizable } from 'src/utils/file-type';

import SvgZoomIn from 'src/components/icons/zoom-in';
import SvgZoomOut from 'src/components/icons/zoom-out';

import { makeAspectCrop, centerCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ConditionalWrap, ConditionalWrapProps } from '../conditional-wrap';

export interface Crop {
  x: number;
  y: number;
  width: number;
  height: number;
  unit: 'px' | '%';
  aspect?: any;
}

interface ImageCropProps extends AvatarEditorProps {
  initialScale?: number;
  wrap?: ConditionalWrapProps['wrap'];
  editorStyles?: CSSProperties;
  title?: string;
}

export function useImageCropper() {
  const imgRef = useRef<HTMLImageElement>(null);
  const [croppedImage, setCroppedImage] = useState<any>();
  // const [aspect] = useState<number | undefined>(ratio ? 16 / 9 : undefined);
  const [cropConfig, setCropConfig] = useState<Crop>({
    unit: '%',
    width: 90,
    aspect: 16 / 9,
  } as Crop);
  async function cropImage(crop) {
    if (imgRef.current && crop.width && crop.height) {
      const cropped = await getCroppedImage(imgRef.current, crop);
      // calling the props function to expose
      // croppedImage to the parent component
      setCroppedImage(cropped);
    } else {
      const cropped = await getCroppedImage(imgRef.current, {
        width: imgRef.current.width,
        height: imgRef.current.height,
        x: 0,
        y: 0,
        unit: 'px',
      });
      setCroppedImage(cropped);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCropConfig(centerAspectCrop(width, height, 16 / 9));
  }

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspectR: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspectR,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else if (imgRef.current) {
      cropImage(cropConfig);
    }
  }, [cropConfig]);

  function getCroppedImage(sourceImage, cropConfg) {
    // creating the cropped image from the source image
    const canvas = document.createElement('canvas');
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    const currWidth = (cropConfg.width / 100) * sourceImage.naturalWidth;
    const currHeight = (cropConfg.height / 100) * sourceImage.naturalHeight;
    const percX = (cropConfg.x / 100) * sourceImage.naturalWidth;
    const percY = (cropConfg.y / 100) * sourceImage.naturalHeight;
    if (cropConfg.unit === '%') {
      canvas.width = currWidth;
      canvas.height = currHeight;
    } else {
      canvas.width = cropConfg.width;
      canvas.height = cropConfg.height;
    }
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      sourceImage,
      cropConfg.unit === '%'
        ? Math.floor(percX)
        : Math.floor(cropConfg.x * scaleX),
      cropConfg.unit === '%'
        ? Math.floor(percY)
        : Math.floor(cropConfg.y * scaleY),
      cropConfg.unit === '%'
        ? Math.floor(currWidth)
        : Math.floor(cropConfg.width * scaleX),
      cropConfg.unit === '%'
        ? Math.floor(currHeight)
        : Math.floor(cropConfg.height * scaleY),
      0,
      0,
      canvas.width,
      canvas.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        // blob?.name = fileName;
        // creating a Object URL representing the Blob object given
        // const croppedImageUrl = window.URL.createObjectURL(blob);

        resolve(blob);
      }, 'image/jpeg');
    });
  }

  return {
    cropper: {
      cropConfig,
      setCropConfig,
      onImageLoad,
      croppedImage,
      cropImage,
    },
    imgRef,
  };
}

export const ImageCrop = forwardRef<AvatarEditor, ImageCropProps>(
  ({ initialScale = 2, wrap, title, editorStyles, ...props }, ref) => {
    const [scale, setScale] = useState(initialScale);
    const isMobile = useIsMobile();
    const positionDragImage = {
      top: 0,
      bottom: 0,
    };

    return (
      <>
        <Box
          w="100%"
          borderRadius="4px"
          overflow="hidden"
          mt="22px"
          pos="relative"
        >
          <ConditionalWrap condition={!!wrap} wrap={wrap}>
            <AvatarEditor
              style={editorStyles}
              ref={ref}
              color={[0, 0, 0, 0.66]}
              scale={scale}
              crossOrigin="anonymous"
              {...props}
            />
          </ConditionalWrap>
          <motion.div
            animate={{ opacity: !!wrap && isMobile ? 1 : 0 }}
            transition={{ delay: 5 }}
          >
            <Center
              pos="absolute"
              left={0}
              right={0}
              bottom="14px"
              pointerEvents="none"
              {...(isMobile && !!wrap && positionDragImage)}
            >
              {!!wrap && isMobile && (
                <Center
                  pos="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  bg="linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #C4C4C4"
                  opacity={0.3}
                  sx={{
                    mixBlendMode: 'multiply',
                  }}
                />
              )}
              <Text textStyle="labelLight" color="white">
                Drag image to reposition
              </Text>
            </Center>
          </motion.div>
        </Box>
        <Flex
          w="100%"
          fontSize="24px"
          pt="20px"
          maxW="400px"
          justify="center"
          m="auto"
        >
          <SvgZoomOut mr="14px" />
          <Slider value={scale} onChange={setScale} max={4} min={1} step={0.1}>
            <SliderTrack bg="$mode.200" height="2px" />
            <SliderThumb
              layerStyle="layer3"
              border="2px solid"
              borderColor="blue.500"
              width="20px"
              height="20px"
            />
          </Slider>
          <SvgZoomIn ml="14px" />
        </Flex>
      </>
    );
  }
);

export type UseImageCropModalReturn = ReturnType<typeof useImageCropModal>;

export type ImageCropModalProps = UseImageCropModalReturn['cropper'];

export function useImageCropModal(props: {
  onSave?: (file: File | Blob) => void;
  editor: Partial<ImageCropProps>;
  displayAspectRatio?: any[];
}) {
  const editorRef = useRef<AvatarEditor>();
  const modal = useDisclosure();
  const [croppingFile, setCroppingFile] = useState<File | string>();
  const [mime, setMime] = useState<string>();

  const onClickSave = () => {
    const image = editorRef.current?.getImage();
    image.toBlob(
      (blob) => {
        props.onSave?.(blob);
        modal.onClose();
      },
      mime?.includes('webp') ? 'image/png' : mime,
      1
    );
  };

  const getFileToCrop = async (file: File | string) => {
    setMime(undefined);
    if (typeof file === 'string') {
      setCroppingFile(file);
      modal.onOpen();
      return;
    }
    // skip gif cropping
    if (isFileTypeNotResizable(file.type)) {
      setMime(file.type);
      props.onSave?.(file);
      return;
    }
    setMime(file.type);
    setCroppingFile(file);
    modal.onOpen();
  };

  return {
    cropper: {
      displayAspectRatio: props.displayAspectRatio,
      editorRef,
      onSave: onClickSave,
      editor: {
        initialScale: 1.4,
        image: croppingFile,
        ...props.editor,
      },
      modal,
    },
    mime,
    getFileToCrop,
  };
}

export function ImageCropModal(props: {
  content?: ModalContentProps;
  // title?: string;
  displayAspectRatio?: any[];
  onSave?: () => void;
  modal?: any;
  isRatio?: boolean;
  children?: ReactNode;
  isGif?: boolean;
}) {
  return (
    <Modal isCentered {...props.modal}>
      <ModalOverlay>
        <ModalContent
          {...props.content}
          px={{
            base: '24px',
            md: '40px',
          }}
          py={{
            base: '16px',
            md: '40px',
          }}
          justifyContent="space-between"
        >
          <Box>
            {/* <Heading as="h3" textStyle="h3">
              {props.title}
            </Heading> */}
            <Center
              style={{ minHeight: '300px' }}
              flexDir="column"
              w="100%"
              className="mt-4 relative bg-gray-100"
            >
              <div style={{ zIndex: '2' }}>{props.children}</div>
              <div style={{ zIndex: '1' }}>
                <div
                  style={{
                    backgroundImage: `url(${require('public/images/assets/spinner.gif')})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '300px',
                    width: '300px',
                  }}
                  className="absolute m-auto inset-0"
                />
              </div>
            </Center>
          </Box>
          <ButtonGroup justifySelf="flex-end" alignSelf="flex-end" mt="40px">
            <Button
              variant="ghost"
              onClick={props.modal.onClose}
              style={{
                color: '#9B9FA4',
                fontWeight: 600,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                props.onSave();
                props.modal.onClose();
              }}
              className="py-2.5 px-8 rounded-2xl"
              style={{
                borderRadius: '20px',
                fontWeight: 600,
              }}
            >
              {props.isGif ? 'Save' : 'Crop and Save'}
            </Button>
          </ButtonGroup>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export function ImageAvatarCropModal(
  props: ImageCropModalProps & {
    content?: ModalContentProps;
    title?: string;
    displayAspectRatio?: any[];
  }
) {
  return (
    <Modal isCentered {...props.modal}>
      <ModalOverlay>
        <ModalContent
          {...props.content}
          px={{
            base: '24px',
            md: '40px',
          }}
          py={{
            base: '16px',
            md: '40px',
          }}
          justifyContent="space-between"
        >
          <Box>
            <Heading as="h3" textStyle="h3">
              {props.title}
            </Heading>
            <Center flexDir="column" w="100%">
              <ImageCrop
                title={props.title}
                wrap={
                  props.displayAspectRatio
                    ? (c) => (
                        <AspectRatio
                          sx={{
                            '& > canvas': {
                              transform: 'translate(-50%, -50%)',
                              top: '100%',
                              left: '50%',
                            },
                          }}
                          ratio={
                            props.displayAspectRatio &&
                            props.displayAspectRatio[0] /
                              props.displayAspectRatio[1]
                          }
                        >
                          {c}
                        </AspectRatio>
                      )
                    : null
                }
                ref={props.editorRef}
                {...props.editor}
              />
            </Center>
          </Box>
          <ButtonGroup justifySelf="flex-end" alignSelf="flex-end" mt="40px">
            <Button
              variant="ghost"
              onClick={props.modal.onClose}
              style={{
                color: '#9B9FA4',
                fontWeight: 600,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={props.onSave}
              className="py-2.5 px-8 rounded-2xl"
              style={{
                borderRadius: '20px',
                fontWeight: 600,
              }}
            >
              Crop and Save
            </Button>
          </ButtonGroup>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
