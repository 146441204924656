import { Modal, ModalContent } from '@chakra-ui/react';
import questionIMG from 'src/app/asset/images/question-icon.png';
import styles from './mint-explainer-modal.module.scss';

export default function MintExplainerModal({ closeModal, isOpen }) {
  return (
    <Modal isOpen={isOpen} onClose={closeModal} allowPinchZoom>
      <ModalContent
        maxW="100vw"
        w="100vw"
        h="100vh"
        borderRadius="none"
        background="none"
      >
        <div className={styles.fadein}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,  jsx-a11y/click-events-have-key-events */}
          <div className={styles.overlay} onClick={closeModal}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,  jsx-a11y/click-events-have-key-events */}
            <div
              className={styles.content}
              onClick={(e) => e.stopPropagation()}
            >
              <button
                type="button"
                className={styles.close_button}
                onClick={closeModal}
              >
                <div className={styles.x1} />
                <div className={styles.x2} />
              </button>
              <h2>Mint Authorship Token</h2>
              <div className={styles.section_container}>
                <img src={questionIMG} alt="" />
                <div className={styles.text_wrapper}>
                  <h3>What does it mean to mint an NFT?</h3>
                  <p>
                    Minting an NFT, or non-fungible token, is publishing a
                    unique digital asset on a blockchain so that it can be
                    bought, sold, and traded.
                  </p>
                </div>
              </div>
              <div className={styles.section_container}>
                <img src={questionIMG} alt="" />
                <div className={styles.text_wrapper}>
                  <h3>What is gas fee? Why should I pay for it?</h3>
                  <p>
                    A gas fee is a blockchain transaction fee, paid to network
                    validators for their services to the blockchain. Without the
                    fees, there would be no incentive for anyone to stake their
                    ETH and help secure the network.
                  </p>
                </div>
              </div>
              <div className={styles.section_container}>
                <img src={questionIMG} alt="" />
                <div className={styles.text_wrapper}>
                  <h3>How do I buy ETH?</h3>
                  <p>
                    Trading Ethereum&apos;s native token, Ether (ETH), can be
                    done online via a number of active exchanges. You can now
                    also buy ETH through mainstream brokerage platforms like
                    Robinhood or Paypal.
                  </p>
                </div>
              </div>
              <div className={styles.button_container}>
                <a
                  href="https://hub.1tm.io/intro-to-web3/what-is-web3"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.button}
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
