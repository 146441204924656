import {
  Box,
  useDisclosure,
  Flex,
  Image,
  useToast,
  Center,
  Heading,
  Tabs,
  TabList,
  Tab,
  Progress,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useState, useRef, useContext, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import cn from 'classnames';
import { useIntersectionObserver } from 'src/hooks';
import dayHepler from 'src/heplers/day';
import { DetailCommentModal } from 'src/app/article/detail-comment-modal';
import { ArticleComment } from 'src/app/article/article-comment';
import { Commenter, CommenterHandler } from 'src/components/comment';
import { useIsMobile, useIsTablet } from 'src/hooks/use-is-mobile';
// import { useAssetFullQuery } from 'src/app/asset/hooks/use-asset-query';
import { Avatar } from 'src/components/avatar';
import SvgArrowForward from 'src/components/icons/arrow-forward';
import SvgDefaultWallet from 'src/components/icons/default-wallet';
// import SvgArrowRight from 'src/components/icons/arrow-right';
import SvgLockedIcon from 'src/components/icons/locked-icon';
import Svg1TMLogo from 'src/components/icons/1tm-logo-v1';
import SvgGlobe from 'src/components/icons/globe';

import SvgOpensea from 'src/components/icons/opensea';
import SvgRarible from 'src/components/icons/rarible';
import SvgLooksrare from 'src/components/icons/looksrare';
import SvgEtherscan from 'src/components/icons/etherscan';
import SvgMetadata from 'src/components/icons/metadata';
import SvgIpfs from 'src/components/icons/ipfs';
import SvgUsers from 'src/components/icons/users';
import SvgMailbox from 'src/components/icons/mailbox';
import SvgCloseModal from 'src/components/icons/close-modal';
import SvgGallery from 'src/components/icons/gallery';
import SvgMore from 'src/components/icons/more-v2';

import { StickyIconButtonList } from 'src/app/article/components/sticky-icon-button-list/sticky-icon-button-list';
// import { AddToLabelModal } from 'src/app/label/components/add-to-label-modal';
import {
  useArticleCommentMutation,
  useArticleEditCommentMutation,
  useCreationsComment,
} from 'src/app/article/hooks/use-article-comment';
import { useCommentEnthuseMutation } from 'src/app/article/hooks/use-enthuse';

import { useMoralis } from 'react-moralis';
import { userMention } from 'src/app/explore/service';
import { getTaggedMention } from 'src/app/article/utils';
import SvgNFTholder from 'src/components/icons/nft-placeholder';
import ReactPlayerComponent from 'react-player';
import { ReactPlayerProps } from 'react-player/types/lib';
import { logger } from 'src/lib/logger/logger.server';
import { CustomToast } from 'src/components/toast';
import { getETHPrice } from 'src/service/eth-value';
import type { Chains } from 'src/types';

import { useRouter } from 'next/router';
import WalletModal from 'src/components/modal/wallet-modal';
// import { fetchMetaData, fetchNftOwners } from 'src/hooks/use-alchemy';

import detailStyles from 'src/app/article/Post_detail.module.scss';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { arrayHasData } from 'src/utils/array';
import { imageOptimize } from 'src/service/image';
import { CopyrightFooter } from 'src/app/reppl/copyright-footer';
import Dropdowns from 'src/components/common/dropdowns/dropdowns';
import MintExplainerModal from 'src/app/asset/MintExplainerModal';
import AfterMintingModal from 'src/app/asset/AfterMintingModal';
import MintSuccessfulModal from 'src/app/asset/MintSuccessfulModal';
import styles from './Nft_detail_buy.module.scss';
import section1Styles from './Nft_detail_buy_section1.module.scss';
// import section2Styles from './Nft_detail_buy_section2.module.scss';
import section3Styles from './Nft_detail_buy_section3.module.scss';

const ReactPlayer = (ReactPlayerComponent as unknown) as React.FC<
  ReactPlayerProps
>;

export function NftImageContainer({
  image,
  ratio,
  animation = false,
  classname,
  objectCover,
  control = false,
}: {
  image?: any;
  ratio?: any;
  animation?: boolean;
  classname?: string;
  objectCover?: boolean;
  control?: boolean;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoad, setImageLoad] = useState(true);
  const checkImageType = (data) => {
    if (
      !data.fileType ||
      !(data.fileType?.includes('mp4') || data.fileType?.includes('webm'))
    ) {
      return true;
    }
    // setImageLoad(true);
    return false;
  };
  const checkMediaType = (data) => {
    if (
      data?.animation_url &&
      data?.animation_url.endsWith('glb' || 'gltf' || '3d')
    ) {
      return true;
    }
    return false;
  };

  if (
    animation &&
    image?.animation_url &&
    !image.fileType?.includes('webm') &&
    !image.fileType?.includes('mp4')
  ) {
    if (checkMediaType(image)) {
      return (
        <div style={{ aspectRatio: '16/9' }} className={`m-auto ${classname}`}>
          <model-viewer
            src={image.animation_url}
            alt=""
            shadow-intensity="1"
            camera-controls=""
            auto-rotate=""
            ar=""
            data-js-focus-visible=""
            ar-status="not-presenting"
            style={{
              width: '100%',
              height: '-webkit-fill-available',
            }}
          />
        </div>
      );
    }
    return (
      <iframe
        title="embedded content"
        src={image?.animation_url}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="encrypted-media"
      />
    );
  }

  if (!image || !(image?.animation_url || image?.imgURL)) {
    return (
      <div
        className={cn(
          section1Styles.image23_layout,
          `overflow-hidden h-full`,
          `${!image && 'aspect-square'}`
        )}
      >
        <Box
          style={{ aspectRatio: ratio || '1' }}
          className="m-auto"
          position="relative"
          height="100%"
        >
          <div style={{ height: '100%' }} className="bg-gray-100">
            <div
              style={{ height: '56px', width: '56px' }}
              className="rounded-full bg-white m-auto absolute inset-0"
            >
              <SvgNFTholder
                style={{ height: 'inherit' }}
                width="40px"
                height="40px"
                className="m-auto"
              />
            </div>
          </div>
        </Box>
      </div>
    );
  }

  return (
    <div
      className={cn(
        section1Styles.image23_layout,
        `overflow-hidden h-full`,
        `${!image && 'aspect-square'}`
      )}
    >
      {checkImageType(image) ? (
        <>
          {!imageLoad && (
            <Box
              style={{ aspectRatio: ratio || '1' }}
              className="m-auto"
              position="relative"
              height="100%"
            >
              <div style={{ height: '100%' }} className="bg-gray-100">
                <div
                  style={{ height: '56px', width: '56px' }}
                  className="rounded-full bg-white m-auto absolute inset-0"
                >
                  <SvgNFTholder
                    style={{ height: 'inherit' }}
                    width="40px"
                    height="40px"
                    className="m-auto"
                  />
                </div>
              </div>
            </Box>
          )}
          {image?.imgURL && imageLoad && (
            <>
              <Box
                style={{ zIndex: '2' }}
                position="relative"
                className={`${classname}`}
              >
                <Image
                  style={{
                    aspectRatio: ratio || 'auto',
                    objectFit: objectCover ? 'cover' : 'contain',
                  }}
                  className={`${classname}`}
                  loading="lazy"
                  zIndex="3"
                  w="100%"
                  src={image.imgURL}
                  onError={() => setImageLoad(false)}
                  onLoad={() => setIsLoading(false)}
                />
              </Box>
              {isLoading && (
                <Box
                  style={{ aspectRatio: '1', zIndex: '1' }}
                  className="absolute inset-0 m-auto"
                  height="100%"
                >
                  <div style={{ height: '100%' }} className="bg-gray-100">
                    <div
                      style={{
                        backgroundImage: `url(${require('public/images/assets/spinner.gif')})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                      className={cn(
                        styles.postgrid_more,
                        styles.postgrid_more_layout,
                        'absolute m-auto inset-0'
                      )}
                    />
                  </div>
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {(image?.animation_url || image?.imgURL) && !checkImageType(image) && (
            <Box position="relative" height="100%">
              <ReactPlayer
                className={`${ratio ? 'player-ratio' : ''}`}
                style={{
                  objectFit: ratio ? 'cover' : 'contain',
                  aspectRatio: ratio ? '1' : 'auto',
                }}
                width="100%"
                height="100%"
                loop
                playing
                controls={control}
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nofullscreen',
                    },
                  },
                }}
                muted
                url={image.imgURL || image?.animation_url}
              />
            </Box>
          )}
        </>
      )}
    </div>
  );
}

function TokenDetail({
  tokenData,
  nft,
  supportedChains,
  isNft = false,
  isLazy,
  setMinter,
}: {
  tokenData?: any;
  nft?: any;
  supportedChains: any[];
  isNft?: boolean;
  isLazy: boolean;
  setMinter?: (e: any) => void;
}) {
  // const router = useRouter();
  const toast = useToast();
  const lightroomContentModal = useDisclosure();
  const { user } = useContext(UserContext) as UserContextType;
  const walletModal = useDisclosure();
  const unlockContentModal = useDisclosure();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const { Moralis } = useMoralis();
  const [nftTransfers, setNftTransfers] = useState<any>({});
  // const [minter, setMinter] = useState<any>({});
  const [tabIndex, setTabIndex] = useState<any>(0);

  const [tokenProp, setTokenProp] = useState<any[]>([]);
  const [tokenLevel, setTokenLevel] = useState<any[]>([]);
  const [tokenStat, setTokenStat] = useState<any[]>([]);

  const blockChainLinks = [
    { name: 'on Opensea', link: 'opensea' },
    { name: 'on Rarible', link: 'rarible' },
    { name: 'on Looksrare', link: 'looksrare' },
    { name: 'on Etherscan', link: 'etherscan' },
    { name: 'Metadata', link: 'uri', icon: '' },
    { name: 'Hosted image', link: 'image' },
  ];

  const getDetailLinks = (data) => {
    switch (data) {
      case 'opensea':
        window.open(
          `https://opensea.io/assets/${nft.token_address}/${nft.token_id}`,
          '_blank'
        );
        break;
      case 'rarible':
        window.open(
          `https://rarible.com/token/${nft.token_address}:${nft.token_id}`,
          '_blank'
        );
        break;
      case 'looksrare':
        window.open(
          `https://looksrare.org/collections/${nft.token_address}/${nft.token_id}`,
          '_blank'
        );
        break;
      case 'etherscan':
        window.open(
          `https://etherscan.io/token/${nft.token_address}?a=${nft.token_id}`,
          '_blank'
        );
        break;
      case 'uri':
        window.open(`${nft.tokenUri.raw}`, '_blank');
        break;
      default:
        window.open(`${tokenData?.imgURL}`, '_blank');
        break;
    }
  };

  const getDetailIcons = (data) => {
    if (data === 'opensea') {
      return <SvgOpensea width="24px" height="24px" />;
    }
    if (data === 'rarible') {
      return <SvgRarible width="24px" height="24px" />;
    }
    if (data === 'looksrare') {
      return <SvgLooksrare width="24px" height="24px" />;
    }
    if (data === 'etherscan') {
      return <SvgEtherscan width="24px" height="24px" />;
    }
    if (data === 'uri') {
      return <SvgMetadata width="24px" height="24px" />;
    }
    return <SvgIpfs width="24px" height="24px" />;
  };

  // const fetchMinter = () => {
  //   if (!minter) return '';
  //   if (minter.from_address === '0x0000000000000000000000000000000000000000') {
  //     return minter.to_address;
  //   }
  //   return minter.from_address;
  // };

  useEffect(() => {
    if (tokenData?.attributes) {
      if (tokenData?.attributes instanceof Array) {
        setTokenProp(
          tokenData?.attributes?.filter(
            (n) => typeof n.value === 'string' && n.trait_type
          )
        );
        setTokenLevel(
          tokenData?.attributes?.filter(
            (n) =>
              typeof n.value === 'number' &&
              n.display_type === '' &&
              n.trait_type
          )
        );
        setTokenStat(
          tokenData?.attributes?.filter(
            (n) =>
              typeof n.value === 'number' &&
              n.display_type === 'number' &&
              n.trait_type
          )
        );
      } else {
        const tokenMap = Object.entries(tokenData.attributes).map((e) => {
          return { trait_type: e[0], value: e[1] };
        });
        setTokenProp(tokenMap);
      }
    }
  }, [tokenData]);

  useEffect(() => {
    if (nft && !isLazy) {
      fetchWalletTokenIdTransfers(nft);
    }
  }, [nft]);

  useEffect(() => {
    if (!tokenData?.attributes && nftTransfers?.length > 0) {
      setTabIndex(1);
    }
  }, [nftTransfers]);

  const walletAddressTruncate = (address) => {
    if (address) {
      return `${address.slice(0, 5)}...${address.slice(
        address.length - 4,
        address.length
      )}`;
    }
    return '';
  };

  const fetchWalletTokenIdTransfers = async (token) => {
    const options = {
      address: token.token_address,
      token_id: token.token_id,
      chain: supportedChains[0] as Chains,
    };
    try {
      const transfers = await Moralis.Web3API.token.getWalletTokenIdTransfers(
        options
      );
      setNftTransfers(transfers.result);
      const mintDetails = transfers.result.filter(
        (n) => n.from_address === '0x0000000000000000000000000000000000000000'
      );

      if (!mintDetails[0]) {
        setMinter(transfers.result[transfers.result.length - 1]);
      } else {
        setMinter(mintDetails[0]);
      }
    } catch (err) {
      logger.error(err);
      return null;
    }
  };

  const getActivityType = (activity) => {
    if (
      activity.from_address === '0x0000000000000000000000000000000000000000'
    ) {
      return 'Minted';
    }
    return 'Transfer';
  };

  const showDivision = (activity) => {
    if (
      getActivityType(activity) !== 'Minted' &&
      getActivityType(activity) !== 'Transfer'
    ) {
      return true;
    }
    if (!isMobile) {
      return true;
    }
    return false;
  };

  const walletVerifier = () => {
    if (user?.walletAddress) {
      unlockContentModal.onOpen();
    } else {
      walletModal.onOpen();
    }
  };

  return (
    <>
      {tokenData?.description && (
        <div
          className={cn(section1Styles.flex7, `${isNft ? 'mt-12' : 'mt-16'}`)}
        >
          <div className="font-semibold text-xl text-gray-700">Description</div>
          <h5
            className={cn(
              section1Styles.highlights2_layout,
              'font-medium text-gray-700'
            )}
          >
            {tokenData?.description}
          </h5>
        </div>
      )}

      {tokenData?.contractID && (
        <div className={cn(section1Styles.flex7, `mt-16`)}>
          <div className="font-semibold text-xl text-gray-700">Collection</div>
          <div className="flex mt-4">
            <div className="font-semibold text-gray-700 w-1/2 flex">
              <Svg1TMLogo width="40px" height="40px" />
              <div className="my-auto mx-2">Gallery 1:</div>
              <SvgGlobe width="20px" height="20px" className="my-auto" />
            </div>
            <div className="font-medium text-sm text-gray-500 text-end w-1/2 hidden">
              8,290 Assets
            </div>
          </div>
          <div className="text-sm font-medium text-gray-700 mt-4">
            Gallery One is the collection of best arts from 1TM. Collect 1-of-1
            arts and limited editions at Gallery One.
          </div>
        </div>
      )}

      {tokenData?.hasUnlockableContent && (
        <div className={cn(section1Styles.flex7, `mt-16`)}>
          <div className="font-semibold text-xl text-gray-700">
            Unlockable content
          </div>
          <div className="bg-gray-50 border rounded-lg border-dashed border-gray-300 overflow-hidden mt-4">
            <div className="my-8 px-8 mx-auto w-auto">
              <SvgLockedIcon height="40px" width="40px" className="m-auto" />
              <div className="font-medium text-sm text-gray-400 my-3 line-clamp-2 text-center">
                unlockable content can only be revealed by the owner of the
                item.
              </div>
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  walletVerifier();
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    walletVerifier();
                  }
                }}
                className="shadow m-auto bg-white text-blue-500 border rounded-full font-medium text-sm w-auto px-7 py-1.5 border-gray-300 w-max"
              >
                Reveal unlockable content
              </div>
            </div>
          </div>
        </div>
      )}

      {isNft && !isLazy && (
        <div
          className={cn(section1Styles.block7, section1Styles.block7_layout)}
        >
          <div className={cn(section1Styles.block7_item)}>
            <div
              className={cn(section1Styles.flex8, section1Styles.flex8_layout)}
            >
              <div
                className={cn(
                  section1Styles.small_text_body,
                  section1Styles.small_text_body_layout
                )}
              >
                OWNED BY
              </div>
              <SvgDefaultWallet
                className={cn(section1Styles.icon, section1Styles.icon_layout)}
              />
              <h5
                className={cn(
                  section1Styles.highlights,
                  section1Styles.highlights_layout1
                )}
              >
                {walletAddressTruncate(nft.owner_of)}
              </h5>
              <div
                className={cn(
                  section1Styles.paragraph_body,
                  section1Styles.paragraph_body_layout,
                  'hidden'
                )}
              >
                Visual artist working with algorithms, plotters, and paint.
                Sometimes I write about art on my site. Creator of Fidenza.
              </div>
              {/* <div className={cn(section1Styles.flex8_item)}>
                <div
                  className={cn(
                    section1Styles.cover_block21,
                    section1Styles.cover_block21_layout
                  )}
                >
                  <div
                    className={cn(
                      section1Styles.text_body,
                      section1Styles.text_body_layout
                    )}
                  >
                    {'Follow'}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}

      <div
        className={cn(
          section1Styles.block29,
          section1Styles.block29_layout,
          'mt-16'
        )}
      >
        <div
          className={cn(section1Styles.block28, section1Styles.block28_layout)}
        >
          <Tabs
            variant="unstyled"
            onChange={(index) => {
              setTabIndex(index);
            }}
          >
            <TabList>
              <Tab
                style={{
                  padding: '0',
                  display: `${
                    tokenProp?.length > 0 ||
                    tokenLevel?.length > 0 ||
                    tokenStat?.length > 0
                      ? 'flex'
                      : 'none'
                  }`,
                }}
                className={cn(
                  section1Styles.flex6,
                  section1Styles.flex6_layout
                )}
              >
                <div className={cn(section1Styles.flex6_item)}>
                  <div
                    className={cn(
                      'font-semibold text-xl',
                      `${tabIndex === 0 ? 'text-gray-700' : 'text-gray-400'}`
                    )}
                  >
                    Details
                  </div>
                </div>
              </Tab>

              {nftTransfers.length > 0 && (
                <>
                  <Tab
                    style={{ padding: '0' }}
                    className={cn(
                      section1Styles.flex6,
                      section1Styles.flex6_layout,
                      `${
                        tokenProp?.length > 0 ||
                        tokenLevel?.length > 0 ||
                        tokenStat?.length > 0
                          ? 'ml-8'
                          : ''
                      }`
                    )}
                  >
                    <div className={cn(section1Styles.flex6_item)}>
                      <div
                        className={cn(
                          'font-semibold text-xl',
                          `${
                            tabIndex === 1 ? 'text-gray-700' : 'text-gray-400'
                          }`
                        )}
                      >
                        Activities
                      </div>
                    </div>
                  </Tab>
                </>
              )}
            </TabList>
          </Tabs>
        </div>
      </div>

      {tabIndex === 0 && (
        <>
          {tokenProp?.length > 0 && (
            <div
              className={cn(
                section1Styles.block8,
                section1Styles.block8_layout
              )}
            >
              <div
                className={cn(
                  section1Styles.small_text_body,
                  section1Styles.small_text_body_layout
                )}
              >
                PROPERTIES
              </div>

              <div
                className={cn(
                  section1Styles.flex13_layout,
                  'grid grid-cols-2 md:grid-cols-4 gap-4'
                )}
              >
                {tokenProp?.map((attr) => (
                  <>
                    {attr.trait_type && (
                      <div
                        className={cn(section1Styles.flex13_item, 'col-span-1')}
                      >
                        <div
                          className={cn(
                            section1Styles.cover_block10,
                            section1Styles.cover_block10_layout
                          )}
                        >
                          <div
                            className={cn(
                              section1Styles.flex10,
                              section1Styles.flex10_layout
                            )}
                          >
                            <div
                              className={cn(
                                section1Styles.flex14,
                                section1Styles.flex14_layout
                              )}
                            >
                              <div
                                style={
                                  {
                                    '--src': `url(${
                                      require('public/assets_pxcode/63865f2c50f73eea1200ed9d4740eab4.png')
                                        .default
                                    })`,
                                  } as React.CSSProperties
                                }
                                className={cn(
                                  section1Styles.image6,
                                  section1Styles.image6_layout
                                )}
                              />
                              <div
                                className={cn(section1Styles.flex1_spacer)}
                              />
                              <div
                                style={
                                  {
                                    '--src': `url(${
                                      require('public/assets_pxcode/11ab108154281f417692f9351b491dc3.png')
                                        .default
                                    })`,
                                  } as React.CSSProperties
                                }
                                className={cn(
                                  section1Styles.icon1,
                                  section1Styles.icon1_layout
                                )}
                              />
                            </div>

                            <div
                              className={cn(
                                section1Styles.flex2,
                                section1Styles.flex2_layout
                              )}
                            >
                              <div
                                className={cn(
                                  section1Styles.small_text_body1_box,
                                  section1Styles.small_text_body1_box_layout
                                )}
                              >
                                <div
                                  className={cn(
                                    section1Styles.small_text_body1,
                                    'text-ellipsis'
                                  )}
                                >
                                  {attr.trait_type}
                                </div>
                              </div>
                              <div
                                className={cn(
                                  section1Styles.text_body1_box,
                                  section1Styles.text_body1_box_layout
                                )}
                              >
                                <div
                                  style={{
                                    minHeight: '17.9px',
                                  }}
                                  className={cn(
                                    section1Styles.text_body1,
                                    'text-ellipsis'
                                  )}
                                >
                                  {attr.value}
                                </div>
                              </div>
                              <div
                                className={cn(
                                  section1Styles.small_text_body2,
                                  section1Styles.small_text_body2_layout,
                                  'hidden'
                                )}
                              >
                                {/* {'95% rarity'} */}
                              </div>
                            </div>

                            <div
                              className={cn(
                                section1Styles.flex3,
                                section1Styles.flex3_layout1
                              )}
                            >
                              <div
                                style={
                                  {
                                    '--src': `url(${
                                      require('public/assets_pxcode/1fef0e88dee17eba4304308d656d040d.png')
                                        .default
                                    })`,
                                  } as React.CSSProperties
                                }
                                className={cn(
                                  section1Styles.image7,
                                  section1Styles.image7_layout
                                )}
                              />
                              <div
                                className={cn(section1Styles.flex3_spacer3)}
                              />
                              <div
                                style={
                                  {
                                    '--src': `url(${
                                      require('public/assets_pxcode/d3bbbcfe4de1a758a85d7fa73b84302f.png')
                                        .default
                                    })`,
                                  } as React.CSSProperties
                                }
                                className={cn(
                                  section1Styles.icon1,
                                  section1Styles.icon1_layout
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}

          {tokenLevel?.length > 0 && (
            <div
              className={cn(
                section1Styles.block8,
                section1Styles.block8_layout
              )}
            >
              <div
                className={cn(
                  section1Styles.small_text_body,
                  section1Styles.small_text_body_layout
                )}
              >
                LEVELS
              </div>

              <div
                className={cn(
                  section1Styles.flex13_layout,
                  'grid grid-cols-2 md:grid-cols-4 gap-4'
                )}
              >
                {tokenLevel?.map((attr) => (
                  <>
                    {attr.trait_type && (
                      <div
                        className={cn(section1Styles.flex13_item, 'col-span-2')}
                      >
                        <div
                          className={cn(
                            section1Styles.cover_block10,
                            section1Styles.cover_block10_layout
                          )}
                        >
                          <div
                            className={cn(
                              section1Styles.flex10,
                              section1Styles.flex10_layout
                            )}
                          >
                            <div className={cn(section1Styles.flex2, 'm-2')}>
                              <div className="flex text-xs font-medium">
                                {attr.trait_type.length > 30 && !isTablet && (
                                  <Tooltip
                                    label={attr.trait_type}
                                    placement="top"
                                    backgroundColor="#242526"
                                    color="white"
                                    borderRadius="4px"
                                    border="1px solid #4F5356"
                                    p="12px"
                                  >
                                    <div className="text-gray-700 line-clamp-1 w-full">
                                      {attr.trait_type}
                                    </div>
                                  </Tooltip>
                                )}
                                {attr.trait_type.length > 30 && isTablet && (
                                  <Popover>
                                    <PopoverTrigger>
                                      <div className="text-gray-700 line-clamp-1 w-full">
                                        {attr.trait_type}
                                      </div>
                                    </PopoverTrigger>
                                    <PopoverContent
                                      _focus={{ outline: 'none' }}
                                    >
                                      <div
                                        style={{
                                          borderRadius: '4px',
                                          border: '1px solid #4F5356',
                                        }}
                                        className="text-xs text-white p-3 bg-gray-900"
                                      >
                                        {attr.trait_type}
                                      </div>
                                    </PopoverContent>
                                  </Popover>
                                )}
                                {attr.trait_type.length < 30 && (
                                  <div className="text-gray-700 line-clamp-1 w-full">
                                    {attr.trait_type}
                                  </div>
                                )}
                                <div
                                  style={{ maxWidth: '65px' }}
                                  className="text-gray-500 w-full text-end"
                                >
                                  {attr.value}{' '}
                                  {attr.max_value && <span>of </span>}
                                  {attr.max_value}
                                </div>
                              </div>
                              <Progress
                                size="sm"
                                value={(attr.value / attr.max_value) * 100}
                                className="progress-bar"
                                height="9px"
                                borderRadius="5px"
                                marginTop="6px"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}

          {tokenStat?.length > 0 && (
            <div
              className={cn(
                section1Styles.block8,
                section1Styles.block8_layout
              )}
            >
              <div
                className={cn(
                  section1Styles.small_text_body,
                  section1Styles.small_text_body_layout
                )}
              >
                STATS
              </div>

              <div
                className={cn(
                  section1Styles.flex13_layout,
                  'grid grid-cols-3 md:grid-cols-6 gap-4'
                )}
              >
                {tokenStat?.map((attr) => (
                  <>
                    {attr.trait_type && (
                      <div
                        className={cn(section1Styles.flex13_item, 'col-span-1')}
                      >
                        <div
                          className={cn(
                            section1Styles.cover_block10,
                            section1Styles.cover_block10_layout
                          )}
                        >
                          <div
                            className={cn(
                              section1Styles.flex10,
                              section1Styles.flex10_layout
                            )}
                          >
                            <div className={cn(section1Styles.flex2, 'm-2')}>
                              <div className="flex text-xs font-medium">
                                {attr.trait_type.length > 10 && !isTablet && (
                                  <Tooltip
                                    label={attr.trait_type}
                                    placement="top"
                                    backgroundColor="#242526"
                                    color="white"
                                    borderRadius="4px"
                                    border="1px solid #4F5356"
                                    p="12px"
                                  >
                                    <div className="text-gray-700 line-clamp-1 w-full">
                                      {attr.trait_type}
                                    </div>
                                  </Tooltip>
                                )}
                                {attr.trait_type.length > 10 && isTablet && (
                                  <Popover>
                                    <PopoverTrigger>
                                      <div className="text-gray-700 line-clamp-1 w-full">
                                        {attr.trait_type}
                                      </div>
                                    </PopoverTrigger>
                                    <PopoverContent
                                      _focus={{ outline: 'none' }}
                                    >
                                      <div
                                        style={{
                                          borderRadius: '4px',
                                          border: '1px solid #4F5356',
                                        }}
                                        className="text-xs text-white p-3 bg-gray-900"
                                      >
                                        {attr.trait_type}
                                      </div>
                                    </PopoverContent>
                                  </Popover>
                                )}
                                {attr.trait_type.length < 10 && (
                                  <div className="text-gray-700 line-clamp-1 w-full">
                                    {attr.trait_type}
                                  </div>
                                )}
                              </div>
                              <div className="text-gray-500 w-full text-end text-xs mt-1.5">
                                <span className="font-semibold text-gray-700 text-base">
                                  {attr.value}
                                </span>{' '}
                                of {attr.max_value}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {tabIndex === 1 && (
        <div className={cn(section1Styles.flex16_item8)}>
          <div
            className={cn(section1Styles.flex27, section1Styles.flex27_layout)}
          >
            {nftTransfers?.map((trnsfers) => (
              <div
                className={cn(
                  section1Styles.block51,
                  section1Styles.block51_layout,
                  'mb-6'
                )}
              >
                <div
                  className={cn(
                    section1Styles.flex78,
                    section1Styles.flex78_layout
                  )}
                >
                  <div className={cn(section1Styles.flex78_item, 'w-full')}>
                    <div
                      className={cn(
                        section1Styles.block34,
                        section1Styles.block34_layout
                      )}
                    >
                      <div
                        style={{
                          width: `${isMobile ? '100%' : '144px'}`,
                          minWidth: `${isMobile ? '200px' : '144px'}`,
                        }}
                        className={cn(section1Styles.block34_item)}
                      >
                        <div
                          className={cn(
                            section1Styles.flex791,
                            section1Styles.flex791_layout
                          )}
                        >
                          <div className={cn(section1Styles.flex79_item)}>
                            <div
                              className={cn(
                                section1Styles.text_body12,
                                section1Styles.text_body12_layout
                              )}
                            >
                              {getActivityType(trnsfers)}
                            </div>
                          </div>
                          <div className={cn(section1Styles.flex79_item)}>
                            <div
                              className={cn(
                                section1Styles.small_text_body12,
                                section1Styles.small_text_body12_layout
                              )}
                            >
                              {dayHepler.format(
                                trnsfers.block_timestamp,
                                'MMM D, YYYY'
                              )}{' '}
                              at{' '}
                              {dayHepler.format(
                                trnsfers.block_timestamp,
                                'h:mma'
                              )}
                            </div>
                          </div>
                          <div className="flex w-full">
                            <div className={cn(section1Styles.flex79_item2)}>
                              <div
                                className={cn(
                                  'flex',
                                  section1Styles.flex163_layout
                                )}
                              >
                                <div className="pr-2">
                                  <SvgDefaultWallet
                                    className={cn(
                                      section1Styles.icon1,
                                      section1Styles.icon1_layout1
                                    )}
                                  />
                                </div>
                                <div
                                  className={cn(section1Styles.flex16_item9)}
                                >
                                  <div
                                    className={cn(
                                      section1Styles.text_body13,
                                      section1Styles.text_body13_layout
                                    )}
                                  >
                                    {getActivityType(trnsfers) === 'Minted'
                                      ? walletAddressTruncate(
                                          trnsfers.to_address
                                        )
                                      : walletAddressTruncate(
                                          trnsfers.from_address
                                        )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {getActivityType(trnsfers) === 'Transfer' &&
                              isMobile && (
                                <>
                                  <div className="w-full m-auto">
                                    <SvgArrowForward
                                      width="24px"
                                      height="24px"
                                      className="mt-2 mb-0 mx-auto"
                                    />
                                  </div>
                                  <div
                                    className={cn(section1Styles.flex79_item2)}
                                  >
                                    <div
                                      className={cn(
                                        'flex',
                                        section1Styles.flex163_layout
                                      )}
                                    >
                                      <div className="pr-2">
                                        <SvgDefaultWallet
                                          className={cn(
                                            section1Styles.icon1,
                                            section1Styles.icon1_layout1
                                          )}
                                        />
                                      </div>
                                      <div
                                        className={cn(
                                          section1Styles.flex16_item9
                                        )}
                                      >
                                        <div
                                          className={cn(
                                            section1Styles.text_body13,
                                            section1Styles.text_body13_layout
                                          )}
                                        >
                                          {walletAddressTruncate(
                                            trnsfers.to_address
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                      {showDivision(trnsfers) && (
                        <div className={cn(section1Styles.flex16_item10)}>
                          <div
                            style={
                              {
                                '--src': `url(${
                                  require('public/assets_pxcode/39d329d643f4bad1112794167250a548.png')
                                    .default
                                })`,
                              } as React.CSSProperties
                            }
                            className={cn(
                              section1Styles.image61,
                              section1Styles.image61_layout
                            )}
                          />
                        </div>
                      )}
                      {/* <div
                        className={cn(
                          section1Styles.flex16_spacer6
                        )}
                      /> */}
                      <div
                        className={`w-full flex pl-4 ${isMobile && 'hidden'}`}
                      >
                        <div className="flex">
                          <div className={cn(section1Styles.flex16_item11)}>
                            <SvgDefaultWallet
                              className={cn(
                                section1Styles.icon1,
                                section1Styles.icon1_layout1
                              )}
                            />
                          </div>
                          <div className={cn(section1Styles.flex16_spacer7)} />
                          <div className={cn(section1Styles.flex16_item12)}>
                            <div
                              className={cn(
                                section1Styles.text_body14,
                                section1Styles.text_body14_layout
                              )}
                            >
                              {getActivityType(trnsfers) === 'Minted'
                                ? walletAddressTruncate(trnsfers.to_address)
                                : walletAddressTruncate(trnsfers.from_address)}
                            </div>
                          </div>
                        </div>

                        {getActivityType(trnsfers) === 'Transfer' && !isMobile && (
                          <>
                            <div className="w-full m-auto">
                              <SvgArrowForward
                                width="24px"
                                height="24px"
                                className="m-auto"
                              />
                            </div>
                            <div className="flex">
                              <div className={cn(section1Styles.flex16_item11)}>
                                <SvgDefaultWallet
                                  className={cn(
                                    section1Styles.icon1,
                                    section1Styles.icon1_layout1
                                  )}
                                />
                              </div>
                              <div
                                className={cn(section1Styles.flex16_spacer7)}
                              />
                              <div className={cn(section1Styles.flex16_item12)}>
                                <div
                                  className={cn(
                                    section1Styles.text_body14,
                                    section1Styles.text_body14_layout
                                  )}
                                >
                                  {walletAddressTruncate(trnsfers.to_address)}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!isLazy && (
        <div
          className={cn(section1Styles.block10, section1Styles.block10_layout)}
        >
          <div className="font-semibold text-xl text-gray-700 mb-4">
            View on Marketplace
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3">
            {blockChainLinks.map((details) => (
              <>
                {details.name !== 'Metadata' && (
                  <div
                    className="flex py-2"
                    role="button"
                    tabIndex={0}
                    onClick={() => getDetailLinks(details.link)}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        getDetailLinks(details.link);
                      }
                    }}
                  >
                    <div className="mr-2">{getDetailIcons(details.link)}</div>
                    <div className="text-gray-700 font-semibold">
                      View {details.name}
                    </div>
                  </div>
                )}
                {nft?.tokenUri?.raw &&
                  nft.tokenUri.raw.includes('http') &&
                  details.name === 'Metadata' && (
                    <div
                      className="flex py-2"
                      role="button"
                      tabIndex={0}
                      onClick={() => getDetailLinks(details.link)}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          getDetailLinks(details.link);
                        }
                      }}
                    >
                      <div className="mr-2">{getDetailIcons(details.link)}</div>
                      <div className="text-gray-700 font-semibold">
                        View {details.name}
                      </div>
                    </div>
                  )}
              </>
            ))}
          </div>
        </div>
      )}
      <WalletModal {...walletModal} isVerify />
      <Modal
        size="2xl"
        isOpen={unlockContentModal.isOpen}
        onClose={unlockContentModal.onClose}
        isCentered
      >
        <ModalOverlay>
          <ModalContent
            bg="$mode.50"
            p="40px"
            w="530px"
            h="auto"
            maxh="80vh"
            borderRadius="24px"
          >
            <ModalCloseButton _hover={{ background: 'none' }} />
            <Center flexDirection="column">
              <Heading as="h3" textStyle="h3" color="black">
                <Text
                  noOfLines={2}
                  textAlign="center"
                  fontSize="20px"
                  className="font-semibold text-gray-900"
                >
                  Unlocked Content
                </Text>
              </Heading>
              {tokenData?.unlockableType === 'image' && (
                <div
                  style={{
                    width: '450px',
                    height: '300px',
                    borderRadius: '8px',
                  }}
                  className="overflow-hidden mt-6 relative"
                >
                  <div
                    style={{
                      backgroundColor: '#1919194d',
                      borderRadius: '25px',
                    }}
                    className="absolute right-4 top-4 p-2.5 z-10"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      lightroomContentModal.onOpen();
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        lightroomContentModal.onOpen();
                      }
                    }}
                  >
                    <SvgGallery
                      height="20px"
                      width="20px"
                      className="text-white"
                    />
                  </div>
                  <div
                    className={cn(
                      section1Styles.image23_layout,
                      `overflow-hidden h-full`
                    )}
                  >
                    <Box
                      style={{ zIndex: '2' }}
                      position="relative"
                      className="h-full"
                    >
                      <Image
                        style={{
                          aspectRatio: 'auto',
                          objectFit: 'cover',
                        }}
                        className="h-full"
                        loading="lazy"
                        zIndex="3"
                        w="100%"
                        src={tokenData?.unlockableContent}
                      />
                    </Box>
                    <Box
                      style={{ aspectRatio: '1', zIndex: '1' }}
                      className="absolute inset-0 m-auto"
                      height="100%"
                    >
                      <div style={{ height: '100%' }} className="bg-gray-100">
                        <div
                          style={{
                            backgroundImage: `url(${require('public/images/assets/spinner.gif')})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                          }}
                          className={cn(
                            styles.postgrid_more,
                            styles.postgrid_more_layout,
                            'absolute m-auto inset-0'
                          )}
                        />
                      </div>
                    </Box>
                  </div>
                </div>
              )}
              <Text
                textAlign="center"
                className="font-medium text-gray-700 text-sm py-6"
              >
                &quot;{tokenData?.unlockableDescription}&quot;
              </Text>
              {tokenData?.unlockableType === 'text' && (
                <div
                  style={{ width: '400px', height: '120px' }}
                  className="border border-gray-200 bg-white rounded-lg overflow-hidden p-6 mb-6"
                >
                  <div className="text-gray-700 font-semibold">
                    {tokenData?.unlockableContent}
                  </div>
                </div>
              )}
              {tokenData?.unlockableType === 'text' ? (
                <div
                  style={{ borderRadius: '20px' }}
                  className="overflow-hidden bg-blue-500 cursor-pointer"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    copy(tokenData?.unlockableContent);
                    toast({
                      position: 'bottom-right',
                      duration: 3000,
                      render: (r) => (
                        <CustomToast
                          icon=""
                          title="Copied!"
                          description="Text has been copied to clipboard!"
                          {...r}
                        />
                      ),
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      copy(tokenData?.unlockableContent);
                      toast({
                        position: 'bottom-right',
                        duration: 3000,
                        render: (r) => (
                          <CustomToast
                            icon=""
                            title="Copied!"
                            description="Text has been copied to clipboard!"
                            {...r}
                          />
                        ),
                      });
                    }
                  }}
                >
                  <div className="py-2 px-8 text-white font-semibold">Copy</div>
                </div>
              ) : (
                <div
                  style={{ borderRadius: '20px' }}
                  className="overflow-hidden bg-blue-500 cursor-pointer"
                  role="button"
                  tabIndex={0}
                  onClick={() =>
                    window.open(tokenData?.unlockableContent, '_blank')
                  }
                  onKeyDown={() => {
                    window.open(tokenData?.unlockableContent, '_blank');
                  }}
                >
                  <div className="py-2 px-8 text-white font-semibold">
                    Download
                  </div>
                </div>
              )}
            </Center>
          </ModalContent>
        </ModalOverlay>
      </Modal>
      <Modal
        isOpen={lightroomContentModal.isOpen}
        onClose={lightroomContentModal.onClose}
        isCentered
      >
        <ModalContent
          w="100vw"
          maxW="100vw"
          h="100vh"
          position="fixed"
          background="#191919"
          borderRadius="0"
          py="45px"
        >
          <ModalCloseButton
            color="$mode.50"
            _focus={{ outline: 'none' }}
            zIndex="99"
          >
            <div
              className="p-2.5 fixed top-6 right-6 z-50"
              style={{
                background: 'rgba(255, 255, 255, 0.3)',
                borderRadius: '22px',
              }}
            >
              <SvgCloseModal fontSize="24px" />
            </div>
          </ModalCloseButton>
          <ModalBody
            p={0}
            className={!isMobile && 'flex flex-col justify-around'}
          >
            <Box w="100%" h={{ base: '100%', md: '650px' }} m="auto">
              <Box
                overflow="hidden"
                borderRadius="10px"
                position="relative"
                h="70vh"
                margin="auto"
                display={isMobile ? 'flex' : 'block'}
                alignItems="center"
              >
                <div
                  style={{ maxWidth: '1500px' }}
                  className="rounded-lg overflow-hidden h-full m-auto w-full px-6 md:px-0"
                >
                  <NftImageContainer
                    image={{ imgURL: tokenData?.unlockableContent }}
                    animation
                    classname="h-full"
                    control
                  />
                </div>
              </Box>
            </Box>
            <style jsx global>{`
              .my-anchor-css-class {
                color: #74787d;
                font-size: 14px;
                font-weight: 400;
              }
              .content-css {
                color: #74787d;
                font-weight: 400;
                color: white;
                font-size: 14px;
              }
              .chakra-modal__content {
                position: relative;
                padding: 0;
              }
              .chakra-modal__close-btn {
                position: absolute;
              }
            `}</style>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export function CreativeAsset({
  nft,
  isOwner = false,
  isLazy = false,
}: {
  nft?: any;
  isOwner?: boolean;
  isLazy?: boolean;
}) {
  // const { Moralis } = useMoralis();
  const isTablet = useIsTablet();
  const lightroomModal = useDisclosure();
  const router = useRouter();
  const isMobile = useIsMobile();
  const supportedChains = [
    'eth',
    'polygon',
    'bsc',
    '0x1',
    'ropsten',
    '0x3',
    'rinkeby',
    '0x4',
    'goerli',
    '0x5',
    'kovan',
    '0x2a',
    '0x89',
    'mumbai',
    '0x13881',
    '0x38',
    'bsc testnet',
    '0x61',
    'avalanche',
    '0xa86a',
    'avalanche testnet',
    '0xa869',
    'fantom',
    '0xfa',
  ];
  const [minter, setMinter] = useState<any>();
  const [tokenData, setTokenData] = useState<any>();
  const [usdPrice, setUsdPrice] = useState<any>(0);
  const mintExplainerModal = useDisclosure();
  const afterMintingModal = useDisclosure();
  const mintSuccessfulModal = useDisclosure();

  // const mintableAuthorship = true;
  const getNFTimageURL = async (NFTfile) => {
    const newNFT = { ...NFTfile };
    if (NFTfile?.animation_url) {
      newNFT.animation_url = NFTfile?.animation_url;
    }
    if (NFTfile?.animationUrl) {
      newNFT.animation_url = NFTfile?.animationUrl;
    }
    if (NFTfile?.image) {
      if (NFTfile?.image?.startsWith('ipfs://')) {
        if (NFTfile?.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('ipfs://')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('ipfs://')[1]
          }`;
        }
      } else if (
        NFTfile?.image?.startsWith('https://gateway.pinata.cloud/ipfs/')
      ) {
        if (NFTfile?.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        }
      } else {
        newNFT.imgURL = NFTfile.image;
      }
    } else if (NFTfile?.image_url) {
      if (NFTfile?.image_url?.startsWith('ipfs://')) {
        if (NFTfile?.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('ipfs://')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('ipfs://')[1]
          }`;
        }
      } else if (
        NFTfile?.image_url?.startsWith('https://gateway.pinata.cloud/ipfs/')
      ) {
        if (NFTfile?.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        }
      } else {
        newNFT.imgURL = NFTfile.image_url;
      }
    }
    if (newNFT.imgURL?.endsWith('.mp4')) {
      const fetchMIME = await fetchMIMEType(newNFT.imgURL);
      newNFT.fileType = fetchMIME;
    }
    return newNFT;
  };

  const fetchMIMEType = async (uri) => {
    const proxyUrl = 'https://corsa.1tm.io/';
    const tokenRes = await fetch(`${proxyUrl}${uri}`).then((response) => {
      return response.headers.get('Content-Type');
    });
    return tokenRes;
  };

  const getTokenUri = async (uri) => {
    const proxyUrl = 'https://corsa.1tm.io/';
    const tokenRes = await fetch(`${proxyUrl}${uri}`)
      .then((res) => res.json())
      .catch((err) => logger.error(err));
    return tokenRes || null;
  };

  const walletAddressTruncate = (address) => {
    if (address) {
      return `${address.slice(0, 5)}...${address.slice(
        address.length - 4,
        address.length
      )}`;
    }
    return '';
  };

  useEffect(() => {
    async function getNFTImage() {
      if (
        nft?.media?.[0]?.raw &&
        !nft?.media?.[0]?.raw.includes('mp4') &&
        !nft?.media?.[0]?.raw.includes('ipfs://')
      ) {
        if (!tokenData) {
          setTokenData({
            ...nft,
            name: nft.title || Number(nft.id.tokenId),
            attributes: nft.metadata.attributes,
            imgURL: nft.media[0].raw,
          });
        }
      } else if (
        nft?.media?.[0]?.raw.includes('mp4') ||
        nft?.media?.[0]?.raw.includes('ipfs://')
      ) {
        const nftData = await getNFTimageURL({
          ...nft,
          name: nft.title || Number(nft.id.tokenId),
          attributes: nft.metadata.attributes,
          image: nft.media[0].raw,
        });
        if (!tokenData) {
          setTokenData(nftData);
        }
      } else {
        let nftData;
        let fromURI;
        if (nft?.tokenUri?.raw?.includes('data:application/json;utf8')) {
          fromURI = JSON.parse(
            nft.tokenUri?.raw?.split('data:application/json;utf8,')[1]
          );
          nftData = await getNFTimageURL({ ...fromURI, owner: nft.owner });
        } else {
          fromURI = await getTokenUri(nft.token_uri);
          nftData = await getNFTimageURL({ ...fromURI, owner: nft.owner });
        }
        if (!tokenData) {
          setTokenData(nftData);
        }
      }
    }

    async function getFileType() {
      const fileData = await fetchMIMEType(nft.animationUrl);
      setTokenData({
        ...nft,
        animation_url: nft.animationUrl,
        fileType: fileData,
        imgURL: nft.imageUrl,
      });
    }

    if (!nft?.author && Object.keys(nft).length > 0 && !isLazy) {
      getNFTImage();
    } else if (Object.keys(nft).length > 0 && isLazy) {
      if (nft.animationUrl) {
        getFileType();
      } else {
        setTokenData({
          ...nft,
          animation_url: nft.animationUrl,
          imgURL: nft.imageUrl,
        });
      }
      if (nft.price) {
        fetchEth(nft.price);
      }
    }
  }, [nft]);

  // const reSyncMetadata = async (token) => {
  //   const options = {
  //     address: token.token_address,
  //     token_id: token.token_id,
  //     chain: supportedChains[0] as Chains,
  //     flag: 'metadata' as Flag,
  //   };
  //   try {
  //     const result = (await Moralis.Web3API.token.reSyncMetadata(
  //       options
  //     )) as any;
  //     if (result?.status === 'Request initiated') {
  //       toast({
  //         position: 'bottom-right',
  //         duration: 3000,
  //         render: (r) => (
  //           <CustomToast
  //             icon="error"
  //             title="Item queued for an update"
  //             description="Please check back in a minute..."
  //             {...r}
  //           />
  //         ),
  //       });
  //     }
  //     return result;
  //   } catch (err) {
  //     toast({
  //       position: 'bottom-right',
  //       duration: 3000,
  //       render: (r) => (
  //         <CustomToast
  //           icon="error"
  //           title="Error occured"
  //           description={err}
  //           {...r}
  //         />
  //       ),
  //     });
  //     return null;
  //   }
  // };

  const assetMoreItems = [
    {
      type: 'html',
      path: `/`,
      displayName: '',
      html: <a href="mailto:workwithus@1tm.io">Report</a>,
      action: () => {},
    },
  ];

  const fetchEth = async (ethAmount) => {
    const ethValue = await getETHPrice();
    const usdValue = Number(ethAmount * ethValue).toFixed(2);
    setUsdPrice(usdValue);
  };

  if (!tokenData || !nft) return <></>;

  return (
    <section
      className={cn(
        section1Styles.section1,
        section1Styles.section1_layout,
        `${!tokenData && 'hidden'}`
      )}
    >
      <div className={cn(section1Styles.group, section1Styles.group_layout)}>
        <div style={{ zIndex: '1' }} className={cn(detailStyles.main_item)}>
          <div className={cn(detailStyles.group, detailStyles.group_layout3)}>
            <div
              style={{
                height: `${isTablet ? 'fit-content' : '80vh'}`,
                maxHeight: '864px',
              }}
              className="bg-gray-100 relative"
            >
              <div
                style={{
                  height: '75%',
                  maxHeight: '648px',
                  margin: `${isTablet ? '12vh auto' : 'auto'}`,
                }}
                className="inset-0 md:absolute"
              >
                <div
                  style={{ maxWidth: '1500px' }}
                  className="rounded-lg overflow-hidden m-auto w-full px-6 md:px-0 md:h-full"
                >
                  {(tokenData?.imgURL || tokenData?.animation_url) && (
                    <NftImageContainer
                      image={tokenData}
                      animation
                      classname="md:h-full"
                      control
                    />
                  )}
                </div>
              </div>
              <div
                style={{ zIndex: '2' }}
                className="absolute right-10 bottom-10"
                role="button"
                tabIndex={0}
                onClick={() => lightroomModal.onOpen()}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    lightroomModal.onOpen();
                  }
                }}
              >
                <SvgGallery
                  style={{ color: '#9B9FA4' }}
                  height="32px"
                  width="32px"
                />
              </div>
            </div>
            {isOwner && (
              <div className="authorship-section bg-gray-200 py-5 px-6 sm:flex sm:gap-2.5 md:py-0 justify-center hidden">
                <div className="text-xs font-medium text-gray-900 my-auto pb-2.5 sm:p-0 md:text-base text-center sm:text-left">
                  As one of the authors, you have option of minting an
                  Authorship Token to protect your ownership rights.
                </div>
                <div className="flex gap-2.5 justify-center">
                  <div
                    style={{ height: 'fit-content' }}
                    className="font-medium text-blue-500 text-xs py-1.5 px-7 border border-blue-500 rounded-full md:my-5 cursor-pointer whitespace-nowrap"
                  >
                    Mint now
                  </div>
                  <div
                    role="button"
                    tabIndex={0}
                    style={{ height: 'fit-content' }}
                    className="font-medium text-blue-500 text-xs py-1.5 px-7 border border-blue-500 rounded-full md:my-5 cursor-pointer whitespace-nowrap"
                    onClick={mintExplainerModal.onOpen}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        mintExplainerModal.onOpen();
                      }
                    }}
                  >
                    Learn more
                  </div>
                </div>
              </div>
            )}
            <div
              className={cn(detailStyles.block28, detailStyles.block28_layout)}
            >
              <div className={cn(detailStyles.block28_item)}>
                <div
                  className={cn(
                    detailStyles.block29,
                    detailStyles.block29_layout
                  )}
                />
              </div>
              <div className={cn(detailStyles.block28_item1)}>
                <div
                  className={cn(
                    detailStyles.block1,
                    detailStyles.block1_layout
                  )}
                >
                  <div
                    className={cn(detailStyles.block1_item)}
                    style={{ paddingBottom: '70px' }}
                  >
                    <div
                      className={cn(
                        detailStyles.cover_block1,
                        detailStyles.cover_block1_layout,
                        'sticky top-0 h-auto'
                      )}
                    >
                      <div
                        className={cn(
                          detailStyles.block19,
                          detailStyles.block19_layout
                        )}
                      >
                        {tokenData?.price && (
                          <div
                            style={{ maxWidth: '340px', borderRadius: '32px' }}
                            className="border border-gray-300 overflow-hidden mb-12"
                          >
                            <div className="p-6 text-center bg-white">
                              <div className="font-semibold text-gray-900 mb-4">
                                Mint this creative asset
                              </div>
                              <div className="font-medium text-gray-500 text-sm mb-6 flex justify-center">
                                <SvgMailbox width="20px" height="20px" />
                                <div className="ml-2">
                                  {tokenData?.totalQuantity} of{' '}
                                  {tokenData?.totalQuantity} available
                                </div>
                              </div>
                              <button
                                onClick={mintSuccessfulModal.onOpen}
                                type="button"
                                className="font-semibold text-white bg-blue-500 rounded-full py-3 w-full mb-2"
                              >
                                <div className="flex m-auto w-max items-center">
                                  <div className="text-sm font-medium pr-2">
                                    Mint for
                                  </div>
                                  <div className="text-xl font-semibold">
                                    {tokenData?.price} ETH
                                  </div>
                                </div>
                              </button>
                              <div className="font-medium text-sm text-gray-500">
                                ${usdPrice}
                              </div>
                            </div>
                            <div className="justify-center bg-gray-50 border-t border-gray-200 font-medium text-sm text-blue-500 py-4 text-center flex">
                              <SvgUsers width="20px" height="20px" />
                              <div className="ml-2">1 owner</div>
                            </div>
                          </div>
                        )}
                        {tokenData?.author && (
                          <div
                            className={cn(
                              detailStyles.flex,
                              detailStyles.flex_layout
                            )}
                          >
                            <div className="font-semibold text-xl text-gray-700">
                              Author
                            </div>
                            <div
                              onClick={() => {
                                router.push(`/${tokenData?.author?.username}`);
                              }}
                              onKeyDown={(e) => {
                                if (e.code === 'Enter') {
                                  router.push(
                                    `/${tokenData?.author?.username}`
                                  );
                                }
                              }}
                              role="button"
                              tabIndex={0}
                            >
                              <div className="relative cursor-pointer">
                                {tokenData?.author?.profileImgURL ? (
                                  <Avatar
                                    src={imageOptimize(
                                      tokenData?.author?.profileImgURL
                                    )}
                                    borderRadius="40"
                                    name={tokenData?.author?.username}
                                    author={tokenData?.author}
                                    width="56"
                                    height="56"
                                    className={cn(
                                      section1Styles.icon,
                                      section1Styles.icon_layout
                                    )}
                                  />
                                ) : (
                                  <SvgDefaultWallet
                                    className={cn(
                                      section1Styles.icon,
                                      section1Styles.icon_layout
                                    )}
                                  />
                                )}
                                <div className="font-medium text-xl text-gray-700 py-2">
                                  {getProfileFullNameOrUsername(
                                    tokenData?.author
                                  )}
                                </div>
                              </div>
                            </div>
                            {tokenData?.author?.about &&
                              tokenData?.author?.about !== '' && (
                                <div className="font-medium text-sm text-gray-500">
                                  {tokenData?.author?.about}
                                </div>
                              )}
                            {/* {!isMyEpisode && publish && (
                              <div
                                className={cn(detailStyles.flex_item)}
                                role="button"
                                tabIndex={0}
                                onClick={async () => {
                                  await onFollowUser();
                                  refetch();
                                }}
                                onKeyDown={async (e) => {
                                  if (e.code === 'Enter') {
                                    await onFollowUser();
                                    refetch();
                                  }
                                }}
                              >
                                <div
                                  className={cn(
                                    detailStyles.block2,
                                    detailStyles.block2_layout
                                  )}
                                >
                                  <div
                                    className={cn(
                                      detailStyles.text_body,
                                      detailStyles.text_body_layout
                                    )}
                                  >
                                    {tokenData?.author?.isFollowed
                                      ? 'Following'
                                      : 'Follow'}
                                  </div>
                                </div>
                              </div>
                            )} */}
                          </div>
                        )}

                        {nft?.totalOwners > 1 ? (
                          <div
                            className={cn(
                              detailStyles.flex,
                              detailStyles.flex_layout
                            )}
                          >
                            <SvgDefaultWallet
                              className={cn(
                                section1Styles.icon,
                                section1Styles.icon_layout
                              )}
                            />
                            <h5
                              className={cn(
                                detailStyles.highlights,
                                detailStyles.highlights_layout
                              )}
                            >
                              {nft?.totalOwners} owners
                            </h5>
                          </div>
                        ) : (
                          <>
                            {tokenData?.owner && (
                              <div
                                className={cn(
                                  detailStyles.flex,
                                  detailStyles.flex_layout
                                )}
                              >
                                <div className="font-semibold text-xl text-gray-700">
                                  OWNER
                                </div>
                                <div
                                  onClick={() => {
                                    if (nft?.author) {
                                      router.push(
                                        `/${tokenData?.owner?.username}`
                                      );
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                      if (nft?.author) {
                                        router.push(
                                          `/${tokenData?.owner?.username}`
                                        );
                                      }
                                    }
                                  }}
                                  role="button"
                                  tabIndex={0}
                                >
                                  <div className="relative cursor-pointer">
                                    {tokenData?.owner?.profileImgURL ? (
                                      <Avatar
                                        src={imageOptimize(
                                          tokenData?.owner?.profileImgURL
                                        )}
                                        borderRadius="40"
                                        name={tokenData?.owner?.username}
                                        author={tokenData?.owner}
                                        width="56"
                                        height="56"
                                        className={cn(
                                          section1Styles.icon,
                                          section1Styles.icon_layout
                                        )}
                                      />
                                    ) : (
                                      <SvgDefaultWallet
                                        className={cn(
                                          section1Styles.icon,
                                          section1Styles.icon_layout
                                        )}
                                      />
                                    )}
                                    <h5
                                      className={cn(
                                        detailStyles.highlights,
                                        detailStyles.highlights_layout
                                      )}
                                    >
                                      {!isLazy ? (
                                        <>{walletAddressTruncate(nft.owner)}</>
                                      ) : (
                                        <>
                                          {tokenData?.owner?.username ? (
                                            <>
                                              {getProfileFullNameOrUsername(
                                                tokenData?.owner
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {walletAddressTruncate(
                                                tokenData?.owner?.walletAddress
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </h5>
                                  </div>
                                </div>
                                {tokenData?.author?.motto &&
                                  tokenData?.author?.motto !== '' && (
                                    <div
                                      className={cn(
                                        detailStyles.paragraph_body,
                                        detailStyles.paragraph_body_layout
                                      )}
                                    >
                                      {tokenData?.author?.motto}
                                    </div>
                                  )}
                                {/* {!isMyEpisode && publish && (
                                  <div
                                    className={cn(detailStyles.flex_item)}
                                    role="button"
                                    tabIndex={0}
                                    onClick={async () => {
                                      await onFollowUser();
                                      refetch();
                                    }}
                                    onKeyDown={async (e) => {
                                      if (e.code === 'Enter') {
                                        await onFollowUser();
                                        refetch();
                                      }
                                    }}
                                  >
                                    <div
                                      className={cn(
                                        detailStyles.block2,
                                        detailStyles.block2_layout
                                      )}
                                    >
                                      <div
                                        className={cn(
                                          detailStyles.text_body,
                                          detailStyles.text_body_layout
                                        )}
                                      >
                                        {tokenData?.author?.isFollowed
                                          ? 'Following'
                                          : 'Follow'}
                                      </div>
                                    </div>
                                  </div>
                                )} */}
                              </div>
                            )}
                          </>
                        )}

                        {tokenData?.coAuthors?.length > 0 && (
                          <>
                            <div
                              className={cn(
                                detailStyles.block21,
                                detailStyles.block21_layout
                              )}
                            >
                              <div className="font-semibold text-xl text-gray-700">
                                Co-authors
                              </div>
                              <div className="mt-4 overflow-hidden">
                                {tokenData?.coAuthors
                                  ?.slice(0, 5)
                                  .map((coCreator) => (
                                    <div
                                      onClick={() =>
                                        router.push(`/${coCreator?.username}`)
                                      }
                                      onKeyDown={(e) => {
                                        if (e.code === 'Enter') {
                                          router.push(
                                            `/${coCreator?.username}`
                                          );
                                        }
                                      }}
                                      role="button"
                                      tabIndex={0}
                                    >
                                      <div
                                        className={cn(
                                          detailStyles.flex6,
                                          detailStyles.flex6_layout
                                        )}
                                      >
                                        <div
                                          className={cn(
                                            detailStyles.flex6_item
                                          )}
                                        >
                                          <Avatar
                                            src={imageOptimize(
                                              coCreator?.profileImgURL
                                            )}
                                            name={coCreator?.username}
                                            author={coCreator}
                                            width={40}
                                            height={40}
                                            wrapperProps={{
                                              m: 'auto',
                                            }}
                                            borderRadius="40"
                                            className={cn(
                                              detailStyles.icon1,
                                              detailStyles.icon1_layout3
                                            )}
                                            border="1px solid #ffffff66"
                                            svgClassName="bg-unset"
                                          />
                                        </div>
                                        <div
                                          className={cn(
                                            detailStyles.flex6_spacer
                                          )}
                                        />
                                        <div
                                          className={cn(
                                            detailStyles.flex8_item1,
                                            'w-full break-all'
                                          )}
                                        >
                                          <div
                                            className={cn(
                                              detailStyles.flex9,
                                              detailStyles.flex9_layout
                                            )}
                                          >
                                            <div
                                              className={cn(
                                                detailStyles.text_body1,
                                                detailStyles.text_body1_layout4,
                                                'line-clamp-1'
                                              )}
                                            >
                                              {getProfileFullNameOrUsername(
                                                coCreator
                                              )}
                                            </div>
                                            <div
                                              className={cn(
                                                detailStyles.text_body24,
                                                detailStyles.text_body24_layout,
                                                'line-clamp-1'
                                              )}
                                            >
                                              {coCreator.message}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <Flex
                                flexDirection="row-reverse"
                                justifyContent="flex-end"
                                className="mt-4"
                              >
                                {tokenData?.coAuthors?.slice(5).length ===
                                  1 && (
                                  <Avatar
                                    src={imageOptimize(
                                      tokenData?.coauthors?.slice(5)[0]
                                        ?.profileImgURL
                                    )}
                                    name={
                                      tokenData?.coauthors?.slice(5)[0]
                                        ?.username
                                    }
                                    author={tokenData?.coAuthors?.slice(5)[0]}
                                    width={40}
                                    height={40}
                                    wrapperProps={{
                                      m: 'auto',
                                    }}
                                    borderRadius="40"
                                    border="1px solid #ffffff66"
                                    svgClassName="bg-unset"
                                  />
                                )}

                                {tokenData?.coAuthors?.slice(5).length > 1 && (
                                  <>
                                    <div
                                      style={
                                        {
                                          '--src': `url(${
                                            require('public/assets_pxcode/b0302db965dbcfb45eb76da17c427746.svg')
                                              .default
                                          })`,
                                        } as React.CSSProperties
                                      }
                                      className={cn(
                                        detailStyles.block4,
                                        detailStyles.block4_layout
                                      )}
                                    />
                                    {tokenData?.coAuthors
                                      ?.slice(5)
                                      .slice(0, 3)
                                      ?.map((coCreator) => {
                                        return (
                                          <div
                                            key={coCreator?.userID}
                                            className="inline-block -mr-3.5"
                                          >
                                            <Avatar
                                              src={imageOptimize(
                                                coCreator?.profileImgURL
                                              )}
                                              name={coCreator?.username}
                                              author={coCreator}
                                              width={40}
                                              height={40}
                                              wrapperProps={{
                                                m: 'auto',
                                              }}
                                              borderRadius="40"
                                              border="1px solid #ffffff66"
                                              svgClassName="bg-unset"
                                            />
                                          </div>
                                        );
                                      })}
                                  </>
                                )}
                              </Flex>
                            </div>
                          </>
                        )}

                        {tokenData?.contributors?.length > 0 && (
                          <div
                            className={cn(
                              detailStyles.block22,
                              detailStyles.block22_layout
                            )}
                          >
                            <div className="font-semibold text-xl text-gray-700">
                              Contributors
                            </div>
                            <div className="mt-4 overflow-hidden">
                              {tokenData?.contributors
                                ?.slice(0, 5)
                                .map((contributor) => (
                                  <div
                                    onClick={() =>
                                      router.push(`/${contributor?.username}`)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.code === 'Enter') {
                                        router.push(
                                          `/${contributor?.username}`
                                        );
                                      }
                                    }}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    <div
                                      className={cn(
                                        detailStyles.flex8,
                                        detailStyles.flex8_layout
                                      )}
                                    >
                                      <div
                                        className={cn(detailStyles.flex8_item)}
                                      >
                                        <Avatar
                                          src={imageOptimize(
                                            contributor?.profileImgURL
                                          )}
                                          name={contributor?.username}
                                          author={contributor}
                                          width={40}
                                          height={40}
                                          wrapperProps={{
                                            m: 'auto',
                                          }}
                                          className={cn(
                                            detailStyles.icon1,
                                            detailStyles.icon1_layout4
                                          )}
                                          borderRadius="40"
                                          border="1px solid #ffffff66"
                                          svgClassName="bg-unset"
                                        />
                                      </div>
                                      <div
                                        className={cn(
                                          detailStyles.flex8_spacer
                                        )}
                                      />
                                      <div
                                        className={cn(
                                          detailStyles.flex8_item1,
                                          'w-full break-all'
                                        )}
                                      >
                                        <div
                                          className={cn(
                                            detailStyles.flex9,
                                            detailStyles.flex9_layout
                                          )}
                                        >
                                          <div
                                            className={cn(
                                              detailStyles.text_body1,
                                              detailStyles.text_body1_layout4,
                                              'line-clamp-1'
                                            )}
                                          >
                                            {getProfileFullNameOrUsername(
                                              contributor
                                            )}
                                          </div>
                                          <div
                                            className={cn(
                                              detailStyles.text_body24,
                                              detailStyles.text_body24_layout,
                                              'line-clamp-1'
                                            )}
                                          >
                                            {contributor.message}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                            <Flex
                              flexDirection="row-reverse"
                              justifyContent="flex-end"
                              className="mt-4"
                            >
                              {tokenData?.contributors?.slice(5).length ===
                                1 && (
                                <Avatar
                                  src={imageOptimize(
                                    tokenData?.contributors?.slice(5)[0]
                                      ?.profileImgURL
                                  )}
                                  name={
                                    tokenData?.contributors?.slice(5)[0]
                                      ?.username
                                  }
                                  author={tokenData?.contributors?.slice(5)[0]}
                                  width={40}
                                  height={40}
                                  wrapperProps={{
                                    m: 'auto',
                                  }}
                                  borderRadius="40"
                                  border="1px solid #ffffff66"
                                  svgClassName="bg-unset"
                                />
                              )}

                              {tokenData?.contributors?.slice(5).length > 1 && (
                                <>
                                  <div
                                    style={
                                      {
                                        '--src': `url(${
                                          require('public/assets_pxcode/b0302db965dbcfb45eb76da17c427746.svg')
                                            .default
                                        })`,
                                      } as React.CSSProperties
                                    }
                                    className={cn(
                                      detailStyles.block4,
                                      detailStyles.block4_layout
                                    )}
                                  />
                                  {tokenData?.contributors
                                    ?.slice(5)
                                    .slice(0, 3)
                                    ?.map((contributor) => {
                                      return (
                                        <div
                                          key={contributor?.userID}
                                          className="inline-block -mr-4"
                                        >
                                          <Avatar
                                            src={imageOptimize(
                                              contributor?.profileImgURL
                                            )}
                                            name={contributor?.username}
                                            author={contributor}
                                            width={40}
                                            height={40}
                                            wrapperProps={{
                                              m: 'auto',
                                            }}
                                            borderRadius="40"
                                            border="1px solid #ffffff66"
                                            svgClassName="bg-unset"
                                          />
                                        </div>
                                      );
                                    })}
                                </>
                              )}
                            </Flex>
                          </div>
                        )}

                        {arrayHasData(tokenData?.categories) && (
                          <>
                            <div
                              className={cn(
                                detailStyles.block23,
                                detailStyles.block23_layout
                              )}
                            >
                              <div className="font-semibold text-xl text-gray-700">
                                Categories
                              </div>
                              <div
                                className={cn(
                                  detailStyles.flex18,
                                  detailStyles.flex18_layout
                                )}
                              >
                                {tokenData.categories.map((s) => (
                                  <div
                                    className={cn(detailStyles.flex18_item)}
                                    key={s}
                                    role="button"
                                    onClick={() => {
                                      const name = s.replace(/ /g, '-');
                                      router.push(`/feed/${name}`);
                                    }}
                                    onKeyDown={() => {
                                      const name = s.replace(/ /g, '-');
                                      router.push(`/feed/${name}`);
                                    }}
                                    tabIndex={0}
                                  >
                                    <div
                                      className={cn(
                                        detailStyles.block5,
                                        detailStyles.block5_layout
                                      )}
                                    >
                                      <div
                                        className={cn(
                                          detailStyles.text_body3,
                                          detailStyles.text_body3_layout
                                        )}
                                      >
                                        {s}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}

                        {arrayHasData(tokenData?.tags) && (
                          <>
                            <div
                              className={cn(
                                detailStyles.block24,
                                detailStyles.block24_layout
                              )}
                            >
                              <div className="font-semibold text-xl text-gray-700">
                                Hashtags
                              </div>
                              <div
                                className={cn(
                                  detailStyles.flex19,
                                  detailStyles.flex19_layout
                                )}
                              >
                                {tokenData.tags.map((s) => (
                                  <div
                                    className={cn(detailStyles.flex19_item)}
                                    key={s}
                                    role="button"
                                    onClick={() => {
                                      const name = s.replace(/ /g, '-');
                                      router.push(`/feed/${name}`);
                                    }}
                                    onKeyDown={() => {
                                      const name = s.replace(/ /g, '-');
                                      router.push(`/feed/${name}`);
                                    }}
                                    tabIndex={0}
                                  >
                                    <div
                                      className={cn(
                                        detailStyles.block8,
                                        detailStyles.block8_layout
                                      )}
                                    >
                                      <div
                                        className={cn(
                                          detailStyles.text_body3,
                                          detailStyles.text_body3_layout
                                        )}
                                      >
                                        #{s}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}

                        {tokenData?.copyrights && (
                          <div
                            className={cn(
                              detailStyles.flex1,
                              detailStyles.flex1_layout
                            )}
                          >
                            <div className="font-semibold text-xl text-gray-700">
                              Copyright
                            </div>
                            <div className={cn(detailStyles.flex1_item)}>
                              <div
                                className={cn(
                                  detailStyles.flex20,
                                  detailStyles.flex20_layout
                                )}
                              >
                                <Copyright copyright={tokenData?.copyrights} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={cn(detailStyles.block1_item1)}>
                    <div
                      className={cn(
                        detailStyles.image2,
                        detailStyles.image2_layout
                      )}
                    />
                  </div>
                  <div className={cn(detailStyles.block1_item2, 'mt-10')}>
                    <div
                      className={cn(
                        detailStyles.group,
                        detailStyles.group_layout2
                      )}
                    >
                      <div
                        className={cn(
                          detailStyles.flex22,
                          detailStyles.flex22_layout,
                          'm-auto md:max-w-720 lg:max-detail xl:max-detail'
                        )}
                      >
                        <div className="mx-6 md:mx-0">
                          <div className={cn(detailStyles.flex16)}>
                            {/* {tokenData?.author && (
                              <div className={cn(detailStyles.flex16_item)}>
                                <div
                                  className={cn(
                                    detailStyles.flex12,
                                    detailStyles.flex12_layout1
                                  )}
                                >
                                  <div className={cn(detailStyles.flex12_item)}>
                                    {tokenData?.author?.profileImgURL ? (
                                      <Avatar
                                        src={imageOptimize(
                                          tokenData?.author?.profileImgURL
                                        )}
                                        borderRadius="40"
                                        name={tokenData?.author?.username}
                                        author={tokenData?.author}
                                        style={{
                                          height: '40px',
                                          width: '40px',
                                        }}
                                        className={cn(section1Styles.icon)}
                                      />
                                    ) : (
                                      <SvgDefaultWallet
                                        style={{
                                          height: '40px',
                                          width: '40px',
                                        }}
                                        className={cn(section1Styles.icon)}
                                      />
                                    )}
                                  </div>
                                  <div
                                    className={cn(detailStyles.flex12_spacer)}
                                  />
                                  <div
                                    className={cn(detailStyles.flex12_item2)}
                                  >
                                    <div
                                      className={cn(
                                        detailStyles.flex13,
                                        detailStyles.flex13_layout
                                      )}
                                    >
                                      <div className="font-semibold text-gray-900 text-sm">
                                        {walletAddressTruncate(
                                          tokenData?.author?.walletAddress
                                        )}
                                      </div>
                                      <div className="font-medium text-gray-700 text-sm">
                                        Author
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )} */}
                            <div
                              className={cn(
                                detailStyles.flex16_item1,
                                'md:flex-row'
                              )}
                            >
                              <h1
                                className={cn(
                                  detailStyles.hero_title,
                                  detailStyles.hero_title_layout_asset,
                                  'w-full'
                                )}
                              >
                                {tokenData?.name}
                              </h1>
                              <div
                                className="flex relative mt-4 md:mx-6 md:m-auto"
                                style={{ minWidth: '56px' }}
                              >
                                {/* <div
                                  className={cn(
                                    section1Styles.block38,
                                    section1Styles.block38_layout,
                                    'mx-1.5 my-auto'
                                  )}
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => reSyncMetadata(nft)}
                                  onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                      reSyncMetadata(nft);
                                    }
                                  }}
                                >
                                  <div
                                    style={
                                      {
                                        '--src': `url(${
                                          require('public/assets_pxcode/57f1b28db0d3c67a81617680fa3488cf.svg')
                                            .default
                                        })`,
                                      } as React.CSSProperties
                                    }
                                    className={cn(
                                      section1Styles.image3,
                                      section1Styles.image3_layout
                                    )}
                                  />
                                </div> */}
                                <Dropdowns
                                  items={[assetMoreItems]}
                                  width="100px"
                                  right={isTablet && '-60px'}
                                  // isClicked={() => handleMenuClick()}
                                  buttonComponent={
                                    <div
                                      className={cn(
                                        section1Styles.block38,
                                        section1Styles.block38_layout,
                                        'my-auto'
                                      )}
                                      style={{
                                        width: `${isTablet ? '40px' : '56px'}`,
                                        height: `${isTablet ? '32px' : '40px'}`,
                                      }}
                                    >
                                      <SvgMore
                                        height="24px"
                                        width="24px"
                                        className="m-auto"
                                      />
                                    </div>
                                  }
                                />
                              </div>
                            </div>
                            {minter?.block_timestamp && (
                              <div className={cn(detailStyles.flex16_item2)}>
                                <div
                                  className={cn(
                                    detailStyles.text_body29,
                                    detailStyles.text_body29_layout
                                  )}
                                >
                                  Created on{' '}
                                  {dayHepler.format(
                                    minter?.block_timestamp,
                                    'MMM D, YYYY'
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* <div className="mt-10">
                          <div
                            style={{ aspectRatio: '16/9' }}
                            className="rounded-lg overflow-hidden"
                          >
                            <NftImageContainer
                              image={tokenData}
                              ratio="16/9"
                              animation
                            />
                          </div>
                        </div> */}

                        <div className="mx-6 md:mx-0">
                          {/* <div
                            style={{ height: '44px' }}
                            className="w-full flex justify-center relative"
                          >
                            <span
                              style={{ color: '#C4C4C4', fontSize: '43px' }}
                              className="absolute"
                            >
                              . . .
                            </span>
                          </div> */}

                          <TokenDetail
                            tokenData={tokenData}
                            nft={nft}
                            supportedChains={supportedChains}
                            isLazy={isLazy}
                            setMinter={(e) => setMinter(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={cn(detailStyles.block28_spacer)} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={lightroomModal.isOpen}
        onClose={lightroomModal.onClose}
        isCentered
      >
        <ModalContent
          w="100vw"
          maxW="100vw"
          h="100vh"
          position="fixed"
          background="#191919"
          borderRadius="0"
          py="45px"
        >
          <ModalCloseButton
            color="$mode.50"
            _focus={{ outline: 'none' }}
            zIndex="99"
          >
            <div
              className="p-2.5 fixed top-6 right-6 z-50"
              style={{
                background: 'rgba(255, 255, 255, 0.3)',
                borderRadius: '22px',
              }}
            >
              <SvgCloseModal fontSize="24px" />
            </div>
          </ModalCloseButton>
          <ModalBody
            p={0}
            className={!isMobile && 'flex flex-col justify-around'}
          >
            <Box w="100%" h={{ base: '100%', md: '650px' }} m="auto">
              <Box
                overflow="hidden"
                borderRadius="10px"
                position="absolute"
                className="inset-0"
                h="70vh"
                margin="auto"
                display={isMobile ? 'flex' : 'block'}
                alignItems="center"
              >
                <div
                  style={{ maxWidth: '1500px' }}
                  className="rounded-lg overflow-hidden h-full m-auto w-full px-6 md:px-0"
                >
                  <NftImageContainer
                    image={tokenData}
                    animation
                    classname="h-full"
                    control
                  />
                </div>
              </Box>
            </Box>
            <style jsx global>{`
              .my-anchor-css-class {
                color: #74787d;
                font-size: 14px;
                font-weight: 400;
              }
              .content-css {
                color: #74787d;
                font-weight: 400;
                color: white;
                font-size: 14px;
              }
              .chakra-modal__content {
                position: relative;
                padding: 0;
              }
              .chakra-modal__close-btn {
                position: absolute;
              }
            `}</style>
          </ModalBody>
        </ModalContent>
      </Modal>
      <MintExplainerModal
        closeModal={() => mintExplainerModal.onClose()}
        isOpen={mintExplainerModal.isOpen}
      />
      <AfterMintingModal
        closeModal={() => afterMintingModal.onClose()}
        isOpen={afterMintingModal.isOpen}
      />
      <MintSuccessfulModal
        closeModal={() => mintSuccessfulModal.onClose()}
        isOpen={mintSuccessfulModal.isOpen}
      />
    </section>
  );
}

function renderSection3() {
  return (
    <section
      className={cn(section3Styles.section3, section3Styles.section3_layout)}
    >
      <div
        className={cn(section3Styles.block16, section3Styles.block16_layout)}
      >
        <div
          className={cn(section3Styles.block34, section3Styles.block34_layout)}
        >
          <div
            className={cn(section3Styles.flex29, section3Styles.flex29_layout)}
          >
            <div className={cn(section3Styles.flex29_item)}>
              <h3
                className={cn(
                  section3Styles.subtitle1,
                  section3Styles.subtitle1_layout
                )}
              >
                More from this collection
              </h3>
            </div>
            <div
              className={cn(
                section3Styles.paragraph_body2,
                section3Styles.paragraph_body2_layout
              )}
            >
              Fidenza is by far my most versatile algorithm to date. Although
              the program stays focused on structured curves and blocks, the
              varieties of scale, organization, texture, and color usage it can
              employ create a wide array of generative possibilities.
            </div>

            <div
              className={cn(section3Styles.group, section3Styles.group_layout)}
            >
              <div
                style={
                  {
                    '--src': `url(${
                      require('public/assets_pxcode/b82eee8cb4b206fe2c50e4d589156dd7.svg')
                        .default
                    })`,
                  } as React.CSSProperties
                }
                className={cn(
                  section3Styles.block12,
                  section3Styles.block12_layout
                )}
              />

              <div
                className={cn(
                  section3Styles.block27,
                  section3Styles.block27_layout
                )}
              >
                <div className={cn(section3Styles.block27_item)}>
                  <div
                    className={cn(
                      section3Styles.flex30,
                      section3Styles.flex30_layout
                    )}
                  >
                    <img
                      src={require('public/assets_pxcode/9af4998dc896c7e511a2a2da7bbd597b.png')}
                      alt="unsplash:nCw-R-HfhWI"
                      className={cn(
                        section3Styles.image10,
                        section3Styles.image10_layout
                      )}
                    />
                    <div
                      className={cn(
                        section3Styles.text_body4,
                        section3Styles.text_body4_layout
                      )}
                    >
                      DT 770 PRO Headphone for ...
                    </div>
                  </div>
                </div>
                <div className={cn(section3Styles.flex30_spacer)} />
                <div className={cn(section3Styles.flex30_item)}>
                  <div
                    className={cn(
                      section3Styles.flex31,
                      section3Styles.flex31_layout
                    )}
                  >
                    <img
                      src={require('public/assets_pxcode/42402cf80da3039e4feec9a9d40ff65f.png')}
                      alt="unsplash:ff0vema8ZHY"
                      className={cn(
                        section3Styles.image10,
                        section3Styles.image10_layout
                      )}
                    />
                    <div
                      className={cn(
                        section3Styles.text_body4,
                        section3Styles.text_body4_layout
                      )}
                    >
                      Mini Projector - Wifi & Bluetooth with USB ...
                    </div>
                  </div>
                </div>
                <div className={cn(section3Styles.flex31_spacer)} />
                <div className={cn(section3Styles.flex31_item)}>
                  <div
                    className={cn(
                      section3Styles.flex31,
                      section3Styles.flex31_layout
                    )}
                  >
                    <img
                      src={require('public/assets_pxcode/12b9f69305508a79b54aa40dc5c6ad09.png')}
                      alt="unsplash:bTxMLuJOff4"
                      className={cn(
                        section3Styles.image10,
                        section3Styles.image10_layout
                      )}
                    />
                    <div
                      className={cn(
                        section3Styles.text_body41_box,
                        section3Styles.text_body41_box_layout
                      )}
                    >
                      <pre className={cn(section3Styles.text_body41)}>
                        Minecraft-themed Redstone PC Custom
                      </pre>
                    </div>
                  </div>
                </div>
                <div className={cn(section3Styles.flex31_spacer1)} />
                <div className={cn(section3Styles.flex31_item1)}>
                  <div
                    className={cn(
                      section3Styles.flex30,
                      section3Styles.flex30_layout
                    )}
                  >
                    <img
                      src={require('public/assets_pxcode/022f0d6800d3699bdf02045109aa65f6.png')}
                      alt="unsplash:wDqytTN8XqE"
                      className={cn(
                        section3Styles.image10,
                        section3Styles.image10_layout
                      )}
                    />
                    <div
                      className={cn(
                        section3Styles.text_body4,
                        section3Styles.text_body4_layout
                      )}
                    >
                      Hustle and bustle in the city
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={
                  {
                    '--src': `url(${
                      require('public/assets_pxcode/5fe5aa58616b6060945ff63b46f49d6c.svg')
                        .default
                    })`,
                  } as React.CSSProperties
                }
                className={cn(
                  section3Styles.block12,
                  section3Styles.block12_layout1
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export function NftDetail(props) {
  const router = useRouter();
  const { user: me, isLogin } = useContext(UserContext) as UserContextType;
  const commentModal = useDisclosure();
  // const addToLabelModal = useDisclosure();
  // const [collectData, setCollectData] = useState([]);
  const commentRef = useRef<CommenterHandler>();
  const isMobile = useIsMobile();
  const stickyButtonRef = useRef();
  const [showStickyButton, setShowStickyButton] = useState<boolean>(true);

  const { mutateAsync: createComment } = useArticleCommentMutation(
    props.defaultAsset?.author ? props.defaultAsset?.id : 0
  );
  const { mutateAsync: updateComment } = useArticleEditCommentMutation(
    props.defaultAsset?.author ? props.defaultAsset?.id : 0
  );
  const { mutateAsync: enthuseComment } = useCommentEnthuseMutation(
    props.defaultAsset?.author ? props.defaultAsset?.id : 0
  );
  const { data: fetchedComent, refetch: refetchComment } = useCreationsComment(
    props.defaultAsset?.author ? props.defaultAsset?.id : 0
  );

  useIntersectionObserver({
    target: stickyButtonRef,
    onIntersect: () => setShowStickyButton(false),
    onUnIntersect: () => setShowStickyButton(true),
    enabled: true,
  });

  const isOwner = me.id === props.defaultAsset?.author?.userId && isLogin;

  useEffect(() => {
    if (router.asPath.includes('#')) {
      const command = router.asPath.split('#')[1];
      commentCommand(command);
    }
  }, [router]);

  useEffect(() => {
    const command = window.history.state;
    if (command === 'comments') {
      commentCommand(command);
    }
  }, []);

  const commentCommand = (command) => {
    if (command === 'comments') {
      commentModal.onOpen();
    }
  };

  const mentionUsers = (mention) => {
    const taggedUsers = {
      userNames: getTaggedMention(mention.data),
      postID: mention.id,
      place: 'COMMENT',
    };

    userMention(taggedUsers);
  };

  const handleComment = ({
    event,
    blockId,
  }: {
    event: any;
    blockId?: string;
  }) => {
    const currValue = event.value;
    const currEvent = event.event;

    if (currValue !== '' && currEvent.key === 'Enter') {
      toComment({
        parentId: blockId,
        objectId: props.defaultAsset?.id,
        objectType: blockId ? 'COMMENT' : 'CREATIVE_ASSET',
        content: currValue,
      });
    }
  };

  const toComment = async (data) => {
    const createdComment = await createComment(data);
    if (createdComment.code === 1000) {
      mentionUsers({ data: createdComment.data, id: props.defaultAsset?.id });
      refetchComment();
    }
  };

  return (
    <main className={`nft-detail-buy ${cn(styles.main, styles.main_layout)}`}>
      <div
        className={cn(
          styles.main_item,
          `fixed w-full bottom-6 z-20 ${!showStickyButton && 'hidden'} ${
            !props.defaultAsset?.id && 'hidden'
          }`
        )}
      >
        {props.defaultAsset?.author && (
          <StickyIconButtonList
            left="0"
            commentable
            authorID={props.defaultAsset?.author?.userId}
            refetch={props.refetch}
            authorUsername={props.defaultAsset?.author?.username}
            onCommentClick={commentModal.onOpen}
            commentCount={fetchedComent?.length}
            // onSaveClick={() =>
            //   isLogin
            //     ? addToLabelModal.onOpen()
            //     : router.push({
            //         query: { ...router.query, page: 'signIn' },
            //       })
            // }
            contentID={props.defaultAsset?.id}
            isEnthused={props.defaultAsset?.isEnthused}
            articleType={!props.defaultAsset ? 'NFT' : 'CREATIVE_ASSET'}
            // collection={collectData}
            data={props.defaultAsset}
            isFixedTab={!isMobile}
            isFloater
          />
        )}
      </div>
      <div className="flex absolute top-10 right-52 z-50">
        {isOwner && (
          <div
            role="button"
            tabIndex={0}
            style={{ borderRadius: '28px', width: '120px' }}
            className="py-2 font-semibold text-blue-500 bg-white border border-gray-300 w-full cursor-pointer opacity-0 md:opacity-100"
            onClick={() => {
              router.push(`/asset/${props.defaultAsset.id}/edit`);
            }}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                router.push(`/asset/${props.defaultAsset.id}/edit`);
              }
            }}
          >
            <div className="m-auto w-max">Edit</div>
          </div>
        )}
        {/* {isOwner && props.defaultAsset?.royalties === 0 && (
          <div
            role="button"
            tabIndex={0}
            style={{ borderRadius: '28px', width: '120px' }}
            className="ml-3 py-2 font-semibold text-white bg-blue-500 border border-blue-500 w-full cursor-pointer opacity-0 md:opacity-100"
            onClick={() => {
              router.push(`/asset/${props.defaultAsset.id}/list`);
            }}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                router.push(`/asset/${props.defaultAsset.id}/list`);
              }
            }}
          >
            <div className="m-auto w-max">Sell</div>
          </div>
        )} */}
      </div>
      <CreativeAsset
        nft={props.defaultAsset}
        isLazy={!!props.defaultAsset.author || false}
        isOwner={isOwner}
      />
      {props.defaultAsset?.author && (
        <>
          <div
            ref={stickyButtonRef}
            className={cn(styles.main_item, 'z-5', 'hidden')}
          >
            <StickyIconButtonList
              left="0"
              commentable
              authorID={props.defaultAsset?.author?.userId}
              refetch={props.refetch}
              authorUsername={props.defaultAsset?.author?.username}
              onCommentClick={commentModal.onOpen}
              commentCount={fetchedComent?.length}
              // onSaveClick={() =>
              //   isLogin
              //     ? addToLabelModal.onOpen()
              //     : router.push({
              //         query: { ...router.query, page: 'signIn' },
              //       })
              // }
              contentID={props.defaultAsset?.id}
              isEnthused={props.defaultAsset?.isEnthused}
              articleType={!props.defaultAsset ? 'NFT' : 'CREATIVE_ASSET'}
              // collection={collectData}
              data={props.defaultAsset}
              isFixedTab={!isMobile}
            />
          </div>
          <DetailCommentModal id="detailComment" modal={commentModal}>
            <Box className="w-full" maxW="704px">
              <div>
                {me && (
                  <div className="px-0 w-full commenter">
                    <Commenter
                      disabled={false}
                      ref={commentRef}
                      user={me}
                      editorIndex="assetComment"
                      onKeyDown={(event) => handleComment({ event })}
                    />
                  </div>
                )}
                {fetchedComent && (
                  <div className="px-0">
                    <ArticleComment
                      comments={fetchedComent}
                      modalId="detailComment"
                      onComment={({ blockId, value, commentID }) => {
                        if (commentID) {
                          updateComment({
                            commentID,
                            Assets: [],
                            content: value,
                            parentId: blockId,
                          });
                          refetchComment();
                        } else {
                          createComment({
                            objectId: props.defaultAsset?.id,
                            objectType: blockId ? 'COMMENT' : 'EPISODE',
                            parentId: blockId,
                            content: value,
                          });
                          refetchComment();
                        }
                      }}
                      onEnthuse={({
                        targetId,
                        targetParentId,
                        targetType,
                        isEnthused,
                        targetOwnerId,
                      }) => {
                        enthuseComment({
                          objectId: targetId,
                          objectParentId: targetParentId,
                          objectType: targetType,
                          enthuse: !isEnthused,
                          ownerID: targetOwnerId,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </Box>
          </DetailCommentModal>
        </>
      )}
      <div className={cn(styles.main_item, 'z-10', 'hidden')}>
        {renderSection3()}
      </div>
    </main>
  );
}

function Copyright(props: { copyright?: string }) {
  return <CopyrightFooter copyright={props.copyright} />;
}
