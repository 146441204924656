import { useForm } from 'react-hook-form';
import { useEffect, useState, useCallback, useReducer } from 'react';
import cn from 'classnames';
import SignInUpTabs from 'src/app/auth/sign-in/sign-in-section/SignInUpTabs';
import Terms from 'src/components/footer/terms';
import PageModal from 'src/components/footer/page-modal';
import SvgExcerptsInfo from 'src/components/icons/excerpts-info';
import {
  Checkbox,
  Stack,
  Button,
  Text,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import {
  useSignUp,
  useResendEmail,
  useChangeEmail,
  // useActivate,
  setAuthToken,
  setSentryUser,
} from 'src/app/auth/use-auth';
import SvgEmailSent from 'src/components/icons/email-sent-2';
import { useTranslation } from 'react-i18next';
import SvgEmailResend from 'src/components/icons/email-resend';
import { logger } from 'src/lib/logger';
import SvgWalletConnectIcon from 'src/components/icons/wallet-connect-icon';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { userWalletNonce, userWalletLogin } from 'src/app/auth/service';
import SvgVisibilityOffOutlined from 'src/components/icons/visibility-off-outlined';
import SvgVisibilityOutlined from 'src/components/icons/visibility-outlined';
import {
  debounce,
  testEmailFormat,
  testPasswordFormat,
} from 'src/app/auth/functions';
import { userReducer, initState } from 'src/contexts/UserContext';
import { useRouter } from 'next/router';
import WalletExplainerModal from 'src/app/code/sign-up-section/WalletExplainerModal';
import { fetchUsername, fetchWallet, changeUsername } from './service';
import styles from './sign-up-section/Sign_up.module.scss';
import SuccessModal from './SuccessModal/SuccessModal';
import ErrorModal from './ErrorModal/ErrorModal';

const errorMessages = {
  usernameTaken: 'Username is taken.',
  userNameInvalid: 'Invalid username.',
  emailInvalid: 'Invalid email.',
  emailTaken: 'Email is already in use.',
  passwordInvalid:
    'Passwords must be 8-128 characters long, including one upper and lower case letter, and one digit.',
};

export default function SignUpForm({ transition }: { transition: () => void }) {
  const router = useRouter();
  const successModal = useDisclosure();
  const walletInUseModal = useDisclosure();
  const noWalletFoundModal = useDisclosure();
  const { t } = useTranslation();
  const [activeBlock, setActiveBlock] = useState('email');
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [validationConditions, setValidationConditions] = useState({
    isUsernameValid: false,
    isUsernameAvailable: false,
    isEmailValid: false,
    isPasswordValid: false,
    hasAgreedToTerms: false,
  });
  const [isInputEmpty, setIsInputEmpty] = useState({
    username: true,
    email: true,
    password: true,
    remail: true,
  });
  const [canSubmit, setCanSubmit] = useState(false);
  const [isRemailValid, setIsRemailValid] = useState(false);
  const [isRemailTaken, setIsRemailTaken] = useState(false);
  const { isLoading, mutateAsync: createUser } = useSignUp();
  const { mutateAsync: resendEmail } = useResendEmail();
  const { mutateAsync: changeEmail } = useChangeEmail();
  const [chosenWallet, setChosenWallet] = useState<string>('metamasak');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isResendEmail, setIsResendEmail] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [over, setOver] = useState(false);
  const [startTick, setStartTick] = useState(false);
  const [registeredUser, setRegisteredUser] = useState<any>();
  const [reenterValue, setReenterValue] = useState('');
  const [wc, setWc] = useState<any>();
  const [web3, setWeb3] = useState<any>();
  const [signatures, setSignatures] = useState<any[]>([]);
  const [signaturesPre, setSignaturesPre] = useState<any[]>([]);
  // const [activationToken, setActivationToken] = useState<any>();
  // const { mutateAsync: activateUser } = useActivate();
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  // const [currSigner, setCurrSigner] = useState<any>();
  const [state, userDispatch] = useReducer(userReducer, initState);
  const [currUsername, setCurrUsername] = useState<any>();
  const walletExplainerModal = useDisclosure();

  function updateIsInputEmpty(event) {
    const key = event.target.id;
    const isEmpty = event.target.value.length === 0;
    if (isInputEmpty[key] === isEmpty) return;
    const newisInputEmpty = { ...isInputEmpty };
    newisInputEmpty[event.target.id] = isEmpty;
    setIsInputEmpty(newisInputEmpty);
  }

  const tick = useCallback(() => {
    if (over) return;
    if (seconds === 1) {
      setOver(true);
      setStartTick(false);
    } else {
      setSeconds(seconds - 1);
    }
  }, [over, seconds]);

  useEffect(() => {
    if (startTick) {
      const timerID = setInterval(() => tick(), 1000);
      return () => clearInterval(timerID);
    }
  }, [startTick, tick]);

  useEffect(() => {
    const sigContinue = async () => {
      const newUserWallet = await userWalletLogin({
        signature: signatures[signatures.length - 1].signature,
        publicAddress: signatures[signatures.length - 1].address,
      });
      if (newUserWallet.code === 1000) {
        const usernameData = {
          username: currUsername,
        };
        const { data: resData } = newUserWallet;
        setAuthToken(resData.token);
        const userAwait = await changeUsername(usernameData);
        setSentryUser({
          id: resData.data.id,
          username: currUsername,
          firstName: resData.data.firstName,
          lastName: resData.data.lastName,
          email: resData.data.email,
          userRole: resData.data.userRole,
        });
        userDispatch({
          type: 'FETCH_USER',
          data: { ...resData.data, username: currUsername },
        });
        if (userAwait.code === 1000) {
          router.push(`${currUsername}`);
        }
        console.log(state);
      }
    };
    if (signatures && signatures.length > 0) {
      if (
        signatures[signatures.length - 1].address &&
        signatures[signatures.length - 1].signature
      ) {
        sigContinue();
      }
    }
  }, [signatures]);

  const onSubmit = async (data) => {
    if (activeBlock === 'wallet') {
      if (chosenWallet === 'walletconnect') {
        walletConnectEnable();
      } else {
        signMessage();
      }
      return null;
    }
    try {
      const createUserResponse = await createUser(data);
      if (createUserResponse?.code === 1000) {
        setRegisteredUser(data);
        setIsResendEmail(false);
        setIsEmailSent(true);
        setStartTick(true);
      }

      if (createUserResponse?.code === 1011) {
        console.log('error code 1011: Please select another username');
      }

      if (createUserResponse?.code === 1004) {
        console.log('error code 1004: Email is already in use');
        setIsEmailAvailable(false);
      }
    } catch (err) {
      logger.error(err);
    }
  };

  const signMessage = async () => {
    try {
      if (!window.ethereum)
        throw new Error('No crypto wallet found. Please install it.');

      await window.ethereum.send('eth_requestAccounts');
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      // setCurrSigner(signer);
      const address = await signer.getAddress();
      await fetchWallet(address).then((code) => {
        if (!code.data) {
          nonceContinue(address, signer);
        } else {
          walletInUseModal.onOpen();
        }
      });
    } catch (err) {
      noWalletFoundModal.onOpen();
    }
  };

  const nonceContinue = (address, signer) => {
    userWalletNonce(address).then(async (res) => {
      if (res) {
        if (chosenWallet === 'walletconnect') {
          walletConnectSign(res.nonce, address);
        } else if (signer) {
          const signature = await signer.signMessage(res.nonce);
          if (signature && address) {
            if (signatures && signatures.length > 0) {
              setSignaturesPre([...signatures, { signature, address }]);
              successModal.onOpen();
            } else {
              setSignaturesPre([{ signature, address }]);
              successModal.onOpen();
            }
          }
        }
      }
    });
  };

  // useEffect(() => {
  //   async function activatingUser() {
  //     if (activationToken) {
  //       try {
  //         const toActivation = { token: activationToken };
  //         const activateResponse = await activateUser(toActivation);
  //         if (activateResponse.code === 1000) {
  //           const { data: resData } = activateResponse;
  //           setAuthToken(resData.token);
  //           setSentryUser({
  //             id: resData.data.id,
  //             username: resData.data.username,
  //             firstName: resData.data.firstName,
  //             lastName: resData.data.lastName,
  //             email: resData.data.email,
  //             userRole: resData.data.userRole,
  //           });
  //         }
  //       } catch (err) {
  //         logger.error(err);
  //       }
  //     }
  //   }
  //   activatingUser();
  // }, [activationToken, setActivationToken]);

  const walletConnectEnable = async () => {
    const walletConnectProvider = new WalletConnectProvider({
      infuraId: '117199ece0414112b97d54fbe01f6b49',
    });
    setWc(walletConnectProvider);
    await walletConnectProvider.enable();
    setWeb3(new Web3(walletConnectProvider as any));
  };

  useEffect(() => {
    const web3Continue = async () => {
      const currAdd = await web3.eth.getAccounts();
      await fetchWallet(currAdd[0]).then((code) => {
        if (!code.data) {
          nonceContinue(currAdd[0], false);
        } else {
          console.log('This wallet is already connected to another account');
        }
      });
    };
    if (web3) {
      web3Continue();
    }
  }, [web3]);

  const walletConnectSign = async (nonce, address) => {
    const rawMessage = nonce;
    const rawMessageLength = new Blob([rawMessage]).size;
    let message = ethers.utils.toUtf8Bytes(
      `\x19Ethereum Signed Message:\n${rawMessageLength}${rawMessage}`
    );
    message = ethers.utils.keccak256(message) as any;
    const params = [address, message];
    const newSig = await wc.connector.signMessage(params);
    const sig = {
      signature: newSig,
      address,
    };
    if (signatures && signatures.length > 0) {
      setSignaturesPre([...signatures, sig]);
      successModal.onOpen();
    } else {
      setSignaturesPre([sig]);
      successModal.onOpen();
    }
  };

  const handleRemailChange = (event) => {
    setIsRemailTaken(false);
    setOver(false);
    setSeconds(30);
    setReenterValue(event.target.value);
    debouncedTestEmail(event.target.value, true);
  };

  const resendNewEmail = async () => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(reenterValue)) {
      console.log('email error');
    } else {
      const changeEmailToResend = {
        newEmail: reenterValue,
        email: registeredUser.email,
        password: registeredUser.password,
      };

      const changeWait = await changeEmail(changeEmailToResend);

      if (changeWait.code === 1000) {
        const newUserEmail = { ...registeredUser };
        newUserEmail.email = reenterValue;
        setRegisteredUser(newUserEmail);
        emailResend(reenterValue);
        setIsEmailSent(true);
        setIsResendEmail(false);
        setStartTick(true);
      }
      if (changeWait.code === 1004) {
        setIsRemailTaken(true);
        setReenterValue('');
      }
    }
  };

  const { register, handleSubmit } = useForm();

  const emailResend = async (data) => {
    const emailToResend = {
      email: data,
    };
    await resendEmail(emailToResend);
  };

  useEffect(() => {
    if (activeBlock === 'email') {
      setCanSubmit(
        Object.values(validationConditions).every((value) => value === true) // test if all conditions are met
      );
    } else {
      setCanSubmit(
        validationConditions.isUsernameValid &&
          validationConditions.isUsernameAvailable &&
          validationConditions.hasAgreedToTerms
      );
    }
  }, [validationConditions, activeBlock]);

  useEffect(() => {
    if (activeBlock !== 'email') return;
    const newValidationState = { ...validationConditions };
    newValidationState.isPasswordValid = false;
    newValidationState.isEmailValid = false;
    setValidationConditions(newValidationState);
  }, [activeBlock]);

  async function testUsername(username: string) {
    const newValidationState = { ...validationConditions };
    // test validity
    const regex: RegExp = /^[a-zA-Z0-9]([._](?![._])|[a-zA-Z0-9]){2,18}[a-zA-Z0-9]$/;
    if (!regex.test(username)) {
      newValidationState.isUsernameValid = false;
      newValidationState.isUsernameAvailable = true;
    } else {
      newValidationState.isUsernameValid = true;
      try {
        // test if username is taken
        const isUsernameTaken = await fetchUsername(username);
        if (isUsernameTaken?.code === 1000) {
          newValidationState.isUsernameAvailable = true;
          setCurrUsername(username);
        }
      } catch (err) {
        newValidationState.isUsernameAvailable = false;
      }
    }
    setValidationConditions(newValidationState);
  }

  function testEmail(email: string, isRemail: boolean = false) {
    const newValidationState = { ...validationConditions };
    const result = testEmailFormat(email);
    newValidationState.isEmailValid = result;
    isRemail
      ? setIsRemailValid(result)
      : setValidationConditions(newValidationState);
  }

  function testPassword(password: string) {
    const newValidationState = { ...validationConditions };
    const result = testPasswordFormat(password);
    newValidationState.isPasswordValid = result;
    setValidationConditions(newValidationState);
  }

  const debouncedTestUsername = debounce((username) => testUsername(username));
  const debouncedTestEmail = debounce((email, isRemail) =>
    testEmail(email, isRemail)
  );
  const debouncedTestPassword = debounce((password) => testPassword(password));
  const debouncedUpdateIsInputEmpty = debounce(
    (event) => updateIsInputEmpty(event),
    500
  );

  const inputErrorStyle = {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#FF6633',
    backgroundColor: '#f4e5e0',
    textFillColor: '#f4e5e0',
  };

  return (
    <>
      <SuccessModal
        onClose={successModal.onClose}
        isOpen={successModal.isOpen}
        heading="Success"
        paragraph="Congratulations, your account has been successfully created."
        buttonText="Ok"
        buttonAction={() => {
          setSignatures(signaturesPre);
        }}
      />
      <ErrorModal
        onClose={walletInUseModal.onClose}
        isOpen={walletInUseModal.isOpen}
        heading="Used Wallet"
        paragraph="This wallet is already connected to another account."
        cancelButtonText="Cancel"
        confirmButtonText="Sign in with this wallet"
        confirmButtonAction={() => {
          transition();
        }}
      />
      <ErrorModal
        onClose={noWalletFoundModal.onClose}
        isOpen={noWalletFoundModal.isOpen}
        heading="No wallet found"
        paragraph="Please make sure you have a wallet installed."
        cancelButtonText="Cancel"
        confirmButtonText="Go to MetaMask"
        confirmButtonAction={() => {
          window.open('https://metamask.io/', '_blank');
        }}
      />
      {isResendEmail && (
        <div className={cn(styles.success_container)}>
          <div className={cn(styles.success_container_background)}>
            <div className={cn(styles.success_text_container)}>
              <div className="pb-16">
                <div className="focus-within:text-gray-900 m-auto text-center px-8">
                  <div className="w-full py-2 mt-5">
                    <SvgEmailResend
                      width="80px"
                      height="80px"
                      className="mx-auto"
                    />
                  </div>
                  <div className="text-4xl py-2">Re-enter Email</div>
                  <div className="">
                    Please re-enter your email below to continue.
                  </div>
                  <Stack className="pt-4" spacing={3} alignItems={['center']}>
                    <input
                      id="remail"
                      data-testid="remail"
                      name="remail"
                      type="email"
                      placeholder={t('welcome@1tm.io')}
                      className={cn(styles.input, styles.remail_input)}
                      onChange={(e) => {
                        handleRemailChange(e);
                        debouncedUpdateIsInputEmpty(e);
                      }}
                      style={
                        !isRemailValid && !isInputEmpty.remail
                          ? inputErrorStyle
                          : null
                      }
                    />
                    <div className={cn(styles.remail_error_message_container)}>
                      {!isRemailValid && !isInputEmpty.remail && (
                        <span className={cn(styles.error_message)}>
                          {errorMessages.emailInvalid}
                        </span>
                      )}
                      {isRemailTaken && !isInputEmpty.remail && (
                        <span className={cn(styles.error_message)}>
                          {errorMessages.emailTaken}
                        </span>
                      )}
                    </div>
                    <Button
                      rounded="ellipse"
                      size="md"
                      width="auto"
                      type="button"
                      onClick={resendNewEmail}
                      className={
                        isRemailValid
                          ? cn(styles.submit_button)
                          : cn(styles.submit_button_disabled)
                      }
                    >
                      {t('Confirm')}
                    </Button>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isEmailSent && (
        <div className={cn(styles.success_container)}>
          <div className={cn(styles.success_container_background)}>
            <div className={cn(styles.success_text_container)}>
              <div className="text-gray-900 m-auto text-center px-8">
                <div className="w-full py-2 mt-5">
                  <SvgEmailSent
                    width="80px"
                    height="80px"
                    className="mx-auto"
                  />
                </div>
                <div className="text-4xl py-2">Verification Email Sent!</div>
                <div className="">
                  We have sent a verification email to
                  <span className="text-blue-500 px-1">
                    {registeredUser?.email}
                  </span>
                  please check your email and click &quot;verify&quot; to
                  complete the account sign up process!
                </div>
                <Stack className="pt-4" spacing={3} alignItems={['center']}>
                  <Button
                    rounded="ellipse"
                    size="md"
                    width="auto"
                    type="button"
                    isDisabled={!over}
                    className={cn(styles.submit_button)}
                    onClick={() => {
                      setOver(false);
                      setSeconds(30);
                      setStartTick(true);
                      emailResend(registeredUser?.email);
                    }}
                  >
                    {over ? (
                      t('Resend Email')
                    ) : (
                      <span>
                        {t('Resend Email')} {seconds} {t('sec')}
                      </span>
                    )}
                  </Button>
                  <Text
                    role="button"
                    tabIndex={0}
                    className="text-blue-500"
                    fontSize="sm"
                    onClick={() => {
                      setIsResendEmail(true);
                      setIsEmailSent(false);
                      setReenterValue('');
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        setIsResendEmail(true);
                        setIsEmailSent(false);
                        setReenterValue('');
                      }
                    }}
                  >
                    <span>{t('Edit Email')}</span>
                  </Text>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      )}
      <PageModal
        isOpen={isTermsOpen}
        setIsOpen={setIsTermsOpen}
        contentType="Terms of Service"
      >
        <Terms />
      </PageModal>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={cn(styles.sign_up_form)}
        noValidate
      >
        <div className={cn(styles.flex_helper)}>
          <span>*Choose a username</span>
          <Tooltip
            hasArrow
            arrowSize={15}
            label={
              <ul className={cn(styles.tooltip_list)}>
                <li>
                  • Only contains <span>alphanumeric</span> characters,
                  <span>underscore</span> and <span>dot</span>.
                </li>
                <li>
                  • <span>Underscore and dot cannot be</span>
                  <li>
                    &emsp;at the end or start of a username (e.g _username /
                    username_ / .username / username).
                  </li>
                  <li>&emsp;• next to each other (e.g user_.name).</li>
                  <li>
                    &emsp;• used multiple times in a row (e.g user__name /
                    user..name).
                  </li>
                </li>
                <li>
                  • The <span>number of characters</span> must be between{' '}
                  <span>4 to 20</span>.
                </li>
              </ul>
            }
            placement="top"
            bg="#ffffff"
            color="$mode.700"
            borderRadius="4px"
            border="1px solid #E6E8E9"
            maxWidth="371px"
            fontSize="12px"
            p="12px"
            alignItems="center"
          >
            <div className={cn(styles.tooltip_icon)}>
              <SvgExcerptsInfo fontSize="17px" />
            </div>
          </Tooltip>
        </div>
        <input
          ref={register}
          id="username"
          name="username"
          type="username"
          placeholder="Username"
          className={`${cn(styles.username_input, styles.input)}`}
          onChange={(e) => {
            debouncedTestUsername(e.target.value);
            debouncedUpdateIsInputEmpty(e);
          }}
          style={
            (!validationConditions.isUsernameValid ||
              !validationConditions.isUsernameAvailable) &&
            !isInputEmpty.username
              ? inputErrorStyle
              : null
          }
        />
        {!validationConditions.isUsernameValid && !isInputEmpty.username && (
          <span className={cn(styles.error_message)}>
            {errorMessages.userNameInvalid}
          </span>
        )}
        {!validationConditions.isUsernameAvailable &&
          validationConditions.isUsernameValid &&
          !isInputEmpty.username && (
            <span className={cn(styles.error_message)}>
              {errorMessages.usernameTaken}
            </span>
          )}
        <SignInUpTabs
          setActiveBlock={setActiveBlock}
          activeBlock={activeBlock}
        />
        {activeBlock === 'email' && (
          <>
            <input
              ref={register}
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              onChange={(e) => {
                debouncedUpdateIsInputEmpty(e);
                debouncedTestEmail(e.target.value);
                if (!isEmailAvailable) {
                  setIsEmailAvailable(true);
                }
              }}
              className={cn(styles.input)}
              style={
                (!validationConditions.isEmailValid &&
                  validationConditions.isUsernameValid &&
                  validationConditions.isUsernameAvailable &&
                  !isInputEmpty.email) ||
                !isEmailAvailable
                  ? inputErrorStyle
                  : null
              }
            />
            {!validationConditions.isEmailValid &&
              validationConditions.isUsernameValid &&
              validationConditions.isUsernameAvailable &&
              !isInputEmpty.email && (
                <span className={cn(styles.error_message)}>
                  {errorMessages.emailInvalid}
                </span>
              )}
            {!isEmailAvailable && (
              <span className={cn(styles.error_message)}>
                {errorMessages.emailTaken}
              </span>
            )}
            <div className={cn(styles.password_input_container)}>
              <input
                ref={register}
                id="password"
                name="password"
                type={isShowingPassword ? 'text' : 'password'}
                maxLength={128}
                placeholder="Password"
                onChange={(e) => {
                  debouncedUpdateIsInputEmpty(e);
                  debouncedTestPassword(e.target.value);
                }}
                className={cn(styles.input)}
                style={
                  !validationConditions.isPasswordValid &&
                  validationConditions.isEmailValid &&
                  validationConditions.isUsernameValid &&
                  validationConditions.isUsernameAvailable &&
                  !isInputEmpty.password
                    ? inputErrorStyle
                    : null
                }
              />
              <div
                className={cn(styles.show_hide_password_btn)}
                onClick={() => setIsShowingPassword(!isShowingPassword)}
                role="button"
                tabIndex={0}
                onKeyDown={() => null}
              >
                {isShowingPassword ? (
                  <SvgVisibilityOffOutlined boxSize="24px" color="#BBC0C5" />
                ) : (
                  <SvgVisibilityOutlined boxSize="24px" color="#BBC0C5" />
                )}
              </div>
            </div>

            {!validationConditions.isPasswordValid &&
              validationConditions.isEmailValid &&
              validationConditions.isUsernameValid &&
              validationConditions.isUsernameAvailable &&
              !isInputEmpty.password && (
                <span className={cn(styles.error_message)}>
                  {errorMessages.passwordInvalid}
                </span>
              )}
          </>
        )}
        {activeBlock === 'wallet' && (
          <div className={cn(styles.block9_item2)}>
            <div className={cn(styles.flex21, styles.flex21_layout)}>
              <div className={cn(styles.flex2_item1)}>
                <div
                  className={cn(
                    chosenWallet === 'walletconnect'
                      ? styles.block4
                      : styles.block3,
                    styles.block3_layout
                  )}
                  role="button"
                  tabIndex={0}
                  onClick={() => setChosenWallet('metamask')}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      setChosenWallet('metamask');
                    }
                  }}
                >
                  <div className={cn(styles.flex3, styles.flex3_layout)}>
                    <div className={cn(styles.flex3_item)}>
                      <div
                        style={{
                          // @ts-ignore
                          '--src': `url(${require('images/assets/sign-up-section/251eb679489f003d7a2074796ab3f013.svg')})`,
                        }}
                        className={cn(styles.image5, styles.image5_layout)}
                      />
                    </div>
                    <div className={cn(styles.flex3_spacer)} />
                    <div className={cn(styles.flex3_item2)}>
                      <h5
                        className={cn(
                          styles.highlights,
                          styles.highlights_layout
                        )}
                      >
                        MetaMask
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className={cn(styles.flex2_spacer1)} />
              <div className={cn(styles.flex2_item2)}>
                <div
                  className={cn(
                    chosenWallet === 'walletconnect'
                      ? styles.block3
                      : styles.block4,
                    styles.block4_layout
                  )}
                  role="button"
                  tabIndex={0}
                  onClick={() => setChosenWallet('walletconnect')}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      setChosenWallet('walletconnect');
                    }
                  }}
                >
                  <div className={cn(styles.flex4, styles.flex4_layout2)}>
                    <SvgWalletConnectIcon fontSize="24px" />
                    <div className={cn(styles.flex4_spacer2)} />
                    <div className={cn(styles.flex4_item)}>
                      <h5
                        className={cn(
                          styles.highlights,
                          styles.highlights_layout
                        )}
                      >
                        WalletConnect
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={cn(styles.checkbox_container)}>
          <div className={styles.checkbox}>
            <Checkbox
              size="lg"
              id="checkbox"
              name="checkbox"
              type="checkbox"
              onChange={() => {
                const newValidationState = { ...validationConditions };
                newValidationState.hasAgreedToTerms = !validationConditions.hasAgreedToTerms;
                setValidationConditions(newValidationState);
              }}
              className={cn(styles.check_box_input)}
            />
          </div>
          <div className={cn(styles.check_box_text_container)}>
            I agree to the &nbsp;
          </div>
          <button
            className={cn(styles.text_body12_span2)}
            type="button"
            onClick={() => {
              setIsTermsOpen(true);
            }}
          >
            Terms and Conditions
          </button>
        </div>
        <input
          id="submit"
          name="Create Account"
          className={
            canSubmit
              ? cn(styles.submit_button)
              : cn(styles.submit_button_disabled)
          }
          type="submit"
          value="Create Account"
          disabled={canSubmit && isLoading}
        />
      </form>
      {activeBlock === 'wallet' && (
        <button
          type="button"
          onClick={() => {
            walletExplainerModal.onOpen();
          }}
          className={styles.wallet_explainer_link_container}
        >
          <span>What is a wallet?</span>
        </button>
      )}
      <WalletExplainerModal
        closeModal={() => walletExplainerModal.onClose()}
        isOpen={walletExplainerModal.isOpen}
      />
    </>
  );
}
