import Axios from 'axios';

export const fetchWalletNfts = (wallet, data) => {
  return Axios.request({
    method: 'GET',
    url: `https://eth-mainnet.g.alchemy.com/nft/v2/${process.env.ALCHEMY_KEY}/getNFTs`,
    params: { owner: wallet, withMetadata: 'true', ...data },
    headers: { accept: 'application/json' },
  }).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
  });
};

export const fetchWalletBalance = (wallet) => {
  return Axios.request({
    method: 'POST',
    url: `https://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_KEY}`,
    headers: { accept: 'application/json', 'content-type': 'application/json' },
    data: { id: 1, jsonrpc: '2.0', params: [wallet], method: 'eth_getBalance' },
  }).then((res) => {
    if (res.status === 200) {
      return res.data.result;
    }
  });
};

export const fetchMetaData = (contract, id) => {
  return Axios.request({
    method: 'GET',
    url: `https://eth-mainnet.g.alchemy.com/nft/v2/${process.env.ALCHEMY_KEY}/getNFTMetadata`,
    params: {
      contractAddress: contract,
      tokenId: id,
      refreshCache: 'false',
    },
    headers: { accept: 'application/json' },
  }).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
  });
};

export const fetchNftOwners = (contract, id) => {
  return Axios.request({
    method: 'GET',
    url: `https://eth-mainnet.g.alchemy.com/nft/v2/${process.env.ALCHEMY_KEY}/getOwnersForToken`,
    params: { contractAddress: contract, tokenId: id },
    headers: { accept: 'application/json' },
  }).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
  });
};
