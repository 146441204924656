import * as React from 'react';
import { Svg } from '../svg';

function SvgGlobe({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10 18.54a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.667zM1.667 10.207h16.667"
        stroke="#242526"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 1.874a12.75 12.75 0 013.334 8.333A12.75 12.75 0 0110 18.541a12.75 12.75 0 01-3.333-8.334A12.75 12.75 0 0110 1.874v0z"
        stroke="#242526"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgGlobe);
export default ForwardRef;
