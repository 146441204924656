import * as React from 'react';
import { Svg } from '../svg';

function SvgFlagV2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M0 .5h9.569a.25.25 0 01.217.374L8 4l1.786 3.126a.25.25 0 01-.217.374H1V10H0V.5z"
        fill="#fff"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgFlagV2);
export default ForwardRef;
