import { Badge, Text, Box, Modal, ModalContent } from '@chakra-ui/react';
import {
  NotificationContext,
  recheckNotification,
} from 'src/hooks/use-notification';
import { useContext, useEffect, useState } from 'react';
// import { useSessionStorage } from 'src/hooks';
import { useModal } from 'src/hooks/use-modal';

import SvgNotification from 'src/components/icons/notification';

import { NotificationCard } from './notification-card';
import { NotificationDrawTabs } from './notification-draw-tabs';

export function NotificationDrawer({ loginUser = '' }) {
  const [notification, setNotification] = useContext(NotificationContext);
  const newnotif = notification?.data?.filter(function filterNotif(notifData) {
    return notifData.read === false;
  });
  const badgeNumber = notification.notifCount > 99 ? '99+' : newnotif?.length;
  // let notifDetails = notification?.notificationTop;
  const isNotifOpen = useModal();
  const [selectedTab, setSelectedTab] = useState('notifications');
  const [notifDetails, setNotifDetails] = useState<{ id: string }[]>();

  const { notificationStats } = notification;
  const handleCardClick = async (event) => {
    const recheckNotif = await recheckNotification(notification, event);
    setNotification(recheckNotif);
  };
  useEffect(() => {
    if (selectedTab === 'notifications') {
      const notifTempt = notification?.notificationTop?.sort(function sortNotif(
        a,
        b
      ) {
        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });

      setNotifDetails(notifTempt);
    } else {
      const data = notification.invitation.filter((i) => i.space);
      setNotifDetails(data);
    }
  }, [notifDetails, notification, selectedTab]);

  const hasInvitation = notification?.invitation?.length > 0;
  // const invitationList = [];

  // notifDetails?.forEach((notif) => {
  //   if (
  //     notif.destination.type === 'PROJECT' &&
  //     notif.destination.projectID
  //   ) {
  //     invitationList.push({
  //       rid: notif.destination.projectID,
  //       invitationID: notif.subText.objectID,
  //       senderName: notif.subText.senderName,
  //       username: notif.subText.senderName,
  //       senderID: notif.subText.senderId,
  //       message: notif.subText.message,
  //       createdAt: notif.subText.createdAt,
  //       user: userID,
  //     });
  //   }
  // });
  // const setInvites = useSessionStorage('userInvitations', []);
  // const [effectIndex, setEffectIndex] = useState(0);
  // useEffect(() => {
  //   function storeInvites() {
  //     if (invitationList.length > 0) {
  //       setInvites[1](invitationList);
  //     }
  //   }
  //   if (effectIndex === 0) {
  //     storeInvites();
  //     setEffectIndex(1);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        onClick={() => isNotifOpen.onSelect(true)}
        onKeyDown={() => isNotifOpen.onSelect(true)}
      >
        {badgeNumber ? (
          <Badge
            pos="absolute"
            borderRadius="full"
            top="0px"
            px="2"
            bgColor="#FF5F3B"
            zIndex="2"
            color="white"
          >
            {badgeNumber}
          </Badge>
        ) : (
          <></>
        )}
        <SvgNotification className="dark:text-gray-500 text-2xl" />
      </div>
      {isNotifOpen.selected && (
        <Modal {...isNotifOpen.modal}>
          {notifDetails && notifDetails?.length > 0 ? (
            <ModalContent
              py={0}
              maxWidth="425px"
              minWidth={{ base: 'unset', sm: '425px' }}
              w={{ base: 'unset', sm: '100%' }}
              left={{ base: '0%', sm: 'unset' }}
              right={{ base: '0%', sm: '8%' }}
              zIndex="99"
              maxH="80%"
              position="fixed"
              className="bg-white text-left rounded-lg border border-gray-300 top-14 right-20"
            >
              <Box overflowY="auto">
                {hasInvitation && (
                  <Box h="78px" p="0 16px" hidden>
                    <NotificationDrawTabs
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      notificationStats={notificationStats}
                    />
                  </Box>
                )}
                {notifDetails.slice(0, 7).map((notifArr, index) => (
                  <Box key={Number(index)}>
                    {/* {index > 0 ? (
                      <div className="border-t border-gray-100" />
                    ) : (
                      <></>
                    )} */}
                    <div className="border-t border-gray-100" />
                    <Box className="dark:bg-gray-800 cursor-pointer">
                      <Box display="inline-flex">
                        <NotificationCard
                          notif={notifArr}
                          subTextWidth="100%"
                          subTextMaxWidth="20rem"
                          subTextFontSize="xs"
                          boxWidth="55.7px"
                          cardCenter={false}
                          clicked={handleCardClick}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              {loginUser && loginUser !== '' && (
                <div className="border-t border-gray-100 py-4 text-center">
                  <a
                    href={`/${loginUser}/notification?tab=${selectedTab}`}
                    className="text-denim-500 font-semibold text-sm"
                  >
                    View all
                  </a>
                </div>
              )}
            </ModalContent>
          ) : (
            <ModalContent
              py={0}
              maxWidth="407px"
              minWidth="407px"
              overflowY="scroll"
              height="60px"
              zIndex="99"
            >
              <Box>
                <Text
                  margin="auto"
                  width="max-content"
                  py={3}
                  fontWeight="semibold"
                  fontSize="md"
                  lineHeight="24px"
                  color="gray.300"
                >
                  No Notifications Yet
                </Text>
              </Box>
            </ModalContent>
          )}
        </Modal>
      )}
    </div>
  );
}
