import * as React from 'react';
import { Svg } from '../svg';

function SvgTwitch({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#twitch_svg__clip0_4815_67421)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.9 0L1.286 4.119v16.836h5.731V24h3.224l3.045-3.045h4.657l6.27-6.269V0H2.898zm19.163 13.612l-3.582 3.582h-5.73l-3.046 3.045v-3.045H4.87V2.149h17.194v11.463zm-3.582-7.343v6.262h-2.149V6.269h2.15zm-5.73 0v6.262H10.6V6.269h2.15z"
          fill="#5A3E85"
        />
      </g>
      <defs>
        <clipPath id="twitch_svg__clip0_4815_67421">
          <path fill="#fff" transform="translate(.75)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTwitch);
export default ForwardRef;
