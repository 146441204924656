import is from 'is_js';

function SearchFilterTitle({ title, selectList, hasFilterGroup = false }) {
  let selectTotal;

  if (hasFilterGroup) {
    selectTotal = 0;
    if (is.not.empty(selectList)) {
      selectTotal = Object.keys(selectList).reduce(
        (acc, groupId) => acc + selectList[groupId]?.length || 0,
        0
      );
    }
  } else {
    selectTotal = selectList.length;
  }

  return (
    <>
      <span className="font-bold mr-2">{title}</span>
      {selectTotal > 0 && (
        <span className="text-gray-400">({selectTotal})</span>
      )}
    </>
  );
}

export default SearchFilterTitle;
