import { createBreakpoints } from '@chakra-ui/theme-tools';

import { toEM } from '../tools';

const breakpoints = createBreakpoints({
  sm: toEM('544'),
  md: toEM('769'),
  lg: toEM('1024'),
  xl: toEM('1200'),
  xxl: toEM('1480'),
});

export default breakpoints;
