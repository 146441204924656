import { Icon, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import SvgClose from 'src/components/icons/close';
import { useIsMobile } from 'src/hooks/use-is-mobile';

const PageModal = ({ children, isOpen, setIsOpen, contentType }) => {
  const isMobile = useIsMobile();
  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      autoFocus={false}
      isCentered
      allowPinchZoom
    >
      <ModalOverlay>
        <ModalContent
          bg="$mode.50"
          pb="40px"
          w={isMobile ? '100%' : '70%'}
          maxW="1056px"
          h={isMobile ? '100%' : '90%'}
          borderRadius={isMobile ? '0' : '0.375rem'}
          maxH="996px"
        >
          <div className="bg-gray-900 relative text-center text-white font-semibold pt-16 pb-12">
            <span className="block mb-1">1TM</span>
            <span className="text-2xl block">
              {contentType === 'Community Guidelines' && 'Community Guidelines'}
              {contentType === 'Terms of Service' && 'Terms of Service'}
              {contentType === 'copyright' && 'Copyright Policy'}
              {contentType === 'Privacy Policy' && 'Privacy Policy'}
            </span>
            <span
              aria-hidden="true"
              className="absolute right-5 top-5 cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <Icon color="$mode.500" as={SvgClose} boxSize="14px" />
            </span>
          </div>
          <div
            className={`${
              isMobile ? 'px-8 pt-8' : 'px-16 pt-16'
            } h-full overflow-y-scroll`}
          >
            {children}
          </div>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
export default PageModal;
