import * as React from 'react';
import { Svg } from '../svg';

function SvgDelete({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M2.5 5h15M6.667 5V3.333a1.667 1.667 0 011.666-1.667h3.333a1.667 1.667 0 011.667 1.667V5m2.5 0v11.666a1.666 1.666 0 01-1.667 1.667H5.833a1.667 1.667 0 01-1.667-1.666V5h11.667zM8.334 9.166v5M11.666 9.166v5"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgDelete);
export default ForwardRef;
