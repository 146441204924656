import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { animated, useTransition } from 'react-spring';

import FormLayoutV2 from 'src/components/form-layout/form-layout-v2';
// import NotificationPopup from 'src/components/notification-popup';
import { useIsTablet, useIsMobile } from 'src/hooks/use-is-mobile';
import SvgBack from 'src/components/icons/back';
import { SvgInfoOutlined } from 'src/components/icons';
import ModalForgetPassword from './modal-forget-password';
import { SignInSection } from './sign-in-section';

export function SignInModal({
  modal,
  hasCloseButton = true,
}: {
  modal?: any;
  hasCloseButton?: boolean;
}) {
  const [toggle, setToggle] = useState(false);

  const onClick = () => setToggle((prevState) => !prevState);
  const transitions = useTransition(toggle, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { position: 'absolute', opacity: 0 },
  });

  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  return (
    <>
      <Modal isOpen={modal.isOpen} onClose={modal.onClose} allowPinchZoom>
        <ModalOverlay />
        <ModalContent maxW="100vw" w="100vw" h="100vh" borderRadius="none">
          {hasCloseButton && (
            <ModalCloseButton
              color={isMobile ? 'gray.600' : 'white'}
              _focus={{ outline: 'none' }}
              style={{ position: 'absolute', top: '21px', right: '30px' }}
            />
          )}
          <ModalBody p={0} overflow="hidden">
            <FormLayoutV2 title="Sign In - 1TM">
              {transitions.map(({ item, key, props }) => (
                <animated.div
                  key={key}
                  style={{
                    ...props,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {item ? (
                    <div
                      className="relative w-full h-full sm:h-auto rounded-none sm:rounded-3xl bg-gray-100 max-h-full px-12 sm:px-16 py-9 flex flex-col justify-between items-center mx-6 max-w-4xl"
                      style={{
                        minHeight: '656px',
                        boxShadow: isTablet
                          ? 'none'
                          : '0px 4px 30px rgba(0, 0, 0, 0.05)',
                      }}
                    >
                      {toggle && (
                        <span
                          aria-hidden="true"
                          className="absolute left-10 top-3 sm:left-11 sm:top-11"
                          onClick={onClick}
                        >
                          <SvgBack
                            alt="Back"
                            className="text-base text-gray-600 cursor-pointer"
                          />
                        </span>
                      )}
                      <div />
                      <ModalForgetPassword toggleForm={onClick} />
                      <div>
                        <div className="only-in-forgot-password-page flex items-center max-w-xs lg:max-w-full text-center lg:text-left">
                          <SvgInfoOutlined className="text-gry-400 mr-2 text-base hidden lg:inline" />
                          <p className="text-gray-500 text-xs">
                            For security reasons, 1TM does NOT store passwords.
                            Please rest assured that 1TM will never send your
                            password via email.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {toggle && (
                        <span
                          aria-hidden="true"
                          className="absolute left-10 top-3 sm:left-11 sm:top-11"
                          onClick={onClick}
                        >
                          <SvgBack
                            alt="Back"
                            className="text-base text-gray-600 cursor-pointer"
                          />
                        </span>
                      )}
                      <SignInSection toggleForm={onClick} />
                    </>
                  )}
                </animated.div>
              ))}
            </FormLayoutV2>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SignInModal;
