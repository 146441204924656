import * as React from 'react';
import { Svg } from '../svg';

function SvgClearCircle({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.666 10c0-4.6 3.733-8.333 8.333-8.333S18.333 5.4 18.333 10s-3.734 8.334-8.334 8.334A8.336 8.336 0 011.666 10zm4.798 2.357l1.178 1.179L10 11.179l2.357 2.357 1.179-1.179L11.178 10l2.357-2.357-1.179-1.178L10 8.822 7.642 6.465 6.464 7.643 8.82 10l-2.357 2.357z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgClearCircle);
export default ForwardRef;
