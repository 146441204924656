import dayjs from 'dayjs';

export const getArticleDraftDateTitle = () =>
  `Draft ${dayjs().format('yyyy-MM-dd HH:mm:ss')}`;

export const getTaggedMention = (contentValue) => {
  const mentionUsers = [];
  /* eslint-disable */
    if (contentValue?.content?.blocks?.length > 0) {
      contentValue?.content?.blocks?.forEach((blks) => {
        if(blks.data.text && blks.data.text.match(/\<a href="(.)+"\>@(.)+/g)) {
          const userArray = blks.data.text.match(/\<a href="(.)+"\>@(.)+/g)[0].split('</a>');
          const mappedUsers = userArray.flatMap((e) => e.match(/\@(.)+/g));
          const pureUsername = mappedUsers.map((d) => {
            if (d) {
              return d.replace("@", "").trim();
            }
          })
          mentionUsers.push(...pureUsername.filter(Boolean));
        }
      })
    }
    /* eslint-enable */
  return mentionUsers;
};
