import React, { useMemo, useRef } from 'react';
import { useDimensions } from '@chakra-ui/react';
import cn from 'classnames';

import styles from 'src/components/creation/Creation.module.scss';

function CardCategoryTags({ categories }) {
  const containerRef = useRef(null);
  const tagRefs = useRef([]);

  const containerDimensions = useDimensions(containerRef, true);
  const containerWidth = useMemo(() => containerDimensions?.contentBox.width, [
    containerDimensions,
  ]);

  const lastTagIndex = useMemo(
    () =>
      tagRefs.current.findIndex((tagRef) => {
        const [width, left] = tagRef;
        const ellipsisWidth = 35;
        return width + left > containerWidth - ellipsisWidth;
      }),
    [containerWidth]
  );

  const Tag = ({ category, index }) => (
    <div
      className={cn(styles.postgrid_category__button)}
      ref={(tagRef) => {
        if (tagRef && tagRef.offsetWidth) {
          tagRefs.current[index] = [tagRef.offsetWidth, tagRef.offsetLeft];
        }
      }}
    >
      <span
        style={{ fontSize: '12px' }}
        className={cn(styles.postgrid_category__button_text)}
      >
        {category}
      </span>
    </div>
  );

  return (
    <div
      style={{ minHeight: '39.19px', maxHeight: '39.19px' }}
      className={cn(styles.flex2_item1)}
      ref={containerRef}
    >
      <div className={cn(styles.block5_noBorder, styles.block5_layout)}>
        {categories
          .filter((n) => n)
          .map((category, index) =>
            lastTagIndex !== -1 && index >= lastTagIndex ? null : (
              <Tag key={category} category={category} index={index} />
            )
          )}
        {lastTagIndex !== -1 && (
          <div className={cn(styles.postgrid_category__button)}>
            <span className={cn(styles.postgrid_category__button_text)}>
              ...
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default CardCategoryTags;
