import {
  Button,
  ButtonGroup,
  ButtonProps,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalProps,
  UseDisclosureReturn,
  Checkbox,
  Text,
} from '@chakra-ui/react';
import { ReactNode, useState, useEffect } from 'react';
import SvgClose from 'src/components/icons/close';
import { useTranslation } from 'react-i18next';
import PageModal from 'src/components/footer/page-modal';
import Privacy from 'src/components/footer/privacy';
import Community from 'src/components/footer/community';

export function OneTmModal({
  showCancel = true,
  showOk = true,
  hasBottomBorder = true,
  isDisabled = false,
  isCheckBox = false,
  showOptionalField = false,
  ...props
}: {
  modalProps?: Partial<ModalProps>;
  modal?: UseDisclosureReturn;
  children: ReactNode;
  title?: string;
  contentProps?: ModalContentProps;
  okText?: string;
  cancelText?: string;
  onCancel?: () => void;
  okButtonProps?: ButtonProps;
  onOk?: () => void;
  showOk?: boolean;
  showCancel?: boolean;
  hasBottomBorder?: boolean;
  loading?: boolean;
  isDisabled?: boolean;
  isCheckBox?: boolean;
  showOptionalField?: boolean;
}) {
  const { t } = useTranslation();
  const [modalOpen, setIsModalOpen] = useState(false);
  const [contentType, setContentType] = useState('');
  const [checked, setChecked] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(isDisabled || false);
  const modalHandle = (type) => {
    setIsModalOpen(true);
    setContentType(type);
  };

  useEffect(() => {
    if (checked && isCheckBox) {
      setButtonDisable(false);
    }
    if (!checked && isCheckBox) {
      setButtonDisable(true);
    }
  }, [checked]);

  return (
    <Modal {...props.modal} isCentered {...props.modalProps}>
      <ModalOverlay>
        <ModalContent
          maxH={{ base: '100%', md: '90%' }}
          h={{ base: '100vh', md: 'auto' }}
          pt="40px"
          w="640px"
          maxW="100%"
          justifyContent="space-between"
          className="relative"
          {...props.contentProps}
        >
          <button
            type="button"
            className="absolute top-4 right-5"
            onClick={() => props.modal.onClose()}
          >
            <SvgClose fontSize="22px" />
          </button>
          <Flex flexDirection="column" overflowY="auto">
            {props.title && (
              <Flex
                justifyContent="space-between"
                px={{ base: '24px', md: '40px' }}
                alignItems="center"
              >
                <Heading as="h3" textStyle="h3">
                  {props.title}
                </Heading>
                {showOptionalField && (
                  <Text fontWeight="500" fontSize="14px" color="#D3D6D8">
                    optional
                  </Text>
                )}
              </Flex>
            )}
            {props.children}
          </Flex>
          <ButtonGroup
            justifyContent="flex-start"
            borderTop={hasBottomBorder ? '1px solid' : 'none'}
            borderTopColor="$mode.200"
            layerStyle="layer3"
            py="24px"
            px={{ base: '24px', md: '40px' }}
          >
            {isCheckBox && (
              <Flex direction="column" className="my-auto">
                <Flex>
                  <Checkbox
                    size="lg"
                    checked={checked}
                    alignItems="flex-start"
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                  <Text
                    className="text-xs"
                    ml="14px"
                    my="auto"
                    color="$mode.500"
                  >
                    Agree with the{' '}
                    <span
                      className="text-blue-500 cursor-pointer mr-1"
                      aria-hidden="true"
                      onClick={() => {
                        modalHandle('privacy');
                      }}
                    >
                      Policy
                    </span>
                    and{' '}
                    <span
                      className="text-blue-500 cursor-pointer"
                      aria-hidden="true"
                      onClick={() => {
                        modalHandle('community');
                      }}
                    >
                      Guidelines
                    </span>
                  </Text>
                </Flex>
              </Flex>
            )}
            <PageModal
              isOpen={modalOpen}
              setIsOpen={setIsModalOpen}
              contentType={contentType}
            >
              {contentType === 'community' && <Community />}
              {contentType === 'privacy' && <Privacy />}
            </PageModal>
            <div style={{ marginLeft: 'auto' }}>
              {showCancel && (
                <Button
                  colorScheme="gray"
                  onClick={props.onCancel || props?.modal?.onClose}
                  variant="ghost"
                  rounded="ellipse"
                  fontWeight="600"
                >
                  {t(props.cancelText) || t('Cancel')}
                </Button>
              )}
              {showOk && (
                <Button
                  type="submit"
                  disabled={buttonDisable}
                  onClick={props.onOk}
                  rounded="ellipse"
                  isLoading={props.loading || false}
                  fontWeight="600"
                  {...props.okButtonProps}
                >
                  {t(props.okText) || t('Confirm')}
                </Button>
              )}
            </div>
          </ButtonGroup>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

OneTmModal.px = { base: '24px', md: '40px' };
