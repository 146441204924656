import CC_BY_URL from './icons/CC_BY.svg';
import CC_NC_URL from './icons/CC_NC.svg';
import CC_ND_URL from './icons/CC_ND.svg';
import CC_SA_URL from './icons/CC_SA.svg';
import CC_URL from './icons/CC.svg';
import NC_EX_URL from './icons/NC_EX.svg';
import NC_FC_URL from './icons/NC_FC.svg';
import NC_NE_URL from './icons/NC_NE.svg';
import NC_NS_URL from './icons/NC_NS.svg';
import NC_NT_URL from './icons/NC_NT.svg';
import NC_SL_URL from './icons/NC_SL.svg';
import NC_URL from './icons/NC.svg';
import NC_BY_URL from './icons/NC_BY.svg';
import NC_NC_URL from './icons/NC_NC.svg';
import NC_ND_URL from './icons/NC_ND.svg';
import NC_SA_URL from './icons/NC_SA.svg';

export const iconURLMap = {
  CC_BY: CC_BY_URL,
  CC_NC: CC_NC_URL,
  CC_ND: CC_ND_URL,
  CC_SA: CC_SA_URL,
  CC: CC_URL,
  NC_EX: NC_EX_URL,
  NC_FC: NC_FC_URL,
  NC_NE: NC_NE_URL,
  NC_NS: NC_NS_URL,
  NC_NT: NC_NT_URL,
  NC_SL: NC_SL_URL,
  NC: NC_URL,
  NC_BY: NC_BY_URL,
  NC_NC: NC_NC_URL,
  NC_ND: NC_ND_URL,
  NC_SA: NC_SA_URL,
};

export const NCTree = {
  title: 'Full Copyright',
  question: 'Are you granting full copyright to the token holder?',
  yes: 'The token holder will have full copyright.',
  no: 'I want to specify how the work can be used.',
  //
  true: {
    result: 'NC FC-',
    title: 'Exclusive',
    question: 'Are you granting exclusive copyright?',
    yes:
      'I will transfer the exclusive copyright ownership to the token holder.',
    no:
      'I am only granting non-exclusive full copyright, and I will reserve my own copyright.',
    //
    true: {
      result: 'NC FC-EX',
      question: 'Can the licensee sub-license this work to a third party?',
      yes: 'It can be sub-licensed.',
      no: 'Sub-licensing is not allowed.',
      tooltip:
        'If choosing no, the licensee can reproduce, distribute, display, create derivative works, or transfer the copyright to a third party even for commercial purposes, without giving credits to the author. However, the licenseee cannot sublicense it to a third party.',
      //
      true: {
        result: 'NC FC-EX',
      },
      //
      false: {
        result: 'NC FC-EX-NS',
      },
    },
    //
    false: {
      result: 'NC FC-NE',
      question: 'Can the licensee transfer the copyright to a third party?',
      yes: 'The copyright can be transferred to a thirds party.',
      no: 'The copyright cannot be transferred to a third party.',
      tooltip:
        'Transfer of copyright ownership is known as an assignment. Unlike a license, which grants another party the ability to exercise certain copyright rights and isn’t a complete transfer of ownership of any of the copyright rights, an assignment of a copyright is a full transfer of ownership of the copyright. An assignment transfers and conveys to the assignee all rights associated with the copyright.',
      //
      true: {
        result: 'NC FC-NE',
      },
      //
      false: {
        result: 'NC FC-NE-NT',
      },
    },
  },
  //
  false: {
    result: 'NC BY',
    title: 'Commercial use',
    question: 'Can the licensee use your work commercially?',
    yes: 'It can be used even for commercial purposes.',
    no: "It can't be used for commercial purposes.",
    //
    true: {
      result: 'NC BY',
      title: 'Derivative Works',
      question:
        'Do you allow the licensee to remix, adapt or build upon your work?',
      yes: 'The licensee can remix, adapt, or build upon my work.',
      no: 'The licensee may only use my work in unadapted form.',
      //
      true: {
        result: 'NC BY',
        question:
          'Do you want to allow the licensee to share adaptations of your work under any terms?',
        yes: 'The licensee can share adaptations of my work under any terms.',
        no: 'The licensee must use the same NC license if they adapt my work.',
        //
        true: {
          result: 'NC BY',
        },
        //
        false: {
          result: 'NC BY-SA',
        },
      },
      //
      false: {
        result: 'NC BY-ND',
      },
    },
    //
    false: {
      result: 'NC BY-NC',
      title: 'Derivative Works',
      question: 'Do you allow others to remix, adapt or build upon your work?',
      yes: 'The licensee can remix, adapt or build upon my work.',
      no: 'The licensee may only use my work in unadapted form.',
      //
      true: {
        result: 'NC BY-NC',
        question:
          'Do you want to allow the licensee to share adaptations of your work under any terms?',
        yes: 'The licensee can share adaptations of my work under any terms.',
        no: 'The licensee must use the same NC license if they adapt my work.',
        //
        true: {
          result: 'NC BY-NC',
        },
        //
        false: {
          result: 'NC BY-NC-SA',
        },
      },
      //
      false: {
        result: 'NC BY-NC-ND',
      },
    },
  },
};

export const CCTree = {
  title: 'Commercial Use',
  question: 'Can the licensee use your work commercially?',
  yes: 'It can be used even for commercial purposes.',
  no: "It can't be used for commercial purposes.",
  //
  true: {
    result: 'CC BY',
    title: 'Derivative Works',
    question:
      'Do you allow the licensee to remix, adapt or build upon your work?',
    yes: 'The licensee can remix, adapt, or build upon my work.',
    no: 'The licensee may only use my work in unadapted form.',
    //
    true: {
      result: 'CC BY',
      question:
        'Do you want to allow the licensee to share adaptations of your work under any terms?',
      yes: 'The licensee can share adaptations of my work under any terms.',
      no: 'The licensee must use the same CC license if they adapt my work.',
      //
      true: {
        result: 'CC BY',
      },
      //
      false: {
        result: 'CC BY-SA',
      },
    },
    //
    false: {
      result: 'CC BY-ND',
    },
  },
  //
  false: {
    result: 'CC BY-NC',
    title: 'Derivative Works',
    question: 'Do you allow others to remix, adapt or build upon your work?',
    yes: 'The licensee can remix, adapt or build upon my work.',
    no: 'The licensee may only use my work in unadapted form.',
    //
    true: {
      result: 'CC BY-NC',
      question:
        'Do you want to allow the licensee to share adaptations of your work under any terms?',
      yes: 'The licensee can share adaptations of my work under any terms.',
      no: 'The licensee must use the same CC license if they adapt my work.',
      //
      true: {
        result: 'CC BY-NC',
      },
      //
      false: {
        result: 'CC BY-NC-SA',
      },
    },
    //
    false: {
      result: 'CC BY-NC-ND',
    },
  },
};

export const NCLicenseMap = {
  'NC FC-': {
    fullName: 'NFT Commons Full Copyright License',
    description:
      'This license grants full copyright to the token holder. Proceed to the next step to conclude the license type.',
    icons: ['NC', 'NC_FC'],
  },
  'NC FC-EX': {
    fullName: 'NFT Commons Exclusive Full Copyright License',
    description:
      'This license transfers the full copyright to the token holder. No person or company other than the token holder can exploit the relevant intellectual property rights. Importantly, the licensor is also excluded from exploiting the intellectual property rights. This license is only available for 1-of-1 tokens. (i.e. NFT).',
    icons: ['NC', 'NC_FC', 'NC_EX'],
  },
  'NC FC-EX-NS': {
    fullName:
      'NFT Commons Exclusive but Non-Sublicensable Full Copyright License',
    description:
      'This license transfers full copyright to the token holder, but the copyright cannot be transferred, assigned, or licensed to a third party. No person or company other than the token holder can exploit the relevant intellectual property rights. Importantly, the licensor is also excluded from exploiting the intellectual property rights. This license is only available for 1-of-1 tokens. (i.e. NFT).',
    icons: ['NC', 'NC_FC', 'NC_EX', 'NC_NS'],
  },
  'NC FC-NE': {
    fullName: 'NFT Commons Non-Exclusive Copyright License',
    description:
      'This license grants non-exclusive and non-sublicensable full copyright to the token holder. The licensee is allowed to exploit the intellectual property with no limitation; but is not allowed to sub-license the copyright to a third party. The licensor is the only party that is able to grant another non-exclusive license to other parties seeking the same rights.',
    icons: ['NC', 'NC_FC', 'NC_NE'],
  },
  'NC FC-NE-NT': {
    fullName:
      'NFT Commons Non-Exclusive Non-Transferable Full Copyright License',
    description:
      'The licensee is allowed to exploit the intellectual property with no limitation, but is not allowed to transfer or sub-license the copyright to a third party. This license is only available for non-transferable tokens. The licensor is the only party that is able to grant another non-exclusive license to other parties seeking the same rights. This license is only available for non-transferrable tokens (i.e. NTT).',
    icons: ['NC', 'NC_FC', 'NC_NE', 'NC_NT'],
  },
  'NC BY': {
    fullName: 'NFT Commons BY license',
    description:
      'This license is identical to the CC BY license, except that the license is granted to the asset token holder. This license allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, so long as attribution is given to the creator, even for commercial use.',
    icons: ['NC', 'NC_BY'],
  },
  'NC BY-SA': {
    fullName: 'NFT Commons BY-SA license',
    description:
      'This license is identical to the CC BY-SA license, except that the license is granted only to the asset token holders. This license allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, so long as attribution is given to the creator. The license allows for commercial use. If you remix, adapt or build upon the material, you must license the modified material under identical terms.',
    icons: ['NC', 'NC_BY', 'NC_SA'],
  },
  'NC BY-NC': {
    fullName: 'NFT Commons BY-NC license',
    description:
      'This license is identical to the CC BY-NC license, except that the license is granted to the asset token holder. This license allows reusers to distribute, remix, adapt, and build upon the material in any medium or format for noncommercial purposes only, and only so long as attribution is given to the creator.',
    icons: ['NC', 'NC_BY', 'NC_NC'],
  },
  'NC BY-NC-SA': {
    fullName: 'NFT Commons BY-NC-SA license',
    description:
      'This license is identical to the CC BY-NC-SA license, except that the license is granted to the asset token holder. This license allows reusers to distribute, remix, adapt, and build upon the material in any medium or format for noncommercial purposes only, and only so long as attribution is given to the creator. If you remix, adapt or build upon the material, you must license the modified material under identical terms.',
    icons: ['NC', 'NC_BY', 'NC_NC', 'NC_SA'],
  },
  'NC BY-ND': {
    fullName: 'NFT Commons BY-ND license',
    description:
      'This license is identical to the CC BY-ND license, except that the license is granted to the asset token holder. This license allows reusers to copy and distribute the material in any medium or format in unadapted form only, and only so long as attribution is given to the creator. The license allows for commercial use.',
    icons: ['NC', 'NC_BY', 'NC_ND'],
  },
  'NC BY-NC-ND': {
    fullName: 'NFT Commons BY-NC-NT license',
    description:
      'This license is identical to the CC BY-NC-ND license, except that the license is granted to the asset token holder. This license allows reusers to copy and distribute the material in any medium or format in unadapted form only, for noncommercial purposes only, and only so long as attribution is given to the creator.',
    icons: ['NC', 'NC_BY', 'NC_NC', 'NC_ND'],
  },
};

export const CCLicenseMap = {
  'CC BY': {
    fullName: 'Attribution 4.0 International',
    description:
      'This license requires that reusers give credit to the creator. It allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, even for commercial purposes.',
    icons: ['CC', 'CC_BY'],
  },
  'CC BY-SA': {
    fullName: 'Attribution-ShareAlike 4.0 International',
    description:
      'This license requires that reusers give credit to the creator. It allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, even for commercial purposes. If others remix, adapt, or build upon the material, they must license the modified material under identical terms.',
    icons: ['CC', 'CC_BY', 'CC_SA'],
  },
  'CC BY-NC': {
    fullName: 'Attribution-NonCommercial 4.0 International',
    description:
      'This license requires that reusers give credit to the creator. It allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, for noncommercial purposes only.',
    icons: ['CC', 'CC_BY', 'CC_NC'],
  },
  'CC BY-NC-SA': {
    fullName: 'Attribution-NonCommercial-ShareAlike 4.0 International',
    description:
      'This license requires that reusers give credit to the creator. It allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, for noncommercial purposes only. If others modify or adapt the material, they must license the modified material under identical terms.',
    icons: ['CC', 'CC_BY', 'CC_NC', 'CC_SA'],
  },
  'CC BY-ND': {
    fullName: 'Attribution-NoDerivatives 4.0 International',
    description:
      'This license requires that reusers give credit to the creator. It allows reusers to copy and distribute the material in any medium or format in unadapted form only, even for commercial purposes.',
    icons: ['CC', 'CC_BY', 'CC_ND'],
  },
  'CC BY-NC-ND': {
    fullName: 'Attribution-NonCommercial-NoDerivatives 4.0 International',
    description:
      'This license requires that reusers give credit to the creator. It allows reusers to copy and distribute the material in any medium or format in unadapted form and for noncommercial purposes only.',
    icons: ['CC', 'CC_BY', 'CC_NC', 'CC_ND'],
  },
};
