/**
 * Internal dependencies
 */
import { INITIAL_STATE } from './constants';
import * as reducers from '../reducers';
import * as types from '../reducers/types';

function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case types.FETCH_SEARCH_START:
      return {
        ...state,
        isFetching: true,
        isSearchLite: true,
      };

    case types.FETCH_SEARCH_SUCCESS:
      return reducers.fetchResultSuccess(state, payload);

    case types.FETCH_SEARCH_ERROR:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    case types.SET_SEARCH_TERM:
      return reducers.setSearchTerm(state, payload.searchTerm);

    case types.UPDATE_SEARCH_RESULT:
      return reducers.updateSearchResult(state, payload);

    case types.CLEAR_SEARCH:
      return reducers.clearSearch(state, payload.isSearchLite);

    case types.UPDATE_SIMILAR_SUGGESTIONS:
      return {
        ...state,
        similarSuggestions: payload.similarSuggestions,
      };

    case types.UPDATE_TRENDING_SUGGESTIONS:
      return {
        ...state,
        trendingSuggestions: payload.trendingSuggestions,
      };

    case types.UPDATE_RECENT_SEARCH_TERMS:
      return {
        ...state,
        recentSearchTerms: payload.recentSearchTerms,
      };

    default:
      return state;
  }
}

export default reducer;
