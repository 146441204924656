import * as React from 'react';
import { Svg } from '../svg';

function SvgEth({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.184 0l-.161.547v15.87l.161.16 7.366-4.355L12.184 0z"
        fill="#343434"
      />
      <path d="M12.184 0L4.818 12.223l7.366 4.354V0z" fill="#8C8C8C" />
      <path
        d="M12.184 17.972l-.09.11v5.653l.09.265 7.37-10.38-7.37 4.352z"
        fill="#3C3C3B"
      />
      <path d="M12.184 24v-6.028L4.818 13.62 12.184 24z" fill="#8C8C8C" />
      <path d="M12.184 16.576l7.366-4.354-7.366-3.348v7.702z" fill="#141414" />
      <path d="M4.818 12.223l7.366 4.354V8.875l-7.366 3.348z" fill="#393939" />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEth);
export default ForwardRef;
