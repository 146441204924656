import { toRem } from '../tools';

export const space = {
  0: '0',
  1: toRem(4), // 0.25rem
  2: toRem(8), // 0.5rem
  3: toRem(16), // 1rem
  4: toRem(24), // 1.5rem
  5: toRem(32), // 2rem
  6: toRem(40), // 2.5rem
  7: toRem(48), // 3rem
  8: toRem(56), // 3.5rem
  9: toRem(64), // 4rem
  10: toRem(128), // 8rem
};
