/**
 * External dependencies
 */
import algoliasearch from 'algoliasearch/lite';
import is from 'is_js';

/**
 * Internal dependencies
 */
import { FILTER_TYPES } from 'src/app/search/constants';
import categories from 'src/configs/categories';

const client = algoliasearch(
  process.env.ALGOLIA_SEARCH_KEY,
  process.env.ALGOLIA_SEARCH_SECRET
);

const suggestionClient = algoliasearch(
  process.env.ALGOLIA_SEARCH_KEY,
  process.env.ALGOLIA_SUGGESTION_SECRET
);

const searchIndex = client.initIndex(process.env.ALGOLIA_SEARCH_INDEX);
const suggestionIndex = suggestionClient.initIndex(
  process.env.ALGOLIA_SEARCH_SUGGESTION_INDEX
);

const isCategory = (data) => {
  const fetched = categories.filter((cat) => cat.value === data);
  return fetched.length > 0;
};

const search = async (
  keyword: string,
  hitsPerPage: number = 1000,
  filters: string = '', // filterType:User
  facet: string = '',
  maxValuesPerFacet: number = 5
) => {
  let searchKeyword = keyword;

  const options = {
    hitsPerPage,
  };

  if (filters !== '') {
    options['filters'] = filters;
  }

  if (facet !== '') {
    switch (facet) {
      case 'UsersNull':
        searchKeyword = '';
        options['analytics'] = false;
        options['attributesToRetrieve'] = ['*'];
        options['attributesToSnippet'] = ['*:20'];
        options['enableABTest'] = false;
        options['explain'] = ['*'];
        options['facetFilters'] = [
          ['filterType:USER'],
          ['publicAddress:-NULL'],
        ];
        options['facets'] = ['*'];
        options['getRankingInfo'] = true;
        options['maxValuesPerFacet'] = 100;
        options['responseFields'] = ['*'];
        options['snippetEllipsisText'] = '…';
        break;

      case 'facetFilters':
        // Search Tribes (categories & tags)
        searchKeyword = '';
        options['facetFilters'] = [
          [
            ...(isCategory(keyword) && `categories:${keyword}`),
            `tags:${keyword}`,
          ],
        ];
        break;

      default:
        options['facets'] = ['*'];
        options['maxValuesPerFacet'] = maxValuesPerFacet;
        break;
    }
  }

  return searchIndex.search(searchKeyword, options).then(({ hits, facets }) => {
    if (facets) {
      const newFacets = [];
      if (is.object(facets.tags)) {
        Object.keys(facets.tags).forEach((key) => {
          newFacets.push({
            type: 'tags',
            title: key,
            posts: facets.tags[key],
          });
        });
      }
      if (is.object(facets.categories)) {
        Object.keys(facets.categories).forEach((key) => {
          newFacets.push({
            type: 'categories',
            title: key,
            posts: facets.categories[key],
          });
        });
      }
      return newFacets;
    }
    return is.array(hits) ? hits : [];
  });
};

const searchTopic = async (
  keyword: string,
  hitsPerPage: number = 1000,
  maxValuesPerFacet: number = 5
) => {
  const options = {
    hitsPerPage,
    maxValuesPerFacet,
    facets: ['tags', 'categories'],
    facetFilters: [
      [
        `filterType:${FILTER_TYPES.CREATIONS}`,
        `filterType:${FILTER_TYPES.CREATIVE_ASSETS}`,
      ],
    ],
  };

  return searchIndex.search(keyword, options).then(({ hits, facets }) => {
    if (facets) {
      const newFacets = [];
      if (is.object(facets.tags)) {
        Object.keys(facets.tags).forEach((key) => {
          if (key.toLowerCase().includes(keyword.toLowerCase())) {
            newFacets.push({
              type: 'tags',
              title: key,
              posts: facets.tags[key],
            });
          }
        });
      }
      if (is.object(facets.categories)) {
        Object.keys(facets.categories).forEach((key) => {
          if (
            key.toLowerCase().includes(keyword.toLowerCase()) &&
            isCategory(key)
          ) {
            newFacets.push({
              type: 'categories',
              title: key,
              posts: facets.categories[key],
            });
          }
        });
      }
      return newFacets;
    }
    return is.array(hits) ? hits : [];
  });
};

const suggestions = async (keyword: string, limit = 30) => {
  return suggestionIndex
    .search(keyword, {
      hitsPerPage: limit,
    })
    .then(({ hits }) => {
      if (is.array(hits) && is.not.empty(hits)) {
        hits.sort((a, b) => (a['popularity'] >= b['popularity'] ? -1 : 1));
        return hits.map((hit) => hit['query']);
      }
      return [];
    });
};

const algolia = {
  search,
  searchTopic,
  suggestions,
};
export default algolia;
