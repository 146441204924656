import * as React from 'react';
import { Svg } from '../svg';

function SvgLogout({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M3 16h2v2h12V2H5v2H3V1a1 1 0 011-1h14a1 1 0 011 1v18a1 1 0 01-1 1H4a1 1 0 01-1-1v-3zm2-7h7v2H5v3l-5-4 5-4v3z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgLogout);
export default ForwardRef;
