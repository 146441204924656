import * as React from 'react';
import { Svg } from '../svg';

function SvgGallery({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M28 28h-6.4m6.4 0l-8-8 8 8zm0 0v-6.4V28zM4 28l8-8m-8 1.6V28v-6.4zM4 28h6.4H4zM28 4l-8 8m8-1.6V4v6.4zM28 4h-6.4H28zM4 4l8 8m-8-1.6V4v6.4zM4 4h6.4H4z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgGallery);
export default ForwardRef;
