export const CollectionsStartBody = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Build your Studio
      </h1>
      <img
        src="/images/assets/tutorial/collections-start.png"
        alt="collections start"
        className="w-full"
      />
    </>
  );
};

export const CollectionsStartFooter = ({
  setNextModal,
}: {
  setNextModal?: any;
}) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Create a Collection
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        A collection is a curated gathering of creations by an individual, where
        their inspiration and imagination drive the topic.
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('collectionsDone');
        }}
        className="px-9 py-2.5 mb-6 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Start
      </button>
    </>
  );
};
