import * as React from 'react';
import { Svg } from '../svg';

function SvgLockedIcon({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        clipPath="url(#locked-icon_svg__clip0_6588_79070)"
        stroke="#BBC0C5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M26.333 19.374H14.667c-.92 0-1.667.746-1.667 1.667v5.833c0 .92.746 1.667 1.667 1.667h11.666c.92 0 1.667-.747 1.667-1.667v-5.833c0-.92-.746-1.667-1.667-1.667zM16.333 19.374v-3.333a4.167 4.167 0 118.333 0v3.333" />
      </g>
      <rect x={1} y={0.707} width={39} height={39} rx={19.5} stroke="#D3D6D8" />
      <defs>
        <clipPath id="locked-icon_svg__clip0_6588_79070">
          <path
            fill="#fff"
            transform="translate(10.5 10.207)"
            d="M0 0h20v20H0z"
          />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgLockedIcon);
export default ForwardRef;
