import { useRef, useEffect } from 'react';
import Link from 'next/link';
import cn from 'classnames';

import { TeamMemberPhotoCard } from 'src/components/team-member-photo-card';
import section2Styles from 'src/components/team/Team_full_state_section2.module.scss';

interface Props {
  data: any;
}
const CreatorGrid = ({ data: user }: Props) => {
  const aboutContainerRef = useRef(null);

  useEffect(() => {
    const aboutContainer = aboutContainerRef?.current;
    if (aboutContainer) {
      const aboutContent = aboutContainer.innerText;
      const contentLength = aboutContent.length;

      const charLimit =
        Math.floor((aboutContainer.clientWidth / 14) * 1.666) * 3;

      if (contentLength > charLimit) {
        aboutContainer.innerHTML = `${aboutContent.substring(
          0,
          charLimit - 4
        )} ...`;
      }
    }
  }, []);

  return (
    <Link prefetch={false} href={{ pathname: `/${user['username']}` }}>
      <div key={user.userID} className={cn(section2Styles.grid_item)}>
        <div className={cn(section2Styles.flex2, section2Styles.flex2_layout)}>
          <div className={cn(section2Styles.editor_avatar)}>
            <div
              style={{
                border: '1px solid rgba(255, 255, 255, 0.4)',
                width: '100%',
              }}
              className="rounded-lg overflow-hidden"
            >
              <TeamMemberPhotoCard
                name={user?.username}
                data-testid={user ? user?.avatar : ''}
                bg="$mode.400"
                svgClassName="team"
                width="100%"
                height="100%"
                src={user?.profileImgURL}
                author={user}
              />
            </div>
          </div>
          <div
            className={cn(
              section2Styles.small_text_body,
              section2Styles.small_text_body_layout,
              section2Styles.caption_layout
            )}
          >
            <span>
              {user?.userType.slice(0, 1).toUpperCase() +
                user?.userType.slice(1).toLowerCase()}
            </span>
            <span>
              {user?.numOfFollowers}
              {` ${user?.numOfFollowers > 1 ? 'followers' : 'follower'}`}
            </span>
          </div>
          <h3
            className={cn(
              section2Styles.subtitle1,
              section2Styles.subtitle1_layout
            )}
          >
            {user?.username}
          </h3>
          {user?.about && (
            <div
              className={cn(
                section2Styles.paragraph_body,
                section2Styles.paragraph_body_layout
              )}
              ref={aboutContainerRef}
            >
              {user.about}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
export default CreatorGrid;
