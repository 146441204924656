import * as React from 'react';
import { Svg } from '../svg';

function SvgArrowRight({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8.295 7.41l4.58 4.59-4.58 4.59L9.705 18l6-6-6-6-1.41 1.41z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowRight);
export default ForwardRef;
