import * as React from 'react';
import { Svg } from '../svg';

function SvgPioneerV2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M3.917 5.833v0A2.917 2.917 0 001 8.75v2.917a2.5 2.5 0 002.5 2.5v0M18.083 5.833v0A2.917 2.917 0 0121 8.75v2.917a2.5 2.5 0 01-2.5 2.5v0M17.666 15.417v1.488c0 .793-.363 1.541-1.1 1.833-1.001.395-2.758.846-5.566.846-2.97 0-4.764-.504-5.73-.914-.627-.265-.937-.907-.937-1.587v0-1.666"
        stroke="#4F5356"
        strokeWidth={1.5}
      />
      <path
        d="M11 17.625c2.058 0 4.174-.14 5.753-1.321 1.633-1.223 2.497-3.391 2.497-6.929a8.25 8.25 0 00-16.5 0c0 3.821.85 6.025 2.52 7.18.814.561 1.752.82 2.714.947.945.123 1.973.123 2.987.123H11z"
        stroke="#4F5356"
        strokeWidth={1.5}
      />
      <mask id="Pioneer-v2_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.377 7.61A5.833 5.833 0 1016.562 7.4c-.832-.324-2.457-.734-5.362-.734-3.592 0-5.228.627-5.823.943z"
        />
      </mask>
      <path
        d="M5.377 7.61l-.703-1.326-.571.303-.172.623 1.446.4zM16.56 7.4l1.43-.453-.217-.683-.668-.26-.545 1.397zM6.666 9.167c0-.402.055-.79.156-1.157L3.931 7.21a7.34 7.34 0 00-.264 1.956h3zM11 13.5a4.333 4.333 0 01-4.333-4.334h-3A7.333 7.333 0 0011 16.5v-3zm4.333-4.334A4.333 4.333 0 0111 13.5v3a7.333 7.333 0 007.333-7.334h-3zm-.202-1.312c.131.412.202.853.202 1.312h3c0-.77-.12-1.517-.342-2.219l-2.86.907zm1.975-1.85c-1.046-.409-2.866-.837-5.906-.837v3c2.77 0 4.2.391 4.816.631l1.09-2.795zM11.2 5.166c-3.757 0-5.653.655-6.526 1.117L6.08 8.935c.318-.169 1.694-.769 5.121-.769v-3z"
        fill="#4F5356"
        mask="url(#Pioneer-v2_svg__a)"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPioneerV2);
export default ForwardRef;
