import * as React from 'react';
import { Svg } from '../svg';

function SvgPioneer({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M5.667 10.045v0A4.667 4.667 0 001 14.712v4.666a4 4 0 004 4v0"
        stroke="url(#pioneer_svg__paint0_linear_7097_75035)"
        strokeWidth={1.5}
      />
      <path
        d="M28.333 10.045v0A4.667 4.667 0 0133 14.712v4.666a4 4 0 01-4 4v0"
        stroke="url(#pioneer_svg__paint1_linear_7097_75035)"
        strokeWidth={1.5}
      />
      <path
        d="M27.666 25.378v2.381c0 1.27-.58 2.467-1.761 2.933-1.601.632-4.412 1.353-8.905 1.353-4.752 0-7.622-.806-9.17-1.461-1.001-.424-1.497-1.451-1.497-2.54v0-2.666"
        stroke="url(#pioneer_svg__paint2_linear_7097_75035)"
        strokeWidth={1.5}
      />
      <path
        d="M17 28.71c3.296 0 6.617-.227 9.084-2.073C28.624 24.736 30 21.345 30 15.71c0-7.18-5.82-13-13-13s-13 5.82-13 13c0 6.094 1.357 9.534 3.946 11.323 1.266.874 2.732 1.282 4.254 1.481 1.5.196 3.133.196 4.76.196H17z"
        stroke="url(#pioneer_svg__paint3_linear_7097_75035)"
        strokeWidth={2}
      />
      <mask id="pioneer_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.003 12.886a9.333 9.333 0 1017.895-.334c-1.33-.518-3.93-1.175-8.578-1.175-5.747 0-8.364 1.004-9.317 1.51z"
        />
      </mask>
      <path
        d="M8.003 12.886l-.937-1.767-.762.404-.23.83 1.929.533zm17.895-.334l1.906-.605-.289-.911-.89-.347-.727 1.863zM9.666 15.377c0-.681.093-1.337.264-1.958l-3.855-1.065c-.267.964-.409 1.979-.409 3.023h4zM17 22.71a7.333 7.333 0 01-7.334-7.333h-4c0 6.26 5.075 11.333 11.334 11.333v-4zm7.333-7.333A7.333 7.333 0 0117 22.71v4c6.26 0 11.333-5.074 11.333-11.333h-4zm-.341-2.22c.221.697.341 1.443.341 2.22h4c0-1.192-.185-2.345-.529-3.43l-3.812 1.21zm2.633-2.468c-1.617-.63-4.476-1.312-9.305-1.312v4c4.467 0 6.81.632 7.851 1.038l1.454-3.726zM17.32 9.377c-5.967 0-8.93 1.04-10.254 1.742l1.873 3.534c.584-.309 2.854-1.276 8.381-1.276v-4z"
        fill="url(#pioneer_svg__paint4_linear_7097_75035)"
        mask="url(#pioneer_svg__a)"
      />
      <defs>
        <linearGradient
          id="pioneer_svg__paint0_linear_7097_75035"
          x1={1}
          y1={16.712}
          x2={5.667}
          y2={16.709}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B960FC" />
          <stop offset={1} stopColor="#3F63F9" />
        </linearGradient>
        <linearGradient
          id="pioneer_svg__paint1_linear_7097_75035"
          x1={33}
          y1={16.712}
          x2={28.333}
          y2={16.709}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B960FC" />
          <stop offset={1} stopColor="#3F63F9" />
        </linearGradient>
        <linearGradient
          id="pioneer_svg__paint2_linear_7097_75035"
          x1={17}
          y1={32.045}
          x2={17.003}
          y2={25.378}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B960FC" />
          <stop offset={1} stopColor="#3F63F9" />
        </linearGradient>
        <linearGradient
          id="pioneer_svg__paint3_linear_7097_75035"
          x1={5}
          y1={15.711}
          x2={29}
          y2={15.669}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B960FC" />
          <stop offset={1} stopColor="#3F63F9" />
        </linearGradient>
        <linearGradient
          id="pioneer_svg__paint4_linear_7097_75035"
          x1={7.667}
          y1={18.044}
          x2={26.333}
          y2={17.998}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B960FC" />
          <stop offset={1} stopColor="#3F63F9" />
        </linearGradient>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPioneer);
export default ForwardRef;
