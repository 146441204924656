const Terms = () => {
  return (
    <div className="policy  text-sm">
      <h5 className="font-bold">Terms of Service for Early Access Program </h5>
      <p>
        This Terms of Service (the “Terms”) describes the rights and
        responsibilities that apply to your use of 1TM&apos;s websites,
        services, and mobile app (collectively, the “Service”), each owned and
        operated by 1TM PTE. LTD. (“1TM”, “we”, “our“ or “us”).
      </p>
      <p>
        Please read the Terms carefully before using the Service. If you do not
        agree to the Terms and 1TM&apos;s Community Guidelines (the “Community
        Guidelines“), you may not use the Service. If you are entering into the
        Terms on behalf of a company or other legal entity, you represent that
        you have the authority to bind such entity to the Terms. If you do not
        have such authority, you must not accept the Terms or use the Service on
        behalf of such entity. The Service is only available to you if you are
        fully able and competent to enter into, abide by and comply with the
        Terms.
      </p>

      <h5 className="font-bold">Your 1TM Account.</h5>
      <p>
        To register as a member of the Service, you must be 13 years or lawfully
        permitted to enter into and form contracts under applicable law. In no
        event may minors submit Content to the Service. You agree that the
        information that you provide to us upon registration, and at all other
        times will be true, accurate, current and complete. You also agree that
        you will ensure that this information is kept accurate and up to date at
        all times. This is especially important with respect to your email
        address, since that is the primary way in which we will communicate with
        you about your account and your orders.
      </p>
      <p>
        If you create an account on the Service (your “Account”), you are
        responsible for maintaining the security of your Account and its Content
        (as defined below), and you are fully responsible for all activities
        that occur under your Account and any other actions taken on the
        Service. You must not describe or assign Content to your Account in a
        misleading or unlawful manner, including in a manner intended to trade
        on the name or reputation of others, and 1TM may change or remove any
        description or keyword that it considers inappropriate or unlawful, or
        otherwise likely to cause 1TM liability. You must immediately notify 1TM
        of any unauthorized uses of your Account or any other breaches of
        security. 1TM will not be liable for any acts or omissions by you,
        including any damages of any kind incurred as a result of such acts or
        omissions.
      </p>

      <h5 className="font-bold">Your Responsibilities. </h5>
      <p>
        If you operate an Account, post comments, post material to the Service,
        post links on the Service, or otherwise make (or allow any third party
        to make) material available by means of the Service (any such material,
        “Content”), you are entirely responsible for the content of, and any
        harm resulting from, that Content. That is the case regardless of
        whether the Content in question constitutes text or graphics. By making
        Content available, you represent and warrant that:
      </p>

      <ul className="list-lower-latin">
        <li>
          the downloading, copying and use of the Content will not infringe the
          proprietary rights, including but not limited to the copyright,
          patent, trademark or trade secret rights, of any third party;
        </li>
        <li>
          if your employer has rights to intellectual property you create, you
          have either (i) received permission from your employer to post or make
          available the Content, including but not limited to any software, or
          (ii) secured from your employer a waiver as to all rights in or to the
          Content;
        </li>
        <li>
          you have fully complied with any third-party licenses relating to the
          Content, and have done all things necessary to successfully pass
          through to end users any required terms;
        </li>
        <li>
          the Content does not contain or install any viruses or other harmful
          or destructive content;
        </li>
        <li>
          the Content is not spam, is not machine or randomly-generated, and
          does not contain unethical or unwanted commercial content designed to
          drive traffic to third party sites or boost the search engine rankings
          of third party sites, or to further unlawful acts (such as phishing)
          or mislead recipients as to the source of the material (such as
          spoofing);
        </li>
        <li>
          the Content is not obscene, libelous or defamatory, hateful or
          racially or ethnically objectionable, and does not violate the privacy
          or publicity rights of any third party;
        </li>
        <li>
          your Account is not getting advertised via unwanted electronic
          messages such as spam links on newsgroups, email lists, other blogs
          and websites, and similar unsolicited promotional methods;
        </li>
        <li>
          your Account is not named in a manner that misleads your readers into
          thinking that you are another person or company. For example, your
          Account&apos;s URL or name is not the name of a person other than
          yourself or company other than your own; and
        </li>
        <li>
          you have, in the case of Content that includes computer code,
          accurately categorized and/or described the type, nature, uses and
          effects of the materials, whether requested to do so by 1TM or
          otherwise.
        </li>
      </ul>
      <p>
        By uploading Content or otherwise providing Content to 1TM, you grant
        1TM the right to use (including the right to host, index, cache and
        format) and display your Content in connection with providing the
        Service as well as otherwise on the Site and our marketing
        communications with you and other users and prospective users of the
        Service, including without limitation on our emails, marketing channels
        and other promotional materials. To any extent that we use your Content
        in our marketing communications, unless you expressly permit us to do
        otherwise, we will always cite you as the owner of such Content. This
        paragraph will survive the termination or expiry of the Terms.
      </p>
      <p>
        1TM reserves the right to refuse and/or remove any Content or terminate
        or deny access to your use of the Service for any reason whatsoever.
        Please read the Community Guidelines for rules and advice on what types
        of Content and uses of the Service are appropriate at 1TM.
      </p>
      <p>
        All Content uploaded are copyright © of their respective owners.
        However, by displaying or publishing any Content on or through the
        Services, you hereby grant to 1TM a non-exclusive, fully paid and
        royalty-free, worldwide, limited license to use, modify, delete from,
        add to, publicly perform, publicly display, reproduce and translate such
        Content, including without limitation, the distributing in part or in
        all any media formats through any media channels. This is solely for the
        purposes of providing and improving our Products and services. This
        means, for example, that if you share a photo on 1TM, you give us
        permission to store, copy, and share it with others.This license will
        end when your content is deleted from our servers upon request.
      </p>

      <p>
        {' '}
        During the Early Access Program, the copyright of any Content uploaded
        to 1TM will continue to be owned by you. At the end of the Early Access
        Program, you will be given the login credentials of your account, which
        you can use to continue to access Service provided by 1TM. However, if
        you also choose to delete the content; upon notification from you, 1TM
        will delete your Content within a reasonable amount of time.
      </p>
      <p>
        Please note that when you upload Content, third parties may be able to
        copy, distribute and display your Content using readily available tools
        on their computers for this purpose although other than by linking to
        your Content on 1TM, any use by a third party of your Content could
        violate these Terms unless the third party receives verbal or written
        permission from you by license.
      </p>
      <p>
        If you delete Content, 1TM will use reasonable efforts to remove it from
        the Service, but you acknowledge that caching or references to the
        Content may not be made immediately unavailable.
      </p>
      <h5 className="font-bold">Rules.</h5>
      <p>
        Use of any of the Services is subject to the following rules. You agree
        that, in addition to abiding by our Community Guidelines, you will not
        do any of the following:
      </p>
      <ul className="list-circle">
        <li>
          Use, support or develop bots, scrapers, software, scripts, crawlers,
          browser plugins, robots or other automated methods including
          human-powered automation through like farming or any other
          crowd-sourced methods to access, scrape, or copy the Services, or to
          add or download data, or send or redirect information or messages;
        </li>
        <li>
          Reverse engineer, decompile, disassemble, decipher or otherwise
          attempt to derive the source code for the Services or any related
          technology that we have not made open source;
        </li>
        <li>
          Rent, lease, loan, trade, sell or otherwise monetize the Services or
          related data or access to the same, without our express written
          consent;
        </li>
        <li>
          Monitor the Services&apos; availability, performance or functionality
          for any competitive purpose;
        </li>
        <li>
          Engage in “framing,” “mirroring,” or otherwise simulating the
          appearance or function of the Services;
        </li>
        <li>
          Post anything that contains software viruses, worms, or any other
          harmful code;
        </li>
        <li>
          Overlay or otherwise modify the Services or their appearance (such as
          by inserting elements into the Services or removing, covering, or
          obscuring an advertisement included on the Services);
        </li>
        <li>
          Interfere with the operation of, or place an unreasonable load on, the
          Services (e.g., spam, denial of service attack, viruses, gaming
          algorithms);
        </li>
        <li>
          Override any security feature or bypass or circumvent any access
          controls or limits of the Services;
        </li>
        <li>
          Create a false identity, misrepresent your identity, create a profile
          for anyone other than yourself, or use or attempt to use
          another&apos;s account; or
        </li>
        <li>
          Violate our intellectual property rights or the intellectual property
          rights of others.
        </li>
      </ul>
      <h5 className="font-bold">Third Party Websites and Content. </h5>
      <p>
        We have not reviewed, and cannot review, all of the material, including
        computer software, made available through the websites and webpages to
        which 1TM links, and that link to 1TM. 1TM does not have any control
        over those non-1TM websites and webpages, and is not responsible for
        their contents or their use. By linking to a non-1TM website or webpage,
        1TM does not represent or imply that it endorses such website or
        webpage. You are responsible for taking precautions as necessary to
        protect yourself and your computer systems from viruses and other
        harmful or destructive content. 1TM disclaims any responsibility for any
        harm resulting from your use of non-1TM websites and webpages.
      </p>
      <h5 className="font-bold">Intellectual Property.</h5>
      <p>
        All material available on the Service and all material and services
        provided by or through 1TM, its affiliates, subsidiaries, employees,
        agents, licensors or other commercial partners including, but not
        limited to, software, all informational text, software documentation,
        design of and “look and feel”, layout, photographs, graphics, audio,
        video, messages, interactive and instant messaging, design and
        functions, files, documents, images, or other materials, whether
        publicly posted or privately transmitted as well as all derivative works
        thereof (collectively, the “Materials”), are owned by us or our
        licensors or service providers, and are protected by copyright,
        trademark, trade secret and other intellectual property laws.
      </p>
      <p>
        If 1TM, in its sole discretion and without notice, considers that there
        is an immediate security or operational risk to the Service, any of its,
        your or a third party system, then 1TM may immediately suspend access to
        or use of the Service. The suspension of use and access is not a breach
        of the Terms. You acknowledge that the preservation of security,
        confidentiality and data is paramount. 1TM has no liability to you for
        suspending the Service under this provision.
      </p>

      <p>
        This section does not apply to Content; however you agree that any
        ideas, suggestions, concepts, processes or techniques which you provide
        to 1TM related to the Service or 1TM or its business (“Feedback”) are
        and will be 1TM&apos;s exclusive property without any compensation or
        other consideration payable to you by 1TM, and you do so of your own
        free will and volition. 1TM may or may not, at its sole discretion, use
        or incorporate the Feedback in whatever form or derivative 1TM may
        decide into the Service or its business or other products. You hereby
        assign all rights on a worldwide basis in perpetuity to 1TM in any
        Feedback and, as applicable, waive any moral rights.
      </p>
      <p>
        1TM retains the right to use or share any Aggregated Data generated by
        anyone using the Service, including our users, for the purpose of
        enhancing and providing the Service. “Aggregated Data” means data that
        does not contain personal information and which has been manipulated or
        combined to provide generalized, anonymous information. Where you choose
        to utilize or connect certain services from third parties with the
        Service, you agree that 1TM may share your lead data with such
        designated third parties. You are still responsible for any and all
        personal information that is part of any Content.
      </p>

      <h5 className="font-bold">Changes.</h5>
      <p>
        1TM reserves the right, at its sole discretion, to modify or replace any
        part of the Terms. It is your responsibility to check the Terms
        periodically for changes. Your continued use of or access to the Service
        following the posting of any changes to the Terms constitutes acceptance
        of those changes. 1TM may also, in the future, offer new services and/or
        features through the Service (including, the release of new tools and
        resources). Such new features and/or services shall be subject to the
        Terms.
      </p>

      <h5 className="font-bold">Termination.</h5>
      <p>
        1TM may terminate your access to all or any part of the Service at any
        time, with or without cause, with or without notice, effective
        immediately. If you wish to terminate the Terms or your Account (if you
        have one), you may simply discontinue using the Service. All provisions
        of the Terms which by their nature should survive termination shall
        survive termination, including, without limitation, ownership
        provisions, warranty disclaimers, indemnity and limitations of
        liability.
      </p>
      <h5 className="font-bold">Disclaimer of Warranties.</h5>
      <p>
        Your use of the Service and all Content forming part of or related to
        the Service, including any Content you upload or submit and any third
        party software and Content, are at your sole responsibility and risk.
        The Service are provided on an “as is” and “as available” basis. 1TM
        expressly disclaims all representations, warranties, or conditions of
        any kind with respect to the Service, whether express or implied,
        including, but not limited to, any implied warranties or conditions of
        merchantability, fitness for a particular purpose, accuracy,
        completeness, performance, system integration, quiet enjoyment, title,
        and non-infringement.
      </p>

      <h5 className="font-bold">Limitation of Liability.</h5>
      <p>
        You agree that, under no legal theory, including but not limited to:
        negligence, breach of warranty or condition, breach of contract or tort,
        will 1TM or its owners, officers, directors, affiliates, contractors,
        employees or agents, be liable to you or any third party acting on your
        behalf for any indirect, incidental, special, consequential, punitive,
        or exemplary damages or damages for loss of profits, goodwill, use,
        data, or other intangible losses or the cost of any substitute
        equipment, facilities or services (even if 1TM has been advised of the
        possibility of such damages), arising from or relating to the Terms or
        your use of or your inability to use the Service, or for any damages
        arising from or related to the Terms. 1TM&apos;s total aggregate
        liability from any and all claims under the Terms is limited to the
        total amounts you paid to 1TM in the one (1) year immediately preceding
        the occurrence of loss or damage. To the extent any province, state or
        jurisdiction does not allow the exclusion or the limitation of liability
        for consequential or incidental damages, 1TM&apos;s liability in such
        province, state or jurisdiction will be limited to the furthest extent
        permitted by law. Notwithstanding the foregoing or anything else herein
        to the contrary, 1TM will not be liable to you or any third party acting
        on your behalf in any way with respect to a trial program or your
        provision of an individual&apos;s personal information to 1TM or through
        the Service. You further agree that the foregoing limitations will apply
        with respect to third party liability of any kind.
      </p>
      <p>
        The foregoing limitations will also apply with respect to any damages
        incurred by reason of any content or services provided on any third
        party sites or otherwise provided by any third parties other than 1TM
        and received by you through or advertised on the Service or received by
        you on any third party sites. You also agree that 1TM will not be
        responsible or liable for any loss or damage of any kind incurred as the
        result of any interactions or dealings with advertisers or as the result
        of the presence of such advertisers on the Service.
      </p>
      <p>
        With respect to any dispute arising out of or related to the Service
        and/or the Terms: (i) you hereby expressly give up your right to have a
        trial by jury; and (ii) you hereby expressly give up your right to
        participate as a member of a class of claimants, in any lawsuit
        including but not limited to class action lawsuits involving any such
        dispute. You agree that you will not bring a claim under or related to
        the Terms more than one (1) year from when your claim first arose.
      </p>

      <h5 className="font-bold">General Representation and Warranty. </h5>
      <p>
        You represent and warrant that (i) your use of the Service will be in
        strict accordance with the Terms and the Community Guidelines and all
        applicable laws and regulations (including without limitation any local
        laws or regulations in your country, state, city, or other governmental
        area, regarding online conduct and acceptable content, and including all
        applicable laws regarding the transmission of technical data exported
        from Singapore or the country in which you reside) and (ii) your use of
        the Service will not infringe or misappropriate the intellectual
        property rights of any third party.
      </p>

      <h5 className="font-bold">Indemnification.</h5>
      <p>
        You agree to indemnify, defend, and hold harmless 1TM, and its
        subsidiaries, affiliates, co-branders, all third-party advertisers,
        technology providers, service providers or other partners, and each of
        their respective officers, directors, agents, shareholders, employees
        and representatives (together, the “Indemnified Parties”), from and
        against any third party claim, demand, loss, damage, cost, or liability
        (including, reasonable attorneys&apos; fees) (collectively and
        individually, “Claims”) incurred by or made against the Indemnified
        Parties in connection with any Claims arising out of or relating to the
        Terms, the Service, including but without limitation in relation to: (a)
        your use, non-use or misuse of, or connection to the Service and any
        Content; (b) your violation or alleged violation of the Terms; and (c)
        your violation of any rights, including intellectual property rights, of
        a third party and otherwise as set out herein. 1TM reserves the right,
        at your expense, to assume the exclusive defense and control of any
        matter for which you are required to indemnify 1TM and you agree to
        cooperate with 1TM&apos;s defense of these Claims. You agree not to
        settle any matter without the prior written consent of 1TM. 1TM will use
        reasonable efforts to notify you of any such Claims upon becoming aware
        of it.
      </p>

      <h5 className="font-bold">Miscellaneous.</h5>
      <p>
        If there is any dispute between you and 1TM about or involving the
        Terms, the Service, you hereby agree that the dispute will be governed
        by and construed in accordance with the laws of Singapore, without
        regard to its conflict of law provisions. Except for any claim involving
        the ownership of intellectual property, all disputes arising out of or
        in connection with the Terms will be referred to and finally resolved by
        arbitration under the Arbitration Rules of the Singapore International
        Arbitration Centre. The appointing authority will be the Singapore
        International Arbitration Centre. The case will be adjudicated by a
        single arbitrator and will be administered by the Singapore
        International Arbitration Centre in accordance with its rules. The place
        of arbitration will be Singapore. The language of the arbitration will
        be English.
      </p>
      <p>
        If any portion of the Terms is deemed unlawful, void or unenforceable by
        any arbitrator or court of competent jurisdiction, the Terms as a whole
        will not be deemed unlawful, void or unenforceable, but only that
        portion of the Terms that is unlawful, void or unenforceable will be
        stricken from the Terms.
      </p>
      <p>
        You agree that the Terms are specifically enforceable by 1TM through
        injunctive relief and other equitable remedies without proof of monetary
        damages.
      </p>
      <p>
        You agree that if 1TM does not exercise or enforce any legal right or
        remedy which is contained in the Terms (or which 1TM has the benefit of
        under any applicable law), this will not be taken to be a formal waiver
        of 1TM&apos;s rights and that those rights or remedies will still be
        available to 1TM.
      </p>
      <p>
        The Terms are the entire agreement between us related to the subject
        matter in the Terms. The Terms replace and supersede any other prior or
        contemporaneous agreement, representation or discussion, oral or
        written, and may not be changed except in writing signed by us,
        regardless of whether or not the parties act under an unsigned
        “electronic” agreement or rely on such an unsigned agreement.
      </p>
    </div>
  );
};
export default Terms;
