/**
 * External dependencies
 */
import { useQuery } from 'react-query';

/**
 * Internal dependencies
 */
import { CacheKey } from 'src/constants/cache-key';
import { fetchFullFolio } from 'src/service/post';
import { logger } from 'src/lib/logger';

export function useFullFolioQuery(id = '') {
  return useQuery([CacheKey.folio, id], () => fetchFullFolio(id), {
    enabled: !!id,
    onError: (error) => {
      logger.error(error);
    },
  });
}
