import { Box, BoxProps, Flex, Stack, Text, Image } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { AreaType } from 'src/app/label/services';
import { Carousel } from 'src/components/carousel';
import { ImageBox } from 'src/components/image-box';
import { DEFAULT_EPISODE_COVER } from 'src/constants/default-image';
import { useIsTablet } from 'src/hooks/use-is-mobile';
import { ListType } from 'src/service/creation';
import { imageOptimize } from 'src/service/image';

export function ArticleGridRowView(
  props: {
    header: ReactNode;
    articleList: ListType[];
    empty?: ReactNode;
    type: AreaType;
  } & BoxProps
) {
  const { header, articleList, empty = null, type, ...boxProps } = props;
  const isTablet = useIsTablet();

  return (
    <Box pt="48px" pb="55px" {...boxProps}>
      <Stack spacing="24px" w="845px" m="auto" maxW="100%">
        <Flex justify="space-between" px={{ base: '24px', lg: 0 }}>
          {header}
        </Flex>
        {articleList?.length > 0 ? (
          <Carousel gridGap="24px" space={isTablet} eachWidth="194px">
            {articleList.map((r) => (
              <ArticleRowItem key={r.contentID} cardData={r} />
            ))}
          </Carousel>
        ) : (
          empty
        )}
      </Stack>
    </Box>
  );
}

export function ArticleRowItem({
  cardData,
  onModalChange,
}: {
  cardData: ListType;
  onModalChange?: (value: any) => void;
}) {
  function handlePage() {
    onModalChange(cardData);
  }
  return (
    <>
      <ImageBox
        ratio={194 / 129}
        src={imageOptimize(cardData.coverImgURL) || DEFAULT_EPISODE_COVER}
        role="button"
        tabIndex={0}
        onClick={() => {
          handlePage();
        }}
        onKeyDown={() => {
          handlePage();
        }}
      >
        <Box
          pos="relative"
          zIndex={1}
          alignSelf="flex-end"
          pb={3}
          w="100%"
          px="24px"
        >
          <Text textStyle="label" color="#E6E7EB" noOfLines={3} fontSize="14px">
            {cardData.title}
          </Text>
        </Box>
      </ImageBox>
    </>
  );
}

export function CarouselRowItem({
  cardData,
  onModalChange,
}: {
  cardData: ListType;
  onModalChange?: (value: any) => void;
}) {
  function handlePage() {
    onModalChange(cardData);
  }
  return (
    <Box>
      <Box borderRadius="16px" overflow="hidden">
        <ImageBox
          ratio={248 / 139.5}
          src={imageOptimize(cardData.coverImgURL) || DEFAULT_EPISODE_COVER}
          role="button"
          tabIndex={0}
          onClick={() => {
            handlePage();
          }}
          onKeyDown={() => {
            handlePage();
          }}
        />
      </Box>
      <Box pos="relative" zIndex={1} alignSelf="flex-end" w="100%" px="16px">
        <Text
          className="text-dark-900"
          noOfLines={2}
          fontSize="14px"
          fontWeight="500"
          pt="0 !important"
          pb="0 !important"
          mt="12px"
        >
          {cardData.title}
        </Text>
      </Box>
    </Box>
  );
}

export function CarouselMobileItem({
  cardData,
  onModalChange,
}: {
  cardData: ListType;
  onModalChange?: (value: any) => void;
}) {
  function handlePage() {
    onModalChange(cardData);
  }
  return (
    <div className="flex mb-6">
      <Box pos="relative" zIndex={1} w="100%">
        <Text
          className="text-dark-900"
          noOfLines={2}
          fontSize="14px"
          fontWeight="500"
          pt="0 !important"
          pb="0 !important"
        >
          {cardData.title}
        </Text>
      </Box>
      <Image
        src={imageOptimize(cardData.coverImgURL) || DEFAULT_EPISODE_COVER}
        style={{ borderRadius: '8px', width: '87px' }}
        onClick={() => {
          handlePage();
        }}
        onKeyDown={() => {
          handlePage();
        }}
      />
    </div>
  );
}
