import * as React from 'react';
import { Svg } from '../svg';

function SvgSuccessIcon({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 110 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7 98.682a50.527 50.527 0 01-1.732-1.002l-1.757 3.044 1.732 1 1.756-3.042zm22.555 10.091v-3.86c.605.021 1.213.032 1.823.032h.177v3.828h-2zm25.488-9.704l4.562 7.901 1.732-1-4.547-7.875c-.575.336-1.158.66-1.747.974zm19.115-17.776l3.497 2.02 1-1.733-3.466-2c-.333.578-.677 1.15-1.031 1.713zm7.671-24.59h4.857v-2h-4.809c-.003.67-.019 1.336-.048 2zm-5.427-25.154l7.5-4.33-1-1.732-7.444 4.298c.326.581.64 1.17.944 1.764zM80.198 11.216l2.131-3.692-1.732-1-2.13 3.69a50.72 50.72 0 011.73 1.002zm-25.943-7.27V0h-2v3.978a51.403 51.403 0 012-.032zm-25.273 6.667L25.118 3.92l-1.732 1 3.881 6.722a50.472 50.472 0 011.715-1.03zM10.406 29.07l-3.251-1.877-1 1.732 3.28 1.893c.312-.59.636-1.172.971-1.748zM3.58 54.702H.614v2h3.014c-.03-.663-.046-1.33-.05-2zm7.104 25.587l-7.13 4.116 1 1.732 7.19-4.15a50.5 50.5 0 01-1.06-1.698z"
        fill="#E1EFFC"
      />
      <circle cx={54.078} cy={54.445} r={37.5} fill="#E1EFFC" />
      <path
        d="M60.245 46.945L52.91 54.28m-7.333 7.333l-6.667-6.667M68.245 46.945L53.578 61.612l-6.667-6.667"
        stroke="#0278E4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgSuccessIcon);
export default ForwardRef;
