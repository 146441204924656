import * as React from 'react';
import { Svg } from '../svg';

function SvgNcNc({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle cx={20} cy={20} r={14.987} stroke="#4F5356" strokeWidth={3.33} />
      <path
        d="M19.534 15.948v9.06h-2.11l-3.999-4.828v4.827h-2.51v-9.06h2.109l3.999 4.828v-4.827h2.51zm5.742 9.24c-.95 0-1.803-.198-2.563-.595a4.558 4.558 0 01-1.773-1.682c-.431-.716-.647-1.527-.647-2.433 0-.906.216-1.717.647-2.434a4.465 4.465 0 011.773-1.67c.76-.405 1.614-.607 2.563-.607.828 0 1.574.146 2.239.44a4.308 4.308 0 011.656 1.268l-1.63 1.475c-.587-.707-1.299-1.06-2.136-1.06-.492 0-.932.107-1.32.323a2.29 2.29 0 00-.893.919c-.207.388-.31.836-.31 1.346 0 .509.103.962.31 1.358.216.389.513.69.893.906.388.216.828.324 1.32.324.837 0 1.549-.354 2.136-1.061l1.63 1.475c-.44.552-.992.975-1.656 1.268-.665.294-1.411.44-2.24.44z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNcNc);
export default ForwardRef;
