export enum CacheKey {
  article = 'article',
  user = 'user',
  profile = 'profile',
  featureFlag = 'feature-flag',
  reppl = 'reppl',
  creation = 'creation',
  folio = 'folio',
  comment = 'comment',
  spaces = 'spaces',
  repplPublishable = 'reppl-publishable',
  projectList = 'project-list',
  creationList = 'creation-list',
  episodeList = 'episode-list',
  unsplash = 'unsplash',
  label = 'label',
  labelList = 'label-list',
  collectionList = 'collection-list',
  creationLabel = 'creation-label',
  curatableList = 'curatable-list',
  twitterTweet = 'twitter-tweet',
  showMeReppl = 'show-me-reppl',
  showMeStudio = 'show-me-studio',
  projectBio = 'project-bio',
  projects = 'projects',
  lookupUsers = 'lookup-users',
  invitationList = 'invitation-list',
  userSubmission = 'user-submission',
  repplSubmission = 'reppl-submission',
  community = 'community-feed',
  feed = 'feed',
  tribeFeed = 'tribe-feed',
  topicList = 'topic-list',
  topicPostsList = 'topic-posts-list',
  topic = 'topic',
  topics = 'topics',
  sentInvitations = 'sent-invitations',
  space = 'space',
  teamMember = 'team-member',
  userInvitation = 'user-invitation',
  creativeAsset = 'creative-asset',
  creator = 'creator',
}
