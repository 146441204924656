const skills = [
  {
    id: 0,
    groupName: 'Ads & Marketing',
    items: [
      { id: 0, value: 'Advertisement', displayName: 'Advertisement' },
      { id: 1, value: 'Branding', displayName: 'Branding' },
      { id: 2, value: 'Copywriting', displayName: 'Copywriting' },
      { id: 3, value: 'Business Writing', displayName: 'Business Writing' },
      {
        id: 4,
        value: 'Crowdfunding Campaign',
        displayName: 'Crowdfunding Campaign',
      },
      {
        id: 5,
        value: 'Performance Marketing',
        displayName: 'Performance Marketing',
      },
      { id: 6, value: 'Digital Marketing', displayName: 'Digital Marketing' },
      { id: 7, value: 'Exhibition Design', displayName: 'Exhibition Design' },
      {
        id: 8,
        value: 'Influencer Marketing',
        displayName: 'Influencer Marketing',
      },
      { id: 9, value: 'PPL', displayName: 'PPL' },
      { id: 10, value: 'PR', displayName: 'PR' },
    ],
  },
  {
    id: 1,
    groupName: 'Architecture',
    items: [
      {
        id: 0,
        value: 'Drawing And Rendering',
        displayName: 'Drawing And Rendering',
      },
      { id: 1, value: 'Interior Design', displayName: 'Interior Design' },
      { id: 2, value: 'Landscape Design', displayName: 'Landscape Design' },
    ],
  },

  {
    id: 2,
    groupName: 'Creative Lifestyle',
    items: [
      { id: 0, value: 'Cap', displayName: 'Cap' },
      { id: 1, value: 'Custom Printing', displayName: 'Custom Printing' },
      { id: 2, value: 'Custom T-Shirt', displayName: 'Custom T-Shirt' },
      { id: 3, value: 'Fan Merch', displayName: 'Fan Merch' },
      { id: 4, value: 'Stationary', displayName: 'Stationary' },
      {
        id: 5,
        value: 'Toys And Collectible',
        displayName: 'Toys And Collectible',
      },
    ],
  },
  {
    id: 3,
    groupName: 'Design & Art',
    items: [
      { id: 0, value: 'Automotive Design', displayName: 'Automotive Design' },
      { id: 1, value: 'UX/UI', displayName: 'UX/UI' },
      { id: 2, value: 'Calligraphy', displayName: 'Calligraphy' },
      { id: 3, value: 'Cartoon', displayName: 'Cartoon' },
      { id: 4, value: 'Character Design', displayName: 'Character Design' },
      { id: 5, value: 'Comic Strip', displayName: 'Comic Strip' },
      { id: 6, value: 'Drawing', displayName: 'Drawing' },
      { id: 7, value: 'Fashion', displayName: 'Fashion' },
      { id: 8, value: 'Fine Art', displayName: 'Fine Art' },
      { id: 9, value: 'Game', displayName: 'Game' },
      {
        id: 10,
        value: 'Graffiti And Street Art',
        displayName: 'Graffiti And Street Art',
      },
      { id: 11, value: 'Graphic Design', displayName: 'Graphic Design' },
      { id: 12, value: 'Icon Design', displayName: 'Icon Design' },
      { id: 13, value: 'Illustration', displayName: 'Illustration' },
      { id: 14, value: 'Industrial Design', displayName: 'Industrial Design' },
      {
        id: 15,
        value: 'Interaction Design',
        displayName: 'Interaction Design',
      },
      { id: 16, value: 'Packaging', displayName: 'Packaging' },
      { id: 17, value: 'Painting', displayName: 'Painting' },
      { id: 18, value: 'Print Design', displayName: 'Print Design' },
      { id: 19, value: 'Product Design', displayName: 'Product Design' },
      {
        id: 20,
        value: 'Storyboard Drawing',
        displayName: 'Storyboard Drawing',
      },
      { id: 21, value: 'Street Art', displayName: 'Street Art' },
      { id: 22, value: 'Typography', displayName: 'Typography' },
      { id: 23, value: 'UX/UI', displayName: 'UX/UI' },
    ],
  },
  {
    id: 4,
    groupName: 'Fashion & Style',
    items: [
      { id: 0, value: 'Accessories', displayName: 'Accessories' },
      { id: 1, value: 'Clothing', displayName: 'Clothing' },
      { id: 2, value: 'Fashion Bag', displayName: 'Fashion Bag' },
      { id: 3, value: 'Fashion Luggage', displayName: 'Fashion Luggage' },
      { id: 4, value: 'Fashion Shoes', displayName: 'Fashion Shoes' },
      { id: 5, value: 'Watches', displayName: 'Watches' },
    ],
  },
  {
    id: 5,
    groupName: 'Game',
    items: [
      { id: 0, value: 'Board Game', displayName: 'Board Game' },
      { id: 1, value: 'Equipment Design', displayName: 'Equipment Design' },
      { id: 2, value: 'Game Art', displayName: 'Game Art' },
      { id: 3, value: 'Game Character', displayName: 'Game Character' },
      { id: 4, value: 'Game Writing', displayName: 'Game Writing' },
      { id: 5, value: 'Gaming Merch', displayName: 'Gaming Merch' },
      { id: 6, value: 'Level Design', displayName: 'Level Design' },
      { id: 7, value: 'Minecraft', displayName: 'Minecraft' },
      { id: 8, value: 'Video Game', displayName: 'Video Game' },
    ],
  },
  {
    id: 6,
    groupName: 'Manufacturing',
    items: [
      { id: 0, value: 'Build On Demand', displayName: 'Build On Demand' },
      { id: 1, value: 'Manufacturability', displayName: 'Manufacturability' },
      { id: 2, value: 'OEM/ODM', displayName: 'OEM/ODM' },
      {
        id: 3,
        value: 'On-Demand 3D Printing',
        displayName: 'On-Demand 3D Printing',
      },
      { id: 4, value: 'Print On Demand', displayName: 'Print On Demand' },
      {
        id: 5,
        value: 'Prototyping Service',
        displayName: 'Prototyping Service',
      },
    ],
  },
  {
    id: 7,
    groupName: 'Mod & Crafts',
    items: [
      { id: 0, value: '3D Printing Craft', displayName: '3D Printing Craft' },
      { id: 1, value: 'Car Mod', displayName: 'Car Mod' },
      { id: 2, value: 'Case Mod', displayName: 'Case Mod' },
      { id: 3, value: 'Crafts', displayName: 'Crafts' },
      { id: 4, value: 'DIY Hack', displayName: 'DIY Hack' },
      { id: 5, value: 'LEGO', displayName: 'LEGO' },
      { id: 6, value: 'Furniture', displayName: 'Furniture' },
      { id: 7, value: 'IKEA Hack', displayName: 'IKEA Hack' },
      { id: 8, value: 'Machinery', displayName: 'Machinery' },
      { id: 9, value: 'PC Mod', displayName: 'PC Mod' },
      {
        id: 10,
        value: 'Product Prototyping',
        displayName: 'Product Prototyping',
      },
    ],
  },
  {
    id: 8,
    groupName: 'Music & Audio',
    items: [
      { id: 0, value: 'Beatbox', displayName: 'Beatbox' },
      { id: 1, value: 'Busking', displayName: 'Busking' },
      { id: 2, value: 'Compose', displayName: 'Compose' },
      { id: 3, value: 'Electronic Music', displayName: 'Electronic Music' },
      { id: 4, value: 'Instruments', displayName: 'Instruments' },
      { id: 5, value: 'Music Producing', displayName: 'Music Producing' },
      { id: 6, value: 'Podcast', displayName: 'Podcast' },
      { id: 7, value: 'Remix', displayName: 'Remix' },
      { id: 8, value: 'Sound Design', displayName: 'Sound Design' },
      { id: 9, value: 'Studio Session', displayName: 'Studio Session' },
      { id: 10, value: 'Vocal', displayName: 'Vocal' },
    ],
  },
  {
    id: 9,
    groupName: 'Photography',
    items: [
      { id: 0, value: 'Architecture', displayName: 'Architecture' },
      { id: 1, value: 'Nature', displayName: 'Nature' },
      { id: 2, value: 'Photography', displayName: 'Photography' },
      { id: 3, value: 'Portrait', displayName: 'Portrait' },
      { id: 4, value: 'Street', displayName: 'Street' },
      { id: 5, value: 'Studio', displayName: 'Studio' },
    ],
  },
  {
    id: 10,
    groupName: 'Road Vehicles',
    items: [
      { id: 0, value: 'Bicycle', displayName: 'Bicycle' },
      { id: 1, value: 'Car Design', displayName: 'Car Design' },
      { id: 2, value: 'Car Tuning', displayName: 'Car Tuning' },
      { id: 3, value: 'Scooter', displayName: 'Scooter' },
      { id: 4, value: 'Electric Vehicle', displayName: 'Electric Vehicle' },
      { id: 5, value: 'Motor Bike', displayName: 'Motor Bike' },
    ],
  },
  {
    id: 11,
    groupName: 'Software & Programming',
    items: [
      { id: 0, value: 'AI', displayName: 'AI' },
      { id: 1, value: 'AR/VR', displayName: 'AR/VR' },
      {
        id: 2,
        value: 'Biochemical Programming',
        displayName: 'Biochemical Programming',
      },
      { id: 3, value: 'Blockchain', displayName: 'Blockchain' },
      { id: 4, value: 'E-commerce', displayName: 'E-commerce' },
      { id: 5, value: 'FinTech', displayName: 'FinTech' },
      {
        id: 6,
        value: 'Frontend Development',
        displayName: 'Frontend Development',
      },
      {
        id: 7,
        value: 'Backend Development',
        displayName: 'Backend Development',
      },
      { id: 8, value: 'IoT', displayName: 'IoT' },
      { id: 9, value: 'Machine Learning', displayName: 'Machine Learning' },
      { id: 10, value: 'Mobile App', displayName: 'Mobile App' },
      { id: 11, value: 'Programming', displayName: 'Programming' },
      { id: 12, value: 'DevOps', displayName: 'DevOps' },
      {
        id: 13,
        value: 'Software Prototyping',
        displayName: 'Software Prototyping',
      },
      { id: 14, value: 'Web Development', displayName: 'Web Development' },
      { id: 15, value: 'Wordpress', displayName: 'Wordpress' },
    ],
  },
  {
    id: 12,
    groupName: 'Storytelling',
    items: [
      { id: 0, value: 'Audio Book', displayName: 'Audio Book' },
      { id: 1, value: 'Brand Story', displayName: 'Brand Story' },
      {
        id: 2,
        value: 'Character Development',
        displayName: 'Character Development',
      },
      { id: 3, value: 'Comics', displayName: 'Comics' },
      { id: 4, value: 'Crowdfunding Pitch', displayName: 'Crowdfunding Pitch' },
      { id: 5, value: 'E-Book', displayName: 'E-Book' },
      { id: 6, value: 'Podcasts', displayName: 'Podcasts' },
      { id: 7, value: 'YouTube', displayName: 'YouTube' },
      { id: 8, value: 'Sci-Fi', displayName: 'Sci-Fi' },
      { id: 9, value: 'Storyboarding', displayName: 'Storyboarding' },
    ],
  },
  {
    id: 13,
    groupName: 'Tech & Innovation',
    items: [
      {
        id: 0,
        value: '3D Printing & Prototyping',
        displayName: '3D Printing & Prototyping',
      },
      { id: 1, value: 'Audio', displayName: 'Audio' },
      { id: 2, value: 'Coffee Maker', displayName: 'Coffee Maker' },
      { id: 3, value: 'Display', displayName: 'Display' },
      { id: 4, value: 'Electric Cars', displayName: 'Electric Cars' },
      { id: 5, value: 'Electric Scooter', displayName: 'Electric Scooter' },
      { id: 6, value: 'Gaming Devices', displayName: 'Gaming Devices' },
      {
        id: 7,
        value: 'Health And Fitness Innovation',
        displayName: 'Health And Fitness Innovation',
      },
      { id: 8, value: 'Innovative Bags', displayName: 'Innovative Bags' },
      { id: 9, value: 'Innovative Bicycle', displayName: 'Innovative Bicycle' },
      {
        id: 10,
        value: 'Innovative Motor Bike',
        displayName: 'Innovative Motor Bike',
      },
      {
        id: 11,
        value: 'Innovative Kitchenware',
        displayName: 'Innovative Kitchenware',
      },
      {
        id: 12,
        value: 'Innovative Luggage',
        displayName: 'Innovative Luggage',
      },
      { id: 13, value: 'Innovative Shoes', displayName: 'Innovative Shoes' },
      {
        id: 14,
        value: 'PC, Laptop And Tablets',
        displayName: 'PC, Laptop And Tablets',
      },
      { id: 15, value: 'Personal Care', displayName: 'Personal Care' },
      { id: 16, value: 'Personal Mobility', displayName: 'Personal Mobility' },
      { id: 17, value: 'Tech Accessories', displayName: 'Tech Accessories' },
      {
        id: 18,
        value: 'Travel And Outdoors',
        displayName: 'Travel And Outdoors',
      },
      { id: 19, value: 'VR', displayName: 'VR' },
    ],
  },
  {
    id: 14,
    groupName: 'Video & Animation',
    items: [
      { id: 0, value: 'Animation', displayName: 'Animation' },
      { id: 1, value: 'Branding Video', displayName: 'Branding Video' },
      { id: 2, value: 'Crowdfunding Video', displayName: 'Crowdfunding Video' },
      { id: 3, value: 'Documentary', displayName: 'Documentary' },
      { id: 4, value: 'Drone', displayName: 'Drone' },
      { id: 5, value: 'Film And Movie', displayName: 'Film And Movie' },
      { id: 6, value: 'Interview', displayName: 'Interview' },
      { id: 7, value: 'Motion Comics', displayName: 'Motion Comics' },
      { id: 8, value: 'Motion Graphics', displayName: 'Motion Graphics' },
      { id: 9, value: 'Music Video', displayName: 'Music Video' },
      {
        id: 10,
        value: 'Product Review Video',
        displayName: 'Product Review Video',
      },
      { id: 11, value: 'Product Video', displayName: 'Product Video' },
      { id: 12, value: 'Unboxing Video', displayName: 'Unboxing Video' },
      { id: 13, value: 'Vlog', displayName: 'Vlog' },
    ],
  },
  {
    id: 15,
    groupName: 'Writing & Publishing',
    items: [
      { id: 0, value: 'Advertorial', displayName: 'Advertorial' },
      { id: 1, value: 'Book', displayName: 'Book' },
      {
        id: 2,
        value: 'Editorial And Journalism',
        displayName: 'Editorial And Journalism',
      },
      { id: 3, value: 'Essay And Blog', displayName: 'Essay And Blog' },
      { id: 4, value: 'Fiction', displayName: 'Fiction' },
      { id: 5, value: 'Magazine', displayName: 'Magazine' },
      { id: 6, value: 'Non-Fiction', displayName: 'Non-Fiction' },
      {
        id: 7,
        value: 'Product Review Blog',
        displayName: 'Product Review Blog',
      },
      { id: 8, value: 'Translation', displayName: 'Translation' },
      { id: 9, value: 'Unboxing Blog', displayName: 'Unboxing Blog' },
    ],
  },
];

export default skills;
