import * as React from 'react';
import { Svg } from '../svg';

function SvgVideo({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M0 .993A1 1 0 01.992 0h18.016c.548 0 .992.445.992.993v16.014a1 1 0 01-.992.993H.992A.993.993 0 010 17.007V.993zm8.622 4.422A.4.4 0 008 5.747v6.506a.4.4 0 00.622.332l4.879-3.252a.4.4 0 000-.666l-4.88-3.252h.001z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgVideo);
export default ForwardRef;
