import React, { useRef, useState } from 'react';
import SvgCheck from 'src/components/icons/check';
import SvgSearch from 'src/components/icons/search';

interface Item {
  id: number;
  value?: string;
  displayName: string;
}

interface OneLayerProps {
  className?: string;
  list: Array<Item>;
  selectList: Array<number>;
  groupId?: number;
  action?: Function;
  isSearchOn?: boolean;
}
const OneLayer = ({
  className = '',
  list,
  selectList,
  action,
  groupId = null,
  isSearchOn,
}: OneLayerProps) => {
  const inputKeyword = useRef<any>();
  const [searchList, setSearchList] = useState(list);

  function searchHandle() {
    const newList = [];
    list.forEach((v) => {
      if (
        v.value
          .toUpperCase()
          .indexOf(inputKeyword.current.value.toUpperCase()) > -1
      ) {
        newList.push(v);
      }
    });
    setSearchList(newList);
  }

  function getSelectList(select) {
    let isSelect = false;
    selectList.forEach((l) => {
      if (l === select.id) {
        isSelect = true;
      }
    });
    return isSelect;
  }

  return (
    <div style={{ width: '255px' }} className="mt-6 mx-6">
      {isSearchOn && (
        <div className="flex mb-2">
          <div style={{ width: '100%' }} className="relative">
            <span
              aria-hidden="true"
              className="text-gray-300 cursor-pointer pt-0.5 absolute left-2 top-0.5"
            >
              <SvgSearch className="dark:text-gray-300 text-2xl" />
            </span>
            <input
              id="search-input"
              type="text"
              className="text-sm border border-gray-300 w-full pl-8 h-8 rounded-2xl"
              ref={inputKeyword}
              placeholder="search"
              onKeyUp={() => searchHandle()}
            />
          </div>
          <div
            role="button"
            tabIndex={0}
            className="my-auto ml-4 w-max"
            onClick={() => action(-1)}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                action(-1);
              }
            }}
          >
            <span className="float-right font-medium text-sm text-gray-500">
              Reset
            </span>
          </div>
        </div>
      )}
      {/* <ul className={`${className} cursor-pointer list-layer mt-2 z-50`}>
        {includeAll && (
          <li
            aria-hidden="true"
            className="cursor-pointer"
            onClick={() => {
              action(-1);
            }}
          >
            <span
              aria-hidden="true"
              className="text-gray-300 cursor-pointer pt-0.5 absolute left-2 top-0.5"
            >
              <SvgSearch className="dark:text-gray-300 text-2xl" />
            </span>

            <span className="float-right reset">Reset</span>
          </li>
        )}
      </ul> */}
      <ul
        className={`${className} filter-block-scrollbar cursor-pointer list-layer max-h-80 overflow-y-auto`}
      >
        {searchList?.map((s) => {
          return (
            <li
              key={s.id}
              className={`layer-icon-hover rounded p-0 ${
                getSelectList(s) ? 'text-gray-500' : 'hover:text-white'
              }`}
              aria-hidden="true"
              onClick={() => {
                action(s.id, groupId);
              }}
            >
              <span className="w-4 inline-block">
                <SvgCheck
                  className={`text-base ${
                    getSelectList(s) ? 'inline text-blue-500' : 'hidden'
                  }`}
                />
              </span>
              <span
                className={`text-sm font-medium ml-1 ${
                  getSelectList(s) ? 'text-blue-500' : ''
                }`}
              >
                {s.displayName}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default React.memo(OneLayer);
