import { useDisclosure } from '@chakra-ui/react';
import styles from './alpha-banner-styles.module.scss';
import AlphaExplainerModal from './AlphaExplainerModal';

export default function AlphaBanner() {
  const alphaExplainerModal = useDisclosure();
  return (
    <>
      <div className={styles.main}>
        Welcome to the Alpha release of 1TM!&nbsp;
        <button onClick={alphaExplainerModal.onOpen} type="button">
          Learn more
        </button>
      </div>
      <AlphaExplainerModal
        closeModal={alphaExplainerModal.onClose}
        isOpen={alphaExplainerModal.isOpen}
      />
    </>
  );
}
