import chakraTheme from '@chakra-ui/theme';

const colors = {
  ...chakraTheme.colors,

  white: '#FFFFFF',
  black: '#191919',

  gray: {
    50: '#FAFBFB',
    100: '#F3F3F4',
    200: '#E6E8E9',
    300: '#D3D6D8',
    400: '#BBC0C5',
    500: '#9B9FA4',
    600: '#74787D',
    700: '#4F5356',
    800: '#36383A',
    900: '#242526',
  },

  $mode: {
    bg: 'var(--modeBg)',
    50: 'var(--mode50)',
    100: 'var(--mode100)',
    200: 'var(--mode200)',
    300: 'var(--mode300)',
    400: 'var(--mode400)',
    500: 'var(--mode500)',
    600: 'var(--mode600)',
    700: 'var(--mode700)',
    800: 'var(--mode800)',
    900: 'var(--mode900)',
  },

  blue: {
    50: '#E1EFFC',
    100: '#B3D7F7',
    200: '#81BCF2',
    300: '#4EA1EC',
    400: '#288CE8',
    500: '#0278E4',
    600: '#0270E1',
    700: '#0165DD',
    800: '#015BD9',
    900: '#0148D1',
  },

  red: {
    50: '#FDEBEB',
    100: '#F9CDCD',
    200: '#F5ABAB',
    300: '#F18989',
    400: '#EE7070',
    500: '#EB5757',
    600: '#E94F4F',
    700: '#E54646',
    800: '#E23C3C',
    900: '#DD2C2C',
  },

  green: {
    50: '#E9F8F3',
    100: '#C8EDE0',
    200: '#A3E1CC',
    300: '#7ED4B8',
    400: '#63CBA8',
    500: '#47C299',
    600: '#40BC91',
    700: '#37B486',
    800: '#2FAC7C',
    900: '#209F6B',
  },

  yellow: {
    50: '#FFFAEB',
    100: '#FFF2CD',
    200: '#FFEAAB',
    300: '#FFE289',
    400: '#FFDB70',
    500: '#FFD557',
    600: '#FFD04F',
    700: '#FFCA46',
    800: '#FFC43C',
    900: '#FFBA2C',
  },

  warning: '#FF6633',
  /**
   * @deprecated use mode instead
   */
  bgLight: '#F3F3F4',
  /**
   * @deprecated use mode instead
   */
  bgDark: '#1B1C1D',
  success: '#2BC3A8',
  error: '#EB5757',
  golden: '#E4B202',
};

export default colors;
