import * as React from 'react';
import { Svg } from '../svg';

function SvgNcNe({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.333 20.5c0 1.834-.37 3.582-1.04 5.173l-5.877-3.393 2.24-2.198-3.306-4.504H14.807l-5.431-3.136A13.312 13.312 0 0120 7.167c7.364 0 13.333 5.97 13.333 13.333zM7.707 15.328a13.292 13.292 0 00-1.04 5.172c0 7.364 5.97 13.333 13.333 13.333 4.336 0 8.188-2.07 10.623-5.274l-6.678-3.856L20 28.573l-8.657-8.491 1.369-1.865-5.005-2.89zm28.96 5.172c0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.667-7.462-16.667-16.667 0-9.205 7.462-16.667 16.667-16.667 9.205 0 16.667 7.462 16.667 16.667zm-13.165 1.311L20 25.314l-3.503-3.503 1.178-1.178L20 22.956l2.324-2.325 1.178 1.179z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNcNe);
export default ForwardRef;
