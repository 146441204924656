import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useState } from 'react';

import { WhatIsAgoraBody, WhatIsAgoraFooter } from './what-is-agora';
import { FeedAreaBody, FeedAreaFooter } from './feed-area';
import {
  CheckFeaturedPostBody,
  CheckFeaturedPostFooter,
} from './check-featured-post';
import { SidePanelsBody, SidePanelsFooter } from './side-panels';

const WhatIsAgora = ({ modal }: { modal: any }) => {
  const [tutorialModalContent, setTutorialModalContent] = useState(
    'whatIsAgora'
  );

  return (
    <>
      <Modal
        isOpen={modal.isOpen}
        onClose={() => {
          modal.onClose();
          modal.openChecklistModal();
          setTutorialModalContent('whatIsAgora');
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalCloseButton color="gray.500" />
          <ModalBody px="60px" pt="40px" pb="24px" bg="white">
            {tutorialModalContent === 'whatIsAgora' && <WhatIsAgoraBody />}
            {tutorialModalContent === 'feedArea' && <FeedAreaBody />}
            {tutorialModalContent === 'checkFeaturedPost' && (
              <CheckFeaturedPostBody />
            )}
            {tutorialModalContent === 'sidePanels' && <SidePanelsBody />}
          </ModalBody>
          <ModalFooter
            flexDirection="column"
            alignItems="center"
            bg="gray.100"
            px="60px"
            py="25px"
          >
            {tutorialModalContent === 'whatIsAgora' && (
              <WhatIsAgoraFooter setNextModal={setTutorialModalContent} />
            )}
            {tutorialModalContent === 'feedArea' && (
              <FeedAreaFooter setNextModal={setTutorialModalContent} />
            )}
            {tutorialModalContent === 'checkFeaturedPost' && (
              <CheckFeaturedPostFooter setNextModal={setTutorialModalContent} />
            )}
            {tutorialModalContent === 'sidePanels' && (
              <SidePanelsFooter
                completed={modal.completed}
                closeModal={() => {
                  modal.onClose();
                  modal.openChecklistModal();
                  setTutorialModalContent('whatIsAgora');
                }}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WhatIsAgora;
