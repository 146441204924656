import * as React from 'react';
import { Svg } from '../svg';

function SvgPlusSquare({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16 3.334H2.667v21.333h18.666v-16H16V3.334zM0 1.99C0 1.259.596.667 1.332.667h16.001L24 7.334V25.99a1.334 1.334 0 01-1.324 1.343H1.324A1.334 1.334 0 010 26.01V1.99zm10.667 10.677v-4h2.666v4h4v2.667h-4v4h-2.666v-4h-4v-2.667h4z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPlusSquare);
export default ForwardRef;
