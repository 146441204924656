import { isBrowser } from 'src/constants/env';
import type { CaptureContext } from '@sentry/types';

// eslint-disable-next-line import/no-mutable-exports
export let logger: {
  error: (exception: any, captionContext?: CaptureContext) => {};
};

if (isBrowser()) {
  logger = require('./logger.client').logger;
} else {
  logger = require('./logger.server').logger;
}
