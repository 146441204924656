import { useMutation, useQueryClient } from 'react-query';

import { axios } from 'src/lib/axios';
import { RestResponse } from 'src/types';

import { useUploadImage } from 'src/hooks';
import { CacheKey } from 'src/constants/cache-key';
import { Article, PublishContent } from 'src/app/article/types';
import { standaloneToast } from 'src/components/toast';
import { useContext } from 'react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import router from 'next/router';
import { imageOptimize } from 'src/service/image';

type StudioRequest = PublishContent & { contentID?: string; topicID?: string };
type CreateStudioResponse = RestResponse<Article>;

export function useStudioMutation() {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext) as UserContextType;

  return useMutation(
    (values: StudioRequest) =>
      axios
        .post<CreateStudioResponse>('/post/publish', values)
        .then((res) => res.data),
    {
      onSuccess: (response, values) => {
        if (response.code === 1000) {
          const { contentID } = values;
          const updater = (prevCache) => ({
            ...prevCache,
            ...values,
            author: {
              userID: user.id,
            },
          });
          queryClient.setQueryData([CacheKey.creation, contentID], updater);
        }
      },
    }
  );
}

export function useGeneralMutation() {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext) as UserContextType;

  return useMutation(
    (values: StudioRequest) =>
      axios
        .post<CreateStudioResponse>('/general-post', values)
        .then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(CacheKey.community);
        queryClient.invalidateQueries(CacheKey.creationList);
        queryClient.invalidateQueries(CacheKey.creation);
      },
      onSuccess: (response, values) => {
        if (response.code === 1000) {
          const { contentID } = values;
          const updater = (prevCache) => ({
            ...prevCache,
            ...values,
            author: {
              userID: user.id,
            },
          });
          queryClient.setQueryData([CacheKey.creation, contentID], updater);
        }
      },
    }
  );
}

export function useGeneralUpdateMutation() {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext) as UserContextType;

  return useMutation(
    (values: StudioRequest) =>
      axios
        .put<CreateStudioResponse>(`/general-post/${values.contentID}`, values)
        .then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(CacheKey.community);
        queryClient.invalidateQueries(CacheKey.creationList);
        queryClient.invalidateQueries(CacheKey.creation);
      },
      onSuccess: (response, values) => {
        if (response.code === 1000) {
          const { contentID } = values;
          const updater = (prevCache) => ({
            ...prevCache,
            ...values,
            author: {
              userID: user.id,
            },
          });
          queryClient.setQueryData([CacheKey.creation, contentID], updater);
        }
      },
    }
  );
}

export function useStudioDeleteMutation(holdToast?: boolean) {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => axios.post(`/post/${id}/unpublish`).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(CacheKey.community);
        queryClient.invalidateQueries(CacheKey.creationList);
        queryClient.invalidateQueries(CacheKey.creation);
      },
      onSuccess: () => {
        if (!holdToast) {
          standaloneToast({
            title: 'Delete success',
          });
        }
      },
    }
  );
}

export function useGeneralDeleteMutation(holdToast?: boolean) {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => axios.delete(`/general-post/${id}`).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(CacheKey.community);
        queryClient.invalidateQueries(CacheKey.creationList);
        queryClient.invalidateQueries(CacheKey.creation);
      },
      onSuccess: () => {
        if (!holdToast) {
          standaloneToast({
            title: 'Delete success',
          });
        }
      },
    }
  );
}

export function useUnpublishEpisodeMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => axios.post(`/post/${id}/unpublish`).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(CacheKey.creationList);
        queryClient.invalidateQueries(CacheKey.creation);
      },
    }
  );
}

export function useUnlistEpisodeMutation() {
  return useMutation((data: any) =>
    axios
      .post(`episode/${data.episodeID}/unlist`, {
        repplID: data.repplID,
        note: data.note,
      })
      .then((res) => res.data)
  );
}

export function useAddEpisodeToDraftMutation() {
  return useMutation((data: any) =>
    axios.post(`/post/save`, data).then((res) => res.data)
  );
}

export function useStudioDeleteAndBackMutation(holdToast?: boolean) {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => axios.post(`/post/${id}/unpublish`).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(CacheKey.creationList);
        queryClient.invalidateQueries(CacheKey.creation);
      },
      onSuccess: () => {
        if (!holdToast) {
          standaloneToast({
            title: 'Delete success',
          });
        }
      },
      onError: () => {
        router.back();
      },
    }
  );
}

export function useRepplDeleteMutation(holdToast?: boolean) {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) =>
      axios.delete('/reppl', { data: { id } }).then((res) => res.data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(CacheKey.creationList);
        queryClient.invalidateQueries(CacheKey.creation);
      },
      onSuccess: () => {
        if (!holdToast) {
          standaloneToast({
            title: 'Delete success',
          });
        }
      },
    }
  );
}

export function useUploadCoverMutation(studioId: string) {
  const queryClient = useQueryClient();

  return useUploadImage(
    { areaType: 'STUDIO', imgType: 'studio_cover' },
    (response) => {
      const updater = (prevCache) => ({
        ...prevCache,
        coverImgURL: imageOptimize(response.imgURL),
      });

      queryClient.setQueryData([CacheKey.creation, studioId], updater);
    }
  );
}
