import { Box, Flex, Img, Text, Circle } from '@chakra-ui/react';
import { Avatar } from 'components/avatar';
import { imageOptimize } from 'src/service/image';

export interface NotificationStats {
  spaces: { title: string; avatarImgURL: string }[];
  unread_count: number;
}

export function NotificationDrawTabs({
  notificationStats,
  setSelectedTab,
  selectedTab,
}: {
  notificationStats: NotificationStats;
  setSelectedTab: (val: string) => void;
  selectedTab: string;
}) {
  const showOtherInvitations = () => {
    const total: number = notificationStats?.unread_count;
    if (total === 1) return ``;
    if (total > 1) return `+${Number(total - 1)} others`;
    return '';
  };
  return (
    <Box h="full">
      <Flex
        w="full"
        h="full"
        alignItems="center"
        justifyContent="space-between"
      >
        {selectedTab === 'notifications' && (
          <Flex
            onClick={() => setSelectedTab('invitations')}
            cursor="pointer"
            alignItems="center"
          >
            <Circle
              mr="14px"
              bg={notificationStats?.spaces.length > 0 ? '#F63' : ''}
              size="6px"
            />
            <Flex position="relative">
              {notificationStats?.spaces.slice(0, 2).map((i, key) => {
                const isFirstItem = key === 0;
                const isOneItem = notificationStats?.spaces.length === 1;
                const visible =
                  isFirstItem || i.avatarImgURL ? 'visible' : 'hidden';
                return (
                  <Box
                    pt={isOneItem ? '5px' : ''}
                    ml={isFirstItem ? '' : '-22px'}
                    mt={isFirstItem ? '' : '8px'}
                    visibility={visible}
                    color="white"
                  >
                    <Avatar
                      name=""
                      data-testid="user avatar"
                      bg="$mode.400"
                      width={32}
                      height={32}
                      src={imageOptimize({
                        url: i.avatarImgURL || '',
                        imgContainer: 'avatar',
                      })}
                      zIndex={100}
                      author={i}
                    />
                  </Box>
                );
              })}
              {notificationStats?.spaces.length === 1 && (
                <Box ml="-22px" mt="8px" w="32px" h="32px" />
              )}
            </Flex>
            <Box mt="6px" ml="14px">
              <Text color="gray.700" fontSize="16px" fontWeight="600">
                Invitation requests
              </Text>
              <Text mt="4px" color="gray.500" fontSize="12px" fontWeight="500">
                {notificationStats?.spaces[0]?.title || ''}
                {showOtherInvitations()}
              </Text>
            </Box>
          </Flex>
        )}
        {selectedTab === 'invitations' && (
          <Flex
            onClick={() => setSelectedTab('notifications')}
            cursor="pointer"
          >
            <Box mr="12px">
              <Img src="/images/notification/arrow-right.svg" />
            </Box>

            <Box ml="14px">
              <Text color="gray.700" fontSize="16px" fontWeight="600">
                Notifications
              </Text>
            </Box>
          </Flex>
        )}
        {selectedTab === 'notifications' && (
          <Img
            cursor="pointer"
            onClick={() => setSelectedTab('invitations')}
            src="/images/notification/arrow-left.svg"
          />
        )}
      </Flex>
    </Box>
  );
}
