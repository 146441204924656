import { Grid, GridProps } from '@chakra-ui/react';
import { useIsMobile, useIsTablet } from 'src/hooks/use-is-mobile';

export function CardGrid(props: GridProps) {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  return (
    <Grid
      role="list"
      w="100%"
      templateColumns={`repeat(auto-fill, minmax(${
        isMobile ? '100%' : '300px'
      }, 1fr))`}
      gap={isTablet ? 4 : 5}
      {...props}
    />
  );
}
