import * as React from 'react';
import { Svg } from '../svg';

function SvgCc0Type({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4.526C9.664 4.526 4.527 9.663 4.527 16c0 6.337 5.137 11.473 11.473 11.473 6.337 0 11.473-5.136 11.473-11.473 0-6.336-5.136-11.473-11.473-11.473zM2.667 16C2.667 8.636 8.637 2.666 16 2.666c7.364 0 13.334 5.97 13.334 13.333 0 7.364-5.97 13.334-13.334 13.334S2.667 23.363 2.667 15.999z"
        fill="currentColor"
      />
      <path
        d="M12.348 17.495V14.57c0-.791.093-1.49.28-2.094.187-.61.45-1.125.789-1.543A3.35 3.35 0 0114.688 10c.492-.215 1.052-.322 1.68-.322.62 0 1.18.107 1.678.322.492.21.91.52 1.255.933.345.418.61.932.797 1.543.18.605.271 1.303.271 2.094v2.925c0 .792-.09 1.492-.271 2.103-.187.605-.455 1.11-.806 1.518a3.32 3.32 0 01-1.263.95c-.497.208-1.057.313-1.679.313-.627 0-1.187-.105-1.679-.314a3.25 3.25 0 01-1.254-.95c-.345-.406-.608-.912-.789-1.517-.187-.61-.28-1.311-.28-2.103zm5.986-.72v-.416-2.154c0-.508-.045-.95-.135-1.322-.09-.379-.23-.687-.416-.924a1.518 1.518 0 00-.593-.475 1.977 1.977 0 00-.823-.161c-.288 0-.545.045-.771.135-.232.09-.427.226-.585.407a2.05 2.05 0 00-.416.789 4.611 4.611 0 00-.186 1.145l3.925 2.976zm-3.942 1.06c0 .52.048.972.144 1.356.09.384.229.695.415.933.164.203.362.359.594.466.232.102.5.153.805.153.311 0 .585-.054.823-.162.231-.107.426-.268.585-.483.158-.198.285-.446.381-.746.09-.3.15-.644.178-1.034l-3.925-2.96v2.476z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCc0Type);
export default ForwardRef;
