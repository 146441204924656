import * as React from 'react';
import { Svg } from '../svg';

function SvgModalSuccessIcon({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.606 99.22a50.364 50.364 0 01-1.731-1l-1.758 3.043 1.732 1 1.757-3.042zm22.555 10.092v-3.86c.606.022 1.213.032 1.824.032h.176v3.828h-2zm25.489-9.704l4.561 7.901 1.732-1-4.546-7.875c-.576.336-1.158.66-1.748.974zm19.114-17.776l3.498 2.02 1-1.733-3.466-2c-.333.578-.677 1.15-1.032 1.713zm7.671-24.59h4.858v-2h-4.809c-.003.67-.019 1.336-.049 2zm-5.427-25.154l7.501-4.33-1-1.732-7.444 4.298c.325.581.64 1.17.943 1.764zM80.104 11.755l2.132-3.692-1.732-1-2.13 3.69c.583.323 1.16.657 1.73 1.002zm-25.943-7.27V.539h-2v3.978a51.403 51.403 0 012-.032zM28.89 11.152l-3.864-6.693-1.732 1 3.88 6.722a50.475 50.475 0 011.716-1.03zM10.313 29.61l-3.251-1.877-1 1.732 3.279 1.893c.313-.59.637-1.172.972-1.748zM3.485 55.242H.52v2h3.014a51.36 51.36 0 01-.048-2zm7.104 25.586l-7.13 4.116 1 1.733 7.19-4.152a50.503 50.503 0 01-1.06-1.697z"
        fill="#E1EFFC"
      />
      <circle cx={53.985} cy={54.984} r={37.5} fill="#E1EFFC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.092 46.544L52.985 60.65l-5.573-5.56-1.88 1.88 7.453 7.453 16-16-1.893-1.88zm-5.654 1.88l-1.88-1.88-8.453 8.453 1.88 1.88 8.453-8.453zm-16 16l-7.453-7.453 1.893-1.88 7.44 7.453-1.88 1.88z"
        fill="#0278E4"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgModalSuccessIcon);
export default ForwardRef;
