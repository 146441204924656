export const FeedAreaBody = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Meet Agora
      </h1>
      <img
        src="/images/assets/tutorial/feed-area.png"
        alt="feed area"
        className="w-full"
      />
    </>
  );
};

export const FeedAreaFooter = ({ setNextModal }: { setNextModal?: any }) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">Agora Feed</h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Here you will find updates from across all of 1TM, including trending
        folios from projects and creators you follow.
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('checkFeaturedPost');
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Next
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        1 / 3
      </h6>
    </>
  );
};
