import * as cookie from 'cookie';

import { isBrowser } from 'src/constants/env';

export function saveCookie(
  name: string,
  value: string,
  opts?: cookie.CookieSerializeOptions
) {
  if (isBrowser()) {
    document.cookie = cookie.serialize(name, value, {
      path: '/',
      ...opts,
    });
  } else {
    throw new Error('Not implement yet');
  }
}

export function getCookie(name: string) {
  if (isBrowser()) {
    return cookie.parse(document.cookie)[name];
  }
}

export function destroyCookie(
  name: string,
  opts?: cookie.CookieSerializeOptions
) {
  if (isBrowser()) {
    document.cookie = cookie.serialize(name, '', {
      path: '/',
      ...opts,
    });
  } else {
    throw new Error('Not implement yet');
  }
}
