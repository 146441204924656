import * as React from 'react';
import { Svg } from '../svg';

function SvgNote({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M11.333.333c.368 0 .667.299.667.667v2.505l-1.333 1.333V1.667H1.333v10.666h9.334v-1.838L12 9.16V13a.667.667 0 01-.667.667H.667A.667.667 0 010 13V1C0 .632.299.333.667.333h10.666zm1.186 4.539l.942.943L8.276 11l-.944-.001.001-.942 5.186-5.185zM6.667 7v1.333H3.333V7h3.334zm2-2.667v1.334H3.333V4.333h5.334z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNote);
export default ForwardRef;
