export default {
  layer0: {
    bg: 'transparent',
  },
  layer2: {
    bg: 'bgLight',
    '.chakra-ui-dark &': { bg: 'bgDark' },
  },
  layer3: {
    bg: 'white',
    '.chakra-ui-dark &': { bg: '$mode.50', borderWidth: 0 },
  },
  layer4: {
    bg: 'white',
    '.chakra-ui-dark &': { bg: '#1a1a1c', borderWidth: 0 },
  },
  layer5: {
    color: 'white',
    '.chakra-ui-dark &': { color: 'white !important' },
  },
};
