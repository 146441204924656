import * as React from 'react';
import { Svg } from '../svg';

function SvgTwitterV3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M0 10C0 4.476 4.476 0 10 0s10 4.476 10 10-4.476 10-10 10c-5.524-.012-10-4.487-10-10z"
        fill="currentColor"
      />
      <path
        d="M8.01 14.38c4.062 0 6.283-3.367 6.283-6.287 0-.095-.002-.19-.006-.285a4.49 4.49 0 001.102-1.144 4.4 4.4 0 01-1.269.347c.456-.273.806-.706.972-1.222a4.428 4.428 0 01-1.403.537A2.21 2.21 0 009.925 8.34a6.27 6.27 0 01-4.552-2.309 2.208 2.208 0 00.684 2.95 2.192 2.192 0 01-1-.277v.029a2.21 2.21 0 001.771 2.165 2.208 2.208 0 01-.998.038 2.21 2.21 0 002.064 1.535 4.43 4.43 0 01-3.27.915 6.25 6.25 0 003.385.992"
        fill="#242526"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTwitterV3);
export default ForwardRef;
