import * as React from 'react';
import { Svg } from '../svg';

function SvgBoxStart({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <rect x="0.5" width="60" height="60" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_1440_27219" transform="scale(0.0125)" />
        </pattern>
        <image
          id="image0_1440_27219"
          width="80"
          height="80"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAABzklEQVR4Ae2cwUrDQBBA+5f1B/RX0pz1C/yFIqgg2FSw0FO9ehPEQy+9isiWPcyWiKbJuhOmu6+w7CHpdPZl5jE9tJMJLwhAAAIQGETgrGoep7PGZbXqxfMgCLE3ZwlPikEbYoBXL0P1Pbx+uVNeoYvkTFXzFFtcne8L8GZLV83fswNY33y4qUBMXYk/4V2vP7MD6M+kAvE3eLkCTA7xL3g5A0wGsQte7gD/DfEYvBIARkPsA68UgFEQw1wkwyV7mDg8m85Zz18EYPdX1N4A33bfjnVgIIUFwMjCAGAkOOlCAALw4COpijF3KpAKpAJPeiwy18K3m607v1y1pnxJcsjuY9xttuoPR3IyMwemgCeHurhaAVBgxOxFAvRtl6IKPbz7lwJbeMwZLsVnSWeYcWCKQ40ZA4AM0gzS6qOGZkvTwrQwLUwLazrGemwciANxIA607inN/HAgDsSBOFDTMdZj40AciANxoHVPaeaHA3EgDsSBmo6xHhsH4kAciAOte0ozPxyIA3EgDtR0jPXYOBAH4kAcaN1TmvnhQByIA3GgpmOsx8aBOBAH4kDrntLMDweO7UAhzt7+K6jev9YEXBuc8DgKkBsgAAEIFERgDzs2oFjVBNNdAAAAAElFTkSuQmCC"
        />
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgBoxStart);
export default ForwardRef;
