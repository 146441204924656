import {
  Box,
  Button,
  Heading,
  Stack,
  StackProps,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  // Image,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { Author } from 'src/app/article/types';
import { useFollowUserMutation } from 'src/app/profile/use-follow-user.mutation';
import useProfileQuery from 'src/app/profile/use-profile-query';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { useFollow, useRepplQuery } from 'src/app/reppl/hooks';
import { Avatar } from 'src/components/avatar';
import { NextChakraLinkBox } from 'src/components/next-chakra-link';
import { CacheKey } from 'src/constants/cache-key';
import { formatNumber } from 'src/lib/formatNumber';
import { logger } from 'src/lib/logger';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';
import styles from 'src/app/article/Post_detail.module.scss';

export function Publisher({
  author,
  isMe = false,
  ...props
}: StackProps & { contentID?: string; isMe?: boolean; author?: Author }) {
  const { mutateAsync: followUser } = useFollowUserMutation([
    CacheKey.creation,
  ]);
  const { data: profile, refetch } = useProfileQuery(author?.username);
  let followers: number | null = profile?.numOfFollowers;
  let creations: number | null = null;
  const isFollowing = !!(author?.isFollowed || profile?.isFollowed);

  if (author && 'count' in author) {
    creations = author.count.episode + author.count.studio;
    followers = followers || author.count.follower;
  }

  return (
    <PublishInfo
      aria-label="publisher-info"
      avatar={author?.profileImgURL}
      followerCount={followers}
      creationCount={creations}
      href={`/${author?.username}`}
      username={author?.username}
      about={author?.about}
      name={getProfileFullNameOrUsername(profile || author)}
      isFollowing={isFollowing}
      showFollowButton={!isMe}
      onFollowClick={async () => {
        try {
          await followUser({
            targetID: author?.userID,
            group: 'USER',
            follow: !author?.isFollowed,
          });
          refetch();
        } catch (err) {
          logger.error(err);
        }
      }}
      {...props}
      type="Publisher"
    />
  );
}

export function PublishIn({ reppl }: { reppl: any }) {
  const rid = reppl;
  const { data: repplData } = useRepplQuery(rid);
  const { mutateAsync: follow } = useFollow(rid);
  const { user } = useContext(UserContext) as UserContextType;

  if (!repplData?.reppl) {
    return null;
  }

  const isMe = repplData.creator.userID === user?.id;

  return (
    <PublishInfo
      aria-label="publish-in-info"
      avatar={repplData.reppl.avatarImgURL}
      about={repplData.reppl.summary}
      heading="PUBLISHED IN SPACE"
      followerCount={repplData.reppl.followerCount}
      creationCount={repplData.reppl.episodeCount}
      name={repplData.reppl.title}
      href={`/space/${repplData.reppl.id}`}
      isFollowing={repplData.reppl.isFollowed}
      onFollowClick={() => {
        follow({
          targetID: repplData.reppl.id,
          group: 'REPPL',
          follow: !repplData.reppl.isFollowed,
        });
      }}
      showFollowButton={!isMe}
      type="PublishIn"
    />
  );
}

interface Props {
  followerCount?: number;
  creationCount?: number;
  href?: string;
  heading?: string;
  about?: string;
  avatar?: string;
  username?: string;
  name?: string;
  isFollowing?: boolean;
  onFollowClick?: () => void;
  type?: string;
}

export function PublishInfo({
  heading = 'PUBLISHER',
  href,
  followerCount,
  creationCount,
  about,
  avatar,
  name,
  username,
  isFollowing,
  showFollowButton = true,
  onFollowClick,
  type,
  ...props
}: StackProps & Props & { showFollowButton?: boolean }) {
  const { t } = useTranslation();

  return (
    <>
      {type === 'Publisher' && (
        <Stack as="section" role="region" m="auto" spacing={0} {...props}>
          <Heading
            textStyle="h6"
            color="$mode.400"
            pb="24px"
            textTransform="uppercase"
            textAlign={{
              base: 'left',
              md: 'left',
            }}
          >
            {t(heading)}
          </Heading>

          <Stack direction="column" spacing={3} alignItems="center">
            <NextChakraLinkBox
              textAlign="center"
              href={href}
              _hover={{ cursor: 'pointer' }}
            >
              <Avatar
                src={avatar}
                name={username || name}
                width={56}
                height={56}
                wrapperProps={{
                  m: 'auto',
                }}
              />
              <Text textStyle="h5" pt="16px" textAlign="center" noOfLines={2}>
                {name}
              </Text>
            </NextChakraLinkBox>
            <StatGroup>
              {!Number.isNaN(followerCount) && (
                <Stat whiteSpace="nowrap">
                  <StatNumber textStyle="body0" display="inline-block" mr={1}>
                    {formatNumber({ number: followerCount })}
                  </StatNumber>
                  <StatLabel textStyle="body0" display="inline-block">
                    {followerCount > 1 ? t('Followers') : t('Follower')}
                  </StatLabel>
                </Stat>
              )}
              {!Number.isNaN(followerCount) && !Number.isNaN(creationCount) && (
                <Box as="span" mx="12px">
                  |
                </Box>
              )}
              {!Number.isNaN(creationCount) && (
                <Stat whiteSpace="nowrap">
                  <StatNumber textStyle="body0" display="inline-block" mr={1}>
                    {formatNumber({ number: creationCount })}
                  </StatNumber>
                  <StatLabel textStyle="body0" display="inline-block">
                    {creationCount > 1 ? t('Creations') : t('Creation')}
                  </StatLabel>
                </Stat>
              )}
            </StatGroup>
            <Text textStyle="body3" textAlign="center" color="$mode.500">
              {about}
            </Text>
            {showFollowButton && (
              <Stack direction={{ base: 'column', md: 'row' }} spacing={3}>
                <Button
                  onClick={onFollowClick}
                  size="sm"
                  borderRadius="2px"
                  fontWeight="bold"
                >
                  {isFollowing ? t('Following') : t('Follow')}
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
      {type === 'PublishIn' && (
        <div className={cn(styles.block20, styles.block20_layout)}>
          <div
            className={cn(
              styles.small_text_body,
              styles.small_text_body_layout1
            )}
          >
            {heading}
          </div>

          <NextChakraLinkBox
            textAlign="center"
            href={href}
            _hover={{ cursor: 'pointer' }}
            mb={0}
            className={cn(styles.flex2, styles.flex2_layout)}
          >
            <div className="flex items-center">
              <Avatar
                src={avatar}
                name={username || name}
                width={40}
                height={40}
                wrapperProps={{
                  m: 'auto',
                }}
              />
              <span
                className={cn(
                  styles.text_body1,
                  styles.text_body1_layout,
                  'pl-2'
                )}
              >
                {name}
              </span>
            </div>
          </NextChakraLinkBox>

          <div className={cn(styles.flex3, styles.flex3_layout)}>
            {!Number.isNaN(followerCount) && (
              <div className={cn(styles.text_body2, styles.text_body2_layout)}>
                {formatNumber({ number: followerCount })}{' '}
                {followerCount > 1 ? t('Followers') : t('Follower')}
              </div>
            )}
            {!Number.isNaN(followerCount) && !Number.isNaN(creationCount) && (
              <div className={cn(styles.flex3_item)}>
                <div
                  style={
                    {
                      '--src': `url(${
                        require('public/assets_pxcode/296c3e6131e95edad051f162661fa3b6.png')
                          .default
                      })`,
                    } as React.CSSProperties
                  }
                  className={cn(styles.icon2, styles.icon2_layout)}
                />
              </div>
            )}
            {!Number.isNaN(creationCount) && (
              <div className={cn(styles.text_body2, styles.text_body2_layout)}>
                {formatNumber({ number: creationCount })}{' '}
                {creationCount > 1 ? t('Creations') : t('Creation')}
              </div>
            )}
          </div>

          <div className={cn(styles.block20_item, 'hidden')}>
            <figure
              className={cn(styles.cover_block, styles.cover_block_layout)}
            >
              <px-posize
                track-style='{"flexGrow":1}'
                area-style='{":before":{"content":"\" \"","display":"inline-block","height":"100%","verticalAlign":"middle"}}'
                x="0px 264fr 0px"
                y="0px minmax(0px, max-content) 0px"
                xxs-x="0px minmax(0px,999px) 0px"
                xxs-y="0px minmax(0px, max-content) 0px"
              >
                <img
                  className={cn(styles.cover_block2)}
                  src={require('public/assets_pxcode/2f07836862b9703202f13b9812cbdc83.png')}
                  alt="alt text"
                />
              </px-posize>

              <div className={cn(styles.block3, styles.block3_layout)}>
                <div
                  className={cn(
                    styles.small_text_body1,
                    styles.small_text_body1_layout
                  )}
                >
                  Topic
                </div>
              </div>
            </figure>
          </div>
          <div
            className={cn(
              styles.text_body1,
              styles.text_body1_layout1,
              'hidden'
            )}
          >
            The African Grassland
          </div>
          <div
            className={cn(
              styles.text_body21,
              styles.text_body21_layout,
              'hidden'
            )}
          >
            {about}
          </div>
          <div
            className={cn(
              styles.paragraph_body1,
              styles.paragraph_body1_layout,
              'hidden'
            )}
          >
            120 folio
          </div>
        </div>
      )}
      <style jsx global>{`
        .margin-top-0 {
          margin-top: 0 !important;
        }
      `}</style>
    </>
  );
}
