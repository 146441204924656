import * as React from 'react';
import { Svg } from '../svg';

function SvgFilter1({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.667.333v7h1.75l-2.334 2.917L7.75 7.333H9.5v-7h1.167zM6 7.917v1.167H.75V7.917H6zm1.167-3.5v1.167H.75V4.417h6.417zm0-3.5v1.166H.75V.917h6.417z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgFilter1);
export default ForwardRef;
