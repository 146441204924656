import { NextRouter, useRouter } from 'next/router';

type ValuesOf<T extends readonly string[]> = T[number];

export function useRouterQuery<K extends readonly string[]>(...queries: K) {
  const router = useRouter();
  const keys = {} as { [key in ValuesOf<K>]: string | null };
  // eslint-disable-next-line no-restricted-syntax
  for (const key of queries) {
    if (typeof key === 'string') {
      keys[key] = getRouterQuery(router, key);
    }
  }
  return {
    router,
    ...keys,
  };
}

function getRouterQuery(router: NextRouter, query: string): string | null {
  const q = router.query[query];
  if (typeof q === 'string') {
    return q;
  }
  return null;
}
