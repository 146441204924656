import * as React from 'react';
import { Svg } from '../svg';

function SvgEtherscan({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#etherscan_svg__clip0_6588_79604)">
        <path
          d="M4.99 11.98a1.017 1.017 0 011.022-1.018l1.695.005a1.02 1.02 0 011.018 1.02v6.408c.191-.057.436-.117.704-.18a.849.849 0 00.655-.826v-7.95a1.02 1.02 0 011.019-1.018H12.8a1.02 1.02 0 011.019 1.018v7.378s.425-.172.839-.346a.85.85 0 00.52-.783V6.892a1.019 1.019 0 011.018-1.019h1.698a1.02 1.02 0 011.019 1.02v7.242c1.472-1.067 2.964-2.35 4.148-3.893a1.71 1.71 0 00.26-1.597A11.981 11.981 0 0012.156.554C5.5.464 0 5.897 0 12.554a11.962 11.962 0 001.594 6.002 1.517 1.517 0 001.447.75c.321-.028.721-.068 1.196-.124a.848.848 0 00.753-.842v-6.36"
          fill="#21325B"
        />
        <path
          d="M4.953 22.257a11.998 11.998 0 0019.056-9.704c0-.277-.013-.55-.031-.822C19.594 18.27 11.5 21.326 4.953 22.257"
          fill="#979695"
        />
      </g>
      <defs>
        <clipPath id="etherscan_svg__clip0_6588_79604">
          <path
            fill="#fff"
            transform="translate(0 .553)"
            d="M0 0h24.009v24H0z"
          />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEtherscan);
export default ForwardRef;
