import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
} from '@chakra-ui/react';
import { useState } from 'react';

export const NoteModal = ({
  modal,
  updateNoteText,
  onOk,
  noteModalTitle = '',
  noteModalSubtitle = '',
  okButtonText = 'Submit',
  placeholder = '',
}: {
  modal?: any;
  updateNoteText: any;
  onOk?: any;
  noteModalTitle?: string;
  noteModalSubtitle?: string;
  okButtonText?: string;
  placeholder?: string;
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="640px">
        <ModalCloseButton color="$mode.500" />
        <ModalBody pt="40px" px="40px" pb="32px">
          <h1 className="text-gray-900 font-semibold text-2xl mb-6">
            {noteModalTitle}
          </h1>
          <p className="text-gray-500 text-sm font-medium mb-2">
            {noteModalSubtitle}
          </p>
          <Textarea
            maxLength={500}
            placeholder={placeholder}
            onChange={(e) => {
              if (e.target.value.trim().length > 0) {
                setIsDisabled(false);
              } else {
                setIsDisabled(true);
              }
              updateNoteText(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter
          py="24px"
          px="40px"
          borderTop="1px solid #E6E8E9"
          background="gray.50"
        >
          <Button
            mr="10px"
            fontSize="16px"
            px="32px"
            py="10px"
            bg="transparent"
            color="gray.500"
            fontWeight="500"
            _hover={{ background: 'transparent' }}
            _focus={{ background: 'transparent' }}
            onClick={async () => {
              modal.onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            isDisabled={isDisabled}
            borderRadius="20px"
            fontSize="16px"
            px="32px"
            py="10px"
            fontWeight="500"
            onClick={async () => {
              await onOk();
              modal.onClose();
            }}
          >
            {okButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
