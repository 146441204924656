import { Modal, ModalOverlay } from '@chakra-ui/react';
import cn from 'classnames';
import SvgError from 'src/components/icons/error-icon';
import styles from './ErrorModal_styles.module.scss';

export default function ErrorModal(props: {
  isOpen: boolean;
  onClose: any;
  heading: string;
  paragraph: string;
  subtext?: string;
  cancelButtonText: string;
  cancelButtonClass?: string;
  confirmButtonText?: string;
  confirmButtonAction?: any;
  confirmButtonClass?: string;
}) {
  const {
    isOpen,
    onClose,
    heading,
    paragraph,
    subtext,
    cancelButtonText,
    cancelButtonClass,
    confirmButtonText,
    confirmButtonAction,
    confirmButtonClass,
  } = props;
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered allowPinchZoom>
        <ModalOverlay
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <div className={cn(styles.main_container)}>
            <SvgError boxSize="109px" />
            <h3>{heading}</h3>
            <p>{paragraph}</p>
            <div className={cn(styles.buttons_container)}>
              <button
                type="button"
                className={cn(styles.cancel_button, `${cancelButtonClass}`)}
                onClick={onClose}
              >
                {cancelButtonText}
              </button>
              {confirmButtonAction && (
                <button
                  type="button"
                  className={cn(styles.confirm_button, `${confirmButtonClass}`)}
                  onClick={confirmButtonAction}
                >
                  {confirmButtonText}
                </button>
              )}
            </div>
            {subtext && (
              <div className="text-xs font-medium text-gray-500 my-4">
                {subtext}
              </div>
            )}
          </div>
        </ModalOverlay>
      </Modal>
    </>
  );
}
