import { AvatarProps as CAvatarProps, SkeletonProps } from '@chakra-ui/react';
import { FC } from 'react';

import SvgLogo from 'src/components/icons/logo';
import { useRouter } from 'next/router';
import { imageOptimize } from 'src/service/image';
import { checkAuthorName } from 'src/utils/checkAuthorName';

export type AvatarProps = Omit<CAvatarProps, 'width' | 'height'> & {
  isLoading?: boolean;
  href?: string;
  wrapperProps?: SkeletonProps;
  svgClassName?: string;
} & {
  width?: any;
  height?: any;
  margin?: string;
  border?: string;
  className?: string;
  widthUnit?: string;
  borderRadius?: string;
  zIndex?: number;
  author?: any; // if Project Page use it, `author` won't be exist.
};

/**
 * @description Avatar wrapped chakra ui avatar but with our own style.
 */

export const Avatar: FC<AvatarProps> = ({
  isLoading,
  href,
  wrapperProps,
  children,
  className = '',
  margin,
  border = 'none',
  bgColor,
  zIndex = '',
  author = {
    username: '',
  },
  ...props
}) => {
  const {
    width,
    height,
    src: imgUrl,
    svgClassName,
    widthUnit,
    borderRadius,
    mr,
  } = props;

  const avatarWidth = width || 40;
  const avatarHeight = height || 40;

  const router = useRouter();

  const getSize = () => {
    const firstName = (author && (author.firstName || author.firstname)) || '';
    const lastName = (author && (author.lastName || author.lastname)) || '';

    let fontSize = getBoxSize(avatarWidth / 2);
    if (firstName && lastName) {
      fontSize = getBoxSize(avatarWidth / 4);
    }

    return fontSize;
  };

  const getBoxSize = (size) => {
    if (/^\d+$/g.test(size)) {
      return `${size}${widthUnit || 'px'}`;
    }
    return size;
  };

  const getUniqueClass = () => svgClassName || 'circle-wrapper';

  if (imgUrl) {
    return (
      <div
        aria-hidden="true"
        className={`${className} relative ${mr && `mr-${mr}`}`}
        onClick={() => {
          if (href) {
            router.push(href);
          }
        }}
      >
        <div
          style={{
            width: `${getBoxSize(avatarWidth)}`,
            height: `${getBoxSize(avatarHeight)}`,
            border,
            borderRadius: `${borderRadius || ''}px`,
          }}
          className={`${getUniqueClass()} align-middle overflow-hidden items-center flex`}
        >
          <img
            src={imageOptimize({
              url: imgUrl,
              imgContainer: 'avatar',
              borderRadius: `${borderRadius || ''}px`,
            })}
            alt=""
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </div>
        {children}
      </div>
    );
  }
  // defaultImg for no username
  return (
    <div
      aria-hidden="true"
      className={`${className} relative ${mr && `mr-${mr}`}`}
    >
      <div
        style={{
          width: `${getBoxSize(avatarWidth)}`,
          height: `${getBoxSize(avatarHeight)}`,
          border,
          zIndex: `${zIndex || 'unset'}`,
          borderRadius: `${borderRadius || ''}px`,
        }}
        className={`${getUniqueClass()} align-middle overflow-hidden`}
      >
        {!author?.username ? (
          <div className="w-full h-full">
            <SvgLogo
              alt="1TM Logo"
              margin="auto"
              top={0}
              bottom={0}
              right={0}
              left={0}
              position="absolute"
              className=""
            />
          </div>
        ) : (
          <div className="relative">
            <div
              style={{ height: 'fit-content', fontSize: `${getSize()}` }}
              className="absolute m-auto right-0 left-0 top-0 bottom-0 w-max text-white font-bold"
            >
              {checkAuthorName(author)}
            </div>
            <img
              src={`/api/avatar?name=${author?.username}`}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: `${borderRadius || ''}px`,
              }}
            />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
