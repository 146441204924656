import breakpoints from './breakpoints';
import colors from './colors';
import radii from './radius';
import { space } from './spacing';
import sizes from './sizes';
import typography from './typography';
import textStyles from './text-styles';
import layerStyles from './layer-styles';

const theme = {
  ...typography,
  breakpoints,
  colors,
  radii,
  sizes,
  space,
  textStyles,
  layerStyles,
};

export default theme;
