import { IS_PROD } from 'src/constants/env';

declare global {
  interface Window {
    gtag: any;
  }
}

// log changed title
export const pageviewtitle = (data) => {
  if (IS_PROD) {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_title: data.title,
      page_location: data.url,
    });
  }
};

// log the pageview with their URL
export const pageview = (url) => {
  if (IS_PROD) {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
  }
};

// log the pageview with their URL manually
export const manualpageview = (data) => {
  if (IS_PROD) {
    window.gtag('event', 'page_view', {
      page_title: data.title,
      page_location: data.location,
      page_path: data.path,
      send_to: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
    });
  }
};

// log specific events happening.
export const event = ({ action, params }) => {
  window.gtag('event', action, params);
};
