import is from 'is_js';
import Link from 'next/link';
import PreloadImg from 'src/components/common/preload-img/preload-img';
import { imageOptimize } from 'src/service/image';
import { Box, Flex } from '@chakra-ui/react'; // Text
import { ReactNode, useMemo } from 'react';
import SvgAddAvatar from 'src/components/icons/add-avatar';
import { SharePopover } from 'src/components/share-buttons/share-popover';
import SvgShareNewWhite from 'src/components/icons/share-new-white';
import SvgProject from 'src/components/icons/project';
import SvgCollection from 'src/components/icons/collection';
import { Avatar } from 'src/components/avatar/avatar';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { spaceTeamSort } from 'src/helpers/sortData';

interface ResultItem {
  owner: any;
  title: string;
  headline: string;
  summary: string;
  categories: any[];
  languages: any[];
  description: string | null;
  avatarURL: string;
  coverImgURL: string;
  filterType: string;
  priority: number;
  createdAt: string;
  numOfFollowers: number;
  objectID: string;
  _highlightResult: any;
}
interface Item {
  id?: string;
  title?: string;
  subtitle?: string;
  avatarImgURL?: string;
  coverImgURL?: string;
  projectCount?: string;
  team?: Array<any>;
}
interface Props {
  data?: Item;
  result?: Partial<ResultItem>;
  className?: string;
  menu?: ReactNode;
  // topAndBottomSpaces?: boolean;
}

const Project = ({ data = {}, result = {}, className = '', menu }: Props) => {
  const isMobile = useIsMobile();

  // handle data
  const id = data.id || result.objectID;

  const title = data.title || result.title;
  const subtitle = data.subtitle || result.headline;
  const projectCount = data.projectCount || '0';
  const coverImgURL = data.coverImgURL || result.coverImgURL;
  const avatarImgURL = data.avatarImgURL || result.avatarURL;

  const owner = useMemo(
    () =>
      data.team?.find((member) => member.role === 'SPACE_FOUNDER') ||
      result.owner,
    [data, result]
  );

  const avatarTeams = useMemo(
    () =>
      (data.team &&
        spaceTeamSort(
          data.team?.map((member) => {
            const {
              firstName,
              lastName,
              username,
              profileImgURL,
              role,
            } = member;
            return {
              firstName,
              lastName,
              username,
              profileImgURL,
              role,
            };
          })
        )) || [owner],
    [data, owner]
  );

  const link = `/space/${id}/`;

  if (is.empty(data) && is.empty(result)) {
    return (
      <div className="animate-pulse">
        <div className="card h-72 skeleton-line rounded mt-4 mb-2" />
      </div>
    );
  }

  return (
    <>
      {menu && (
        <Box className="absolute right-1 top-0 z-10 flex items-center">
          {menu}
        </Box>
      )}
      <Box
        _hover={{ background: 'rgba(0,0,0,0.3)' }}
        className="w-9 h-9 flex justify-center items-center rounded-full z-10 block absolute right-4 top-4 cursor-pointer"
      >
        <SharePopover
          heading="Share this Space"
          url={`${window.location.origin}${link}`}
          shareButton={<SvgShareNewWhite fontSize="20px" />}
        />
      </Box>
      <Link href={{ pathname: link }} prefetch={false}>
        <div
          style={{ borderRadius: '24px', height: 'auto' }}
          className={`shadow overflow-hidden cursor-pointer ${className}`}
        >
          <div style={{ minHeight: '81.75px', maxHeight: '176.25px' }}>
            {!coverImgURL ? (
              <PreloadImg height={169} />
            ) : (
              <img
                className="block object-cover w-full"
                alt=""
                src={imageOptimize({
                  url: coverImgURL,
                  imgContainer: 'postCover',
                })}
                style={{ height: 'inherit', aspectRatio: '15/4' }}
              />
            )}
          </div>
          <div style={{ minHeight: '194px' }} className="flex p-8">
            <div>
              <Avatar
                width={isMobile ? 56 : 80}
                height={isMobile ? 56 : 80}
                src={imageOptimize(avatarImgURL) || ''}
              />
            </div>
            <div className="pl-4 w-full">
              <div className="text-gray-900 font-bold text-xl md:text-2xl line-clamp-1">
                {title}
              </div>
              <div className="max-h-12 my-4 text-sm md:text-base text-gray-700 font-medium line-clamp-2">
                {subtitle}
              </div>
              <div className="flex flex-col lg:flex-row">
                <div className="flex text-gray-900 font-medium">
                  <div className="flex">
                    <SvgProject
                      height={isMobile ? '15px' : '20px'}
                      width={isMobile ? '15px' : '20px'}
                      className="m-auto"
                    />
                    <span className="m-auto pl-2 text-sm md:text-base">
                      {`${projectCount} ${
                        !isMobile &&
                        (Number(projectCount) > 1 ? 'projects' : 'project')
                      }`}
                    </span>
                  </div>
                  <div className="flex ml-6">
                    <SvgCollection
                      height={isMobile ? '15px' : '20px'}
                      width={isMobile ? '15px' : '20px'}
                      className="m-auto"
                    />
                    <span className="m-auto pl-2 text-sm md:text-base">
                      {`0 ${!isMobile && 'collection'}`}
                    </span>
                  </div>
                </div>
                <div className="py-4 m-0 md:ml-auto">
                  <Flex flexDirection="row-reverse" className="justify-end">
                    {avatarTeams.length > 3 && <SvgAddAvatar fontSize="42px" />}
                    {avatarTeams
                      .slice(0, 3)
                      .reverse()
                      .map((member, index) => (
                        <Avatar
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          borderRadius="full"
                          width="40"
                          height="40"
                          src={imageOptimize({
                            url: member?.profileImgURL,
                            imgContainer: 'avatar',
                          })}
                          className={`${
                            index !== avatarTeams.length + 1 &&
                            'override-avatar'
                          }`}
                          author={member}
                        />
                      ))}
                  </Flex>
                </div>
              </div>
            </div>
          </div>
          <style jsx global>{`
            .override-avatar {
              margin-right: -16px;
            }
          `}</style>
        </div>
      </Link>
    </>
  );

  // return (
  //   shouldBeVisible && (
  //     <Box
  //       w="100%"
  //       boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06)"
  //       className={`bg-white relative project-card rounded mt-4 mb-2 overflow-hidden ${className}`}
  //       borderRadius="24px"
  //     >
  //       {menu && (
  //         <Box className="hidden absolute right-1 top-0 z-10 flex items-center">
  //           {menu}
  //         </Box>
  //       )}
  //       <Box
  //         _hover={{ background: 'rgba(0,0,0,0.3)' }}
  //         className="w-9 h-9 flex justify-center items-center rounded-full z-10 block absolute right-4 top-4 cursor-pointer"
  //       >
  //         <SharePopover
  //           heading="Share this Space"
  //           url={`${window.location.origin}${link}`}
  //           shareButton={<SvgShareNewWhite fontSize="20px" />}
  //         />
  //       </Box>
  //       <Link href={{ pathname: link }} prefetch={false} scroll>
  //         <a>
  //           <Box className="relative">
  //             <div className="relative project-image ">
  //               {data.draft && (
  //                 <div
  //                   className="absolute flex justify-center items-center bg-white top-4 left-4 py-1 px-2.5 w-auto"
  //                   style={{
  //                     border: '1px solid #E6E8E9',
  //                     opacity: 0.8,
  //                     borderRadius: '45px',
  //                   }}
  //                 >
  //                   <p
  //                     className="text-gray-900 font-bold"
  //                     style={{
  //                       fontSize: '0.75rem',
  //                       lineHeight: '1.125rem',
  //                     }}
  //                   >
  //                     Draft
  //                   </p>
  //                 </div>
  //               )}

  //               {data.coverImgURL === '' && (
  //                 <PreloadImg height={169} className="" />
  //               )}
  //               {data.coverImgURL !== '' && (
  //                 <img
  //                   className="block object-cover w-full"
  //                   alt=""
  //                   src={imageOptimize({
  //                     url: data.coverImgURL,
  //                     imgContainer: 'postCover',
  //                   })}
  //                   style={{ height: '169px' }}
  //                 />
  //               )}
  //               {(categories.length > 0 || data.summary !== '') && (
  //                 <div className="hidden project-detail  p-4 bg-black absolute top-0 left-0 h-full w-full opacity-70 flex flex-col justify-end pb-16">
  //                   <p className="text-sm text-white mt-2 mb-1 line-clamp-3 mb-4">
  //                     {data.summary}
  //                   </p>
  //                   <div>
  //                     {categories.map((cate, key) => {
  //                       if (cate === '') return null;
  //                       const mkey = `m_${key}`;
  //                       return (
  //                         <span
  //                           key={mkey}
  //                           className="label font-medium text-sm px-4 py-1 text-xs rounded-full bg-pinkBlue text-snomo"
  //                         >
  //                           {cate}
  //                         </span>
  //                       );
  //                     })}
  //                   </div>
  //                 </div>
  //               )}
  //             </div>
  //             <Box
  //               position="absolute"
  //               right="24px"
  //               top="169px"
  //               transform="translateY(-50%)"
  //             >
  //               <Flex flexDirection="row-reverse">
  //                 {avatarTeams?.length === 1 && (
  //                   <Image
  //                     borderRadius="full"
  //                     boxSize="40px"
  //                     src={imageOptimize({
  //                       url: avatarTeams[0],
  //                       imgContainer: 'avatar',
  //                     })}
  //                     alt="avatar-user"
  //                   />
  //                 )}

  //                 {avatarTeams?.length > 1 && (
  //                   <>
  //                     <SvgAddAvatar fontSize="42px" />
  //                     {avatarTeams?.slice(0, 3).map((item, index) => {
  //                       return (
  //                         <Image
  //                           // eslint-disable-next-line react/no-array-index-key
  //                           key={index}
  //                           borderRadius="full"
  //                           boxSize="40px"
  //                           src={imageOptimize({
  //                             url: item,
  //                             imgContainer: 'avatar',
  //                           })}
  //                           alt="avatar-user"
  //                           className={`${index !== 3 && 'override-avatar'}`}
  //                         />
  //                       );
  //                     })}
  //                   </>
  //                 )}
  //               </Flex>
  //             </Box>

  //             {/* <Box
  //               className="absolute p-4 bottom-0 flex justify-between w-full items-end"
  //             >
  //               <div className="flex">
  //                 <Avatar
  //                   width={30}
  //                   height={30}
  //                   src={imageOptimize(owner['profileImgURL']) || ''}
  //                 />
  //                 <div className="ml-2">
  //                   <Text
  //                     className="font-bold block text-white text-sm"
  //                     fontSize={{ base: 'xs', md: 'md' }}
  //                     lineHeight="17px"
  //                     fontWeight={{ base: '700', md: 'bold' }}
  //                   >
  //                     {getProfileFullNameOrUsername(owner) || '1TM Member'}
  //                   </Text>
  //                   <Text
  //                     className="font-medium block text-xs text-white"
  //                     lineHeight="18px"
  //                   >
  //                     {owner['role'] === 0 ? 'Author' : 'Creator'}
  //                   </Text>
  //                 </div>
  //               </div>
  //               {data['createdAt'] && (
  //                 <span className="text-white text-xs font-bold">
  //                   {dayHepler.localizedFormat(data['createdAt'], 'MMM DD')}
  //                 </span>
  //               )}
  //             </Box> */}
  //           </Box>
  //           <Box p="24px">
  //             <Text
  //               fontWeight="500"
  //               lineHeight="18px"
  //               fontSize="sm"
  //               color="gray.900"
  //             >
  //               {data.projectType === 'REPPL' ? 'SPACE' : data.projectType}
  //               {data.status || ''}
  //             </Text>
  //             <Text
  //               fontWeight="600"
  //               lineHeight="20px"
  //               fontSize="md"
  //               color="gray.900"
  //               pt="8px"
  //             >
  //               {data.title}
  //             </Text>
  //             <Text
  //               fontWeight="500"
  //               lineHeight="20px"
  //               fontSize="sm"
  //               color="gray.500"
  //               pt="12px"
  //             >
  //               {data.summary}
  //             </Text>
  //           </Box>
  //         </a>
  //       </Link>
  //       <style jsx global>{`
  //         .override-avatar {
  //           margin-right: -16px;
  //         }
  //       `}</style>
  //     </Box>
  //   )
  // );
};

export default Project;
