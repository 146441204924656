import * as React from 'react';
import { Svg } from '../svg';

function SvgMusic({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M11 7.17A3 3 0 1013 10V.458c4.057 1.274 7 5.064 7 9.542 0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0c.337 0 .671.017 1 .05v7.12z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgMusic);
export default ForwardRef;
