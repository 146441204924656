import * as React from 'react';
import { Svg } from '../svg';

function SvgNcType({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.407 4.58c-6.336 0-11.473 5.136-11.473 11.472 0 6.336 5.137 11.473 11.473 11.473 6.336 0 11.473-5.137 11.473-11.473 0-6.336-5.137-11.473-11.473-11.473zM3.074 16.051c0-7.364 5.97-13.333 13.333-13.333 7.364 0 13.333 5.97 13.333 13.333 0 7.364-5.97 13.333-13.333 13.333-7.364 0-13.333-5.97-13.333-13.333zm17.193-4.35a4.403 4.403 0 012.67-.16c.884.224 1.679.717 2.278 1.411A.803.803 0 1124 14.003a2.856 2.856 0 00-1.458-.905 2.797 2.797 0 00-1.696.103 2.88 2.88 0 00-1.347 1.076 3 3 0 00-.512 1.68 3 3 0 00.512 1.68c.334.492.804.867 1.347 1.076.542.21 1.134.245 1.696.103A2.857 2.857 0 0024 17.91a.803.803 0 111.215 1.05c-.6.694-2.279 1.412-2.279 1.412s-1.816.168-2.669-.161a4.487 4.487 0 01-2.098-1.674 4.605 4.605 0 01-.789-2.581c0-.92.275-1.82.79-2.58a4.487 4.487 0 012.097-1.675zm-4.323.424a.842.842 0 00-1.683 0v5.342l-4.753-5.835a.947.947 0 00-1.68.598v7.343a.842.842 0 101.683 0v-5.342l4.753 5.835a.947.947 0 001.68-.598v-7.343z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNcType);
export default ForwardRef;
