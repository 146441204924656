import * as React from 'react';
import { Svg } from '../svg';

function SvgSpotify({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M19.348 10.638C15.48 8.341 9.1 8.13 5.408 9.251a1.122 1.122 0 11-.652-2.148C8.995 5.816 16.04 6.064 20.494 8.708a1.122 1.122 0 01-1.146 1.93zm-.126 3.403a.936.936 0 01-1.287.308c-3.225-1.982-8.143-2.557-11.958-1.399a.937.937 0 01-1.097-1.335.937.937 0 01.554-.455c4.358-1.322 9.776-.682 13.48 1.594.44.271.578.847.308 1.287zm-1.469 3.267a.747.747 0 01-1.028.249c-2.818-1.722-6.365-2.111-10.542-1.157a.748.748 0 11-.333-1.458c4.571-1.045 8.492-.595 11.655 1.338a.748.748 0 01.248 1.028zM12.25 0c-6.627 0-12 5.372-12 12s5.373 12 12 12 12-5.372 12-12c0-6.627-5.372-12-12-12"
        fill="#1ED660"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgSpotify);
export default ForwardRef;
