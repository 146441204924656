import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Tabs,
  Stack,
  // useDisclosure,
} from '@chakra-ui/react';
import SvgPost from 'src/components/icons/post-icon';
import SvgDraft from 'src/components/icons/draft-icon';
import SvgExisting from 'src/components/icons/existing';
// import SvgStory from 'src/components/icons/post-story';
// import SvgMedia from 'src/components/icons/post-carousel';
// import SvgCreation from 'src/components/icons/post-creation';
import SvgClose from 'src/components/icons/close';
// import { useContext } from 'react';
// import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { useRouter } from 'next/router';
// import { PostingOptionModalItem } from './post-items';
import { CreateButton } from 'src/components/create-button';

export function PostingOptionModal({
  modal,
  reppl,
  episodeType,
}: {
  modal: any;
  reppl?: any;
  episodeType?: any;
}) {
  // const { user } = useContext(UserContext) as UserContextType;

  // const mediaPostLink = episodeType
  //   ? `/reppl/${reppl?.id}/episode/new?episode=${episodeType}&&type=mediaPost`
  //   : '/new?type=mediaPost';

  const router = useRouter();

  // const storyPostLink = episodeType
  //   ? `/space/${router?.query.tid}/episode/new?episode=${episodeType}`
  //   : '/new';

  const storyPostLink = episodeType
    ? `/space/${router?.query.tid}/episode/new?sid=${router?.query.rid}`
    : '/new';

  // const draftPostLink = episodeType
  //   ? `/${user.username}/drafts-submissions?rid=${reppl?.id}&&episode=${episodeType}`
  //   : `/${user.username}/drafts-submissions`;

  return (
    <>
      <Modal isOpen={modal.isOpen} onClose={modal.onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW={848} borderRadius="32px">
          <ModalCloseButton mt={2}>
            <SvgClose fontSize="24px" />
          </ModalCloseButton>
          <Flex
            direction="column"
            alignItems="center"
            px="40px"
            pt="40px"
            pb="45px"
          >
            <Tabs>
              <div className="text-center font-semibold text-2xl text-gray-900 pb-2">
                Create new folio
              </div>
              <div className="font-medium text-sm text-gray-700 text-center py-6">
                What kind of folio do you want to start from?
              </div>
              <ModalBody w="100%" p="0">
                <PostingOptions
                  storyLink={storyPostLink}
                  closeParent={modal.onClose}
                  isReppl={!!reppl}
                />
              </ModalBody>
            </Tabs>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
}

export function PostingOptions({
  storyLink,
  closeParent,
  isReppl,
}: {
  storyLink?: string;
  closeParent?: (e: any) => void;
  isReppl?: boolean;
}) {
  // const subModal = useDisclosure();
  const router = useRouter();
  return (
    <Stack
      spacing="28px"
      justifyContent="center"
      alignItems="center"
      direction={{ base: 'column', md: 'row' }}
    >
      <CreateButton
        postHeading="Create a new folio"
        postDescription={{
          1: 'Start a blank folio to express your ideas and display your creations.',
        }}
        icon={<SvgPost fontSize="48px" mb="8px" />}
        selected={(e) => {
          router.push(storyLink);
          if (closeParent) {
            closeParent(e);
          }
        }}
      />
      <CreateButton
        postHeading="Edit your draft"
        postDescription={{
          1: 'Edit a folio from one of your saved drafts.',
        }}
        icon={<SvgDraft fontSize="48px" mb="8px" />}
        selected={() => {
          router.push(`/${router.query.username}/drafts-submissions`);
        }}
      />
      {isReppl && (
        <CreateButton
          isDisabled
          postHeading="Start from existing"
          postDescription={{
            1: 'Use your current folios to submit to the project.',
          }}
          icon={<SvgExisting fontSize="48px" mb="8px" />}
          // selected={draftModal.onOpen}
        />
      )}
      {/* {subModal.isOpen && (
        <div className="absolute inset-0 w-full h-full">
          <div
            style={{ height: 'fit-content' }}
            className="grid grid-cols-3 gap-6 absolute inset-0 m-auto w-max"
          >
            <div
              role="button"
              tabIndex={0}
              style={{ zIndex: '60', height: '24px' }}
              className="rounded-full bg-white absolute -right-2 -top-8"
              onClick={() => subModal.onClose()}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  subModal.onClose();
                }
              }}
            >
              <SvgClose fontSize="24px" />
            </div>
            <PostingOptionModalItem
              postHeading="Story"
              postDescription={{
                1: 'Built for the wordsmith.',
                2: 'Share through a storytelling format.',
              }}
              icon={<SvgStory fontSize="48px" mb="8px" />}
              zIndex={60}
              selected={(e) => {
                router.push(storyLink);
                if (closeParent) {
                  closeParent(e);
                }
              }}
            />
            <PostingOptionModalItem
              postHeading="Carousel"
              postDescription={{
                1: 'Built for the wordsmith.',
                2: 'Share through a storytelling format.',
              }}
              icon={<SvgMedia fontSize="48px" mb="8px" />}
              zIndex={60}
              selected={(e) => {
                router.push(mediaLink);
                if (closeParent) {
                  closeParent(e);
                }
              }}
            />
            <PostingOptionModalItem
              isDisabled
              postHeading="Creation"
              postDescription={{
                1: 'Built for the wordsmith.',
                2: 'Share through a storytelling format.',
              }}
              icon={<SvgCreation fontSize="48px" mb="8px" />}
              zIndex={60}
            />
          </div>
          <div
            style={{ backdropFilter: 'blur(3px)' }}
            className="absolute inset-0 w-full h-full bg-bgBlur z-50"
          />
        </div>
      )} */}
    </Stack>
  );
}
