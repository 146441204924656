export const getLocalStorage = (key: string, parse: boolean = false) => {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  const value = localStorage.getItem(key);
  if (parse) return JSON.parse(value);

  return value;
};
export const setLocalStorage = (
  key: string,
  value: any,
  stringify: boolean = false
) => {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  const setValue = stringify ? JSON.stringify(value) : value;
  return localStorage.setItem(key, setValue);
};
export const removeLocalStorage = (key: string) => {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  return localStorage.removeItem(key);
};
