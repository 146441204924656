import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
  useQuery,
} from 'react-query';
import { standaloneToast } from 'src/components/toast';
import { CacheKey } from 'src/constants/cache-key';
import { useRouter } from 'next/router';
import {
  BiographyRepplResponse,
  BiographyUserResponse,
} from 'src/service/project';
import { useContext } from 'react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import {
  CollectionDetailListResponse,
  CollectionDetailResponse,
} from './types';
import {
  addCuration,
  createLabel,
  CreateLabelRequest,
  CreationLabelParams,
  CreationLabelRequest,
  deleteCuration,
  getCreationLabel,
  updateLabel,
  LabelListRequestQuery,
  LabelListRequest,
  fetchLabelList,
  getLabelDetail,
  deleteLabel,
  getLabelDetailList,
  changeLabelOrder,
  CuratableListParams,
  getCuratableList,
  featureCollection,
} from './services';
import { FollowRequest } from '../follow/types';
import { createFollow, deleteFollow } from '../follow/service';
import { RepplResponse } from '../reppl/service';

// Label

const getCollectionQueryKey = (
  req: LabelListRequest,
  params?: LabelListRequestQuery
) => [
  CacheKey.labelList,
  {
    ...req,
    ...params,
  },
];
export function useCollectionDetail(labelId?: string) {
  return useQuery<CollectionDetailResponse>(
    [CacheKey.label, { id: labelId }],
    () => getLabelDetail({ id: labelId }),
    {
      enabled: !!labelId,
    }
  );
}

export function useCollectionDetailList({
  labelId,
  params = {},
}: {
  labelId: string;
  params?: LabelListRequestQuery;
}) {
  return useInfiniteQuery(
    [CacheKey.collectionList, { id: labelId }],
    ({ pageParam = 1 }) => {
      return getLabelDetailList<CollectionDetailListResponse>(
        { id: labelId },
        { ...params, pageNum: pageParam }
      );
    },
    {
      getNextPageParam: ({ next, pageNum }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export function useLabelListQuery({
  req,
  params = {},
  enabled,
}: {
  req: LabelListRequest;
  params?: LabelListRequestQuery;
  enabled?: boolean;
}) {
  return useInfiniteQuery(
    getCollectionQueryKey(req, params),
    ({ pageParam = 1 }) => {
      return fetchLabelList(req, {
        ...params,
        pageNum: pageParam,
      });
    },
    {
      enabled: enabled || !!req.areaID,
      refetchOnWindowFocus: false,
      getNextPageParam: ({ next, pageNum }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export function useCreateLabelMutation() {
  const queryClient = useQueryClient();

  const router = useRouter();
  const { isLogin: isAuth } = useContext(UserContext) as UserContextType;
  return useMutation((r: CreateLabelRequest) => createLabel(r), {
    onSettled: () => {
      queryClient.invalidateQueries(CacheKey.labelList);
    },
    onError: () => {
      if (!isAuth) {
        router.push({ query: { ...router.query, page: 'signIn' } });
      }
    },
  });
}

export function useUpdateLabelMutation(showToast = true) {
  const queryClient = useQueryClient();

  return useMutation(updateLabel, {
    onSettled: () => {
      queryClient.invalidateQueries(CacheKey.labelList);
      queryClient.invalidateQueries(CacheKey.label);
    },
    onSuccess: (res) => {
      if (res.code === 1000) {
        if (showToast) {
          standaloneToast({
            position: 'bottom-right',
            title: '🎉 The inspiration has been successfully updated!',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    },
    onError: () => {
      if (showToast) {
        standaloneToast({
          title: 'Update inspiration failed 😢',
          status: 'error',
        });
      }
    },
  });
}

export function useDeleteCollection() {
  const queryClient = useQueryClient();

  return useMutation(deleteLabel, {
    onSuccess: (res) => {
      if (res.code === 1000) {
        standaloneToast({
          position: 'bottom-right',
          title: '🎉 Inspiration deleted successfully!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(CacheKey.reppl);
      queryClient.invalidateQueries(CacheKey.labelList);
    },
    onError: () => {
      standaloneToast({
        title: 'Delete inspiration failed 😢',
        status: 'error',
      });
    },
  });
}

// Curation
export function useCurationMutation(labelId?: number) {
  const queryClient = useQueryClient();
  return useMutation(addCuration, {
    onSettled: () => {
      if (labelId) {
        queryClient.invalidateQueries(CacheKey.label);
        queryClient.invalidateQueries(CacheKey.collectionList);
      } else {
        queryClient.invalidateQueries(CacheKey.labelList);
      }
    },
  });
}

export function useDeleteCurationMutation() {
  const queryClient = useQueryClient();
  return useMutation(deleteCuration, {
    onSettled: () => {
      queryClient.invalidateQueries(CacheKey.label);
      queryClient.invalidateQueries(CacheKey.collectionList);
    },
  });
}

export function useCreationLabelQuery(
  req?: CreationLabelRequest,
  params?: CreationLabelParams
) {
  return useInfiniteQuery(
    [CacheKey.creationLabel, req, params],
    ({ pageParam = 1 }) =>
      getCreationLabel(req, {
        ...params,
        keyword: params.keyword && encodeURIComponent(params.keyword),
        pageNum: pageParam,
      }),
    {
      enabled:
        !!req &&
        !!(req.areaType && req.contentID && req.contentType && req.areaID),
      getNextPageParam: ({ next, pageNum }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export function useChangeOrderMutation() {
  return useMutation(changeLabelOrder, {
    onError: () => {
      standaloneToast({
        title: 'Changing failed, meet some problems 😢',
        status: 'error',
      });
    },
  });
}

export function useLabelFollow() {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { isLogin: isAuth } = useContext(UserContext) as UserContextType;

  return useMutation(
    (values: FollowRequest) => {
      if (!isAuth) {
        router.push({ query: { ...router.query, page: 'signIn' } });
        return;
      }

      return values.follow ? createFollow(values) : deleteFollow(values);
    },
    {
      onMutate: (values) => {
        if (!isAuth) return;

        // Optimistic update bio
        if (values.group === 'USER' || values.group === 'REPPL') {
          const queryKey = [
            CacheKey.projectBio,
            { id: values.targetID, type: values.group },
          ];
          queryClient.cancelQueries(queryKey);

          const prevBioCache = queryClient.getQueryData<RepplResponse>(
            queryKey
          );

          let updater;
          if (values.group === 'USER') {
            updater = (prevCache: BiographyUserResponse) => ({
              ...prevCache,
              isFollowed: !prevCache.isFollowed,
              followerCount: !prevCache.isFollowed
                ? prevCache.followerCount + 1
                : prevCache.followerCount - 1,
            });
          }

          if (values.group === 'REPPL') {
            updater = (prevCache: BiographyRepplResponse) => ({
              ...prevCache,
              isFollowed: !prevCache.isFollowed,
              followerCount: !prevCache.isFollowed
                ? prevCache.followerCount + 1
                : prevCache.followerCount - 1,
            });
          }

          queryClient.setQueryData(queryKey, updater);

          return () => queryClient.setQueryData(queryKey, prevBioCache);
        }

        // Optimistic update collection detail
        if (values.group === 'LABEL') {
          const queryKey = [CacheKey.label, { id: values.targetID }];

          queryClient.cancelQueries(queryKey);

          const prevCollectionCache = queryClient.getQueryData<
            CollectionDetailResponse
          >(queryKey);
          const updater = (prevCache: CollectionDetailResponse) => ({
            ...prevCache,
            isFollowed: !prevCache?.isFollowed,
          });

          queryClient.setQueryData(queryKey, updater);

          return () => queryClient.setQueryData(queryKey, prevCollectionCache);
        }
      },
    }
  );
}

export function useCuratableList({
  labelId,
  params = {},
}: {
  labelId: number;
  params?: CuratableListParams;
}) {
  return useInfiniteQuery(
    [CacheKey.curatableList, { id: labelId, keyword: params.keyword }],
    ({ pageParam = 1 }) => {
      return getCuratableList(
        { id: labelId },
        { ...params, pageNum: pageParam }
      );
    },
    {
      getNextPageParam: ({ next, pageNum }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export function useFeaturedCollectionMutation(rid?: string) {
  const queryClient = useQueryClient();

  return useMutation(featureCollection, {
    onSuccess: () => {
      standaloneToast({
        position: 'bottom-right',
        title: '🎉 Successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onSettled: () => {
      if (rid) {
        queryClient.invalidateQueries([CacheKey.reppl, rid]);
      } else {
        queryClient.invalidateQueries(CacheKey.labelList);
      }
    },
  });
}
