import { Stack, Text, Box } from '@chakra-ui/react';
import {
  ArticleGridRowView,
  CarouselRowItem,
  CarouselMobileItem,
} from 'src/app/article/components/grid-view/grid-row-view';
import useProfileQuery from 'src/app/profile/use-profile-query';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { Avatar } from 'src/components/avatar';
import { NextChakraLink } from 'src/components/next-chakra-link';
import { useRouterQuery } from 'src/hooks/use-router-query';
import Carousel from 'nuka-carousel';
import SvgNextSlide from 'src/components/icons/next-slide';
import SvgPrevSlide from 'src/components/icons/prev-slide';
import { useIsMobile, useIsLg } from 'src/hooks/use-is-mobile';
import { useState, useEffect } from 'react';

export function StudioMoreFrom({
  type = 'grid',
  author,
  projects,
  modalChange,
  background,
}: {
  projects: any;
  type?: string;
  author?: string;
  modalChange?: (value: any) => void;
  background?: string;
}) {
  const { username } = useRouterQuery('username');
  const user = username || author;
  const { data: profile, isLoading: profileIsLoading } = useProfileQuery(user);

  const projectLength = projects?.length ?? 0;

  const isMobile = useIsMobile();
  const isLg = useIsLg();

  const [showEpisodesCount, setShowEpisodesCount] = useState(4);

  useEffect(() => {
    if (isMobile) {
      setShowEpisodesCount(4);
    } else if (isLg) {
      setShowEpisodesCount(3);
    } else {
      setShowEpisodesCount(4);
    }
  }, [isLg, isMobile]);

  if (projectLength === 0) {
    return null;
  }

  return (
    <Box background={background}>
      {type === 'grid' && (
        <ArticleGridRowView
          w="100%"
          type="STUDIO"
          articleList={projects.slice(0, 4)}
          header={
            <>
              <Stack direction="row" spacing={3} align="center">
                <Text textStyle="h5" color="$mode.900">
                  See more from
                </Text>
                <NextChakraLink href={`/${username}`}>
                  <Stack direction="row" spacing="12px" align="center">
                    <Avatar
                      width={40}
                      height={40}
                      isLoading={profileIsLoading}
                      name={getProfileFullNameOrUsername(profile)}
                      src={profile?.profileImgURL}
                      author={profile}
                    />
                    <Text
                      textStyle="buttonText"
                      color="$mode.900"
                      noOfLines={1}
                    >
                      {getProfileFullNameOrUsername(profile)}
                    </Text>
                  </Stack>
                </NextChakraLink>
              </Stack>
            </>
          }
        />
      )}
      {type === 'carousel' && (
        <>
          {isMobile ? (
            <div>
              {projects.slice(0, 4).map((episode) => {
                return (
                  <CarouselMobileItem
                    key={episode?.contentID}
                    cardData={episode}
                    onModalChange={modalChange}
                  />
                );
              })}
            </div>
          ) : (
            <div className="mx-auto w-full xl:w-11/12">
              <Carousel
                slidesToShow={showEpisodesCount}
                cellSpacing={16}
                renderCenterLeftControls={({ previousSlide, currentSlide }) => (
                  <button
                    type="button"
                    className="opacity-0 xl:opacity-100 w-10 h-10 bg-dark-100 rounded-full flex justify-center items-center border border-dark-200 cursor-pointer -left-14 absolute top-0 bottom-0 m-auto"
                    onClick={previousSlide}
                  >
                    <SvgPrevSlide
                      w="20px"
                      h="20px"
                      className="text-dark-900"
                      opacity={currentSlide === 0 ? 0.3 : 1}
                    />
                  </button>
                )}
                renderCenterRightControls={({ nextSlide, currentSlide }) => (
                  <button
                    type="button"
                    className="opacity-0 xl:opacity-100 w-10 h-10 bg-dark-100 rounded-full flex justify-center items-center border border-dark-200 cursor-pointer -right-14 absolute top-0 bottom-0 m-auto"
                    onClick={nextSlide}
                  >
                    <SvgNextSlide
                      w="20px"
                      h="20px"
                      className="text-dark-900"
                      opacity={
                        currentSlide + showEpisodesCount === projects.length
                          ? 0.3
                          : 1
                      }
                    />
                  </button>
                )}
                renderBottomCenterControls={() => null}
              >
                {projects.map((episode) => {
                  return (
                    <CarouselRowItem
                      key={episode?.contentID}
                      cardData={episode}
                      onModalChange={modalChange}
                    />
                  );
                })}
              </Carousel>
              <style jsx global>{`
                .more-from-reppl-carousel .slider-control-centerright {
                  right: ${isMobile ? '-20px !important' : '-56px !important'};
                  top: ${isMobile && 'calc(50% - 20px) !important'};
                }
                .more-from-reppl-carousel .slider-control-centerleft {
                  left: ${isMobile ? '-20px !important' : '-56px !important'};
                  top: ${isMobile && 'calc(50% - 20px) !important'};
                }
                .more-from-link {
                  color: #242526 !important;
                  text-decoration: none !important;
                }
              `}</style>
            </div>
          )}
        </>
      )}
    </Box>
  );
}
