import { EnthuseButton } from 'src/app/article/components/enthuse-button/enthuse-button';
import {
  useAgoraFeedEnthuseMutation,
  useFeedCommentEnthuseMutation,
} from 'src/app/article/hooks/use-enthuse';
import { GalleryModal } from 'src/lib/hexx/blocks/gallery/gallery-renderer';
import { useModal } from 'src/hooks/use-modal';
import { useState, useContext, useRef, useEffect } from 'react';
import Link from 'next/link';
import { Box } from '@chakra-ui/react';
import dayHepler from 'src/heplers/day';
import { Avatar } from 'src/components/avatar/avatar';
import SvgCommentsV2 from 'src/components/icons/comments-v1';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { Commenter, CommenterHandler } from 'src/components/comment';
import {
  fetchGeneralComment,
  useArticleCommentMutation,
  useGeneralCommentMutation,
  useArticleEditCommentMutation,
} from 'src/app/article/hooks/use-article-comment';
import { ArticleComment } from 'src/app/article/article-comment';
import SvgIcSettings from 'src/components/icons/ic-settings';
import Dropdowns from 'src/components/common/dropdowns/dropdowns';
// import { imageOptimize } from 'src/service/image';
// import copy from 'copy-to-clipboard';
import { SharePopover } from 'src/components/share-buttons/share-popover';
import { useEditor } from 'src/lib/editor';
import { imageOptimize } from 'src/service/image';

export function GeneralFeed({
  recentFeed,
  modal = false,
  isEdit,
  isDelete,
}: {
  recentFeed: any;
  modal?: boolean;
  isEdit?: (value: any) => void;
  isDelete?: (value: any) => void;
}) {
  const { user } = useContext(UserContext) as UserContextType;
  const commentRef = useRef<CommenterHandler>();
  const galleryModal = useModal();
  const [currAsset, setCurrAsset] = useState<any>();
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const toggleComment = () => setIsCommentOpen((comment) => !comment);
  const [newData, setNewData] = useState(recentFeed);
  const { mutateAsync: createComment } = useArticleCommentMutation(
    recentFeed.contentID
  );
  const { mutateAsync: createGeneral } = useGeneralCommentMutation();
  const { mutateAsync: updateComment } = useArticleEditCommentMutation(
    recentFeed.contentID
  );
  const { mutateAsync: enthuseComment } = useFeedCommentEnthuseMutation(
    recentFeed.contentID
  );

  const { mutateAsync: enthuse } = useAgoraFeedEnthuseMutation(
    newData,
    setNewData
  );

  const [currComments, setCurrComments] = useState([]);

  const checkIfContentParsed = (data) => {
    if (typeof data === 'string' && data) {
      // eslint-disable-next-line no-param-reassign
      return JSON.parse(data);
    }
    return data;
  };

  useEffect(() => {
    if (currComments.length > 0) {
      setIsCommentOpen(true);
    }
  }, [currComments]);

  useEffect(() => {
    if (recentFeed) {
      setNewData(recentFeed);
    }
  }, [recentFeed]);

  useEditor({
    editorId: `generalPost${recentFeed.contentID}`,
    uniqueId: `${recentFeed.contentID}`,
    data: checkIfContentParsed(recentFeed.content),
    areaType: 'EPISODE',
    imgType: 'episode_img',
    readOnly: true,
  });

  function getImgContent(img, length, index) {
    let height = 200;
    switch (length) {
      case 5:
        height = index % 3 === 0 ? 305 : 200;
        break;
      case 4:
        height = 200;
        break;
      case 3:
        height = index === 0 ? 300 : 200;
        break;
      case 2:
        height = 200;
        break;
      case 1:
        height = 200;
        break;
      default:
        height = 200;
        break;
    }
    return (
      <>
        <img
          src={imageOptimize(img.imgURL)}
          alt="img"
          className="cover-image-box cursor-pointer rounded-lg"
          style={{
            width: '100%',
            height: `${height}px`,
            maxWidth: '100%',
            margin: '0 !important',
            objectFit: 'cover',
          }}
        />
      </>
    );
  }

  async function enthuseCommentForwarder(data) {
    enthuseComment(data);
    const enthusedComment = [...currComments];
    enthusedComment.forEach((parentComment) => {
      if (data.objectParentId) {
        parentComment.child.forEach((comment) => {
          if (comment.id === data.objectId) {
            // eslint-disable-next-line no-param-reassign
            comment.isEnthused = !comment.isEnthused;

            // eslint-disable-next-line no-param-reassign
            comment.enthuseCount = !comment.isEnthused
              ? comment.enthuseCount - 1
              : comment.enthuseCount + 1;
          }
        });
      } else if (parentComment.id === data.objectId) {
        // eslint-disable-next-line no-param-reassign
        parentComment.isEnthused = !parentComment.isEnthused;

        // eslint-disable-next-line no-param-reassign
        parentComment.enthuseCount = !parentComment.isEnthused
          ? parentComment.enthuseCount - 1
          : parentComment.enthuseCount + 1;
      }
    });

    setCurrComments(enthusedComment);
  }

  async function handleComment({
    event,
    blockId,
    value = '',
    commentID,
  }: {
    event: any;
    blockId?: string;
    value?: string;
    commentID?: string;
  }) {
    let currValue = value;
    let currEvent = event;

    if (event?.value) {
      currValue = event.value;
      currEvent = event.event;
    }
    if (currValue !== '' && currEvent.key === 'Enter') {
      // if (event.currentTarget && event.currentTarget.value) {
      //   event.currentTarget.blur();
      //   // eslint-disable-next-line no-param-reassign
      //   event.currentTarget.value = '';
      // }

      if (!modal) {
        if (commentID) {
          updateComment({
            commentID,
            Assets: [],
            content: currValue,
            parentId: blockId,
          });
        } else {
          const currComement = await createGeneral({
            parentId: blockId,
            objectId: recentFeed.contentID,
            objectType: 'GENERAL POST',
            content: currValue,
          });

          const newComment = {
            assets: null,
            child: [],
            commenter: {
              firstName: user.firstName,
              id: user.id,
              lastName: user.lastName,
              profileImgURL: user.avatar,
              username: user.username,
            },
            content: currValue,
            createdAt: currComement.data.createdAt,
            enthuseCount: 0,
            id: currComement.data.id,
            isEnthused: false,
            parentID: '',
          };

          if (value !== '') {
            const subComment = [...currComments];
            subComment.forEach((sub) => {
              if (sub.id === blockId) {
                sub.child.push(newComment);
              }
            });
            setCurrComments([...subComment]);
          } else if (currComments.length === 0) {
            setCurrComments([newComment]);
          } else {
            setCurrComments([...currComments, newComment]);
          }
        }
      } else if (commentID) {
        updateComment({
          commentID,
          Assets: [],
          content: currValue,
          parentId: blockId,
        });
      } else {
        createComment({
          objectId: recentFeed.contentID,
          objectType: 'GENERAL POST',
          parentId: blockId,
          content: currValue,
        });
      }
    }
  }

  const deleteGeneral = () => {
    isDelete(recentFeed['contentID']);
  };

  const editGeneral = () => {
    isEdit(recentFeed);
  };

  const getGeneralComment = async () => {
    const comments = await fetchGeneralComment(recentFeed);
    setCurrComments(comments);
  };

  const fns = [
    {
      action: editGeneral,
      displayName: 'Edit',
      path: '',
      type: 'click',
    },
    {
      action: deleteGeneral,
      displayName: 'Delete',
      path: '',
      type: 'click',
    },
  ];

  const getTagLink = (data) => {
    return data.replace(/ /g, '-');
  };

  if (recentFeed.postType === 'STORY' || recentFeed.postType === 'MEDIA') {
    return <></>;
  }

  return (
    <div
      className={`p-4 sm:p-5 xl:p-8 relative rounded-2xl sm:rounded-3xl shadow-sm w-full text-gray-900 bg-white dark:bg-gray-900 dark:text-white ${
        modal ? 'my-0' : 'my-4 sm:my-6'
      }`}
    >
      <div className="">
        <div className="sm:flex relative">
          <div className="flex justify-start items-center">
            <Link href={`/${recentFeed?.owner?.username}`}>
              <div className="cursor-pointer">
                <Avatar
                  name={recentFeed?.owner?.username}
                  data-testid={recentFeed?.owner?.profileImgURL}
                  bg="$mode.400"
                  width={40}
                  height={40}
                  author={recentFeed?.owner}
                  src={recentFeed?.owner?.profileImgURL}
                  mr={1}
                />
              </div>
            </Link>
            <Link href={`/${recentFeed?.owner?.username}`}>
              <div className="sm:m-auto pl-1 cursor-pointer">
                <div className="font-medium text-xs sm:text-lg">
                  {getProfileFullNameOrUsername(recentFeed?.owner)}
                </div>
                <div className="font-medium text-xs text-gray-500">
                  Published a new folio in{' '}
                  <Link
                    href={`/feed/${getTagLink(
                      recentFeed.categories[0]
                    )}?tab=Community`}
                  >
                    <span className="text-blue-500">
                      {recentFeed.categories[0]}
                    </span>
                  </Link>
                </div>
              </div>
            </Link>
          </div>
          <div className="flex sm:absolute right-2 my-auto">
            <div className="m-auto text-sm w-full sm:w-auto">
              {dayHepler.localizedFormat(recentFeed?.createdAt, 'MMMM DD')} at{' '}
              {dayHepler.localizedFormat(recentFeed?.createdAt, 'h:mm A')}
            </div>
          </div>
        </div>
        <div>
          <div className="py-1">
            <div className="text-bold text-base sm:text-2xl xl:text-4xl">
              {recentFeed?.title}
            </div>
          </div>
          <div className="pb-2">
            {recentFeed?.content.blocks.length > 0 && (
              <>
                <Box
                  position="relative"
                  className="general-content-block"
                  id={`generalPost${recentFeed.contentID}`}
                  zIndex={1}
                  m="auto"
                  onPaste={(e) => e.stopPropagation()}
                />
                <style>{`
              .codex-editor--narrow .codex-editor__redactor {
                margin-right: 0;
              }
              `}</style>
              </>
            )}
          </div>
        </div>
        {/* {recentFeed?.tags?.length > 0 && (
          <div className="flex mb-1">
            {recentFeed?.tags?.map((tag) => (
              <Link key={`tag${tag}`} href={`/feed/${getTagLink(tag)}`}>
                <div className="bg-blue-50 text-blue-500 px-2 py-0.5 rounded-full text-sm mr-2 cursor-pointer">
                  {tag.substring(0, 1) === '#' ? tag : `#${tag}`}
                </div>
              </Link>
            ))}
          </div>
        )} */}
        {recentFeed?.tags?.length > 0 && (
          <div className="flex mb-1 w-auto overflow-hidden">
            {recentFeed?.tags?.map((tag, key) => {
              const mkey = `key_${key}`;
              return (
                <Link
                  prefetch={false}
                  key={mkey}
                  href={`/feed/${getTagLink(tag)}`}
                >
                  <div
                    key={mkey}
                    className="whitespace-nowrap bg-blue-50 text-blue-500 px-3 py-2 rounded-full text-xs font-medium mr-2 cursor-pointer overflow-ellipsis overflow-hidden"
                  >
                    {tag.substring(0, 1) === '#' ? tag : `#${tag}`}
                  </div>
                </Link>
              );
            })}
          </div>
        )}
        <div
          className="flex mx-auto"
          onClick={() => {
            setCurrAsset(recentFeed?.content?.uploadedFiles);
            galleryModal.onSelect(true);
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              setCurrAsset(recentFeed?.content?.uploadedFiles);
              galleryModal.onSelect(true);
            }
          }}
          role="button"
          tabIndex={0}
        >
          {recentFeed?.content?.uploadedFiles?.length > 3 && (
            <div
              style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: '10px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                {recentFeed?.content?.uploadedFiles?.map(
                  (generalImgs, getIndex) => {
                    if (getIndex % 3 === 0) {
                      return getImgContent(
                        generalImgs,
                        recentFeed?.content?.uploadedFiles?.length,
                        getIndex
                      );
                    }
                    return null;
                  }
                )}
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                {recentFeed?.content?.uploadedFiles?.map(
                  (generalImgs, getIndex) => {
                    if (getIndex % 3 !== 0) {
                      return getImgContent(
                        generalImgs,
                        recentFeed?.content?.uploadedFiles?.length,
                        getIndex
                      );
                    }
                    return null;
                  }
                )}
              </div>
            </div>
          )}
          {recentFeed?.content?.uploadedFiles?.length <= 3 && (
            <div
              style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '100%',
                gridGap: '10px',
              }}
            >
              {(recentFeed?.content?.uploadedFiles?.length === 1 ||
                recentFeed?.content?.uploadedFiles?.length === 3) &&
                getImgContent(
                  recentFeed?.content?.uploadedFiles[0],
                  recentFeed?.content?.uploadedFiles?.length,
                  0
                )}
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: '10px',
                }}
              >
                {recentFeed?.content?.uploadedFiles?.length === 3 && (
                  <>
                    {getImgContent(
                      recentFeed?.content?.uploadedFiles[1],
                      recentFeed?.content?.uploadedFiles?.length,
                      1
                    )}
                    {getImgContent(
                      recentFeed?.content?.uploadedFiles[2],
                      recentFeed?.content?.uploadedFiles?.length,
                      2
                    )}
                  </>
                )}
                {recentFeed?.content?.uploadedFiles?.length === 2 && (
                  <>
                    {getImgContent(
                      recentFeed?.content?.uploadedFiles[0],
                      recentFeed?.content?.uploadedFiles?.length,
                      0
                    )}
                    {getImgContent(
                      recentFeed?.content?.uploadedFiles[1],
                      recentFeed?.content?.uploadedFiles?.length,
                      1
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="mb-2" />
        {galleryModal.selected && (
          <GalleryModal
            {...galleryModal.modal}
            assets={currAsset}
            data={currAsset}
          />
        )}
      </div>
      <div className="">
        <div className="flex relative">
          <div className="flex">
            <div className="flex text-xs font-medium my-auto py-2">
              <EnthuseButton
                width="20px"
                height="20px"
                fontSize={20}
                minWidth="auto"
                svgClass="text-gray-500"
                isEnthuse={newData['isEnthused']}
                onEnthuse={() => {
                  enthuse({
                    objectId: recentFeed['contentID'],
                    objectType: 'GENERAL POST',
                    ownerID: recentFeed['owner']?.['userId'],
                  });
                }}
                likesCount={newData.enthuseCount}
              />
            </div>
            <div
              role="button"
              tabIndex={0}
              className="flex px-2 text-xs font-medium m-auto cursor-pointer p-2"
              onClick={() => {
                getGeneralComment();
                toggleComment();
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  getGeneralComment();
                  toggleComment();
                }
              }}
            >
              <SvgCommentsV2 fontSize="18px" />
              <span className="m-auto px-1 font-medium text-sm text-gray-500">
                {recentFeed?.commentCount}
              </span>
            </div>
          </div>
          <div className="hidden flex absolute right-2 h-auto">
            <Box
              _hover={{ background: 'rgba(0,0,0,0.15)' }}
              className="w-9 h-9 flex justify-center items-center rounded-full cursor-pointer"
            >
              <SharePopover
                hashtags={recentFeed['tags']}
                url={`${`${window.location.origin}/${recentFeed?.owner?.username}/${recentFeed?.contentID}`}`}
              />
            </Box>
          </div>
        </div>
        {isCommentOpen && (
          <div>
            <Commenter
              disabled={false}
              ref={commentRef}
              user={user}
              editorIndex={
                modal
                  ? `editorMainModal${recentFeed['contentID']}`
                  : `editorMain${recentFeed['contentID']}`
              }
              onKeyDown={(event) => handleComment({ event })}
            />
            {currComments?.length > 0 && (
              <ArticleComment
                isModal
                comments={currComments}
                onComment={({ keyEvent, blockId, value, commentID }) => {
                  const event = keyEvent;
                  handleComment({ event, blockId, value, commentID });
                }}
                onEnthuse={({
                  targetId,
                  targetParentId,
                  targetType,
                  isEnthused,
                  targetOwnerId,
                }) => {
                  enthuseCommentForwarder({
                    objectId: targetId,
                    objectParentId: targetParentId,
                    objectType: targetType,
                    enthuse: !isEnthused,
                    ownerID: targetOwnerId,
                  });
                }}
              />
            )}
          </div>
        )}
      </div>
      {recentFeed['owner'].userId === user.id && !modal && (
        <div className="absolute right-1 top-3 z-50">
          <Dropdowns
            className="text-sm font-medium"
            items={[fns]}
            dataID={
              recentFeed['source'] === 'REPPL'
                ? recentFeed['contentID']
                : recentFeed['objectID']
            }
            buttonComponent={
              <SvgIcSettings
                fontSize="20px"
                className="inline mr-2 align-text-bottom text-gray-900"
              />
            }
          />
        </div>
      )}
    </div>
  );
}
