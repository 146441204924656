export const SubmitAnEpisodeStartBody = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Join a Space
      </h1>
      <img
        src="/images/assets/tutorial/submit-an-episode-start.png"
        alt="submit an episode start"
        className="w-full"
      />
    </>
  );
};

export const SubmitAnEpisodeStartFooter = ({
  setNextModal,
}: {
  setNextModal?: any;
}) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Submit an Episode
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        This is the entry to unabridged creativity: start your creative journey
        here by clicking on Submit an Episode, then show the world your work!
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('submitAnEpisode1');
        }}
        className="px-9 py-2.5 mb-6 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Start
      </button>
    </>
  );
};
