import * as React from 'react';
import { Svg } from '../svg';

function SvgDrag({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8.5 4C7.675 4 7 4.675 7 5.5S7.675 7 8.5 7 10 6.325 10 5.5 9.325 4 8.5 4zm0 13c-.825 0-1.5.675-1.5 1.5S7.675 20 8.5 20s1.5-.675 1.5-1.5S9.325 17 8.5 17zm0-6.5c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5S10 12.825 10 12s-.675-1.5-1.5-1.5zM15.5 4c-.825 0-1.5.675-1.5 1.5S14.675 7 15.5 7 17 6.325 17 5.5 16.325 4 15.5 4zm0 13c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5 1.5-.675 1.5-1.5-.675-1.5-1.5-1.5zm0-6.5c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5S17 12.825 17 12s-.675-1.5-1.5-1.5z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgDrag);
export default ForwardRef;
