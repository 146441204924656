import * as React from 'react';
import { Svg } from '../svg';

function SvgStoryIcon({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#fff"
        stroke="#4788C7"
        strokeWidth={0.75}
        d="M1.125 3.375h27.75v23.25H1.125z"
      />
      <path fill="#98CCFD" d="M3.576 6.016h22.497v4.489H3.576z" />
      <path fill="#B6DCFE" d="M3.576 12.004h10.462v11.98H3.576z" />
      <path
        stroke="#4788C7"
        strokeWidth={0.75}
        d="M16.069 21.441h2.388M16.069 14.94h8.958M16.069 17.105h6.73M16.069 19.273h4.777"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgStoryIcon);
export default ForwardRef;
