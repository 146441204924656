/**
 * Internal dependencies
 */
import { useIsMobile } from 'src/hooks/use-is-mobile';

import Project from 'src/components/common/card/project';

function SearchResultSpaceLayout({ onClick, showViewAll, children }) {
  const isMobile = useIsMobile();

  return (
    <div>
      <div
        className={`relative flex text-gray-900 items-center justify-between ${
          isMobile && 'pb-4'
        }`}
      >
        <div className="text-base text-gray-500 font-semibold pt-6 pl-4">
          Projects
        </div>
        {showViewAll && (
          <div
            className="text-sm font-medium pt-6 pr-4 text-gray-700 cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyDown={onClick}
          >
            View all
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
function SearchResultSpace({ result, resultLimit = 4 }) {
  return (
    <div className="grid md:grid-cols-2 gap-8">
      {result.slice(0, resultLimit).map((data, key) => {
        const mkey = `caall_${key}`;
        return <Project data={data} key={mkey} />;
      })}
    </div>
  );
}

SearchResultSpace.Layout = SearchResultSpaceLayout;

export default SearchResultSpace;
