import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';

import authenticate, { AuthUserInfo } from 'src/app/auth/authenticate';
import redirect from 'src/lib/redirect';
import { API_V2, IS_PROD } from 'src/constants/env';
import { COOKIES_AUTH_TOKEN } from 'src/constants/cookies';
import { CacheKey } from 'src/constants/cache-key';
import { destroyCookie, saveCookie } from 'src/lib/cookies';
import { logger } from 'src/lib/logger';
import { removeAxiosToken, setAxiosToken } from 'src/lib/axios';

import {
  logout,
  signIn,
  signUp,
  codeVerify,
  codeConsume,
  resendEmail,
  changeEmail,
  activateUser,
} from './service';

function useAuth({ required }: { required?: (() => void) | boolean } = {}) {
  const logoutFn = useLogout();
  const logoutNeeded = !!required;

  const query = useQuery(CacheKey.user, {
    refetchOnWindowFocus: !!API_V2, // only enable if API is set
    refetchInterval: false, // refetch on 30s
    onSettled: (settledData: AuthUserInfo) => {
      if (settledData) {
        setSentryUser({
          email: settledData.email,
          id: settledData.id,
          username: settledData.username,
          firstName: settledData.firstName,
          lastName: settledData.lastName,
          userRole: settledData.userRole,
        });
      }
      if (logoutNeeded && !settledData) {
        logoutFn(false);
        if (typeof required === 'boolean') {
          redirect({ statusCode: 401, target: '/' });
        } else if (typeof required === 'function') {
          required();
        }
      }
    },
    queryFn: authenticate,
  });

  const isAuth = query.isFetched && query.data && query.isSuccess;
  const user = query.data;

  return {
    isAuth,
    user,
    ...query,
  };
}

export default useAuth;

export function useSignInMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(signIn, {
    onSuccess: ({ data: signInData, code }) => {
      if (code === 1000) {
        const { data } = signInData;
        setAuthToken(signInData.token);
        setSentryUser({
          id: data.id,
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        });
        queryClient.setQueryData<AuthUserInfo>(CacheKey.user, {
          id: data.id,
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          avatar: data.profileImgURL,
          isActivated: data.isActivated,
        });
      }
    },
  });

  return mutation;
}

export function setAuthToken(token: string) {
  setAxiosToken(token);
  saveCookie(COOKIES_AUTH_TOKEN, token, {
    httpOnly: false,
    sameSite: true,
    secure: IS_PROD,
    maxAge: 5 * 24 * 60 * 60,
  });
}

export function removeAuthToken() {
  removeAxiosToken();
  destroyCookie(COOKIES_AUTH_TOKEN);
}

export function useSignUp() {
  return useMutation(signUp, {});
}

export function useCodeVerify() {
  return useMutation(codeVerify, {});
}

export function useCodeConsume() {
  return useMutation(codeConsume, {});
}

export function useResendEmail() {
  return useMutation(resendEmail, {});
}

export function useChangeEmail() {
  return useMutation(changeEmail, {});
}

export function useActivate() {
  return useMutation(activateUser, {});
}

export function useLogout() {
  const queryClient = useQueryClient();

  return (reload = true) => {
    logout()
      .then(() => {
        unSetSentryUser();
        removeAuthToken();
        queryClient.cancelQueries(CacheKey.user);
        queryClient.clear();
        if (reload) {
          window.location.reload();
        }
      })
      .catch((err) => {
        logger.error(err);
      });
  };
}

export function useUserCacheUpdate() {
  const queryClient = useQueryClient();
  const update = (partialUser: Partial<AuthUserInfo>) =>
    queryClient.setQueryData<AuthUserInfo>(CacheKey.user, (u) => ({
      ...u,
      ...partialUser,
    }));

  return update;
}

export function setSentryUser(
  user: Omit<AuthUserInfo, 'avatar' | 'isActivated'>
) {
  Sentry.configureScope((scope) => {
    scope.setUser({
      username: user.username,
      email: user.email,
      id: String(user.id),
    });
  });
}

export function unSetSentryUser() {
  Sentry.configureScope((scope) => {
    scope.setUser(null);
  });
}
