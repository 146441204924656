import * as React from 'react';
import { Svg } from '../svg';

function SvgArrowDrop({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M.917 9.167L5.083 5 .917.833v8.334z" fill="#9B9FA4" />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowDrop);
export default ForwardRef;
