import * as React from 'react';
import { Svg } from '../svg';

function SvgTooltip({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8.5 14.667a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333z"
        stroke="#9B9FA4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.56 6a2 2 0 013.887.667c0 1.333-2 2-2 2M8.5 11.334h.007"
        stroke="#9B9FA4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTooltip);
export default ForwardRef;
