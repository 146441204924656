import Link from 'next/link';

export function CategoryCard({
  name,
  count,
}: {
  name: string;
  count: number | string;
}) {
  const currName = name.replace(/ /g, '-');
  const asPath = `/feed/${currName}`;
  const query: any = {
    tribe: currName,
    type: 'category',
  };
  const path = '/feed/[tribe]';
  const image = name.replace(' & ', '').replace(/\s/g, '');
  const newLink = {
    href: {
      pathname: path,
      query,
    },
    as: asPath,
  };
  return (
    <Link {...newLink}>
      <div
        style={{
          backgroundImage: `url(/images/assets/categories/onboarding-small/${image}.png)`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundColor: '#00000005',
          backgroundBlendMode: 'multiply',
        }}
        className="relative bg-gray-600 py-2 px-3 rounded w-48 h-11 sm:h-16 mr-2 cursor-pointer flex sm:block justify-center items-center"
      >
        <div className="font-bold text-sm py-1 z-50 text-white">{name}</div>
        {count && (
          <div className="text-xs z-50 text-white hidden sm:block">
            {count} folios
          </div>
        )}
      </div>
    </Link>
  );
}
