import * as React from 'react';
import { Svg } from '../svg';

function SvgError({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M50.681 7.812l17.76 17.757V50.68l-17.76 17.76H25.57L7.809 50.68V25.57l17.76-17.76H50.68v.003zM34.375 46.875v6.25h6.25v-6.25h-6.25zm0-25v18.75h6.25v-18.75h-6.25z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgError);
export default ForwardRef;
