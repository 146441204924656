import { languagesAll } from 'countries-list';

const languages = Object.keys(languagesAll)
  .map((lang) => languagesAll[lang])
  .map((name, lkey) => {
    return {
      id: lkey,
      value: name.name,
      displayName: name.native,
    };
  });
export default languages;
