import * as React from 'react';
import { Svg } from '../svg';

function Svg1CuPass({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.084 1.667H5a1.667 1.667 0 00-1.667 1.666v13.334A1.667 1.667 0 005 18.332h10a1.667 1.667 0 001.667-1.666V6.25l-4.584-4.583z"
        stroke="#4F5356"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.202 7.3c.668 0 1.247.11 1.738.33.499.22.88.535 1.144.946.264.41.396.898.396 1.463 0 .557-.132 1.045-.396 1.463-.264.41-.645.726-1.144.946-.491.22-1.07.33-1.738.33H8.464v1.507a.715.715 0 11-1.43 0V8.3a1 1 0 011-1h2.168zm-.066 4.268c.623 0 1.097-.132 1.42-.396.322-.264.483-.642.483-1.133 0-.492-.161-.87-.484-1.133-.322-.264-.796-.396-1.419-.396H8.464v3.058h1.672z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(Svg1CuPass);
export default ForwardRef;
