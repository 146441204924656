import {
  Box,
  BoxProps,
  IconButton,
  Link as CLink,
  ResponsiveValue,
  StackProps,
  SystemStyleObject,
  useBreakpointValue,
  useEventListener,
  useMediaQuery,
} from '@chakra-ui/react';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import type * as CSS from 'csstype';

import SvgArrowRight from 'src/components/icons/arrow-right';
import { useIsMobile } from 'src/hooks/use-is-mobile';

import { HorizontalScroller } from '../horizontal-scroller';

import styles from './link-tabs.module.scss';

const TRANSITION = '0s';
const cx = cn.bind(styles);

export const Tab = forwardRef<any, { active?: boolean } & BoxProps>(
  ({ active, sx, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        _hover={{
          textDecor: 'none',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        fontWeight="bold"
        display="inline-block"
        position="relative"
        verticalAlign="middle"
        whiteSpace="nowrap"
        userSelect="none"
        py="16px"
        color={active ? '$mode.900' : '$mode.400'}
        borderBottom={
          active ? '2px solid #242526' : '2px solid rgba(255, 255, 255, 0)'
        }
        transition={TRANSITION}
        cursor="pointer"
        {...props}
        sx={sx}
        mx={{ base: '1rem', md: '2rem' }}
      />
    );
  }
);

export function LinkTab({
  href,
  as,
  sx,
  display = 'inline-block',
  ...props
}: LinkProps & {
  children: ReactNode;
  sx?: SystemStyleObject;
  display?: ResponsiveValue<CSS.Property.Display>;
}) {
  // TODO: use modal parent location instead of router
  const router = useRouter();
  let active;

  if (router.pathname.includes('/[username]')) {
    active =
      router.pathname.replace(/(\[username\])/g, `${router.query.username}`) ===
      href;
  } else {
    active = router.asPath === href || router.asPath === as;
  }

  const textStyle = useBreakpointValue({
    base: 'sm',
    md: 'h4Light',
  });
  const isMobile = useIsMobile();
  const [isLessThan1280] = useMediaQuery('(max-width: 1280px)');

  return (
    <Link
      as={as}
      href={href}
      {...props}
      scroll={false}
      passHref
      prefetch={false}
    >
      <Tab
        display={display}
        as={CLink}
        textStyle={textStyle}
        active={active}
        sx={sx}
        h={!isLessThan1280 && '100%'}
        px="20px"
        pb="13px"
        fontWeight="600"
        fontSize={{ base: 'sm', md: 'md' }}
        lineHeight={isLessThan1280 && '18px'}
        className={cx(styles.borderTab, {
          [styles.borderTab_tablet]: isLessThan1280,
          [styles.borderTab_mobile]: isMobile,
        })}
      >
        {props.children}
      </Tab>
    </Link>
  );
}

export interface LinkTabsProps extends StackProps {
  children: ReactNode;
  arrowBg?: string;
  showArrow?: boolean;
}

export function LinkTabs({
  children,
  arrowBg = 'linear-gradient(to right, rgba(244, 247, 246, 0.7), #F4F7F6 100%)',
  showArrow = true,
  ...props
}: LinkTabsProps) {
  const isMobile = useIsMobile();
  const ref = useRef<HTMLDivElement>();
  const scrollerRef = useRef<HTMLDivElement>();
  const [scrollEnd, setScrollEnd] = useState(false);

  const handleScroll = useCallback((e) => {
    if (e.target instanceof HTMLElement) {
      if (e.target.scrollWidth - e.target.clientWidth <= e.target.scrollLeft) {
        setScrollEnd(true);
      } else {
        setScrollEnd(false);
      }
    }
  }, []);

  function handleArrowVisible() {
    if (!scrollerRef.current) return;

    if (scrollerRef.current.clientWidth > 450) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  }

  useEventListener('scroll', handleScroll, scrollerRef.current);

  useEffect(() => {
    handleArrowVisible();

    window.addEventListener('resize', handleArrowVisible);

    return () => window.addEventListener('resize', handleArrowVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HorizontalScroller
        ref={scrollerRef}
        position="relative"
        display="block"
        px={{ base: 0, md: 3 }}
        minHeight="35px"
        width="100%"
        spacing="0"
        {...props}
      >
        {children}
        <Box as="span" ref={ref} />
      </HorizontalScroller>
      {isMobile && showArrow && (
        <IconButton
          _focus={{
            boxShadow: 'none',
          }}
          transition="0s"
          opacity={scrollEnd ? 0 : 1}
          ml={0}
          pl="16px"
          bg={arrowBg}
          height="48px"
          colorScheme="black"
          fontSize="28px"
          right={0}
          pos="absolute"
          aria-label="tab-right"
          onClick={() => {
            ref.current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start',
            });
          }}
          icon={<SvgArrowRight color="$mode.900" />}
        />
      )}
    </>
  );
}
