import {
  Box,
  BoxProps,
  FlexProps,
  SystemStyleObject,
  useBreakpointValue,
  useTheme,
  Text,
  // Switch,
  Textarea,
  Input,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  UseDisclosureReturn,
  useDisclosure,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Image,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import React, {
  ReactNode,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { fetchPioneerCollected } from 'src/app/article/hooks/use-article-query';
import SvgClose from 'src/components/icons/close';
import SvgAlert from 'src/components/icons/alert-post';
import SvgChain from 'src/components/icons/chain';
import SvgExcerptsInfo from 'src/components/icons/excerpts-info';
import SvgArrowUp from 'src/components/icons/arrow-up';
import SvgArrowDown from 'src/components/icons/arrow-down';
import SvgNFTholder from 'src/components/icons/nft-placeholder';
import SvgAddItem2 from 'src/components/icons/add-item2';
import SvgStoryIcon from 'src/components/icons/story-icon';

import SvgEditorEdit from 'src/components/icons/editor-edit';
import SvgOrder from 'src/components/icons/order';
import SvgDelete from 'src/components/icons/delete';
import { CustomToast } from 'src/components/toast';

import { Theme } from 'src/theme';
import { useRouter } from 'next/router';
// import { imageOptimize } from 'src/service/image';
// import { useIsMobile } from 'src/hooks/use-is-mobile';
import ReactPlayer from 'react-player';
import { logger } from 'src/lib/logger/logger.server';

import cn from 'classnames';
import { useIsLg } from 'src/hooks/use-is-mobile';
import styles from 'src/lib/editor/Post_editor.module.scss';
import { CoverImageUploader } from 'src/app/article/components/cover-image-uploader';
import { PostEmbed } from 'src/components/embed/Post_embed';
import { EditorBlockComponent } from 'src/app/article/components/editing-block';
import WalletModal from 'src/components/modal/wallet-modal';
import AlphaExplainerModal from 'src/components/header/AlphaExplainerModal';
import { fetchMetaData } from 'src/hooks/use-alchemy';
import { useEditor } from './use-editor';
import { EDITOR_CONFIG } from './config';

export interface EditorProps extends FlexProps {
  title?: string;
  setTitle?: (value: any) => void;
  coverImgURL?: string;
  titleProps?: BoxProps;
  showTitle?: boolean;
  children?: ReactNode;
  readOnly?: boolean;
  editorSx?: SystemStyleObject;
  cover?: ReactNode;
  updateExcerpts?: (value: any) => void;
  excerpts?: any;
  requiredFieldsWarningStatus?: any;
  preview?: (value?: any) => void;
  settings?: () => void;
  savedDraft?: (value: any) => void;
  publish: (value: any) => void;
  content?: any;
}

export function HoverButton({
  readOnly,
  blockDelete,
  openReorder,
  block,
  openEditor,
}: {
  readOnly?: boolean;
  blockDelete?: (value: any) => void;
  openReorder?: () => void;
  block?: any;
  openEditor?: (value: any) => void;
}) {
  return (
    <div
      className={`${cn(styles.buttons, styles.buttons_layout)} buttons ${
        readOnly && 'hidden'
      }`}
    >
      {block.type === 'editor' && (
        <div className={cn(styles.buttons_item)}>
          <div
            aria-label="button"
            className={cn(styles.block3, styles.block4_layout)}
            role="button"
            tabIndex={0}
            onClick={() => openEditor(block.id)}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                openEditor(block.id);
              }
            }}
          >
            <SvgEditorEdit
              height="20px"
              width="20px"
              className="text-blue-500"
            />
          </div>
        </div>
      )}
      <div className={cn(styles.buttons_item)}>
        <div
          aria-label="button"
          className={cn(styles.block3, styles.block4_layout)}
          role="button"
          tabIndex={0}
          onClick={() => openReorder()}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              openReorder();
            }
          }}
        >
          <SvgOrder height="20px" width="20px" className="text-blue-500" />
        </div>
      </div>
      <div className={cn(styles.buttons_item)}>
        <div
          aria-label="button"
          className={cn(styles.block4, styles.block4_layout)}
          role="button"
          tabIndex={0}
          onClick={() => blockDelete(block.id)}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              blockDelete(block.id);
            }
          }}
        >
          <SvgDelete height="20px" width="20px" className="text-blue-500" />
        </div>
      </div>
      {/* <div className={cn(styles.buttons_item)}>
        <div
          aria-label="button"
          style={
            {
              '--src': `url(${
                require('public/assets_pxcode/6923808196a9a6d3169934569cb6b37e.svg')
                  .default
              })`,
            } as React.CSSProperties
          }
          className={cn(styles.block4, styles.block4_layout)}
          role="button"
          tabIndex={0}
          onClick={() => blockDelete(block.id)}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              blockDelete(block.id);
            }
          }}
        />
      </div> */}
    </div>
  );
}

export function BlockRenderer({
  blockData,
  setBlockData,
  reOrderBlock,
  editorSx,
  requiredFieldsWarningStatus,
  readOnly,
  openEditor,
}: {
  blockData: any;
  setBlockData?: (value: any) => void;
  reOrderBlock?: () => void;
  editorSx?: SystemStyleObject;
  requiredFieldsWarningStatus?: any;
  readOnly?: boolean;
  openEditor?: (value: any) => void;
}) {
  const deleteFromBlock = (id) => {
    const blockCopy = blockData.blocks.map((block) => {
      if (block.id !== id) return block;
      return null;
    });
    setBlockData({
      ...blockData,
      blocks: [...blockCopy.filter((n) => n)],
    });
  };
  const checkImageType = (data) => {
    if (!data.fileType || !data.fileType.endsWith('mp4' || 'gif')) {
      return true;
    }
    return false;
  };
  const renderBlockType = (block) => {
    if (block.type === 'embed') {
      return (
        <div
          style={{ marginBottom: `${blockData.settings.blockSpacing}px` }}
          className={`${cn(
            !readOnly && styles.block31,
            readOnly ? styles.blockRender_layout : styles.block31_layout,
            readOnly && 'py-4'
          )} ${!readOnly && 'block-border'}`}
        >
          <HoverButton
            readOnly={readOnly}
            blockDelete={(e) => deleteFromBlock(e)}
            block={block}
            openReorder={() => reOrderBlock()}
          />
          <div
            className={`${cn(
              styles.flex13,
              !readOnly && styles.flex13_layout
            )}`}
          >
            {/* <div className="ldsRing absolute inset-0 m-auto">
              <div />
              <div />
              <div />
              <div />
            </div> */}
            <div dangerouslySetInnerHTML={{ __html: block.children }} />
          </div>
        </div>
      );
    }
    if (block.type === 'nft') {
      return (
        <div
          style={{ marginBottom: `${blockData.settings.blockSpacing}px` }}
          className={`${cn(
            !readOnly && styles.block31,
            readOnly ? styles.blockEditor_layout : styles.block31_layout,
            readOnly && 'py-4'
          )} ${!readOnly && 'block-border'}`}
        >
          <HoverButton
            readOnly={readOnly}
            blockDelete={(e) => deleteFromBlock(e)}
            block={block}
            openReorder={() => reOrderBlock()}
          />
          <div
            className={`${cn(
              styles.block41,
              !readOnly && styles.block41_layout
            )}`}
          >
            {!block.data?.imgURL && (
              <Box position="relative" aspectRatio="16/9" height="100%">
                <div
                  style={{ height: '100%', aspectRatio: '1/1' }}
                  className={cn(
                    styles.image,
                    styles.image_layout,
                    'bg-gray-100'
                  )}
                >
                  <div
                    style={{ height: '56px', width: '56px' }}
                    className="rounded-full bg-white m-auto absolute inset-0"
                  >
                    <SvgNFTholder
                      style={{ height: 'inherit' }}
                      width="40px"
                      height="40px"
                      className="m-auto"
                    />
                  </div>
                </div>
              </Box>
            )}
            {block.data?.imgURL && checkImageType(block.data) && (
              <Image
                src={block.data?.imgURL}
                className={cn(styles.image, styles.image_layout)}
              />
            )}
            {block.data?.imgURL && !checkImageType(block.data) && (
              <Box
                position="relative"
                aspectRatio="16/9"
                height="100%"
                className={cn(styles.image, styles.image_layout)}
              >
                <ReactPlayer
                  height="inherit"
                  width="100%"
                  loop
                  playing
                  url={block.data?.imgURL}
                />
              </Box>
            )}

            <div className={cn(styles.block51, styles.block51_layout)}>
              <div className={cn(styles.flex10, styles.flex10_layout)}>
                <div className={cn(styles.flex10_item)}>
                  <div className={cn(styles.block234, styles.block234_layout)}>
                    <div className={cn(styles.block23_item)}>
                      <div
                        className={cn(styles.block61, styles.block61_layout)}
                      >
                        <div
                          className={cn(
                            styles.small_text_body1,
                            styles.small_text_body1_layout
                          )}
                        >
                          NFT
                        </div>
                      </div>
                    </div>
                    <div className={cn(styles.block23_spacer)} />
                    <div className={cn(styles.block23_item1)}>
                      <div
                        className={cn(styles.flex111, styles.flex111_layout)}
                      >
                        <h5
                          className={cn(
                            styles.highlights,
                            styles.highlights_layout,
                            'truncate'
                          )}
                        >
                          {block.data.name}
                        </h5>
                        <div
                          className={cn(
                            styles.small_text_body11,
                            styles.small_text_body11_layout
                          )}
                        >
                          {block.data.collectionName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cn(styles.flex10_spacer)} />
                <a
                  href={`https://etherscan.io/token/${block.data.contractAddress}?a=${block.data.tokenId}`}
                  target="_blank"
                  rel="noreferrer"
                  className="relative"
                  style={{ width: '20px' }}
                >
                  <SvgChain
                    className="absolute bottom-0"
                    width="20px"
                    height="20px"
                  />
                </a>
                <div className={cn(styles.flex10_item1, 'hidden')}>
                  <div className={cn(styles.flex91, styles.flex91_layout)}>
                    <div className={cn(styles.flex9_item)}>
                      <div
                        style={
                          {
                            '--src': `url(${
                              require('public/assets_pxcode/b9608b3e3be6961b150c0a7d41a806cc.svg')
                                .default
                            })`,
                          } as React.CSSProperties
                        }
                        className={cn(styles.image13, styles.image13_layout)}
                      />
                    </div>
                    <div className={cn(styles.flex9_spacer)} />
                    <div
                      className={cn(
                        styles.text_body16,
                        styles.text_body16_layout
                      )}
                    >
                      6.742
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (block.type === 'editor' && !block.data?.uploadedFiles) {
      return (
        <div
          style={{ marginBottom: `${blockData.settings.blockSpacing}px` }}
          className={cn(styles.flex12, styles.flex12_layout)}
        >
          <div
            className={`${cn(styles.blockBorder, styles.blockBorder_layout)} ${
              !readOnly && 'block-border'
            } parent-block`}
          >
            <div
              className={`${cn(
                readOnly ? styles.blockEditor : styles.flex19,
                readOnly ? styles.blockEditor_layout : styles.flex19_layout
              )}`}
            >
              <EditorBlock
                block={block}
                defaultBlock={blockData}
                setBlock={setBlockData}
                editorSx={editorSx}
                readOnly={readOnly}
                requiredFieldsWarningStatus={requiredFieldsWarningStatus}
              />
            </div>

            <HoverButton
              readOnly={readOnly}
              blockDelete={(e) => deleteFromBlock(e)}
              block={block}
              openReorder={() => reOrderBlock()}
              openEditor={openEditor}
            />
          </div>
        </div>
      );
    }
    if (
      block.type === 'media' ||
      (block.type === 'editor' && block.data.uploadedFiles)
    ) {
      const mediaTitle =
        block.type === 'media' ? block.carouselTitle : block.data.carouselTitle;

      const setMediaFiles = (media) => {
        const customBlock = { ...blockData };
        customBlock.blocks.forEach((bData, i) => {
          if (bData.id === block.id) {
            customBlock.blocks[i].uploadedFiles = [...media];
            if (media.length > 0) {
              customBlock.blocks[i].hasContent = true;
            } else {
              customBlock.blocks[i].hasContent = false;
            }
          }
        });
        if (setBlockData) {
          setBlockData(customBlock);
        }
      };

      const setMediaTitle = (title) => {
        const customBlock = { ...blockData };
        customBlock.blocks.forEach((bData, i) => {
          if (bData.id === block.id) {
            customBlock.blocks[i].carouselTitle = title;
          }
        });
        if (setBlockData) {
          setBlockData(customBlock);
        }
      };

      const blockMediaFiles =
        block.type === 'media' ? block.uploadedFiles : block.data.uploadedFiles;

      return (
        <>
          <div
            style={{ marginBottom: `${blockData.settings.blockSpacing}px` }}
            className={cn(styles.flex12, styles.flex12_layout)}
          >
            <div
              className={`${cn(
                !readOnly && styles.block5,
                readOnly ? styles.blockRender_layout : styles.block5_layout
              )} ${!readOnly && 'block-border'} parent-block`}
            >
              <HoverButton
                readOnly={readOnly}
                blockDelete={(e) => deleteFromBlock(e)}
                block={block}
                openReorder={() => reOrderBlock()}
              />
              <div style={{ zIndex: '3' }}>
                <CoverImageUploader
                  areaType="EPISODE"
                  imgType="episode_cover"
                  setMediaTitle={setMediaTitle}
                  mediaTitle={mediaTitle}
                  setUploadedFiles={setMediaFiles}
                  uploadedFiles={blockMediaFiles}
                  requiredFieldsWarningStatus={requiredFieldsWarningStatus}
                  isMedia
                  readOnly={readOnly}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      {blockData?.blocks?.map((block) => renderBlockType(block))}
      <GlobalEditorStyle full readOnly={readOnly} />
    </>
  );
}

export function Editor({
  title,
  children,
  coverImgURL,
  readOnly,
  setTitle,
  editorSx,
  titleProps,
  showTitle = true,
  cover,
  updateExcerpts,
  excerpts,
  requiredFieldsWarningStatus,
  preview,
  settings,
  savedDraft,
  publish,
  content,
}: EditorProps) {
  // usePlainTextPaste(titleRef?.current?.getRef());
  const { user } = useContext(UserContext) as UserContextType;
  const [titleLength, setTitleLength] = useState(0);
  const [useExcerpts, setExcerpts] = useState('');
  const [currBlockID, setCurrBlockID] = useState<any>();
  const toast = useToast();
  const postCover = useDisclosure();
  const blockSet = useDisclosure();
  const nftModal = useDisclosure();
  const walletModal = useDisclosure();
  const alphaExplainerModal = useDisclosure();
  const editingBlock = useDisclosure();
  const reOrderModal = useDisclosure();
  const embedModal = useDisclosure();
  const [currTitle, setCurrTitle] = useState(title || '');
  const [nftUrl, setNftUrl] = useState(null);
  const [currEmbedType, setCurrEmbedType] = useState<string>();
  const [enableEmbed, setEnableEmbed] = useState<boolean>(false);
  const [initialToolVisible, setInitialToolVisible] = useState<any>(false);
  const [toolsVisible, setToolVisible] = useState<boolean>(false);
  const [reorderBlocks, setReorderBlocks] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHolder, setIsHolder] = useState<boolean>(true);

  const [appended, setAppended] = useState<boolean>(false);
  const contentBodyRef = useRef();
  const isLessThan1200 = useIsLg();

  const NFTexceptions = {
    '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85': {
      url: 'https://metadata.ens.domains/mainnet/',
    },
  };

  const toolsList = [
    {
      type: 'editor',
      icon: require('public/assets_pxcode/rich_text.png').default,
      embedType: '',
    },
    {
      type: 'media',
      icon: require('public/assets_pxcode/0bd5393220b2a023fdca0d5ba21a20ed.png')
        .default,
      embedType: '',
    },
    {
      type: 'nft',
      icon: require('public/assets_pxcode/691a7af1c33e803f9f635d497a37326e.svg')
        .default,
      embedType: '',
    },
    {
      type: 'embed',
      icon: require('public/assets_pxcode/8da45cdbc9ae03a6363aa44ec0a750e3.png')
        .default,
      embedType: 'video',
    },
    {
      type: 'embed',
      icon: require('public/assets_pxcode/c0e458f0b9e9031cc06a4124d21fd0b0.svg')
        .default,
      embedType: 'document',
    },
    {
      type: 'embed',
      icon: require('public/assets_pxcode/5999add7895605a4710a3cc12e13a69a.png')
        .default,
      embedType: 'design',
    },
    {
      type: 'embed',
      icon: require('public/assets_pxcode/a9ab19c40e944ebfdae558c1080d1a46.png')
        .default,
      embedType: '3d',
    },
    {
      type: 'embed',
      icon: require('public/assets_pxcode/8b831de5c87924fcd2ce3afc1e9ed8cf.png')
        .default,
      embedType: 'others',
    },
  ];

  const [editBlock, setEditBlock] = useState<any>(
    content || {
      blocks: [] as any[],
      settings: {
        bgColor: '#ffffff',
        blockSpacing: '0',
      },
    }
  );

  useEffect(() => {
    if (currBlockID) {
      editingBlock.onOpen();
    }
  }, [currBlockID]);

  const scrollToBottom = () => {
    setAppended(false);
    const contentRef = contentBodyRef.current as any;
    contentRef?.scrollTo({
      top: contentRef.scrollHeight,
      behavior: 'smooth',
    });
  };

  const getLowestId = (list, property) => {
    let arrList = list;
    if (property !== 'default') {
      arrList = arrList.map((arr) => arr.split(property)[1]);
    }
    let lowestAvailable = 1;

    while (arrList.includes(lowestAvailable.toString())) {
      lowestAvailable += 1;
    }
    return lowestAvailable;
  };

  const appendEditorBlock = ({
    type,
    appendData,
    embedType,
  }: {
    type: string;
    appendData?: any;
    embedType?: string;
  }) => {
    const nameGenerate = (data) => {
      return `${type}_${data.toString()}`;
    };

    const needContent = () => {
      if (type === 'nft' || type === 'embed') return true;
      return false;
    };

    const customBlock = { ...editBlock };
    const lowestID = getLowestId(
      customBlock.blocks.map((blk) => blk.id.toString()),
      'default'
    ).toString();
    customBlock.blocks.push({
      id: lowestID,
      type,
      name: nameGenerate(
        getLowestId(
          customBlock.blocks
            .filter((blk) => blk.name.includes(type))
            .map((blks) => blks.name),
          '_'
        )
      ),
      data: type === 'nft' && appendData,
      children: type === 'embed' && appendData,
      uploadedFiles: [],
      embed_type: embedType || type,
      hasContent: needContent(),
    });
    setAppended(true);
    setEditBlock(customBlock);
    if (type === 'editor') {
      if (lowestID === currBlockID) {
        editingBlock.onOpen();
      } else {
        setCurrBlockID(lowestID);
      }
    }
  };

  function checkNFTUrl(data) {
    let splitData;
    if (
      data.startsWith('https://opensea.io') ||
      data.startsWith('https://looksrare.org')
    ) {
      splitData = data.match(/(0x.*)\/([0-9])*/g)[0].split('/');
    }
    if (data.startsWith('https://rarible.com')) {
      splitData = data.match(/(0x.*):([0-9])*/g)[0].split(':');
    }
    if (splitData && splitData[0]) {
      return { tokenContract: splitData[0], tokenId: splitData[1] };
    }
    return null;
  }

  const fetchNFTData = async (token) => {
    try {
      let nftData;
      await fetchMetaData(token.tokenContract, token.tokenId).then((e) => {
        nftData = e;
      });

      return nftData;
    } catch (err) {
      toast({
        position: 'bottom-right',
        duration: 3000,
        render: (r) => (
          <CustomToast
            icon="error"
            title="Encountered an error"
            description="Please try again in a few seconds!"
            {...r}
          />
        ),
      });
      return null;
    }
  };

  const getNFTimageURL = async (NFTfile) => {
    const newNFT = { ...NFTfile };
    if (NFTfile && NFTfile?.image) {
      if (NFTfile.image?.startsWith('ipfs://')) {
        if (NFTfile.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('ipfs://')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('ipfs://')[1]
          }`;
        }
      } else if (
        NFTfile.image?.startsWith('https://gateway.pinata.cloud/ipfs/')
      ) {
        if (NFTfile.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        }
      } else {
        newNFT.imgURL = NFTfile.image;
      }
    } else if (NFTfile && NFTfile.image_url) {
      if (NFTfile.image_url?.startsWith('ipfs://')) {
        if (NFTfile.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('ipfs://')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('ipfs://')[1]
          }`;
        }
      } else if (
        NFTfile.image_url?.startsWith('https://gateway.pinata.cloud/ipfs/')
      ) {
        if (NFTfile.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        }
      } else {
        newNFT.imgURL = NFTfile.image_url;
      }
    }
    if (newNFT.imgURL?.endsWith('.mp4')) {
      const fetchMIME = await fetchMIMEType(newNFT.imgURL);
      newNFT.fileType = fetchMIME;
    }

    return newNFT;
  };

  const fetchMIMEType = (uri) => {
    const proxyUrl = 'https://corsa.1tm.io/';
    const tokenRes = fetch(`${proxyUrl}${uri}`)
      .then((response) => {
        return response.headers.get('Content-Type');
      })
      .catch((err) => {
        logger.error(err);
        toast({
          position: 'bottom-right',
          duration: 3000,
          render: (r) => (
            <CustomToast
              icon="error"
              title="Encountered an error"
              description="Please try again in a few seconds!"
              {...r}
            />
          ),
        });
      });
    return tokenRes;
  };

  const getTokenUri = (uri) => {
    const proxyUrl = 'https://corsa.1tm.io/';
    const tokenRes = fetch(`${proxyUrl}${uri}`)
      .then((res) => res.json())
      .catch((err) => {
        logger.error(err);
        toast({
          position: 'bottom-right',
          duration: 3000,
          render: (r) => (
            <CustomToast
              icon="error"
              title="Encountered an error"
              description="Please try again in a few seconds!"
              {...r}
            />
          ),
        });
      });
    return tokenRes || null;
  };

  useEffect(() => {
    if (appended) {
      scrollToBottom();
    }
  }, [editBlock]);

  useEffect(() => {
    if (updateExcerpts) {
      updateExcerpts(useExcerpts);
    }
  }, [useExcerpts]);

  useEffect(() => {
    if (excerpts) {
      setExcerpts(excerpts);
    }
    if (title && title?.length !== 0) {
      setTitleLength(title.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pioneerCollected = async () => {
      try {
        const collected = await fetchPioneerCollected(user.walletAddress);
        if (collected) {
          setIsHolder(true);
        }
      } catch (e) {
        setIsHolder(false);
      }
    };

    if (user.walletAddress) {
      pioneerCollected();
    }
  }, [user]);

  useEffect(() => {
    if (currEmbedType) {
      embedModal.onOpen();
    }
  }, [enableEmbed]);

  useEffect(() => {
    if (initialToolVisible) {
      setInitialToolVisible((n) => !n);
    }
  }, [toolsVisible]);

  const padNum = (num) => {
    if (num < 10 && num !== 0) {
      return `0${num.toString()}`;
    }
    return num.toString();
  };

  const getTransformName = (block) => {
    const nameSplit = block.name.split('_');
    let nameBase;
    if (block.embed_type) {
      nameBase = `${block.embed_type[0].toUpperCase()}${block.embed_type.slice(
        1
      )} `;
    }
    if (nameSplit[0] === 'editor') {
      nameBase = 'Story ';
    }
    if (nameSplit[0] === 'media') {
      nameBase = 'Carousel ';
    }
    if (nameSplit[0] === 'nft') {
      nameBase = 'NFT ';
    }
    if (nameSplit[0] === 'embed' && !block.embed_type) {
      nameBase = 'Embed ';
    }
    return nameBase + padNum(nameSplit[1].toString());
  };

  const getIconType = (blocks) => {
    let icon;
    toolsList.forEach((tools) => {
      if (blocks.type === 'embed') {
        if (tools.embedType === blocks.embed_type) {
          icon = tools.icon;
        }
        if (!blocks.embed_type) {
          icon = tools.icon;
        }
      }
      if (tools.type === blocks.type) {
        icon = tools.icon;
      }
    });
    return icon;
  };

  const arrMoveFromTo = (arr, from, dir) => {
    const newArr = [...arr];
    if (newArr.length < 2) return null;
    if (from === 0 && dir === 'up') return null;
    if (from + 1 === newArr.length && dir === 'down') return null;

    let to;
    if (dir === 'up') {
      to = from - 1;
    } else {
      to = from + 1;
    }

    const items = newArr.splice(from, 1);
    newArr.splice(to, 0, items[0]);
    setReorderBlocks({
      blocks: [...newArr],
      settings: reorderBlocks.settings,
    });
  };
  const router = useRouter();
  const [hasHistory, setHasHistory] = useState(false);
  useEffect(() => {
    if (window.history.length > 2) {
      setHasHistory(true);
    }
  }, []);

  return (
    <div
      className={`post-editor div-offest header-gradient padding-offset ${cn(
        styles.block,
        styles.block_layout
      )}`}
    >
      <div
        style={
          {
            '--src': `url(${require('public/assets_pxcode/6017c07cc51f5b0749c989956dc01f29.png')})`,
          } as React.CSSProperties
        }
        className={cn(styles.block33, styles.block33_layout)}
      />
      <div className={cn(styles.block_item)}>
        <div
          style={
            {
              '--src': `url(${require('public/assets_pxcode/6017c07cc51f5b0749c989956dc01f29.png')})`,
            } as React.CSSProperties
          }
          className={`${cn(
            styles.block331,
            styles.block331_layout
          )} left-panel scroll-hidden`}
        >
          <div className={cn(styles.flex8, styles.flex8_layout)}>
            <px-posize
              x="39.5fr minmax(0px,238px) 39.5fr"
              y="25px minmax(0px, max-content) 0px"
              lg-x="66fr minmax(0px,118px) 66fr"
              lg-y="0px minmax(0px, max-content) 0px"
            >
              <div className={cn(styles.block24)}>
                <div className="flex m-auto text-sm text-gray-400 font-semibold text-center">
                  Content Blocks
                  <Tooltip
                    hasArrow
                    arrowSize={15}
                    label="Add content blocks to add rich media or embed content from other platforms in your folio."
                    placement="right"
                    bg="#ffffff"
                    color="$mode.700"
                    borderRadius="4px"
                    border="1px solid #E6E8E9"
                    maxWidth="230px"
                    fontSize="12px"
                    p="12px"
                  >
                    <SvgExcerptsInfo
                      className="ml-1"
                      height="20px"
                      width="20px"
                    />
                  </Tooltip>
                </div>

                {/* Middle Editor ToolbarItem */}
                <div className={cn(styles.flex9, styles.flex9_layout)}>
                  <div
                    className={cn(
                      styles.content_box11,
                      styles.content_box11_layout
                    )}
                  >
                    <div
                      className={cn(styles.content_box11_item)}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        appendEditorBlock({
                          type: 'editor',
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          appendEditorBlock({
                            type: 'editor',
                          });
                        }
                      }}
                    >
                      <div
                        className={cn(
                          styles.content_box10,
                          styles.content_box10_layout
                        )}
                      >
                        <div
                          className={cn(styles.block21, styles.block21_layout)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <SvgStoryIcon
                            className={cn(styles.icon1, styles.icon1_layout)}
                            style={{ width: '100%', height: '100%' }}
                          />
                        </div>

                        <div
                          className={cn(
                            styles.text_body1,
                            styles.text_body1_layout
                          )}
                        >
                          Story
                        </div>
                      </div>
                    </div>
                    <div className={cn(styles.content_box11_spacer)} />
                    <div className={cn(styles.content_box11_item1)}>
                      <div
                        style={
                          {
                            '--src': `url(${
                              require('public/assets_pxcode/0feef808cdd939fc3f8ae022c426f262.png')
                                .default
                            })`,
                          } as React.CSSProperties
                        }
                        className={cn(styles.image2, styles.image2_layout)}
                      />
                    </div>
                    <div className={cn(styles.content_box11_spacer)} />
                    <div
                      className={cn(styles.content_box11_item)}
                      role="button"
                      tabIndex={0}
                      onClick={() =>
                        appendEditorBlock({
                          type: 'media',
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          appendEditorBlock({
                            type: 'media',
                          });
                        }
                      }}
                    >
                      <div
                        className={cn(
                          styles.content_box,
                          styles.content_box_layout
                        )}
                      >
                        <div
                          className={cn(styles.block23, styles.block23_layout)}
                        >
                          <div
                            style={
                              {
                                '--src': `url(${
                                  require('public/assets_pxcode/0bd5393220b2a023fdca0d5ba21a20ed.png')
                                    .default
                                })`,
                              } as React.CSSProperties
                            }
                            className={cn(styles.icon1, styles.icon1_layout1)}
                          />
                        </div>

                        <div
                          className={`${cn(
                            styles.text_body1,
                            styles.text_body1_layout1
                          )} text_body1`}
                        >
                          Carousel
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={cn(
                      styles.content_box3,
                      styles.content_box3_layout
                    )}
                  >
                    <div
                      className={cn(styles.content_box3_item)}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        nftModal.onOpen();
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          nftModal.onOpen();
                        }
                      }}
                    >
                      <div
                        className={cn(
                          styles.content_box1,
                          styles.content_box1_layout
                        )}
                      >
                        <div
                          className={cn(styles.block26, styles.block26_layout)}
                        >
                          <div
                            style={
                              {
                                '--src': `url(${
                                  require('public/assets_pxcode/691a7af1c33e803f9f635d497a37326e.svg')
                                    .default
                                })`,
                              } as React.CSSProperties
                            }
                            className={cn(
                              styles.block27,
                              styles.block27_layout
                            )}
                          />
                        </div>

                        <div
                          className={cn(
                            styles.text_body1,
                            styles.text_body1_layout2
                          )}
                        >
                          NFT
                        </div>
                      </div>
                    </div>
                    <div className={cn(styles.content_box3_spacer)} />
                    <div className={cn(styles.content_box3_item1)}>
                      <div
                        style={
                          {
                            '--src': `url(${
                              require('public/assets_pxcode/0feef808cdd939fc3f8ae022c426f262.png')
                                .default
                            })`,
                          } as React.CSSProperties
                        }
                        className={cn(styles.image2, styles.image2_layout)}
                      />
                    </div>
                    <div className={cn(styles.content_box3_spacer)} />
                    <div
                      className={cn(styles.content_box3_item)}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setEnableEmbed((n) => !n);
                        setCurrEmbedType('video');
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          setEnableEmbed((n) => !n);
                          setCurrEmbedType('video');
                        }
                      }}
                    >
                      <div
                        className={cn(
                          styles.content_box2,
                          styles.content_box2_layout
                        )}
                      >
                        <div
                          className={cn(styles.block23, styles.block23_layout)}
                        >
                          <div
                            style={
                              {
                                '--src': `url(${
                                  require('public/assets_pxcode/8da45cdbc9ae03a6363aa44ec0a750e3.png')
                                    .default
                                })`,
                              } as React.CSSProperties
                            }
                            className={cn(styles.icon1, styles.icon1_layout2)}
                          />
                        </div>

                        <div
                          className={cn(
                            styles.text_body1,
                            styles.text_body1_layout3
                          )}
                        >
                          Video/Audio
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={cn(
                      styles.content_box6,
                      styles.content_box6_layout
                    )}
                  >
                    <div
                      className={cn(styles.content_box6_item)}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setEnableEmbed((n) => !n);
                        setCurrEmbedType('document');
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          setEnableEmbed((n) => !n);
                          setCurrEmbedType('document');
                        }
                      }}
                    >
                      <div
                        className={cn(
                          styles.content_box4,
                          styles.content_box4_layout
                        )}
                      >
                        <div
                          className={cn(styles.block30, styles.block30_layout)}
                        >
                          <div
                            style={
                              {
                                '--src': `url(${
                                  require('public/assets_pxcode/c0e458f0b9e9031cc06a4124d21fd0b0.svg')
                                    .default
                                })`,
                              } as React.CSSProperties
                            }
                            className={cn(
                              styles.image11,
                              styles.image11_layout
                            )}
                          />
                        </div>

                        <div
                          className={cn(
                            styles.text_body1,
                            styles.text_body11_layout
                          )}
                        >
                          Document
                        </div>
                      </div>
                    </div>
                    <div className={cn(styles.content_box6_spacer)} />
                    <div className={cn(styles.content_box6_item1)}>
                      <div
                        style={
                          {
                            '--src': `url(${
                              require('public/assets_pxcode/0feef808cdd939fc3f8ae022c426f262.png')
                                .default
                            })`,
                          } as React.CSSProperties
                        }
                        className={cn(styles.image2, styles.image2_layout)}
                      />
                    </div>
                    <div className={cn(styles.content_box6_spacer)} />
                    <div
                      className={cn(styles.content_box6_item)}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setEnableEmbed((n) => !n);
                        setCurrEmbedType('design');
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          setEnableEmbed((n) => !n);
                          setCurrEmbedType('design');
                        }
                      }}
                    >
                      <div
                        className={cn(
                          styles.content_box41,
                          styles.content_box41_layout
                        )}
                      >
                        <div
                          className={cn(styles.block23, styles.block23_layout)}
                        >
                          <div
                            style={
                              {
                                '--src': `url(${
                                  require('public/assets_pxcode/5999add7895605a4710a3cc12e13a69a.png')
                                    .default
                                })`,
                              } as React.CSSProperties
                            }
                            className={cn(styles.icon1, styles.icon1_layout3)}
                          />
                        </div>

                        <div
                          className={cn(
                            styles.small_text_body2,
                            styles.small_text_body2_layout
                          )}
                        >
                          Design/Prototype
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={cn(
                      styles.content_box31,
                      styles.content_box31_layout
                    )}
                  >
                    <div
                      className={cn(styles.content_box3_item)}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setEnableEmbed((n) => !n);
                        setCurrEmbedType('3d');
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          setEnableEmbed((n) => !n);
                          setCurrEmbedType('3d');
                        }
                      }}
                    >
                      <div
                        className={cn(
                          styles.content_box5,
                          styles.content_box5_layout
                        )}
                      >
                        <div
                          className={cn(styles.block23, styles.block23_layout)}
                        >
                          <div
                            style={
                              {
                                '--src': `url(${
                                  require('public/assets_pxcode/a9ab19c40e944ebfdae558c1080d1a46.png')
                                    .default
                                })`,
                              } as React.CSSProperties
                            }
                            className={cn(styles.icon1, styles.icon1_layout4)}
                          />
                        </div>

                        <div
                          className={cn(
                            styles.text_body1,
                            styles.text_body1_layout4
                          )}
                        >
                          3D
                        </div>
                      </div>
                    </div>
                    <div className={cn(styles.content_box3_spacer)} />
                    <div className={cn(styles.content_box3_item2)}>
                      <div
                        style={
                          {
                            '--src': `url(${
                              require('public/assets_pxcode/0feef808cdd939fc3f8ae022c426f262.png')
                                .default
                            })`,
                          } as React.CSSProperties
                        }
                        className={cn(styles.image2, styles.image2_layout)}
                      />
                    </div>
                    <div className={cn(styles.content_box3_spacer)} />
                    <div
                      className={cn(styles.content_box3_item)}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setEnableEmbed((n) => !n);
                        setCurrEmbedType('others');
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          setEnableEmbed((n) => !n);
                          setCurrEmbedType('others');
                        }
                      }}
                    >
                      <div
                        className={cn(
                          styles.content_box7,
                          styles.content_box7_layout
                        )}
                      >
                        <div
                          className={cn(styles.block23, styles.block23_layout)}
                        >
                          <div
                            style={
                              {
                                '--src': `url(${
                                  require('public/assets_pxcode/8b831de5c87924fcd2ce3afc1e9ed8cf.png')
                                    .default
                                })`,
                              } as React.CSSProperties
                            }
                            className={cn(styles.icon1, styles.icon1_layout5)}
                          />
                        </div>

                        <div
                          className={cn(
                            styles.text_body1,
                            styles.text_body1_layout5
                          )}
                        >
                          Others
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </px-posize>

            {/* Last Editor ToolbarItem */}
            <div className={cn(styles.flex8_item)}>
              <div className={cn(styles.flex2, styles.flex2_layout)}>
                <div className={cn(styles.flex2_item)}>
                  <div>
                    {/* Set Cover */}
                    {!isLessThan1200 && (
                      <div
                        style={{
                          borderRadius: '8px',
                          borderColor: `${
                            requiredFieldsWarningStatus?.coverImage
                              ? '#F63'
                              : 'inherit'
                          }`,
                        }}
                        className={cn(
                          styles.block231,
                          styles.block231_layout,
                          'flex-col w-full mb-6'
                        )}
                        role="button"
                        tabIndex={0}
                        onClick={() => postCover.onOpen()}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            postCover.onOpen();
                          }
                        }}
                      >
                        <div
                          style={{
                            width: '239px',
                            height: '116px',
                          }}
                          className="relative overflow-hidden"
                        >
                          {coverImgURL ? (
                            <Image src={coverImgURL} w="100%" />
                          ) : (
                            <div
                              aria-label="button"
                              style={
                                {
                                  '--src': `url(${
                                    require('public/assets_pxcode/f1340d370f3e75671fbb0bda3b9b39e8.svg')
                                      .default
                                  })`,
                                  height: '40px',
                                  width: '40px',
                                } as React.CSSProperties
                              }
                              className={cn(
                                styles.icon1,
                                styles.icon1_layout6,
                                'm-auto absolute inset-0'
                              )}
                            />
                          )}
                        </div>
                        <div className="border-t border-gray-200 bg-gray-50 py-3 text-center text-blue-500">
                          Set Cover
                        </div>
                      </div>
                    )}

                    {/* Settings */}
                    <div
                      style={{
                        borderRadius: '8px',
                        ...(isLessThan1200 && { width: '116px' }),
                      }}
                      className={cn(
                        styles.block231,
                        styles.block231_layout,
                        'w-full mb-12',
                        { 'flex-row': !isLessThan1200 }
                      )}
                    >
                      {isLessThan1200 && (
                        <div
                          className="w-full m-auto border-b border-gray-200 py-4"
                          role="button"
                          tabIndex={0}
                          onClick={() => postCover.onOpen()}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              postCover.onOpen();
                            }
                          }}
                        >
                          {coverImgURL ? (
                            <Image src={coverImgURL} w="100%" />
                          ) : (
                            <div
                              aria-label="button"
                              style={
                                {
                                  '--src': `url(${
                                    require('public/assets_pxcode/f1340d370f3e75671fbb0bda3b9b39e8.svg')
                                      .default
                                  })`,
                                } as React.CSSProperties
                              }
                              className={cn(
                                styles.icon1,
                                styles.icon1_layout7,
                                'm-auto'
                              )}
                            />
                          )}
                          <div className="text-blue-500 text-xs font-medium text-center pt-2.5">
                            Set Cover
                          </div>
                        </div>
                      )}
                      <div
                        className={`w-full m-auto border-gray-200 py-4 ${
                          isLessThan1200 ? 'border-b' : 'border-r'
                        }`}
                        role="button"
                        tabIndex={0}
                        onClick={() => settings()}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            settings();
                          }
                        }}
                      >
                        <div
                          aria-label="button"
                          style={
                            {
                              '--src': `url(${
                                require('public/assets_pxcode/0275e3ec54a0af3333d546ad07445862.svg')
                                  .default
                              })`,
                            } as React.CSSProperties
                          }
                          className={cn(
                            styles.icon1,
                            styles.icon1_layout7,
                            'm-auto'
                          )}
                        />
                        <div className="text-gray-700 text-xs font-medium text-center pt-2.5">
                          Settings
                        </div>
                      </div>
                      <div
                        className="w-full m-auto py-4"
                        role="button"
                        tabIndex={0}
                        onClick={() => blockSet.onOpen()}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            blockSet.onOpen();
                          }
                        }}
                      >
                        <div
                          aria-label="button"
                          style={
                            {
                              '--src': `url(${
                                require('public/assets_pxcode/ad082d8eadf653940f69ee6e35014a70.svg')
                                  .default
                              })`,
                            } as React.CSSProperties
                          }
                          className={cn(
                            styles.icon1,
                            styles.icon1_layout8,
                            'm-auto'
                          )}
                        />
                        <div className="text-gray-700 text-xs font-medium text-center pt-2.5">
                          Spacing
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className={cn(styles.flex3, styles.flex3_layout)}>
                    <div className={cn(styles.flex3_item)}>
                      <div className={cn(styles.group, styles.group_layout)}>
                        <div
                          className={cn(styles.block232, styles.block22_layout)}
                        >
                          <div
                            aria-label="button"
                            style={
                              {
                                '--src': `url(${
                                  require('public/assets_pxcode/f1340d370f3e75671fbb0bda3b9b39e8.svg')
                                    .default
                                })`,
                              } as React.CSSProperties
                            }
                            className={cn(styles.icon1, styles.icon1_layout6)}
                            role="button"
                            tabIndex={0}
                            onClick={() => postCover.onOpen()}
                            onKeyDown={(e) => {
                              if (e.code === 'Enter') {
                                postCover.onOpen();
                              }
                            }}
                          />
                        </div>
                        {requiredFieldsWarningStatus?.coverImage && (
                          <div
                            style={
                              {
                                '--src': `url(${
                                  require('public/assets_pxcode/e6f4d557d370b201d8bbf95a5707c0dd.svg')
                                    .default
                                })`,
                              } as React.CSSProperties
                            }
                            className={cn(styles.icon4, styles.icon4_layout)}
                          />
                        )}
                      </div>
                    </div>
                    <div className={cn(styles.flex3_spacer)} />
                    <div className={cn(styles.flex3_item)}>
                      <div
                        className={cn(styles.block231, styles.block231_layout)}
                      >
                        <div
                          aria-label="button"
                          style={
                            {
                              '--src': `url(${
                                require('public/assets_pxcode/0275e3ec54a0af3333d546ad07445862.svg')
                                  .default
                              })`,
                            } as React.CSSProperties
                          }
                          className={cn(styles.icon1, styles.icon1_layout7)}
                          role="button"
                          tabIndex={0}
                          onClick={() => settings()}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              settings();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className={cn(styles.flex3_spacer)} />
                    <div className={cn(styles.flex3_item)}>
                      <div
                        className={cn(styles.block232, styles.block232_layout)}
                      >
                        <div
                          aria-label="button"
                          style={
                            {
                              '--src': `url(${
                                require('public/assets_pxcode/ad082d8eadf653940f69ee6e35014a70.svg')
                                  .default
                              })`,
                            } as React.CSSProperties
                          }
                          className={cn(styles.icon1, styles.icon1_layout8)}
                          role="button"
                          tabIndex={0}
                          onClick={() => blockSet.onOpen()}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              blockSet.onOpen();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cn(styles.block_item1)}>
        <div className={cn(styles.block34, styles.block34_layout)}>
          <div
            style={
              {
                '--src': `url(${
                  require('public/assets_pxcode/cc0a3de20124af6d3d2943733362802c.png')
                    .default
                })`,
              } as React.CSSProperties
            }
            className={cn(styles.image1, styles.image1_layout)}
          />
          <div
            id="contentBody"
            style={{ backgroundColor: `${editBlock.settings.bgColor}` }}
            className={cn(styles.flex, styles.flex_layout)}
            ref={contentBodyRef}
          >
            <div
              style={{ paddingBottom: '40px' }}
              className={cn(styles.group, styles.group_layout2)}
            >
              <div
                style={{ backgroundColor: `${editBlock.settings.bgColor}` }}
                className={cn(styles.block1, styles.block1_layout)}
              >
                <div className={cn(styles.flex8, styles.flex8_layout1)}>
                  <px-posize
                    x="0px 780fr 0px"
                    y="1fr 70px 0px"
                    style={{ marginBottom: '36px' }}
                  >
                    {!readOnly && showTitle && (
                      <div className={cn(styles.flex11)}>
                        {requiredFieldsWarningStatus?.title && (
                          <div className="absolute inset-0 right-48 top-1 z-50 m-auto w-max">
                            <SvgAlert width="24px" height="24px" />
                          </div>
                        )}
                        <Input
                          fontSize="32px"
                          py="13px"
                          pr="7rem"
                          fontWeight="500"
                          color="$mode.700"
                          lineHeight="38px"
                          textAlign="left"
                          variant="unstyled"
                          value={currTitle}
                          placeholder="Write a title"
                          backgroundColor="unset"
                          className={cn(
                            styles.big_title,
                            styles.big_title_layout
                          )}
                          {...titleProps}
                          onChange={(e) => {
                            setCurrTitle(e.target.value);
                            setTitleLength(e.target.value.length);
                            setTitle(e.target.value);
                          }}
                          _focus={{
                            '::placeholder': {
                              color: 'rgba(0,0,0,0)',
                            },
                          }}
                          _hover={{
                            borderColor: requiredFieldsWarningStatus?.title
                              ? 'warning'
                              : '#D3D6D8',
                          }}
                          maxLength={70}
                        />
                        <div className={cn(styles.flex11_spacer)} />
                        <div
                          className={cn(
                            styles.text_body,
                            styles.text_body_layout
                          )}
                        >
                          {titleLength ? 70 - titleLength : 70}
                        </div>
                      </div>
                    )}
                  </px-posize>

                  <BlockRenderer
                    blockData={editBlock}
                    setBlockData={setEditBlock}
                    reOrderBlock={() => {
                      reOrderModal.onOpen();
                      setReorderBlocks(editBlock);
                    }}
                    editorSx={editorSx}
                    requiredFieldsWarningStatus={requiredFieldsWarningStatus}
                    readOnly={readOnly}
                    openEditor={(e) => {
                      if (e === currBlockID) {
                        editingBlock.onOpen();
                      } else {
                        setCurrBlockID(e);
                      }
                    }}
                  />
                  {!readOnly && (
                    <div
                      className={`${cn(
                        styles.block31,
                        styles.block31_layout
                      )} 'block-border'`}
                    >
                      <div
                        className={`${cn(styles.flex19, styles.flex19_layout)}`}
                        role="button"
                        tabIndex={0}
                        onClick={() => setInitialToolVisible(true)}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            setInitialToolVisible(true);
                          }
                        }}
                      >
                        <div className="text-gray-500 font-medium">
                          <div className="flex items-center justify-left">
                            <SvgAddItem2
                              className="mr-2 w-3 h-3"
                              style={{ height: '24px', width: '24px' }}
                            />
                            Add a content block
                          </div>
                        </div>
                        {initialToolVisible && (
                          <div
                            style={{
                              width: '498px',
                              height: '68px',
                              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06)',
                              borderRadius: '4px',
                              marginTop: '10px',
                              padding: '4px 0',
                            }}
                            className="border border-gray-200 flex"
                          >
                            {toolsList.map((tools) => (
                              <div
                                className="w-full"
                                role="button"
                                tabIndex={0}
                                onClick={() => {
                                  setToolVisible((n) => !n);
                                  if (tools.type === 'embed') {
                                    setEnableEmbed((n) => !n);
                                    setCurrEmbedType(tools.embedType);
                                  }
                                  if (tools.type === 'nft') {
                                    nftModal.onOpen();
                                  }
                                  if (tools.type === 'editor') {
                                    appendEditorBlock({
                                      type: tools.type,
                                    });
                                  }
                                  if (tools.type === 'media') {
                                    appendEditorBlock({
                                      type: tools.type,
                                    });
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.code === 'Enter') {
                                    if (tools.type === 'embed') {
                                      setEnableEmbed((n) => !n);
                                      setCurrEmbedType(tools.embedType);
                                    }
                                    if (tools.type === 'nft') {
                                      nftModal.onOpen();
                                    }
                                    if (tools.type === 'editor') {
                                      appendEditorBlock({
                                        type: tools.type,
                                      });
                                    }
                                    if (tools.type === 'media') {
                                      appendEditorBlock({
                                        type: tools.type,
                                      });
                                    }
                                    setToolVisible((n) => !n);
                                  }
                                }}
                              >
                                <div
                                  style={
                                    {
                                      '--src': `url(${tools.icon})`,
                                      width: '30px',
                                      height: '30px',
                                      margin: '15px',
                                      filter: 'saturate(0%)',
                                    } as React.CSSProperties
                                  }
                                  className={cn(
                                    styles.icon1,
                                    'hover:filter-none'
                                  )}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ height: 'auto' }}
            className={cn(styles.block42, styles.block42_layout)}
          >
            <div
              style={{
                background:
                  'linear-gradient(89.97deg, #E1EFFC 66.66%, rgba(225, 239, 252, 0) 99.98%)',
              }}
              className="token-gate md:flex p-3 justify-center"
            >
              <div className="font-medium text-xs text-gray-900 my-auto">
                Publish permits will be limited to 1TM Pioneer Pass and Ark Crew
                PFP holders only.
              </div>
              <div className="flex gap-2 font-medium text-xs pl-1 justify-center my-2 md:my-0">
                <div
                  role="button"
                  tabIndex={0}
                  className="text-gray-900 underline my-auto cursor-pointer whitespace-nowrap"
                  onClick={() => alphaExplainerModal.onOpen()}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      alphaExplainerModal.onOpen();
                    }
                  }}
                >
                  Learn more
                </div>
                {(!isHolder || !user.walletAddress) && (
                  <div
                    role="button"
                    tabIndex={0}
                    style={{ height: 'max-content' }}
                    className="text-blue-500 py-1.5 px-4 border border-blue-500 rounded-full cursor-pointer whitespace-nowrap my-auto"
                    onClick={() => {
                      if (user.walletAddress) {
                        window.open(
                          'https://1cu.1tm.io/mint/pioneer-pass#1',
                          '_blank'
                        );
                      } else {
                        walletModal.onOpen();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        if (user.walletAddress) {
                          window.open(
                            'https://1cu.1tm.io/mint/pioneer-pass#1',
                            '_blank'
                          );
                        } else {
                          walletModal.onOpen();
                        }
                      }
                    }}
                  >
                    {user.walletAddress ? 'Get your pass' : 'Connect to verify'}
                  </div>
                )}
              </div>
            </div>

            <div className="relative">
              <div
                style={
                  {
                    '--src': `url(${
                      require('public/assets_pxcode/18724fd5c4d48bbd8b7f32ccc51e9d50.png')
                        .default
                    })`,
                  } as React.CSSProperties
                }
                className={cn(styles.line, styles.line_layout)}
              />

              <div
                className={cn(styles.flex20, styles.flex20_layout)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Button
                  colorScheme="white"
                  style={{
                    width: '125px',
                    height: '32px',
                    fontSize: '0.875rem',
                  }}
                  borderRadius="20px"
                  border="1px"
                  borderColor="$mode.300"
                  bgColor="white"
                  color="#0278E4"
                  px="2rem"
                  fontWeight="600"
                  onClick={() => {
                    savedDraft(editBlock);
                  }}
                >
                  Save as draft
                </Button>

                <div className={cn(styles.flex191, styles.flex191_layout)}>
                  <div
                    className={cn(styles.flex1, styles.flex1_layout)}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      preview({ ...editBlock });
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        preview({ ...editBlock });
                      }
                    }}
                  >
                    <div className={cn(styles.flex_item)}>
                      <div
                        style={
                          {
                            '--src': `url(${
                              require('public/assets_pxcode/09ed988c4a13ded1d3097ab5fc642bf3.svg')
                                .default
                            })`,
                          } as React.CSSProperties
                        }
                        className={cn(styles.image23, styles.image23_layout)}
                      />
                    </div>
                    <div className={cn(styles.flex_spacer)} />
                    <h5
                      className={cn(
                        styles.highlights1,
                        styles.highlights1_layout
                      )}
                    >
                      Preview
                    </h5>
                  </div>

                  <div className={cn(styles.flex19_spacer)} />
                  <div className={cn(styles.block52, styles.block52_layout)}>
                    <Button
                      colorScheme="white"
                      style={{ width: '100%', height: '100%' }}
                      borderRadius="20px"
                      border="1px"
                      borderColor="$mode.300"
                      bgColor="white"
                      color="#0278E4"
                      px="2rem"
                      fontWeight="600"
                      onClick={() => {
                        if (hasHistory) {
                          router.back();
                        } else {
                          router.push('../');
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={cn(styles.flex19_spacer1)} />
                  <div className={cn(styles.block53, styles.block53_layout)}>
                    <Button
                      // disabled={!(user.walletAddress && isHolder)}
                      style={{ width: '100%', height: '100%' }}
                      px="2rem"
                      borderRadius="20px"
                      fontWeight="600"
                      onClick={() => {
                        publish(editBlock);
                      }}
                    >
                      Publish
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
      <EditorBlockComponent
        modal={editingBlock}
        type="drawer"
        blockData={editBlock.blocks}
        blockID={currBlockID}
        setBlockData={(e) => {
          setEditBlock({
            ...editBlock,
            blocks: e,
          });
        }}
      />
      <BlockSettingsModal
        modal={blockSet}
        blockSettings={editBlock}
        setSettings={setEditBlock}
      />
      <PostCoverModal
        modal={postCover}
        cover={cover}
        title={currTitle}
        setCurrTitle={(e) => setCurrTitle(e)}
        setTitle={(e) => setTitle(e)}
        titleLength={titleLength}
        setTitleLength={(e) => setTitleLength(e)}
        useExcerpts={useExcerpts}
        onOk={() => setTitle(currTitle)}
        setExcerpts={(e) => setExcerpts(e)}
        requiredFieldsWarningStatus={requiredFieldsWarningStatus}
      />
      <PostEmbed
        modal={embedModal}
        onEmbed={(e) => {
          appendEditorBlock({
            type: 'embed',
            appendData: e.html,
            embedType: e.type,
          });
        }}
        type={currEmbedType}
      />
      <WalletModal {...walletModal} />
      <AlphaExplainerModal
        closeModal={alphaExplainerModal.onClose}
        isOpen={alphaExplainerModal.isOpen}
      />
      <Modal
        isOpen={nftModal.isOpen}
        onClose={() => {
          setIsLoading(false);
          nftModal.onClose();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          pt="40px"
          pb="24px"
          maxW="480px"
          borderRadius="24px"
          className="relative"
        >
          <button
            type="button"
            className="absolute top-6 right-6"
            onClick={() => {
              setIsLoading(false);
              nftModal.onClose();
            }}
          >
            <img
              src={
                require('public/assets_pxcode/6fb52961dc5b298c6e539ee7e9d851e6.png')
                  .default
              }
              alt="alt text"
              style={{ height: '14px' }}
              className={cn(styles.cover_group, styles.cover_group_layout)}
            />
          </button>
          <div className="text-gray-900 font-semibold text-xl pb-8 px-10">
            Add NFTs to your folio
          </div>
          <ModalBody px="40px" py={0}>
            <div className="text-xs font-medium text-gray-700 pb-4">
              <div>
                Now we support any NFT minted on OpenSea, LooksRare and Rarible.
              </div>
            </div>
            <Textarea
              overflow="hidden"
              className="description-scrollbar"
              placeholder="Paste NFT url here"
              fontSize="sm"
              h="100%"
              py="11px"
              pl="16px"
              pr="25px"
              minH="40px"
              maxH="40px"
              fontWeight="500"
              lineHeight="19px"
              border="1px solid #BBC0C5"
              borderRadius="4px"
              resize="none"
              _focus={{
                '::placeholder': {
                  color: 'rgba(0,0,0,0)',
                },
              }}
              onChange={(e) => {
                setNftUrl(e.target.value);
              }}
            />
          </ModalBody>
          <div className="relative border-t mt-10">
            <Box w="50%" className="justify-end ml-auto pt-6 mr-6">
              <Button
                width="50%"
                colorScheme="#9B9FA4"
                color="#9B9FA4"
                variant="ghost"
                onClick={() => {
                  nftModal.onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                width="50%"
                bgColor={isLoading ? '#BBC0C5' : '#0278E4'}
                _hover={{
                  bgColor: isLoading ? '#BBC0C5' : '#0278E4',
                }}
                rounded="ellipse"
                onClick={async () => {
                  setIsLoading(true);
                  if (checkNFTUrl(nftUrl)) {
                    const fetchedNFT = await fetchNFTData(checkNFTUrl(nftUrl));
                    let newFile: any = {
                      type: 'nft',
                      contractAddress: checkNFTUrl(nftUrl).tokenContract,
                      tokenId: checkNFTUrl(nftUrl).tokenId,
                      collectionName: fetchedNFT.contractMetadata?.name
                        ? fetchedNFT.contractMetadata?.name
                        : '',
                      contractType: fetchedNFT.contractMetadata?.tokenType
                        ? fetchedNFT.contractMetadata?.tokenType
                        : '',
                      ...fetchedNFT.metadata,
                    };

                    if (!NFTexceptions[newFile.contractAddress]) {
                      if (!fetchedNFT.metadata) {
                        let nftData;
                        let fromURI;
                        if (
                          fetchedNFT?.tokenUri.raw &&
                          fetchedNFT?.tokenUri.raw?.includes(
                            'data:application/json;utf8'
                          )
                        ) {
                          fromURI = JSON.parse(
                            fetchedNFT.tokenUri.raw.split(
                              'data:application/json;utf8,'
                            )[1]
                          );
                          nftData = await getNFTimageURL(fromURI);
                        } else {
                          fromURI = await getTokenUri(fetchedNFT.tokenUri.raw);
                          nftData = await getNFTimageURL(fromURI);
                        }
                        newFile = {
                          ...newFile,
                          ...fromURI,
                          ...nftData,
                        };
                      } else {
                        const nftData = await getNFTimageURL(newFile);
                        newFile = { ...newFile, ...nftData };
                      }
                    } else {
                      const fromURI = await getTokenUri(
                        `${NFTexceptions[newFile.contractAddress]?.url}${
                          newFile.contractAddress
                        }/${newFile.tokenId}/`
                      );
                      const nftData = await getNFTimageURL(fromURI);
                      newFile = {
                        ...newFile,
                        ...fromURI,
                        ...nftData,
                      };
                    }

                    if (!newFile.name) {
                      newFile.name = `${newFile.collectionName} #${newFile.tokenId}`;
                    }
                    setIsLoading(false);
                    nftModal.onClose();
                    appendEditorBlock({ type: 'nft', appendData: newFile });
                  }
                }}
              >
                {isLoading ? 'Loading...' : 'Add NFT'}
              </Button>
            </Box>
          </div>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={reOrderModal.isOpen}
        onClose={reOrderModal.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          pt="40px"
          pb="24px"
          maxW="640px"
          maxH="calc(100vh * 0.8)"
          borderRadius="24px"
          className="relative"
        >
          <button
            type="button"
            className="absolute top-5 right-6"
            onClick={() => reOrderModal.onClose()}
          >
            <SvgClose fontSize="22px" />
          </button>
          <div className="text-gray-900 font-semibold text-xl pb-8 px-10">
            Reorder blocks
          </div>
          <ModalBody px="40px" py={0}>
            <div className="text-xs font-medium text-gray-700 pb-4">
              <div>Use the arrows to reorder the Blocks</div>
            </div>
            <div
              style={{ maxHeight: 'calc(100vh * 0.5' }}
              className="overflow-auto"
            >
              {reorderBlocks?.blocks?.map((blocks, blockIndex) => (
                <div
                  style={{
                    width: '560px',
                    height: '84px',
                    borderRadius: '4px',
                  }}
                  className="overflow-hidden border border-gray-200 flex bg-gray-50 mb-3"
                >
                  <div>
                    <div
                      className={`relative ${
                        blockIndex === 0
                          ? 'bg-gray-50 cursor-not-allowed'
                          : 'bg-white'
                      }`}
                      style={{ height: '42px', width: '40px' }}
                      role="button"
                      tabIndex={0}
                      onClick={() =>
                        arrMoveFromTo(reorderBlocks.blocks, blockIndex, 'up')
                      }
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          arrMoveFromTo(reorderBlocks.blocks, blockIndex, 'up');
                        }
                      }}
                    >
                      <SvgArrowUp
                        height="24px"
                        width="24px"
                        className={`absolute inset-0 m-auto ${
                          blockIndex === 0 ? 'text-gray-300' : 'text-gray-500'
                        }`}
                      />
                    </div>
                    <div
                      style={{ height: '42px', width: '40px' }}
                      className={`border-t border-gray-200 relative ${
                        blockIndex + 1 === reorderBlocks.blocks.length
                          ? 'bg-gray-50 cursor-not-allowed'
                          : 'bg-white'
                      }`}
                      role="button"
                      tabIndex={0}
                      onClick={() =>
                        arrMoveFromTo(reorderBlocks.blocks, blockIndex, 'down')
                      }
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          arrMoveFromTo(
                            reorderBlocks.blocks,
                            blockIndex,
                            'down'
                          );
                        }
                      }}
                    >
                      <SvgArrowDown
                        height="24px"
                        width="24px"
                        className={`absolute inset-0 m-auto ${
                          blockIndex + 1 === reorderBlocks.blocks.length
                            ? 'text-gray-300'
                            : 'text-gray-500'
                        }`}
                      />
                    </div>
                  </div>
                  <div className="flex border-l border-gray-200 px-6 py-3">
                    <div
                      style={{ margin: 'auto' }}
                      className={cn(styles.block23, styles.block23_layout)}
                    >
                      <div
                        style={
                          {
                            '--src': `url(${getIconType(blocks)})`,
                            width: '30px',
                            height: '30px',
                            margin: '15px',
                          } as React.CSSProperties
                        }
                        className={cn(styles.icon1)}
                      />
                    </div>
                    <div className="m-auto font-medium text-gray-700 text-sm ml-3">
                      {getTransformName(blocks)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
          <ModalFooter style={{ padding: '0' }}>
            <div className="relative border-t w-full bg-white">
              <Box className="justify-end ml-auto pt-6 mr-6 w-min flex">
                <Button
                  width="auto"
                  colorScheme="#9B9FA4"
                  color="#9B9FA4"
                  variant="ghost"
                  onClick={() => {
                    reOrderModal.onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  width="auto"
                  rounded="ellipse"
                  onClick={() => {
                    setEditBlock({ ...reorderBlocks });
                    reOrderModal.onClose();
                  }}
                >
                  Update order
                </Button>
              </Box>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div className={cn(styles.block_spacer)} />
      <GlobalEditorStyle full />
    </div>
  );
}

function PostCoverModal({
  modal,
  title,
  setCurrTitle,
  titleLength,
  setTitleLength,
  cancelBtnText = 'Cancel',
  useExcerpts,
  setExcerpts,
  requiredFieldsWarningStatus,
  onOk,
  cover,
  setTitle,
}: {
  modal: UseDisclosureReturn;
  title?: string;
  setCurrTitle?: (value: any) => void;
  titleLength?: any;
  setTitleLength?: (value: any) => void;
  cancelBtnText?: string;
  onOk?: () => void;
  cover?: ReactNode;
  setExcerpts?: (value: any) => void;
  useExcerpts?: any;
  requiredFieldsWarningStatus?: any;
  setTitle?: any;
}) {
  return (
    <Modal isCentered size="2xl" {...modal} autoFocus={false}>
      <ModalOverlay>
        <ModalContent
          bg="$mode.50"
          pt="40px"
          pb="24px"
          w="480px"
          borderRadius="24px"
        >
          <button
            type="button"
            className="absolute top-5 right-6"
            onClick={() => modal.onClose()}
          >
            <SvgClose fontSize="22px" />
          </button>
          <div>
            <div className="px-8 font-semibold text-2xl text-gray-900 pb-6">
              Set Folio Cover
            </div>
            <Box w="100%" h="auto" background="$mode.50">
              {cover}
            </Box>
            <div className="p-8">
              <div className="flex items-center">
                <div className="w-2/5 relative text-sm text-gray-600 font-medium">
                  *Title
                </div>
                <Box position="relative" h="62px" w="100%">
                  <Text
                    fontSize="12px"
                    textStyle="labelLight"
                    color={
                      (titleLength !== 70 && titleLength > 0) ||
                      !requiredFieldsWarningStatus?.title
                        ? '$mode.500'
                        : 'warning'
                    }
                    position="absolute"
                    right="16px"
                    bottom="10px"
                    zIndex="999"
                  >
                    {titleLength ? 70 - titleLength : 70}
                  </Text>
                  <Textarea
                    className="description-scrollbar"
                    placeholder="Write a title"
                    fontSize="sm"
                    h="100%"
                    py="11px"
                    pl="16px"
                    pr="30px"
                    minHeight="62px"
                    fontWeight="500"
                    lineHeight="19px"
                    border="1px solid"
                    borderColor={
                      (titleLength !== 70 && titleLength > 0) ||
                      !requiredFieldsWarningStatus?.title
                        ? '#D3D6D8'
                        : 'warning'
                    }
                    borderRadius="4px"
                    resize="none"
                    _focus={{
                      border: '1px solid #E6E8E9',
                      borderColor:
                        (titleLength !== 70 && titleLength > 0) ||
                        !requiredFieldsWarningStatus?.title
                          ? '$mode.200'
                          : 'warning',
                      '::placeholder': {
                        color: 'rgba(0,0,0,0)',
                      },
                    }}
                    value={title}
                    onChange={(e) => {
                      setCurrTitle((e.target as HTMLTextAreaElement).value);
                      setTitleLength(
                        (e.target as HTMLTextAreaElement).value.length
                      );
                      setTitle((e.target as HTMLTextAreaElement).value);
                    }}
                    maxLength={70}
                  />
                </Box>
              </div>
              <div className="flex mt-6 items-center">
                <div className="w-2/5 relative text-sm text-gray-600 font-medium">
                  Excerpt
                </div>
                <Box position="relative" h="102px" w="100%">
                  <Text
                    fontSize="12px"
                    textStyle="labelLight"
                    color="$mode.500"
                    position="absolute"
                    right="16px"
                    bottom="10px"
                    zIndex="999"
                  >
                    {260 - useExcerpts.length}
                  </Text>
                  <Textarea
                    className="description-scrollbar"
                    placeholder="Write a summary of your story"
                    fontSize="sm"
                    h="100%"
                    py="11px"
                    pl="16px"
                    pr="30px"
                    fontWeight="500"
                    lineHeight="19px"
                    border="1px solid"
                    borderColor="$mode.200"
                    borderRadius="4px"
                    resize="none"
                    _focus={{
                      border: '1px solid #E6E8E9',
                      borderColor: '$mode.200',
                      '::placeholder': {
                        color: 'rgba(0,0,0,0)',
                      },
                    }}
                    value={useExcerpts}
                    onChange={(e) => {
                      setExcerpts((e.target as HTMLTextAreaElement).value);
                    }}
                    maxLength={260}
                  />
                </Box>
              </div>
            </div>
            <div className="relative border-t">
              <Box w="50%" className="justify-end ml-auto pt-6 mr-10">
                <Button
                  width="50%"
                  colorScheme="#9B9FA4"
                  color="#9B9FA4"
                  variant="ghost"
                  onClick={() => {
                    modal.onClose();
                  }}
                >
                  {cancelBtnText}
                </Button>
                <Button
                  width="50%"
                  rounded="ellipse"
                  onClick={() => {
                    onOk();
                    modal.onClose();
                  }}
                >
                  Save
                </Button>
              </Box>
            </div>
          </div>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

function EditorBlock({
  readOnly,
  editorSx,
  requiredFieldsWarningStatus,
  block,
  setBlock,
  defaultBlock,
}: {
  readOnly?: boolean;
  editorSx?: SystemStyleObject;
  requiredFieldsWarningStatus?: any;
  block: any;
  setBlock?: (value: any) => void;
  defaultBlock?: any;
}) {
  const isXL = useBreakpointValue({
    xxl: true,
    base: false,
  });

  const [editorChange, setEditorChange] = useState(true);

  // const getParentNode = (target) => {
  //   let nodeClass = target;

  //   while (!nodeClass.classList.contains('parent-block')) {
  //     nodeClass = nodeClass.parentNode;
  //   }

  //   return nodeClass;
  // }

  useEffect(() => {
    autoSaveOnChange();
  }, [editorChange]);

  useEffect(() => {
    if (editor) {
      renderData();
    }
  }, [block.data]);

  const { editor } = useEditor({
    editorId: `editorBlock${block.id}${readOnly ? 'read' : ''}`,
    uniqueId: `editorBlock${block.id}${readOnly ? 'read' : ''}`,
    data: block.data,
    areaType: 'EPISODE',
    imgType: 'episode_img',
    placeholder: 'Start your story here',
    editorOnChange: () => {
      setEditorChange((edit) => !edit);
    },
    readOnly: true, // readOnly || false,
  });

  const renderData = async () => {
    await editor.render(block?.data);
  };

  const autoSaveOnChange = async () => {
    if (editor && !readOnly) {
      await editor.isReady;

      const content = await editor.save();
      const customBlock = {
        ...defaultBlock,
      };
      customBlock.blocks.forEach((blockData, i) => {
        if (blockData.id === block.id) {
          customBlock.blocks[i].data = content;
        }
        if (content.blocks.length > 0) {
          customBlock.blocks[i].hasContent = true;
        }
        if (content.blocks.length === 0) {
          customBlock.blocks[i].hasContent = false;
        }
      });
      setBlock(customBlock);
    }
  };

  return (
    <Box
      bg="white"
      className={readOnly ? 'read-only' : ''}
      position="relative"
      id={`editorBlock${block.id}${readOnly ? 'read' : ''}`}
      zIndex={0}
      onPaste={(e) => e.stopPropagation()}
      maxWidth={EDITOR_CONFIG.maxWidth}
      sx={editorSx}
      boxSizing="content-box"
      // onFocus={(e) => getParentNode(e.target).classList.add('active-block')}
      // onBlur={(e) => getParentNode(e.target).classList.remove('active-block')}
    >
      {requiredFieldsWarningStatus?.content && (
        <div
          style={{ left: `${isXL ? '5.6rem' : '2rem'}` }}
          className="absolute top-0 z-50 m-auto w-max"
        >
          <SvgAlert width="24px" height="24px" />
        </div>
      )}
    </Box>
  );
}

function BlockSettingsModal({
  modal,
  blockSettings,
  setSettings,
}: {
  modal: UseDisclosureReturn;
  blockSettings?: any;
  setSettings?: (value: any) => void;
}) {
  const newBlockSettings = (space) => {
    setSettings({
      ...blockSettings,
      settings: {
        bgColor: blockSettings.settings.bgColor,
        blockSpacing: space > 100 ? 100 : space,
      },
    });
  };

  return (
    <Modal isCentered size="2xl" {...modal} autoFocus={false}>
      <ModalOverlay>
        <ModalContent
          bg="$mode.50"
          pt="40px"
          pb="24px"
          w="480px"
          borderRadius="24px"
          className="relative"
        >
          <button
            type="button"
            className="absolute top-5 right-6"
            onClick={() => modal.onClose()}
          >
            <SvgClose fontSize="22px" />
          </button>
          <div>
            <div className="px-8 font-semibold text-2xl text-gray-900 pb-6">
              Post layout
            </div>
            <div className="flex px-6 items-center">
              <div className="px-2 font-medium text-sm text-gray-700">
                Content spacing
              </div>
              <div style={{ width: '154px' }} className="mx-2">
                <Slider
                  aria-label="slider-comp"
                  value={blockSettings?.settings?.blockSpacing * 1}
                  onChange={(v) => newBlockSettings(v)}
                  focusThumbOnChange={false}
                  style={{ paddingTop: '5px', paddingBottom: '5px' }}
                >
                  <SliderTrack h="2px" overflow="unset">
                    <div
                      style={{ height: '4px', width: '4px' }}
                      className="absolute -left-px -top-px bg-gray-200 rounded-full"
                    />
                    <div
                      style={{ height: '4px', width: '4px' }}
                      className="absolute -right-px -top-px bg-gray-200 rounded-full"
                    />
                    <SliderFilledTrack bg="#E6E8E9" />
                  </SliderTrack>
                  <SliderThumb
                    style={{ boxShadow: 'unset' }}
                    border="2px solid"
                    borderColor="#0278E4"
                    w="20px"
                    h="20px"
                  />
                </Slider>
              </div>
              <div className="px-2">
                <Input
                  fontSize="14px"
                  fontWeight="500"
                  color="$mode.900"
                  lineHeight="20px"
                  textAlign="center"
                  variant="outline"
                  width="53px"
                  height="40px"
                  padding={0}
                  style={{ borderColor: '#D3D6D8' }}
                  value={blockSettings?.settings?.blockSpacing * 1 || 0}
                  backgroundColor="unset"
                  isReadOnly
                />
                <span className="font-medium text-sm text-gray-500 px-2">
                  px
                </span>
              </div>
            </div>
          </div>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export function GlobalEditorStyle(props: {
  full?: boolean;
  readOnly?: boolean;
}) {
  const theme = useTheme<Theme>();
  const { bodyQuote, bodyQuote0 } = theme.textStyles;
  const isMobile = useBreakpointValue({
    base: true,
    ml: false,
  });
  const quote = useBreakpointValue({
    base: bodyQuote0,
    md: bodyQuote,
  });

  const isMobileFull = isMobile && !!props.full;

  // const contentIsMobile = useIsMobile();

  if (props.readOnly) {
    return (
      <style jsx global>{`
        .cdx-quote {
          padding: 0 !important;
        }
        .cdx-quote {
          padding-left: ${isMobileFull ? '24px !important' : 0};
          padding-right: ${isMobileFull ? '24px' : 0};
          margin: auto !important;
        }
        .cdx-quote__text {
          min-height: unset !important;
          border: none !important;
          border-left: 2px solid !important;
          box-shadow: none !important;
          border-radius: 0 !important;
          margin-bottom: 0 !important;
          font-size: ${theme.fontSizes[quote?.fontSize] || '20px'} !important;
          line-height: ${quote?.lineHeight || '32px'} !important;
          font-style: italic !important;
          padding-left: 16px !important;
        }
        .cdx-quote__caption {
          display: none;
        }
        .ce-inline-toolbar {
          margin-top: 0px !important;
        }
        .ce-conversion-toolbar--showed {
          width: min-content !important;
        }
        .cdx-simple-image__caption:focus,
        .image-tool__caption:focus {
          display: block !important;
        }
        .cdx-simple-image__caption,
        .image-tool__caption {
          font-size: 14px !important;
          color: #9b9fa4 !important;
          font-weight: 400 !important;
          border: unset;
          box-shadow: unset;
          text-align: center;
          margin: 12px auto;
          padding: 0;
        }
      `}</style>
    );
  }

  return (
    <style jsx global>{`
      .cdx-quote {
        padding: 0 !important;
      }
      .cdx-quote {
        padding-left: ${isMobileFull ? '24px !important' : 0};
        padding-right: ${isMobileFull ? '24px' : 0};
        margin: auto !important;
      }
      .cdx-quote__text {
        min-height: unset !important;
        border: none !important;
        border-left: 2px solid !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        margin-bottom: 0 !important;
        font-size: ${theme.fontSizes[quote?.fontSize] || '20px'} !important;
        line-height: ${quote?.lineHeight || '32px'} !important;
        font-style: italic !important;

        padding-left: 16px !important;
      }
      .cdx-quote__caption {
        display: none;
      }
      .ce-inline-toolbar {
        margin-top: 0px !important;
      }
      .ce-conversion-toolbar--showed {
        width: min-content !important;
      }
      .image-tool__caption:focus {
        display: block !important;
      }
      .cdx-input {
        ${props.readOnly &&
        'font-size: 14px !important; color: #9B9FA4 !important; font-weight: 400 !important;'}
      }
    `}</style>
  );

  // return (
  //   <style jsx global>{`
  //     .image-tool__image {
  //       width: fit-content;
  //     }
  //     .image-tool__row.svelte-mydto2 {
  //       margin-bottom: unset !important;
  //     }

  //     .ce-delimiter::before {
  //       color: rgb(196, 196, 196);
  //       content: '・・・' !important;
  //     }
  //     .ce-block {
  //       margin-top: ${contentIsMobile ? '32px' : '48px'};
  //     }
  //     #studioForm .ce-block__content {
  //       max-width: ${contentIsMobile
  //         ? '100% !important'
  //         : 'calc(100% - 29vw) !important'};
  //       margin: auto !important;
  //     }
  //     .image-tool--stretched {
  //       padding-left: 5%;
  //       padding-right: 5%;
  //     }
  //     #studioForm .image-tool--stretched {
  //       width: ${contentIsMobile ? '100%' : 'calc(100% + 18vw)'};
  //       margin-left: ${contentIsMobile ? '0' : '-9vw'};
  //       padding-left: 0;
  //       padding-right: 0;
  //     }
  //     .ce-header,
  //     .cdx-quote,
  //     .ce-paragraph,
  //     .link-tool,
  //     .cdx-list {
  //       padding: 0 !important;
  //     }
  //     .ce-header,
  //     .cdx-quote,
  //     .ce-paragraph,
  //     .link-tool,
  //     .cdx-list {
  //       padding-left: ${isMobileFull ? '24px !important' : 0};
  //       padding-right: ${isMobileFull ? '24px' : 0};
  //       margin: auto !important;
  //     }
  //     .ce-paragraph[data-placeholder]:empty::before {
  //       color: ${theme.colors.gray['300']};
  //     }
  //     .cdx-list {
  //       position: relative;
  //       left: 18px;
  //     }
  //     .ce-paragraph {
  //       font-size: ${theme.fontSizes[body4.fontSize]};
  //       line-height: ${body4.lineHeight};
  //       letter-spacing: ${body4.letterSpacing};
  //     }
  //     .cdx-block,
  //     .cdx-list__item {
  //       padding-left: 0;
  //     }

  //     .ce-toolbar .ce-toolbox {
  //       border-radius: 4px;
  //       overflow: hidden;
  //     }
  //     .cdx-quote__text {
  //       min-height: unset !important;
  //       border: none;
  //       border-left: 2px solid;
  //       box-shadow: none;
  //       border-radius: 0;
  //       margin-bottom: 0;
  //       font-size: ${theme.fontSizes[quote?.fontSize] || '20px'};
  //       line-height: ${quote?.lineHeight || '32px'};
  //       font-style: italic;

  //       padding-left: 16px;
  //     }
  //     .cdx-quote__caption {
  //       display: none;
  //     }
  //     .ce-inline-tool,
  //     .ce-inline-toolbar__dropdown:hover,
  //     .ce-conversion-toolbar,
  //     .ce-toolbar .ce-toolbox,
  //     .ce-inline-tool:hover,
  //     .ce-conversion-tool:hover,
  //     .ce-conversion-tool__icon,
  //     .ce-settings,
  //     .cdx-settings-button,
  //     .cdx-settings-button:hover,
  //     .ce-settings__button,
  //     .ce-settings__button:hover,
  //     .ce-toolbox__button,
  //     .ce-inline-toolbar {
  //       background-color: ${theme.colors.gray['900']};
  //       color: #dadada;
  //     }
  //     .ce-toolbar__content {
  //       max-width: ${EDITOR_CONFIG.articleMaxWidth};
  //     }
  //     .codex-editor__redactor {
  //       margin-right: 0px !important;
  //     }
  //     .read-only
  //       .codex-editor--empty
  //       .ce-block:first-child
  //       .ce-paragraph[data-placeholder]:empty::before {
  //       opacity: 0;
  //     }
  //     .ce-toolbar__plus--hidden {
  //       display: inline-flex;
  //     }
  //     .image-tool__caption:focus {
  //       display: block !important;
  //     }
  //     .ce-settings__button--focused {
  //       background: unset !important;
  //       box-shadow: unset;
  //     }
  //     .cdx-settings-button--active {
  //       background: rgba(34, 186, 255, 0.08) !important;
  //       box-shadow: inset 0 0 0 1px rgb(7 161 227 / 8%);
  //     }

  //     div[data-tune='addImages'] {
  //       background: unset !important;
  //       box-shadow: unset !important;
  //     }
  //   `}</style>
  // );
}
