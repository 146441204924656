import * as React from 'react';
import { Svg } from '../svg';

function SvgVisibilityOffOutlined({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.9 4.24A9.12 9.12 0 0112 4c7 0 11 8 11 8a18.494 18.494 0 01-2.16 3.19m-6.72-1.07a2.998 2.998 0 01-5.194-2.098A3 3 0 019.88 9.88m8.06 8.06A10.07 10.07 0 0112 20c-7 0-11-8-11-8a18.45 18.45 0 015.06-5.94l11.88 11.88zM1 1l22 22" />
      </g>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgVisibilityOffOutlined);
export default ForwardRef;
