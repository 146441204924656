// import { useColorMode } from '@chakra-ui/react';
import { useDisclosure, Box, Tooltip, useToast } from '@chakra-ui/react';
import { useState, useContext, useEffect } from 'react';
import { ethers } from 'ethers';
import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
// import { switchThemeMode } from 'src/heplers/theme';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
// import SearchPanel from 'src/components/common/search/searchPanel';
import SearchPanelModal from 'src/components/common/search/search-panel-modal';
import { Transition } from 'react-transition-group';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
// import SearchProvider from 'src/contexts/search/SearchContext';
// import SuggestProvider from 'src/contexts/search/RecommendContext';
import { CustomToast } from 'src/components/toast';
import SvgSearch from 'src/components/icons/search';
import SvgMenu from 'src/components/icons/menu';
// import SvgSafari from 'src/components/icons/safari';
// import SvgCoCreator from 'src/components/icons/co-creator';
import SvgStudio from 'src/components/icons/studio';
import SvgPioneer from 'src/components/icons/pioneer-v2';
import SvgHome from 'src/components/icons/home';
import SvgUpdates from 'src/components/icons/updates';
import SvgMessage from 'src/components/icons/message';
import Svg1CU from 'src/components/icons/1cu-pass';
// import { IconMenu } from 'src/components/icon-menu/icon-menu';
// import SvgPackage from 'src/components/icons/package';
import NavigationPanelMobile from 'src/components/mobile/navigationPanel';
import NavigationPanelDesktop from 'src/components/desktop/navigationPanel';
// import TutorialChecklistModal from 'src/components/tutorial/tutorial-checklist-modal';
// import { getTutorial } from 'src/app/explore/service';
import WarningModal from 'src/app/article/warning-modal';
import { useRouter } from 'next/router';
import SubmissionHistory from 'src/components/drafts-submissions/submission-history-modal';
import SignInModal from 'src/app/auth/sign-in/sign-in-modal';
import { getLocalStorage, removeLocalStorage } from 'src/heplers/localStorage';
import { useIsMobile, useIsLg } from 'src/hooks/use-is-mobile';
import { isMobile } from 'react-device-detect';
import SvgWallet from 'src/components/icons/wallet';
import SvgToolTip from 'src/components/icons/tooltip';
import SvgMetaMask from 'src/components/icons/meta-mask';
import SvgWalletConnect from 'src/components/icons/wallet-connect';
import SvgDuplicate from 'src/components/icons/duplicate2';
import SvgEth from 'src/components/icons/eth';
// import SvgMatic from 'src/components/icons/matic';
// import SvgEllipse from 'src/components/icons/ellipsis-v2';
import {
  userWalletConnectNonce,
  userWalletConnect,
} from 'src/app/auth/service';
import useProfileQuery from 'src/app/profile/use-profile-query';
import WalletExplainerModal from 'src/app/code/sign-up-section/WalletExplainerModal';
import { fetchWalletNfts, fetchWalletBalance } from 'src/hooks/use-alchemy';
import { Avatar } from '../avatar';
import Dropdowns from '../common/dropdowns/dropdowns';
import { NotificationDrawer } from '../notification/notification-drawer';
import { PostingOptionModal } from '../../app/studio/side-widget/posting-option-modal';
import SvgLogout from '../icons/logout';
import SvgLogoV1 from '../icons/1tm-alpha'; // '../icons/logo-v1'
// import TutorialProgressButton from '../tutorial/tutorial-progress-button';
import SvgClose from '../icons/close';
import AlphaBanner from './AlphaBanner';

// interface SiteNavigationProps {
//   customNav?: ReactNode;
// }
// { customNav }: SiteNavigationProps

const SiteNavigation = () => {
  const toast = useToast();
  const isLg = useIsLg();
  const isMobileView = useIsMobile();
  const warningModal = useDisclosure();
  const walletwarning = useDisclosure();
  const localDraftModal = useDisclosure();
  const { user, isLogin, logout, isFetching } = useContext(
    UserContext
  ) as UserContextType;
  const { data: profile } = useProfileQuery(user.username);
  const router = useRouter();
  const walletExplainerModal = useDisclosure();

  // const [navPanel, setNavPanel] = useState('');
  const [dispalyNavPanel, setDispalyNavPanel] = useState(false);
  // const [isHeaderTransparent, setHeaderTransparent] = useState(true);
  const [localDraft, setLocalDraft] = useState<any>({});
  // const [searchPanel, setSearchPanel] = useState(false);
  const { t } = useTranslation();
  const [fillStudio, setFillStudio] = useState('white');
  const [currColor, setCurrColor] = useState('#000');
  const [transparentNav, setTransparentNav] = useState(true);

  // const resultBox = useRef();
  const [signatures, setSignatures] = useState<any[]>([]);
  const [error, setError] = useState<any>();
  const [wc, setWc] = useState<any>();
  const [web3, setWeb3] = useState<any>();
  const [ensName, setEnsName] = useState('');
  const [alphaBannerShow, setAlphaBannerShow] = useState<boolean>(true);
  // const [runOnce, setRunOnce] = useState(true);

  useEffect(() => {
    const sigContinue = async () => {
      const connectWallet = await userWalletConnect({
        signature: signatures[signatures.length - 1].signature,
        publicAddress: signatures[signatures.length - 1].address,
      });
      if (connectWallet.code !== 1000) {
        setError(connectWallet.msg);
      } else {
        walletwarning.onOpen();
      }
    };
    if (signatures && signatures.length > 0) {
      if (
        signatures[signatures.length - 1].address &&
        signatures[signatures.length - 1].signature
      ) {
        sigContinue();
      }
    }
  }, [signatures]);

  useEffect(() => {
    if (profile?.publicAddress || user.walletAddress) {
      fetchENS();
    }
  }, [profile, user]);

  useEffect(() => {
    if (error?.length > 1) {
      toast({
        position: 'bottom-right',
        duration: 3000,
        render: (r) => (
          <CustomToast
            icon="error"
            title="Error"
            description={`${error}`}
            {...r}
          />
        ),
      });
      setError('');
    }
  }, [error]);

  useEffect(() => {
    if (profile?.publicAddress) {
      fetchUserWalletBalance();
    }
  }, [user]);

  // const [walletNFTs, setWalletNFTs] = useState([]);
  const [walletBalance, setWalletBalance] = useState<any>();

  const chainDecimals = { eth: '18', polygon: '18' };

  const fetchENS = async () => {
    try {
      const address = profile?.publicAddress || user.walletAddress;
      const ensContractAddress = '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85';
      const nfts = await fetchWalletNfts(address, {
        contractAddresses: [ensContractAddress],
      });
      setEnsName(nfts.ownedNfts[0].title);
    } catch (err) {
      console.log('error:', err);
    }
  };

  const fetchUserWalletBalance = async () => {
    try {
      const balances = await fetchWalletBalance(profile.publicAddress);
      setWalletBalance(Number(balances));
    } catch (err) {
      console.log('error:', err);
    }
  };

  const signMessage = async () => {
    try {
      if (!window.ethereum)
        throw new Error('No crypto wallet found. Please install it.');

      await window.ethereum.send('eth_requestAccounts');
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      userWalletConnectNonce().then(async (res) => {
        if (res) {
          const signature = await signer.signMessage(res.nonce);
          if (signature && address) {
            if (signatures && signatures.length > 0) {
              setSignatures([...signatures, { signature, address }]);
            } else {
              setSignatures([{ signature, address }]);
            }
          }
        }
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const walletConnectEnable = async () => {
    const walletConnectProvider = new WalletConnectProvider({
      infuraId: '117199ece0414112b97d54fbe01f6b49', // Required
      // qrcode: true
    });
    setWc(walletConnectProvider);
    await walletConnectProvider.enable();
    setWeb3(new Web3(walletConnectProvider as any));
  };

  useEffect(() => {
    const web3Continue = async () => {
      const currAddress = await web3.eth.getAccounts();
      userWalletConnectNonce().then((res) => {
        if (res) {
          walletConnectSign(res.nonce, currAddress[0]);
        }
      });
    };
    if (web3) {
      web3Continue();
    }
  }, [web3]);
  const [callbackRouter, setCallbackRouter] = useState<string>();
  useEffect(() => {
    setCallbackRouter(document.referrer);
  }, [router]);

  const walletConnectSign = async (nonce, address) => {
    const rawMessage = nonce;
    const rawMessageLength = new Blob([rawMessage]).size;
    let message = ethers.utils.toUtf8Bytes(
      `\x19Ethereum Signed Message:\n${rawMessageLength}${rawMessage}`
    );
    message = ethers.utils.keccak256(message) as any;
    const params = [address, message];
    const newSig = await wc.connector.signMessage(params);
    const sig = {
      signature: newSig,
      address,
    };
    if (signatures && signatures.length > 0) {
      setSignatures([...signatures, sig]);
    } else {
      setSignatures([sig]);
    }
    // this.verified = ethers.utils.verifyMessage(rawMessage, this.signature);

    // message = "Hello World";
    // setSignatures(await web3.eth.sign(message, coinbase));
    // this.verified = ethers.utils.verifyMessage(message, this.signature);
    // this.verified2 = this.web3.eth.accounts.recover(message, this.signature);
  };

  // const closeSearchPanel = () => {
  //   setSearchPanel(false);
  // };

  // const searchPanelHandle = () => {
  //   document.body.style.overflow = !searchPanel ? 'hidden' : 'auto';
  //   setSearchPanel(!searchPanel);
  // };

  const logoutAll = () => {
    removeLocalStorage('walletconnect'); // this is to remove stuck/pending signatures
    logout();
  };

  useEffect(() => {
    const clickHandle = () => {
      window.addEventListener(
        'scroll',
        () => {
          setNavStyle('scroll');
        },
        { passive: true }
      );
    };
    clickHandle();
    if (isLogin) {
      setLocalDraft(getLocalStorage('localPostDraft'));
      if (getLocalStorage('localPostDraft') && router.pathname === '/') {
        localDraftModal.onOpen();
      }
      setNavStyle('firstRender');
    } else {
      setNavStyle('scroll');
    }
    return () => clickHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  // const { toggleColorMode, colorMode } = useColorMode();

  // const themeModeDefault = getLocalStorage('theme');
  // const [themeMode, setThemeMode] = useState(themeModeDefault || 'light');
  // const handleThemeMode = () => {
  //   const selectThemeMode = themeMode === 'light' ? 'dark' : 'light';
  //   setLocalStorage('theme', selectThemeMode);
  //   switchThemeMode(selectThemeMode);
  //   setThemeMode(selectThemeMode);
  //   toggleColorMode();
  // };
  const [displayPanel, setDisplayPanel] = useState(false);

  const [windowHeight, setWindowHeight] = useState('unset');
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowHeight(`${window.innerHeight - 1}px`);
    });
  }, []);
  useEffect(() => {
    if (displayPanel) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = windowHeight;
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.height = windowHeight;
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.height = 'unset';
      document.documentElement.style.overflow = 'unset';
      document.documentElement.style.height = `unset`;
    }
  }, [displayPanel, windowHeight]);

  const setNavStyle = (from, dispalyPanel = null) => {
    const nav = document.getElementById('nav');
    const isOffest = document.getElementsByClassName('div-offest').length > 0;
    const isInitial =
      document.getElementsByClassName('header-initial').length > 0;
    const isTransparent =
      document.getElementsByClassName('header-transparent').length > 0;
    const isGradient =
      document.getElementsByClassName('header-gradient').length > 0 ||
      router.pathname === '/new' ||
      router.pathname === '/[username]/[sid]/edit';
    const isSticky = window.scrollY > 60;
    if (nav) {
      if (
        from === 'scroll' &&
        nav.style.backgroundColor === 'rgb(36, 37, 38)'
      ) {
        return;
      }
      let bgColor = '#242526';
      let color = '#fff';
      let boxShadow = 'unset';
      // let backdropFilter = 'unset';
      let bg = '';

      if (!dispalyPanel) {
        if (isInitial && !isSticky) {
          bgColor = 'initial';
          color = '#000';
          // setHeaderTransparent(false);
        } else if (isTransparent && !isSticky) {
          bgColor = 'initial';
          color = '#000';
          // setHeaderTransparent(false);
        } else if (isGradient && !isSticky) {
          bgColor = 'gradient';
          bg = 'linear-gradient(180deg, #ffffff 0%, #ffffffd4 25%, #fff0 100%)';
          color = '#000';
          // setHeaderTransparent(false);
        } else if (isSticky && !isInitial && !isTransparent && !isGradient) {
          setFillStudio('#242526');
          bgColor = '#fff';
          color = '#000';
          boxShadow = '0 1px 2px 0 rgb(0 0 0 / 0.05)';
          // setHeaderTransparent(false);
        } else if (isInitial && isSticky) {
          bgColor = '#fff';
          color = '#000';
          boxShadow = '0 1px 2px 0 rgb(0 0 0 / 0.05)';
          // setHeaderTransparent(false);
        } else if (isTransparent && isSticky) {
          bgColor = 'gradient';
          bg = 'linear-gradient(180deg, #FFFFFF 0%, #ffffff00 100%)';
          color = '#000';
          // setHeaderTransparent(false);
        } else if (isGradient && isSticky) {
          bgColor = 'gradient';
          bg = 'linear-gradient(180deg, #ffffff 0%, #ffffffd4 25%, #fff0 100%)';
          color = '#000';
          // boxShadow = '0 1px 2px 0 rgb(0 0 0 / 0.05)';
          // setHeaderTransparent(false);
        } else {
          bgColor = isOffest ? 'initial' : '#f3f3f4';
          color = isOffest ? '#fff' : '#000';
          // setHeaderTransparent(isOffest);
          setFillStudio('white');
        }
      }
      // nav.style.backdropFilter = backdropFilter;
      nav.style.boxShadow = boxShadow;
      if (bgColor === 'gradient') {
        nav.style.background = bg;
      } else {
        nav.style.backgroundColor = bgColor;
      }
      if (color === '#000') {
        setTransparentNav(false);
      } else {
        setTransparentNav(true);
      }
      setCurrColor(color);
      nav.style.color = color;
    }
  };

  // useEffect(() => {
  //   const clickListener = (panelStatus) => {
  //     if (isMobile) return;
  //     document.addEventListener('click', (e) => {
  //       const clickNav = e['path'].some((path) => {
  //         if (!path['className'] || typeof path['className'] !== 'string')
  //           return false;
  //         return path['className'].includes('nav-panel');
  //       });
  //       let status = panelStatus;
  //       if (!clickNav) {
  //         setDispalyNavPanel(false);
  //         status = false;
  //       }
  //       setNavStyle('click', status);
  //     });
  //   };
  //   clickListener(dispalyNavPanel);
  //   return () => {
  //     clickListener(dispalyNavPanel);
  //   };
  // }, [dispalyNavPanel]);

  const walletAddressTruncate = (address) => {
    if (address) {
      return `${address.slice(0, 8)}...${address.slice(
        address.length - 4,
        address.length
      )}`;
    }
    return '';
  };

  const checkENS = () => {
    if (ensName) {
      return ensName;
    }
    return walletAddressTruncate(profile?.publicAddress);
  };

  const siteItems = [
    // {
    //   type: 'link', // 'click',
    //   path: '/',
    //   displayName: 'EXPLORE',
    //   icon: null,
    //   action: () => {}, // setNavPanel('COMMUNITY'),
    // },
    // {
    //   type: 'link',
    //   path: '/space',
    //   displayName: 'SPACES',
    //   icon: null,
    //   action: () => {}, // setNavPanel('REPPL'),
    // },
    // {
    //   type: 'link',
    //   path: '/tribes',
    //   displayName: 'TOPICS',
    //   icon: null,
    //   action: () => {}, // setNavPanel('EXPLORE'),
    // },
    {
      type: 'link',
      path: '/feed',
      displayName: 'UPDATES',
      icon: null,
      action: () => {}, // setNavPanel('EXPLORE'),
    },
    {
      type: 'link',
      path: '/hall-of-fame',
      displayName: 'PIONEERS',
      icon: null,
      action: () => {}, // setNavPanel('EXPLORE'),
    },
  ];

  const siteItemsMobile = [
    // {
    //   type: 'click', // 'click',
    //   path: '/',
    //   displayName: 'Explore',
    //   icon: <SvgSafari className="inline text-xl" />,
    //   action: () => {}, // setNavPanel('COMMUNITY'),
    // },
    // {
    //   type: 'click',
    //   path: '/',
    //   displayName: 'Community',
    //   icon: <SvgCoCreator className="inline text-xl" />,
    //   action: () => {}, // setNavPanel('REPPL'),
    // },
    // {
    //   type: 'click',
    //   path: '/tribes',
    //   displayName: 'Make it yours',
    //   icon: <SvgPackage className="inline text-xl" />,
    //   action: () => {}, // setNavPanel('EXPLORE'),
    // },
    {
      type: 'link',
      path: `/${user.username}`,
      isLogin: !isLogin,
      displayName: 'My Studio',
      icon: <SvgStudio className="inline text-xl" />,
      action: () => {}, // setNavPanel('EXPLORE'),
    },
    {
      type: 'link',
      path: `/feed`,
      displayName: 'Updates',
      icon: <SvgUpdates className="inline text-xl" />,
      action: () => {}, // setNavPanel('EXPLORE'),
    },
    {
      type: 'link',
      path: `/hall-of-fame`,
      displayName: 'Pioneers',
      icon: <SvgPioneer className="inline text-xl" />,
      action: () => {}, // setNavPanel('EXPLORE'),
    },
    {
      type: 'link',
      path: `https://1cu.1tm.io/mint/pioneer-pass`,
      displayName: '1CU Pioneer Pass',
      icon: <Svg1CU className="inline text-xl" />,
      action: () => {}, // setNavPanel('EXPLORE'),
    },
    {
      type: 'link',
      path: 'mailto:workwithus@1tm.io',
      displayName: 'Feedback and Suggestions',
      icon: <SvgMessage className="inline text-xl" />,
      action: () => {}, // setNavPanel('EXPLORE'),
    },
    {
      type: 'link',
      path: `https://meet.1tm.io`,
      displayName: '1TM Portal',
      icon: <SvgHome className="inline text-xl" />,
      action: () => {}, // setNavPanel('EXPLORE'),
    },
    {
      type: 'click',
      path: '/',
      isLogin: !isLogin,
      displayName: 'My Wallet',
      icon: <SvgWallet className="inline text-xl" />,
      action: () => {}, // setNavPanel('EXPLORE'),
    },
  ];
  const postingOptionModal = useDisclosure();

  const balanceWithDecimal = (balances, chain) => {
    const newBalance = balances / 10 ** chainDecimals[chain];
    if (!newBalance) return '0.00';
    return newBalance.toFixed(4);
  };

  const walletItems = [
    {
      type: 'html',
      path: `/${user.username}`,
      displayName: '',
      html: (
        <>
          {profile?.publicAddress ? (
            <div className="w-full text-gray-900 flex font-semibold truncate relative text-base">
              <div>{checkENS()}</div>
              <div className="flex ml-auto">
                <SvgDuplicate style={{ width: '20px', height: '20px' }} />
              </div>
            </div>
          ) : (
            <div className="text-gray-900 font-semibold text-base flex justify-between items-center">
              <span>My Wallet</span>{' '}
              <button
                className="text-blue-500 font-medium text-sm"
                onClick={() => walletExplainerModal.onOpen()}
                type="button"
              >
                What is a Wallet?
              </button>
            </div>
          )}
        </>
      ),
      action: () => {
        navigator.clipboard.writeText(profile?.publicAddress);
      },
    },
    {
      type: 'html',
      path: `/${user.username}`,
      displayName: '',
      html: (
        <>
          {profile?.publicAddress ? (
            <div className="pt-2">
              <div className="text-sm text-gray-500 font-medium pb-5">
                Total balance
              </div>
              <div className="font-medium text-sm text-gray-900 pb-5">
                <SvgEth
                  style={{ width: '20px', height: '20px' }}
                  className="inline mr-2"
                />
                {balanceWithDecimal(walletBalance, 'eth')} ETH
              </div>
              {/* <div className="font-medium text-sm text-gray-900 pb-5">
                <SvgMatic
                  style={{ width: '20px', height: '20px' }}
                  className="inline mr-2"
                />
                {balanceWithDecimal(walletBalance, 'polygon')} Matic
              </div> */}
            </div>
          ) : (
            <div className="text-sm text-gray-900 font-medium">
              Connect with one of our available wallet providers or create a new
              one.
            </div>
          )}
        </>
      ),
      action: () => {},
    },
    {
      type: 'html',
      path: `/${user.username}`,
      displayName: '',
      disabled: profile?.publicAddress,
      html: (
        <div
          style={{ height: '40px', marginTop: '16px' }}
          className="border border-gray-300 rounded-full text-center font-semibold flex items-center justify-center"
        >
          <div>
            <SvgMetaMask
              style={{ width: '20px', height: '20px' }}
              className="inline mr-2"
            />
            {profile?.publicAddress ? (
              <div
                style={{ maxWidth: '74px' }}
                className="text-gray-700 font-semibold align-middle truncate inline-block"
              >
                {ensName || profile?.publicAddress}
              </div>
            ) : (
              <span className="align-middle text-semibold text-sm text-gray-700">
                MetaMask
              </span>
            )}
          </div>
        </div>
      ),
      action: () => {
        signMessage();
      },
    },
    {
      type: 'html',
      path: `/${user.username}`,
      displayName: '',
      disabled: profile?.publicAddress,
      html: (
        <div
          style={{ height: '40px', marginTop: '8px' }}
          className="border border-gray-300 rounded-full text-center font-semibold flex items-center justify-center"
        >
          <div>
            <SvgWalletConnect
              style={{ width: '24px' }}
              className="inline mr-2"
            />
            {profile?.publicAddress && (
              <div
                style={{ maxWidth: '74px' }}
                className="text-gray-700 font-semibold align-middle truncate inline-block"
              >
                {ensName || profile?.publicAddress}
              </div>
            )}
            {!profile?.publicAddress && (
              <span className="align-middle text-semibold text-sm text-gray-700">
                WalletConnect
              </span>
            )}
          </div>
        </div>
      ),
      action: () => {
        // authenticate({
        //   provider: 'walletconnect',
        //   signingMessage: '1TM wallet authentication',
        // });
        walletConnectEnable();
        // enableWeb3({provider: 'walletconnect', signingMessage: '1TM wallet authentication'})
      },
    },
    {
      type: 'html',
      path: `/${user.username}`,
      displayName: '',
      disabled: profile?.publicAddress,
      html: (
        <div className="text-sm text-gray-500 my-2 flex font-medium">
          Stuck with WalletConnect?
          <div className="flex items-center">
            <button
              type="button"
              onClick={() => {
                if (wc) {
                  wc.disconnect();
                  setSignatures([]);
                }
                removeLocalStorage('walletconnect');
              }}
              className="text-blue-500 px-0.5 font-medium"
            >
              {' '}
              Reset
            </button>
            <Tooltip
              label="This happens when you leave the signup/in process without completing signature."
              placement="right"
              bg="#ffffff"
              color="$mode.700"
              borderRadius="4px"
              border="1px solid #E6E8E9"
              maxWidth="230px"
              fontSize="12px"
              p="12px"
              alignItems="center"
            >
              <SvgToolTip style={{ width: '16px', height: '16px' }} />
            </Tooltip>
          </div>
        </div>
      ),
      action: () => {},
    },
    {
      type: 'html',
      path: `/${user.username}`,
      displayName: '',
      disabled: !profile?.publicAddress,
      notMobile: true,
      html: (
        <div
          style={{ padding: '10px 0' }}
          className="border border-gray-300 rounded-full text-gray-700 text-center text-sm font-semibold"
        >
          Sign out
        </div>
      ),
      action: logoutAll,
    },
  ];

  const navItems = [
    {
      type: 'link',
      path: `/${user.username}`,
      displayName: t('My Studio'),
      action: () => {},
    },
    // {
    //   type: 'link',
    //   path: `/${user.username}/projects`,
    //   displayName: t('My Spaces'),
    //   action: () => {},
    // },
    // {
    //   type: 'link',
    //   path: `/${user.username}/collections`,
    //   displayName: t('My Inspirations'),
    //   action: () => {},
    // },
    // {
    //   type: 'click',
    //   path: `/`,
    //   displayName: t('Create a Folio'),
    //   action: postingOptionModal.onOpen,
    // },
    // {
    //   type: 'link',
    //   path: `/asset/new`,
    //   displayName: t('Create an Asset'),
    //   action: () => {},
    // },
    // {
    //   type: 'link',
    //   path: `/space/start`,
    //   displayName: t('Start a Space'),
    //   action: () => {},
    // },
    // {
    //   type: 'link',
    //   path: `/${user.username}/drafts-submissions`,
    //   displayName: t('Saved Drafts'),
    //   action: () => {},
    // },
    {
      type: 'link',
      path: 'mailto:workwithus@1tm.io',
      displayName: t('Feedbacks and Suggestions'),
      action: () => {},
    },
    {
      type: 'click',
      path: '',
      displayName: 'Log Out',
      action: logoutAll,
    },
  ];

  const navItemsMobile = [
    // {
    //   type: 'link',
    //   path: `/${user.username}`,
    //   displayName: t('My Studio'),
    //   icon: <SvgPacmanBorder className="inline text-xl" />,
    //   action: () => {},
    // },
    // {
    //   type: 'desktop-only',
    //   displayName: t('Create a Studio Post'),
    //   icon: <SvgFeather className="inline text-xl" />,
    //   action: () => {},
    // },
    // {
    //   type: 'desktop-only',
    //   displayName: t('Drafts & Submissions'),
    //   icon: <SvgFeather className="inline text-xl" />,
    //   action: () => {},
    // },
    // {
    //   type: 'link',
    //   path: `/${user.username}/projects`,
    //   displayName: t('My Spaces'),
    //   icon: <SvgPacmanBorder className="inline text-xl" />,
    //   action: () => {},
    // },
    // {
    //   type: 'link',
    //   path: `/${user.username}/collections`,
    //   displayName: t('My Inspirations'),
    //   icon: <SvgPacmanBorder className="inline text-xl" />,
    //   action: () => {},
    // },
    {
      type: 'signout',
      displayName: 'Sign Out',
      icon: (
        <SvgLogout className="inline text-blue-500 dark:text-gray-500 text-xl" />
      ),
      action: logoutAll,
    },
  ];

  const subItems = {
    Explore: [
      {
        link: '/space',
        title: 'Spaces',
        icon: null,
        describe: 'Browse all the creative works from creators on 1TM',
      },
      // {
      //   link: '/',
      //   title: 'Projects',
      //   icon: null,
      //   describe: '',
      // },
      // {
      //   link: '/',
      //   title: 'Posts',
      //   icon: null,
      //   describe: '',
      // },
      // {
      //   link: '/',
      //   title: 'Creations (NFT)',
      //   icon: null,
      //   describe: '',
      // },
      // {
      //   link: '/',
      //   title: 'Collections',
      //   icon: null,
      //   describe: '',
      // },
    ],
    Community: [
      // {
      //   link: '/',
      //   title: 'Leader board',
      //   icon: null,
      //   describe: '',
      // },
      {
        link: '/feed',
        title: 'Updates',
        icon: null,
        describe: '',
      },
      {
        link: '/tribes',
        title: 'Topics',
        icon: null,
        describe: '',
      },
    ],
    'My Wallet': [...walletItems],
  };

  const handleMenuClick = () => {
    setNavStyle('firstRender');
    setDispalyNavPanel(false);
  };

  // const tutorialChecklistModal = useDisclosure();

  // const [tutorialData, setTutorialData] = useState([]);

  // useEffect(() => {
  //   const getTutorialData = async () => {
  //     if (isLogin) {
  //       const data = await getTutorial();
  //       setTutorialData(data);
  //     }
  //   };

  //   getTutorialData();
  // }, [tutorialChecklistModal.isOpen]);

  // let progress = 0;

  // tutorialData.forEach((step) => {
  //   if (step.step !== 'whats_agora') {
  //     progress += 12.5;
  //   }
  // });

  // const panelHandle = (type) => {
  //   const flag = type === navPanel ? !dispalyNavPanel : true;
  //   setNavPanel(type);
  //   setDispalyNavPanel(flag);
  // };

  useEffect(() => {
    if (isMobile) {
      if (
        router.pathname === '/space/[rid]/team' ||
        router.pathname === '/space/[rid]/submissions' ||
        router.pathname === '/[username]/drafts-submissions' ||
        // router.pathname === '/[username]/notification' ||
        router.pathname === '/following' ||
        router.pathname === '/new'
      ) {
        warningModal.onOpen();
      }
    }
    if (
      router.pathname === '/asset/new' ||
      router.pathname === '/asset/[assetid]/edit' ||
      router.pathname === '/asset/[assetid]/sell' ||
      router.pathname === '/new' ||
      router.pathname === '/[username]/[sid]/edit'
    ) {
      setAlphaBannerShow(false);
    } else {
      setAlphaBannerShow(true);
    }
  }, [router]);

  const searchModal = useDisclosure();

  const signInModal = useDisclosure();

  useEffect(() => {
    signInModal.onClose();
    if (router.query.page) {
      if (router.query.page === 'signIn') {
        signInModal.onOpen();
      }
    }
  }, [router.query]);

  const callBackRouter = () => {
    if (!callbackRouter) {
      router.push('/');
    } else {
      router.back;
    }
    warningModal.onClose();
  };
  const greyOrWhite = () => {
    if (currColor === '#fff') {
      return 'text-white';
    }
    return 'text-gray-900';
  };

  if (!isLogin && !isFetching) {
    return (
      <>
        <Box
          id="nav"
          className={`sticky top-0 relative h-15 w-full md:text-center font-semibold ${
            (isMobileView || isLg) && 'flex justify-between items-center'
          }`}
        >
          {isLg && !searchModal.isOpen && (
            <a href="/" className="">
              <SvgLogoV1
                alt="1TM Logo"
                className="inline"
                height="60px"
                width="85px"
                ml="15px"
                fill={router?.query?.username && fillStudio}
              />
            </a>
          )}
          {!isLg && !searchModal.isOpen && (
            <Box className="absolute left-6" alignItems="center" display="flex">
              {!searchModal.isOpen && (
                <a href="/">
                  <SvgLogoV1
                    alt="1TM Logo"
                    className="inline"
                    height="60px"
                    width="85px"
                    ml="15px"
                    fill={router?.query?.username && fillStudio}
                  />
                </a>
              )}
              <Box display="inline" ml="46px">
                {siteItems.map((item, key) => {
                  const mkey = `m-${key}`;
                  return (
                    <Link key={Number(key)} href={`${item.path}`}>
                      <div key={mkey} className="relative inline">
                        <span
                          aria-hidden="true"
                          onClick={() => {
                            // panelHandle(item['displayName']);
                          }}
                          key={mkey}
                          className={`${greyOrWhite()} text-sm font-bold cursor-pointer mr-11 nav-panel`}
                        >
                          {t(item['displayName'])}
                        </span>
                      </div>
                    </Link>
                  );
                })}
              </Box>
            </Box>
          )}

          {!searchModal.isOpen && (
            <SignInModal
              modal={{
                ...signInModal,
                onClose: () => {
                  router.push({ query: { ...router.query, page: '' } });
                  signInModal.onClose();
                },
              }}
            />
          )}

          {!isLg && !searchModal.isOpen && (
            <div className="absolute right-4 md:right-10 top-3 flex items-center">
              {/* eslint-disable  */}
              <div aria-hidden="true" className="cursor-pointer">
                {!searchModal.isOpen && router.pathname !== '/search' && (
                  <div onClick={searchModal.onOpen}>
                    <SvgSearch
                      fontSize="24px"
                      className={`${
                        isMobile ? 'hidden' : ''
                      } text-lg inline mr-3`}
                    />
                  </div>
                )}
              </div>
              {/* eslint-enable */}
              <button
                type="button"
                onClick={() => {
                  window.history.replaceState('', '', '?signin=true');
                  signInModal.onOpen();
                }}
              >
                Sign In
              </button>
              <button
                className="ml-6 bg-denim-500 text-white px-8 py-2 rounded-full font-medium"
                type="button"
                onClick={() => {
                  window.history.replaceState('', '', '?signin=false');
                  signInModal.onOpen();
                }}
              >
                Get Started
              </button>
            </div>
          )}
          <SearchPanelModal modal={searchModal} />

          <div
            className="lg:hidden cursor-pointer mr-4"
            aria-hidden="true"
            onClick={() => setDisplayPanel(!displayPanel)}
          >
            {!displayPanel && <SvgMenu className="text-2xl inline" />}
            {displayPanel && <SvgClose className="text-2xl inline" />}
          </div>

          <WarningModal
            {...warningModal}
            title={t('Oops!')}
            text={t(
              'This page is not mobile ready yet, please view it on the desktop. We’re working hard to develop it, please bear with us. '
            )}
            warningType="warning3"
            noCancel
            fullScreenOnMobile
            onOk={callBackRouter}
            okBtnText={t('Bring me back')}
            onOkBtnColor="#0278E4"
          />
        </Box>
        {displayPanel && (
          <NavigationPanelMobile
            siteItems={[...siteItemsMobile]}
            navItems={navItemsMobile}
            siteSubItems={subItems}
            setDisplayPanel={setDisplayPanel}
            isLoggedIn={isLogin}
          />
        )}
      </>
    );
  }

  return (
    <>
      {alphaBannerShow && <AlphaBanner />}
      <Box
        id="nav"
        className="sticky top-0 relative h-15 w-full md:text-center font-semibold  z-50"
      >
        {isLg && !searchModal.isOpen && (
          <a href="/" className="">
            <SvgLogoV1
              alt="1TM Logo"
              className="inline"
              height="60px"
              width="85px"
              ml="15px"
              fill={router?.query?.username && fillStudio}
            />
          </a>
        )}
        {!isLg && !searchModal.isOpen && (
          <Box className="absolute left-6" alignItems="center" display="flex">
            {!searchModal.isOpen && (
              <a href="/" className="">
                <SvgLogoV1
                  alt="1TM Logo"
                  className="inline"
                  height="60px"
                  width="85px"
                  ml="15px"
                  fill={router?.query?.username && fillStudio}
                />
              </a>
            )}
            <Box display="inline" ml="46px">
              {siteItems.map((item, key) => {
                const mkey = `m-${key}`;
                return (
                  <Link key={Number(key)} href={`${item.path}`}>
                    <div key={mkey} className="relative inline">
                      <span
                        aria-hidden="true"
                        onClick={() => {
                          // panelHandle(item['displayName']);
                        }}
                        key={mkey}
                        className={`${greyOrWhite()} text-sm font-bold cursor-pointer mr-11 nav-panel`}
                      >
                        {t(item['displayName'])}
                      </span>
                    </div>
                  </Link>
                );
              })}
            </Box>
          </Box>
        )}
        <div className="absolute right-4 md:right-10 top-3 flex items-center">
          {/* <div
            aria-hidden="true"
            onClick={() => searchPanelHandle()}
            className="cursor-pointer"
          >
            {searchPanel && <SvgClose className="text-lg inline mt-2" />}
            {!searchPanel && <SvgSearch className="text-2xl inline" />}
          </div> */}
          {/* {!isLg && (
            <>
              <TutorialChecklistModal
                username={user.firstName || user?.username}
                user={user?.username}
                modal={{ ...tutorialChecklistModal }}
                tutorialStep={router.query.tutorialStep}
              />
              {progress !== 100 && !searchModal.isOpen && (
                <div className="">
                  <TutorialProgressButton
                    headerTransparent={isHeaderTransparent}
                    onClick={() => {
                      tutorialChecklistModal.onOpen();
                    }}
                    progress={progress}
                    isChecked={tutorialChecklistModal.isOpen}
                  />
                </div>
              )}
            </>
          )} */}
          {Object.keys(user).length > 0 && (
            <>
              {/* eslint-disable  */}
              <div aria-hidden="true" className="cursor-pointer">
                {!searchModal.isOpen && router.pathname !== '/search' && (
                  <div
                    onClick={searchModal.onOpen}
                    className={`md:ml-8 ${
                      transparentNav
                        ? 'hover:bg-transparentNav-800'
                        : 'hover:bg-transparentNav-50'
                    } rounded-full p-2`}
                  >
                    <SvgSearch fontSize="24px" className="text-lg inline" />
                  </div>
                )}
              </div>
              {/* eslint-enable */}
              <SearchPanelModal modal={searchModal} />
              <>
                {!searchModal.isOpen && !displayPanel && (
                  <div
                    className={`lg:ml-8 ${
                      transparentNav
                        ? 'hover:bg-transparentNav-800'
                        : 'hover:bg-transparentNav-50'
                    } rounded-full p-2`}
                  >
                    <NotificationDrawer loginUser={user?.username} />
                  </div>
                )}
              </>
              {!isLg && (
                <>
                  <div
                    className={`md:ml-8 ${
                      transparentNav
                        ? 'hover:bg-transparentNav-800'
                        : 'hover:bg-transparentNav-50'
                    } rounded-full p-2 aspect-square`}
                    style={{
                      height: '40px',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      aspectRatio: '1/1',
                    }}
                  >
                    <Box
                      style={{
                        height: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '-4px',
                      }}
                    >
                      <Dropdowns
                        items={[walletItems]}
                        width="330px"
                        className="nav text-2xl flex justify-center items-center"
                        isClicked={() => handleMenuClick()}
                        buttonComponent={
                          <SvgWallet className="text-2xl inline" />
                        }
                      />
                    </Box>
                  </div>
                  <div className="md:ml-8">
                    <Dropdowns
                      items={[navItems]}
                      width="309px"
                      className="nav inline"
                      isClicked={() => handleMenuClick()}
                      buttonComponent={
                        <Avatar
                          name={user ? user?.username : ''}
                          data-testid={user ? user?.avatar : ''}
                          width={40}
                          height={40}
                          src={user ? user?.avatar : ''}
                          mr={1}
                          author={user}
                        />
                      }
                    />
                  </div>
                </>
              )}
            </>
          )}
          {/* mobile */}
          <div
            className="lg:hidden cursor-pointer ml-4"
            aria-hidden="true"
            onClick={() => setDisplayPanel(!displayPanel)}
          >
            {!displayPanel && <SvgMenu className="text-2xl inline" />}
            {displayPanel && <SvgClose className="text-2xl inline" />}
          </div>
        </div>
      </Box>
      {/* <Transition in={searchPanel} timeout={500} unmountOnExit>
        {(state) => (
          <div className={`fade-default fade-${state}`}>
            <SuggestProvider>
              <SearchProvider>
                <SearchPanel closePanel={closeSearchPanel} />
              </SearchProvider>
            </SuggestProvider>
          </div>
        )}
      </Transition> */}
      <Transition in={dispalyNavPanel} timeout={500} unmountOnExit>
        {(state) => (
          <NavigationPanelDesktop
            items={subItems}
            type="REPPL"
            className={`nav-panel fade-default fade-${state}`}
          />
        )}
      </Transition>

      {displayPanel && (
        <NavigationPanelMobile
          siteItems={[...siteItemsMobile]}
          navItems={navItemsMobile}
          siteSubItems={subItems}
          setDisplayPanel={setDisplayPanel}
          isLoggedIn={isLogin}
        />
      )}

      <PostingOptionModal modal={postingOptionModal} />
      {router.query?.submissionHistoryModal === 'open' && (
        <SubmissionHistory id={`${router.query.episode}`} />
      )}
      <SignInModal
        modal={{
          ...signInModal,
          onClose: () => {
            router.push({ query: { ...router.query, page: '' } });
            signInModal.onClose();
          },
        }}
      />
      <WarningModal
        {...warningModal}
        title={t('Oops!')}
        text={t(
          'This page is not mobile ready yet, please view it on the desktop. We’re working hard to develop it, please bear with us. '
        )}
        warningType="warning3"
        noCancel
        fullScreenOnMobile
        onOk={callBackRouter}
        okBtnText={t('Bring me back')}
        onOkBtnColor="#0278E4"
      />
      <WarningModal
        {...walletwarning}
        title={t('Syncing your wallet')}
        text={t(
          'Please refresh the page to continue. Next time, you can also use this wallet as your sign in credential. '
        )}
        warningType="warning2"
        noCancel
        onOk={router.reload}
        onClose={router.reload}
        okBtnText={t('Refresh')}
        onOkBtnColor="#FFD557"
      />
      <WarningModal
        {...localDraftModal}
        title={t('Warning!')}
        text={t('You have unsaved changes. Do you want to continue editing ?')}
        onOk={() => {
          if (JSON.parse(localDraft).postID) {
            router.push(`
              /${JSON.parse(localDraft).username}/${
              JSON.parse(localDraft).postID
            }/edit`);
          } else {
            router.push(`/new`);
          }
        }}
        okBtnText={t('Continue')}
        cancelBtnText={t('Discard')}
        onDecline={() => removeLocalStorage('localPostDraft')}
      />
      <WalletExplainerModal
        closeModal={walletExplainerModal.onClose}
        isOpen={walletExplainerModal.isOpen}
      />
    </>
  );
};

export default SiteNavigation;
