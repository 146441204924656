import {
  Box,
  Button,
  // Collapse,
  Flex,
  Icon,
  Link,
  // Radio,
  // RadioGroup,
  Stack,
  // Switch,
  // Text,
  UseDisclosureReturn,
  // VStack,
  useDisclosure,
  Checkbox,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react';
import Select from 'react-select';
import { LicensePreset } from 'src/components/select';
import { useState, useEffect } from 'react'; // useReducer
// import { Transition } from '@headlessui/react';
// import { OneTmModal } from 'src/components/1tm-modal';
// import type { Options } from 'src/app/reppl/types';

import SvgCC0 from 'src/components/icons/cc0-type';
import SvgCC from 'src/components/icons/cc-type';
import SvgARR from 'src/components/icons/arr-type';
import SvgNC from 'src/components/icons/nc-type';

// import SvgInfo from 'src/components/icons/info';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { LICENSE_FEATURES } from 'src/app/license/types';
import { dataSort } from 'src/helpers/sortData';
import cn from 'classnames';
import ErrorModal from 'src/app/code/ErrorModal/ErrorModal';
import styles from 'src/pages/asset/Create_NFT_empty_status_New.module.scss';
import LicenseChooser from 'src/components/LicenseChooser/LicenseChooser';

type License = keyof typeof LICENSE_FEATURES;
export const isLicense = (copyright?: string): copyright is License =>
  copyright in LICENSE_FEATURES;

interface Props {
  copyright: string;
  isLoaded?: boolean;
  changeable?: boolean;
  sharable?: boolean;
  preApprove?: boolean;
  handleChange?: (copyright: any) => void;
  waiveLicense?: (e: any) => void;
}

function LicenseEditModal({
  modal,
  copyright,
  onOk,
}: {
  modal: UseDisclosureReturn;
  copyright: string;
  onOk?: (e: any) => void;
}) {
  const [editedLicense, setEditedLicense] = useState<any>();
  const [licenseApproved, setLicenseApproved] = useState<boolean>(false);

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW="645px"
        maxH="calc(100vh - 10%)"
        borderRadius="16px"
        className="relative"
      >
        <div className="text-gray-900 font-semibold text-xl pt-10 px-10">
          Change current license
        </div>
        <ModalBody p="0 8px">
          <LicenseFull
            copyright={copyright}
            handleChange={(e) => setEditedLicense(e)}
            allApproved={(e) => {
              setLicenseApproved(e);
            }}
            isCreate
            isModal
            isNft
          />
        </ModalBody>
        <div className="relative border-t">
          <Box w="50%" className="text-end ml-auto py-6 px-10">
            <Button
              colorScheme="#9B9FA4"
              color="#9B9FA4"
              variant="ghost"
              className="mr-6"
              p={0}
              onClick={() => {
                modal.onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              rounded="ellipse"
              isDisabled={!licenseApproved}
              onClick={() => {
                onOk(editedLicense);
                modal.onClose();
              }}
            >
              Update
            </Button>
          </Box>
        </div>
      </ModalContent>
    </Modal>
  );
}

export function LicenseFull({
  copyright,
  isCreate = false,
  isModal = false,
  isNft = false,
  handleChange,
  allApproved,
  preApprove = false,
}: {
  copyright?: any;
  isCreate?: boolean;
  isModal?: boolean;
  isNft?: boolean;
  preApprove?: boolean;
  handleChange?: (copyright: string) => void;
  allApproved?: (e: any) => void;
}) {
  const { t } = useTranslation();
  const licenseEdit = useDisclosure();
  const licenseChange = useDisclosure();
  const [currLicense, setCurrLicense] = useState<any>(copyright);
  const [selectedLicense, setSelectedLicense] = useState<any>(
    copyright?.slice(0, 2) || 'CC'
  );
  const [preApproveUse, setPreApproveUse] = useState<boolean>(false);
  const [acknowledge, setAcknowledge] = useState<any[]>([false, false, false]);
  const [licenseWaive, setLicenseWaive] = useState<boolean>(false);

  useEffect(() => {
    if (preApprove && !preApproveUse) {
      setAcknowledge([true, true, true]);
      setLicenseWaive(true);
      setPreApproveUse(true);
    } else if (acknowledge[0] && acknowledge[1]) {
      const newAcknowledge = [...acknowledge];
      newAcknowledge.splice(2, 1, true);
      setAcknowledge(newAcknowledge);
    } else {
      const newAcknowledge = [...acknowledge];
      newAcknowledge.splice(2, 1, false);
      setAcknowledge(newAcknowledge);
    }
  }, [acknowledge[0], acknowledge[1]]);

  useEffect(() => {
    if (copyright) {
      if (typeof copyright !== 'string') {
        setCurrLicense(copyright.value);
        if (copyright.value === 'CC0 1.0') {
          setSelectedLicense('CC0');
        } else if (copyright.value === 'All Rights Reserved') {
          setSelectedLicense('ARR');
        } else {
          setSelectedLicense(copyright?.value.slice(0, 2));
        }
      } else {
        setCurrLicense(copyright);
        if (copyright === 'CC0 1.0') {
          setSelectedLicense('CC0');
        } else if (copyright === 'All Rights Reserved') {
          setSelectedLicense('ARR');
        } else {
          setSelectedLicense(copyright.slice(0, 2));
        }
      }
    }
  }, [copyright]);

  useEffect(() => {
    if (allApproved) {
      if (currLicense === 'CC0 1.0') {
        allApproved(acknowledge.every((v) => v === true) && licenseWaive);
      } else {
        allApproved(acknowledge.every((v) => v === true));
      }
    }
  }, [acknowledge, licenseWaive]);

  useEffect(() => {
    handleChange(currLicense);
  }, [currLicense]);

  if (!isCreate) {
    return (
      <div className={cn(styles.cover_block33, styles.cover_block33_layout)}>
        <div className={cn(styles.flex52, styles.flex52_layout)}>
          <div className={cn(styles.flex53, styles.flex53_layout)}>
            <div>
              {currLicense === 'CC0 1.0' && (
                <div className="border border-gray-200 rounded-lg p-6 bg-white my-1.5 ">
                  <div className="flex font-semibold text-sm text-gray-900 items-center">
                    <SvgCC0 height="32px" width="32px" className="mr-2" />
                    <div>No Rights Reserved</div>
                    <div
                      style={{ width: '91px' }}
                      className="border border-gray-300 rounded-full py-1.5 ml-auto cursor-not-allowed"
                    >
                      <div className="text-blue-200 font-medium text-sm m-auto w-max">
                        Change
                      </div>
                    </div>
                  </div>
                  <div className="pl-8 text-gray-500 text-sm font-medium mt-3.5">
                    {t('license1')}
                  </div>

                  <div className="pl-8 mt-4">
                    <LicenseContainer copyright={currLicense} />
                  </div>
                </div>
              )}
              {currLicense === 'All Rights Reserved' && (
                <div className="border border-gray-300 rounded-lg p-6 bg-white my-1.5">
                  <div className="flex font-semibold text-sm text-gray-900 items-center">
                    <SvgARR height="32px" width="32px" className="mr-2" />
                    <div>All Rights Reserved</div>
                    <div
                      style={{ width: '91px' }}
                      className="border border-gray-300 rounded-full py-1.5 ml-auto cursor-pointer"
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        licenseEdit.onOpen();
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          licenseEdit.onOpen();
                        }
                      }}
                    >
                      <div className="text-blue-500 font-medium text-sm m-auto w-max">
                        Change
                      </div>
                    </div>
                  </div>
                  <div className="pl-8 text-gray-500 text-sm font-medium mt-3.5">
                    {t('license3')}
                  </div>

                  <div className="pl-8 mt-4">
                    <LicenseContainer copyright={currLicense} />
                  </div>
                </div>
              )}
              {currLicense.slice(0, 2) === 'CC' &&
                currLicense !== 'CC0 1.0' &&
                currLicense !== 'All Rights Reserved' && (
                  <div className="border border-gray-300 rounded-lg p-6 bg-white my-1.5">
                    <div className="flex font-semibold text-sm text-gray-900 items-center">
                      <SvgCC height="32px" width="32px" className="mr-2" />
                      <div>Open for Public</div>
                      <div
                        style={{ width: '91px' }}
                        className="border border-gray-300 rounded-full py-1.5 ml-auto cursor-pointer"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          licenseChange.onOpen();
                        }}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            licenseEdit.onOpen();
                          }
                        }}
                      >
                        <div className="text-blue-500 font-medium text-sm m-auto w-max">
                          Change
                        </div>
                      </div>
                    </div>
                    <div className="pl-8 text-gray-500 text-sm font-medium mt-3.5">
                      {t('license2')}
                    </div>

                    <div className="pl-8 mt-4">
                      <LicenseContainer copyright={currLicense} />
                    </div>
                  </div>
                )}
              {currLicense.slice(0, 2) === 'NC' &&
                currLicense !== 'All Rights Reserved' && (
                  <div className="border border-gray-300 rounded-lg p-6 bg-white my-1.5">
                    <div className="flex font-semibold text-sm text-gray-900 items-center">
                      <SvgNC height="32px" width="32px" className="mr-2" />
                      <div>NFT Holder Only</div>
                      <div
                        style={{ width: '91px' }}
                        className="border border-gray-300 rounded-full py-1.5 ml-auto cursor-pointer"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          licenseEdit.onOpen();
                        }}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            licenseEdit.onOpen();
                          }
                        }}
                      >
                        <div className="text-blue-500 font-medium text-sm m-auto w-max">
                          Change
                        </div>
                      </div>
                    </div>
                    <div className="pl-8 text-gray-500 text-sm font-medium mt-3.5">
                      {t('license4')}
                    </div>

                    <div className="pl-8 mt-4">
                      <LicenseContainer copyright={currLicense} />
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <LicenseEditModal
          modal={licenseEdit}
          copyright={copyright}
          onOk={(e) => {
            setCurrLicense(e);
          }}
        />
        <ErrorModal
          {...licenseChange}
          heading="CC License is not revocable."
          paragraph={t('licenseWarning')}
          subtext={t('licenseWarningSub')}
          cancelButtonText="Cancel"
          confirmButtonText="Change"
          confirmButtonClass="w-auto px-8"
          confirmButtonAction={() => {
            licenseChange.onClose();
            licenseEdit.onOpen();
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        margin: `${isModal ? '0px' : '12px 0px 0px'}`,
        backgroundColor: `${isModal ? '#fff' : '#fafbfb'}`,
        border: `${isModal ? 'unset' : '1px solid #e6e8e9'}`,
      }}
      className={cn(styles.cover_block33, styles.cover_block33_layout)}
    >
      <div className={cn(styles.flex52, styles.flex52_layout)}>
        <div className={cn(styles.flex53, styles.flex53_layout)}>
          <div className={cn(styles.text_body91, styles.text_body91_layout)}>
            License Type
          </div>

          <div>
            <div
              className={`border ${
                selectedLicense === 'CC0'
                  ? 'border-blue-300 bg-cyan-50'
                  : 'border-gray-300 bg-white'
              } rounded-lg p-6 my-3 relative`}
              role="button"
              tabIndex={0}
              onClick={() => {
                setCurrLicense('CC0 1.0');
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  setCurrLicense('CC0 1.0');
                }
              }}
            >
              <div
                className={`flex font-semibold text-sm items-center ${
                  selectedLicense === 'CC0' ? 'text-blue-500' : 'text-gray-900'
                }`}
              >
                <SvgCC0 height="32px" width="32px" className="mr-2" />
                <div>No Rights Reserved (CC0)</div>
                <div className="absolute top-3 right-3">
                  <Popover placement="left-end">
                    <PopoverTrigger>
                      <div
                        style={
                          {
                            '--src': `url(${
                              require('public/assets_pxcode/ccbeefc78b0ff8df007c0bb8302bb826.svg')
                                .default
                            })`,
                          } as React.CSSProperties
                        }
                        className={cn(styles.icon21, styles.icon21_layout)}
                      />
                    </PopoverTrigger>
                    <PopoverContent _focus={{ outline: 'none' }} border="none">
                      <div
                        style={{
                          backgroundColor: 'white',
                          color: '$mode.700',
                          borderRadius: '4px',
                          border: '1px solid #E6E8E9',
                          padding: '12px',
                          width: '356px',
                        }}
                      >
                        <div className="text-xs text-gray-700 font-medium">
                          <div className="font-bold">
                            What is Public Domain Dedication?
                          </div>
                          <div className="mb-1">
                            This means you are giving away your copyright
                            ownership to the public, so anyone can reproduce and
                            distribute the creative work with no limitation.
                          </div>
                          <div>
                            Learn more about{' '}
                            <span>
                              <a
                                href="https://creativecommons.org/publicdomain/zero/1.0/"
                                target="_blank"
                                rel="noreferrer"
                                className="underline"
                              >
                                CC0 1.0
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              <div
                className={`pl-10 text-sm font-medium mt-3.5 ${
                  selectedLicense === 'CC0' ? 'text-blue-500' : 'text-gray-900'
                }`}
              >
                Let everyone use this work with no strings attached.
              </div>
              <div
                className={`pl-10 text-xs font-medium mt-3 ${
                  selectedLicense === 'CC0' ? 'text-blue-300' : 'text-gray-400'
                }`}
              >
                {t('license1')}
              </div>
            </div>
            <div
              className={`border ${
                selectedLicense === 'CC'
                  ? 'border-blue-300 bg-cyan-50'
                  : 'border-gray-300 bg-white'
              } rounded-lg p-6 my-3 relative`}
              role="button"
              tabIndex={0}
              onClick={() => {
                setCurrLicense('CC BY 4.0');
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  setCurrLicense('CC BY 4.0');
                }
              }}
            >
              <div
                className={`flex font-semibold text-sm items-center ${
                  selectedLicense === 'CC' ? 'text-blue-500' : 'text-gray-900'
                }`}
              >
                <SvgCC height="32px" width="32px" className="mr-2" />
                <div>Open for Public (CC 4.0)</div>
                <div className="absolute top-3 right-3">
                  <Popover placement="left-end">
                    <PopoverTrigger>
                      <div
                        style={
                          {
                            '--src': `url(${
                              require('public/assets_pxcode/ccbeefc78b0ff8df007c0bb8302bb826.svg')
                                .default
                            })`,
                          } as React.CSSProperties
                        }
                        className={cn(styles.icon21, styles.icon21_layout)}
                      />
                    </PopoverTrigger>
                    <PopoverContent _focus={{ outline: 'none' }} border="none">
                      <div
                        style={{
                          backgroundColor: 'white',
                          color: '$mode.700',
                          borderRadius: '4px',
                          border: '1px solid #E6E8E9',
                          padding: '12px',
                          width: '356px',
                        }}
                      >
                        <div className="text-xs text-gray-700 font-medium">
                          <div className="font-bold">
                            What is Open for Public?
                          </div>
                          <div className="mb-1">
                            You can use this license to grant broad rights to
                            public to any and all persons in the general public
                            as licensees.
                          </div>
                          <div>
                            1TM is using{' '}
                            <span>
                              <a
                                href="https://creativecommons.org/licenses/by/4.0/"
                                target="_blank"
                                rel="noreferrer"
                                className="underline"
                              >
                                Creative Commons Attribution 4.0
                              </a>
                            </span>{' '}
                            International license as public license standard to
                            help share your work on conditions of your choice.
                          </div>
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              <div
                className={`pl-10 text-sm font-medium mt-3.5 ${
                  selectedLicense === 'CC' ? 'text-blue-500' : 'text-gray-900'
                }`}
              >
                Grant an irrevocable copyright license of your choice to the
                general public.
              </div>
              <div
                className={`pl-10 text-xs font-medium mt-3 ${
                  selectedLicense === 'CC' ? 'text-blue-300' : 'text-gray-400'
                }`}
              >
                {t('license2')}
              </div>
            </div>
            {isNft && (
              <div
                className={`border ${
                  selectedLicense === 'NC'
                    ? 'border-blue-300 bg-cyan-50'
                    : 'border-gray-300 bg-white'
                } rounded-lg p-6 my-3 relative`}
                role="button"
                tabIndex={0}
                onClick={() => {
                  setCurrLicense('NC FC-EX 1.0');
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    setCurrLicense('NC FC-EX 1.0');
                  }
                }}
              >
                <div
                  className={`flex font-semibold text-sm items-center ${
                    selectedLicense === 'NC' ? 'text-blue-500' : 'text-gray-900'
                  }`}
                >
                  <SvgNC height="32px" width="32px" className="mr-2" />
                  <div>NFT Holder Only (NFT Commons 1.0)</div>
                  <div className="absolute top-3 right-3">
                    <Popover placement="left-end">
                      <PopoverTrigger>
                        <div
                          style={
                            {
                              '--src': `url(${
                                require('public/assets_pxcode/ccbeefc78b0ff8df007c0bb8302bb826.svg')
                                  .default
                              })`,
                            } as React.CSSProperties
                          }
                          className={cn(styles.icon21, styles.icon21_layout)}
                        />
                      </PopoverTrigger>
                      <PopoverContent
                        _focus={{ outline: 'none' }}
                        border="none"
                      >
                        <div
                          style={{
                            backgroundColor: 'white',
                            color: '$mode.700',
                            borderRadius: '4px',
                            border: '1px solid #E6E8E9',
                            padding: '12px',
                            width: '356px',
                          }}
                        >
                          <div className="text-xs text-gray-700 font-medium">
                            <div className="font-bold">
                              What is NFT Commons?
                            </div>
                            <div className="mb-1">
                              NFT Commons is an NFT-based copyright license
                              standard made for creators who are sharing their
                              works through NFTs. It is created to help creators
                              easily choose desired license type without complex
                              and expensive legal procedures.
                            </div>
                            <div>
                              It is built upon CC 4.0 License, but unlike CC
                              4.0, it is not a public license where the
                              copyright is licensed to It is a holder-only
                              license where the license is granted only to the
                              token holder(s).
                            </div>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
                <div
                  className={`pl-10 text-sm font-medium mt-3.5 ${
                    selectedLicense === 'NC' ? 'text-blue-500' : 'text-gray-900'
                  }`}
                >
                  Grant an irrevocable copyright license of your choice to NFT
                  holders.
                </div>
                <div
                  className={`pl-10 text-xs font-medium mt-3 ${
                    selectedLicense === 'NC' ? 'text-blue-300' : 'text-gray-400'
                  }`}
                >
                  {t('license4')}
                </div>
              </div>
            )}
            {!(copyright === 'All Rights Reserved' && isModal) && (
              <div
                className={`border ${
                  selectedLicense === 'ARR'
                    ? 'border-blue-300 bg-cyan-50'
                    : 'border-gray-300 bg-white'
                } rounded-lg p-6 my-3 relative`}
                role="button"
                tabIndex={0}
                onClick={() => {
                  setCurrLicense('All Rights Reserved');
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    setCurrLicense('All Rights Reserved');
                  }
                }}
              >
                <div
                  className={`flex font-semibold text-sm items-center ${
                    selectedLicense === 'ARR'
                      ? 'text-blue-500'
                      : 'text-gray-900'
                  }`}
                >
                  <SvgARR height="32px" width="32px" className="mr-2" />
                  <div>All Rights Reserved</div>
                  <div className="absolute top-3 right-3">
                    <Popover placement="left-end">
                      <PopoverTrigger>
                        <div
                          style={
                            {
                              '--src': `url(${
                                require('public/assets_pxcode/ccbeefc78b0ff8df007c0bb8302bb826.svg')
                                  .default
                              })`,
                            } as React.CSSProperties
                          }
                          className={cn(styles.icon21, styles.icon21_layout)}
                        />
                      </PopoverTrigger>
                      <PopoverContent
                        _focus={{ outline: 'none' }}
                        border="none"
                      >
                        <div
                          style={{
                            backgroundColor: 'white',
                            color: '$mode.700',
                            borderRadius: '4px',
                            border: '1px solid #E6E8E9',
                            padding: '12px',
                            width: '356px',
                          }}
                        >
                          <div className="text-xs text-gray-700 font-medium">
                            <div className="font-bold">
                              What is All Rights Reserved?
                            </div>
                            <div className="mb-1">
                              &quot;All rights reserved&quot; is a copyright
                              formality indicating that the copyright holder
                              reserves, or holds for its own use, all the rights
                              provided by copyright law.
                            </div>
                            <div className="mb-1">
                              As a copyright owner, you might use the “All
                              rights reserved” notice to indicate that you
                              retain all rights provided by copyright law. As
                              such, another person cannot reproduce, distribute
                              and/or adapt any part of the work without your
                              permission.
                            </div>
                            <div className="mb-1">
                              If you are not yet sure how you want to share your
                              work with the audience, this is the most
                              protective option you can choose.
                            </div>
                            <a
                              href="https://en.wikipedia.org/wiki/All_rights_reserved"
                              target="_blank"
                              rel="noreferrer"
                              className="underline"
                            >
                              Learn more
                            </a>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
                <div
                  className={`pl-10 text-sm font-medium mt-3.5 ${
                    selectedLicense === 'ARR'
                      ? 'text-blue-500'
                      : 'text-gray-900'
                  }`}
                >
                  Reserve your copyright so no one may use your work without
                  your permission.
                </div>
                <div
                  className={`pl-10 text-xs font-medium mt-3 ${
                    selectedLicense === 'ARR'
                      ? 'text-blue-300'
                      : 'text-gray-400'
                  }`}
                >
                  {t('license3')}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={cn(styles.flex59, styles.flex59_layout)}>
          <div
            className={cn(
              styles.text_body92,
              styles.text_body92_layout,
              'mb-3'
            )}
          >
            Copyright License
          </div>

          <CurrentLicense
            preApprove={preApprove}
            copyright={currLicense}
            handleChange={(e) => {
              if (typeof e === 'string') {
                setCurrLicense(e);
              } else {
                setCurrLicense(e.value);
              }
            }}
            waiveLicense={(e) => {
              setLicenseWaive(e);
            }}
          />
        </div>

        <div className={cn(styles.flex64, styles.flex64_layout)}>
          <div className={cn(styles.text_body93, styles.text_body93_layout)}>
            Your responsibility as a Publisher
          </div>

          <div className={cn(styles.flex65, styles.flex65_layout)}>
            <div className="flex mb-3.5">
              <Checkbox
                size="lg"
                alignItems="baseline"
                isChecked={acknowledge[0]}
                onChange={() => {
                  const newAcknowledge = [...acknowledge];
                  newAcknowledge.splice(0, 1, !acknowledge[0]);
                  setAcknowledge(newAcknowledge);
                }}
              />
              <div
                className="text-sm text-gray-500 font-medium pl-2"
                role="button"
                tabIndex={0}
                onClick={() => {
                  const newAcknowledge = [...acknowledge];
                  newAcknowledge.splice(0, 1, !acknowledge[0]);
                  setAcknowledge(newAcknowledge);
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    const newAcknowledge = [...acknowledge];
                    newAcknowledge.splice(0, 1, !acknowledge[0]);
                    setAcknowledge(newAcknowledge);
                  }
                }}
              >
                I own the copyright or have authority to determine the license
                term for this work.
              </div>
            </div>

            <div className="flex mb-3.5">
              <Checkbox
                size="lg"
                alignItems="baseline"
                isChecked={acknowledge[1]}
                onChange={() => {
                  const newAcknowledge = [...acknowledge];
                  newAcknowledge.splice(1, 1, !acknowledge[1]);
                  setAcknowledge(newAcknowledge);
                }}
              />
              <div
                className="text-sm text-gray-500 font-medium pl-2"
                role="button"
                tabIndex={0}
                onClick={() => {
                  const newAcknowledge = [...acknowledge];
                  newAcknowledge.splice(1, 1, !acknowledge[1]);
                  setAcknowledge(newAcknowledge);
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    const newAcknowledge = [...acknowledge];
                    newAcknowledge.splice(1, 1, !acknowledge[1]);
                    setAcknowledge(newAcknowledge);
                  }
                }}
              >
                I have read and understand the terms of the license.
              </div>
            </div>

            <div className="flex mb-3.5">
              <Checkbox
                size="lg"
                alignItems="baseline"
                isChecked={acknowledge.every((v) => v === true)}
                onChange={() => {
                  if (acknowledge[2]) {
                    const newAcknowledge = [...acknowledge];
                    newAcknowledge.splice(2, 1, !acknowledge[2]);
                    setAcknowledge(newAcknowledge);
                  } else {
                    setAcknowledge([true, true, true]);
                  }
                }}
              />
              <div
                className="text-sm text-blue-500 font-medium pl-2"
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (acknowledge[2]) {
                    const newAcknowledge = [...acknowledge];
                    newAcknowledge.splice(2, 1, !acknowledge[2]);
                    setAcknowledge(newAcknowledge);
                  } else {
                    setAcknowledge([true, true, true]);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    if (acknowledge[2]) {
                      const newAcknowledge = [...acknowledge];
                      newAcknowledge.splice(2, 1, !acknowledge[2]);
                      setAcknowledge(newAcknowledge);
                    } else {
                      setAcknowledge([true, true, true]);
                    }
                  }
                }}
              >
                I acknowledge and agree with all of the above.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function LicenseContainer({ copyright, isLoaded = true }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  if (!isLoaded) {
    return null;
  }
  const license = isLicense(copyright) && LICENSE_FEATURES[copyright];

  return (
    <Flex
      py={3}
      px={4}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8px"
      layerStyle="layer3"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Flex direction="column">
        <div className="font-medium text-xs text-gray-500 mb-2">
          Selected License
        </div>
        <div className="flex">
          <Stack
            spacing={0}
            direction={isMobile ? 'column' : 'row'}
            width="fit-content"
          >
            {license &&
              license.icons.map((icon, i) => (
                <Icon
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  borderRadius="50%"
                  color="black"
                  bg="white"
                  as={icon}
                  boxSize="40px"
                />
              ))}
          </Stack>
          <div className="font-semibold text-xl text-gray-900 my-auto mr-auto pl-2">
            {t(license.name)}
          </div>
        </div>
        {license.name !== 'All Rights Reserved' && (
          <div className="text-xs font-medium mt-2">
            {license.url ? (
              <Link href={license.url} isExternal>
                {t(license.license)}
              </Link>
            ) : (
              <>{t(license.license)}</>
            )}
          </div>
        )}
      </Flex>
    </Flex>
  );
}

export function CurrentLicense({
  changeable = true,
  isLoaded = true,
  copyright,
  waiveLicense,
  handleChange,
  preApprove = false,
}: Props) {
  const { t } = useTranslation();
  const licenseModal = useDisclosure();
  const [licenseList, setLicenseList] = useState<any[]>([]);
  const [licenseOptions, setLicenseOptions] = useState<any[]>([]);
  const ccList = [
    'CC BY 4.0',
    'CC BY-SA 4.0',
    'CC BY-ND 4.0',
    'CC BY-NC 4.0',
    'CC BY-NC-SA 4.0',
    'CC BY-NC-ND 4.0',
  ];
  const ncList = [
    'NC FC-EX 1.0',
    'NC FC-EX-NS 1.0',
    'NC FC-NE 1.0',
    'NC FC-NE-NT 1.0',
    'NC BY 1.0',
    'NC BY-SA 1.0',
    'NC BY-ND 1.0',
    'NC BY-NC 1.0',
    'NC BY-NC-SA 1.0',
    'NC BY-NC-ND 1.0',
  ];
  const cc0List = ['CC0 1.0'];
  const arrList = ['All Rights Reserved'];
  const [waive, setWaive] = useState<boolean>(false);

  useEffect(() => {
    if (preApprove) {
      setWaive(true);
    }
  }, [preApprove]);

  useEffect(() => {
    if (cc0List.includes(copyright)) {
      setLicenseList(cc0List);
    } else if (arrList.includes(copyright)) {
      setLicenseList(arrList);
    } else if (ccList.includes(copyright)) {
      setLicenseList(ccList);
    } else {
      setLicenseList(ncList);
    }
  }, [copyright]);

  useEffect(() => {
    if (licenseList.length > 0) {
      setLicenseOptions(
        dataSort(
          licenseList.map((v) => ({
            value: v,
            label: v,
          })),
          'label'
        )
      );
    }
  }, [licenseList]);

  useEffect(() => {
    waiveLicense(waive);
  }, [waive]);

  if (!isLoaded) {
    return null;
  }

  return (
    <div className="border rounded-2xl bg-white border-gray-200 p-6 max-w-full">
      {copyright !== 'CC0 1.0' && copyright !== 'All Rights Reserved' && (
        <>
          <div className="font-medium text-sm text-gray-700">
            Select a license that you want to apply to this creation.
          </div>
          <div className="pt-3.5">
            <Select
              onKeyDown={(e) => {
                if (!e.shiftKey && e.key === 'Enter') {
                  e.stopPropagation();
                }
              }}
              placeholder={t('Select Category')}
              value={{ value: copyright, label: copyright }}
              onChange={handleChange}
              options={licenseOptions}
              {...LicensePreset()}
            />
          </div>
          {changeable && (
            <div
              className="font-medium text-sm text-blue-500 text-end pt-1.5 pb-3"
              role="button"
              tabIndex={0}
              onClick={() => {
                licenseModal.onOpen();
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  licenseModal.onOpen();
                }
              }}
            >
              Need help selecting a license?
            </div>
          )}
        </>
      )}

      <div>
        <LicenseContainer copyright={copyright} />
        {changeable && isLoaded && (
          <>
            {/* <LicenseModal
              modal={licenseModal}
              copyright={copyright}
              handleChange={handleChange}
            /> */}
            <Modal {...licenseModal} isCentered>
              <ModalOverlay>
                <LicenseChooser
                  licenseType={copyright?.slice(0, 2) === 'NC' ? 'NC' : 'CC'}
                  modal={licenseModal}
                  setState={handleChange}
                />
              </ModalOverlay>
            </Modal>
          </>
        )}
      </div>
      {copyright === 'CC0 1.0' && (
        <div>
          <div className="font-semibold text-sm text-gray-900 my-3">
            Waive Your Copyright
          </div>
          <div className="flex">
            <Checkbox
              size="lg"
              alignItems="baseline"
              isChecked={waive}
              onChange={() => {
                setWaive(!waive);
              }}
            />
            <div
              className="text-sm text-gray-500 font-medium pl-2"
              role="button"
              tabIndex={0}
              onClick={() => {
                setWaive(!waive);
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  setWaive(!waive);
                }
              }}
            >
              {t('licenseWaive')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// type Action =
//   | { type: 'Sharing'; state: Options }
//   | { type: 'Adaptations'; state: Options }
//   | { type: 'Commercial'; state: Options }
//   | { type: 'ALL'; sharing: string; adaptations: Options; commercial: Options };

// export type Sharing = Record<'sharing' | 'adaptations' | 'commercial', Options>;

// const initState: Sharing = {
//   sharing: 'yes',
//   adaptations: 'yes',
//   commercial: 'yes',
// };

// function reducer(state: Sharing, action: Action) {
//   switch (action.type) {
//     case 'Sharing':
//       return {
//         ...state,
//         sharing: action.state,
//       };
//     case 'Adaptations':
//       return {
//         ...state,
//         adaptations: action.state,
//       };
//     case 'Commercial':
//       return {
//         ...state,
//         commercial: action.state,
//       };

//     default:
//       return state;
//   }
// }

// function getLicense({ sharing, adaptations, commercial }: Sharing) {
//   if (sharing === 'yes') {
//     const result = Object.keys(LICENSE_FEATURES)
//       .map((l: keyof typeof LICENSE_FEATURES) => {
//         if (
//           LICENSE_FEATURES[l].adaptations === adaptations &&
//           LICENSE_FEATURES[l].commercial === commercial
//         ) {
//           return LICENSE_FEATURES[l];
//         }
//         return undefined;
//       })
//       .filter(Boolean);

//     if (result.length === 0) {
//       // If not found the license, given the default license
//       return {
//         name: 'CC BY 4.0',
//       };
//     }

//     return result[0];
//   }

//   return {
//     name: 'All Rights Reserved',
//   };
// }

// function LicenseModal({
//   modal,
//   copyright,
//   handleChange,
// }: {
//   modal: UseDisclosureReturn;
//   copyright: string;
//   handleChange?: (c: any) => void;
// }) {
//   const sharable = copyright !== 'All Rights Reserved';
//   const [licenseState, dispatch] = useReducer(reducer, {
//     ...initState,
//     sharing: sharable ? 'yes' : 'no',
//     // If not shareable, given the default value
//     adaptations: sharable ? LICENSE_FEATURES[copyright]?.adaptations : 'yes',
//     commercial: sharable ? LICENSE_FEATURES[copyright]?.commercial : 'yes',
//   });
//   const isAllowSharing = licenseState.sharing === 'yes';
//   const { isOpen, onToggle } = useDisclosure({
//     defaultIsOpen: isAllowSharing,
//   });
//   const { t } = useTranslation();
//   return (
//     <OneTmModal
//       modal={modal}
//       title={t('Change License Type')}
//       okText={t('Update')}
//       onOk={() => {
//         if (handleChange) {
//           const { name } = getLicense(licenseState);
//           handleChange({ value: name, label: name });
//           modal.onClose();
//         }
//       }}
//     >
//       <Box p={6} overflowY="scroll">
//         <Flex
//           mt={6}
//           alignItems="center"
//           justifyContent="space-between"
//           height="24px"
//         >
//           <Text
//             fontWeight="bold"
//             fontSize="sm"
//             color="$mode.600"
//             lineHeight="19px"
//           >
//             {t('Allow sharing?')}
//           </Text>
//           <Switch
//             isChecked={isAllowSharing}
//             onChange={(event) => {
//               onToggle();

//               if (event.target.checked) {
//                 dispatch({ type: 'Sharing', state: 'yes' });
//               } else {
//                 dispatch({ type: 'Sharing', state: 'no' });
//               }
//             }}
//           />
//         </Flex>

//         <Flex mt={2}>
//           <Text textStyle="body3" color="$mode.500">
//             {t('shareMessage1')}
//           </Text>
//         </Flex>

//         <Collapse startingHeight={20} in={isOpen}>
//           <VStack spacing={5} mt={5} px="4px">
//             <Flex direction="column" width="100%" maxHeight="100%">
//               <Text
//                 fontWeight="bold"
//                 fontSize="sm"
//                 color="$mode.600"
//                 lineHeight="19px"
//               >
//                 {t('shareMessage2')}
//               </Text>
//               <RadioGroup
//                 mt={3}
//                 onChange={(state: Options) =>
//                   dispatch({ type: 'Adaptations', state })
//                 }
//                 value={licenseState.adaptations}
//               >
//                 <Stack direction={{ base: 'column', md: 'row' }} spacing={3}>
//                   <Radio size="lg" value="yes">
//                     <Box fontSize="sm" fontWeight="bold" lineHeight="18px">
//                       {t('Yes')}
//                     </Box>
//                   </Radio>
//                   <Radio size="lg" value="no">
//                     <Box fontSize="sm" fontWeight="bold" lineHeight="18px">
//                       {t('No')}
//                     </Box>
//                   </Radio>
//                   <Radio size="lg" value="others">
//                     <Box fontSize="sm" fontWeight="bold" lineHeight="18px">
//                       {t('shareLike')}
//                     </Box>
//                   </Radio>
//                 </Stack>
//               </RadioGroup>
//             </Flex>

//             <Flex direction="column" width="100%" maxHeight="100%">
//               <Text
//                 fontWeight="bold"
//                 fontSize="sm"
//                 color="$mode.600"
//                 lineHeight="19px"
//               >
//                 {t('allowCommercial')}
//               </Text>
//               <RadioGroup
//                 mt={3}
//                 onChange={(state: Options) =>
//                   dispatch({ type: 'Commercial', state })
//                 }
//                 value={licenseState.commercial}
//               >
//                 <Radio size="lg" value="yes" mr={3}>
//                   <Box fontSize="sm" fontWeight="bold" lineHeight="18px">
//                     {t('Yes')}
//                   </Box>
//                 </Radio>
//                 <Radio size="lg" value="no">
//                   <Box fontSize="sm" fontWeight="bold" lineHeight="18px">
//                     {t('No')}
//                   </Box>
//                 </Radio>
//               </RadioGroup>
//             </Flex>
//           </VStack>
//         </Collapse>
//         <LicenseContainer
//           sharable={isAllowSharing}
//           copyright={t(getLicense(licenseState).name)}
//         />
//       </Box>
//     </OneTmModal>
//   );
// }
