export const Creations1Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Create with 1TM
      </h1>
      <img
        src="/images/assets/tutorial/creations-1.png"
        alt="creations 1"
        className="w-full"
      />
    </>
  );
};

export const Creations1Footer = ({ setNextModal }: { setNextModal?: any }) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Choose a Type
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        First, select the desired format for a new creation. Note the
        description of the strengths of the two different types.
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('creations2');
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Next
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        1 / 4
      </h6>
    </>
  );
};
