import { useMutation, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { CacheKey } from 'src/constants/cache-key';
import { axios } from 'src/lib/axios';
import { logger } from 'src/lib/logger';
import { RestResponse } from 'src/types';
import { useEpisodeFullQuery } from 'src/app/reppl/episode/hooks';
import { useContext } from 'react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { useTopicFullQuery } from 'src/app/space/hooks';
import { EnthuseObjectTypes } from '../enum';
import type { FullArticle } from '../types';

interface EnthuseRequest {
  objectParentId?: string;
  objectId: string;
  objectType: EnthuseObjectTypes;
  /** Object Type Owner */
  ownerID: number;
  enthuse?: boolean;
}

function useEnthuseMutation(
  article: any,
  updater: (prev: any, values: EnthuseRequest) => any
) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const queryKey = [CacheKey.creation, article?.contentID];
  const { isLogin: isAuth } = useContext(UserContext) as UserContextType;
  return useMutation(
    (values: EnthuseRequest) => {
      if (!isAuth) {
        router.push({ query: { ...router.query, page: 'signIn' } });
        return;
      }
      return axios.request<RestResponse>({
        method: !article.isEnthused ? 'post' : 'delete',
        url: '/enthuse',
        data: values,
      });
    },
    {
      onMutate: (values) => {
        queryClient.cancelQueries(queryKey);
        const previousCache = queryClient.getQueryData<FullArticle>(queryKey);
        if (isAuth) {
          const cache = queryClient.setQueryData(
            queryKey,
            updater(previousCache, values)
          );
          return cache;
        }
      },
      onError: (err) => {
        logger.error(err);
      },
      onSettled: () => {
        queryClient.invalidateQueries([CacheKey.creation, article?.contentID]);
      },
    }
  );
}

function useEnthuseCommentMutation(id: any, updater: any) {
  const queryClient = useQueryClient();
  const queryKey = [CacheKey.comment, id];
  const router = useRouter();
  const { isLogin: isAuth } = useContext(UserContext) as UserContextType;
  return useMutation(
    (values: EnthuseRequest) => {
      if (!isAuth) {
        router.push({ query: { ...router.query, page: 'signIn' } });
        return;
      }
      return axios.request<RestResponse>({
        method: values.enthuse ? 'post' : 'delete',
        url: '/enthuse',
        data: { objectID: values.objectId, objectType: values.objectType },
      });
    },
    {
      onMutate: (values) => {
        queryClient.cancelQueries(queryKey);
        const previousCache = queryClient.getQueryData<any>(queryKey);
        const cache = queryClient.setQueryData(
          queryKey,
          updater(previousCache, values)
        );
        return cache;
      },
      onError: (err) => {
        logger.error(err);
      },
      onSettled: () => {
        queryClient.invalidateQueries([CacheKey.creation, id]);
      },
    }
  );
}

export function useAgoraFeedEnthuseMutation(article: any, setNewData?: any) {
  return useEnthuseMutation(article, () => {
    let enthuseReturn;
    if ('enthusesCount' in article) {
      enthuseReturn = {
        ...article,
        isEnthused: !article.isEnthused,
        enthuseCount: article.isEnthused
          ? article.enthusesCount - 1
          : article.enthusesCount + 1,
      };
    } else {
      enthuseReturn = {
        ...article,
        isEnthused: !article.isEnthused,
        enthuseCount: article.isEnthused
          ? article.enthuseCount - 1
          : article.enthuseCount + 1,
      };
    }
    if (setNewData) {
      setNewData(enthuseReturn);
    }
    return enthuseReturn;
  });
}

export function useArticleEnthuseMutation(id: string, setNewData?: any) {
  /* eslint-disable */
  const { data: episode, isLoading } = useEpisodeFullQuery(id);
  /* eslint-enable */

  return useEnthuseMutation(episode, (prev) => {
    if (setNewData) {
      setNewData({
        ...prev,
        isEnthused: !prev.isEnthused,
        enthuseCount: prev.isEnthused
          ? prev.enthuseCount - 1
          : prev.enthuseCount + 1,
      });
    }
    return {
      ...prev,
      isEnthused: !prev.isEnthused,
      enthuseCount: prev.isEnthused
        ? prev.enthuseCount - 1
        : prev.enthuseCount + 1,
    };
  });
}

export function useFeedCommentEnthuseMutation(id: string) {
  return useEnthuseCommentMutation(id, () => {});
}

export function useCommentEnthuseMutation(id: string) {
  return useEnthuseCommentMutation(id, (prevCache, values) => {
    if (values.objectParentId) {
      return {
        ...prevCache,
        comments: prevCache.map((parentComment) => ({
          ...parentComment,
          child: parentComment.child.map((comment) => {
            if (comment.id === values.objectId) {
              return {
                ...comment,
                isEnthused: !comment.isEnthused,
                enthuseCount: comment.isEnthused
                  ? comment.enthuseCount - 1
                  : comment.enthuseCount + 1,
              };
            }
            return comment;
          }),
        })),
      };
    }
    return {
      ...prevCache,
      comments: prevCache.map((parentComment) => {
        if (values.objectId === parentComment.id) {
          return {
            ...parentComment,
            isEnthused: !parentComment.isEnthused,
            enthuseCount: parentComment.isEnthused
              ? parentComment.enthuseCount - 1
              : parentComment.enthuseCount + 1,
          };
        }
        return parentComment;
      }),
    };
  });
}
export function useTopicEnthuseMutation(id: string, setNewData?: any) {
  /* eslint-disable */
  const { data: topic, isLoading } = useTopicFullQuery(id);
  /* eslint-enable */

  return useEnthuseMutation(topic, (prev) => {
    if (setNewData) {
      setNewData({
        ...prev,
        isEnthused: !prev.isEnthused,
        enthuseCount: prev.isEnthused
          ? prev.enthuseCount - 1
          : prev.enthuseCount + 1,
      });
    }
    return {
      ...prev,
      isEnthused: !prev.isEnthused,
      enthuseCount: prev.isEnthused
        ? prev.enthuseCount - 1
        : prev.enthuseCount + 1,
    };
  });
}
