import { useEpisodeFullQuery } from 'src/app/reppl/episode/hooks';
import { useDisclosure } from '@chakra-ui/hooks';
import { useEffect } from 'react';
import Router from 'next/router';
import { SubmissionHistoryModal } from './submission-history';

const SubmissionHistory = ({ id }: { id: string }) => {
  const modal = useDisclosure();
  const { data: episode } = useEpisodeFullQuery(id);
  useEffect(() => {
    modal.onOpen();
  }, []);

  const article = {
    Sender: {
      avatarUrl: episode?.author?.profileImgURL,
      username: episode?.author?.username,
    },
    repplID: episode?.sourceID,
    episodeID: episode?.contentID,
    contentID: episode?.contentID,
    status: episode?.status,
    episodeTitle: episode?.title,
    episodeCoverImgUrl: episode?.coverImgURL,
  };

  return (
    <SubmissionHistoryModal
      modal={{
        ...modal,
        onClose: () => {
          modal.onClose();
          Router.back();
        },
      }}
      article={article}
      isReppl={episode?.classType === 'REPPL'}
      id={episode?.classType === 'REPPL' && episode?.sourceID}
    />
  );
};

export default SubmissionHistory;
