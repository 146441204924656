import * as React from 'react';
import { Svg } from '../svg';

function SvgCloseButton({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.927 2.927c3.904-3.903 10.24-3.903 14.143 0 3.903 3.904 3.903 10.24 0 14.143-3.904 3.903-10.24 3.903-14.143 0-3.903-3.904-3.903-10.24 0-14.143zm7.071 8.486l2.829 2.828 1.414-1.414-2.828-2.829L14.24 7.17l-1.414-1.414-2.829 2.828L7.17 5.756 5.756 7.17l2.828 2.828-2.828 2.829L7.17 14.24l2.828-2.828z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCloseButton);
export default ForwardRef;
