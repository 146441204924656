import * as React from 'react';
import { Svg } from '../svg';

function SvgLogoBeta({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 100 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M9.968 0v19.6H3.36V4.984H0V0h9.968zM18.815 5.124h-5.74V0h18.088v5.124h-5.74V19.6h-6.608V5.124zM51.78 19.6l-.057-8.932-4.256 7.168h-2.912l-4.256-6.86V19.6h-6.02V0h5.432l6.384 10.444L52.311 0h5.432l.056 19.6h-6.02zM71.967 4.032c.48.152.856.408 1.128.768.272.352.408.788.408 1.308 0 .736-.288 1.304-.864 1.704-.568.392-1.4.588-2.496.588H65.8V0h4.104c1.024 0 1.808.196 2.352.588.552.392.828.924.828 1.596 0 .408-.1.772-.3 1.092-.192.32-.464.572-.816.756zm-4.236-2.568v1.98h1.932c.48 0 .844-.084 1.092-.252.248-.168.372-.416.372-.744s-.124-.572-.372-.732c-.248-.168-.612-.252-1.092-.252h-1.932zM70 6.936c.512 0 .896-.084 1.152-.252.264-.168.396-.428.396-.78 0-.696-.516-1.044-1.548-1.044h-2.268v2.076H70zM81.72 6.84V8.4h-6.505V0h6.348v1.56h-4.416v1.824h3.9v1.512h-3.9V6.84h4.572zM85.246 1.584h-2.688V0h7.32v1.584H87.19V8.4h-1.944V1.584zM96.274 6.6h-3.9l-.744 1.8h-1.992L93.382 0h1.92l3.756 8.4h-2.04l-.744-1.8zm-.612-1.476L94.33 1.908l-1.332 3.216h2.664z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgLogoBeta);
export default ForwardRef;
