/* eslint-disable prefer-destructuring */
export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD = process.env.NODE_ENV === 'production';
export const isBrowser = () => typeof window !== 'undefined';
export const API_V2 = process.env.NEXT_PUBLIC_BACKEND_API_V2;
export const API = 'https://dev-api.1tm.io';
// process.env.NODE_ENV === 'development'
//   ? 'https://dev.1tm.io'
//   : isBrowser() && `${window.location.origin}`;

export const NEXT_PUBLIC_UNSPLASH_ACCESS_KEY =
  process.env.NEXT_PUBLIC_UNSPLASH_ACCESS_KEY;

export const isTouchDevice =
  isBrowser() &&
  ('ontouchstart' in document.documentElement ||
    window.navigator.maxTouchPoints > 0);
