import cn from 'classnames';
import SvgEmailIcon from 'src/components/icons/email-icon';
import SvgWalletIcon from 'src/components/icons/wallet-icon';
import styles from './Sign_in_email.module.scss';

export default function SignUpTabs(props: {
  setActiveBlock: Function;
  activeBlock: string;
}) {
  const { setActiveBlock, activeBlock } = props;
  return (
    <>
      <span className={cn(styles.sign_in_selection_heading)}>
        Choose how you’d like to sign in:
      </span>
      <div className={cn(styles.sign_in_selection_container)}>
        <button
          className={cn(
            styles.email_tab_button,
            styles.tab_button,
            `${activeBlock === 'email' ? styles.button_active : ''}`
          )}
          type="button"
          onClick={() => setActiveBlock('email')}
        >
          <SvgEmailIcon className={cn(styles.tab_button_icon)} />
          <span className={cn(styles.tab_button_text)}>Email</span>
        </button>
        <button
          className={cn(
            styles.wallet_tab_button,
            styles.tab_button,
            `${activeBlock === 'wallet' ? styles.button_active : ''}`
          )}
          type="button"
          onClick={() => setActiveBlock('wallet')}
        >
          <SvgWalletIcon className={cn(styles.tab_button_icon)} />
          <span className={cn(styles.tab_button_text)}>Wallet</span>
        </button>
      </div>
    </>
  );
}
