import { useMutation } from 'react-query';
import { useToast } from '@chakra-ui/react';
import * as ImgService from 'src/service/image';

type OptionalReq = Partial<Omit<ImgService.ImageRequest, 'data'>>;

export function useUploadImage(
  {
    id,
    areaType,
    imgType,
    updateProgress,
    getCancelFunction,
  }: {
    id?: string;
    areaType: ImgService.ImgUploadAreaType;
    imgType?: ImgService.ImgUploadType;
    updateProgress?: (progress: ProgressEvent) => void;
    getCancelFunction?: (cancelFunction: Function) => void;
  },
  updater?: (
    response: ImgService.ImgUploadResponse,
    values: ImgService.ImageRequest
  ) => void,
  errorDescription: string = 'Upload failure, please try again later.'
) {
  const toast = useToast();
  return useMutation(
    (req: Omit<ImgService.ImageRequest, keyof OptionalReq> & OptionalReq) =>
      ImgService.postUploadImage({
        id,
        areaType,
        imgType,
        updateProgress,
        getCancelFunction,
        ...req,
      }),
    {
      onSuccess: (response, values) => {
        if (updater && response.code === 1000) {
          updater(response, { areaType, imgType, ...values });
        }
      },
      onError: () => {
        if (!getCancelFunction) {
          toast({
            title: errorDescription,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom',
          });
        }
      },
    }
  );
}
