import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react';

import SvgTrash from 'src/components/icons/trash';
import SvgWarningV3 from 'src/components/icons/warning-v3';

export function DeleteModal({
  isOpen,
  onClose,
  title,
  subTitle,
  text,
  cancelBtnText = 'Cancel',
  okBtnText = 'Remove',
  isDeleting = false,
  onOk,
  modalVersion = 1,
}: UseDisclosureReturn & {
  title: string;
  subTitle?: string;
  text?: string;
  cancelBtnText?: string;
  okBtnText?: string;
  isDeleting?: boolean;
  onOk: () => void;
  modalVersion?: number;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} isCentered>
      <ModalOverlay>
        <ModalContent
          pt="24px"
          pb="40px"
          px="48px"
          borderRadius={modalVersion === 1 ? '6px' : '24px'}
          mt={{ base: 0, md: '3.75rem' }}
          minWidth={{ base: '100vw', md: '480px' }}
          minHeight={{ base: '100vh', md: 'auto' }}
        >
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            mt={modalVersion === 1 ? '42px' : '33px'}
          >
            {modalVersion === 1 && <SvgTrash color="error" fontSize="48px" />}
            {modalVersion === 2 && (
              <div
                className="flex justify-center items-center bg-outrageousOrange-500 bg-opacity-10 rounded-full"
                style={{ width: '75px', height: '75px' }}
              >
                <SvgWarningV3 fontSize="32px" />
              </div>
            )}
            <Heading
              textStyle="h4"
              mt={modalVersion === 1 ? '32px' : '25px'}
              textAlign="center"
              maxWidth="100%"
              className={`${
                modalVersion === 2
                  ? 'text-gray-700 font-semibold'
                  : 'font-extrabold'
              }`}
            >
              <Text
                fontSize={24}
                noOfLines={2}
                className="font-semibold text-gray-700"
              >
                {title}
              </Text>
              <Text>{subTitle}</Text>
            </Heading>
            {text ? (
              <Text
                textStyle="body0"
                color="#89969F"
                fontWeight="medium"
                mt={3}
                mb={6}
                textAlign="center"
              >
                {text}
              </Text>
            ) : (
              <Box mt={3} mb={6} />
            )}
          </Flex>
          <ButtonGroup justifyContent="center">
            <Button
              variant="ghost"
              rounded="ellipse"
              colorScheme="$mode"
              onClick={onClose}
            >
              {cancelBtnText}
            </Button>
            <Button
              colorScheme={modalVersion === 1 ? 'red' : '#FF6633'}
              bgColor={modalVersion === 1 ? '#EB5757' : '#FF6633'}
              rounded="ellipse"
              isLoading={isDeleting}
              onClick={() => {
                onOk();
                onClose();
              }}
            >
              {okBtnText}
            </Button>
          </ButtonGroup>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
