import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useState } from 'react';

import { CreationsStartBody, CreationsStartFooter } from './creations-start';
import { Creations1Body, Creations1Footer } from './creations-1';
import { Creations2Body, Creations2Footer } from './creations-2';
import { Creations3Body, Creations3Footer } from './creations-3';
import { Creations4Body, Creations4Footer } from './creations-4';

const Creations = ({ modal }: { modal: any }) => {
  const [tutorialModalContent, setTutorialModalContent] = useState(
    'creationsStart'
  );

  return (
    <>
      <Modal
        isOpen={modal.isOpen}
        onClose={() => {
          modal.onClose();
          modal.openChecklistModal();
          setTutorialModalContent('creationsStart');
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalCloseButton color="gray.500" />
          <ModalBody px="60px" pt="40px" pb="24px" bg="white">
            {tutorialModalContent === 'creationsStart' && (
              <CreationsStartBody />
            )}
            {tutorialModalContent === 'creations1' && <Creations1Body />}
            {tutorialModalContent === 'creations2' && <Creations2Body />}
            {tutorialModalContent === 'creations3' && <Creations3Body />}
            {tutorialModalContent === 'creations4' && <Creations4Body />}
          </ModalBody>
          <ModalFooter
            flexDirection="column"
            alignItems="center"
            bg="gray.100"
            px="60px"
            py="25px"
          >
            {tutorialModalContent === 'creationsStart' && (
              <CreationsStartFooter setNextModal={setTutorialModalContent} />
            )}
            {tutorialModalContent === 'creations1' && (
              <Creations1Footer setNextModal={setTutorialModalContent} />
            )}
            {tutorialModalContent === 'creations2' && (
              <Creations2Footer setNextModal={setTutorialModalContent} />
            )}
            {tutorialModalContent === 'creations3' && (
              <Creations3Footer setNextModal={setTutorialModalContent} />
            )}
            {tutorialModalContent === 'creations4' && (
              <Creations4Footer
                completed={modal.completed}
                closeModal={() => {
                  modal.onClose();
                  modal.openChecklistModal();
                  setTutorialModalContent('creationsStart');
                }}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Creations;
