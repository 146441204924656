import * as React from 'react';
import { Svg } from '../svg';

function SvgStudio({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M15.833 17.5H4.167c-.917 0-1.667-.75-1.667-1.667V4.167c0-.917.75-1.667 1.667-1.667h11.666c.917 0 1.667.75 1.667 1.667v11.666c0 .917-.75 1.667-1.667 1.667z"
        stroke="#4F5356"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.834a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM14.167 14.584C13 13.75 11.583 13.334 10 13.334c-1.583 0-3 .5-4.167 1.25"
        stroke="#4F5356"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgStudio);
export default ForwardRef;
