import { Styles, mode } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: (props) => ({
    html: {
      height: '-webkit-fill-available',
    },
    body: {
      fontFamily: 'body',
      color: '$mode.800',
      bg: mode('white', 'bgDark')(props),
      transition: 'background-color 0.2s',
      lineHeight: 'base',
      minH: '100vh',
      minHeight: '-webkit-fill-available',
      ...mode(
        {
          '--mode-border-width': '1px',
          '--modeBg': 'white',
          '--mode50': '#FAFBFB',
          '--mode100': '#F3F3F4',
          '--mode200': '#E6E8E9',
          '--mode300': '#D3D6D8',
          '--mode400': '#BBC0C5',
          '--mode500': '#9B9FA4',
          '--mode600': '#74787D',
          '--mode700': '#4F5356',
          '--mode800': '#36383A',
          '--mode900': '#242526',
        },
        {
          '--mode-border-width': '0',
          '--modeBg': 'var(--mode50)',
          '--mode50': '#28282A',
          '--mode100': '#313235',
          '--mode200': '#414348',
          '--mode300': '#51535C',
          '--mode400': '#737682',
          '--mode500': '#8C8F9B',
          '--mode600': '#AFB2BB',
          '--mode700': '#C7C9D1',
          '--mode800': '#E8E9ED',
          '--mode900': '#F9F9FB',
        }
      )(props),
    },

    '*::placeholder': {
      color: mode('$mode.400', 'whiteAlpha.400')(props),
    },

    '*, *::before, &::after': {
      borderColor: mode('$mode.200', 'whiteAlpha.300')(props),
      wordWrap: 'break-word',
    },
    ':focus': {
      outline: 'none',
    },

    '#nprogress': {
      pointerEvents: 'none',
    },

    '#nprogress .bar': {
      background: `${props.theme.colors.black}`,
      position: 'fixed',
      zIndex: 1000,
      top: 0,
      left: 0,
      width: '100%',
      height: '2px',
    },

    '#nprogress::after': {
      content: '""',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '2px',
      background: 'transparent',
    },

    // editor.js heading: change heading can't apply chakra-ui style, so need to define in here.
    'h1.ce-header': {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    'h2.ce-header': {
      fontSize: '2rem',
      fontWeight: 700,
    },
    'h3.ce-header': {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    'h4.ce-header': {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    'h5.ce-header': {
      fontSize: '1rem',
      fontWeight: 700,
    },
    'h6.ce-header': {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
  }),
};

export default styles;
