import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { useIsLg } from 'src/hooks/use-is-mobile';
import { ReactNode } from 'react';

export const DetailCommentModal = ({
  id,
  modal,
  children,
}: {
  id: string;
  modal?: any;
  children?: ReactNode;
}) => {
  const isLg = useIsLg();
  const pushStateCommentUrl = () => {
    window.history.pushState('#comments', ' ', ' ');
  };
  return (
    <Modal
      id={id}
      isOpen={modal.isOpen}
      onClose={() => {
        pushStateCommentUrl();
        modal.onClose();
      }}
      motionPreset="slideInRight"
    >
      <ModalOverlay />
      <ModalContent
        position="absolute"
        right={0}
        top={0}
        height="100vh"
        maxH="100vh"
        maxW="440px"
        overflow="hidden auto"
        minW={!isLg ? '410px' : 'auto'}
        width={isLg ? '100vw' : '30vw'}
        borderRadius={0}
      >
        <ModalHeader
          paddingX="40px"
          paddingTop="40px"
          paddingBottom="32px"
          background="#F3F3F4"
        >
          <h1 className="text-xl font-semibold text-gray-900 mb-4">Comments</h1>
          <p className="text-sm text-gray-700 font-medium">
            What are your thoughts?
          </p>
        </ModalHeader>
        <ModalCloseButton
          _hover={{ background: 'none' }}
          _focus={{ border: 'none' }}
          onClick={() => {
            pushStateCommentUrl();
          }}
        />
        <ModalBody display="flex" justifyContent="center" padding="40px">
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
