import { useMutation } from 'react-query';
import { axios } from 'src/lib/axios';
import { logger } from 'src/lib/logger';
import { RestResponse } from 'src/types';

export function useTutorialMutation() {
  return useMutation(
    (values: any) => {
      return axios
        .patch<RestResponse>('/tutorial', values)
        .then((res) => res.data);
    },
    {
      onError: (err) => {
        logger.error(err);
      },
    }
  );
}
