import * as React from 'react';
import { Svg } from '../svg';

function SvgPick({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M5.838 4.568V17.4c0 .782.374 1.482.969 1.902l7.186 5.098 7.187-5.098c.595-.42.98-1.12.98-1.902V4.568L13.993 10.4 5.838 4.568z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPick);
export default ForwardRef;
