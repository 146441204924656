import Router from 'next/router';
import { NextPageContext } from 'next';

export default function redirect({
  context,
  target,
  statusCode = 303,
}: {
  context?: NextPageContext;
  target: string;
  statusCode?: number;
}) {
  if (context?.res) {
    // server
    // 303: "See other"
    context.res.writeHead(statusCode, { Location: target });
    context.res.end();
  } else {
    Router.replace(target);
  }
}
