import { useTutorialMutation } from 'src/app/explore/use-tutorial';

export const CheckOutAndJoinARepplBody = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Join the Space
      </h1>
      <img
        src="/images/assets/tutorial/check-out-and-join-a-reppl.png"
        alt="check out and join a reppl"
        className="w-full"
      />
    </>
  );
};

export const CheckOutAndJoinARepplFooter = ({
  closeModal,
  completed,
}: {
  closeModal?: any;
  completed: boolean;
}) => {
  const { mutateAsync: updateTutorial } = useTutorialMutation();
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        What is a Space?
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        a Space is product of the cumulative efforts of a team of creators,
        striving to push the boundaries of creation in a collective and
        collaborative manner. Start building your team!
      </p>
      <button
        type="button"
        onClick={async () => {
          if (!completed) {
            await updateTutorial({ step: 'join_reppl' });
          }
          closeModal();
        }}
        className="px-9 py-2.5 mb-6 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Got it
      </button>
    </>
  );
};
