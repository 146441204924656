import * as React from 'react';
import { Svg } from '../svg';

function SvgTrash({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M30 8.663h10v3.91h-4v25.421c0 .519-.21 1.016-.586 1.383a2.024 2.024 0 01-1.414.572H6c-.53 0-1.04-.206-1.414-.572A1.934 1.934 0 014 37.994v-25.42H0V8.662h10V2.796c0-.518.21-1.016.586-1.382A2.023 2.023 0 0112 .84h16c.53 0 1.04.206 1.414.573.375.366.586.864.586 1.382v5.867zm2 3.91H8V36.04h24V12.573zM14 18.44h4v11.732h-4V18.44zm8 0h4v11.732h-4V18.44zM14 4.752v3.91h12v-3.91H14z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTrash);
export default ForwardRef;
