import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import cn from 'classnames';
import SvgSucces from 'src/components/icons/success-icon';
import styles from './SuccessModal_styles.module.scss';

export default function SuccessModal(props: {
  isOpen: boolean;
  onClose: any;
  heading: string;
  paragraph: string;
  buttonText: string;
  buttonAction?: any;
}) {
  const {
    isOpen,
    onClose,
    heading,
    paragraph,
    buttonText,
    buttonAction,
  } = props;
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered allowPinchZoom>
        <ModalOverlay>
          <ModalContent backgroundColor="unset">
            <div className={cn(styles.main_container)}>
              <SvgSucces boxSize="109px" />
              <h3>{heading}</h3>
              <p>{paragraph}</p>
              <button type="button" onClick={buttonAction || onClose}>
                {buttonText}
              </button>
            </div>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
}
