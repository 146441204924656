/**
 * Internal dependencies
 */
import { INITIAL_STATE } from './constants';
import { updateFilter, updateFilterResult } from '../reducers';
import * as types from '../reducers/types';

function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case types.UPDATE_ALL_FILTERS:
      return { ...state, ...payload.filters };

    case types.UPDATE_FILTER:
      return updateFilter(state, payload);

    case types.UPDATE_FILTER_RESULT:
      return updateFilterResult(state, payload);

    default:
      return state;
  }
}

export default reducer;
