import * as React from 'react';
import { Svg } from '../svg';

function SvgBack({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16 7H3.83l5.59-5.59L8 0 0 8l8 8 1.41-1.41L3.83 9H16V7z"
        fill="#74787D"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgBack);
export default ForwardRef;
