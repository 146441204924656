import * as React from 'react';
import { Svg } from '../svg';

function SvgIllustrationSculpting({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 186 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#illustration_sculpting_svg__clip0_847:24565)">
        <path
          opacity={0.1}
          d="M186.001 36.38c.021-16.135-12.931-29.45-29.06-29.864a29.882 29.882 0 00-18.142 5.544 29.85 29.85 0 00-11.14 15.345 30.144 30.144 0 00-12.372.571 88.57 88.57 0 01-44.56 0 30.143 30.143 0 00-12.372-.57 29.847 29.847 0 00-11.14-15.345 29.88 29.88 0 00-18.141-5.545C12.929 6.931-.014 20.247.014 36.38a29.81 29.81 0 004.115 15.085 29.836 29.836 0 0011.188 10.931 29.694 29.694 0 00-4.507 15.76c0 16.364 13.35 29.774 29.725 29.843a29.711 29.711 0 0016.59-4.942 29.872 29.872 0 0035.876-2.24 29.877 29.877 0 0035.89 2.24 29.703 29.703 0 0016.589 4.949c16.374-.07 29.73-13.48 29.725-29.842a29.701 29.701 0 00-4.507-15.76 29.839 29.839 0 0011.189-10.935 29.81 29.81 0 004.114-15.089z"
          fill="#97BAFF"
        />
        <path
          d="M72.69 47.992s-8.838-.623-10.877.056c-2.04.68-6.118 9.68-6.118 9.68l13.086.51 5.608-1.189-1.7-9.057z"
          fill="#2F2E41"
        />
        <path
          d="M116.365 108l.51-21.448 15.635-.29V108h-16.145z"
          fill="#3F3D56"
        />
        <path
          d="M86.18 62.506c1.044 1.42 1.213 3.28 1.343 5.035.254 3.397.507 6.794.758 10.19.17 2.281.34 4.586-.057 6.844-.022.18-.08.353-.17.51-.18.268-.51.375-.833.448-3.117.729-6.371.014-9.492-.701-.574-4.297.356-8.672 1.838-12.737.904-2.482 2.01-4.88 3.125-7.27.457-.977.887-2.747 1.633-3.511.843-.878 1.295.431 1.856 1.192z"
          fill="#2F2E41"
        />
        <path
          opacity={0.1}
          d="M86.18 62.506c1.044 1.42 1.213 3.28 1.343 5.035.254 3.397.507 6.794.758 10.19.17 2.281.34 4.586-.057 6.844-.022.18-.08.353-.17.51-.18.268-.51.375-.833.448-3.117.729-6.371.014-9.492-.701-.574-4.297.356-8.672 1.838-12.737.904-2.482 2.01-4.88 3.125-7.27.457-.977.887-2.747 1.633-3.511.843-.878 1.295.431 1.856 1.192z"
          fill="#000"
        />
        <path
          d="M96.662 79.304c.698-.724 1.417-1.46 2.321-1.902 1.123-.55 2.418-.586 3.654-.792.97-.17 1.927-.434 2.911-.485.984-.051 2.039.156 2.755.825.85.781 1.067 2.002 1.24 3.137.417 2.74.736 5.75-.749 8.089-1.004 1.583-2.743 2.62-4.578 2.997-1.139.235-2.312.235-3.474.233l-10.91-.012c-.203 0-.063-3.169-.016-3.463a6.091 6.091 0 011.103-2.673c1.623-2.141 3.872-4.015 5.743-5.954z"
          fill="#FBBEBE"
        />
        <path
          opacity={0.1}
          d="M96.662 79.304c.698-.724 1.417-1.46 2.321-1.902 1.123-.55 2.418-.586 3.654-.792.97-.17 1.927-.434 2.911-.485.984-.051 2.039.156 2.755.825.85.781 1.067 2.002 1.24 3.137.417 2.74.736 5.75-.749 8.089-1.004 1.583-2.743 2.62-4.578 2.997-1.139.235-2.312.235-3.474.233l-10.91-.012c-.203 0-.063-3.169-.016-3.463a6.091 6.091 0 011.103-2.673c1.623-2.141 3.872-4.015 5.743-5.954z"
          fill="#000"
        />
        <path
          d="M53.05 39.657l-2.114 2.77c-1.516 1.989-3.282 4.13-5.755 4.505-.152.52.357.979.81 1.276 6.428 4.215 13.921 6.914 21.603 7.259.989.045 2.21-.094 2.587-1.011.34-.83-.283-1.734-.945-2.34-.995-.912-2.27-1.8-2.368-3.145-.064-.904.446-1.733.741-2.59.403-1.169.412-2.43.416-3.665 0-.445 0-.906-.17-1.316a2.912 2.912 0 00-.68-.902c-2.305-2.278-5.417-3.397-8.355-4.601-.986-.406-2.798-1.729-3.661-.593-.404.532-.4 1.529-.697 2.164-.393.777-.866 1.51-1.412 2.189z"
          fill="#FBBEBE"
        />
        <path
          opacity={0.1}
          d="M53.05 39.657l-2.114 2.77c-1.516 1.989-3.282 4.13-5.755 4.505-.152.52.357.979.81 1.276 6.428 4.215 13.921 6.914 21.603 7.259.989.045 2.21-.094 2.587-1.011.34-.83-.283-1.734-.945-2.34-.995-.912-2.27-1.8-2.368-3.145-.064-.904.446-1.733.741-2.59.403-1.169.412-2.43.416-3.665 0-.445 0-.906-.17-1.316a2.912 2.912 0 00-.68-.902c-2.305-2.278-5.417-3.397-8.355-4.601-.986-.406-2.798-1.729-3.661-.593-.404.532-.4 1.529-.697 2.164-.393.777-.866 1.51-1.412 2.189z"
          fill="#000"
        />
        <path
          d="M66.656 44.229c7.884 0 14.276-6.387 14.276-14.266 0-7.879-6.392-14.266-14.276-14.266-7.884 0-14.275 6.387-14.275 14.266 0 7.879 6.391 14.266 14.275 14.266z"
          fill="#FBBEBE"
        />
        <path
          d="M62.893 52.98c-2.04.437-4.153-.18-6.154-.79-2.878-.878-5.98-1.917-7.659-4.415-.39-.583-.719-1.257-1.329-1.602-1.317-.745-2.841.459-3.941 1.497-5.364 5.06-12.576 8.352-16.434 14.632-2.2 3.585-3.088 7.831-3.319 12.033-.117 2.13-.071 4.305.566 6.34 1.917 6.113 8.65 9.702 10.746 15.756 1.273 3.677.353 7.23-.187 11.083l51.045.106a132.76 132.76 0 01-1.219-21.909c.043-1.574.114-3.162.502-4.69.316-1.245.85-2.46.861-3.736.038-2.35-1.618-4.552-1.27-6.875.349-2.323 2.623-4.246 2.2-6.535-.51-2.718-1.095-5.642-2.24-8.152-.595-1.311-1.265-2.62-2.272-3.651-1.008-1.031-2.283-1.723-3.595-2.29a24.35 24.35 0 00-9.896-1.987 2.124 2.124 0 00-.9.148c-.838.4-.831 1.56-1.083 2.456-.486 1.732-2.71 3.135-4.422 2.581z"
          fill="#2F2E41"
        />
        <path
          d="M82.095 72.173l-.308.144 2.224 4.772.309-.144-2.225-4.772z"
          fill="#D0D2D5"
        />
        <path
          d="M84.235 74.548l-1.848.86 6.961 14.933 1.849-.86-6.962-14.933z"
          fill="#97BAFF"
        />
        <path
          d="M27.544 84.975c-.63 4.37 1.372 8.632 2.334 12.944.712 3.191.85 6.482.86 9.752.096-.031.08.032.17 0h34.886c5.584-4.98 11.325-10.032 18.668-11.483 1.054-.207 2.128-.34 3.144-.679 3.882-1.308 6.064-5.326 7.764-9.05.603-1.323 1.19-2.91.45-4.165a3.67 3.67 0 00-1.006-1.04 8.802 8.802 0 00-8.02-1.234c-4.904 1.71-7.308 7.32-11.71 10.066-2.038 1.269-4.41 1.868-6.6 2.843-3.271 1.462-6.118 3.772-9.413 5.172-4.465 1.893-9.478 2.009-14.106 3.459 1.674-1.92 2.25-4.63 1.946-7.158-.304-2.529-1.407-4.898-2.756-7.058-1.21-1.936-2.762-3.839-4.929-4.545-3.837-1.248-7.718 1.46-11.682 2.175z"
          fill="#FBBEBE"
        />
        <path
          d="M53.315 36.507c1.457-1.126 1.737-2.077 2.1-3.88.225-1.13.249-2.414 1.072-3.228 1.67-1.637 4.907.192 6.678-1.336 1.105-.951 1.049-2.763 2.079-3.794.756-.76 1.917-.916 2.99-.963a25.501 25.501 0 014.84.248c1.143.17 2.282.414 3.437.406 2.311-.017 4.48-1.043 6.562-2.047a.925.925 0 01-.779-.521c.806-.425 1.64-.795 2.497-1.106-.224-.69-1.73-.14-1.807-.863a.777.777 0 01.198-.509l1.468-2.092c-1.396.356-2.804-.608-3.683-1.75-.879-1.14-1.468-2.508-2.5-3.513-2.065-2.01-5.283-2.064-8.157-1.992l-5.325.13c-2.47.061-4.969.128-7.362.75-1.087.281-2.16.679-3.074 1.34-1.149.825-1.995 2.003-3.083 2.905-1.456 1.207-3.353 1.934-4.437 3.483a4.661 4.661 0 00-.772 3.366c.213 1.318.986 2.485 1.299 3.784.534 2.23-.34 4.61.17 6.848.375 1.677 1.477 3.087 2.549 4.432.246.31.542.646.936.663.243-.017.48-.08.697-.189"
          fill="#2F2E41"
        />
        <path
          d="M161.995 92.29v3.567l-7.477 9.341-78.685-2.208v-3.906l21.078-7.678 65.084.885z"
          fill="#3F3D56"
        />
        <path
          opacity={0.1}
          d="M161.995 92.29v3.567l-7.477 9.341-78.685-2.208v-3.566l78.175 1.868 7.987-9.001z"
          fill="#000"
        />
        <path
          d="M161.995 89.234v3.057l-7.987 9.001-57.781-1.381v-3.714l18.863-6.963h46.905z"
          fill="#D0D2D5"
        />
        <path
          opacity={0.1}
          d="M161.995 89.234v3.057l-7.987 9.001-57.781-1.381v-3.714l57.781 1.528 7.987-8.491z"
          fill="#000"
        />
        <path
          d="M116.28 92.29l.51-37.532 15.635-.51v38.043H116.28z"
          fill="#3F3D56"
        />
        <path
          d="M58.672 77.757l1.878 1.188-1.14-2.071c.322-.258.722-.401 1.135-.406h.03c.13.003.261-.007.39-.03l.637.405-.274-.496a2.269 2.269 0 001.113-.85l1.139.723-.719-1.308c.666-.8 1.563-1.289 2.55-1.289 1.189 0 2.24.703 2.919 1.8a2.184 2.184 0 001.93 1.041h.065c1.307 0 2.367 1.483 2.367 3.31 0 1.828-1.06 3.308-2.367 3.308-.287 0-.57-.07-.824-.205a3.42 3.42 0 00-2.867-.056 2.99 2.99 0 01-2.436 0 3.426 3.426 0 00-2.841.056c-.252.131-.532.2-.816.2-1.307 0-2.367-1.48-2.367-3.308a4.18 4.18 0 01.498-2.012z"
          fill="#97BAFF"
        />
        <path
          opacity={0.1}
          d="M62.204 82.599a3.5 3.5 0 012.029.22 2.996 2.996 0 002.437 0 3.418 3.418 0 012.865.056c.254.134.537.204.824.206 1.163 0 2.128-1.17 2.33-2.718a2.222 2.222 0 01-.581-.624c-.68-1.098-1.737-1.802-2.92-1.802-1.183 0-2.228.696-2.91 1.781a2.24 2.24 0 01-1.928 1.06h-.03c-.925 0-1.727.734-2.116 1.82zM26.474 67.463c-.564 2.273-1.13 4.552-1.414 6.875a28.787 28.787 0 00.408 9.41c.192.909.428 1.817.425 2.746-.003.93-.284 1.9-.972 2.522 4.327-2.836 10.163-3.2 14.81-.927 2.268 1.11 4.52 2.847 7.012 2.453.23-1.398-1.128-2.718-.81-4.105.233-1.01 1.291-1.651 1.584-2.645.375-1.268-.602-2.493-1.035-3.736a9.921 9.921 0 01-.386-1.905c-.364-2.641-.85-5.289-1.844-7.763-.994-2.475-2.53-4.784-4.689-6.354-1.305-.949-2.797-1.594-4.315-2.12-1.198-.415-2.941-1.242-4.236-1.02-2.79.5-3.967 4.28-4.538 6.57z"
          fill="#000"
        />
        <path
          d="M25.964 66.954c-.564 2.272-1.13 4.551-1.414 6.875a28.788 28.788 0 00.408 9.41c.192.908.429 1.817.425 2.746-.003.929-.284 1.9-.972 2.522 4.327-2.836 10.163-3.201 14.81-.927 2.268 1.109 4.52 2.846 7.013 2.452.23-1.398-1.129-2.717-.81-4.105.232-1.009 1.29-1.65 1.583-2.644.376-1.269-.602-2.493-1.035-3.736a9.936 9.936 0 01-.386-1.906c-.364-2.64-.85-5.288-1.844-7.763-.994-2.474-2.53-4.784-4.688-6.353-1.306-.95-2.798-1.595-4.315-2.121-1.198-.415-2.942-1.242-4.237-1.02-2.789.5-3.967 4.28-4.538 6.57z"
          fill="#2F2E41"
        />
        <path
          d="M158.921 62.886c-.599 3.375-3.051 6.075-5.398 8.575-2.996 3.191-5.814 6.348-8.81 9.541h-38.426c-.367 0-.863-.071-1.219.03l-.576.17c-2.853-2.097-5.062-4.925-7.233-7.727l-3.929-5.06c-2.005-2.586-4.124-5.573-3.637-8.806.031-.346.156-.677.36-.956.201-.209.441-.375.706-.491 5.438-2.76 11.556-3.822 17.419-5.542.908-.267 1.87-.582 2.483-1.297.83-.953.85-2.337 1.107-3.567.256-1.23.807-2.439 1.114-3.685.356-1.449.383-2.953.407-4.446l.079-4.815.079-4.78c6.288-.123 12.494-.607 18.755-.12.641.051 1.334.128 1.8.568.358.34.509.835.64 1.314.498 1.899.724 3.842.87 5.802.213 2.84.26 5.707.735 8.511.261 1.551.679 3.136 1.675 4.355 1.382 1.698 3.608 2.391 5.692 3.057a152.13 152.13 0 0112.746 4.687c.627.263 1.267.542 1.747 1.02.928.913 1.041 2.377.814 3.662z"
          fill="#D0D2D5"
        />
        <path
          opacity={0.1}
          d="M135.513 37.583c-1.268 1.378-3.78 2.196-5.206 2.977-1.426.782-2.889 1.57-4.49 1.731-1.891.192-3.747-.51-5.52-1.189-.953-.37-1.912-.744-2.775-1.29-1.869-1.19-3.078-3.03-4.153-5.005l.078-4.781c6.288-.122 12.494-.606 18.755-.119.641.051 1.334.128 1.8.567.358.34.51.836.64 1.315.505 1.892.724 3.835.871 5.794z"
          fill="#000"
        />
        <path
          d="M138.335 29.182c.453-.34.988-.567 1.425-.93a4.838 4.838 0 001.082-1.424c.773-1.39 1.35-2.953 1.225-4.538-.124-1.584-1.077-3.177-2.583-3.688.947-2.922.17-6.089-.622-9.058-.253-.956-.51-1.922-.968-2.8-.92-1.758-2.568-3.013-4.249-4.059-1.936-1.204-4.031-2.228-6.288-2.563-2.337-.34-4.736.07-6.959.866-1.19.428-2.379.985-3.267 1.896-.647.664-1.106 1.487-1.677 2.22-.924 1.188-2.148 2.138-2.913 3.432-.85 1.435-1.045 3.147-1.227 4.804l-.605 5.504c-.401-.558-1.269-.61-1.869-.258-.6.352-.943.999-1.166 1.65a6.743 6.743 0 00.68 5.728c.47.735 1.081 1.37 1.619 2.058 2.74 3.488 3.788 8.432 7.536 10.811.863.547 1.821.92 2.775 1.291 1.772.68 3.628 1.387 5.52 1.189 1.607-.17 3.072-.956 4.49-1.732 1.735-.95 5.084-1.959 5.834-3.96.836-2.181.039-4.799 2.207-6.44z"
          fill="#D0D2D5"
        />
        <path
          opacity={0.1}
          d="M120.464 30.622c.486-.36.95-.762 1.499-1.019a3.213 3.213 0 012.408-.102c.154.072.32.114.489.126.218-.033.426-.107.615-.22.753-.327 1.629-.09 2.38.242.995.47 1.951 1.019 2.86 1.64a6.69 6.69 0 00-1.052.432c-.389.2-.76.44-1.149.642a8.688 8.688 0 01-3.141.86 7.286 7.286 0 01-.882.043c-1.201-.023-2.353-.456-3.477-.884l-.992-.374-.495-.187c-.1-.039-.418-.098-.474-.185-.117-.17.42-.367.573-.459.291-.171.566-.358.838-.555zM130.219 17.16a3.68 3.68 0 014.665 1.469c-.124.027-.231-.079-.327-.162a3.531 3.531 0 00-.871-.473c-.206-.097-.405-.21-.614-.298a3.82 3.82 0 00-2.335-.137c-.743.17-1.359.543-2.039.867 0-.246.462-.584.642-.731.268-.217.563-.397.879-.535zM132.088 19.658c.738.024 1.53-.058 2.104-.51-.35-.034-.692.09-1.033.17a4.288 4.288 0 01-1.904.015c-.312-.07-.618-.17-.936-.225a1.47 1.47 0 00-.787.035c.082.236.95.325 1.19.369.451.082.908.131 1.366.146zM119.326 17.16a3.68 3.68 0 00-4.665 1.469c.124.027.231-.079.328-.162.266-.197.559-.356.87-.472.205-.097.404-.209.613-.297a3.827 3.827 0 012.337-.138c.741.17 1.36.544 2.031.868 0-.246-.464-.584-.644-.732a3.842 3.842 0 00-.87-.536zM117.452 19.658c-.736.024-1.529-.058-2.102-.51.35-.034.692.09 1.033.17a4.28 4.28 0 001.902.015c.314-.07.62-.17.936-.225.262-.06.534-.048.789.035-.082.236-.952.325-1.19.369a9.309 9.309 0 01-1.368.146zM122.448 26.48c.596 0 1.08-.194 1.08-.433 0-.24-.484-.433-1.08-.433-.597 0-1.081.194-1.081.433s.484.433 1.081.433zM127.207 26.48c.597 0 1.081-.194 1.081-.433 0-.24-.484-.433-1.081-.433s-1.081.194-1.081.433.484.433 1.081.433zM101.884 57.713a12.453 12.453 0 014.281-.7c1.424.02 2.833.282 4.249.43 2.233.235 4.483.182 6.728.13M144.635 57.713a12.453 12.453 0 00-4.281-.7c-1.424.02-2.833.282-4.248.43-2.233.235-4.483.182-6.727.13M119.949 65.548c.559 1.528 2.444 2.16 3.268 3.566a6.01 6.01 0 01.563 1.564l.438 1.698c-.17-.837-.34-1.684-.292-2.537.048-.852.357-1.724.999-2.286a4.949 4.949 0 011.159-.679l2.605-1.235"
          fill="#000"
        />
        <g opacity={0.1} fill="#000">
          <path
            opacity={0.1}
            d="M53.257 29.909c-.822.806-.85 2.09-1.072 3.227-.34 1.674-.603 2.613-1.803 3.639.22.257.477.49.805.51.243-.016.48-.08.697-.189l1.431-.584c1.457-1.126 1.737-2.077 2.099-3.88.226-1.13.25-2.414 1.072-3.228a2.16 2.16 0 01.228-.192c-1.292-.07-2.587-.156-3.457.697zM82.294 21.392c.806-.425 1.64-.795 2.497-1.106-.225-.69-1.73-.14-1.807-.863a.777.777 0 01.197-.509l1.469-2.092c-1.396.356-2.805-.608-3.683-1.75-.879-1.14-1.468-2.508-2.5-3.513-2.065-2.01-5.284-2.064-8.157-1.992l-5.325.13c-2.391.06-4.806.124-7.126.692 1.294-.116 2.599-.15 3.897-.182l5.325-.131c2.88-.071 6.099-.017 8.157 1.992 1.032 1.005 1.621 2.378 2.5 3.514.879 1.136 2.287 2.106 3.683 1.75l-1.469 2.091a.777.777 0 00-.197.51c.077.723 1.582.17 1.807.863-.857.31-1.69.68-2.497 1.105a.922.922 0 00.787.523 28.197 28.197 0 01-3.605 1.529h.272c2.312-.017 4.48-1.043 6.562-2.047a.923.923 0 01-.787-.514zM65.004 23.815c-1.072.048-2.233.204-2.99.963-1.03 1.031-.973 2.843-2.078 3.794a1.61 1.61 0 01-.15.117c1.241.063 2.494.138 3.38-.626 1.104-.951 1.048-2.763 2.078-3.794a2.64 2.64 0 01.657-.476c-.299.004-.601.009-.897.022z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="illustration_sculpting_svg__clip0_847:24565">
          <path fill="#fff" d="M0 0h186v108H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgIllustrationSculpting);
export default ForwardRef;
