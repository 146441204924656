import { Flex, Box } from '@chakra-ui/react';

export function CreateButton({
  isDisabled = false,
  postHeading,
  postDescription,
  icon,
  selected,
  zIndex = 10,
}: {
  isDisabled?: boolean;
  postHeading: string;
  postDescription: object;
  icon: object;
  selected?: (e: any) => void;
  zIndex?: number;
}) {
  return (
    <div style={{ zIndex }}>
      <div
        role="button"
        tabIndex={0}
        onClick={selected}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            selected(e);
          }
        }}
      >
        <Box
          border="1px solid"
          borderColor="$mode.300"
          borderRadius="24px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06)"
          backgroundColor="$mode.50"
          w="100%"
          maxW="240px"
          height="208px"
          _hover={
            isDisabled
              ? {}
              : {
                  borderColor: '#4EA1EC',
                  '&>div>div>p': { color: '#4EA1EC' },
                  '&>div>div>p:first-of-type': { color: '#0278E4' },
                  cursor: 'pointer',
                  background: '#E1EFFC',
                }
          }
        >
          <div style={{ cursor: `${isDisabled ? 'not-allowed' : 'pointer'}` }}>
            <div className="m-6">
              <Flex
                alignItems="center"
                justifyContent="center"
                marginBottom="8px"
                style={{ filter: `${isDisabled && 'saturate(0%)'}` }}
              >
                {icon}
              </Flex>
              <div className="text-center text-sm">
                <div
                  className={`font-bold pb-3 ${
                    isDisabled ? 'text-gray-500' : 'text-gray-700'
                  }`}
                >
                  {postHeading}
                </div>
                {Object.entries(postDescription).map(([key, description]) => {
                  return (
                    <div
                      key={key}
                      className={`font-medium leading-5 ${
                        isDisabled ? 'text-gray-300' : 'text-gray-500'
                      }`}
                    >
                      {description}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}
