import * as React from 'react';
import { Svg } from '../svg';

function SvgNcFc({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.333 20.5c0 7.364-5.97 13.333-13.333 13.333-7.364 0-13.333-5.97-13.333-13.333 0-7.364 5.97-13.333 13.333-13.333 7.364 0 13.333 5.97 13.333 13.333zm3.334 0c0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.667-7.462-16.667-16.667 0-9.205 7.462-16.667 16.667-16.667 9.205 0 16.667 7.462 16.667 16.667zM8.61 19.348l3.758 6.73 1.805-1.042 6.46 1.732c.98.262 2.314.432 3.527-.39 1.213-.823 4.389-3.215 6.18-4.57.391-.348 1.12-1.209.42-2.256-.685-1.02-1.75-.739-2.311-.385l-2.506 1.553c-.269 1.003-1.612 2.485-3.464 1.989l-3.02-.81c-.207-.055-.62-.258-.486-.756.116-.432.517-.533.81-.455l2.731.732c.515.192 1.83.315 2.21-1.103.379-1.419-.805-1.876-1.371-2.027l-4.743-1.21c-.19-.051-.398-.117-.623-.188-1.337-.42-3.254-1.024-5.35.186a171.152 171.152 0 01-3.391 1.916c-.266.146-.463.255-.636.354z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNcFc);
export default ForwardRef;
