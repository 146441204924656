import { useCallback, useState } from 'react';

export function useLocalStorage<T>(targetKey: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(targetKey);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (
      value: T,
      {
        updateState = true,
        deleteKey = false,
      }: { updateState?: boolean; deleteKey?: boolean } = {}
    ) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        if (updateState) {
          setStoredValue(valueToStore);
        }

        if (deleteKey) {
          window.localStorage.removeItem(targetKey);
        } else {
          window.localStorage.setItem(targetKey, JSON.stringify(valueToStore));
        }
      } catch (error) {
        // do nothing
      }
    },
    [targetKey, storedValue]
  );

  return [storedValue, setValue] as const;
}
