/**
 * Internal dependencies
 */
import { CardGrid } from 'src/components/card/card-grid';
import Collection from 'src/components/common/card/collection';

function SearchResultCollectionLayout({ onClick, showViewAll, children }) {
  return (
    <div>
      <div className="relative flex text-gray-900 items-center justify-between">
        <div className="text-base text-gray-500 font-semibold pb-4 pt-6 pl-4">
          Collections
        </div>
        {showViewAll && (
          <div
            className="text-sm font-medium pb-4 pt-6 pr-4 text-gray-700 cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyDown={onClick}
          >
            View all
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

function SearchResultCollection({ result, resultLimit = 3 }) {
  return (
    <CardGrid>
      {result.slice(0, resultLimit).map((data, key) => {
        const mkey = `caall_${key}`;
        return <Collection data={data} key={mkey} />;
      })}
    </CardGrid>
  );
}

SearchResultCollection.Layout = SearchResultCollectionLayout;

export default SearchResultCollection;
