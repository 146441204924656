import { useForm } from 'react-hook-form';
import { ArticleFormValues } from '../settings-modal/types';
import { Article } from '../types';

export function useArticleForm(article?: Article) {
  const getCurrContent = (data) => {
    if (article) {
      if (article.draftContent && article?.draftContent[data]) {
        return article?.draftContent[data];
      }
      if (article[data]) {
        return article[data];
      }
    }
  };
  const currTags = getCurrContent('tags');
  const currCats = getCurrContent('categories');
  const methods = useForm<ArticleFormValues>({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      description: getCurrContent('description') ?? '',
      coAuthors: getCurrContent('coAuthors') ?? [],
      contributors: getCurrContent('contributors') ?? [],
      categories: currCats?.map((tag) => ({ label: tag, value: tag })) ?? [],
      tags: currTags?.map((tag) => ({ label: tag, value: tag })) ?? [],
      hasSensitiveContent: article?.hasSensitiveContent ?? false,
      copyright: article?.copyright ?? '',
      postType: article?.postType ?? '',
      commentable:
        article && typeof article.commentable === 'boolean'
          ? article.commentable
          : true,
    },
  });

  return methods;
}
