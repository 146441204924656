import * as React from 'react';
import { Svg } from '../svg';

function SvgInfo({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.333A6.67 6.67 0 001.333 8 6.669 6.669 0 008 14.667 6.669 6.669 0 0014.666 8 6.669 6.669 0 008 1.333zm-.667 10V10h1.333v1.334H7.333zm0-6.666v4h1.333v-4H7.333z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgInfo);
export default ForwardRef;
