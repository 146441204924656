/**
 * External dependencies
 */
import { useState, useMemo } from 'react';
import is from 'is_js';

/**
 * Internal dependencies
 */
import { dataSort } from 'src/helpers/sortData';
import { GENRES } from './genreConfiguration';
import { SORT_TYPES } from './constants';
import { ResultType } from './types';

function useSort({
  searchState: { result: searchResult, genre },
  filterState: { result: filterResult, hasFilter },
}) {
  const [sortType, setSortType] = useState(SORT_TYPES.NAME);

  const result = useMemo(() => (hasFilter ? filterResult : searchResult), [
    hasFilter,
    filterResult,
    searchResult,
  ]);

  const genreSortProperty = useMemo(() => GENRES[genre].sortProperty, [genre]);
  const sortResult: ResultType = useMemo(() => {
    if (!genreSortProperty[sortType]) return result;
    const afterSort = { ...result };
    Object.keys(result).forEach((genreKey) => {
      if (is.not.empty(result[genreKey])) {
        afterSort[genreKey] = dataSort(
          result[genreKey],
          genreSortProperty[sortType],
          {
            reverse: [SORT_TYPES.POPULARITY, SORT_TYPES.RECENTLY].includes(
              sortType
            ),
          }
        );
      }
    });

    return afterSort;
  }, [result, sortType, genreSortProperty]);

  const sortTotalResult = useMemo(
    () =>
      Object.values(sortResult).reduce(
        (acc, genreResult) => acc + genreResult.length,
        0
      ),
    [sortResult]
  );

  const sortOptions = [
    [
      {
        id: 0,
        type: 'click',
        path: '',
        displayName: 'Recently',
        sortType: SORT_TYPES.RECENTLY,
        action: () => setSortType(SORT_TYPES.RECENTLY),
      },
      {
        id: 1,
        type: 'click',
        path: '',
        displayName: 'Name',
        sortType: SORT_TYPES.NAME,
        action: () => setSortType(SORT_TYPES.NAME),
      },
      {
        id: 2,
        type: 'click',
        path: '',
        displayName: 'Popularity',
        sortType: SORT_TYPES.POPULARITY,
        action: () => setSortType(SORT_TYPES.POPULARITY),
      },
    ].filter((option) => genreSortProperty[option.sortType]),
  ].filter((item) => item.length > 0);

  return {
    sortType,
    sortResult,
    sortTotalResult,
    sortOptions,
  };
}

export default useSort;
