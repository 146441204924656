import * as React from 'react';
import { Svg } from '../svg';

function SvgWalletIcon({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16.666 9.167V5.833H3.541a2 2 0 01-1.325-.488 1.579 1.579 0 01-.55-1.178c0-.917.844-1.667 1.876-1.667h11.25v3.333"
        stroke="#4F5356"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.667 4.167v11.428c0 1.048.843 1.905 1.875 1.905h13.125v-3.334"
        stroke="#4F5356"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 9.166c-.442 0-.866.264-1.178.733-.313.469-.488 1.105-.488 1.768 0 1.374.75 2.5 1.666 2.5h3.334v-5H15z"
        stroke="#4F5356"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgWalletIcon);
export default ForwardRef;
