import {
  Center,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Text,
  UseDisclosureReturn,
  useDisclosure,
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { useState, useEffect } from 'react';
import SvgMetaMask from 'src/components/icons/meta-mask';
import SvgWalletConnect from 'src/components/icons/wallet-connect';
import SvgToolTip from 'src/components/icons/tooltip';
import { removeLocalStorage } from 'src/heplers/localStorage';
import ErrorModal from 'src/app/code/ErrorModal/ErrorModal';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import {
  userWalletConnectNonce,
  userWalletConnect,
} from 'src/app/auth/service';

function WalletModal({
  isOpen,
  onClose,
  isVerify = false,
}: UseDisclosureReturn & {
  isVerify?: boolean;
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const walletInUseModal = useDisclosure();
  const noWalletFoundModal = useDisclosure();
  const [signatures, setSignatures] = useState<any[]>([]);
  const [error, setError] = useState<any>();
  const [wc, setWc] = useState<any>();
  const [web3, setWeb3] = useState<any>();

  useEffect(() => {
    const sigContinue = async () => {
      const web3Connect = await userWalletConnect({
        signature: signatures[signatures.length - 1].signature,
        publicAddress: signatures[signatures.length - 1].address,
      });
      if (web3Connect.code === 1000) {
        onClose();
      } else {
        setError(web3Connect.msg);
      }
    };
    if (signatures && signatures.length > 0) {
      if (
        signatures[signatures.length - 1].address &&
        signatures[signatures.length - 1].signature
      ) {
        sigContinue();
      }
    }
  }, [signatures]);

  useEffect(() => {
    if (error) {
      if (error === 'wallet not found') {
        noWalletFoundModal.onOpen();
      } else {
        walletInUseModal.onOpen();
      }
    }
  }, [error]);

  const signMessage = async () => {
    try {
      if (!window.ethereum)
        throw new Error('No crypto wallet found. Please install it.');

      await window.ethereum.send('eth_requestAccounts');
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      userWalletConnectNonce().then(async (res) => {
        if (res) {
          const signature = await signer.signMessage(res.nonce);
          if (signature && address) {
            if (signatures && signatures.length > 0) {
              setSignatures([...signatures, { signature, address }]);
            } else {
              setSignatures([{ signature, address }]);
            }
          }
        }
      });
    } catch (err) {
      setError('wallet not found');
    }
  };

  const walletConnectEnable = async () => {
    const walletConnectProvider = new WalletConnectProvider({
      infuraId: '117199ece0414112b97d54fbe01f6b49', // Required
      // qrcode: true
    });
    setWc(walletConnectProvider);
    await walletConnectProvider.enable();
    setWeb3(new Web3(walletConnectProvider as any));
  };

  useEffect(() => {
    const web3Continue = async () => {
      const currAddress = await web3.eth.getAccounts();
      userWalletConnectNonce().then((res) => {
        if (res) {
          walletConnectSign(res.nonce, currAddress[0]);
        }
      });
    };
    if (web3) {
      web3Continue();
    }
  }, [web3]);

  const walletConnectSign = async (nonce, address) => {
    const rawMessage = nonce;
    const rawMessageLength = new Blob([rawMessage]).size;
    let message = ethers.utils.toUtf8Bytes(
      `\x19Ethereum Signed Message:\n${rawMessageLength}${rawMessage}`
    );
    message = ethers.utils.keccak256(message) as any;
    const params = [address, message];
    const newSig = await wc.connector.signMessage(params);
    const sig = {
      signature: newSig,
      address,
    };
    if (signatures && signatures.length > 0) {
      setSignatures([...signatures, sig]);
    } else {
      setSignatures([sig]);
    }
  };

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay>
        <ModalContent
          bg="$mode.50"
          p="64px"
          w="480px"
          h="360px"
          borderRadius="24px"
        >
          <ModalCloseButton _hover={{ background: 'none' }} />
          <Center flexDirection="column">
            <Heading as="h3" textStyle="h3" color="black">
              <Text
                noOfLines={2}
                textAlign="center"
                fontSize="20px"
                className="font-semibold text-gray-900"
              >
                {isVerify ? t('Verify to unlock') : t('Import from wallet')}
              </Text>
              {/* <Text textAlign="center">{subTitle}</Text> */}
            </Heading>
            {isVerify ? (
              <Text
                textAlign="center"
                className="font-medium text-gray-900 text-sm pt-3 pb-6"
              >
                This content is token-gated. Connect your wallet to verify that
                you own this asset in the wallet.
              </Text>
            ) : (
              <Text
                textAlign="center"
                className="font-medium text-gray-900 text-sm pt-3 pb-6"
              >
                Connect with one of our available wallet providers or create a
                new one.
              </Text>
            )}
            <div className="w-full">
              <div
                style={{ padding: '10px 0', height: '40px' }}
                className="border border-gray-300 rounded-full text-center font-semibold cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={signMessage}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    signMessage();
                  }
                }}
              >
                <div className="m-auto flex w-max">
                  <SvgMetaMask
                    style={{ width: '20px', height: '20px' }}
                    className="inline mr-2"
                  />
                  <span className="align-middle text-semibold text-sm text-gray-700">
                    MetaMask
                  </span>
                </div>
              </div>
              <div
                style={{ padding: '10px 0', height: '40px' }}
                className="border border-gray-300 rounded-full text-center font-semibold my-4 cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={walletConnectEnable}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    walletConnectEnable();
                  }
                }}
              >
                <div className="m-auto flex w-max">
                  <SvgWalletConnect
                    style={{ width: '24px' }}
                    className="inline mr-2"
                  />
                  <span className="align-middle text-semibold text-sm text-gray-700">
                    WalletConnect
                  </span>
                </div>
              </div>
            </div>
            <div className="text-sm text-gray-500 font-medium">
              {t('Stuck with WalletConnect?')}
              <button
                type="button"
                onClick={() => {
                  if (wc) {
                    wc.disconnect();
                    setSignatures([]);
                  }
                  removeLocalStorage('walletconnect');
                }}
                className="text-blue-500 px-0.5"
              >
                Reset
              </button>
              <button
                type="button"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="This happens when you leave the signup/in process without completing signature."
                className="inline align-middle"
              >
                <SvgToolTip style={{ width: '16px', height: '16px' }} />
              </button>
            </div>
          </Center>
          <ErrorModal
            onClose={walletInUseModal.onClose}
            isOpen={walletInUseModal.isOpen}
            heading="Used Wallet"
            paragraph="This wallet is already connected to another account."
            cancelButtonText="Cancel"
            confirmButtonText="Sign in with this wallet"
            confirmButtonAction={() => {
              router.push({
                query: { ...router.query, page: 'signIn' },
              });
            }}
          />
          <ErrorModal
            onClose={noWalletFoundModal.onClose}
            isOpen={noWalletFoundModal.isOpen}
            heading="No wallet found"
            paragraph="Please make sure you have a wallet installed."
            cancelButtonText="Cancel"
            confirmButtonText="Go to MetaMask"
            confirmButtonAction={() => {
              window.open('https://metamask.io/', '_blank');
            }}
          />
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default WalletModal;
