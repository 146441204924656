import { Modal, ModalContent } from '@chakra-ui/react';
import styles from './alpha-explainer-modal.module.scss';

export default function MintExplainerModal({ closeModal, isOpen }) {
  return (
    <Modal isOpen={isOpen} onClose={closeModal} allowPinchZoom>
      <ModalContent
        maxW="100vw"
        w="100vw"
        h="100vh"
        borderRadius="none"
        background="none"
      >
        <div className={styles.fadein}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,  jsx-a11y/click-events-have-key-events */}
          <div className={styles.overlay} onClick={closeModal}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,  jsx-a11y/click-events-have-key-events */}
            <div
              className={styles.content}
              onClick={(e) => e.stopPropagation()}
            >
              <h2>1TM Alpha Release Note</h2>
              <h3>
                This is an Alpha release version created for user testing before
                a feature freeze.
              </h3>
              <ul>
                <li>
                  Anyone create an account to read content and interact with
                  other users.
                </li>
                <li>
                  Writing permission will be limited to the 1TM pioneer pass and
                  Ark crew PFP holders.
                </li>
                <li>
                  This version does not contain all of the features that are
                  planned for the official launch.
                </li>
                <li>
                  This version may contain known or unknown errors, that could
                  cause instability like crashes or data loss.
                </li>
              </ul>
              <div className={styles.bottom_section}>
                <p>
                  * Your account and content created during the Alpha release
                  will remain intact through version changes and official
                  release.{' '}
                </p>
              </div>
              <button
                type="button"
                className={styles.close_button}
                onClick={closeModal}
              >
                <div className={styles.x1} />
                <div className={styles.x2} />
              </button>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
