import * as React from 'react';
import { Svg } from '../svg';

function SvgWhimsical({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M9.866 5.78c.06-.012.108-.024.168-.024l.06.024h-.228z"
        fill="#A665D2"
      />
      <path
        d="M11.977 21.895c6.018 0 10.896-4.878 10.896-10.896 0-6.017-4.878-10.895-10.896-10.895C5.96.104 1.082 4.982 1.082 11c0 6.017 4.878 10.895 10.895 10.895z"
        fill="#7A12CD"
      />
      <path
        d="M13.622 5.792a.604.604 0 01.456.072c.216.18.396.384.6.576l.828.828c.156.12.264.276.42.408.144.192.348.324.492.504.132.12.264.24.372.372.3.264.54.576.852.828.18.216.384.408.576.6.216.216.432.432.648.66.024.06.06.132.084.192v.228a.866.866 0 01-.168.288l-4.62 4.62a1.13 1.13 0 01-.264.144h-.24a1.13 1.13 0 01-.264-.144l-1.02-1.02c-.108-.108-.216-.228-.336-.336-.06-.06-.108-.12-.168-.168l-.024-.024c-.252-.252-.492-.504-.744-.744l-.072-.072-1.284-1.284c-.312-.324-.636-.636-.96-.96a.591.591 0 01-.168-.288v-.204c.048-.216.228-.372.372-.516.108-.12.228-.228.336-.336.264-.252.504-.528.768-.78.324-.3.612-.612.924-.924a.251.251 0 01.072-.048c.252-.252.516-.492.744-.756l.036-.036c.276-.3.576-.576.864-.864.288-.264.528-.588.864-.78"
        fill="#FFFEFF"
      />
      <path
        d="M9.506 6.032a.83.83 0 01.36-.24h.228c.216.06.348.228.492.372l.792.792c-.096.072-.18-.156-.276-.072-.097.084-.18.18-.264.276-.048.048-.12.06-.144.12-.036.096-.144.12-.216.192-.012.036.048.036.06.06l-.013.12c-.143-.132-.275-.288-.431-.42-.06-.024-.168-.048-.216 0-1.176 1.14-2.316 2.316-3.48 3.468-.048.06-.132.096-.132.18a.343.343 0 00.096.312l.648.648 1.548 1.548c.396.396.792.78 1.176 1.176a.346.346 0 00.312.132c.192-.072.312-.264.468-.396.024.036 0 .084 0 .132-.06.012-.132.06-.108.132.035.096-.12 0-.156.072-.012.048.036.072.048.108-.096.012-.228-.06-.276.048-.06.024-.096-.036-.144-.048-.156.048-.108-.144-.216-.156-.108-.048-.132-.204-.264-.228-.096-.024-.072-.156-.144-.192A.424.424 0 019.098 14c-.024-.072-.12.036-.132-.06-.036-.144-.168-.204-.24-.312-.048-.048-.144-.036-.168-.12a.189.189 0 00-.12-.132c-.06-.084-.12-.192-.24-.192-.084.012-.072-.096-.072-.156-.132-.036-.156-.18-.264-.24-.048.012-.12-.012-.156.048 0 .024.012.06-.012.096l-.072-.036c-.144.156-.312.312-.456.468-.012.144.156.216.24.336.012.06.072.036.108.036.036.072.072.156.12.228.108.06.168.168.264.216.072.132.18.252.312.336a.31.31 0 01.108.108c.06.024.144 0 .096.12.036.048.084.096.132.144.12-.048.108.048.12.096.036.06.18-.012.132.132.072.108.204.156.264.264.108.06.144.18.24.24s.18.144.24.24c.072.048.132.108.192.168.12 0 .252.024.371.048.097-.024.12-.156.205-.192.108-.036.144-.156.24-.216.048-.024.024-.096.06-.132l.132.024c-.084.132-.216.204-.312.336a.794.794 0 01-.372.252h-.216a1.105 1.105 0 01-.288-.156c-.132-.156-.3-.276-.432-.444-.012-.024-.036-.036-.06-.048a8.172 8.172 0 00-.456-.456c-.132-.156-.312-.288-.444-.444-.144-.18-.348-.3-.48-.492-.036-.048-.096-.072-.132-.12-.264-.252-.504-.552-.792-.78-.144-.18-.336-.336-.504-.504-.156-.204-.36-.348-.528-.528-.072-.06-.12-.144-.192-.192-.012-.024-.036-.06-.06-.072-.18-.144-.312-.348-.504-.492a.616.616 0 01-.18-.276c0-.084.012-.168 0-.252a.78.78 0 01.132-.264c1.56-1.572 3.072-3.084 4.584-4.596z"
        fill="#E3CCF4"
      />
      <path
        d="M11.101 6.884c.096-.084.192.144.276.072.06.048.108.108.168.168v.024h-.024a1.271 1.271 0 00-.144-.12c-.096 0-.132.096-.18.156a.698.698 0 01-.216.204 7.383 7.383 0 01-.288.312c0 .036 0 .072-.012.096l-.144-.144.012-.12c-.012-.024-.072-.024-.06-.06.072-.06.18-.096.216-.192.024-.06.096-.072.144-.12a1.38 1.38 0 01.252-.276z"
        fill="#D9C4EB"
      />
      <path
        d="M11.185 7.184c.048-.06.096-.156.18-.156.048.036.108.072.144.12-.132.012-.18.132-.276.192a2.045 2.045 0 00-.228.228c-.084.096-.216.168-.24.312-.036-.024-.072-.06-.096-.084.012-.036.012-.072.012-.096.108-.096.192-.204.288-.312a.592.592 0 00.216-.204z"
        fill="#D0BCE0"
      />
      <path
        d="M11.545 7.124c.12.096.216.24.36.324l-.036.036c-.048-.036-.084-.084-.132-.12l-.024-.024-.072-.072-.048-.048-.048-.072v-.024z"
        fill="#D7B0F8"
      />
      <path
        d="M11.246 7.34c.096-.06.144-.18.276-.192h.024l.048.072c-.036.012-.084.024-.12.036-.18.216-.384.384-.564.588a.426.426 0 00-.048.108.261.261 0 00-.096-.06V7.88c.024-.144.156-.216.24-.312.072-.084.156-.168.24-.228z"
        fill="#C7B5D7"
      />
      <path d="M9.866 7.22c.06-.048.168-.024.216 0h-.216z" fill="#BB85E0" />
      <path
        d="M11.473 7.256c.036-.012.072-.024.12-.036l.049.048c-.06.024-.12.036-.144.108-.168.216-.396.372-.576.588 0 .024 0 .048.011.072a.318.318 0 01-.06-.072c.013-.036.025-.072.049-.108.167-.216.383-.396.551-.6z"
        fill="#BFAECD"
      />
      <path
        d="M11.497 7.364c.024-.06.096-.084.144-.108l.072.072c-.24.096-.36.36-.576.504-.06.072-.168.132-.156.24l-.048-.048c0-.024-.012-.048-.012-.072.18-.216.408-.372.576-.588z"
        fill="#B7A7C5"
      />
      <path
        d="M11.137 7.832c.216-.144.336-.396.576-.504l.024.024c-.12.132-.252.264-.372.384-.108.072-.168.204-.276.276-.036.024-.024.072-.036.12l-.072-.072c-.012-.096.096-.144.156-.228z"
        fill="#AF9FBC"
      />
      <path
        d="M11.365 7.748c.12-.132.252-.252.372-.384.048.036.084.084.132.12-.228.264-.504.504-.744.756-.012-.036-.036-.06-.06-.096.012-.036 0-.096.036-.12.096-.084.156-.204.264-.276z"
        fill="#A79AB2"
      />
      <path
        d="M10.777 7.892c.036.012.06.036.096.06.024.024.048.048.06.072l.048.048.072.072c.024.024.048.06.06.096a.251.251 0 01-.072.048c0-.024.012-.048.012-.072-.084-.108-.24-.18-.276-.324z"
        fill="#702EA5"
      />
      <path
        d="M4.802 10.856l.024-.024v.252a.091.091 0 00-.024-.048v-.18z"
        fill="#B981E5"
      />
      <path d="M18.961 10.832a.37.37 0 010 .228v-.228z" fill="#A063D0" />
      <path d="M4.802 11.024a.116.116 0 010-.168v.168z" fill="#8030C6" />
      <path
        d="M8.618 10.844l.012.012v.204c-.012-.072-.012-.144-.012-.216z"
        fill="#A569DC"
      />
      <path
        d="M7.705 12.824c.024-.06.096-.036.156-.048.108.06.132.216.264.24-.012.06-.012.168.072.156.12-.012.18.108.24.192.06.024.108.072.12.132.012.084.12.072.168.12.084.108.216.168.24.312.012.084.108-.012.132.06.036.072.084.12.156.168.084.036.048.168.144.192.12.024.144.18.264.228.108.012.06.204.216.156.048.012.096.072.144.048.048-.108.18-.036.276-.048-.012-.036-.06-.072-.048-.108.036-.072.192.024.156-.072-.036-.072.048-.108.108-.132-.012.084.084.108.132.168.096.144.228.264.372.348.048.036.06.108.084.156.108-.012.18-.108.264-.168l.036.024c-.216.18-.396.408-.6.576l-.132-.024c-.036.036-.012.108-.06.132-.096.06-.132.18-.24.216-.084.048-.108.168-.204.192-.12-.024-.252-.036-.372-.048-.06-.06-.12-.12-.192-.168a.764.764 0 00-.24-.24c-.096-.06-.144-.192-.24-.24-.06-.108-.192-.168-.264-.264.048-.144-.084-.072-.132-.132-.024-.06 0-.156-.12-.096a.815.815 0 01-.144-.144c.048-.12-.048-.108-.096-.12l-.108-.108a.929.929 0 01-.312-.336c-.108-.048-.168-.156-.264-.216a1.368 1.368 0 01-.12-.228c-.048 0-.096.024-.108-.036-.072-.108-.24-.18-.24-.336.144-.156.312-.312.456-.468l.072.036c-.012 0-.024-.036-.036-.072z"
        fill="#E3CFF2"
      />
      <path
        d="M11.041 13.592l.072.072-.035.048c-.037.036-.072.06-.096.096l-.024.024-.072.072-.048.048-.072.072c-.024.024-.072.06-.097.096l-.024.012c0-.048.024-.084.049-.12.12-.12.24-.228.347-.348v-.072z"
        fill="#68219F"
      />
      <path
        d="M11.114 13.664l.744.744-.072.06c-.096-.06-.168-.156-.252-.228-.156-.12-.24-.288-.396-.408l-.06-.144c0 .012.036-.024.036-.024z"
        fill="#A195AB"
      />
      <path
        d="M10.981 13.808c.036-.036.06-.072.096-.096l.06.144c.144.12.24.288.396.408.084.072.156.156.252.228l-.048.048a.695.695 0 01-.204-.168c-.204-.192-.348-.408-.552-.564z"
        fill="#A999B5"
      />
      <path
        d="M10.957 13.82l.024-.024c.204.168.348.384.553.552.06.072.12.132.204.168l-.072.072c-.205-.204-.433-.396-.625-.624a.288.288 0 01-.084-.144z"
        fill="#B0A2BC"
      />
      <path
        d="M10.886 13.892l.072-.072c.012.06.036.108.084.144.192.228.42.408.624.624l-.036.048-.012.012c-.252-.192-.456-.444-.684-.66a.36.36 0 01-.048-.096z"
        fill="#B9A8C5"
      />
      <path
        d="M10.837 13.94l.048-.048c.012.024.024.06.048.084.24.216.432.48.684.66-.024.024-.048.036-.072.06-.228-.216-.456-.432-.672-.66-.036-.012-.024-.06-.036-.096z"
        fill="#C1B0CE"
      />
      <path
        d="M10.766 14.012l.072-.072c.012.036 0 .072.024.096.216.228.444.444.672.66-.06.036-.132.012-.192.024-.156-.24-.408-.408-.6-.624.024-.024.036-.048.024-.084z"
        fill="#C9B7D7"
      />
      <path
        d="M10.67 14.12c.036-.036.06-.072.096-.096.012.024-.012.048-.012.084.192.216.444.384.6.624.06 0 .132.012.192-.024.024-.024.048-.036.072-.06l.012-.012.012.048-.144.144-.012-.048c-.06.036-.108.084-.156.12-.132-.168-.312-.276-.444-.432-.06-.12-.228-.192-.216-.348z"
        fill="#D0BDE0"
      />
      <path
        d="M10.646 14.132l.023-.012c-.011.156.156.228.216.348.133.168.313.276.444.432.06-.036.108-.084.168-.12l.012.048a.569.569 0 01-.12.12l-.035-.024c-.084.06-.144.156-.264.168-.024-.048-.024-.12-.085-.156a1.036 1.036 0 01-.372-.348c-.047-.06-.143-.084-.131-.168 0-.048.035-.096 0-.132.047-.06.095-.108.143-.156z"
        fill="#D9C6E9"
      />
      <path
        d="M11.785 14.468l.072-.06.024.024c-.084.084-.156.168-.24.252l-.012-.048.036-.048.072-.072.048-.048z"
        fill="#CBA2E7"
      />
      <path d="M9.866 16.112h.216a.332.332 0 01-.216 0z" fill="#7B38B1" />
      <path d="M13.67 16.112h.24l-.025.024h-.204l-.012-.024z" fill="#7B41AC" />
      <path d="M13.693 16.136h.204c-.06.036-.132.036-.204 0z" fill="#580897" />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgWhimsical);
export default ForwardRef;
