import * as React from 'react';
import { Svg } from '../svg';

function SvgCreator6({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="url(#creator-6_svg__pattern0)" d="M.667 0h48v48h-48z" />
      <defs>
        <pattern
          id="creator-6_svg__pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use xlinkHref="#creator-6_svg__image0" transform="scale(.01)" />
        </pattern>
        <image
          id="creator-6_svg__image0"
          width={100}
          height={100}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAABmJLR0QA/wD/AP+gvaeTAAAFOElEQVR4nO2dX4hVRRzHP90VkmqlPwgVbBCUZSEUKkIhRP9IjIiUJPunCAZB9JYhCBH4HBSlEfRSL/1jFSJ9KHsJQqgnKQiDst3N2M1K7d+u7d0e5i57zsyce86Ze87M3HN+H/iB65k/35nvnXNnzp+5IAiCIAjN4SHgZ2AhkpgAHq61xZETkxmLcQHYVmejYyZGQ1ptylZgnvAGiCkJdgBd0p0xA9ziWcdWlAlJHf8BT3jWEQXPY35Cp4DrPesQUxLsxzTle+BazzrElASvYJpyArjKsw4xpcdFwNuYphwHRj1rEVN6jAAfYppyDFjuWYuY0uMGzJnXAnAYWOZZyzZMU1o3JR4ne33wLtDxrKfVI2UL+Yu2NwLoaqUpl2NeTjmOmv7qpuwPoK91p683STd2FrgVuA44hWnKngAaWzNSNmJ+kb+UOL4K+EU73gWe8SsTaIEpFwPfkG7gd5jT3NuA37V088Bj3pQu0WhTXsb85N+dkfYO4E8t/RzwYP0yDRppys3Av6QbdTAnz/2WPH8Dd9UnM5NGmdIBviDdmNPAFQXyPoJqeDLvWWBdLUr70xhTnsWcOW0pkX83cdxLgYZMiSdJN+CIQxkvYJo6if97KVCjKeuBj4BfMRs7SKzQ6pnSjv+G26c7617KNQ5lDUrlpuyyFFhVrNHqehTzlPMDcLWD7tct9Z0ArnQoa1AqM2WdpaAqY6+lzj2WdF8Bl5XU3gHeqVF7FXEBNRkpzAc1C5oGVlrqPWBJ+zHlL7UvQ12iD93x/WKyTINmtMzbUXfx6mYEZYAuPm89YmM58JmlrFiilCH6+dznfYdLUacqvQEvOpQ1ihrts5byQsYUsLlMQ/QCfLMSOKlp6AJPBtBio2j/VNaPoQ0BWA2c0XTMAvcE0pOklYaAuhSvX6v6A3Pa7JvWGgJqjaI/9+u6RqmKVhsC6gtd1/Q15dcoVdF6QwBew9T1Cf4fB8KiY9B0lVXokxHgEKa2twJoEUN6XAJ8ianPZY0yCGJIghjWKGKIRug1ihhiwbZGCRVZtMoQsK9RxJDA2O6j+Iyf+mhrpSEA+1CXVEKY8UAfXYX6sch9DT2zj3shTaRQP/p+p0LIQQyJDDEkMkJcjGsalX7HygiJDDEkMsSQyBBDIkMMiQwxJDLEkMiQdYg/Cq1PZIREhhgSGWJIZIghfhhDbcB2rhfjwE2uhQ3bHUPf5PXPGOZTMQuol1nH6qiw7eT1j21rwsV4v44K205e/5yzpFmMs3pi+Q6pn3/KHBND6ueo47FM5JTVn7z+WY99N9Vu71jlFbadIv1je3VivKoKJfqHjTWkH3OdZ4B3I0M3cNgii+RbX69mJXJ5clHoT1afjgLfot7+Wo1lygty+d0n54Hnev+2mgHybG8VVNo/sg6JjGE0ZDNq95wJYFNgLUEoOovwxQRLWvq9IOOLSvtnGEdI64lthGxCjZK8N5Z84b1/Qm5gFjsd0n3TraLAPM5of28vmK/pdIDHtf+b8VFx3ZtgNinec+zjUqyl3m1imxJzwO2OfVyanYgpeWY87dy7jqxFnb6mBxDetJhGPajgbWSEYgXphmdejEtwXssTaoe5gYh1tqTfvCmy0bCeJvTmmE7EashT2t+fF8ijp9HLEBzZgDmBuLNAvo1anjkcHyIQltiA+TN4n5bIf0zLexoxpTSjqBFwEHNk/AXcWKKsVb08+kg5gPolN98/9z1U3If9lzrrjlPAvR7aN3TYfsvWV5z00D4nYp1l1c1CaAExEuqU9SNyyhIEQRAayP+omN1kseQxkAAAAABJRU5ErkJggg=="
        />
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCreator6);
export default ForwardRef;
