import * as React from 'react';
import { Svg } from '../svg';

function SvgComments({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M9.333 14l-1.867-2.334H4A.667.667 0 013.333 11V3.735A.667.667 0 014 3.068h10.666a.667.667 0 01.667.667V11a.667.667 0 01-.667.666H11.2L9.333 14zm1.226-3.667H14V4.402H4.667v5.931h3.441l1.226 1.532 1.226-1.532zm-9.226-10h11.333v1.333H2V9H.667V1a.667.667 0 01.666-.667z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgComments);
export default ForwardRef;
