import * as React from 'react';
import { Svg } from '../svg';

function SvgTeamV2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M.333 13.666a5.08 5.08 0 0110.16 0h-1.27a3.81 3.81 0 00-7.62 0H.333zm5.08-5.714a3.808 3.808 0 01-3.81-3.81A3.808 3.808 0 015.413.334a3.808 3.808 0 013.81 3.81 3.808 3.808 0 01-3.81 3.81zm0-1.27a2.539 2.539 0 10.001-5.078 2.539 2.539 0 00-.001 5.078zm5.26 2.351a5.08 5.08 0 012.994 4.633h-1.27a3.81 3.81 0 00-2.246-3.475l.52-1.158h.001zm-.437-7.168a3.492 3.492 0 01-1.014 6.708V7.295a2.222 2.222 0 00.661-4.196l.353-1.234z"
        fill="#fff"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTeamV2);
export default ForwardRef;
