export function checkAuthorName(author) {
  if (!author) return '';
  const firstName = author.firstName || author.firstname || '';
  const lastName = author.lastName || author.lastname || '';

  const firstNameChar = firstName.replace(/\s/g, '')?.[0];
  const lastNameChar = lastName.replace(/\s/g, '')?.[0];
  const usernameChar = author.username.replace(/\s/g, '')?.[0];

  if (firstNameChar && lastNameChar) {
    return (firstNameChar + lastNameChar).toUpperCase();
  }
  return usernameChar.toUpperCase();
}
