/**
 * Internal dependencies
 */
import { useIsMobile } from 'src/hooks/use-is-mobile';

import { CardGrid } from 'src/components/card/card-grid';
import Creation from 'src/components/card/Creation';

function SearchResultCreationLayout({ onClick, showViewAll, children }) {
  const isMobile = useIsMobile();
  return (
    <div>
      <div className="relative flex text-gray-900 items-center justify-between">
        <div
          className={`text-base text-gray-500 font-semibold ${
            isMobile && 'pb-4'
          } pt-6 pl-4`}
        >
          Creations
        </div>
        {showViewAll && (
          <div
            className="text-sm font-medium pb-4 pt-6 pr-4 text-gray-700 cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyDown={onClick}
          >
            View all
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

function SearchResultCreation({
  result,
  resultLimit = 4,
  openModal,
  isAllResult = false,
}) {
  const isMobile = useIsMobile();
  return (
    <CardGrid paddingTop={isMobile && !isAllResult ? '16px' : 0}>
      {result.slice(0, resultLimit).map((data, key) => {
        const mkey = `caall_${key}`;
        return (
          <Creation
            key={mkey}
            className="mt-4 mb-2"
            cover={data.coverImgURL}
            title={data.title}
            excerpt={data.excerpts}
            categories={data.categories}
            enthuseCount={data.enthuseCount}
            author={data.author}
            contentID={data.objectID}
            modalOpener={(e) => openModal(e)}
          />
        );
      })}
    </CardGrid>
  );
}

SearchResultCreation.Layout = SearchResultCreationLayout;

export default SearchResultCreation;
