import {
  IconButton,
  IconButtonProps,
  ButtonGroup,
  Text,
} from '@chakra-ui/react';
import { ComponentProps } from 'react';

import SvgEnthusedNew from 'src/components/icons/enthused-new';
import SvgEnthuseNew from 'src/components/icons/enthuse-new';

// import SvgEnthused from 'src/components/icons/enthused';
// import SvgEnthuse from 'src/components/icons/enthuse';
import SvgEnthused from 'src/components/icons/enthused';
// import SvgEnthuseV1 from 'src/components/icons/enthuse-v1';
import SvgEnthuseV2 from 'src/components/icons/enthuse-v2';

import { formatNumber } from 'src/lib/formatNumber';

export interface EnthuseButtonProps
  extends Omit<IconButtonProps, 'aria-label'> {
  onEnthuse: () => void;
  isEnthuse?: boolean;
  likesCount?: number;
  fontSize?: number;
  minWidth?: string;
  svgClass?: string;
  textClass?: string;
  containerClass?: string;
  contentView?: boolean;
  isFeedPost?: boolean;
}

export function EnthuseButton({
  isEnthuse,
  onEnthuse,
  likesCount,
  fontSize = 20,
  minWidth = '2.5rem',
  svgClass = '',
  textClass = '',
  containerClass = '',
  contentView = false,
  isFeedPost = false,
  ...props
}: EnthuseButtonProps) {
  return (
    <ButtonGroup
      onClick={(e) => {
        e.preventDefault();
        onEnthuse();
      }}
      display={{ base: 'block', md: 'inline-flex' }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      verticalAlign="middle"
      spacing={0}
    >
      <div
        className={`justify-center flex items-center ${containerClass} ${
          contentView && 'md:contents'
        }`}
      >
        <IconButton
          aria-label="enthuse"
          variant="ghost"
          color="gray.500"
          colorScheme="white"
          isRound
          minWidth={minWidth}
          icon={
            <EnthuseIcon
              isEnthuse={isEnthuse}
              fontSize={fontSize}
              svgClass={svgClass}
              isFeedPost={isFeedPost}
            />
          }
          {...props}
        />
        <Text
          className={`${textClass || 'ml-1'}`}
          fontWeight="500"
          lineHeight="20px"
          fontSize="14px"
          display="inline"
          color={`${isFeedPost ? 'white' : 'gray.500'}`}
        >
          {formatNumber({ number: likesCount })}
        </Text>
      </div>
    </ButtonGroup>
  );
}

export interface EnthuseIconProps extends ComponentProps<typeof SvgEnthuseNew> {
  isEnthuse: boolean;
  fontSize?: number;
  svgClass?: string;
  isFeedPost?: boolean;
}

export function EnthuseIcon({
  isEnthuse,
  fontSize,
  svgClass,
  isFeedPost,
  ...props
}: EnthuseIconProps) {
  if (isFeedPost) {
    return isEnthuse ? (
      <SvgEnthused className={svgClass} fontSize={fontSize} {...props} />
    ) : (
      <SvgEnthuseV2 className={svgClass} fontSize={fontSize} {...props} />
    );
  }
  return isEnthuse ? (
    <SvgEnthusedNew
      fill="none"
      className={svgClass}
      fontSize={fontSize}
      {...props}
    />
  ) : (
    <SvgEnthuseNew
      fill="none"
      className={svgClass}
      fontSize={fontSize}
      {...props}
    />
  );
}
