import { Modal, ModalContent, ModalBody } from '@chakra-ui/react';

import SearchPanel from './searchPanel';

const SearchPanelModal = ({ modal }: { modal?: any }) => {
  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
      <ModalContent
        maxW="626px"
        borderRadius="0"
        boxShadow="none"
        background="none"
      >
        <ModalBody p={0}>
          <SearchPanel modal={modal} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SearchPanelModal;
