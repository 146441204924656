import * as React from 'react';
import { Svg } from '../svg';

function SvgTypeform({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.119.044c3.083-.254 5.298.565 7.68 2.946 2.573 2.571 4.284 5.903 4.745 9.453.513 3.947-.193 6.892-2.365 9.048-2.078 2.062-5.112 2.743-9.166 2.404l-.168-.015c-3.451-.317-5.893-1.45-8.258-3.845C2.06 17.476.728 14.571.8 11.521c.034-1.493.457-2.765 1.273-4.014C2.608 6.69 3.15 6.05 4.287 4.88l.379-.388.417-.424C7.775 1.361 9.548.256 12.12.044zm7.498 3.13C17.287.846 15.147.055 12.14.303 9.606.51 7.865 1.619 5.141 4.378l-.197.2c-1.327 1.349-1.95 2.039-2.497 2.836l-.08.118-.077.117c-.79 1.21-1.198 2.435-1.231 3.878-.07 2.974 1.232 5.814 3.712 8.326 2.36 2.39 4.784 3.492 8.264 3.783l.172.014c3.899.3 6.805-.373 8.79-2.342 2.107-2.093 2.793-4.958 2.29-8.832-.454-3.49-2.137-6.77-4.67-9.302zM16.39 8.72v1.266H13.7v7.258h-1.34V9.987H9.674V8.721h6.718z"
        fill="#262627"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTypeform);
export default ForwardRef;
