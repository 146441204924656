import * as React from 'react';
import { Svg } from '../svg';

function SvgSketch({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        clipPath="url(#Sketch_svg__clip0_5610_67944)"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <path
          d="M4.814 7.121L10.729 18.7.786 7.121h4.028zM16.646 7.121L10.731 18.7l9.943-11.578h-4.028z"
          fill="#EA6C00"
        />
        <path d="M4.814 7.121h11.83L10.73 18.7 4.814 7.121z" fill="#FDAD00" />
        <path
          d="M10.729.703l-5.604.593-.311 5.824L10.729.703zM10.729.703l5.604.593.311 5.824L10.73.703z"
          fill="#FDD231"
        />
        <path
          d="M20.674 7.121l-4.34-5.824.312 5.824h4.027zM.786 7.121l4.34-5.824-.312 5.824H.786z"
          fill="#FDAD00"
        />
        <path d="M10.729.703L4.814 7.12h11.83L10.73.703z" fill="#FEEEB7" />
      </g>
      <defs>
        <clipPath id="Sketch_svg__clip0_5610_67944">
          <path
            fill="#fff"
            transform="translate(.75 .615)"
            d="M0 0h20v18.104H0z"
          />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgSketch);
export default ForwardRef;
