import { Box, useDisclosure } from '@chakra-ui/react';
import { useRouterQuery } from 'src/hooks/use-router-query';
import { ArticleDetailModal } from 'src/app/article/detail-modal';
import {
  useUpdateInviteMutation,
  updateNotification,
} from 'src/app/auth/service';

export const GetLink = (notifData) => {
  const { router } = useRouterQuery('rid', 'cid');
  const notifModal = useDisclosure();
  const newData = notifData?.notif;
  let redirectLink;
  const { mutate: inviteUpdateMutate } = useUpdateInviteMutation();

  const cardConsume = async (event, e) => {
    e.stopPropagation();
    if (!event.read) {
      if (notifData?.notif?.space?.id) {
        await inviteUpdateMutate(String(event.id));
      } else {
        await updateNotification(event.id);
      }
    }

    notifData.clicked(event.id);
  };
  if (newData?.destination?.type) {
    switch (newData?.destination.type) {
      case 'PROJECT':
        redirectLink = `/space/${newData?.destination.projectID}`;
        break;
      case 'PROJECT_TEAM':
        redirectLink = `/space/${newData?.destination.projectID}/team`;
        break;
      case 'MANAGE_INVITATION':
        redirectLink = `/space/${newData?.destination.projectID}/invitations`;
        break;
      case 'SUBMISSION_HISTORY':
        if (newData?.destination.projectID) {
          redirectLink = `/space/${newData?.destination.projectID}/submissions`;
        } else {
          redirectLink = `/${newData?.destination.username}/drafts-submissions`;
        }
        break;
      case 'STUDIO':
        redirectLink = `/${newData?.destination.username}`;
        break;
      case 'COMMENT':
      case 'POST':
        break;
      case 'COLLECTION':
        if (newData?.destination.projectID) {
          redirectLink = `/space/${newData?.destination.projectID}/collections/${newData?.destination.collectionID}`;
        } else {
          redirectLink = `/${newData?.destination.username}/collections/${newData?.destination.collectionID}`;
        }
        break;
      case 'SPACE':
        redirectLink = `/space/${newData?.destination.spaceID}`;
        break;
      default:
        redirectLink = '/';
    }
  }

  if (notifData?.notif?.space?.id) {
    redirectLink = `/space/${notifData?.notif?.space?.id}`;
  }
  const handleCardClick = (event, e) => {
    e.stopPropagation();
    if (event) {
      router.push(event);
    } else {
      notifModal.onOpen();
    }
  };

  return (
    <>
      <Box
        w="100%"
        onClick={(e) => {
          cardConsume(newData, e);
          handleCardClick(redirectLink, e);
        }}
      >
        {notifData.children}
      </Box>
      {notifModal.isOpen && newData?.destination?.postID && (
        <ArticleDetailModal
          id={newData?.destination.postID}
          modal={notifModal}
          publish
        />
      )}
    </>
  );
};
