/**
 * Internal dependencies
 */
import categories from 'src/configs/categories';
import skills from 'src/configs/skills';
import languages from 'src/configs/languages';
import services from 'src/configs/service';

import { FILTER_NAME } from './constants';

import { CategoryFilterTitle, CategoryFilterContent } from './categories';
import { SkillFilterTitle, SkillFilterContent } from './skills';
import { ServiceFilterTitle, ServiceFilterContent } from './services';
import { LanguageFilterTitle, LanguageFilterContent } from './languages';

export const FILTERS = {
  CATEGORIES: {
    name: FILTER_NAME.CATEGORIES,
    filterList: categories,
    accordionItem: {
      title: CategoryFilterTitle,
      component: CategoryFilterContent,
    },
  },
  SKILLS: {
    name: FILTER_NAME.SKILLS,
    filterList: skills,
    accordionItem: {
      title: SkillFilterTitle,
      component: SkillFilterContent,
    },
  },
  SERVICES: {
    name: FILTER_NAME.SERVICES,
    filterList: services,
    accordionItem: {
      title: ServiceFilterTitle,
      component: ServiceFilterContent,
    },
  },
  LANGUAGES: {
    name: FILTER_NAME.LANGUAGES,
    filterList: languages,
    accordionItem: {
      title: LanguageFilterTitle,
      component: LanguageFilterContent,
    },
  },
};
