import { axios } from 'src/lib/axios';
import { RestResponse } from 'src/types';
import {
  CreateSpaceResponse,
  Space,
  SpaceRequest,
  TopicListRequest,
  TopicListRequestQuery,
  TopicPostsListRequest,
  TopicPostsListRequestQuery,
  TopicsRequest,
  TopicsRequestQuery,
  UpdateSpaceResponse,
} from './types';

export async function fetchTopicList({
  data,
  params,
}: {
  data: TopicListRequest;
  params?: TopicListRequestQuery;
}) {
  return axios
    .get(`spaces/${data.spaceID}/${data.projectType}`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => ({
      ...res.data,
      pageNum: params.pageNum,
    }));
}

export async function fetchTopicsList({
  data,
  params,
}: {
  data?: any;
  params?: { lastID?: string; lastTime?: any };
}) {
  return axios
    .get(`spaces/${data.spaceID}/${data.projectType}`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => res.data);
}

export function getTopicData(id: string) {
  return axios
    .get(`topics/${id}`)
    .then((res) => res.data)
    .then((res) => res.data);
}

export function getFullTopic(id: string) {
  return axios
    .get(`topics/${id}`)
    .then((res) => res.data)
    .then((res) => res.data);
}

export function getAllTopics(
  { spaceID, projectType }: TopicsRequest,
  params: TopicsRequestQuery
) {
  return axios
    .get(`spaces/${spaceID}/${projectType}`, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
    .then((res) => res.data);
}

export const createSpace = (data: SpaceRequest) =>
  axios.post<CreateSpaceResponse>('/spaces', data).then((res) => res.data);

export const deleteSpace = (id: string) =>
  axios.delete(`/spaces/${id}`).then((res) => res.data);

export const getSpace = (id: string) => {
  return axios
    .get<RestResponse<Space>>(`/spaces/${id}`)
    .then((res) => res.data)
    .then((res) => res.data);
};

export const updateSpace = (data) => {
  return axios
    .put<UpdateSpaceResponse>(`/spaces/${data.spaceId}`, data.reqData)
    .then((res) => res.data);
};

export function fetchTopicPostsList(
  { topicID }: TopicPostsListRequest,
  params: TopicPostsListRequestQuery
) {
  return axios
    .get(`topics/${topicID}/posts`, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
    .then((res) => {
      return {
        ...res.data,
        pageNum: params.pageNum,
      };
    });
}

export function getSentInvitations(id: string, params: any) {
  return axios
    .get(`space/${id}/team`, { params })
    .then((res) => res.data)
    .then((res) => {
      return {
        ...res.data,
        pageNum: params.pageNum,
      };
    });
}

export const deleteTeamMember = ({
  userId,
  spaceId,
}: {
  userId: number;
  spaceId: string;
}) =>
  axios
    .delete<RestResponse>(`spaces/${spaceId}/editors/${userId}`)
    .then((res) => res.data);

export const deleteuserInvitation = ({
  inviteId,
  spaceId,
}: {
  inviteId: string;
  spaceId: string;
}) =>
  axios
    .delete<RestResponse>(`space/${spaceId}/invite/${inviteId}`)
    .then((res) => res.data);
