import * as React from 'react';
import { Svg } from '../svg';

function SvgDuplicate2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        clipPath="url(#Duplicate2_svg__clip0_4709_276974)"
        stroke="#BBC0C5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16.853 7.5h-7.5c-.92 0-1.666.746-1.666 1.667v7.5c0 .92.746 1.666 1.666 1.666h7.5c.92 0 1.667-.746 1.667-1.666v-7.5c0-.92-.746-1.667-1.667-1.667z" />
        <path d="M4.353 12.5H3.52a1.667 1.667 0 01-1.667-1.667v-7.5A1.667 1.667 0 013.52 1.666h7.5a1.667 1.667 0 011.667 1.667v.833" />
      </g>
      <defs>
        <clipPath id="Duplicate2_svg__clip0_4709_276974">
          <path fill="#fff" transform="translate(.187)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgDuplicate2);
export default ForwardRef;
