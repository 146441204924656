import * as React from 'react';
import { Svg } from '../svg';

function SvgMagnifying({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g filter="url(#magnifying_svg__filter0_d_2316_44855)">
        <rect
          x={4}
          y={2.25}
          width={39.643}
          height={40}
          rx={19.822}
          fill="#000"
          fillOpacity={0.3}
        />
        <g
          opacity={0.8}
          stroke="#fff"
          strokeWidth={1.228}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M31.188 29.75h-3.929m3.929 0l-4.911-5 4.91 5zm0 0v-4 4zM16.456 29.75l4.91-5m-4.91 1v4-4zm0 4h3.928-3.928zM31.188 14.75l-4.911 5m4.91-1v-4 4zm0-4H27.26h3.929zM16.456 14.75l4.91 5m-4.91-1v-4 4zm0-4h3.928-3.928z" />
        </g>
        <rect
          x={4.5}
          y={2.75}
          width={38.643}
          height={39}
          rx={19.322}
          stroke="#fff"
          strokeOpacity={0.3}
        />
      </g>
      <defs>
        <filter
          id="magnifying_svg__filter0_d_2316_44855"
          x={0}
          y={0.25}
          width={47.643}
          height={48}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2316_44855"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2316_44855"
            result="shape"
          />
        </filter>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgMagnifying);
export default ForwardRef;
