import * as React from 'react';
import { Svg } from '../svg';

function SvgIncreasedRating({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M1.068 6a.5.5 0 01-.385-.82L4.616.46a.5.5 0 01.768 0l3.933 4.72a.5.5 0 01-.385.82H1.068z"
        fill="#2BC3A8"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgIncreasedRating);
export default ForwardRef;
