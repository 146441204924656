import React, { createContext, useState } from 'react';

export interface ToastContextType {
  show: boolean;
  toastText: string;
  toastHandle: Function;
  className: string;
  setClassName: Function;
}

export const ToastContext = createContext<ToastContextType | undefined>(
  undefined
);
type Props = {
  children: React.ReactNode;
};

const ToastProvider = ({ children }: Props) => {
  const [show, setShow] = useState(false);
  const [className, setClassName] = useState('top-16 right-10');
  const [toastText, setToastText] = useState('');

  const toastHandle = (text, showFlag) => {
    setShow(showFlag);
    setToastText(text);
  };
  return (
    <ToastContext.Provider
      value={{ show, toastText, toastHandle, className, setClassName }}
    >
      {children}
    </ToastContext.Provider>
  );
};
export default ToastProvider;
