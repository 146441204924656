import * as React from 'react';
import { Svg } from '../svg';

function SvgUsers({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        clipPath="url(#users_svg__clip0_6880_3978)"
        stroke="#0278E4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.666 17.707V16.04a3.333 3.333 0 00-3.333-3.333H4.666a3.333 3.333 0 00-3.333 3.333v1.667M8 9.374a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667zM19.667 17.707V16.04a3.334 3.334 0 00-2.5-3.225M13.833 2.815a3.334 3.334 0 010 6.459" />
      </g>
      <defs>
        <clipPath id="users_svg__clip0_6880_3978">
          <path fill="#fff" transform="translate(.5 .207)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgUsers);
export default ForwardRef;
