import * as React from 'react';
import { Svg } from '../svg';

function SvgUnsplash({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M7.083 9.167v4.166h5.834V9.167H17.5V17.5h-15V9.167h4.583zM12.917 2.5v4.167H7.083V2.5h5.834z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgUnsplash);
export default ForwardRef;
