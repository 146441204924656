import { useTutorialMutation } from 'src/app/explore/use-tutorial';

export const SubmitAnEpisode1Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Join a Space
      </h1>
      <img
        src="/images/assets/tutorial/submit-an-episode-1.png"
        alt="submit an episode 1"
        className="w-full"
      />
    </>
  );
};

export const SubmitAnEpisode1Footer = ({
  closeModal,
  completed,
}: {
  closeModal?: any;
  completed: boolean;
}) => {
  const { mutateAsync: updateTutorial } = useTutorialMutation();
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Official vs Open Episodes
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Official episodes are works curated by the editors, therefore your
        submission will be reviewed before being approved. Open episodes are
        free for all creators to leave their mark!
      </p>
      <button
        type="button"
        onClick={async () => {
          if (!completed) {
            await updateTutorial({ step: 'submit_episode' });
          }
          closeModal();
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Got it
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        1 / 1
      </h6>
    </>
  );
};
