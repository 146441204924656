import * as React from 'react';
import { Svg } from '../svg';

function SvgNftPlaceholder({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M28 21.333V10.666a2.667 2.667 0 00-1.333-2.306l-9.334-5.334a2.667 2.667 0 00-2.666 0L5.333 8.36A2.667 2.667 0 004 10.666v10.667a2.667 2.667 0 001.333 2.307l9.334 5.333a2.667 2.667 0 002.666 0l9.334-5.333A2.667 2.667 0 0028 21.333z"
        stroke="#D3D6D8"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 16.276L16 7l5 9.276L16 20l-5-3.724z"
        stroke="#D3D6D8"
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
      <path
        d="M11 16l4.998 9L21 16"
        stroke="#D3D6D8"
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNftPlaceholder);
export default ForwardRef;
