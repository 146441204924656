import { isBrowser } from 'src/constants/env';

export async function importAllEditorTools() {
  if (isBrowser()) {
    const [
      Delimiter,
      Header,
      EditorJS,
      InlineCode,
      EditorList,
      Marker,
      Quote,
      UnderLine,
      Paragraph,
      Undo,
      Mention,
      CodeTool,
      Table,
      Alert,
      Checklist,
      Image,
    ] = await Promise.all([
      import('@editorjs/delimiter').then((s) => s.default),
      import('@editorjs/header').then((s) => s.default),
      import('@editorjs/editorjs').then((s) => s.default),
      import('@editorjs/inline-code').then((s) => s.default),
      import('@1tmio/editorjs-list').then((s) => s.default),
      import('@editorjs/marker').then((s) => s.default),
      import('@editorjs/quote').then((s) => s.default),
      import('@editorjs/underline').then((s) => s.default),
      import('@1tmio/editor-paragraph-with-alignment').then((s) => s.default),
      import('@1tmio/editorjs-undo').then((s) => s.default),
      import('src/lib/editor/mention').then((s) => s.default),
      import('@editorjs/code').then((s) => s.default),
      import('@editorjs/table').then((s) => s.default),
      import('editorjs-alert').then((s) => s.default),
      import('@editorjs/checklist').then((s) => s.default),
      import('@editorjs/image').then((s) => s.default),
    ]);

    class List extends EditorList {
      /**
       * Returns list tag with items
       *
       * @returns {Element}
       * @public
       */
      render() {
        return super.render();
      }

      /**
       * @returns {ListData}
       * @public
       */
      save() {
        return super.save();
      }

      /**
       * Toggles List style
       *
       * @param {string} style - 'ordered'|'unordered'
       */
      toggleTune(style) {
        super.toggleTune(style);
        // eslint-disable-next-line no-underscore-dangle
        this._elements.wrapper.addEventListener(
          'keydown',
          (event) => {
            const [ENTER, BACKSPACE] = [13, 8]; // key codes

            switch (event.keyCode) {
              case ENTER:
                this.getOutofList(event);
                break;
              case BACKSPACE:
                this.backspace(event);
                break;
              default:
                break;
            }
          },
          false
        );
      }
    }

    return {
      Delimiter,
      Header,
      EditorJS,
      InlineCode,
      List,
      Marker,
      Quote,
      UnderLine,
      Paragraph,
      Undo,
      Mention,
      CodeTool,
      Table,
      Alert,
      Checklist,
      Image,
    };
  }
  return undefined;
}
