import * as React from 'react';
import { Svg } from '../svg';

function SvgShareNew1({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M15.5 6.667a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM5.5 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM15.5 18.333a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM7.658 11.258l5.692 3.317M13.341 5.425L7.659 8.742"
        stroke="#0278E4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgShareNew1);
export default ForwardRef;
