const baseStyle = {
  transition: `all 0.15s ease-out`,
  cursor: 'pointer',
  textDecoration: 'none',
  outline: 'none',
  color: 'blue.500',

  _hover: {
    textDecoration: 'underline',
  },

  _focus: {
    boxShadow: 'outline',
  },

  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    textDecoration: 'none',
  },
};

export default {
  baseStyle,
};
