import * as React from 'react';
import { Svg } from '../svg';

function SvgRecruit({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M21.333 28v-2.667A5.333 5.333 0 0016 20H6.666a5.333 5.333 0 00-5.333 5.333V28M11.333 14.667a5.333 5.333 0 100-10.667 5.333 5.333 0 000 10.667zM26.667 10.666v8M30.667 14.666h-8"
        stroke="#0278E4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgRecruit);
export default ForwardRef;
