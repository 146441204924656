import * as React from 'react';
import { Svg } from '../svg';

function SvgGoogledrive({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M2.617 18.617l1.053 1.819c.218.383.533.684.902.902l3.76-6.509H.813c0 .424.109.848.328 1.23l1.477 2.558z"
        fill="#0066DA"
      />
      <path
        d="M12.75 7.17L8.99.662c-.37.219-.684.52-.903.903L1.14 13.598a2.478 2.478 0 00-.328 1.23h7.52l4.418-7.657z"
        fill="#00AC47"
      />
      <path
        d="M20.928 21.338c.369-.218.683-.52.902-.902l.438-.752 2.092-3.624c.22-.383.329-.807.329-1.23h-7.523l1.601 3.144 2.16 3.364z"
        fill="#EA4335"
      />
      <path
        d="M12.75 7.17L16.51.662a2.404 2.404 0 00-1.23-.328h-5.06c-.438 0-.862.123-1.23.328l3.76 6.51z"
        fill="#00832D"
      />
      <path
        d="M17.167 14.83H8.333l-3.76 6.509c.368.218.792.328 1.23.328h13.894c.438 0 .862-.123 1.23-.328l-3.76-6.51z"
        fill="#2684FC"
      />
      <path
        d="M20.887 7.58l-3.474-6.016a2.484 2.484 0 00-.902-.903l-3.761 6.51 4.417 7.658h7.508c0-.424-.11-.848-.329-1.231l-3.46-6.017z"
        fill="#FFBA00"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgGoogledrive);
export default ForwardRef;
