import { theme } from '@chakra-ui/theme';

export default {
  ...theme.components.CloseButton,
  variants: {
    circle: {
      bg: 'gray.900',
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      fontSize: 'md',
      color: 'gray.100',
      _hover: {
        bg: 'gray.900',
      },
    },
  },
};
