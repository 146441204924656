import SvgCheck from 'src/components/icons/check';
import SvgSearch from 'src/components/icons/search';
// import is from 'is_js';
import { useState, useRef } from 'react';
// import SvgFilterGroupShow from 'src/components/icons/filter-group-show';
// import SvgFilterHide from 'src/components/icons/filter-hide';

interface Item {
  id: number;
  value?: string;
  displayName: string;
}
interface Group {
  id: number;
  groupName: string;
  items: Array<Item>;
}

interface OneLayerProps {
  className?: string;
  list: Array<Group>;
  selectList: Object;
  action?: Function;
}
const TwoLayer = ({
  className = '',
  list,
  selectList,
  action,
}: OneLayerProps) => {
  const [listOpen, setListOpen] = useState([]);
  const [currList, setCurrList] = useState<any>({});
  const inputKeyword = useRef<any>();
  const [searchList, setSearchList] = useState(list);

  function searchHandle() {
    const newList = [];
    list.forEach((v) => {
      if (
        v.groupName
          .toUpperCase()
          .indexOf(inputKeyword.current.value.toUpperCase()) > -1
      ) {
        newList.push(v);
      }
    });
    setSearchList(newList);
    setListOpen([]);
  }

  // function checkListOpen(ilist) {
  //   let isListOpen = false;
  //   listOpen.forEach((ix) => {
  //     if (list[ix] === ilist) {
  //       isListOpen = true;
  //     }
  //   });
  //   return isListOpen ? '' : 'hidden';
  // }

  function toggleOpenList(set) {
    let isToggled = false;
    listOpen.forEach((il) => {
      if (il === set.id) {
        isToggled = true;
      }
    });
    if (isToggled) {
      setCurrList({ items: [] });
      setListOpen(listOpen.filter((listItem) => listItem !== set.id));
    } else {
      setCurrList(set);
      setListOpen([set.id]);
    }
  }

  function getSelectList(select, group) {
    let isSelect = false;
    Object.entries(selectList).forEach((si) => {
      if (si[0] === `${group.id}`) {
        si[1].forEach((l) => {
          if (l === select.id) {
            isSelect = true;
          }
        });
      }
    });
    return isSelect;
  }
  return (
    <div
      style={{ maxWidth: '610px', maxHeight: '335px' }}
      className="flex w-max list-layer"
    >
      <div style={{ width: '305px' }} className="p-6 border-r border-gray-200">
        <div className="flex mb-2">
          <div style={{ width: '100%' }} className="relative">
            <span
              aria-hidden="true"
              className="text-gray-300 cursor-pointer pt-0.5 absolute left-2 top-0.5"
            >
              <SvgSearch className="dark:text-gray-300 text-2xl" />
            </span>
            <input
              id="search-input"
              type="text"
              className="text-sm border border-gray-300 w-full pl-8 h-8 rounded-2xl"
              ref={inputKeyword}
              placeholder="search"
              onKeyUp={() => searchHandle()}
            />
          </div>
          <div
            role="button"
            tabIndex={0}
            className="my-auto ml-4 w-max"
            onClick={() => action(-1, -1)}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                action(-1, -1);
              }
            }}
          >
            <span className="float-right font-medium text-sm text-gray-500">
              Reset
            </span>
          </div>
        </div>
        {/* <ul className={`${className} cursor-pointer list-layer mt-2`}>
          {includeAll && (
            <li
              aria-hidden="true"
              className="cursor-pointer"
              onClick={() => {
                action(-1, -1);
              }}
            >
              <span
                className={`${
                  is.empty(selectList) ? 'text-black' : 'hover:text-black'
                }`}
              >
                {is.empty(selectList) && (
                  <SvgCheck className="inline text-base" />
                )}
                <span className={`${is.empty(selectList) ? 'ml-1' : 'ml-5'}`}>
                  Select all
                </span>
              </span>

              <span className="float-right">Reset</span>
            </li>
          )}
        </ul> */}
        <ul
          className={`${className} cursor-pointer max-h-60 overflow-y-scroll`}
        >
          {searchList.map((s) => {
            const checked = !!selectList[s.id];

            return (
              <li key={s.id} className="relative list-layer-content">
                <div
                  role="button"
                  className={`layer-icon-hover rounded p-0 ${
                    checked ? 'text-gray-500' : 'hover:text-white'
                  }`}
                  onClick={() => toggleOpenList(s)}
                  onKeyDown={() => toggleOpenList(s)}
                  tabIndex={0}
                >
                  <span className="w-4 inline-block">
                    <SvgCheck
                      className={`text-base ${
                        checked ? 'inline text-blue-500' : 'hidden'
                      }`}
                    />
                  </span>
                  <span
                    className={`text-sm font-medium ml-1 ${
                      checked && 'text-blue-500'
                    }`}
                  >
                    {s.groupName}
                  </span>
                </div>
                {/* {currList.length > 0 && (
                  <div className={`${checkListOpen(s)}`}>
                    {currList?.map((x) => (
                      <div
                        key={x.id}
                        className={`layer-icon-hover h-6 ml-5 py-4 ${
                          getSelectList(x, s) ? 'text-gray-500' : 'hover:text-white'
                        }`}
                        aria-hidden="true"
                        onClick={() => {
                          action(x.id, s.id);
                        }}
                      >
                        <span className="w-4 inline-block">
                          <SvgCheck
                            className={`text-base ${
                              getSelectList(x, s) ? 'inline text-blue-500' : 'hidden'
                            }`}
                          />
                        </span>
                        <span
                          className={`text-sm font-medium ml-1 ${
                            getSelectList(s) ? 'text-blue-500' : ''
                          }`}
                        >
                          {x.displayName}
                        </span>
                      </div>
                    ))}
                  </div>
                )} */}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="pt-6 overflow-auto">
        {currList?.items?.length > 0 && (
          <ul className="px-6 pb-6" style={{ width: '305px' }}>
            {currList?.items?.map((x) => (
              <li
                key={x.id}
                style={{ paddingLeft: '0px' }}
                className={`layer-icon-hover h-6 py-4 ${
                  getSelectList(x, currList)
                    ? 'text-gray-500'
                    : 'hover:text-white'
                }`}
                aria-hidden="true"
                onClick={() => {
                  action(x.id, currList.id);
                }}
              >
                <span className="w-4 inline-block">
                  <SvgCheck
                    className={`text-base ${
                      getSelectList(x, currList)
                        ? 'inline text-blue-500'
                        : 'hidden'
                    }`}
                  />
                </span>
                <span
                  className={`text-sm font-medium ml-1 ${
                    getSelectList(x, currList) ? 'text-blue-500' : ''
                  }`}
                >
                  {x.displayName}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
export default TwoLayer;
