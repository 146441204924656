/** SEARCH */
export const SET_GENRE = 'SET_GENRE';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';

export const FETCH_SEARCH_START = 'FETCH_SEARCH_START';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';
export const FETCH_SEARCH_ERROR = 'FETCH_SEARCH_ERROR';

export const UPDATE_SEARCH_RESULT = 'UPDATE_SEARCH_RESULT';
// Original
// export const SET_SEARCH = 'SET_SEARCH';
// export const SET_LITE_SEARCH = 'SET_LITE_SEARCH';

export const CLEAR_SEARCH = 'CLEAR_SEARCH';
// Original
// export const CLEAR_LITE_SEARCH = 'CLEAR_LITE_SEARCH';

export const SET_SIMILAR_SUGGESTIONS = 'SET_SIMILAR_SUGGESTIONS';

/** SEARCH */
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_ALL_FILTERS = 'UPDATE_ALL_FILTERS';
export const UPDATE_FILTER_RESULT = 'UPDATE_FILTER_RESULT';

/** SUGGESTION */
export const FETCH_SIMILAR_SUGGESTIONS = 'FETCH_SIMILAR_SUGGESTIONS';
export const UPDATE_SIMILAR_SUGGESTIONS = 'UPDATE_SIMILAR_SUGGESTIONS';
export const UPDATE_TRENDING_SUGGESTIONS = 'UPDATE_TRENDING_SUGGESTIONS';
export const UPDATE_RECENT_SEARCH_TERMS = 'UPDATE_RECENT_SEARCH_TERMS';
