import { ExploreLayout, Agora } from 'src/app/explore/index';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { useContext, useEffect } from 'react';
import SignInModal from 'src/app/article/sign-in-modal';
import { useModal } from 'src/hooks/use-modal';

function IndexPage() {
  const { isLogin, validateVisitorPassed } = useContext(
    UserContext
  ) as UserContextType;
  const signinModal = useModal();

  useEffect(() => {
    const isVisitorPassed = validateVisitorPassed();
    if (!isLogin && !isVisitorPassed) {
      signinModal.onSelect(true);
    } else {
      signinModal.modal.onClose();
    }
  }, [isLogin, validateVisitorPassed, signinModal]);
  return (
    <>
      <Agora />
      {signinModal.selected && (
        <SignInModal
          isOpen={signinModal.modal.isOpen}
          onOpen={signinModal.modal.onOpen}
          onClose={() => {}}
          onToggle={signinModal.modal.onToggle}
          getButtonProps={signinModal.modal.getButtonProps}
          getDisclosureProps={signinModal.modal.getDisclosureProps}
          isControlled={signinModal.modal.isControlled}
          handleClose={signinModal.modal.onClose}
          title="This content is password protected. To view it please enter your password below:"
        />
      )}
    </>
  );
}

IndexPage.Layout = ExploreLayout;

export default IndexPage;
