import * as React from 'react';
import { Svg } from '../svg';

function SvgHome({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        clipPath="url(#Home_svg__clip0_7547_77780)"
        stroke="#4F5356"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.5 7.5L10 1.667 17.5 7.5v9.166a1.666 1.666 0 01-1.667 1.667H4.167A1.667 1.667 0 012.5 16.667V7.5z" />
        <path d="M7.5 18.333V10h5v8.333" />
      </g>
      <defs>
        <clipPath id="Home_svg__clip0_7547_77780">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgHome);
export default ForwardRef;
