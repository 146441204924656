import * as React from 'react';
import { Svg } from '../svg';

function SvgSketchfab({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.75.828c-6.186 0-11.2 5.014-11.2 11.199s5.014 11.2 11.2 11.2c6.186 0 11.2-5.015 11.2-11.2S18.937.828 12.75.828z"
        fill="#fff"
      />
      <path
        d="M12.75 0c-6.628 0-12 5.373-12 12s5.372 12 12 12 12-5.373 12-12-5.371-12-12-12zm-1.043 19.14l-5.173-2.986V10.13l5.173 2.794v6.217zm.922-7.633l-6.12-3.245 6.12-3.534 6.122 3.534-6.122 3.245zm6.126 4.659L13.6 19.142v-6.194l5.154-2.784v6.002z"
        fill="#1FAAD8"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgSketchfab);
export default ForwardRef;
