import * as React from 'react';
import { Svg } from '../svg';

function SvgEnthuseGray({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M7.083 12.333a2.083 2.083 0 002.083-2.083c0-1.15-.416-1.667-.833-2.5-.893-1.786-.187-3.378 1.667-5 .416 2.083 1.666 4.083 3.333 5.417 1.667 1.333 2.5 2.916 2.5 4.583a5.832 5.832 0 11-11.666 0c0-.96.36-1.912.833-2.5a2.083 2.083 0 002.083 2.083v0z"
        stroke="#9B9FA4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEnthuseGray);
export default ForwardRef;
