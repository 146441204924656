/**
 * Internal dependencies
 */
import { INITIAL_STATE } from './constants';
import * as reducers from './reducers';
import * as types from './reducers/types';

function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case types.FETCH_SEARCH_START:
      return {
        ...state,
        isFetching: true,
        isSearchLite: false,
      };

    case types.FETCH_SEARCH_SUCCESS:
      return reducers.fetchResultSuccess(
        { ...state, isFetching: false },
        payload
      );

    case types.FETCH_SEARCH_ERROR:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    case types.SET_SEARCH_TERM:
      return reducers.setSearchTerm(
        { ...state, isFetching: false },
        payload.searchTerm
      );

    case types.SET_GENRE:
      return reducers.setGenre({ ...state, isFetching: false }, payload.genre);

    case types.UPDATE_SEARCH_RESULT:
      return reducers.updateSearchResult(
        { ...state, isFetching: false },
        payload
      );

    case types.CLEAR_SEARCH:
      return reducers.clearSearch(
        { ...state, isFetching: false },
        payload.isSearchLite
      );

    default:
      return state;
  }
}

export default reducer;
