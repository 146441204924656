import * as React from 'react';
import { Svg } from '../svg';

function SvgPostMedia({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="url(#post-media_svg__pattern0)" d="M0 0h40v40H0z" />
      <defs>
        <pattern
          id="post-media_svg__pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use
            xlinkHref="#post-media_svg__image0_2602_48966"
            transform="scale(.0125)"
          />
        </pattern>
        <image
          id="post-media_svg__image0_2602_48966"
          width={80}
          height={80}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAABmJLR0QA/wD/AP+gvaeTAAACi0lEQVR4nO3cT0tUURzG8e+5o1bknyIh/yCKZDS2EYqkjauiEIIi8rX0Eno3raM2YqFERbVxNzSNJEPGgIaZOve0kEuhDV3n2D3n6PNZDbOY+fHM7977cAcuiIiIiMhJZLIXd54sWp+DxObZ45sGIPE9SOw69r+RJbtftqEPbt343zN59fTFa+DfOWS0gY4UoCMF6EgBOlKAjg5chdUH9+TNQRvo6NA98KRQDyyIAnSkAB0pQEcK0JF6YAvqgQVRD2xBPbAg3gO8e8lwttP3FO3zHuBon2HuakK5/69HTPC8BwjQVYKZUcPshKGny/c0hxNEgJmR3r1tnBowmEgWMrge2JHA9LBhrM8wX01pbPmZI/oeeLEbHpYTrg8ZkoC3MegeWErg2qBh7Jxh/lPK183ivvtY9cALZ+D+lYTpYUMpsIkDG6e1xMDUgGFuMmGoJ5xjOpoAM72n4N5lw8yoobPke5oIA8yU+w2PJhNGev1uY7QBAvR0weyE4fa44fSBy2ExguuB7Rg/bxjsNrysWSqNoxk/+h4Yi6B7YF6VhmXhs2Vr9+g+M28Ons4cR2NjGxaqltq6v9822gCX1yyLK5adpt85ogtw/SfMVy1fNsI4o0QTYGrhY93yZtXSTH1P81sUAX77QeE3E/IKugc2U3hft7xbtaQFT5U3h2A3sP4drzdU8wquB+6m8HbV8qFusR6PhSh7YG3dslC1bGz7niS/IALcbsLSimV5LZjTb27eA6w0LK9qls0d35O0x3uAzyvxbd2fdDfGUdA90CfdDyxIcD0wFMfqf+GQKUBHCtCRAnSkAB2pB7agHlgQ9cAW1AMLogAdKUBHCtCRAnSk5we2Sc8PFBERkcj9AjfU5/vdGD09AAAAAElFTkSuQmCC"
        />
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPostMedia);
export default ForwardRef;
