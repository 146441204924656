import * as React from 'react';
import { Svg } from '../svg';

function SvgFacebookV3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10 .272C4.477.272 0 4.64 0 10.028c0 5.389 4.477 9.757 10 9.757s10-4.368 10-9.757C20 4.64 15.523.272 10 .272z"
        fill="currentColor"
      />
      <path
        d="M10.89 10.478h1.997l.314-1.98h-2.312V7.416c0-.822.276-1.552 1.064-1.552h1.267V4.137c-.222-.03-.693-.094-1.583-.094-1.858 0-2.947.958-2.947 3.139v1.317H6.78v1.98h1.91v5.442c.378.055.761.092 1.154.092.356 0 .703-.031 1.045-.076v-5.459z"
        fill="#242526"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgFacebookV3);
export default ForwardRef;
