import is from 'is_js';

export const dataSort = (
  data,
  sortType,
  { reverse = false, ignoreCase = true } = {}
) => {
  if (data.length > 1 && is.array(data)) {
    data.sort((a, b) => {
      let A = a[sortType];
      let B = b[sortType];
      if (
        typeof a[sortType] === 'string' &&
        typeof b[sortType] === 'string' &&
        ignoreCase
      ) {
        A = A.toUpperCase();
        B = B.toUpperCase();
      }
      return A >= B ? 1 : -1;
    });
  }
  if (reverse) return data.reverse();
  return data;
};

// For old function
export const genreSort = (data, genreKey, sortTypeSelect, reverse = true) => {
  let sortType = 'updatedAt';
  switch (genreKey) {
    case 'creators':
      sortType = sortTypeSelect === 'Recently' ? 'username' : 'numOfFollowers';
      break;
    case 'creations':
      sortType = sortTypeSelect === 'Recently' ? 'updatedAt' : 'enthuseCount';
      break;
    case 'projects':
      sortType = sortTypeSelect === 'Recently' ? 'title' : 'title';
      break;
    default:
      sortType = sortTypeSelect;
      break;
  }

  return dataSort(data, sortType, { reverse });
};

export const spaceTeamSort = (team) => {
  if (!team) return [];
  const sortTeam = [...team];
  sortTeam.sort((a, b) => {
    if (a.role === 'SPACE_FOUNDER') return -1;
    if (b.role === 'SPACE_FOUNDER') return 1;
    return 0;
  });
  return sortTeam;
};
