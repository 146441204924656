import { axios } from 'src/lib/axios';
import type { RestResponse } from 'src/types';

export const changeUsername = (data: any) =>
  axios.put<RestResponse>('/username', data).then((res) => res.data);

export function fetchUsername(username: string) {
  return axios
    .get<RestResponse<any>>(`user/username/${username}`)
    .then((res) => res.data);
}

export function fetchWallet(address: string) {
  return axios
    .get<RestResponse<any>>(`user/public-address/${address}`)
    .then((res) => res.data);
}
