import { axios } from 'src/lib/axios';
import { RestResponse } from 'src/types';

type CreateSpaceResponse = RestResponse<{
  id: string;
}>;

export function createInvitation({
  rid,
  invitedIDs,
  message,
}: {
  rid: string;
  invitedIDs: number[];
  message: string;
}) {
  return axios
    .post<CreateSpaceResponse>(`/spaces/${rid}/invite`, {
      roleType: 'SPACE_EDITOR', // TODO: for now just only editor
      invitedUsers: invitedIDs,
      message,
    })
    .then((res) => res.data);
}
