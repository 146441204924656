import {
  Button,
  Center,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import SvgWarning from 'src/components/icons/warning';

function WarningModalWithoutCancel({
  isOpen,
  onClose,
  title,
  subTitle,
  text,
  okBtnText = 'Remove',
}: UseDisclosureReturn & {
  title: string;
  subTitle?: string;
  text?: any;
  okBtnText?: string;
}) {
  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay>
        <ModalContent
          bg="$mode.50"
          pt="10px"
          px={{ base: '15px', md: '30px' }}
          pb="40px"
          w="480px"
        >
          <Center flexDirection="column">
            <div className="relative p-5">
              <SvgWarning
                fontSize="26px"
                position="absolute"
                top="calc(50% - 13px)"
                left="calc(50% - 13px)"
              />
              <Box
                bgColor="warning"
                borderRadius={99}
                opacity="0.1"
                margin="auto"
                w="75px"
                h="75px"
              />
            </div>
            <Heading as="h3" textStyle="h3" color="black">
              <Text noOfLines={2} textAlign="center">
                {title}
              </Text>
              <Text>{subTitle}</Text>
            </Heading>
            {text ? (
              <Box py={3}>
                {/* eslint-disable */}
                {text.map((t, index) => {
                  return (
                    <Text
                      key={index}
                      textAlign="center"
                      textStyle="body0"
                      color="$mode.500"
                      pt={1}
                    >
                      {t}
                    </Text>
                  )
                })}
                {/* eslint-enable */}
              </Box>
            ) : (
              <Box mt={3} mb={6} />
            )}
            <Box>
              <Button
                width="100%"
                colorScheme="#FF6633"
                bgColor="warning"
                rounded="ellipse"
                onClick={() => {
                  onClose();
                }}
              >
                {okBtnText}
              </Button>
            </Box>
          </Center>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default WarningModalWithoutCancel;
