import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useState } from 'react';

import {
  EnableSubmissionsStartBody,
  EnableSubmissionsStartFooter,
} from './enable-submissions';
import {
  EnableSubmissions1Body,
  EnableSubmissions1Footer,
} from './enable-submissions-1';
import {
  EnableSubmissions2Body,
  EnableSubmissions2Footer,
} from './enable-submissions-2';

const EnableSubmissions = ({ modal }: { modal: any }) => {
  const [tutorialModalContent, setTutorialModalContent] = useState(
    'enableSubmissionsStart'
  );

  return (
    <>
      <Modal
        isOpen={modal.isOpen}
        onClose={() => {
          modal.onClose();
          modal.openChecklistModal();
          setTutorialModalContent('enableSubmissionsStart');
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalCloseButton color="gray.500" />
          <ModalBody px="60px" pt="40px" pb="24px" bg="white">
            {tutorialModalContent === 'enableSubmissionsStart' && (
              <EnableSubmissionsStartBody />
            )}
            {tutorialModalContent === 'enableSubmissions1' && (
              <EnableSubmissions1Body />
            )}
            {tutorialModalContent === 'enableSubmissions2' && (
              <EnableSubmissions2Body />
            )}
          </ModalBody>
          <ModalFooter
            flexDirection="column"
            alignItems="center"
            bg="gray.100"
            px="60px"
            py="25px"
          >
            {tutorialModalContent === 'enableSubmissionsStart' && (
              <EnableSubmissionsStartFooter
                setNextModal={setTutorialModalContent}
              />
            )}
            {tutorialModalContent === 'enableSubmissions1' && (
              <EnableSubmissions1Footer
                setNextModal={setTutorialModalContent}
              />
            )}
            {tutorialModalContent === 'enableSubmissions2' && (
              <EnableSubmissions2Footer
                completed={modal.completed}
                closeModal={() => {
                  modal.onClose();
                  modal.openChecklistModal();
                  setTutorialModalContent('enableSubmissionsStart');
                }}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EnableSubmissions;
