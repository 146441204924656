import React, { Fragment, ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

interface DropdownsProps {
  buttonComponent: ReactNode;
  items: Array<any>;
  className?: string | undefined;
  dataID?: string;
  width?: string;
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
  right?: string;
  textClass?: string;
  isClicked?: () => void;
  isSidebar?: boolean;
  setIsActions?: (val: boolean) => void;
}

const Dropdowns = (props: DropdownsProps) => {
  const {
    items,
    buttonComponent,
    width = props.isSidebar ? '12rem' : '14rem',
    position,
    right = '',
    dataID = '',
    textClass = 'text-gray-700',
    className = '',
  } = props;

  const { t } = useTranslation();

  return (
    <div className={`${className}`}>
      <Menu as="div" className="z-20 relative text-left">
        {({ open }) => {
          if (open && props.isClicked) props.isClicked();
          if (props.setIsActions) props.setIsActions(Boolean(open));

          return (
            <>
              <div>
                <Menu.Button className="w-full">{buttonComponent}</Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  style={{
                    width,
                    position,
                    right,
                  }}
                  className="menu-list z-50 origin-top-right absolute right-0 mt-2 rounded-md overflow-hidden shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-medium"
                >
                  {items.map((item, key) => {
                    const divKey = `${key}_${key}`;
                    return (
                      <div key={divKey} className="dark:bg-gray-900 py-2">
                        {item.map((i, mkey) => {
                          const menuKey = `menu_${mkey}`;
                          return (
                            <Menu.Item key={menuKey}>
                              {() => {
                                if (i.disabled) return <></>;
                                if (i.type === 'html') {
                                  return (
                                    <div
                                      role="button"
                                      tabIndex={0}
                                      onClick={i.action}
                                      onKeyDown={(e) => {
                                        if (e.code === 'Enter') {
                                          i.action();
                                        }
                                      }}
                                      className={`${textClass} block px-4 py-3 dark:text-white cursor-pointer aspect-square`}
                                    >
                                      {i.html && i.html}
                                    </div>
                                  );
                                }
                                if (i.type === 'link') {
                                  return (
                                    <a
                                      href={i.path}
                                      className={`${textClass} block px-4 py-3 dark:text-white cursor-pointer`}
                                    >
                                      {i.html && i.html}
                                      {!i.html && i.displayName}
                                    </a>
                                  );
                                }
                                return (
                                  <span
                                    onClick={i.action}
                                    aria-hidden="true"
                                    data-id={dataID}
                                    className={`${textClass} block px-4 py-3 dark:text-white cursor-pointer`}
                                    role="menuitem"
                                  >
                                    <span className="align-bottom ">
                                      {/* {i.icon && i.icon} */}
                                    </span>
                                    {t(i.displayName)}
                                  </span>
                                );
                              }}
                            </Menu.Item>
                          );
                        })}
                      </div>
                    );
                  })}
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    </div>
  );
};
export default React.memo(Dropdowns);
