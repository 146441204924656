import {
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerFooter,
  DrawerBody,
  DrawerContent,
  Box,
  Button,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditor } from 'src/lib/editor/use-editor';
import WarningModal from 'src/app/article/warning-modal';

export function EditorBlockComponent({
  modal,
  blockData,
  blockID,
  setBlockData,
  type,
}: {
  modal: any;
  blockData: any;
  blockID: string;
  setBlockData: (e: any) => void;
  type: string;
}) {
  const { t } = useTranslation();
  const warningModal = useDisclosure();
  const editingData = [...blockData].filter((e) => e.id === blockID);

  const [tempData, setTempData] = useState(editingData[0]?.data);

  const saveBlock = () => {
    const customBlock = [...blockData];
    customBlock.forEach((bData, i) => {
      if (bData.id === blockID) {
        customBlock[i].data = tempData;
        if (tempData?.blocks.length > 0) {
          customBlock[i].hasContent = true;
        }
        if (tempData?.blocks.length === 0) {
          customBlock[i].hasContent = false;
        }
      }
    });
    setBlockData(customBlock);
    modal.onClose();
  };

  const checkContent = () => {
    if (tempData?.blocks?.length > 0) {
      if (tempData === editingData[0]?.data) {
        modal.onClose();
      } else {
        warningModal.onOpen();
      }
    } else {
      warningModal.onOpen();
    }
  };

  if (type === 'drawer') {
    return (
      <>
        <Drawer
          isOpen={modal.isOpen}
          placement="right"
          onClose={checkContent}
          size="lg"
        >
          <DrawerOverlay>
            <DrawerContent>
              <DrawerBody
                paddingTop="62px"
                paddingLeft="90px"
                position="relative"
                overflow="hidden auto"
                style={{
                  maxHeight: 'calc(100vh - 72px)',
                }}
              >
                <EditingBlockContainer
                  editorBlock={editingData[0]?.data || {}}
                  setBlock={setTempData}
                />
              </DrawerBody>
              <DrawerFooter
                style={{ maxHeight: '72px' }}
                className=" fixed bottom-0 right-0 w-full bg-gray-50 border-t dark:bg-gray-800"
              >
                <Box>
                  <Button
                    colorScheme="gray"
                    onClick={checkContent}
                    variant="ghost"
                    rounded="ellipse"
                    fontWeight="600"
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => saveBlock()}
                    rounded="ellipse"
                    fontWeight="600"
                  >
                    {t('Save')}
                  </Button>
                </Box>
              </DrawerFooter>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
        <WarningModal
          {...warningModal}
          title={t('Leave without saving?')}
          text={t(
            'Leaving without saving will discard any of your changes, are you sure you want to leave?'
          )}
          onOk={() => {
            modal.onClose();
          }}
          okBtnText={t('Leave')}
          cancelBtnText={t('Cancel')}
          cancelBtnColor="#9B9FA4"
          cancelBtnScheme="#9B9FA4"
        />
        <style jsx global>{`
          .ce-toolbar__actions {
            right: 100% !important;
          }
          .codex-editor--narrow .ce-toolbox .ce-popover {
            right: unset !important;
          }
        `}</style>
      </>
    );
  }

  return <></>; // this is for modal type (general post)
}

function EditingBlockContainer({
  editorBlock,
  setBlock,
}: {
  editorBlock: any;
  setBlock: (e: any) => void;
}) {
  const [editorChange, setEditorChange] = useState(true);

  const { editor } = useEditor({
    editorId: `editingblock`,
    uniqueId: `editingblock`,
    data: editorBlock,
    areaType: 'EPISODE',
    imgType: 'episode_img',
    placeholder: 'Start your story here',
    editorOnChange: () => {
      setEditorChange((edit) => !edit);
    },
    readOnly: false,
  });

  const autoSaveOnChange = async () => {
    if (editor) {
      await editor.isReady;

      const content = await editor.save();
      setBlock(content);
    }
  };

  useEffect(() => {
    autoSaveOnChange();
  }, [editorChange]);

  return (
    <>
      <Box
        bg="white"
        id="editingblock"
        zIndex={0}
        onPaste={(e) => e.stopPropagation()}
      />
      <style>
        {' '}
        {`
        .codex-editor__redactor {
          padding-bottom: 30px !important;
        }
      `}
      </style>
    </>
  );
}
