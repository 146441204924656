import * as React from 'react';
import { Svg } from '../svg';

function SvgYoutube({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#youtube_svg__clip0_4815_67406)">
        <g clipPath="url(#youtube_svg__clip1_4815_67406)">
          <path
            d="M24.249 6.64a3.016 3.016 0 00-2.122-2.135C20.255 4 12.75 4 12.75 4s-7.505 0-9.377.505A3.015 3.015 0 001.251 6.64C.75 8.524.75 12.455.75 12.455s0 3.93.501 5.814a3.016 3.016 0 002.122 2.136c1.872.504 9.377.504 9.377.504s7.505 0 9.377-.504a3.016 3.016 0 002.122-2.136c.501-1.884.501-5.814.501-5.814s0-3.93-.502-5.815"
            fill="red"
          />
          <path d="M10.295 16.023l6.273-3.569-6.273-3.568v7.137z" fill="#fff" />
        </g>
      </g>
      <defs>
        <clipPath id="youtube_svg__clip0_4815_67406">
          <path fill="#fff" transform="translate(.75)" d="M0 0h24v24H0z" />
        </clipPath>
        <clipPath id="youtube_svg__clip1_4815_67406">
          <path
            fill="#fff"
            transform="translate(.75 4)"
            d="M0 0h24v16.909H0z"
          />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgYoutube);
export default ForwardRef;
