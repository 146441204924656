import dayHepler from 'src/heplers/day';

import {
  Box,
  Flex,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { KeyboardEvent, useState, useEffect, useRef } from 'react';

import {
  getProfileFullName,
  getProfileFullNameOrUsername,
} from 'src/app/profile/utils';
// import { toRem } from 'src/theme/tools';
import type { Comment } from 'src/app/article/types';

import SvgEnthuseGray from 'src/components/icons/enthuse-gray';
import SvgEnthused from 'src/components/icons/enthused-new';
import SvgDeleteEditComment from 'src/components/icons/delete-edit-comment';
import { rendererBlockMaps } from 'src/lib/hexx/render-blocks';
import { EditorRenderer } from '@hexx/renderer';
import { imageOptimize } from 'src/service/image';
import { useModal } from 'src/hooks/use-modal';
import { GalleryModal } from 'src/lib/hexx/blocks/gallery/gallery-renderer';
import { useDeleteCommentMutation } from 'src/app/article/hooks/use-article-comment';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { Avatar } from '../avatar';
import { Commenter, CommenterHandler } from './commenter';

export type CommentBlockProps = {
  isModal?: boolean;
  /**
   * Render block as sub comment.
   */
  asSubcomment?: boolean;

  /**
   * Render reply icon.
   */
  hideReply?: boolean;

  /**
   * Display viewer information.
   */
  viewer: any;

  /**
   * Handling input onKeyDown event.
   */
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>, commentID?: any) => void;

  /**
   * Handling if comment enthused
   */

  isEnthused?: boolean;

  /**
   * Handling click enthuse icon action.
   */
  onEnthuse?: () => void;

  onComment?: ({
    keyEvent,
    blockId,
    value,
    commentID,
  }: {
    keyEvent?: any;
    blockId?: string;
    value: string;
    commentID?: any;
  }) => void;

  index?: string;

  childReply?: any;
  setChildReply?: (value: any) => void;
  isReply?: any;
  /**
   * Handling reply button onClick event.
   */
  onReplyClick?: (input: ParentNode) => void;

  assets?: any; // TODO: not yet ready
  children?: any;
  marginTop?: string;
} & { comment: Comment };

export function CommentBlock(props: CommentBlockProps) {
  const commenterRef = useRef<CommenterHandler>(null);
  const [isEditing, setIsEditing] = useState(false);

  const { mutateAsync: deleteComment } = useDeleteCommentMutation();

  const {
    asSubcomment,
    comment: { commenter, content, enthuseCount, createdAt, id, parentID },
    viewer,
    hideReply,
    onKeyDown,
    isEnthused = false,
    onEnthuse,
    index,
    isModal,
    isReply,
    onReplyClick,
    setChildReply,
    marginTop = '16px',
  } = props;

  const commentId = props.comment.id;
  const isMobile = useIsMobile();

  const galleryModal = useModal();
  const [currAsset, setCurrAsset] = useState<any>();
  const [replyCommenter, setReplyCommenter] = useState<any>();
  const containerRef = useRef<any>(null);

  useEffect(() => {
    if (isReply) {
      if (isReply.id === id) {
        setIsEditing(true);
        setReplyCommenter(isReply.commenter);
      }
    }
  }, [isReply]);

  useEffect(() => {
    if (isEditing && Object.values(replyCommenter).length > 0) {
      const handleReplyClick = () => {
        if (
          containerRef.current &&
          commenterRef.current?.container &&
          onReplyClick
        ) {
          const offsetTop =
            containerRef.current.offsetTop +
            commenterRef.current.container.offsetTop;
          onReplyClick(offsetTop);
        }
      };

      handleReplyClick();
    }
  }, [isEditing, replyCommenter]);

  const [hideComment, setHideComment] = useState(false);
  const [showEditComment, setShowEditComment] = useState(false);

  if (hideComment) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing="12px"
      px="0px"
      borderRadius="4px"
      // pt={asSubcomment ? isMobile && '12px' : 3}
      ml={asSubcomment && !isMobile && 0}
      mt={marginTop}
      ref={containerRef}
    >
      {/* {!isMobile && asSubcomment && <Box w="2px" h="100%" />} */}
      <a href={`/${commenter?.username}`}>
        <Avatar
          name={getProfileFullNameOrUsername(commenter)}
          src={commenter?.profileImgURL}
          width={40}
          height={40}
          author={commenter}
        />
      </a>
      <Stack
        width="calc(100% - 40px)"
        direction="column"
        spacing={1}
        position="relative"
      >
        {/* commenter */}
        <div style={{ fontSize: '14px', display: 'flex' }}>
          <a
            href={`/${commenter?.username}`}
            style={{
              fontWeight: '600',
              maxWidth: `${isMobile ? '35%' : '45%'}`,
            }}
          >
            <div className="truncate pr-2">
              {`${getProfileFullName(commenter)}`}
            </div>
          </a>
          <a style={{ maxWidth: '30%' }} href={`/${commenter?.username}`}>
            <div className="truncate font-medium">
              {`/${commenter.username}`}
            </div>
          </a>
          <span style={{ color: '#9B9FA4', fontWeight: '500' }}>
            {`・${dayHepler?.fromNowDays(createdAt, true)}`}
          </span>

          {viewer.id === commenter.id && (
            <Menu>
              <MenuButton width="1.5rem">
                <SvgDeleteEditComment marginLeft="auto" />
              </MenuButton>
              <MenuList
                position="absolute"
                right="0"
                top="0"
                width="auto"
                zIndex={999999}
              >
                <MenuItem
                  onClick={() => {
                    setShowEditComment(true);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    await deleteComment({ commentId });
                    setHideComment(true);
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </div>

        {/* comment */}
        {showEditComment && (
          <Commenter
            ref={commenterRef}
            user={viewer}
            editorIndex={`edit-${commentId}`}
            commentContent={{ blocks: props.comment.content.blocks }}
            commentUploadedFiles={props.comment.content.uploadedFiles}
            onKeyDown={(event) => {
              onKeyDown(event, commentId);
              setShowEditComment(false);
            }}
          />
        )}
        {!showEditComment &&
          (content?.blocks ? (
            <Box
              fontSize={{ base: 'xs', md: '14px' }}
              lineHeight="18px"
              fontWeight="500"
              color="gray.700"
              mt={isMobile && '0px!important'}
              className="editor-render"
              wordBreak="break-all"
            >
              <EditorRenderer
                maxWidth="100%"
                blocks={content.blocks}
                blockMap={rendererBlockMaps}
              />
              <style jsx global>{`
                .editor-render p {
                  padding: 0px !important;
                }
                .editor-render > div > div {
                  padding-top: 0px !important;
                }
                .editor-render a[href] {
                  color: #0278e4;
                }
              `}</style>
            </Box>
          ) : (
            <Text fontSize="sm" fontWeight="bold" mb={1}>
              {content}
            </Text>
          ))}

        {content?.uploadedFiles?.length > 0 &&
          !showEditComment &&
          content?.uploadedFiles?.map((contentImages) => (
            <>
              <div
                role="button"
                aria-label="gallery view"
                tabIndex={0}
                style={{ height: '6rem', width: '8.7rem' }}
                className="comment-box m-2 cursor-pointer rounded-lg"
                onClick={() => {
                  setCurrAsset(content?.uploadedFiles);
                  galleryModal.onSelect(true);
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    setCurrAsset(content?.uploadedFiles);
                    galleryModal.onSelect(true);
                  }
                }}
              />
              <style jsx>
                {`
                  .comment-box {
                    background-image: url('${imageOptimize(
                      contentImages.imgURL
                    )}');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                  }
                `}
              </style>
            </>
          ))}

        {/* Enthuse and reply */}

        <Flex direction="column" hidden>
          {!showEditComment && (
            <Flex color="$mode.500" alignItems="center">
              {isEnthused ? (
                <SvgEnthused
                  className="text-gray-900"
                  fontSize={{ base: '14px', md: '20px' }}
                  cursor={viewer ? 'pointer' : 'default'}
                  onClick={viewer && onEnthuse}
                />
              ) : (
                <SvgEnthuseGray
                  fontSize={{ base: '14px', md: '20px' }}
                  cursor={viewer ? 'pointer' : 'default'}
                  onClick={viewer && onEnthuse}
                />
              )}
              <Text
                ml={1}
                mr={2}
                as="span"
                fontSize={{ base: 'xs', md: 'sm' }}
                fontWeight="500"
              >
                {enthuseCount} •
              </Text>

              {!hideReply && viewer && (
                <Text
                  as="span"
                  cursor="pointer"
                  color="$mode.500"
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontWeight="500"
                  onClick={() => {
                    setIsEditing(true);
                    setReplyCommenter({
                      id: commenter.id,
                      firstName: commenter.firstName,
                      lastName: commenter.lastName,
                      username: commenter.username,
                    });
                  }}
                >
                  Reply
                </Text>
              )}
              {hideReply && (
                <Text
                  as="span"
                  cursor="pointer"
                  color="$mode.500"
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontWeight="500"
                  onClick={() => setChildReply({ commenter, id: parentID })}
                >
                  Reply
                </Text>
              )}
            </Flex>
          )}

          {props.children}
          {!showEditComment && (
            <Commenter
              ref={commenterRef}
              editorIndex={
                isModal ? `modalsub${index}${id}` : `sub${index}${id}`
              }
              asSubcomment
              user={viewer}
              userReply={replyCommenter}
              commenter={commenter}
              onKeyDown={(e) => {
                onKeyDown(e);
                setReplyCommenter({});
              }}
              onReply={isEditing}
              stackStyle={{
                display: isEditing ? 'inline-flex' : 'none',
                paddingTop: '12px',
                paddingBottom: '0',
              }}
            />
          )}

          {galleryModal.selected && (
            <GalleryModal
              {...galleryModal.modal}
              assets={currAsset}
              data={currAsset}
            />
          )}
        </Flex>
      </Stack>
    </Stack>
  );
}
