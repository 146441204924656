export function formatNumber({
  locales = 'en-US',
  options = {},
  number = 0,
} = {}) {
  if (!Number.isInteger(number)) {
    return new Intl.NumberFormat(locales, options).format(0);
  }
  return new Intl.NumberFormat(locales, options).format(number);
}

export function kFormatter(num?: number) {
  if (!num) return 0;
  return num > 999
    ? `${(num / 1000).toFixed(1)}k`
    : formatNumber({ number: num });
}
