/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { repplStaticProps } from 'src/app/reppl/ssg';
import { staticPaths } from 'src/lib/ssg';

import SvgTimer from 'src/components/icons/timer';
import { Box, Flex } from '@chakra-ui/react';

import { NftImageContainer } from 'src/app/asset/detail';
import { useRouter } from 'next/router';
import { SharePopover } from 'src/components/share-buttons/share-popover';
import { checkAuthorName } from 'src/utils/checkAuthorName';
import { EnthuseButton } from 'src/app/article/components/enthuse-button';
import { useAgoraFeedEnthuseMutation } from 'src/app/article/hooks/use-enthuse';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';

import styles from './Creation.module.scss';
import CardCategoryTags from './card-category-tags';

function Creation(props) {
  const router = useRouter();
  const [isNft, setIsNft] = useState<boolean>(false);
  const [tokenData, setTokenData] = useState<any>();
  const [gridData, setGridData] = useState<any>();
  const [assetLink, setAssetLink] = useState<string>();

  const propsData = [
    props.nft,
    props.cover,
    props.title,
    props.excerpt,
    props.enthuseCount,
    props.author,
    props.isEnthused,
    props.categories,
    props.tags,
    props.contentID,
  ];

  function goToCreation() {
    const data = {
      username: props.author?.username || '',
      contentID: props.contentID,
    };
    props.modalOpener(data);
  }

  useEffect(() => {
    if (tokenData) {
      setGridData({ title: tokenData.name });
    }
  }, [tokenData]);

  useEffect(() => {
    if (props.nft) {
      setIsNft(true);
      setAssetLink(
        `${window.location.origin}/asset/${props.nft.token_address}:${props.nft.token_id}`
      );
    } else {
      setGridData({ ...props });
      setAssetLink(
        `${window.location.origin}/${props.author?.username}/${props.contentID}`
      );
    }

    if (!newData) {
      setNewData({
        enthuseCount: props['enthuseCount'],
        isEnthused: props['isEnthused'],
      });
    }
  }, propsData);

  const [newData, setNewData] = useState<any>();

  const { mutateAsync: enthuse } = useAgoraFeedEnthuseMutation(
    newData,
    setNewData
  );

  const getNFTimageURL = async (NFTfile) => {
    const newNFT = { ...NFTfile };
    if (NFTfile?.image) {
      if (NFTfile?.image?.startsWith('ipfs://')) {
        if (NFTfile?.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('ipfs://')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('ipfs://')[1]
          }`;
        }
      } else if (
        NFTfile?.image?.startsWith('https://gateway.pinata.cloud/ipfs/')
      ) {
        if (NFTfile?.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        }
      } else {
        newNFT.imgURL = NFTfile.image;
      }
    } else if (NFTfile?.image_url) {
      if (NFTfile?.image_url?.startsWith('ipfs://')) {
        if (NFTfile?.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('ipfs://')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('ipfs://')[1]
          }`;
        }
      } else if (
        NFTfile?.image_url?.startsWith('https://gateway.pinata.cloud/ipfs/')
      ) {
        if (NFTfile?.image?.endsWith('.mp4')) {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        } else {
          newNFT.imgURL = `https://ipfs.io/ipfs/${
            NFTfile.image_url.split('https://gateway.pinata.cloud/ipfs/')[1]
          }`;
        }
      } else {
        newNFT.imgURL = NFTfile.image_url;
      }
    }
    if (newNFT.imgURL?.endsWith('.mp4')) {
      const fetchMIME = await fetchMIMEType(newNFT.imgURL);
      newNFT.fileType = fetchMIME;
    }

    return newNFT;
  };

  const fetchMIMEType = (uri) => {
    const proxyUrl = 'https://corsa.1tm.io/';
    const tokenRes = fetch(`${proxyUrl}${uri}`).then((response) => {
      return response.headers.get('Content-Type');
    });
    return tokenRes;
  };

  const getTokenUri = (uri) => {
    const proxyUrl = 'https://corsa.1tm.io/';
    const tokenRes = fetch(`${proxyUrl}${uri}`)
      .then((res) => res.json())
      .catch((err) => console.log(err));
    return tokenRes || null;
  };

  useEffect(() => {
    async function getNFTImage() {
      if (!props.nft.metadata) {
        let nftData;
        let fromURI;
        if (props.nft?.token_uri?.includes('data:application/json;utf8')) {
          fromURI = JSON.parse(
            props.nft.token_uri.split('data:application/json;utf8,')[1]
          );
          nftData = await getNFTimageURL(fromURI);
        } else {
          fromURI = await getTokenUri(props.nft.token_uri);
          nftData = await getNFTimageURL(fromURI);
        }
        setTokenData(nftData);
      } else {
        const nftData = await getNFTimageURL(JSON.parse(props.nft.metadata));
        setTokenData(nftData);
      }
    }
    if (props.nft) {
      getNFTImage();
    }
  }, [props.nft]);

  return (
    <div className={`creation ${cn(styles.block8, styles.block8_layout)}`}>
      <div
        className={cn(styles.block9, styles.block9_layout)}
        role="button"
        tabIndex={0}
        onClick={() => {
          if (isNft) {
            router.push(
              `/asset/${props.nft.token_address}:${props.nft.token_id}`
            );
          } else {
            goToCreation();
          }
        }}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            if (isNft) {
              router.push(
                `/asset/${props.nft.token_address}:${props.nft.token_id}`
              );
            } else {
              goToCreation();
            }
          }
        }}
      >
        <div
          className={cn(styles.flex1, styles.flex1_layout)}
          style={{
            margin: props.menu ? '15px 0 15px 15px' : '15px 16px 15px 15px',
          }}
        >
          {!isNft && (
            <>
              {props.author && (
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex alignItems="center" w={props.menu ? '90%' : '100%'}>
                    <Box mr="8px" w="30px" h="30px">
                      <div
                        style={{
                          backgroundImage: `url(${
                            props.author.profileImgURL ||
                            (props.author.username &&
                              `/api/avatar?name=${props.author.username}`)
                          })`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                        }}
                        className={cn(
                          styles.icon,
                          styles.icon_layout,
                          'rounded-full'
                        )}
                      >
                        {!props.author.profileImgURL && props.author.username && (
                          <div
                            style={{
                              height: 'fit-content',
                              fontSize: '12px',
                            }}
                            className={`${cn(
                              styles.text_ellipsis
                            )} absolute m-auto right-0 left-0 top-0 bottom-0 w-max text-white font-bold`}
                          >
                            {checkAuthorName(props.author)}
                          </div>
                        )}
                      </div>
                    </Box>
                    <Box
                      className={cn(
                        styles.text_body1,
                        styles.text_body1_layout,
                        styles.text_ellipsis
                      )}
                    >
                      {getProfileFullNameOrUsername(props.author)}
                    </Box>
                  </Flex>
                  {props.menu && (
                    <Flex
                      h="full"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <div className={cn(styles.postgrid_nftprice_item2)}>
                        <div
                          style={{
                            backgroundImage: `url(${
                              require('public/assets_pxcode/ef9cce8de8c98f5648048480f1a92402.svg')
                                .default
                            })`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                          }}
                          className={cn(
                            styles.postgrid_more,
                            styles.postgrid_more_layout
                          )}
                        />
                      </div>
                    </Flex>
                  )}
                </Flex>
              )}
            </>
          )}

          <div className={cn(styles.flex1_item1)}>
            <div
              className={cn(
                styles.postgrid_authors,
                styles.postgrid_authors_layout
              )}
            >
              {/* show/hide to switch variants */}
              <img
                src={
                  require('public/assets_pxcode/499709956541ed4b8da4ac75cab70cf9.svg')
                    .default
                }
                alt="Size / sm_40x40 (has background)"
                className={cn(styles.block3, styles.block3_layout)}
              />
              <div
                style={{
                  backgroundImage: `url(${require('public/assets_pxcode/549a3f86841c43194a27b35dd5264d4b.png')})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
                className={cn(styles.icon, styles.icon_layout1)}
              />
              <div
                style={{
                  backgroundImage: `url(${require('public/assets_pxcode/da6c07ec4f9c33e72096b96e8b562f6d.png')})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
                className={cn(styles.icon, styles.icon_layout2)}
              />
              <div
                style={{
                  backgroundImage: `url(${require('public/assets_pxcode/b50b554b113f83e4617066804e6ad13c.png')})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
                className={cn(styles.icon, styles.icon_layout3)}
              />
            </div>
          </div>
          <div className={cn(styles.flex1_spacer)} />
          {isNft && gridData?.timer && (
            <div className={cn(styles.flex1_item)}>
              <div className={cn(styles.postgrid_nftprice)}>
                <div
                  style={{
                    width: '128px',
                    height: '32px',
                    borderRadius: '20px',
                  }}
                  className="border-gray-300 border relative flex"
                >
                  <div className="mx-auto flex">
                    <SvgTimer height="20px" width="20px" className="my-auto" />
                    <div className="text-gray-700 text-sm font-medium my-auto ml-1">
                      3 hours left
                    </div>
                  </div>
                </div>

                {/* <div className={cn(styles.postgrid_nftprice_item)}>
                <div
                  style={{
                    backgroundImage: `url(${
                      require('public/assets_pxcode/b6101220f13f3a97baa3ce9487e6bb45.svg')
                        .default
                    })`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                  className={cn(styles.image55, styles.image55_layout)}
                />
              </div>
              <div className={cn(styles.postgrid_nftprice_spacer)} />
              <div className={cn(styles.postgrid_nftprice_item1)}>
                <div
                  className={cn(
                    styles.postgrid_nftprice1,
                    styles.postgrid_nftprice1_layout
                  )}
                >
                  {props.postgrid_nftprice}
                </div>
              </div> */}

                <div className={cn(styles.postgrid_nftprice_spacer1)} />
              </div>
            </div>
          )}
        </div>
      </div>
      {props.menu && (
        <div className="absolute right-0 top-2 z-10 ">{props.menu}</div>
      )}
      <div
        style={{ minHeight: '100px' }}
        role="button"
        tabIndex={0}
        onClick={() => {
          if (isNft) {
            router.push(
              `/asset/${props.nft.token_address}:${props.nft.token_id}`
            );
          } else {
            goToCreation();
          }
        }}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            if (isNft) {
              router.push(
                `/asset/${props.nft.token_address}:${props.nft.token_id}`
              );
            } else {
              goToCreation();
            }
          }
        }}
      >
        {!tokenData ? (
          <img
            style={{ objectFit: 'cover' }}
            src={gridData ? gridData?.cover : props.cover}
            alt=""
            className={cn(styles.postgrid_cover, styles.postgrid_cover_layout)}
          />
        ) : (
          <div
            className={cn(styles.postgrid_cover, styles.postgrid_cover_layout)}
          >
            <NftImageContainer image={tokenData} ratio="1/1" />
          </div>
        )}
      </div>
      <div
        className={cn(
          styles.postgrid_container,
          styles.postgrid_container_layout
        )}
      >
        <div className={cn(styles.flex2, styles.flex2_layout)}>
          {isNft ? (
            <div
              className="flex"
              role="button"
              tabIndex={0}
              onClick={() => {
                if (isNft) {
                  router.push(
                    `/asset/${props.nft.token_address}:${props.nft.token_id}`
                  );
                } else {
                  goToCreation();
                }
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  if (isNft) {
                    router.push(
                      `/asset/${props.nft.token_address}:${props.nft.token_id}`
                    );
                  } else {
                    goToCreation();
                  }
                }
              }}
            >
              <div>
                <div className={cn(styles.flex2_item)}>
                  <h3
                    className={cn(
                      styles.postgrid_title_box,
                      styles.postgrid_title_box_layout
                    )}
                  >
                    <div
                      style={{ height: '64px', display: 'block' }}
                      className={cn(styles.postgrid_title)}
                    >
                      {gridData?.title}
                    </div>
                  </h3>
                </div>
                <div className={cn(styles.flex2_item1)}>
                  <div className={cn(styles.block5, styles.block5_layout)}>
                    {gridData?.tags && (
                      <div className={cn(styles.block5_item)}>
                        <div className={cn(styles.flex6, styles.flex6_layout)}>
                          <div className={cn(styles.flex6_item)}>
                            <div
                              className={cn(
                                styles.postgrid_spacetag,
                                styles.postgrid_spacetag_layout
                              )}
                            >
                              {gridData?.tag}
                            </div>
                          </div>
                          <div className={cn(styles.flex6_item1)}>
                            <div
                              style={{
                                backgroundImage: `url(${
                                  require('public/assets_pxcode/aeb1826314e81fa5c6db6eb2c2079fe0.png')
                                    .default
                                })`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                              }}
                              className={cn(
                                styles.image5,
                                styles.image5_layout
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {gridData?.price && (
                <div
                  style={{ width: '90px', height: '78px' }}
                  className="border border-gray-300 rounded-lg relative"
                >
                  <div
                    style={{ height: 'fit-content' }}
                    className="absolute m-auto inset-0"
                  >
                    <div className="flex">
                      <div
                        style={{
                          backgroundImage: `url(${
                            require('public/assets_pxcode/b6101220f13f3a97baa3ce9487e6bb45.svg')
                              .default
                          })`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          height: '20px',
                          width: '20px',
                        }}
                        className="my-auto ml-auto"
                      />
                      <div className="text-gray-900 text-xl font-medium mr-auto">
                        {gridData?.price}
                      </div>
                    </div>
                    <div className="text-gray-500 font-bold text-xs text-center">
                      Top Bid
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                if (isNft) {
                  router.push(
                    `/asset/${props.nft.token_address}:${props.nft.token_id}`
                  );
                } else {
                  goToCreation();
                }
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  if (isNft) {
                    router.push(
                      `/asset/${props.nft.token_address}:${props.nft.token_id}`
                    );
                  } else {
                    goToCreation();
                  }
                }
              }}
            >
              <div className={cn(styles.flex2_item)}>
                <div
                  style={{ paddingTop: '22px' }}
                  className={cn(styles.flex2_item)}
                >
                  <div
                    style={{ minHeight: '28px', maxHeight: '28px' }}
                    className="text-xl font-medium text-gray-900 line-clamp-1"
                  >
                    {gridData?.title}
                  </div>
                  <div
                    style={{ minHeight: '44px', maxHeight: '44px' }}
                    className="pt-1 text-gray-500 font-medium text-sm line-clamp-2"
                  >
                    {gridData?.excerpt}
                  </div>
                </div>
              </div>
              {/* category */}
              {gridData?.categories && (
                <CardCategoryTags categories={gridData.categories} />
              )}
            </div>
          )}
          <div className={cn(styles.flex2_item)}>
            <div className={cn(styles.flex61, styles.flex61_layout)}>
              {/* <div className={cn(styles.flex6_item)}>
                <div className={cn(styles.flex5, styles.flex5_layout)}>
                  <div className={cn(styles.flex5_item)}>
                    <div
                      style={{
                        backgroundImage: `url(${
                          require('public/assets_pxcode/356db3911399d0c302487bdf4a375203.svg')
                            .default
                        })`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                      className={cn(styles.enthuse, styles.enthuse_layout)}
                    />
                  </div>
                  <div className={cn(styles.flex5_spacer)} />
                  <div
                    className={cn(
                      styles.postgrid_enthusednum,
                      styles.postgrid_enthusednum_layout
                    )}
                  >
                    {gridData?.enthuseCount}
                  </div>
                </div>
              </div> */}
              <div className="z-10">
                {gridData && (
                  <EnthuseButton
                    width="20px"
                    height="20px"
                    minWidth="0"
                    isEnthuse={newData['isEnthused']}
                    likesCount={newData['enthuseCount']}
                    textClass="ml-1"
                    onEnthuse={() => {
                      enthuse({
                        enthuse: !newData['isEnthused'],
                        objectId: gridData['contentID'],
                        objectType: 'EPISODE',
                        ownerID: gridData['author']?.['userID'],
                      });
                    }}
                  />
                )}
              </div>
              <div className={cn(styles.flex6_spacer)} />
              <div className={cn(styles.flex6_item)}>
                <div className={cn(styles.block37, styles.block37_layout)}>
                  <div className={cn(styles.block37_item)}>
                    <div
                      aria-label="redirect"
                      style={{
                        backgroundImage: `url(${
                          require('public/assets_pxcode/13a52f30b69bf4eaf23c66de8b41e286.svg')
                            .default
                        })`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                      className={cn(
                        styles.postgrid_comment,
                        styles.postgrid_comment_layout
                      )}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        if (isNft) {
                          router.push(
                            `/asset/${props.nft.token_address}:${props.nft.token_id}#comments`
                          );
                        } else {
                          goToCreation();
                          window.history.pushState('comments', '', '#comments');
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          if (isNft) {
                            router.push(
                              `/asset/${props.nft.token_address}:${props.nft.token_id}#comments`
                            );
                          } else {
                            goToCreation();
                            window.history.pushState(
                              'comments',
                              '',
                              '#comments'
                            );
                          }
                        }
                      }}
                    />
                  </div>
                  <div className={cn(styles.block37_spacer)} />
                  <SharePopover
                    heading={`Share this ${props.nft ? 'Asset' : 'Post'}`}
                    url={`${assetLink}`}
                    shareButton={
                      <div className={cn(styles.block37_item)}>
                        <div
                          style={{
                            backgroundImage: `url(${
                              require('public/assets_pxcode/a470ce147e30b47926a0152a250eb3d4.svg')
                                .default
                            })`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                          }}
                          className={cn(
                            styles.postgrid_share,
                            styles.postgrid_share_layout
                          )}
                        />
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Creation.inStorybook = true;

export const getStaticProps = repplStaticProps;
export const getStaticPaths = staticPaths;

export default Creation;
