import * as React from 'react';
import { Svg } from '../svg';

function SvgLooksrare({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#looksrare_svg__clip0_6588_79593)">
        <path
          d="M7.87 3.647L.668 10.773l12 11.875 12-11.87-7.204-7.13H7.871z"
          fill="#2DE370"
        />
        <path
          d="M7.535 8.275a7.28 7.28 0 0110.264 0l2.45 2.436-2.45 2.436a7.28 7.28 0 01-10.264 0l-2.45-2.436 2.45-2.436z"
          fill="#121619"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.666 13.698c-1.656 0-2.999-1.303-2.999-2.906 0-1.604 1.343-2.907 2.999-2.907 1.655 0 2.999 1.303 2.999 2.907 0 1.603-1.339 2.906-3 2.906z"
          fill="#fff"
        />
        <path
          d="M12.667 11.967a1.262 1.262 0 01-1.263-1.255 1.26 1.26 0 011.263-1.256c.696 0 1.263.564 1.263 1.256 0 .696-.563 1.255-1.263 1.255z"
          fill="#121619"
        />
      </g>
      <defs>
        <clipPath id="looksrare_svg__clip0_6588_79593">
          <path
            fill="#fff"
            transform="translate(.667 .553)"
            d="M0 0h24v24H0z"
          />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgLooksrare);
export default ForwardRef;
