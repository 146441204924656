import * as React from 'react';
import { Svg } from '../svg';

function Svg1TmAlpha({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 109 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M9.968 1.2v19.6H3.36V6.184H0V1.2h9.968zM18.815 6.324h-5.74V1.2h18.088v5.124h-5.74V20.8h-6.608V6.324zM51.78 20.8l-.057-8.932-4.256 7.168h-2.912l-4.256-6.86V20.8h-6.02V1.2h5.432l6.384 10.444L52.311 1.2h5.432l.056 19.6h-6.02zM73.471 8.932h-4.9l-.966 2.268H65.73l4.41-9.8h1.792l4.424 9.8h-1.904l-.98-2.268zm-.602-1.428L71.021 3.22l-1.834 4.284h3.682zM77.434.812h1.75V11.2h-1.75V.812zm8.19 2.828c.729 0 1.377.159 1.947.476a3.42 3.42 0 011.358 1.344c.326.579.49 1.246.49 2.002 0 .756-.164 1.428-.49 2.016a3.419 3.419 0 01-1.359 1.344c-.569.318-1.217.476-1.945.476-1.008 0-1.806-.336-2.394-1.008v3.626h-1.75V3.724h1.666v.98c.289-.354.643-.62 1.063-.798.43-.177.901-.266 1.415-.266zM85.43 9.8c.644 0 1.17-.214 1.581-.644.42-.43.63-.994.63-1.694s-.21-1.264-.63-1.694c-.41-.43-.938-.644-1.582-.644-.42 0-.797.098-1.133.294a2.026 2.026 0 00-.799.812c-.195.355-.293.766-.293 1.232 0 .467.097.878.293 1.232.197.355.463.63.799.826.335.187.713.28 1.133.28zm9.891-6.16c.943 0 1.699.276 2.268.826.57.55.854 1.368.854 2.45V11.2h-1.75V7.14c0-.653-.154-1.143-.462-1.47-.308-.336-.746-.504-1.316-.504-.644 0-1.153.196-1.526.588-.373.383-.56.938-.56 1.666v3.78h-1.75V.812h1.75v3.78c.29-.308.644-.541 1.064-.7.43-.168.906-.252 1.428-.252zm8.198 0c1.101 0 1.941.266 2.52.798.588.523.882 1.316.882 2.38V11.2h-1.652v-.91a2.077 2.077 0 01-.924.756c-.392.168-.868.252-1.428.252-.56 0-1.05-.093-1.47-.28a2.39 2.39 0 01-.98-.798 2.09 2.09 0 01-.336-1.162c0-.672.247-1.208.742-1.61.504-.41 1.293-.616 2.366-.616h1.932V6.72c0-.522-.159-.924-.476-1.204-.308-.28-.77-.42-1.386-.42-.42 0-.835.066-1.246.196-.401.13-.742.313-1.022.546l-.686-1.274a4.245 4.245 0 011.414-.686 6.293 6.293 0 011.75-.238zm-.238 6.384c.439 0 .826-.098 1.162-.294.345-.205.588-.494.728-.868v-.868h-1.806c-1.008 0-1.512.332-1.512.994 0 .318.126.57.378.756.252.187.602.28 1.05.28z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(Svg1TmAlpha);
export default ForwardRef;
