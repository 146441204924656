function setSearchTerm(state, searchTerm) {
  if (searchTerm === state.searchTerm) {
    return state;
  }

  return {
    ...state,
    searchTerm,
  };
}

export default setSearchTerm;
