import { Modal, ModalContent } from '@chakra-ui/react';
import questionIMG from 'src/app/asset/images/question-icon.png';
import styles from './mint-explainer-modal.module.scss';

export default function AfterMintingModal({ closeModal, isOpen }) {
  return (
    <Modal isOpen={isOpen} onClose={closeModal} allowPinchZoom>
      <ModalContent
        maxW="100vw"
        w="100vw"
        h="100vh"
        borderRadius="none"
        background="none"
      >
        <div className={styles.fadein}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,  jsx-a11y/click-events-have-key-events */}
          <div className={styles.overlay} onClick={closeModal}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,  jsx-a11y/click-events-have-key-events */}
            <div
              className={styles.content}
              onClick={(e) => e.stopPropagation()}
            >
              <button
                type="button"
                className={styles.close_button}
                onClick={closeModal}
              >
                <div className={styles.x1} />
                <div className={styles.x2} />
              </button>
              <h2>After minting</h2>
              <div className={styles.section_container}>
                <img src={questionIMG} alt="" />
                <div className={styles.text_wrapper}>
                  <h3>Where can I find the minted NFTs?</h3>
                  <p>
                    Minting an NFT refers to converting digital files into
                    crypto collections or digital assets stored on the
                    blockchain. The digital items or files will be stored in a
                    decentralized database or distributed ledger and cannot be
                    edited, modified, or deleted.
                  </p>
                </div>
              </div>
              <div className={styles.section_container}>
                <img src={questionIMG} alt="" />
                <div className={styles.text_wrapper}>
                  <h3>What can I do with minted NFTs?</h3>
                  <p>
                    Minting an NFT, or non-fungible token, is publishing a
                    unique digital asset on a blockchain so that it can be
                    bought, sold, and traded.
                  </p>
                </div>
              </div>
              <div className={styles.button_container}>
                <button
                  onClick={closeModal}
                  type="button"
                  className={styles.button}
                >
                  Got it
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
