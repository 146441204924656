import * as React from 'react';
import { Svg } from '../svg';

function SvgDeviantart({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 15 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M14.455 0h-4.131l-.441.438L7.781 4.45l-.613.354H.05v5.995h3.803l.396.396L.05 19.21V24l4.134-.001.443-.44 2.106-4.013.6-.347h7.122v-5.993h-3.814l-.386-.386 4.2-8.019"
        fill="#00E59B"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgDeviantart);
export default ForwardRef;
