/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import { cloneElement, ElementType } from 'react';

export interface ConditionalWrapProps {
  condition: boolean;
  wrap: ElementType<any>;
  children: JSX.Element;
}
export const ConditionalWrap = ({
  condition,
  children,
  wrap,
}: ConditionalWrapProps) =>
  // @ts-ignore
  condition ? cloneElement(wrap(children)) : children;
