import { toRem } from '../../tools';

const parts = ['track', 'thumb'];

function baseStyleTrack(props) {
  const { colorScheme: c } = props;

  return {
    borderRadius: 'full',
    padding: '2px',
    transition: 'all 150ms',
    bg: 'gray.400',

    _focus: { boxShadow: 'outline' },
    _disabled: { opacity: 0.4, cursor: 'not-allowed' },
    _checked: {
      bg: `${c}.500`,
    },
  };
}

const baseStyleThumb = {
  bg: 'white',
  transition: 'transform 300ms',
  borderRadius: 'full',
  transform: 'translateX(0)',
};

const baseStyle = (props) => ({
  track: baseStyleTrack(props),
  thumb: baseStyleThumb,
});

const sizes = {
  sm: {
    track: { padding: toRem(1), width: toRem(30), height: toRem(14) },
    thumb: {
      width: toRem(14),
      height: toRem(14),

      _checked: {
        transform: 'translateX(1rem)',
      },
    },
  },

  md: {
    track: { padding: toRem(2), width: toRem(40), height: toRem(20) },
    thumb: {
      width: toRem(20),
      height: toRem(20),

      _checked: {
        transform: `translateX(${toRem(20)})`,
      },
    },
  },

  lg: {
    track: { padding: toRem(2), width: toRem(48), height: toRem(24) },
    thumb: {
      width: toRem(24),
      height: toRem(24),

      _checked: {
        transform: `translateX(${toRem(24)})`,
      },
    },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'blue',
};

export default {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
