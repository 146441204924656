/**
 * External dependencies
 */
import { useCallback, useEffect } from 'react';
import Router from 'next/router';
import debounce from 'lodash.debounce';

/**
 * Internal dependencies
 */
import { SearchReducer } from './types';
import { INITIAL_STATE } from './constants';

import Context from './context';
import useFilterReducer from './filter/useFilterReducer';
import useSearchReducer from './useSearchReducer';
import useSort from './useSort';
import useSuggestionsReducer from './suggestion/useSuggestionsReducer';

function SearchProvider({ children }: { children: React.ReactNode }) {
  const {
    state: searchState,
    actions: searchActions,
    searchInputRef,
  }: SearchReducer = useSearchReducer();

  const { state: filterState, actions: filterActions } = useFilterReducer(
    searchState
  );

  const { sortType, sortResult, sortTotalResult, sortOptions } = useSort({
    searchState,
    filterState,
  });
  const {
    state: suggestionsState,
    onSearchSuggestions,
    onClearSearchLite,
    onSaveSearchTerm,
    suggestionInputRef,
  } = useSuggestionsReducer();

  const { setGenre, search, clear, onSearchChange } = searchActions;
  const {
    handleFilter,
    updateFilterResult,
    onFilterChange,
    resetFilter,
  } = filterActions;

  const onGenreChange = useCallback(
    (genre) => {
      setGenre(genre);
      handleFilter({ type: 'ALL', value: {} });
      updateFilterResult({ result: searchState.result, genre });
    },
    [searchState.result, setGenre, handleFilter, updateFilterResult]
  );

  const onClearSearch = useCallback(() => {
    clear();
    resetFilter();
  }, [clear, resetFilter]);

  const onSearch = useCallback(
    ({ more = false } = {}) => {
      debounce(() => {
        if (searchState.isFetching) return;
        const inputValue = onSearchChange();

        if (inputValue.match(/^\s*$/g)) return onClearSearch();
        search(inputValue, more, ({ result }) =>
          updateFilterResult({
            result,
            genre: searchState.genre,
          })
        );
      }, 300)();
    },
    [
      searchState.isFetching,
      searchState.genre,
      onSearchChange,
      onClearSearch,
      search,
      updateFilterResult,
    ]
  );

  useEffect(() => {
    const handleRouteChange = () => {
      onGenreChange(INITIAL_STATE.genre);
    };
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [onGenreChange]);

  const goBackToSearchPage = useCallback(
    (e) => {
      window.location.replace(
        `/search/${e.state?.searchTerm || searchState.searchTerm}`
      );
      e.preventDefault();
    },
    [searchState.searchTerm]
  );

  useEffect(() => {
    window.removeEventListener('popstate', goBackToSearchPage, true);
    return () => {
      window.addEventListener('popstate', goBackToSearchPage, true);
    };
  }, [goBackToSearchPage]);

  const state = {
    search: {
      ...searchState,
      onClearSearch,
      onGenreChange,
      onSearch,
    },

    filter: {
      ...filterState,
      onFilterChange,
    },

    sort: {
      type: sortType,
      result: sortResult,
      totalResult: sortTotalResult,
    },

    suggestions: {
      ...suggestionsState,
      onSearchSuggestions,
      onClearSearchLite,
    },

    sortOptions,

    searchInputRef,
    suggestionInputRef,

    onSaveSearchTerm,
  };
  return <Context.Provider value={state}>{children}</Context.Provider>;
}

export default SearchProvider;
