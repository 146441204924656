import type EditorJS from '@editorjs/editorjs';
import type { EditorConfig } from '@editorjs/editorjs/types';
import { useEffect, useRef, useState } from 'react';
import { ContentEditableHandler } from 'src/components/content-editable';
import { isBrowser } from 'src/constants/env';
import { importAllEditorTools } from 'src/lib/editor/editor-tool';
import { logger } from 'src/lib/logger';
import {
  ImgUploadAreaType,
  ImgUploadType,
  postUploadImage,
} from 'src/service/image';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

function uploadImage(
  file: File,
  uniqueId: string,
  areaType?: ImgUploadAreaType,
  imgType?: ImgUploadType
) {
  const formData = new FormData();

  formData.append('file', file);

  return postUploadImage({
    data: formData,
    id: uniqueId,
    areaType,
    imgType,
  });
}

type UseEditorProps = {
  editorId?: string;
  allow?: string[];
  uniqueId: string;
  editorOnChange?: () => void;
  imageUpLoader?: (
    file: File
  ) => Promise<{ success: number; file: { url: string } }>;
} & Omit<EditorConfig, 'readOnly'> &
  (
    | {
        readOnly?: boolean;
        imgType?: ImgUploadType;
        areaType?: ImgUploadAreaType;
      }
    | {
        readOnly: true;
        imgType?: ImgUploadType;
        areaType?: ImgUploadAreaType;
      }
  );

declare global {
  interface Window {
    // for better debugging
    __editor?: EditorJS;
  }
}

function whiteListDefaultEditorConfigTool(
  config: EditorConfig,
  allow?: string[],
  router?: any
) {
  if (!allow) {
    if (router.pathname === '/feed/[tribe]') {
      // eslint-disable-next-line no-param-reassign
      delete config.tools.code;

      // eslint-disable-next-line no-param-reassign
      delete config.tools.inlineCode;

      // eslint-disable-next-line no-param-reassign
      delete config.tools.table;

      // eslint-disable-next-line no-param-reassign
      delete config.tools.alert;

      // eslint-disable-next-line no-param-reassign
      delete config.tools.anyButton;

      // eslint-disable-next-line no-param-reassign
      delete config.tools.marker;

      // eslint-disable-next-line no-param-reassign
      delete config.tools.checklist;

      // eslint-disable-next-line no-param-reassign
      delete config.tools.quote;

      // eslint-disable-next-line no-param-reassign
      delete config.tools.list;
    }
    if (router.pathname === '/space/[rid]/topic/[tid]') {
      // eslint-disable-next-line no-param-reassign
      delete config.tools.code;
    }
    return config;
  }
  const whiteListConfig: EditorConfig = {
    ...config,
    tools: {},
  };

  // eslint-disable-next-line no-restricted-syntax
  for (const tool of allow) {
    const toolName = tool;
    whiteListConfig.tools[toolName] = config.tools[toolName];
  }

  return whiteListConfig;
}

const EDITOR_ID = 'editor';
export function useEditor({
  editorId,
  uniqueId,
  readOnly,
  allow,
  areaType,
  imgType,
  editorOnChange,
  ...editorConfig
}: UseEditorProps) {
  const router = useRouter();
  const titleRef = useRef<ContentEditableHandler>(null);
  const editorRef = useRef<EditorJS>(null);
  const [isReady, setIsReady] = useState(false);
  const browser = isBrowser();

  const { t } = useTranslation();
  const write = t('Write something...');

  useEffect(() => {
    if (!browser) {
      return;
    }
    importAllEditorTools().then((tools) => {
      if (!tools) {
        return;
      }
      const DEFAULT_EDITOR_CONFIG: EditorConfig = {
        placeholder: write,
        i18n: {
          messages: {
            tools: {
              anyButton: {
                'Button Text': 'Max. 50 characters will be shown in the button',
                'Link Url': 'URL here',
              },
            },
          },
        },
        tools: {
          mention: {
            class: tools.Mention,
          },
          header: {
            class: tools.Header,
            config: {
              placeholder: 'Headline',
              levels: [1, 2, 3, 4, 5, 6],
              defaultLevel: 3,
            },
            inlineToolbar: true,
          },
          paragraph: {
            class: tools.Paragraph,
            config: {
              preserveBlank: true,
            },
            inlineToolbar: true, // allow ? ['mention', 'link'] : true,
          },
          list: {
            class: tools.List,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered',
            },
          },
          // image: tools.SimpleImage,
          // gallery: {
          //   class: tools.Gallery,
          //   config: {
          //     unsplash: {
          //       appName: '1tm-demo',
          //       clientId: NEXT_PUBLIC_UNSPLASH_ACCESS_KEY,
          //     },
          //     buttonContent: '🖼 Select an image',
          //     uploader: {
          //       uploadByFile(file) {
          //         return uploadImage(file, uniqueId, areaType, imgType)
          //           .then((response) => {
          //             if (response.code === 1000) {
          //               return {
          //                 success: 1,
          //                 file: {
          //                   url: response.imgURL,
          //                 },
          //               };
          //             }
          //           })
          //           .catch((err) => {
          //             logger.error(err);
          //           });
          //       },
          //     },
          //   },
          // },
          image: {
            class: tools.Image,
            config: {
              buttonContent: '🖼 Select an image',
              uploader: {
                uploadByFile(file) {
                  return uploadImage(file, uniqueId, areaType, imgType)
                    .then((response) => {
                      if (response.code === 1000) {
                        return {
                          success: 1,
                          file: {
                            url: response.imgURL,
                          },
                        };
                      }
                    })
                    .catch((err) => {
                      logger.error(err);
                    });
                },
              },
            },
          },
          code: tools.CodeTool,
          underline: {
            class: tools.UnderLine,
            shortcut: 'CMD+U',
            inlineToolbar: true,
          },
          marker: {
            class: tools.Marker,
            inlineToolbar: true,
          },
          quote: {
            class: tools.Quote,
            inlineToolbar: true,
          },
          inlineCode: {
            class: tools.InlineCode,
          },
          delimiter: tools.Delimiter,
          // table: {
          //   class: tools.Table,
          //   inlineToolbar: true,
          //   config: {
          //     rows: 2,
          //     cols: 3,
          //   },
          // },
          alert: {
            class: tools.Alert,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+W',
            config: {
              defaultType: 'primary',
              messagePlaceholder: 'Enter something',
            },
          },
          checklist: {
            class: tools.Checklist,
            inlineToolbar: true,
          },
        },
      };

      (async () => {
        if (!isReady && !editorRef.current && uniqueId) {
          const editor = new tools.EditorJS({
            onReady: () => {
              const undo = new tools.Undo({
                editor,
              });
              if (editorConfig.data) undo.initialize(editorConfig.data);
            },
            onChange: () => {
              if (editorOnChange) {
                editorOnChange();
              }
            },
            ...whiteListDefaultEditorConfigTool(
              DEFAULT_EDITOR_CONFIG,
              allow,
              router
            ),
            holder: editorId || EDITOR_ID,
            ...editorConfig,
            readOnly,
          });
          editorRef.current = editor;
          // eslint-disable-next-line no-underscore-dangle
          window.__editor = editor;
          try {
            if (editorRef.current.isReady instanceof Promise) {
              await editorRef.current.isReady;
              setIsReady(true);
            }
          } catch (err) {
            logger.error(err);
          }
        }
      })();
    });

    return () => {
      if (editorRef.current && isReady) {
        try {
          editorRef.current.destroy?.();
          editorRef.current = null;
          // eslint-disable-next-line no-underscore-dangle
          window.__editor = null;
          setIsReady(false);
        } catch (err) {
          logger.error(err);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueId, isReady, readOnly]);

  return {
    titleRef,
    editor: editorRef.current,
    isReady,
  };
}
