import { UseDisclosureReturn, Tooltip } from '@chakra-ui/react';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import SvgInfo3 from 'src/components/icons/info3';
import {
  iconURLMap,
  NCTree,
  CCTree,
  NCLicenseMap,
  CCLicenseMap,
} from './nc-cc-data';
import styles from './styles.module.scss';

export default function LicenseChooser(props: {
  modal: UseDisclosureReturn;
  licenseType: 'CC' | 'NC'; // choose license type: CC or NC
  setState?: (value: string) => void; // set state of parent with result
}) {
  const [recommendation, setRecommendation] = useState('NC FC-');
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({ 1: true });
  const tree = props.licenseType === 'NC' ? NCTree : CCTree;
  const licenseMap = props.licenseType === 'NC' ? NCLicenseMap : CCLicenseMap;
  const [canSave, setCanSave] = useState(false);

  function next() {
    if (!getProperty('true', step + 1)) return;
    setStep(step + 1);
  }

  function back() {
    if (step === 1) return;
    setStep(step - 1);
  }

  function handleChange(e) {
    const newObj = { ...formData };
    // convert input value to boolean and set state
    if (e.target.value === 'true') {
      newObj[e.target.name] = true;
    } else if (e.target.value === 'false') {
      newObj[e.target.name] = false;
    } else {
      newObj[e.target.name] = e.target.value;
    }
    setFormData(newObj);
  }

  function getProperty(property, evaluatedStep = 0) {
    // traverses the tree to find a property based on property key and form step
    let currentNode = tree;
    for (let j = 0; j < evaluatedStep; j++) {
      currentNode = currentNode[formData[j + 1]] || currentNode;
    }
    return currentNode[property];
  }

  function getIcons(iconArr: any) {
    if (!iconArr) return;
    return iconArr.map((icon) => <img src={iconURLMap[icon]} alt="icon" />);
  }

  useEffect(() => {
    const newObj = { ...formData };
    delete newObj[step + 1]; // delete form data when going back
    setFormData(newObj);
    newObj[step] = true;
    setFormData(newObj);
  }, [step]);

  useEffect(() => {
    setRecommendation(getProperty('result', step));
    if (!getProperty('true', step + 1)) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [formData]);

  return (
    <>
      <div className={cn(styles.main_container)}>
        <div className={cn(styles.modal)}>
          <div className={cn(styles.modal_content)}>
            <div className={cn(styles.top_section)}>
              <h2>License Chooser</h2>
              <p>
                Follow the steps to select the appropriate license you are
                granting to token holders.
              </p>
            </div>
            <div className={cn(styles.bottom_section)}>
              <div className={cn(styles.left_side)}>
                {Steps(handleChange, step, formData, getProperty, tree)}
                <div className={cn(styles.buttons_container)}>
                  <button
                    type="button"
                    onClick={back}
                    className={step === 1 && cn(styles.disabled)}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={next}
                    className={
                      !getProperty('true', step + 1) && cn(styles.disabled)
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
              <div className={cn(styles.right_side)}>
                <div className={cn(styles.recommendation_card)}>
                  <h3>Recommended License</h3>
                  <div className={cn(styles.icons_container)}>
                    {getIcons(licenseMap[recommendation]?.icons)}
                    <span>
                      {recommendation}
                      {props.licenseType === 'NC' &&
                        recommendation !== 'NC FC-' && // Exception: Removes version number from NC FC-
                        ' 1.0'}
                      {props.licenseType === 'CC' && ' 4.0'}
                    </span>
                  </div>
                  <span className={cn(styles.full_name)}>
                    {licenseMap[recommendation]?.fullName}
                  </span>
                  <p>{licenseMap[recommendation]?.description}</p>
                </div>
              </div>
            </div>
            <div className={styles.nav_bar}>
              <button
                className={styles.cancel_button}
                type="button"
                onClick={() => props.modal.onClose()}
              >
                Cancel
              </button>
              <button
                className={
                  canSave ? styles.save_button : styles.save_button_disabled
                }
                type="button"
                onClick={() => {
                  props.setState(
                    `${recommendation} ${
                      props.licenseType === 'NC' ? '1.0' : '4.0'
                    }`
                  );
                  props.modal.onClose();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Steps(
  handleChange: (e: any) => void,
  step: number,
  formData: object,
  getProperty: Function,
  tree: object
) {
  const stepElements = [];

  for (let i = 0; i < step; i++) {
    // generate question elements
    stepElements.push(
      <div
        className={
          i + 1 !== step
            ? cn(styles.step_container)
            : cn(styles.current_step_container)
        }
      >
        <div className={cn(styles.step_left)}>
          <div className={cn(styles.hexagon)}>
            <div className={cn(styles.hexagon_top)} />
            <div className={cn(styles.hexagon_mid)}>{i + 1}</div>
            <div className={cn(styles.hexagon_bot)} />
          </div>
        </div>
        <div className={cn(styles.step_right)}>
          {i + 1 === step // get question or title based on if it's the current question
            ? getProperty('question', i, tree, formData)
            : getProperty('title', i, tree, formData)}
          {i + 1 !== step && (
            <span className={styles.previous_answer}>
              {getProperty(
                `${formData[i + 1] === true ? 'yes' : 'no'}`,
                i,
                tree,
                formData
              )}
            </span>
          )}
          {getProperty('tooltip', i, tree, formData) && (
            <Tooltip
              hasArrow
              label={getProperty('tooltip', i, tree, formData)}
              bg="white"
              color="#4F5356"
              borderRadius="4px"
              padding="12px"
              border=" 1px solid #E6E8E9"
              arrowShadowColor="#E6E8E9"
              shadow="false"
            >
              <div className={cn(styles.tooltip)}>
                <span className={cn(styles.tooltip)}>
                  <SvgInfo3 className={cn(styles.info_icon)} /> More info
                </span>
              </div>
            </Tooltip>
          )}
          {i + 1 === step && (
            <div className={cn(styles.inputs_container)}>
              <div className={cn(styles.input_container, styles.true_input)}>
                <input
                  className={cn(styles.input)}
                  type="radio"
                  name={`${i + 1}`}
                  id="true"
                  value="true"
                  onClick={(e) => {
                    handleChange(e);
                  }}
                  defaultChecked={formData[i + 1] === 'true' || true}
                />
                {/* answer text */}
                <span className={cn(styles.answer_span)}>
                  Yes. {getProperty('yes', i, tree, formData)}
                </span>
              </div>
              <div className={cn(styles.input_container, styles.false_input)}>
                <input
                  className={cn(styles.input)}
                  type="radio"
                  name={`${i + 1}`}
                  id="false"
                  value="false"
                  onClick={(e) => {
                    handleChange(e);
                  }}
                  defaultChecked={formData[i + 1] === 'false'}
                />
                {/* answer text */}
                <span className={cn(styles.answer_span)}>
                  No. {getProperty('no', i, tree, formData)}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  return stepElements;
}
