const Community = () => {
  return (
    <div className="policy text-sm">
      <h5 className="font-bold">Community Guidelines</h5>
      <p>
        1TM is a platform for creators around the world to showcase their own
        work, discover the creative work of others, and collaborate with other
        creators. These 1TM Community Guidelines (“Guidelines”) govern your use
        of 1TM to maintain an engaging and trustworthy community that fosters
        creativity across the platform.
      </p>
      <p>
        If you come across content that violates these Guidelines, please help
        report the violation to enable us to moderate and provide a better
        experience for all users.
      </p>

      <h5 className="font-bold">Share your own content on 1TM</h5>
      <ul>
        <li>Don&apos;t share others&apos; work without giving them credits.</li>
        <li>Don&apos;t take credit for others&apos; work.</li>
        <li>Don&apos;t plagiarize others&apos; work.</li>
        <li>
          If your folio violates these guidelines, it will be removed from 1TM
          and your account may be suspended.
        </li>
      </ul>
      <h5 className="font-bold">Do not post inappropriate content.</h5>
      <p>
        To keep 1TM as a healthy and inclusive community, please do not post
        folios that contain:
      </p>
      <ul>
        <li>Illegal activities or goods</li>
        <li>Harassment and threats</li>
        <li>Graphic violence or gore</li>
        <li>Self-harm or the promotion of self-harm</li>
        <li>Depictions of minors in a sexual manner</li>
        <li>Promotion of terrorism or violent extremism</li>
        <li>Pornographic material and explicit nudity</li>
        <li>
          Spam or folios related to the sale of non-creative goods or services
        </li>
        <li>
          Dissemination of misleading, fraudulent, or deceptive content that
          could lead to real-world harm
        </li>
        <li>
          Hateful or highly offensive content that attacks or dehumanizes a
          group based on race, ethnicity, national origin, religion, serious
          disease or disability, gender, age, or sexual orientation
        </li>
        <li>
          Personal or private information of others (like full name, home
          address, phone number, email address, government issued IDs, or
          anything else that would let someone locate or contact you in the real
          world)
        </li>
      </ul>
      <p>
        Please note 1TM has final say over whether content is appropriate. Your
        account may be suspended or removed if you engage in activity that
        violates these guidelines.
      </p>
      <h5 className="font-bold">Be Supportive</h5>
      <p>
        We value creative expression, but we ask that you keep your comments,
        private messages, and other communications constructive and respectful.
        We don&apos;t tolerate abusive, vulgar or insulting comments and
        messages.
      </p>

      <h5 className="font-bold">Be Authentic</h5>
      <p>
        The 1TM community is for people to bring their authentic selves and to
        publicly share one&apos;s own creative work. We disable accounts that
        engage in behavior that undermines the authenticity of our community,
        including but not limited to, the following deceptive or harmful
        behaviors:
      </p>
      <ul>
        <li>
          Using fake, misleading, or inaccurate information in your profile
        </li>
        <li>Impersonating other people or entities</li>
        <li>Falsely attributing someone&apos;s work</li>
        <li>
          Using automated or scripting processes (such as bulk or automated
          uploading of content through a script)
        </li>
        <li>
          Engaging in schemes or third-party services to boost account
          engagement (artificially increasing the number of enthuse, views, or
          other metrics)
        </li>
      </ul>
      <p>
        Broadcasting or uploading other people&apos;s work as your own is
        copyright infringement and is not tolerated.{' '}
      </p>
      <p>
        If you have a contract or other dispute with another 1TM user about
        content they have uploaded to our site, please resolve the issue
        directly with the user. We can&apos;t moderate contracts, employment, or
        other disputes between our users and the public.
      </p>

      <h5 className="font-bold">Give credit</h5>
      <p>
        If your work is inspired by other work on 1TM, make sure to give credit.
        You can do this by mentioning the 1TM member or creating a Remix, if
        allowed. Know that work that&apos;s inspired by others may be
        interpreted as stolen.
      </p>
      <p>
        If you&apos;re the owner of work that has been posted on 1TM without
        your consent, please review our Copyright and Content Licensing Policy,
        and report the violation to us.
      </p>

      <h5 className="font-bold">Follow the law</h5>
      <p>
        1TM is not a place to support or praise terrorism, organized crime, or
        hate groups. Offering sexual services, buying or selling firearms,
        alcohol, and tobacco products between private individuals, and buying or
        selling illegal or prescription drugs (even if legal in your region) are
        also not allowed.{' '}
      </p>
      <p>
        Remember to always follow the law when offering to sell or buy regulated
        goods.
      </p>
      <p>
        We have zero tolerance when it comes to sharing sexual content involving
        minors or threatening to post intimate images of others.
      </p>
    </div>
  );
};
export default Community;
