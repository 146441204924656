import * as React from 'react';
import { Svg } from '../svg';

function SvgTimer2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.25 2h4M12.25 14l3-3M12.25 22a8 8 0 100-16 8 8 0 000 16z"
        stroke="#BBC0C5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTimer2);
export default ForwardRef;
