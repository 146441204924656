/**
 * External dependencies
 */
import { useEffect, useMemo, Fragment } from 'react';

/**
 * Internal dependencies
 */
import { useIsMobile } from 'src/hooks/use-is-mobile';

import { CardGrid } from 'src/components/card/card-grid';
import TribeTag from 'src/components/common/card/tribe-tag';
import Category from 'src/components/common/card/category';

const TAGS = [
  {
    text: 'Categories',
    value: 'categories',
    cardComponent: Category,
  },
  {
    text: 'Hashtags',
    value: 'tags',
    cardComponent: TribeTag,
  },
];

function SearchResultTopicLayout({ onClick, showViewAll, children }) {
  return (
    <div>
      <div className="relative flex text-gray-900 items-center justify-between">
        <div className="text-base text-gray-500 font-semibold pb-4 pt-6 pl-4">
          Topics
        </div>
        {showViewAll && (
          <div
            className="text-sm font-medium pb-4 pt-6 pr-4 text-gray-700 cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyDown={onClick}
          >
            View all
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

function SearchResultTopic({
  currentTag,
  onChangeTag,
  result,
  resultLimit = 4,
  isAllResult = false,
}) {
  const isMobile = useIsMobile();

  useEffect(() => {
    onChangeTag(currentTag || TAGS[0].value);
  }, [currentTag, onChangeTag]);

  const Card = useMemo(() => {
    const tagConfig = TAGS.find((tag) => currentTag === tag.value);
    return tagConfig?.cardComponent || Fragment;
  }, [currentTag]);

  return (
    <>
      <div className="search-result-topic__tags relative mb-6 flex gap-2">
        {TAGS.map((tag) => (
          <button
            key={`search-result-topic__tag--${tag.value}`}
            type="button"
            className={`search-result-topic__tag relative flex px-6 py-3 ${
              currentTag === tag.value ? 'active' : ''
            }`}
            onClick={() => onChangeTag(tag.value)}
          >
            {tag.text}
          </button>
        ))}
      </div>
      <CardGrid paddingTop={isMobile && !isAllResult ? '16px' : 0}>
        {result.slice(0, resultLimit).map((data, key) => {
          const mkey = `caall_${key}`;
          return <Card key={mkey} data={data} />;
        })}
      </CardGrid>
    </>
  );
}

SearchResultTopic.Layout = SearchResultTopicLayout;

export default SearchResultTopic;
