export const CheckFeaturedPostBody = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Meet Agora
      </h1>
      <img
        src="/images/assets/tutorial/feed-area.png"
        alt="check feautred post"
        className="w-full"
      />
    </>
  );
};

export const CheckFeaturedPostFooter = ({
  setNextModal,
}: {
  setNextModal?: any;
}) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Featured folio
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        This is a featured folio. We will feature creations our editors love, or
        folio key updates and announcements here.
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('sidePanels');
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Next
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        2 / 3
      </h6>
    </>
  );
};
