import { AvatarProps as CAvatarProps, SkeletonProps } from '@chakra-ui/react';
import { FC } from 'react';

import SvgLogo from 'src/components/icons/logo';
import { useRouter } from 'next/router';
import { imageOptimize } from 'src/service/image';
import { checkAuthorName } from 'src/utils/checkAuthorName';

export type AvatarProps = Omit<CAvatarProps, 'width' | 'height'> & {
  isLoading?: boolean;
  href?: string;
  wrapperProps?: SkeletonProps;
  svgClassName?: string;
} & {
  width?: any;
  height?: any;
  margin?: string;
  border?: string;
  className?: string;
  widthUnit?: string;
  borderRadius?: string;
  author: any;
};

/**
 * @description Avatar wrapped chakra ui avatar but with our own style.
 */

const TeamMemberPhotoCard: FC<AvatarProps> = ({
  isLoading,
  href,
  wrapperProps,
  children,
  className = '',
  margin,
  border = 'none',
  bgColor,
  author = {
    username: '',
  },
  ...props
}) => {
  const {
    width,
    height,
    src: imgUrl,
    svgClassName,
    widthUnit,
    borderRadius,
    mr,
  } = props;

  const avatarWidth = width || 40;
  const avatarHeight = height || 40;

  const router = useRouter();

  const getSize = () => {
    const userName = checkAuthorName(author);
    const fontSize = 9 * (userName.length > 1 ? userName.length * 0.6 : 1);
    return `${fontSize}em`;
  };

  const getBoxSize = (size) => {
    if (/^\d+$/g.test(size)) {
      return `${size}${widthUnit || 'px'}`;
    }
    return size;
  };

  const getUniqueClass = () => svgClassName || 'circle-wrapper';

  // defaultImg for no username
  return (
    <div
      aria-hidden="true"
      className={`${className} relative ${mr && `mr-${mr}`}`}
      onClick={() => {
        if (href) {
          router.push(href);
        }
      }}
    >
      <div
        style={{
          width: `${getBoxSize(avatarWidth)}`,
          height: `${getBoxSize(avatarHeight)}`,
          border,
          borderRadius: `${borderRadius || ''}px`,
        }}
        className={`${getUniqueClass()} align-middle overflow-hidden`}
      >
        {imgUrl && (
          <img
            src={imageOptimize({ url: imgUrl, imgContainer: 'avatar' })}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              aspectRatio: '1',
            }}
          />
        )}

        {!imgUrl && author.username && (
          <div className="relative">
            <div
              style={{ height: 'fit-content', fontSize: `${getSize()}` }}
              className="absolute m-auto right-0 left-0 top-0 bottom-0 w-max text-white font-normal"
            >
              {checkAuthorName(author)}
            </div>
            <img
              src={`/api/avatar?name=${author.username}`}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
        )}

        {!(imgUrl || author.username) && (
          <div className="w-full h-full">
            <SvgLogo
              alt="1TM Logo"
              margin="auto"
              top={0}
              bottom={0}
              right={0}
              left={0}
              position="absolute"
              className=""
            />
          </div>
        )}
      </div>
      {(imgUrl || author.username) && children}
    </div>
  );
};

export default TeamMemberPhotoCard;
