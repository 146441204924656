import { Button } from '@chakra-ui/react';
import { Avatar } from 'src/components/avatar';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { useRepplQuery, useFollow } from 'src/app/reppl/hooks';
import { logger } from 'src/lib/logger';
import { useState, useEffect } from 'react';
import { imageOptimize } from 'src/service/image';

export function PopupCard({ rid }: { rid?: string }) {
  const [reppl, setReppl] = useState<any>({});
  const { data: repplData } = useRepplQuery(rid);

  useEffect(() => {
    setReppl(repplData?.reppl);
  }, [repplData]);

  return (
    <div className="border rounded-xl p-4 z-50 bg-white dark:bg-gray-900">
      <div className="flex">
        <div>
          <Avatar
            name={getProfileFullNameOrUsername(reppl?.title)}
            src={imageOptimize(reppl?.coverImgURL)}
            width={40}
            height={40}
          />
        </div>
        <div className="font-bold my-auto mx-2 dark:text-white">
          {reppl?.title}
        </div>
      </div>
      <div className="py-4 text-xs dark:text-white">{reppl?.summary}</div>
      <div className="flex relative pb-2">
        <div className="text-gray-500 text-xs px-1 dark:text-gray-400">
          {reppl?.followerCount} Followers
        </div>
        <div className="text-gray-500 text-xs px-1 dark:text-gray-400">
          {reppl?.episodeCount} Episodes
        </div>
        <div className="absolute right-0 bottom-1">
          <OnFollow reppl={reppl} />
        </div>
      </div>
    </div>
  );
}

function OnFollow({ reppl }: { reppl: any }) {
  const { mutateAsync: creatRepplFollow } = useFollow(reppl?.id);

  const handleRepplFollow = async () => {
    try {
      await creatRepplFollow({
        targetID: reppl.id,
        group: 'REPPL',
        follow: !reppl?.isFollowed,
      });
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <Button
      variant="link"
      fontSize="sm"
      fontWeight="600"
      border="1px solid #BBC0C5"
      borderRadius="2px"
      p="5px 5px"
      onClick={() => handleRepplFollow()}
    >
      {reppl?.isFollowed ? 'Following' : 'Follow'}
    </Button>
  );
}
