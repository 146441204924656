export type FilterType =
  | 'ALL'
  | 'CATEGORIES'
  | 'SKILLS'
  | 'SERVICES'
  | 'LANGUAGES';

export enum FilterNameType {
  CATEGORIES = 'categories',
  SKILLS = 'skills',
  SERVICES = 'services',
  LANGUAGES = 'languages',
}

export type FilterTypeState = Record<
  Exclude<FilterNameType, 'skills'>,
  number[]
> & {
  skills: Record<number, number[]>;
};
