import * as React from 'react';
import { Svg } from '../svg';

function SvgChain({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8.334 10.833a4.168 4.168 0 006.283.45l2.5-2.5a4.167 4.167 0 00-5.892-5.892L9.792 4.316"
        stroke="#9B9FA4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.667 9.167a4.165 4.165 0 00-6.284-.45l-2.5 2.5a4.167 4.167 0 005.892 5.892l1.425-1.425"
        stroke="#9B9FA4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgChain);
export default ForwardRef;
