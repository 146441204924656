import * as React from 'react';
import { Svg } from '../svg';

function SvgRarible({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#rarible_svg__clip0_6588_79586)">
        <path
          d="M19.533.553h-14.4a4.8 4.8 0 00-4.8 4.8v14.4a4.8 4.8 0 004.8 4.8h14.4a4.8 4.8 0 004.8-4.8v-14.4a4.8 4.8 0 00-4.8-4.8z"
          fill="#FEDA03"
        />
        <path
          d="M16.893 12.465c.756-.196 1.43-.762 1.43-1.899 0-1.893-1.608-2.333-3.668-2.333H6.454v8.524h3.452v-2.893h4.096c.63 0 1 .25 1 .869v2.024h3.452v-2.131c0-1.16-.655-1.893-1.56-2.16zm-2.833-.899H9.905v-.833h4.155c.453 0 .726.06.726.417s-.273.416-.726.416z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="rarible_svg__clip0_6588_79586">
          <path
            fill="#fff"
            transform="translate(.333 .553)"
            d="M0 0h24v24H0z"
          />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgRarible);
export default ForwardRef;
