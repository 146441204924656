import * as React from 'react';
import { Svg } from '../svg';

function SvgHashtag({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M6.487 11.667l.35-3.334H3.334V6.667h3.679L7.45 2.5h1.676l-.438 4.167h3.325L12.45 2.5h1.676l-.438 4.167h2.979v1.666h-3.154l-.35 3.334h3.504v1.666h-3.68L12.55 17.5h-1.676l.438-4.167H7.988L7.55 17.5H5.874l.438-4.167H3.333v-1.666h3.154zm1.676 0h3.325l.35-3.334H8.513l-.35 3.334z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgHashtag);
export default ForwardRef;
