export const SetUpYourProfile3Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Build your Studio
      </h1>
      <img
        src="/images/assets/tutorial/set-up-your-profile-3.png"
        alt="set up your profile 3"
        className="w-full"
      />
    </>
  );
};

export const SetUpYourProfile3Footer = ({
  setNextModal,
}: {
  setNextModal?: any;
}) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Set up your profile
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        The About tab: Here you add further details that you want to share with
        your audience.
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('setUpYourProfile4');
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Next
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        3 / 5
      </h6>
    </>
  );
};
