import { useQuery } from 'react-query';
import { CacheKey } from 'src/constants/cache-key';
import { axios } from 'src/lib/axios';
import { logger } from 'src/lib/logger';
import { RestResponse } from 'src/types';

export const fetchFullAsset = (id: string) => {
  return axios
    .get<RestResponse>(`/nft/creative-asset/${id}`)
    .then((res) => res.data)
    .then((res) => {
      if (res.code === 1000) {
        return res.data;
      }
    });
};

export function useAssetFullQuery(id: string) {
  return useQuery([CacheKey.creation, id], () => fetchFullAsset(id), {
    enabled: !!id,
    onError: (error) => {
      logger.error(error);
    },
  });
}
