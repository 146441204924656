import * as React from 'react';
import { Svg } from '../svg';

function SvgIcSettings({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.895 8c0-1.466-1.232-2.667-2.737-2.667-1.506 0-2.737 1.2-2.737 2.667 0 1.467 1.231 2.667 2.737 2.667 1.505 0 2.736-1.2 2.736-2.667zm0 16c0-1.466-1.232-2.666-2.737-2.666-1.506 0-2.737 1.2-2.737 2.666 0 1.467 1.231 2.667 2.737 2.667 1.505 0 2.736-1.2 2.736-2.667zm-2.737-10.666c1.505 0 2.736 1.2 2.736 2.666 0 1.467-1.231 2.667-2.736 2.667-1.506 0-2.737-1.2-2.737-2.667 0-1.466 1.231-2.666 2.737-2.666z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgIcSettings);
export default ForwardRef;
