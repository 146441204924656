import { FilterType, FilterNameType, FilterTypeState } from './filter/types';
import { SuggestionState } from './suggestion/types';

/**
 * FILTER GENRE NAMES PREV VERSION
 * creations -> folio
 * collections -> inspirations
 * creators -> users
 * projects -> spaces
 * tribes -> topics
 * topics -?-> galleries
 */

// algolia { filterType }
export enum AlgoliaFilterType {
  CREATIONS = 'FOLIO',
  USERS = 'USER',
  SPACES = 'SPACE',
  COLLECTIONS = 'COLLECTION',
  TOPICS = 'TOPIC',
  CREATIVE_ASSETS = 'CREATIVE_ASSET',
}

export type ResultType = Record<AlgoliaFilterType, Array<object | undefined>>;

export type GenreType =
  | 'ALL'
  | 'CREATIONS'
  | 'USERS'
  | 'SPACES'
  | 'COLLECTIONS'
  | 'TOPICS'
  | 'CREATIVE_ASSETS';

export interface SearchState {
  isFetching: boolean;
  isError: boolean;
  isSearchLite: boolean;

  searchTerm: string;
  genre: GenreType;

  result: ResultType;
  totalResult: number;
}

export enum SortType {
  NAME = 'name',
  RECENTLY = 'recently',
  POPULARITY = 'popularity',
}

interface GenreCardComponentProps {
  // TODO: add result types
  result: any;
  resultLimit?: number;
  openModal?: (data: any) => void;
  isAllResult?: boolean;
}

export interface TopicCardComponentProps extends GenreCardComponentProps {
  currentTag: string;
  onChangeTag: (tag: string) => void;
}

export interface GenreConfig {
  filterType: AlgoliaFilterType;
  tab: string;
  filter: Array<FilterNameType>;
  sortProperty: Record<SortType, string> | {};
  facet: Array<string>;
  cardComponent: ({
    result,
    resultLimit,
    openModal,
    isAllResult,
  }: GenreCardComponentProps | TopicCardComponentProps) => JSX.Element;
  viewAllShowLimit: number;
  showTab: boolean;
}

export type GenreConfigType = Record<Exclude<GenreType, 'ALL'>, GenreConfig>;

export type FilterChangeFunction = (props: {
  type: FilterType;
  value: number | object;
  groupId?: number | null;
}) => void;

export interface SearchReducer {
  state: SearchState;
  actions: {
    onSearchChange: () => string;
    setGenre: (genre: GenreType) => void;
    search: (
      searchTerm: string,
      more: boolean,
      callbackAfterFetchSuccess: Function
    ) => Promise<void>;
    clear: () => void;
  };
  searchInputRef: React.MutableRefObject<HTMLInputElement>;
}
export interface SearchContextType {
  search: SearchState & {
    onClearSearch: () => void;
    onGenreChange: (genre: GenreType) => void;
    onSearch: () => void;
  };

  filter: FilterTypeState & {
    hasFilter: boolean;
    result: ResultType;
    totalResult: number;

    onFilterChange: FilterChangeFunction;
  };

  sort: {
    type: string;
    result: ResultType;
    totalResult: number;
  };

  suggestions: SuggestionState & {
    onSearchSuggestions: () => void;
    onClearSearchLite: () => void;
  };

  // TODO: Maybe a better name?
  sortOptions: any[];
  searchInputRef: React.MutableRefObject<HTMLInputElement>;
  suggestionInputRef: React.MutableRefObject<HTMLInputElement>;
  onSaveSearchTerm: (searchTerm: string) => void;
}
