export const SetUpYourProfile4Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Build your Studio
      </h1>
      <img
        src="/images/assets/tutorial/set-up-your-profile-4.png"
        alt="set up your profile 4"
        className="w-full"
      />
    </>
  );
};

export const SetUpYourProfile4Footer = ({
  setNextModal,
}: {
  setNextModal?: any;
}) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Set up your profile
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        The Details tab: Highlight your skills and passions. This helps create
        potential opportunities for collaboration!
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('setUpYourProfile5');
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Next
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        4 / 5
      </h6>
    </>
  );
};
