import * as React from 'react';
import { Svg } from '../svg';

function SvgInstagramV3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M11.914 10a1.914 1.914 0 11-3.828 0 1.914 1.914 0 013.828 0z"
        fill="currentColor"
      />
      <path
        d="M14.476 6.613a1.78 1.78 0 00-.43-.66 1.778 1.778 0 00-.66-.43c-.202-.078-.506-.171-1.066-.197-.605-.028-.787-.034-2.32-.034-1.534 0-1.715.006-2.32.034-.56.026-.865.12-1.067.198-.25.092-.475.238-.66.43a1.78 1.78 0 00-.43.66c-.078.202-.172.506-.197 1.066-.028.605-.034.787-.034 2.32s.006 1.715.034 2.32c.025.56.119.864.197 1.067.092.25.24.475.43.66.185.19.41.337.66.43.202.078.507.172 1.066.197.606.028.787.034 2.32.034 1.534 0 1.716-.006 2.321-.034.56-.025.864-.119 1.066-.198.501-.193.897-.589 1.09-1.09.079-.202.172-.506.198-1.066.027-.605.033-.787.033-2.32s-.006-1.715-.033-2.32c-.026-.56-.12-.864-.198-1.067zM10 12.95a2.949 2.949 0 110-5.898 2.949 2.949 0 010 5.898zm3.065-5.325a.69.69 0 110-1.378.69.69 0 010 1.378z"
        fill="currentColor"
      />
      <path
        d="M10 0C4.478 0 0 4.478 0 10s4.478 10 10 10 10-4.478 10-10S15.522 0 10 0zm5.708 12.367c-.028.612-.125 1.029-.267 1.394a2.936 2.936 0 01-1.68 1.68c-.365.142-.782.239-1.393.267-.613.027-.808.034-2.368.034-1.56 0-1.755-.007-2.367-.034-.612-.028-1.029-.125-1.394-.267a2.813 2.813 0 01-1.017-.663 2.815 2.815 0 01-.663-1.017c-.141-.365-.239-.782-.266-1.393-.029-.613-.035-.809-.035-2.368 0-1.56.006-1.755.034-2.367.028-.612.125-1.029.267-1.394.144-.383.37-.73.663-1.017a2.812 2.812 0 011.017-.663c.365-.142.782-.239 1.394-.267.612-.027.808-.034 2.367-.034 1.56 0 1.755.007 2.367.035.612.027 1.029.124 1.394.266.383.144.73.37 1.017.663.292.287.519.634.663 1.017.142.365.239.782.267 1.394.028.612.034.808.034 2.367 0 1.56-.006 1.755-.034 2.367z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgInstagramV3);
export default ForwardRef;
