import * as React from 'react';
import { Svg } from '../svg';

function SvgArr({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.584 20.057c0 7.364-5.97 13.334-13.334 13.334S6.917 27.42 6.917 20.057c0-7.363 5.97-13.333 13.333-13.333 7.364 0 13.334 5.97 13.334 13.333zm3.333 0c0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.666-7.462-16.666-16.667 0-9.204 7.461-16.666 16.666-16.666s16.667 7.461 16.667 16.666zm-22.802-8.732a1.58 1.58 0 011.117-.463h5.873c.826 0 2.195.297 3.396 1.155 1.286.918 2.347 2.464 2.347 4.766 0 1.387-.351 2.553-.972 3.497-.615.934-1.435 1.563-2.247 1.983-.284.147-.571.27-.855.375l3.655 3.965a1.58 1.58 0 01-.09 2.231c-.218.2-.486.265-.732.26a1.938 1.938 0 01-.7-.162 3.367 3.367 0 01-1.105-.76l-5.292-5.499a1.58 1.58 0 011.162-2.65c.472 0 1.577-.085 2.506-.566.442-.229.804-.526 1.059-.913.248-.377.452-.926.452-1.761 0-1.193-.493-1.817-1.023-2.196-.616-.44-1.33-.566-1.56-.566h-4.294v13.652a1.58 1.58 0 01-3.16 0V12.442c0-.42.167-.821.463-1.117z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgArr);
export default ForwardRef;
