/**
 * Internal dependencies
 */
import SearchFilterTitle from 'src/components/common/search/searchFilterTitle';

const TITLE_NAME = 'Category';

const CategoryFilterTitle = ({ selectList }) => (
  <SearchFilterTitle title={TITLE_NAME} selectList={selectList} />
);

export default CategoryFilterTitle;
