/**
 * Internal dependencies
 */
import SearchFilterTitle from 'src/components/common/search/searchFilterTitle';

const TITLE_NAME = 'Service';

const ServiceFilterTitle = ({ selectList }) => (
  <SearchFilterTitle title={TITLE_NAME} selectList={selectList} />
);

export default ServiceFilterTitle;
