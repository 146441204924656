export const HowToStartAReppl1Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Start a Space
      </h1>
      <img
        src="/images/assets/tutorial/how-to-start-a-reppl-1.png"
        alt="how to start a reppl 1"
        className="w-full"
      />
    </>
  );
};

export const HowToStartAReppl1Footer = ({
  setNextModal,
}: {
  setNextModal?: any;
}) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Set Up your Space
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Fill out the details and set up your Space, this is important as it will
        be the first thing that your audience sees, so make a good lasting first
        impression!
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('howToStartAReppl2');
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Next
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        1 / 2
      </h6>
    </>
  );
};
