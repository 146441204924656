import * as React from 'react';
import { Svg } from '../svg';

function SvgExisting({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="url(#existing_svg__pattern0)" d="M0 0h60v60H0z" />
      <defs>
        <pattern
          id="existing_svg__pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use
            xlinkHref="#existing_svg__image0_5039_218186"
            transform="scale(.0125)"
          />
        </pattern>
        <image
          id="existing_svg__image0_5039_218186"
          width={80}
          height={80}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAABmJLR0QA/wD/AP+gvaeTAAACN0lEQVR4nO3cP2gTYRzG8W/+kEQsxRAtqJQu2kFDHRxsQZcilIKOLgo61tGx6KrgJNLJ3S5ODmKhUHCpGkEEwUm6iMUKKgYMrUTN62AtDuLlzdNgLn0+4/G748eX4257wcxs58rE3jA9t1AMG+WZEDgPVIHd279W71icnfhno3zMw85cf3Lw23rmIXBM2qqPtB1wem6h+DverlKBsdERhiqD5HM5AKYORb/MPe3ynWdtzbUdMGyUZ9iMNzlepZCPenn7VrbdwRC4ADA2OuJ4f2g7IHAEYKgy2KVV0ikm4ACw9c2zX2IC2l84oMgBRQ4ockCRA4ocUOSAIgcUOaDIAUUOKHJAkQOKHFDkgCIHFDmgyAFFDihyQJEDihxQ5IAiBxQ5oMgBRQ4ockCRA4ocUOSAIgcUOaDIAUUOKHJAkQOKHFDkgCIHFDmgyAFFDihyQJEDihxQ5IAiBxQ5oMgBRQ4ockCRA4ocUBR9BNHahzorb95T/9Lg+4/W1vX7S9u6V2pEB6y9fN2NPVIrOmA+l+HSqWEmj+6lMlDoxk7/3fzyKvOPV8nAg6TZ6IAXTw5z7sSBzjZLidrKZwBaIXs7aTb6J3K6uq+DldJlrf4VgGZp/UXSbEzABkAgdLZVn4oJ+Bxg6dXHLq3SO/bvKQFQahaPJ822fwAj3AK4u/yWe7V3fGo0O16w140fLgMQWpkrSbNRB59O3Xx6A7ja2VrplHSKb9RPZHF24lrIhrPAIza/iWZmtnP9BKFEWxxTfKkDAAAAAElFTkSuQmCC"
        />
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgExisting);
export default ForwardRef;
