export function removeItemFromIndex<T>(arr: T[], index: number) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

// ref: https://github.com/sindresorhus/array-move/blob/master/index.js
export function arrayMove<T>(array: T[], from: number, to: number) {
  const nextArray = [...array];
  const startIndex = from < 0 ? nextArray.length + from : from;

  if (startIndex >= 0 && startIndex < nextArray.length) {
    const endIndex = to < 0 ? nextArray.length + to : to;

    const [item] = nextArray.splice(from, 1);
    nextArray.splice(endIndex, 0, item);
  }

  return nextArray;
}

export function arrayHasData<T>(arr?: Array<T>): arr is Array<T> {
  return Array.isArray(arr) && arr.length > 0;
}
