import { getColor, mode } from '@chakra-ui/theme-tools';

const parts = ['field', 'addon'];

const baseStyle = {
  field: {
    width: '100%',
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transition: 'all 0.3s',
  },
};

const size = {
  sm: {
    fontSize: 'sm',
    px: '12px',
    h: 5,
    borderRadius: 'md',
  },
  md: {
    fontSize: 'md',
    px: 3,
    h: 6,
    borderRadius: 'md',
  },
  lg: {
    fontSize: 'lg',
    px: '20px',
    h: 7,
    borderRadius: 'md',
  },
};

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
};

function getDefaults(props) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;

  return {
    focusBorderColor: fc || 'black',
    errorBorderColor: ec || 'red.500',
  };
}

function variantOutline(props) {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      border: mode('1px solid', 'none')(props),
      borderColor: 'gray.200',
      bg: mode('white', '$mode.50')(props),

      _hover: {
        borderColor: 'gray.300',
      },

      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },

      _disabled: {
        bg: '$mode.100',
        color: '$mode.400',
        cursor: 'not-allowed',
      },

      _focus: {
        zIndex: 1,
        borderColor: getColor(theme, fc),
        boxShadow: mode(`0 0 0 1px ${getColor(theme, fc)}`, 'none')(props),
      },

      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: `0 0 0 1px ${getColor(theme, ec)}`,
      },
    },

    addon: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: '$mode.100',
    },
  };
}

function variantFlushed(props) {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      borderBottom: '2px solid',
      borderColor: 'inherit',
      borderRadius: 0,
      px: 0,
      bg: 'transparent',

      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },

      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },

      _focus: {
        zIndex: 1,
        borderColor: getColor(theme, fc),
      },

      _invalid: {
        borderColor: getColor(theme, ec),
      },
    },

    addon: {
      borderBottom: '2px solid',
      borderColor: 'inherit',
      px: 0,
      bg: 'transparent',
    },
  };
}

const variantUnstyled = {
  field: {
    bg: 'transparent',
    pl: 0,
    pr: 0,
    h: 'auto',
  },
  addon: {
    bg: 'transparent',
    pl: 0,
    pr: 0,
    h: 'auto',
  },
};

function variantEllipse(props) {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      border: '1px solid',
      borderColor: 'gray.200',
      bg: 'transparent',
      borderRadius: '40px',

      _hover: {
        borderColor: 'gray.300',
      },

      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },

      _disabled: {
        bg: 'gray.100',
        cursor: 'not-allowed',
      },

      _focus: {
        zIndex: 1,
        borderColor: getColor(theme, fc),
        boxShadow: `0 0 0 1px ${getColor(theme, fc)}`,
      },

      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: `0 0 0 1px ${getColor(theme, ec)}`,
      },
    },
    addon: {
      border: '1px solid',
      borderRadius: '40px',
      borderColor: 'inherit',
      bg: 'gray.100',
    },
  };
}

const variants = {
  outline: variantOutline,
  flushed: variantFlushed,
  unstyled: variantUnstyled,
  ellipse: variantEllipse,
};

const defaultProps = {
  size: 'md',
  variant: 'outline',
};

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
