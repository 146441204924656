import * as React from 'react';
import { Svg } from '../svg';

function SvgDiscussion({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M17.5 12.5a1.666 1.666 0 01-1.667 1.667h-10L2.5 17.5V4.167A1.667 1.667 0 014.167 2.5h11.666A1.666 1.666 0 0117.5 4.167V12.5z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgDiscussion);
export default ForwardRef;
