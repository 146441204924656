import * as React from 'react';
import { Svg } from '../svg';

function SvgArrowLeftV22X({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M7.705 1.41L3.125 6l4.58 4.59L6.295 12l-6-6 6-6 1.41 1.41z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowLeftV22X);
export default ForwardRef;
