import * as React from 'react';
import { Svg } from '../svg';

function SvgCopied({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <rect width={32} height={32} rx={16} fill="#74787D" />
      <path
        d="M20.167 11l-4.584 4.583M11 20.167L6.833 16M25.167 11L16 20.167 11.834 16"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCopied);
export default ForwardRef;
