import * as React from 'react';
import { Svg } from '../svg';

function SvgArrType({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4.526C9.664 4.526 4.527 9.663 4.527 16c0 6.337 5.137 11.473 11.473 11.473 6.336 0 11.473-5.136 11.473-11.473 0-6.336-5.137-11.473-11.473-11.473zM2.666 16C2.666 8.636 8.636 2.666 16 2.666s13.333 5.97 13.333 13.333c0 7.364-5.97 13.334-13.333 13.334-7.364 0-13.334-5.97-13.334-13.334z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.328 9.249a.93.93 0 01.657-.273h4.699c.598 0 1.623.22 2.523.862.95.678 1.738 1.818 1.738 3.542 0 1.052-.266 1.919-.723 2.614a4.375 4.375 0 01-1.672 1.474 5.89 5.89 0 01-1.13.438l3.278 3.556a.93.93 0 11-1.368 1.26l-4.477-4.856a.93.93 0 01.684-1.56c.4 0 1.346-.07 2.158-.49.393-.204.731-.477.973-.844.237-.361.416-.866.416-1.592 0-1.071-.454-1.668-.958-2.028-.555-.396-1.194-.515-1.442-.515h-3.768v11.255a.93.93 0 11-1.86 0V9.907a.93.93 0 01.272-.658z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrType);
export default ForwardRef;
