import Input from '../Input';

const { defaultProps, variants } = Input;

const parts = ['field', 'icon'];

function baseStyleField() {
  return {
    ...Input.baseStyle.field,
    appearance: 'none',
    paddingBottom: '1px',
    lineHeight: 'normal',
    '> option': {
      bg: 'white',
    },
  };
}

const baseStyleInput = {
  width: '1.5rem',
  height: '100%',
  right: '0.5rem',
  position: 'relative',
  color: 'currentColor',
  fontSize: '1.25rem',
  _disabled: { opacity: 0.5 },
};

const baseStyle = (props) => ({
  field: baseStyleField(props),
  icon: baseStyleInput,
});

const sizes = {
  sm: {
    ...Input.sizes.sm,
    field: {
      ...Input.sizes.sm.field,
      pr: '2rem',
    },
  },

  md: {
    ...Input.sizes.md,
    field: {
      ...Input.sizes.md.field,
      pr: '2rem',
    },
  },

  lg: {
    ...Input.sizes.lg,
    field: {
      ...Input.sizes.lg.field,
      pr: '2rem',
    },
  },
};

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
