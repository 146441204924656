import * as React from 'react';
import { Svg } from '../svg';

function SvgNcNt({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.623 28.058A13.312 13.312 0 0120 33.334c-7.364 0-13.333-5.97-13.333-13.333 0-1.834.37-3.582 1.04-5.172l3.57 2.06a9.2 9.2 0 00-.486 2.963 9.17 9.17 0 001.845 5.531l2.743-1.9a5.878 5.878 0 014.325-9.5v2.256l5.6-3.926-5.6-3.925v2.258a9.192 9.192 0 00-6.8 3.334l-3.528-2.037A13.312 13.312 0 0120 6.667c7.364 0 13.333 5.97 13.333 13.333 0 1.834-.37 3.582-1.04 5.173l-3.655-2.11c.372-1 .575-2.082.575-3.212a9.169 9.169 0 00-1.63-5.233l-2.74 1.898a5.878 5.878 0 01-4.505 9.203v-2.215l-5.6 3.926 5.6 3.925v-2.299a9.19 9.19 0 006.59-3.132l3.695 2.134zM36.667 20c0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.667-7.462-16.667-16.667 0-9.205 7.462-16.667 16.667-16.667 9.205 0 16.667 7.462 16.667 16.667z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNcNt);
export default ForwardRef;
