import { Link as ChakraLink, Button } from '@chakra-ui/react';
import SvgResendPasswordLogo from 'src/components/icons/resend-password-logo';

interface ModalResendPasswordProps {
  email: string;
  toggleForm: () => void;
  onEmailSubmit: (email: string) => void;
  timerSeconds: any;
  Timer: () => void;
}

function ModalResendPassword({
  email,
  onEmailSubmit,
  timerSeconds,
  Timer,
}: ModalResendPasswordProps) {
  return (
    <div className="flex flex-col	items-center">
      <SvgResendPasswordLogo fontSize="80px" mb={1} />
      <h1 className="mb-1 text-xl lg:text-4xl text-gray-900">
        Reset Password Email Sent
      </h1>
      <p className="mb-6 lg:mb-8 text-gray-700 text-xs ls:text-sm max-w-xs lg:max-w-md text-center">
        An email has been sent to your email address,{' '}
        <ChakraLink>{email}</ChakraLink> Please follow the instructions in the
        email to reset your password.
      </p>
      {timerSeconds > 0 ? (
        <Button
          rounded="ellipse"
          marginBottom={3}
          data-testid="resend-pwd"
          width="max-content"
          disabled
          px="32px"
          py="10px"
        >
          <span className="text-sm lg:text-base">
            Resend Email ({timerSeconds} sec.)
          </span>
        </Button>
      ) : (
        <Button
          rounded="ellipse"
          marginBottom={3}
          data-testid="resend-pwd"
          width="max-content"
          onClick={async () => {
            await onEmailSubmit(email);
            Timer();
          }}
          px="32px"
          py="10px"
        >
          <span className="text-sm lg:text-base">Resend Email</span>
        </Button>
      )}
    </div>
  );
}

export default ModalResendPassword;
