import * as React from 'react';
import { Svg } from '../svg';

function SvgStoryPost({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16.667 1.667c.46 0 .833.373.833.833v3.131l-1.667 1.667V3.334H4.167v13.333h11.666v-2.298l1.667-1.667V17.5c0 .46-.373.834-.833.834H3.333A.834.834 0 012.5 17.5v-15c0-.46.373-.833.833-.833h13.334zm1.481 5.673l1.179 1.179L12.845 15 11.665 15l.002-1.177 6.481-6.482zM10.833 10v1.667H6.667V10h4.166zm2.5-3.333v1.667H6.667V6.667h6.666z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgStoryPost);
export default ForwardRef;
