const baseStyle = {
  lineHeight: '1.2',
  borderRadius: 'md',
  fontWeight: 'bold',
  _focus: {
    boxShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
};

function variantGhost(props) {
  const { colorScheme: c } = props;

  return {
    bg: 'transparent',
    color: `${c}.500`,
    _hover: {
      bg: `${c}.50`,
    },
    _active: {
      bg: `${c}.100`,
    },
    borderRadius: 'md',
  };
}

function variantOutline(props) {
  const { size } = props;
  const borders = {
    sm: '1px solid',
    md: '1.5px solid',
    lg: '2px solid',
  };

  return {
    border: borders[size],
    borderColor: 'currentColor',
    ...variantGhost(props),
  };
}

function variantSolid(props) {
  const { colorScheme: c } = props;

  return {
    bg: `${c}.500`,
    color: 'white',
    _hover: { bg: `${c}.400` },
    _active: { bg: `${c}.700` },
    borderRadius: 'md',
  };
}

const variantUnstyled = {
  bg: 'none',
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: 0,
  p: 0,
};

const variantLink = {
  bg: 'none',
  color: 'blue.500',
  px: 0,
  width: 'fit-content',
  height: 'auto',
};

const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid,
  unstyled: variantUnstyled,
  link: variantLink,
};

const sizes = {
  lg: {
    fontSize: 'lg',
    h: 7,
    minWidth: 7,
    px: 6,
  },
  md: {
    fontSize: 'md',
    h: 6,
    minWidth: 6,
    px: 5,
  },
  sm: {
    fontSize: 'xs',
    h: 5,
    minWidth: 5,
    px: 4,
    borderRadius: 'sm',
  },
};

const defaultProps = {
  variant: 'solid',
  size: 'md',
  colorScheme: 'blue',
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
