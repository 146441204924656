export const CreationsBody = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Build your Studio
      </h1>
      <img
        src="/images/assets/tutorial/creations.png"
        alt="creations"
        className="w-full"
      />
    </>
  );
};

export const CreationsFooter = ({ setNextModal }: { setNextModal?: any }) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        This is your home base!
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Studio is where other 1TMers can get to know you and where you can
        showcase your creativity and work. It’s your 1TM user profile.
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('setUpYourProfile1');
        }}
        className="px-9 py-2.5 mb-6 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Start
      </button>
    </>
  );
};
