import * as React from 'react';
import { Svg } from '../svg';

function SvgWalletConnect({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#WalletConnect_svg__clip0_4709_276930)">
        <path
          d="M5.415 3.5c3.913-3.83 10.257-3.83 14.17 0l.47.462a.483.483 0 010 .693l-1.61 1.578a.254.254 0 01-.354 0l-.649-.635c-2.73-2.672-7.155-2.672-9.885 0l-.694.68a.254.254 0 01-.354 0L4.899 4.7a.483.483 0 010-.693l.516-.506zm17.501 3.263l1.434 1.403a.483.483 0 010 .694l-6.465 6.33a.509.509 0 01-.708 0l-4.588-4.493a.127.127 0 00-.178 0L7.823 15.19a.509.509 0 01-.708 0L.65 8.86a.483.483 0 010-.694l1.433-1.403a.509.509 0 01.709 0l4.588 4.492a.127.127 0 00.177 0l4.589-4.492a.509.509 0 01.708 0l4.588 4.492a.127.127 0 00.178 0l4.588-4.492a.509.509 0 01.708 0z"
          fill="#3B99FC"
        />
      </g>
      <defs>
        <clipPath id="WalletConnect_svg__clip0_4709_276930">
          <path fill="#fff" transform="translate(.5 .6)" d="M0 0h24v14.8H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgWalletConnect);
export default ForwardRef;
