import { ReactNode } from 'react';
import { BoxProps } from '@chakra-ui/react';

import { Metadata } from 'src/app/metadata';
import { Layout as BasicLayout } from './layout';

export interface BasicLayoutProps {
  header?: ReactNode;
  footerVisible?: boolean;
  boxProps?: BoxProps;

  children: ReactNode;
}

export interface SearchLayoutProps extends BasicLayoutProps {
  keyword?: string;
}

export default function SearchLayout({
  keyword = '',
  header,
  children,
}: SearchLayoutProps) {
  const title = keyword;
  const description = keyword ? `${keyword} on 1TM ` : '';

  return (
    <BasicLayout header={header} boxProps={{ layerStyle: 'layer2' }}>
      <Metadata title={title} description={description} />
      {children}
    </BasicLayout>
  );
}
