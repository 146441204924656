import * as React from 'react';
import { Svg } from '../svg';

function SvgAddItem({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M19 22.6H5c-1.1 0-2-.9-2-2v-14c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2zM16 2.6v2M8 2.6v2"
        stroke="#0278E4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.6a3 3 0 100-6 3 3 0 000 6zM17 19.1c-1.4-1-3.1-1.5-5-1.5s-3.6.6-5 1.5"
        stroke="#0278E4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgAddItem);
export default ForwardRef;
