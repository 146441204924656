import * as React from 'react';
import { Svg } from '../svg';

function SvgWarning({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M1.912 24.611v-8a10.667 10.667 0 0121.333 0v8h1.333v2.667h-24v-2.667h1.334zm2.666 0h16v-8a8 8 0 00-16 0v8zm6.667-24h2.667v4h-2.667v-4zm11.704 3.744l1.885 1.886-2.826 2.828-1.887-1.886 2.828-2.828zM.322 6.241l1.886-1.886 2.828 2.827L3.152 9.07.322 6.24zm5.59 10.37a6.667 6.667 0 016.666-6.666v2.666a4 4 0 00-4 4H5.912z"
        fill="#F63"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgWarning);
export default ForwardRef;
