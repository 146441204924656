import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import SvgArrowRight from 'src/components/icons/arrow-right';
import SvgArrowLeft from 'src/components/icons/arrow-left';
import { NotificationContext } from 'src/hooks/use-notification';
import { useRouter } from 'next/router';
import {
  useColorModeValue,
  useMediaQuery,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import SignInModal from 'src/app/auth/sign-in/sign-in-modal';

interface NavigationPanelProps {
  setDisplayPanel: Function;
  navItems: Array<any>;
  siteItems: Array<any>;
  siteSubItems: object;
  isLoggedIn?: Boolean;
}
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavigationPanel = ({
  setDisplayPanel,
  navItems,
  siteItems,
  siteSubItems,
  isLoggedIn,
}: NavigationPanelProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const color = useColorModeValue('text-gray-900', 'text-white-900');
  const bg = useColorModeValue('bg-white', 'bg-gray-900');
  const [isLessThan370] = useMediaQuery('(max-width: 370px)');
  const [mainNavClose, setMainNavClose] = useState(false);

  const [notification] = useContext(NotificationContext);
  const newnotif = notification?.notificationTop?.filter(function filterNotif(
    notifData
  ) {
    return notifData.read === false;
  });

  const badgeNumber = notification.notifCount > 99 ? '99+' : newnotif?.length;

  const [displaySubItems, setDisplaySubItems] = useState('');
  const panelHandle = (type) => {
    setDisplaySubItems(type);
  };

  const signInModal = useDisclosure();

  return (
    <div style={{ height: '100vh', backgroundColor: 'white' }}>
      <Box
        zIndex="99"
        className={classNames(
          bg,
          color,
          'fixed inset-0 bg-br text-white h-screen'
        )}
        style={{
          paddingTop: '3.75rem',
          height: '100%',
          overflow: 'scroll',
          overscrollBehavior: 'contain',
          position: 'fixed',
        }}
      >
        {/* <header className="text-right pt-5 px-6 flex justify-between items-center">
        <a href="/" className="md:ml-0">
          <SvgLogo
            alt="1TM Logo"
            className="inline"
            height="60px"
            width="85px"
          />
        </a>
        <span
          aria-hidden="true"
          onClick={() => {
            setDisplayPanel(false);
          }}
        >
          <SvgClose alt="close" className="cursor-pointer inline text-lg" />
        </span>
      </header> */}
        <ul className="font-black mt-10">
          {siteItems.map((sitem, itemKey) => {
            const liKey = `${itemKey}_${sitem.id}`;
            const isNotify =
              router.pathname.includes('/notification') &&
              sitem.path.includes('/notification');
            return (
              <li
                className={classNames(
                  router.pathname === sitem.path && 'text-gray-500important',
                  isNotify && 'text-gray-500important',
                  isLessThan370 && 'py-0.5',
                  mainNavClose && 'hidden',
                  'px-6'
                )}
                key={liKey}
              >
                {sitem.type === 'link' && !sitem.isLogin && (
                  <a
                    href={sitem.path}
                    className={classNames(
                      color,
                      'block mb-6 font-medium text-base',
                      router.pathname === sitem.path && 'opacity-60',
                      isNotify && 'opacity-60',
                      isLessThan370 && 'text-xs'
                    )}
                    onClick={() => {
                      setDisplayPanel(false);
                    }}
                    aria-hidden="true"
                  >
                    {sitem.icon && <span className="pr-3">{sitem.icon}</span>}
                    {t(sitem.displayName)}
                    {sitem.displayName === 'NOTIFICATIONS' && (
                      <span
                        className={classNames(
                          isNotify && 'opacity-60',
                          'bg-outrageousOrange-500 ml-6 font-bold text-sm py-1 px-2 text-white rounded-full'
                        )}
                      >
                        {badgeNumber}
                      </span>
                    )}
                  </a>
                )}
                {sitem.type === 'click' && !sitem.isLogin && (
                  <div
                    role="button"
                    tabIndex={0}
                    aria-hidden="true"
                    className="block mb-7 relative"
                    onClick={() => {
                      panelHandle(sitem.displayName);
                      setMainNavClose(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        panelHandle(sitem.displayName);
                        setMainNavClose(true);
                      }
                    }}
                  >
                    {sitem.icon && <span className="pr-3">{sitem.icon}</span>}
                    <span
                      aria-hidden="true"
                      className="font-medium text-gray-900"
                    >
                      {t(sitem.displayName)}
                    </span>
                    {siteSubItems[sitem.displayName] && (
                      <SvgArrowRight
                        style={{ width: '20px', height: '20px' }}
                        className="absolute right-0 top-0 bottom-0 margin-auto"
                      />
                    )}
                  </div>
                )}
              </li>
            );
          })}
          <li
            className={`${isLessThan370 && 'py-0.5'} ${
              !mainNavClose && 'hidden'
            } px-6`}
          >
            <div
              role="button"
              tabIndex={0}
              onClick={() => setMainNavClose(false)}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  setMainNavClose(false);
                }
              }}
            >
              <SvgArrowLeft
                style={{ width: '20px', height: '20px' }}
                className="margin-auto"
              />
            </div>
            {siteSubItems[displaySubItems]?.map((item, key) => {
              const mkey = `m-${key}`;
              return (
                <>
                  {item.html && !item.notMobile && !item.disabled ? (
                    <div
                      key={mkey}
                      role="button"
                      tabIndex={0}
                      onClick={item.action}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          item.action();
                        }
                      }}
                      className="block px-4 py-2 dark:text-white cursor-pointer"
                    >
                      {item.html}
                    </div>
                  ) : (
                    <a
                      key={mkey}
                      className="block my-7 font-medium text-gray-900"
                      href={item.link}
                    >
                      {item.title}
                    </a>
                  )}
                </>
              );
            })}
          </li>
        </ul>
        {!isLoggedIn && (
          <div className="flex flex-col justify-center w-full absolute bottom-6 px-6">
            <button
              className="bg-denim-500 text-white px-8 py-2.5 rounded-full text-center font-semibold text-base mb-3"
              type="button"
              style={{
                lineHeight: '20px',
              }}
              onClick={() => {
                window.history.replaceState('', '', '?signin=false');
                signInModal.onOpen();
              }}
            >
              Get Started
            </button>
            <button
              type="button"
              onClick={() => {
                window.history.replaceState('', '', '?signin=true');
                signInModal.onOpen();
              }}
              className="text-base text-denim-500 font-medium"
            >
              Sign In
            </button>
            <SignInModal
              modal={{
                ...signInModal,
                onClose: () => {
                  router.push({ query: { ...router.query, page: '' } });
                  signInModal.onClose();
                },
              }}
            />
          </div>
        )}
        {isLoggedIn && (
          <ul className="mt-4 pt-4 absolute bottom-16 w-screen">
            {navItems.map((item, itemKey) => {
              const liKey = `${itemKey}_${item.id}`;
              // if (item['displayName'] === 'Create a Studio Post') return null;
              return (
                <li
                  className={classNames(isLessThan370 ? 'px-8' : 'px-11')}
                  key={liKey}
                >
                  {item.type === 'link' && (
                    <a
                      href={item.path}
                      className={classNames(
                        isLessThan370 ? 'block text-xs' : 'block'
                      )}
                      onClick={() => {
                        setDisplayPanel(false);
                      }}
                      aria-hidden="true"
                    >
                      {t(item.displayName)}
                    </a>
                  )}
                  {item.type === 'desktop-only' && (
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          isLessThan370 ? 'pr-2 text-xs' : 'pr-3',
                          'text-gray-400'
                        )}
                        onClick={item.action}
                        aria-hidden="true"
                      >
                        {t(item.displayName)}
                      </span>
                      <div
                        className={classNames(
                          isLessThan370
                            ? 'h-6 pt-1 pb-1.5 px-3'
                            : 'h-7 pt-1.5 pb-1 px-3',
                          'text-xs rounded-sm text-gray-700 bg-gray-400'
                        )}
                      >
                        Desktop only
                      </div>
                    </div>
                  )}
                  {item.type === 'html' && (
                    <span
                      onClick={item.action}
                      aria-hidden="true"
                      className={`${isLessThan370 ? 'text-xs' : 'pr-3'} block`}
                    >
                      {item.html}
                    </span>
                  )}
                  {item.type === 'click' && (
                    <span
                      onClick={item.action}
                      aria-hidden="true"
                      className={`${isLessThan370 ? 'text-xs' : 'pr-3'} block ${
                        item.displayName === 'Sign Out' && 'text-blue-500'
                      }`}
                    >
                      {t(item.displayName)}
                    </span>
                  )}
                  {item.type === 'signout' && (
                    <div
                      role="button"
                      tabIndex={0}
                      style={{ padding: '10px 0' }}
                      className="border border-blue-500 rounded-full text-blue-500 text-center text-sm font-semibold"
                      onClick={item.action}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          item.action();
                        }
                      }}
                    >
                      Sign out
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </Box>
    </div>
  );
};

export default NavigationPanel;
