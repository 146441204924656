import * as React from 'react';
import { Svg } from '../svg';

function SvgSite({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.667 10c0-4.6 3.725-8.333 8.325-8.333 4.608 0 8.342 3.733 8.342 8.333s-3.734 8.333-8.342 8.333c-4.6 0-8.325-3.733-8.325-8.333zm11.642-3.333h2.458A6.691 6.691 0 0012.159 3.7c.5.925.883 1.925 1.15 2.967zM10 3.367a11.74 11.74 0 011.592 3.3H8.409c.358-1.192.9-2.3 1.591-3.3zM3.334 10c0 .575.083 1.133.216 1.667h2.817c-.067-.55-.117-1.1-.117-1.667s.05-1.117.117-1.667H3.55A6.868 6.868 0 003.334 10zm.9 3.333h2.458a13.04 13.04 0 001.15 2.967 6.656 6.656 0 01-3.608-2.967zm0-6.666h2.458c.267-1.042.65-2.042 1.15-2.967a6.656 6.656 0 00-3.608 2.967zM10 16.633c-.691-1-1.233-2.108-1.591-3.3h3.183a11.74 11.74 0 01-1.592 3.3zm-1.95-4.966h3.9c.075-.55.134-1.1.134-1.667s-.059-1.125-.134-1.667h-3.9c-.075.542-.133 1.1-.133 1.667s.058 1.117.133 1.667zM12.16 16.3c.5-.925.883-1.925 1.15-2.967h2.458a6.691 6.691 0 01-3.608 2.967zM13.75 10c0 .567-.05 1.117-.116 1.667h2.816A6.87 6.87 0 0016.667 10a6.87 6.87 0 00-.217-1.667h-2.816c.066.55.116 1.1.116 1.667z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgSite);
export default ForwardRef;
