import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import SvgLogo from 'src/components/icons/logo';
import SvgLogo2 from 'src/components/icons/logo-2';
import SvgEmailVerification from 'src/components/icons/email-verification';
import { useIsMobile, useIsTablet } from 'src/hooks/use-is-mobile';
import { useRouter } from 'next/router';
import { useResendEmail } from 'src/app/auth/use-auth';
import { useState, useEffect } from 'react';

const EmailVerificationModal = ({ modal }: { modal?: any }) => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const router = useRouter();

  const { mutateAsync: resendEmail } = useResendEmail();

  const userEmail = { email: router.query?.email };

  const [modalPage, setModalPage] = useState('emailIsNotVerified');
  const [timerSeconds, setTimerSeconds] = useState(30);

  const Timer = () => {
    let seconds = 30;
    setTimerSeconds(seconds);

    const timer = setInterval(() => {
      if (seconds > 0) {
        seconds -= 1;
        setTimerSeconds(seconds);
      } else {
        clearInterval(timer);
      }
    }, 1000);
  };

  useEffect(() => {
    if (modalPage === 'pleaseCheckYourEmail') {
      Timer();
    }
  }, [modalPage]);

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={() => {
        setModalPage('emailIsNotVerified');
        modal.onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent maxW="100vw" w="100vw" h="100vh" borderRadius="none">
        <ModalCloseButton
          color={isMobile ? 'gray.600' : 'white'}
          _focus={{ outline: 'none' }}
        />
        <ModalBody p={0}>
          <div
            className="w-full h-screen"
            style={{
              display: 'grid',
              gridTemplateColumns: '100%',
              gridTemplateRows: 'auto 1fr auto',
              background: 'url(/images/assets/login/signInBG.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              overflowY: 'scroll',
            }}
          >
            <div className="bg-gray-100 sm:bg-transparent px-9 py-5 sm:py-1.5 flex justify-center sm:justify-start">
              <div className="hidden sm:block">
                <SvgLogo alt="1TM Logo" className="text-6xl" color="#E4EEFF" />
              </div>
              <div className="block sm:hidden">
                <SvgLogo2
                  alt="1TM Logo"
                  className="text-xl text-black"
                  color="#E4EEFF"
                />
              </div>
            </div>
            <div>
              <div className="w-full h-full flex justify-center items-center px-0 sm:px-9 bg-gray-100 sm:bg-transparent">
                <div
                  className="relative w-full h-full sm:h-auto rounded-none sm:rounded-lg bg-gray-100 max-h-full px-12 sm:px-16 py-9 flex flex-col justify-center items-center max-w-full lg:max-w-4xl"
                  style={{
                    minHeight: '590px',
                    boxShadow: isTablet
                      ? 'none'
                      : '0px 4px 30px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  {modalPage === 'pleaseCheckYourEmail' ? (
                    <div className="flex flex-col	items-center">
                      <SvgEmailVerification fontSize="80px" mb={1} />
                      <h1 className="mb-1 text-xl lg:text-4xl text-gray-900">
                        Verification Email Resent!
                      </h1>
                      <p className="mb-6 lg:mb-8 text-gray-700 text-xs ls:text-sm max-w-xs lg:max-w-md text-center">
                        We have resent the verification email to{' '}
                        <a className="text-blue-500">{router.query?.email}</a>{' '}
                        please check your email for further Instructions.
                      </p>
                      {timerSeconds > 0 ? (
                        <Button
                          rounded="ellipse"
                          marginBottom={3}
                          data-testid="resend-pwd"
                          width="max-content"
                          disabled
                          px="32px"
                          py="10px"
                        >
                          <span className="text-sm lg:text-base">
                            Resend Email ({timerSeconds} sec.)
                          </span>
                        </Button>
                      ) : (
                        <Button
                          rounded="ellipse"
                          marginBottom={3}
                          data-testid="resend-pwd"
                          width="max-content"
                          onClick={async () => {
                            await resendEmail(userEmail);
                            Timer();
                          }}
                          px="32px"
                          py="10px"
                        >
                          <span className="text-sm lg:text-base">
                            Resend Email
                          </span>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-col	items-center">
                      <SvgEmailVerification fontSize="80px" mb={1} />
                      <h1 className="mb-1 text-xl lg:text-4xl text-gray-900">
                        Your Email is not verified yet
                      </h1>
                      <p className="mb-6 lg:mb-8 text-gray-700 text-xs ls:text-sm max-w-xs lg:max-w-md text-center">
                        Please check the verification email in your mailbox{' '}
                        <a className="text-blue-500">{router.query?.email}</a>{' '}
                        and click “Activate account” button to activate your
                        account.
                      </p>

                      <Button
                        rounded="ellipse"
                        marginBottom={3}
                        data-testid="resend-pwd"
                        width="max-content"
                        onClick={async () => {
                          await resendEmail(userEmail);
                          setModalPage('pleaseCheckYourEmail');
                        }}
                        px="32px"
                        py="10px"
                      >
                        <span className="text-sm lg:text-base">
                          Resend Email
                        </span>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center flex-col text-xs sm:text-sm pb-6 sm:pb-10 pt-4 px-6 sm:px-9 bg-gray-100 sm:bg-transparent text-gray-600 sm:text-gray-300">
              <p
                style={{ color: '#E4EEFF' }}
                className="italicpb-6 text-sm font-bold mx-auto"
              >
                Connecting Creativity and People, Making ideas happen!
              </p>
              <p
                style={{ color: '#E4EEFF' }}
                className="text-sm font-medium mx-auto"
              >
                Copyright @ 2022 1TM
              </p>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EmailVerificationModal;
