import { HStack, StackProps } from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';

type HorizontalScrollProps = {
  className?: string;
  children: ReactNode;
} & StackProps;

function HorizontalScroll(
  { className = '', children, ...props }: HorizontalScrollProps,
  ref
) {
  return (
    <HStack
      ref={ref}
      className={`h-scroll ${className}`}
      whiteSpace="nowrap"
      {...props}
    >
      {children}
      <style jsx global>{`
        .h-scroll::-webkit-scrollbar {
          display: none;
        }
        .h-scroll {
          overflow-x: scroll;
          scrollbar-width: none;
          -webkit-overflow-scrolling: touch;
        }
      `}</style>
    </HStack>
  );
}

export const HorizontalScroller = forwardRef<
  HTMLDivElement,
  HorizontalScrollProps
>(HorizontalScroll);
