import { AlgoliaFilterType, SortType, ResultType, SearchState } from './types';

export const FILTER_TYPES = AlgoliaFilterType;
export const SORT_TYPES = SortType;

export const INITIAL_RESULT_STATE: ResultType = {
  [FILTER_TYPES.CREATIONS]: [],
  [FILTER_TYPES.USERS]: [],
  [FILTER_TYPES.SPACES]: [],
  [FILTER_TYPES.COLLECTIONS]: [],
  [FILTER_TYPES.TOPICS]: [],
  [FILTER_TYPES.CREATIVE_ASSETS]: [],
};

export const INITIAL_STATE: SearchState = {
  isFetching: true,
  isError: false,
  isSearchLite: false,

  searchTerm: '',
  genre: 'CREATIONS',

  result: INITIAL_RESULT_STATE,
  totalResult: 0,
};
