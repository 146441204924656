import { useMemo } from 'react';
import Link from 'next/link';

interface Item {
  title: string;
  description: string;
  posts: number;
  followers: number;
  imageUrl: string;
  isFollow: boolean;
}
interface Props {
  data: Item;
  className?: string;
}

const TribeTag = ({ data, className = '' }: Props) => {
  const { title, posts } = data;
  const link = useMemo(() => title.replace(/ /g, '-'), [title]);
  const postAmount = useMemo(() => new Intl.NumberFormat().format(posts), [
    posts,
  ]);
  return (
    <Link prefetch={false} href={`/feed/${link}`}>
      <section
        className={`tribe-tag-card cursor-pointer border rounded-3xl border-gray-300 p-8 w-full bg-white ${className}`}
      >
        <h4 className="font-bold text-xl">#{title}</h4>
        <div className="flex justify-between items-center">
          <div className="text-sm mt-3 desc leading-4.5">
            <div className="font-medium">
              {postAmount}
              <span className="ml-1 mr-4 pr-4">
                {posts > 1 ? 'creations' : 'creation'}
              </span>
            </div>
          </div>
        </div>
      </section>
    </Link>
  );
};
export default TribeTag;
