import * as React from 'react';
import { Svg } from '../svg';

function SvgMatterport({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M20.72.094L12.25 2.47 3.78.094l-3.389.94v21.86l3.388.94 8.47-2.375 8.472 2.376 3.388-.941V1.034L20.72.095zM17.333 16.4l-5.082-1.412L7.168 16.4V7.53l5.082 1.41 5.082-1.41v8.87z"
        fill="#6D6D6D"
      />
      <path
        d="M12.25 4.353v4.611L3.78 6.588v17.27l-3.389-.94V1.057L12.25 4.354zm5.082 13.953L12.25 16.87v4.612l8.47 2.377V6.588l-3.388.941v10.777z"
        fill="#0E0708"
      />
      <path
        d="M20.72.094l3.389.94-11.86 3.319L.392 1.035 3.78.094l8.47 2.376L20.722.094zm-8.47 14.87l5.082 1.436v1.905L12.25 16.87l-5.082 1.435V16.4l5.082-1.436z"
        fill="#999"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgMatterport);
export default ForwardRef;
