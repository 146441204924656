import {
  Grid,
  GridItem,
  Box,
  Text,
  Image,
  Flex,
  Circle,
} from '@chakra-ui/react';
import { Avatar } from 'components/avatar';
import dayHepler from 'src/heplers/day';
import { imageOptimize } from 'src/service/image';
import SvgArrowGrey from 'src/components/icons/arrow/arrow-right-grey';
import { useMemo } from 'react';
import { GetLink } from './getsubtext';

export function NotificationCard(props) {
  const notif = props?.notif;
  let notifDetails = {
    gridPadding: 0,
    avatarMargin: '2px 0.5rem 2px 4px',
    subTextMargin: '0px',
  };
  if (props.cardCenter) {
    notifDetails = {
      gridPadding: 2,
      avatarMargin: '0 4px',
      subTextMargin: '3px',
    };
  }

  const notifClicked = (data) => {
    if (props.clicked) {
      props.clicked(data);
    }
  };
  // const showAvatar = () => {
  //   if (notif?.notifier?.profileImgURL || notif?.headPicURL)
  //     return notif?.notifier?.profileImgURL || notif?.headPicURL;
  //   if (notif?.inviter) return notif?.inviter?.profileImgURL;
  //   return '';
  // };
  const notifier = useMemo(
    () => ({
      profileImgURL: notif.space?.avatarImgURL,
      ...notif.notifier,
    }),
    [notif]
  );
  return (
    <GetLink notif={notif} clicked={notifClicked}>
      <Grid w="100%" gridTemplateRows="repeat(2, 1fr)" gap={0}>
        <GridItem
          rowSpan={2}
          display="flex"
          p="16px"
          alignItems="center"
          justifyContent="space-between"
          //   maxWidth={props.cardCenter ? '' : '87%'}
          width="100%"
          className="relative"
        >
          <Box
            // w={props.boxWidth}

            w={
              notif?.thumbnailURL
                ? { base: '80%', sm: '70%' }
                : { base: '100%' }
            }
            color="white"
          >
            <Flex
              alignItems="center"
              //   w={{ base: '100%', sm: '80%', lg: '100%' }}
            >
              {/* {!notif?.read && ( */}
              <>
                <Flex height="fit-content">
                  <Flex alignItems="center" mr="14px">
                    <Circle bg={!notif?.read ? '#F63' : ''} size="6px" />
                  </Flex>
                  <Box ml="4px" fontSize="1.2rem">
                    <Avatar
                      name=""
                      data-testid="user avatar"
                      bg="$mode.400"
                      width={40}
                      height={40}
                      src={imageOptimize({
                        url: notifier?.profileImgURL || notif?.headPicURL,
                        imgContainer: 'avatar',
                      })}
                      author={notifier}
                    />
                  </Box>
                </Flex>
                <Box
                  marginLeft="14px"
                  marginTop="6px"
                  noOfLines={3}
                  maxHeight="5rem"
                  textStyle="labelLight"
                  fontSize={{ base: '14px' }}
                  lineHeight="20px"
                  fontWeight="500"
                  color="$mode.500"
                  className={!notif?.read ? '' : 'notification-title-unread'}
                  maxWidth={`${props.cardCenter ? '100%' : '350px'}`}
                  display="inline"
                  dangerouslySetInnerHTML={{
                    __html: notif.copy
                      ? notif.copy
                      : `You are invited to a Space! <b>${
                          notif?.inviter?.firstName || notif?.inviter?.username
                        }</b> has invited to join <b>${notif?.space?.title}</b>
                      as a team member.`,
                  }}
                />
                <style jsx global>{`
                  b {
                    font-weight: 500;
                  }
                `}</style>
              </>
              {/* )} */}
            </Flex>
            <Box ml="79px">
              <Text
                marginLeft={notifDetails.subTextMargin}
                noOfLines={3}
                maxHeight="5rem"
                textStyle="labelLight"
                color="$mode.300"
                fontSize={{ base: '12px' }}
                lineHeight="18px"
                fontWeight="500"
                display="inline"
                dangerouslySetInnerHTML={{
                  __html: dayHepler.fromNow(notif?.createdAt),
                }}
              />
            </Box>
          </Box>

          <Box
            display={{ base: 'none', sm: 'block' }}
            color="white"
            overflow="hidden"
            w={notif?.thumbnailURL ? '20%' : '10%'}
            height="100%"
          >
            {notif?.thumbnailURL && (
              <Flex justifyContent="flex-end" height="100%">
                <Image
                  src={imageOptimize({
                    url: notif?.thumbnailURL,
                    imgContainer: 'avatar',
                  })}
                  w="72px"
                  height="48px"
                  borderRadius="4px"
                  objectFit="cover"
                />
              </Flex>
            )}
          </Box>
          <Flex
            display={{ base: 'flex', sm: 'none' }}
            justifyContent="flex-end"
            mt="25px"
            mr="5px"
            h="full"
          >
            <SvgArrowGrey
              color={
                notif?.thumbnailURL && props.cardCenter ? '#242526' : '#9B9FA4'
              }
            />
          </Flex>
        </GridItem>
        <GridItem>
          <Box py={0}>
            {notif?.date && (
              <div className="text-xs font-normal text-gray-400 dark:text-white">
                {dayHepler.fromNow(notif?.createdAt)}
              </div>
            )}
          </Box>
        </GridItem>
      </Grid>
    </GetLink>
  );
}
