import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Image as ChakraImage,
  Textarea,
  IconButton,
  CircularProgress,
  // Center,
  //  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
// import { useQueryClient } from 'react-query';
import { OneTmModal } from 'src/components/1tm-modal';

// import SvgAddImage from 'src/components/icons/add-image';
// import SvgCamera from 'src/components/icons/camera';
// import SvgUnsplash from 'src/components/icons/unsplash';
// import SvgAddMediaIcons from 'src/components/icons/add-media-icons';
// import SvgVideo from 'src/components/icons/video';
import SvgAdd from 'src/components/icons/add';
import SvgCloseModal from 'src/components/icons/close-modal';

// import SvgImage from 'src/components/icons/post-image';
// import SvgUnsplash2 from 'src/components/icons/post-unsplash';
// import SvgMedia from 'src/components/icons/post-media';

import SvgArrowLeft from 'src/components/icons/arrow-left';
import SvgArrowRight from 'src/components/icons/arrow-right';
import { CustomToast } from 'src/components/toast';

import SvgCancel from 'src/components/icons/cancel';

import SvgPlayButton from 'src/components/icons/play-button';
// import SvgNFT from 'src/components/icons/nft';
import SvgNFTholder from 'src/components/icons/nft-placeholder';
// import ShowMoreText from 'react-show-more-text';

import {
  useImageCropper,
  ImageCropModal,
} from 'src/components/image-crop/image-crop';
import { ImageUploader } from 'src/components/image-uploader';
import { MediaPostImageUploader } from 'src/components/image-uploader/media-post-image-uploader';
import { UnsplashSelect } from 'src/components/unsplash/unsplash-select';
// import { CacheKey } from 'src/constants/cache-key';
import { useUploadImage } from 'src/hooks';
import { useIsTablet } from 'src/hooks/use-is-mobile';
import { EDITOR_CONFIG } from 'src/lib/editor/config';
import {
  ImgUploadAreaType,
  ImgUploadType,
  imageOptimize,
} from 'src/service/image';
// import { useRouter } from 'next/router';
import ReactPlayer from 'react-player';
import WarningModalWithoutCancel from 'src/app/article/warning-modal-without-cancel';
// import { UploadedImage } from './uploaded-image';
import Carousel from 'nuka-carousel';

import cn from 'classnames';
import styles from 'src/lib/editor/Post_editor.module.scss';
import ReactCrop from 'react-image-crop';

export function CoverImageUploader(props: {
  areaType: ImgUploadAreaType;
  imgType: ImgUploadType;
  coverImgURL?: string;
  setUploadedFiles?: any;
  uploadedFiles?: Array<any>;
  setMediaTitle?: any;
  mediaTitle?: Array<any>;
  swapUploadedFiles?: Function;
  setCoverImage?: any;
  requiredFieldsWarningStatus?: any;
  currImgId?: (e: any) => void;
  isMedia?: boolean;
  readOnly?: boolean;
}) {
  // const queryClient = useQueryClient();
  const isTablet = useIsTablet();
  const unsplashModal = useDisclosure();
  const uploadModal = useDisclosure();
  const lightroomModal = useDisclosure();
  const cropModal = useDisclosure();
  // const showImageAndVideoModal = useDisclosure();

  const warningModal = useDisclosure();
  const [warningModalText, setWarningModalText] = useState([]);
  const [warningModalTitle, setWarningModalTitle] = useState('');

  // const [showModalFile, setShowModalFile] = useState(null);
  const [lastUploadedFile, setLastUploadedFile] = useState(null);
  const [videoURL, setvideoURL] = useState(null);
  const [progress, setProgress] = useState<ProgressEvent | null>(null);
  const uploaderCancelRef = useRef<any>();
  const [isGif, setIsGif] = useState(false);

  const [imgURL, setImgURL] = useState(imageOptimize(props.coverImgURL));
  const [carouselIdx, setCarouselIndx] = useState<number>(0);
  const [currFiles, setCurrFiles] = useState(props.uploadedFiles || []);
  const [currMedia, setCurrMedia] = useState<any>(props.mediaTitle || '');
  const [imageToCrop, setImageToCrop] = useState<any>(null);
  const [isNotResizable, setIsNotResizable] = useState<boolean>(true);
  const toast = useToast();
  const { mutateAsync: uploadCover, isLoading: isUploading } = useUploadImage(
    {
      areaType: props.areaType,
      imgType: props.imgType,
      updateProgress: setProgress,
      getCancelFunction: (cancelFunction) => {
        uploaderCancelRef.current = cancelFunction;
      },
    },
    (response) => {
      // const updater = (prevCache) => ({
      //   ...prevCache,
      //   coverImgURL: response.imgURL,
      // });
      setImgURL(imageOptimize(response.imgURL));
      if (!props.isMedia) {
        props?.setCoverImage(response.imgURL);
      }

      if (props.isMedia && props.setUploadedFiles) {
        props.setUploadedFiles([
          ...currFiles,
          {
            type: 'image',
            id: generateUniqueID(),
            imgURL: response.imgURL,
            file: lastUploadedFile,
            title: null,
            description: null,
          },
        ]);
      }
      // queryClient.setQueryData([CacheKey.creation, props.id], updater);
    }
  );

  const { cropper, imgRef } = useImageCropper();

  const dropzone = useDropzone({
    noClick: true,
    onDrop: async (files) => {
      setIsGif(false); // reset gif check
      if (files[0]) {
        const newImg = new Image();
        // const reader = new FileReader();
        // reader.readAsDataURL(files[0]);
        // reader.onload = function (e) {
        //   newImg.src = `${e.target.result}`;
        //   setImageToCrop(`${e.target.result}`);
        // };
        newImg.src = URL.createObjectURL(files[0]);
        setImageToCrop(`${URL.createObjectURL(files[0])}`);
        newImg.onload = function () {
          if (files[0].type === 'image/webp' || files[0].type === 'image/gif') {
            setIsNotResizable(true);
            cropModal.onOpen();
            setLastUploadedFile(files[0]);
            if (files[0]?.type !== 'image/gif') return; // check if is gif
            setIsGif(true);
          } else if (newImg.height < 600 || newImg.width < 900) {
            setWarningModalTitle('Image is too small');
            setWarningModalText([
              'Image should be larger than 900x600 px.',
              'Please resize and try again, or select another image.',
            ]);
            warningModal.onOpen();
          } else {
            setIsNotResizable(false);
            cropModal.onOpen();
            setLastUploadedFile(files[0]);
          }
        };
      }
    },
  });

  const swapUploadedFiles = (index) => {
    if (index === 0) return;
    const file = mediaAsset[index];
    const updatedIndex = index - 1;
    const updatedUploadedFiles = [...mediaAsset];
    updatedUploadedFiles[index] = updatedUploadedFiles[updatedIndex];
    updatedUploadedFiles[updatedIndex] = file;
    props.setUploadedFiles(updatedUploadedFiles.filter((n) => n));
  };

  useEffect(() => {
    if (!props.readOnly) {
      setCurrFiles(props.uploadedFiles);
      setCurrMedia(props.mediaTitle);
    }
  }, [props.uploadedFiles]);

  const cropSave = async (file) => {
    if (
      file.type === 'image/png' ||
      file.type === 'image/gif' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/webp'
    ) {
      if (lastUploadedFile?.size > 5000000) {
        setWarningModalTitle('Image file size is too large');
        setWarningModalText([
          'Image file size should be under 5MB.',
          'Please check file size and try again.',
        ]);
        warningModal.onOpen();
      } else {
        uploadModal.onClose();
        const formData = new FormData();
        formData.set('file', file);
        const uploadedImage = await uploadCover({ data: formData });
        if (props.currImgId) {
          props?.currImgId(uploadedImage);
        }
      }
    } else {
      setWarningModalTitle('Image format not supported');
      setWarningModalText([
        'We supported images in JPEG/GIF/PNG/WebP.',
        'Please convert the image format and try again, or select another image in the supported formats.',
      ]);
      warningModal.onOpen();
    }
  };

  const { t } = useTranslation();

  function checkVidUrl(data, isVideo?: boolean) {
    if (data?.startsWith('https://www.youtube')) {
      if (isVideo) {
        return true;
      }
      return 'Youtube';
    }
    if (data?.startsWith('https://vimeo')) {
      if (isVideo) {
        return true;
      }
      return 'Vimeo';
    }
    if (data?.startsWith('https://open.spotify')) {
      if (isVideo) {
        return false;
      }
      return 'Spotify';
    }
    if (data?.startsWith('https://soundcloud')) {
      if (isVideo) {
        return false;
      }
      return 'SoundCloud';
    }
    return null;
  }

  const padNum = (num) => {
    if (num < 10 && num !== 0) {
      return `0${num.toString()}`;
    }
    return num.toString();
  };

  const mediaAsset = currFiles.length > 0 ? [...currFiles] : [''];

  const [uploadedFileTitle, setUploadedFileTitle] = useState('');
  const [uploadedFileDescription, setUploadedFileDescription] = useState('');
  const [uploadedCarouselTitle, setUploadedCarouselTitle] = useState('');
  const [postLoad, setPostLoad] = useState<boolean>(false);

  const [fileTitle, setFileTitle] = useState('');
  const [fileDesc, setFileDesc] = useState('');
  const [carouselTitle, setCarouselTitle] = useState('');

  useEffect(() => {
    if (props.readOnly) {
      setCarouselTitle(currMedia || '');
      setFileTitle(mediaAsset[carouselIdx]?.title || '');
      setFileDesc(mediaAsset[carouselIdx]?.description || '');
    } else {
      setUploadedCarouselTitle(currMedia || '');
      setUploadedFileTitle(mediaAsset[carouselIdx]?.title || '');
      setUploadedFileDescription(mediaAsset[carouselIdx]?.description || '');
    }
  }, [carouselIdx]);

  useEffect(() => {
    if (!props.readOnly) {
      if (postLoad) {
        setAutoSave();
      }
      setFileTitle(mediaAsset[carouselIdx]?.title || '');
      setFileDesc(mediaAsset[carouselIdx]?.description || '');
      setCarouselTitle(currMedia || '');
      setPostLoad(true);
    }
  }, [uploadedFileTitle, uploadedFileDescription, uploadedCarouselTitle]);

  useEffect(() => {
    if (mediaAsset[0].type && !props.readOnly) {
      setCarouselIndx(mediaAsset.length - 1);
    }
  }, [mediaAsset.length]);

  function setAutoSave() {
    if (currFiles.length > 0) {
      const filesByDescriptionAndTitle = [...currFiles];
      filesByDescriptionAndTitle[carouselIdx].title = uploadedFileTitle;
      filesByDescriptionAndTitle[
        carouselIdx
      ].description = uploadedFileDescription;
      if (props.setUploadedFiles && !props.readOnly) {
        props.setUploadedFiles(filesByDescriptionAndTitle);
      }
      if (props.setMediaTitle && !props.readOnly) {
        props.setMediaTitle(uploadedCarouselTitle);
      }
    }
  }

  const deleteMediaAsset = (data) => {
    for (let i = 0; i < currFiles.length; i++) {
      if (currFiles[i].id === data.id) {
        const uploadedFilesArray = [...currFiles];
        uploadedFilesArray.splice(i, 1);
        props.setUploadedFiles(uploadedFilesArray);
      }
    }
  };

  const imageCropRendererAspect = useMemo(
    () => (
      <ImageCropModal
        modal={cropModal}
        onSave={() => {
          if (isNotResizable) {
            cropSave(lastUploadedFile);
          } else {
            cropSave(cropper.croppedImage);
          }
        }}
        content={{ maxW: 1020, maxH: '100%' }}
        isGif={isGif}
      >
        {isNotResizable ? (
          <ChakraImage
            src={imageToCrop}
            crossOrigin="anonymous"
            style={{ maxHeight: '650px' }}
          />
        ) : (
          <ReactCrop
            crop={cropper.cropConfig}
            aspect={16 / 9}
            onComplete={(complete) => cropper.cropImage(complete)}
            onChange={(change) => cropper.setCropConfig(change)}
          >
            <ChakraImage
              ref={imgRef}
              src={imageToCrop}
              crossOrigin="anonymous"
              style={{ maxHeight: '650px' }}
              onLoad={(e) => {
                cropper.onImageLoad(e);
              }}
            />
          </ReactCrop>
        )}
      </ImageCropModal>
    ),
    [imageToCrop, cropModal]
  );

  const imageCropRenderer = useMemo(
    () => (
      <ImageCropModal
        modal={cropModal}
        onSave={() => {
          if (isNotResizable) {
            cropSave(lastUploadedFile);
          } else {
            cropSave(cropper.croppedImage);
          }
        }}
        content={{ maxW: 1020, maxH: '100%' }}
        isGif={isGif}
      >
        {isNotResizable ? (
          <ChakraImage
            src={imageToCrop}
            crossOrigin="anonymous"
            style={{ maxHeight: '650px' }}
          />
        ) : (
          <ReactCrop
            crop={cropper.cropConfig}
            onComplete={(complete) => cropper.cropImage(complete)}
            onChange={(change) => cropper.setCropConfig(change)}
          >
            <ChakraImage
              ref={imgRef}
              src={imageToCrop}
              crossOrigin="anonymous"
              style={{ maxHeight: '650px' }}
              onLoad={(e) => {
                cropper.onImageLoad(e);
              }}
            />
          </ReactCrop>
        )}
      </ImageCropModal>
    ),
    [imageToCrop, cropModal]
  );

  const imageRender = (d) => {
    return (
      <div
        style={{
          height: `${isTablet ? 'inherit' : '100%'}`,
          aspectRatio: '16/9',
          borderRadius: `${props.readOnly ? '0px' : '16px'}`,
        }}
        className="m-auto relative overflow-hidden w-full"
      >
        {d.type === 'image' && (
          <AspectRatio ratio={16 / 9} h="inherit">
            <ChakraImage
              src={d.imgURL}
              width="auto"
              m="auto"
              style={{ objectFit: 'contain' }}
            />
          </AspectRatio>
        )}
        {d.type === 'nft' && !d.imgURL && (
          <Box position="relative" aspectRatio="16/9" height="100%">
            <div
              style={{ height: '100%', aspectRatio: '1/1' }}
              className="bg-gray-100 m-auto"
            >
              <div
                style={{ height: '56px', width: '56px' }}
                className="rounded-full bg-white m-auto absolute inset-0"
              >
                <SvgNFTholder
                  style={{ height: 'inherit' }}
                  width="40px"
                  height="40px"
                  className="m-auto"
                />
              </div>
            </div>
          </Box>
        )}
        {d.type === 'nft' && d.imgURL && !d.imgURL.endsWith('.mp4') && (
          <AspectRatio ratio={16 / 9}>
            <ChakraImage
              src={d.imgURL}
              width={`${props.readOnly ? 'auto' : '100%'}`}
              m="auto"
              style={{ objectFit: 'contain' }}
            />
          </AspectRatio>
        )}
        {d.type === 'nft' && d.imgURL && d.imgURL.endsWith('.mp4') && (
          <Box position="relative" aspectRatio="16/9" height="100%">
            <ReactPlayer
              height="inherit"
              width="100%"
              loop
              playing
              url={d.imgURL}
            />
          </Box>
        )}
        {d.type === 'spotify' && (
          <iframe
            title={d.id}
            style={{ transform: 'scaleY(1.7) scaleX(2.5)' }}
            className="absolute top-0 bottom-0 right-0 left-0 m-auto pointer-events-none"
            src={`https://open.spotify.com/embed/track/${d.id}`}
            width="80"
            height="80"
            frameBorder="0"
            allow="encrypted-media"
          />
        )}
        {d.type === 'video' && (
          <Box position="relative" aspectRatio="16/9" height="100%">
            <ReactPlayer
              height="inherit"
              width="100%"
              playIcon={<SvgPlayButton w="28px" h="28px" />}
              light
              url={d.videoURL}
              controls
            />
          </Box>
        )}
      </div>
    );
  };

  if (props.isMedia) {
    return (
      <>
        <div
          className={`${cn(
            styles.flex13,
            !props.readOnly && 'styles.flex13_layout'
          )}`}
        >
          <div className={cn(styles.flex13_item)}>
            <div className={cn(styles.group, styles.group_layout1)}>
              <div>
                {!props.readOnly ? (
                  <Box
                    maxW="516px"
                    h="42px"
                    mb="43px"
                    position="relative"
                    mx="auto"
                  >
                    <Text
                      fontSize="12px"
                      textStyle="labelLight"
                      position="absolute"
                      zIndex="999"
                      right="10px"
                      display="flex"
                      alignItems="center"
                      h="100%"
                      color="$mode.500"
                      fontWeight="500"
                    >
                      {70 - uploadedCarouselTitle.length}
                    </Text>

                    <Textarea
                      fontSize="sm"
                      w="100%"
                      h="100%"
                      minH="auto"
                      py="10px"
                      pl="16px"
                      pr="36px"
                      fontWeight="500"
                      lineHeight="19px"
                      border="1px solid"
                      borderColor="$mode.200"
                      borderRadius="4px"
                      resize="none"
                      maxLength={70}
                      value={uploadedCarouselTitle}
                      onChange={(e) => {
                        setUploadedCarouselTitle(
                          (e.target as HTMLTextAreaElement).value
                        );
                      }}
                      _focus={{
                        border: '1px solid #E6E8E9',
                        '::placeholder': {
                          color: 'rgba(0,0,0,0)',
                        },
                      }}
                      placeholder="Input carousel title (optional)"
                    />
                  </Box>
                ) : (
                  <>
                    {carouselTitle && (
                      <div
                        style={{ maxWidth: '764px' }}
                        className="font-semibold mx-3 md:mx-auto text-gray-700 text-2xl mb-8 line-clamp-2 text-center w-auto"
                      >
                        {carouselTitle}
                      </div>
                    )}
                  </>
                )}
                <div
                  style={{
                    aspectRatio: '16 / 9',
                    maxWidth: `${props.readOnly ? '100%' : '96%'}`,
                  }}
                  className="m-auto"
                >
                  <Carousel
                    slideIndex={carouselIdx}
                    cellSpacing={32}
                    afterSlide={(slideIndex) => setCarouselIndx(slideIndex)}
                    withoutControls
                    defaultControlsConfig={{
                      containerClassName: 'upload-media',
                    }}
                  >
                    {mediaAsset.map((d, key) => {
                      const mkey = `ca_${key}`;
                      if (
                        progress &&
                        progress?.loaded / progress?.total !== 1
                      ) {
                        return (
                          <div
                            key={mkey}
                            style={{
                              height: 'auto',
                              borderRadius: '16px',
                              aspectRatio: '16/9',
                            }}
                            className="bg-gray-50 border m-auto relative overflow-hidden"
                          >
                            <CircularProgress
                              position="absolute"
                              className="inset-0 m-auto"
                              height="fit-content"
                              width="max-content"
                              size="24px"
                              isIndeterminate
                            />
                          </div>
                        );
                      }
                      if (d.type) {
                        return (
                          <div
                            key={mkey}
                            className="m-auto"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              if (props.readOnly) {
                                lightroomModal.onOpen();
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.code === 'Enter' && props.readOnly) {
                                lightroomModal.onOpen();
                              }
                            }}
                          >
                            {!props.readOnly && (
                              <div
                                role="button"
                                tabIndex={0}
                                className="absolute top-2 right-2 cursor-pointer z-50"
                                onClick={() => {
                                  deleteMediaAsset(d);
                                }}
                                onKeyDown={(e) => {
                                  if (e.code === 'Enter') {
                                    deleteMediaAsset(d);
                                  }
                                }}
                              >
                                <SvgCancel
                                  height="32px"
                                  width="32px"
                                  style={{ color: '#24252680' }}
                                />
                              </div>
                            )}
                            {imageRender(d)}
                          </div>
                        );
                      }

                      return (
                        <div
                          className={cn(styles.block6, styles.block6_layout)}
                        >
                          <div
                            className={cn(
                              styles.paragraph_body_box,
                              styles.paragraph_body_box_layout
                            )}
                          >
                            {/* <pre className={cn(styles.paragraph_body)}>
                              {
                                'Minimum dimensions: 900 x 600px\n(JPG, PNG, GIF format less than 5MB)'
                              }
                            </pre> */}
                          </div>
                          <div
                            style={{ height: 'fit-content' }}
                            className="m-auto absolute inset-0"
                          >
                            <div className={cn(styles.block233)}>
                              <div
                                className={cn(
                                  styles.block11,
                                  styles.block11_layout
                                )}
                              >
                                <div
                                  className={cn(styles.block11_item)}
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => {
                                    uploadModal.onOpen();
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                      uploadModal.onOpen();
                                    }
                                  }}
                                >
                                  <div
                                    className={cn(
                                      styles.text_body22,
                                      styles.text_body22_layout
                                    )}
                                  >
                                    add a media
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>

                {!props.readOnly && mediaAsset.length > 1 && (
                  <div className={cn(styles.block7, styles.block7_layout)}>
                    <div className={cn(styles.block7_item)}>
                      <IconButton
                        onClick={() => {
                          if (carouselIdx !== 0) {
                            swapUploadedFiles(carouselIdx);
                            setCarouselIndx(carouselIdx - 1);
                          }
                        }}
                        pointerEvents="auto"
                        className={`hover-arrow ${
                          carouselIdx === 0 && 'cursor-not-allowed'
                        }`}
                        zIndex="3"
                        bg={carouselIdx === 0 ? '#F3F3F4' : '#FFF'}
                        color={carouselIdx === 0 ? '#D3D6D8' : '#242526'}
                        aria-label="previous-page"
                        borderRadius={100}
                        border="1px solid #E6E8E9"
                        minWidth="20px"
                        height="20px"
                        margin="auto"
                        icon={<SvgArrowLeft fontSize="14px" />}
                      />
                    </div>
                    <div className={cn(styles.block7_item1)}>
                      <div
                        className={cn(styles.block10, styles.block10_layout)}
                      >
                        <div
                          className={cn(
                            styles.text_body13,
                            styles.text_body13_layout
                          )}
                          style={{
                            color: `${
                              mediaAsset.length > 1 ? '#9B9FA4' : '#d2d5d8'
                            }`,
                          }}
                        >
                          Change order
                        </div>
                      </div>
                    </div>
                    <IconButton
                      onClick={() => {
                        if (carouselIdx + 1 !== mediaAsset.length) {
                          swapUploadedFiles(carouselIdx + 1);
                          setCarouselIndx(carouselIdx + 1);
                        }
                      }}
                      pointerEvents="auto"
                      className={`hover-arrow ${
                        carouselIdx + 1 === currFiles.length &&
                        'cursor-not-allowed'
                      }`}
                      zIndex="3"
                      bg={
                        carouselIdx + 1 === mediaAsset.length
                          ? '#F3F3F4'
                          : '#FFF'
                      }
                      color={
                        carouselIdx + 1 === mediaAsset.length
                          ? '#D3D6D8'
                          : '#242526'
                      }
                      aria-label="next-page"
                      borderRadius={100}
                      border="1px solid #E6E8E9"
                      minWidth="20px"
                      height="20px"
                      margin="auto"
                      icon={<SvgArrowRight fontSize="14px" />}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {(mediaAsset.length > 1 || !props.readOnly) && (
            <div className={cn(styles.flex13_item)}>
              <div
                className={cn(
                  styles.flex14,
                  styles.flex14_layout,
                  `${props.readOnly && 'mt-6'}`
                )}
              >
                <div style={{ width: '28%' }} />
                <div style={{ width: '36%' }} className="flex justify-center">
                  <div
                    className={cn(
                      styles.flex14_item,
                      `${
                        props?.readOnly &&
                        mediaAsset.length === 1 &&
                        'opacity-0'
                      }`
                    )}
                  >
                    <IconButton
                      onClick={() => {
                        if (carouselIdx !== 0) {
                          setCarouselIndx(carouselIdx - 1);
                        }
                      }}
                      pointerEvents="auto"
                      className={`hover-arrow ${
                        carouselIdx === 0 && 'cursor-not-allowed'
                      }`}
                      zIndex="3"
                      boxShadow="-1px 1px #F3F3F4"
                      bg={carouselIdx === 0 ? '#FAFBFB' : '#FFF'}
                      color={carouselIdx === 0 ? '#D3D6D8' : '#242526'}
                      aria-label="previous-page"
                      borderRadius={100}
                      border="1px solid #F3F3F4"
                      width="25px"
                      icon={<SvgArrowLeft fontSize="24px" />}
                    />
                  </div>
                  <div className={cn(styles.flex14_spacer)} />
                  <div
                    className={cn(
                      styles.text_body3_box,
                      styles.text_body3_box_layout
                    )}
                  >
                    <div className={cn(styles.text_body3)}>
                      <span className={cn(styles.text_body3_span0)}>
                        {padNum(carouselIdx + 1)} /{' '}
                      </span>
                      <span className={cn(styles.text_body3_span1)}>
                        {padNum(mediaAsset.length)}
                      </span>
                    </div>
                  </div>
                  <div className={cn(styles.flex14_spacer)} />
                  <div
                    className={cn(
                      styles.flex14_item,
                      `${
                        props?.readOnly &&
                        mediaAsset.length === 1 &&
                        'opacity-0'
                      }`
                    )}
                  >
                    <IconButton
                      onClick={() => {
                        if (carouselIdx + 1 !== mediaAsset.length) {
                          setCarouselIndx(carouselIdx + 1);
                        }
                      }}
                      pointerEvents="auto"
                      className={`hover-arrow ${
                        carouselIdx + 1 === currFiles.length &&
                        'cursor-not-allowed'
                      }`}
                      zIndex="3"
                      boxShadow="1px 1px #F3F3F4"
                      bg={
                        carouselIdx + 1 === mediaAsset.length
                          ? '#FAFBFB'
                          : '#FFF'
                      }
                      color={
                        carouselIdx + 1 === mediaAsset.length
                          ? '#D3D6D8'
                          : '#242526'
                      }
                      aria-label="next-page"
                      borderRadius={100}
                      border="1px solid #F3F3F4"
                      width="25px"
                      icon={<SvgArrowRight fontSize="24px" />}
                    />
                  </div>
                </div>

                <div style={{ width: '28%' }}>
                  {mediaAsset[carouselIdx]?.type &&
                    !props.readOnly &&
                    mediaAsset.length < 12 && (
                      <div
                        style={{ height: '40px', width: '40px' }}
                        className={cn(
                          styles.block11,
                          'relative text-blue-500 font-medium border border-gray-300 rounded-full bg-white leading-6 w-max ml-auto'
                        )}
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          uploadModal.onOpen();
                        }}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            uploadModal.onOpen();
                          }
                        }}
                      >
                        <Tooltip
                          hasArrow
                          label={t('add a new slide')}
                          placement="top-end"
                          bg="$mode.900"
                          color="$mode.200"
                          borderRadius="4px"
                          p="12px"
                        >
                          <SvgAdd
                            w="20px"
                            h="20px"
                            className="absolute inset-0 m-auto"
                          />
                        </Tooltip>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}

          <div className={cn(styles.flex15, styles.flex15_layout)}>
            {props.readOnly ? (
              <div className="text-center">
                <div className="text-gray-700 font-semibold">{fileTitle}</div>
                <div className="text-sm text-gray-500 font-medium mt-4">
                  {fileDesc}
                </div>
              </div>
            ) : (
              <>
                <Box w="79%" h="42px" mb="8px" position="relative" mx="auto">
                  <Text
                    fontSize="12px"
                    textStyle="labelLight"
                    position="absolute"
                    zIndex="999"
                    right="10px"
                    display="flex"
                    alignItems="center"
                    h="100%"
                    color="$mode.500"
                    fontWeight="500"
                  >
                    {70 - uploadedFileTitle.length}
                  </Text>

                  <Textarea
                    fontSize="sm"
                    w="100%"
                    h="100%"
                    minH="auto"
                    py="10px"
                    pl="16px"
                    pr="36px"
                    disabled={!mediaAsset[carouselIdx]?.type}
                    fontWeight="500"
                    lineHeight="19px"
                    border="1px solid"
                    borderColor="$mode.200"
                    borderRadius="4px"
                    resize="none"
                    mb="10px"
                    maxLength={70}
                    value={uploadedFileTitle}
                    onChange={(e) => {
                      setUploadedFileTitle(
                        (e.target as HTMLTextAreaElement).value
                      );
                    }}
                    _focus={{
                      border: '1px solid #E6E8E9',
                      '::placeholder': {
                        color: 'rgba(0,0,0,0)',
                      },
                    }}
                    placeholder="Write headline here (optional)"
                  />
                </Box>
                <Box
                  w="79%"
                  h="calc(100% - 50px)"
                  position="relative"
                  mx="auto"
                >
                  <Text
                    fontSize="12px"
                    position="absolute"
                    right="10px"
                    bottom="10px"
                    zIndex="999"
                    textStyle="labelLight"
                    color="$mode.500"
                    fontWeight="500"
                  >
                    {250 - uploadedFileDescription.length}
                  </Text>
                  <Textarea
                    fontSize="sm"
                    w="100%"
                    height="100%"
                    minH="82px"
                    py="10px"
                    pl="16px"
                    pr="36px"
                    disabled={!mediaAsset[carouselIdx]?.type}
                    fontWeight="500"
                    lineHeight="19px"
                    border="1px solid"
                    borderColor="$mode.200"
                    borderRadius="4px"
                    resize="none"
                    _focus={{
                      border: '1px solid #E6E8E9',
                      '::placeholder': {
                        color: 'rgba(0,0,0,0)',
                      },
                    }}
                    placeholder="Write description here (optional)"
                    maxLength={250}
                    value={uploadedFileDescription}
                    onChange={(e) => {
                      setUploadedFileDescription(
                        (e.target as HTMLTextAreaElement).value
                      );
                    }}
                  />
                </Box>
              </>
            )}
          </div>
          <Modal
            isOpen={uploadModal.isOpen}
            onClose={uploadModal.onClose}
            isCentered
          >
            <ModalOverlay />
            <ModalContent pt="40px" maxW="640px" h="480px" borderRadius="24px">
              <ModalCloseButton
                color="$mode.500"
                mt={2}
                _focus={{ border: 'none' }}
              />
              <Tabs variant="unstyled">
                <TabList px={4}>
                  <Tab
                    _selected={{ color: 'gray.700' }}
                    fontWeight="600"
                    fontSize="16px"
                    color="gray.400"
                    py={0}
                  >
                    Upload
                  </Tab>
                  <Tab
                    _selected={{ color: 'gray.700' }}
                    fontWeight="600"
                    fontSize="16px"
                    color="gray.400"
                    py={0}
                  >
                    Unsplash
                  </Tab>
                  <Tab
                    _selected={{ color: 'gray.700' }}
                    fontWeight="600"
                    fontSize="16px"
                    color="gray.400"
                    py={0}
                  >
                    Video/Audio
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel p={0}>
                    <div style={{ height: '416px' }} className="relative">
                      <div
                        style={{ height: 'fit-content' }}
                        className="absolute inset-0 m-auto"
                      >
                        <div className="text-center">
                          <MediaPostImageUploader
                            dropzone={dropzone}
                            uploading={isUploading}
                            onDelete={() => {
                              setImgURL(null);
                            }}
                            src={imgURL}
                          >
                            <Button
                              variant="link"
                              className="border-gray-300 border font-semibold m-auto"
                              bg="white"
                              px="32px"
                              py="8px"
                              fontWeight="600"
                              color="blue.500"
                              rounded="ellipse"
                              onClick={() => {
                                dropzone.open();
                              }}
                            >
                              Select image
                            </Button>
                          </MediaPostImageUploader>
                        </div>
                        <div className={cn(styles.paragraph_body, 'my-6')}>
                          {
                            'Minimum dimensions: 900 x 600px\n(JPG, PNG, GIF format less than 5MB)'
                          }
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel p={0}>
                    <UnsplashSelect
                      height="416px"
                      onChange={async (us) => {
                        if (us) {
                          uploadModal.onClose();
                          setLastUploadedFile({
                            name: 'Image from Unsplash',
                            size: null,
                            createdBy: us.author,
                          });
                          setImageToCrop(us.url);
                          setIsNotResizable(false);
                          cropModal.onOpen();
                        }
                      }}
                    />
                  </TabPanel>
                  <TabPanel p={0}>
                    <div style={{ height: '416px' }}>
                      <div
                        style={{ height: 'fit-content' }}
                        className="absolute m-auto inset-0 px-8"
                      >
                        <Box mb="8px" position="relative" mx="auto">
                          <Textarea
                            placeholder="URL - Youtube, Vimeo, Spotify, SoundCloud"
                            fontSize="sm"
                            h="100%"
                            py="11px"
                            pl="16px"
                            pr="25px"
                            fontWeight="500"
                            lineHeight="19px"
                            border="1px solid #BBC0C5"
                            borderRadius="4px"
                            resize="none"
                            _focus={{
                              '::placeholder': {
                                color: 'rgba(0,0,0,0)',
                              },
                            }}
                            onChange={(e) => {
                              setvideoURL(e.target.value);
                            }}
                          />
                        </Box>
                        <div className="text-center pt-4">
                          <Button
                            variant="link"
                            className="border-gray-300 border font-semibold m-auto"
                            bg="white"
                            px="56px"
                            py="8px"
                            fontWeight="600"
                            color="blue.500"
                            rounded="ellipse"
                            onClick={() => {
                              if (checkVidUrl(videoURL)) {
                                const videoType = checkVidUrl(videoURL);

                                let newFile: any = {
                                  type: 'video',
                                  id: generateUniqueID(),
                                  description: null,
                                  title: null,
                                  videoURL,
                                  file: {
                                    name: `${
                                      checkVidUrl(videoURL, true)
                                        ? 'Video'
                                        : 'Music'
                                    } from ${videoType}`,
                                    size: null,
                                  },
                                };
                                if (checkVidUrl(videoURL) === 'Spotify') {
                                  const spotifyID = videoURL.match(
                                    /^https?:\/\/open\.spotify\.com\/track\/(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/
                                  );
                                  newFile = {
                                    type: 'spotify',
                                    id: spotifyID[1],
                                  };
                                }
                                const uploadedFilesArray = currFiles;
                                uploadedFilesArray.push(newFile);
                                if (props.setUploadedFiles) {
                                  props.setUploadedFiles(uploadedFilesArray);
                                }

                                uploadModal.onClose();
                              } else {
                                toast({
                                  position: 'bottom-right',
                                  duration: 3000,
                                  render: (r) => (
                                    <CustomToast
                                      icon="error"
                                      title="Invalid URL"
                                      description="Please provide a valid URL."
                                      {...r}
                                    />
                                  ),
                                });
                              }
                            }}
                          >
                            Add
                          </Button>
                        </div>
                        {/* <div className="my-6 text-sm text-gray-500 font-medium whitespace-pre text-center">
                          {
                            'Minimum dimensions: 900 x 600px\n(JPG, PNG, GIF format less than 5MB)'
                          }
                        </div> */}
                      </div>
                    </div>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalContent>
          </Modal>
          {imageCropRenderer}
          <WarningModalWithoutCancel
            {...warningModal}
            title={warningModalTitle}
            text={warningModalText}
            okBtnText="Upload again"
          />
        </div>
        <Modal
          isOpen={lightroomModal.isOpen}
          onClose={lightroomModal.onClose}
          isCentered
        >
          <ModalContent
            w="100vw"
            maxW="100vw"
            h="100vh"
            position="fixed"
            background="#191919"
            borderRadius="0"
            py="45px"
          >
            <ModalCloseButton
              color="$mode.50"
              _focus={{ outline: 'none' }}
              zIndex="99"
            >
              <div
                className="p-2.5 fixed top-6 right-6 z-50"
                style={{
                  background: 'rgba(255, 255, 255, 0.3)',
                  borderRadius: '22px',
                }}
              >
                <SvgCloseModal fontSize="24px" />
              </div>
            </ModalCloseButton>
            <ModalBody
              p={0}
              overflow="hidden"
              className={!isTablet && 'flex flex-col justify-around'}
            >
              {/* {!isMobile && (
                <Heading
                  as="h2"
                  textStyle="h2"
                  textAlign="center"
                  fontWeight="800"
                  noOfLines={4}
                  color="$mode.bg"
                  mb="20px"
                  className="text-white dark:text-white"
                >
                  {article?.title}
                </Heading>
              )} */}
              <Box w="100%" h={{ base: '100%', md: '650px' }} m="auto">
                {/* {article.content?.uploadedFiles?.length === 1 &&
                  article.content?.uploadedFiles[0].type === 'image' && (
                    <Box
                      overflow="hidden"
                      position={isMobile ? 'absolute' : 'relative'}
                      borderRadius={isMobile ? '0px' : '16px'}
                      w="100%"
                      opacity={isMobile && isShowMore && 0.5}
                      display="flex"
                      justifyContent="center"
                      alignItems={isMobile ? 'center' : 'stretch'}
                      bottom={isMobile && 'calc(15vh + 40px)'}
                      top={isMobile && 'calc(10vh)'}
                    >
                      <Image
                        alt="cover-image"
                        src={imageOptimize(
                          article.content.uploadedFiles[0].imgURL
                        )}
                        fallbackSrc={urlLoadingGif}
                        objectFit="cover"
                        layout="fill"
                        w="100%"
                        h="66vw !important"
                      />
                    </Box>
                  )}
                {article.content?.uploadedFiles?.length === 1 &&
                  article.content?.uploadedFiles[0].type === 'spotify' && (
                    <Box
                      background="#000000"
                      overflow="hidden"
                      borderRadius="16px"
                      minHeight={{ base: '2.5rem', md: '70vh' }}
                      position="relative"
                      w="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <iframe
                        title={article.content?.uploadedFiles[0].id}
                        src={`https://open.spotify.com/embed/track/${article.content?.uploadedFiles[0].id}`}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allow="encrypted-media"
                      />
                    </Box>
                  )}
                {article.content?.uploadedFiles?.length === 1 &&
                  article.content?.uploadedFiles[0].type === 'nft' &&
                  article.content?.uploadedFiles[0].imgURL &&
                  !article.content?.uploadedFiles[0].imgURL.endsWith(
                    '.mp4'
                  ) && (
                    <Box
                      overflow="hidden"
                      position="relative"
                      borderRadius="16px"
                      w="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <Image
                        margin="auto"
                        alt="cover-image"
                        src={imageOptimize(
                          article.content.uploadedFiles[0].imgURL
                        )}
                        fallbackSrc={urlLoadingGif}
                        objectFit="cover"
                        layout="fill"
                      />
                    </Box>
                  )}
                {article.content?.uploadedFiles?.length === 1 &&
                  article.content?.uploadedFiles[0].type === 'nft' &&
                  article.content?.uploadedFiles[0].imgURL &&
                  article.content?.uploadedFiles[0].imgURL.endsWith('.mp4') && (
                    <Box
                      overflow="hidden"
                      position="relative"
                      borderRadius="16px"
                      w="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <ReactPlayer
                        height="inherit"
                        width="100%"
                        loop
                        playing
                        url={article.content?.uploadedFiles[0].imgURL}
                      />
                    </Box>
                  )}
                {article.content?.uploadedFiles?.length === 1 &&
                  article.content?.uploadedFiles[0].type === 'nft' &&
                  !article.content?.uploadedFiles[0].imgURL && (
                    <Box
                      overflow="hidden"
                      position="relative"
                      borderRadius="16px"
                      w="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <div
                        style={{
                          height: '100%',
                          aspectRatio: '1/1',
                        }}
                        className="bg-gray-100 m-auto"
                      >
                        <div
                          style={{
                            height: '56px',
                            width: '56px',
                          }}
                          className="rounded-full bg-white m-auto absolute inset-0"
                        >
                          <SvgNFTholder
                            style={{ height: 'inherit' }}
                            width="40px"
                            height="40px"
                            className="m-auto"
                          />
                        </div>
                      </div>
                    </Box>
                  )}
                {article.content?.uploadedFiles?.length === 1 &&
                  article.content?.uploadedFiles[0].type === 'video' && (
                    <Box
                      background="#000000"
                      overflow="hidden"
                      borderRadius="16px"
                      minHeight={{ base: '2.5rem', md: '70vh' }}
                      className="absolute md:relative inset-0"
                      w="100%"
                      h={{ base: '25vh', md: '100%' }}
                      m="auto"
                      display="flex"
                      justifyContent="center"
                    >
                      <ReactPlayer
                        width="100%"
                        height="100%"
                        url={article.content.uploadedFiles[0].videoURL}
                        light
                        playing
                        controls
                      />
                    </Box>
                  )} */}
                {mediaAsset?.length === 1 && (
                  <div>
                    {mediaAsset.map((d, key) => {
                      const mkey = `ca_${key}`;
                      return (
                        <Box
                          key={mkey}
                          overflow="hidden"
                          borderRadius="10px"
                          position="relative"
                          margin="auto"
                          display="block"
                          alignItems="center"
                        >
                          <div
                            style={{
                              height: `${isTablet ? '100vh' : '650px'}`,
                            }}
                          >
                            {imageRender(d)}
                          </div>
                          <div
                            className={`w-full my-6 md:text-center md:mt-8 md:mb-0 px-6 ${
                              isTablet && 'absolute bottom-2'
                            }`}
                          >
                            <div
                              style={{ color: '#C7C9D1' }}
                              className="text-sm font-semibold"
                            >
                              {d.title}
                            </div>
                            <div
                              style={{ color: '#8C8F9B' }}
                              className="text-xs font-medium my-2"
                            >
                              {d.description}
                            </div>
                            {d.file?.createdBy && (
                              <div
                                style={{ color: '#8C8F9B' }}
                                className="text-xs font-medium my-2"
                              >
                                Photo by <span style={{ color: '#C7C9D1' }} />
                                {d.file.name === 'Image from Unsplash' &&
                                  'on Unsplash'}
                              </div>
                            )}
                          </div>
                        </Box>
                      );
                    })}
                  </div>
                )}
                {mediaAsset?.length > 1 && (
                  <Carousel
                    slideIndex={carouselIdx}
                    cellSpacing={32}
                    afterSlide={(slideIndex) => setCarouselIndx(slideIndex)}
                    slidesToShow={isTablet ? 1 : 1.25}
                    cellAlign="center"
                    wrapAround
                    opacityScale={0.5}
                    renderCenterLeftControls={({ previousSlide }) => (
                      <ChakraImage
                        src="/images/icons/post-arrow-left.svg"
                        onClick={previousSlide}
                        w="64px"
                        h="inherit"
                        className="opacity-0 md:opacity-100 animate-fadeOut cursor-pointer text-white"
                        margin="0  auto"
                      />
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                      <ChakraImage
                        src="/images/icons/post-arrow-right.svg"
                        onClick={nextSlide}
                        w="64px"
                        h="inherit"
                        className="opacity-0 md:opacity-100 animate-fadeOut cursor-pointer text-white"
                        margin="0  auto"
                      />
                    )}
                    defaultControlsConfig={{
                      pagingDotsClassName: 'px-1',
                      pagingDotsStyle: {
                        fill: '#8C8F9B',
                        display: `${isTablet ? 'block' : 'none'}`,
                      },
                    }}
                  >
                    {/* {article.content.uploadedFiles.map((file) => {
                      if (file.type === 'spotify') {
                        return (
                          <Box
                            key={file.id}
                            background="#000000"
                            width="100%"
                            height="100%"
                            overflow="hidden"
                            borderRadius="16px"
                            position="relative"
                            pt={isMobile && 'calc(10vh)'}
                          >
                            <iframe
                              title={file.id}
                              src={`https://open.spotify.com/embed/track/${file.id}`}
                              width="100%"
                              height="100%"
                              frameBorder="0"
                              allow="encrypted-media"
                            />
                          </Box>
                        );
                      }
                      if (file.type === 'video') {
                        return (
                          <Box
                            key={file.id}
                            background={!isMobile && 'black'}
                            width="100%"
                            height="100%"
                            overflow="hidden"
                            borderRadius="16px"
                            position="relative"
                            pt={isMobile && 'calc(10vh)'}
                          >
                            <ReactPlayer
                              width="100%"
                              height="100%"
                              url={file.videoURL}
                              light
                              playing
                              controls
                            />
                          </Box>
                        );
                      }
                      return (
                        <Box
                          key={file.id}
                          overflow="hidden"
                          borderRadius="10px"
                          position="relative"
                          h="100vh"
                          pt={isMobile && 'calc(10vh)'}
                          display={isMobile ? 'flex' : 'block'}
                          alignItems="center"
                        >
                          {file.type === 'nft' && !file.imgURL && (
                            <Box
                              position="relative"
                              aspectRatio="16/9"
                              height="100%"
                            >
                              <div
                                style={{ height: '100%', aspectRatio: '1/1' }}
                                className="bg-gray-100 m-auto"
                              >
                                <div
                                  style={{ height: '56px', width: '56px' }}
                                  className="rounded-full bg-white m-auto absolute inset-0"
                                >
                                  <SvgNFTholder
                                    style={{ height: 'inherit' }}
                                    width="40px"
                                    height="40px"
                                    className="m-auto"
                                  />
                                </div>
                              </div>
                            </Box>
                          )}
                          {file.type === 'nft' &&
                            file.imgURL &&
                            !file.imgURL.endsWith('.mp4') && (
                              <Image src={file.imgURL} height="100%" m="auto" />
                            )}
                          {file.type === 'nft' &&
                            file.imgURL &&
                            file.imgURL.endsWith('.mp4') && (
                              <Box
                                position="relative"
                                aspectRatio="16/9"
                                height="100%"
                              >
                                <ReactPlayer
                                  height="inherit"
                                  width="100%"
                                  loop
                                  playing
                                  url={file.imgURL}
                                />
                              </Box>
                            )}
                          {file.type !== 'nft' && (
                            <Image
                              src={imageOptimize(file.imgURL)}
                              fallbackSrc={urlLoadingGif}
                              objectFit="cover"
                              layout="fill"
                              opacity={isMobile && isShowMore && '0.5'}
                              w={isMobile ? '100%' : 'auto'}
                              h={
                                isMobile ? '66vw !important' : '100% !important'
                              }
                            />
                          )}
                        </Box>
                      );
                    })} */}
                    {mediaAsset.map((d, key) => {
                      const mkey = `ca_${key}`;
                      return (
                        <Box
                          key={mkey}
                          overflow="hidden"
                          borderRadius="10px"
                          position="relative"
                          margin="auto"
                          display="block"
                          alignItems="center"
                        >
                          <div
                            style={{
                              height: `${isTablet ? '100vh' : '650px'}`,
                            }}
                          >
                            {imageRender(d)}
                          </div>
                          <div
                            className={`w-full my-6 md:text-center md:mt-8 md:mb-0 px-6 ${
                              isTablet && 'absolute bottom-2'
                            }`}
                          >
                            <div
                              style={{ color: '#C7C9D1' }}
                              className="text-sm font-semibold"
                            >
                              {d.title}
                            </div>
                            <div
                              style={{ color: '#8C8F9B' }}
                              className="text-xs font-medium my-2"
                            >
                              {d.description}
                            </div>
                            {d.file.createdBy && (
                              <div
                                style={{ color: '#8C8F9B' }}
                                className="text-xs font-medium my-2"
                              >
                                Photo by{' '}
                                <span style={{ color: '#C7C9D1' }}>
                                  {d.file.createdBy}
                                </span>{' '}
                                {d.file.name === 'Image from Unsplash' &&
                                  'on Unsplash'}
                              </div>
                            )}
                          </div>
                        </Box>
                      );
                    })}
                  </Carousel>
                )}
                {/* {isMobile ? (
                  <Box
                    w="100%"
                    maxW="704px"
                    mx="auto"
                    pt={3}
                    pb={6}
                    px="6.6%"
                    pos="absolute"
                    bottom="0"
                    background="rgba(36,37,38,0.5)"
                    wordBreak="break-word"
                  >
                    {mediaAsset[carouselIdx]?.type === 'nft' && (
                      <div className="flex grid grid-cols-4">
                        <div className="font-semibold text-gray-700 text-base col-span-4 pb-3 truncate">
                          {mediaAsset[carouselIdx].name}
                        </div>
                        <div className="text-gray-500 font-medium text-sm col-span-1">
                          {mediaAsset[carouselIdx].contractAddress && (
                            <div className="pb-3">Contract Address</div>
                          )}
                          {mediaAsset[carouselIdx].tokenId && (
                            <div className="pb-3">Token ID</div>
                          )}
                          {mediaAsset[carouselIdx].collectionName && (
                            <div className="pb-3">Collection Name</div>
                          )}
                          {mediaAsset[carouselIdx].contractType && (
                            <div className="pb-3">Contract Type</div>
                          )}
                        </div>
                        <div className="font-medium text-gray-700 text-sm col-span-3 text-right">
                          {mediaAsset[carouselIdx].contractAddress && (
                            <div className="pb-3 text-blue-500 truncate">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://etherscan.io/address/${mediaAsset[carouselIdx].contractAddress}`}
                              >
                                {mediaAsset[carouselIdx].contractAddress}
                              </a>
                            </div>
                          )}
                          {mediaAsset[carouselIdx].tokenId && (
                            <div className="pb-3 text-blue-500 truncate">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://etherscan.io/token/${mediaAsset[carouselIdx].contractAddress}?a=${mediaAsset[carouselIdx].tokenId}`}
                              >
                                {mediaAsset[carouselIdx].tokenId}
                              </a>
                            </div>
                          )}
                          {mediaAsset[carouselIdx].collectionName && (
                            <div className="pb-3">
                              {mediaAsset[carouselIdx].collectionName}
                            </div>
                          )}
                          {mediaAsset[carouselIdx].contractType && (
                            <div className="pb-3">
                              {mediaAsset[carouselIdx].contractType}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {mediaAsset[carouselIdx]?.type !== 'nft' && (
                      <>
                        <Text
                          textStyle="h6Light"
                          lineHeight="20px"
                          mb={2}
                          color="white"
                        >
                          {mediaAsset[carouselIdx].title}
                        </Text>
                        <ShowMoreText
                          lines={1}
                          more="more"
                          less="less"
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          expanded={false}
                          width={280}
                        >
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="18px"
                            color="white"
                          >
                            {mediaAsset[carouselIdx].description}
                          </Text>
                        </ShowMoreText>
                        {mediaAsset[carouselIdx]?.createdBy && (
                          <Text
                            fontSize="14px"
                            mt={`${
                              !mediaAsset[carouselIdx].title &&
                              !mediaAsset[carouselIdx].description
                                ? 0
                                : '8px'
                            }`}
                            lineHeight="18px"
                            color="white"
                          >
                            Photo by{' '}
                            <span className="underline">
                              {mediaAsset[carouselIdx]?.createdBy}
                            </span>{' '}
                            on <span className="underline">Unsplash</span>
                          </Text>
                        )}
                      </>
                    )}
                  </Box>
                ) : (
                  <Box
                    display={mediaAsset.length > 1 ? 'grid' : 'block'}
                    w="100%"
                    gridTemplateColumns="80px 1fr"
                    maxW="704px"
                    mx="auto"
                    pt={4}
                    pb={4}
                  >
                    <Flex
                      color="$mode.200"
                      fontSize="14px"
                      fontWeight="bold"
                      lineHeight="18px"
                      h="100%"
                      pr={3}
                      mr={3}
                      borderRight={
                        mediaAsset[carouselIdx]?.type === 'nft'
                          ? 'none'
                          : '2px solid #E6E8E9'
                      }
                    >
                      {mediaAsset?.length > 1 && (
                        <>
                          <span className={cn(styles.text_body3_span0)}>
                            {padNum(carouselIdx + 1)}
                          </span>
                          <span className={cn(styles.text_body3_span1)}>
                            &nbsp;/&nbsp;
                            {padNum(mediaAsset.length)}
                          </span>
                        </>
                      )}
                    </Flex>
                    {mediaAsset[carouselIdx]?.type === 'nft' && (
                      <div className="flex grid grid-cols-4">
                        <div className="font-semibold text-white text-base col-span-4 pb-3 truncate">
                          {mediaAsset[carouselIdx].name}
                        </div>
                        <div className="text-gray-500 font-medium text-sm col-span-1">
                          {mediaAsset[carouselIdx].contractAddress && (
                            <div className="pb-3">Contract Address</div>
                          )}
                          {mediaAsset[carouselIdx].tokenId && (
                            <div className="pb-3">Token ID</div>
                          )}
                          {mediaAsset[carouselIdx].collectionName && (
                            <div className="pb-3">Collection Name</div>
                          )}
                          {mediaAsset[carouselIdx].contractType && (
                            <div className="pb-3">Contract Type</div>
                          )}
                        </div>
                        <div className="font-medium text-white text-sm col-span-3 text-right">
                          {mediaAsset[carouselIdx].contractAddress && (
                            <div className="pb-3 text-blue-500 truncate">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://etherscan.io/address/${mediaAsset[carouselIdx].contractAddress}`}
                              >
                                {mediaAsset[carouselIdx].contractAddress}
                              </a>
                            </div>
                          )}
                          {mediaAsset[carouselIdx].tokenId && (
                            <div className="pb-3 text-blue-500 truncate">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://etherscan.io/token/${mediaAsset[carouselIdx].contractAddress}?a=${mediaAsset[carouselIdx].tokenId}`}
                              >
                                {mediaAsset[carouselIdx].tokenId}
                              </a>
                            </div>
                          )}
                          {mediaAsset[carouselIdx].collectionName && (
                            <div className="pb-3">
                              {mediaAsset[carouselIdx].collectionName}
                            </div>
                          )}
                          {mediaAsset[carouselIdx].contractType && (
                            <div className="pb-3">
                              {mediaAsset[carouselIdx].contractType}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {mediaAsset[carouselIdx]?.type !== 'nft' && (
                      <Box wordBreak="break-word">
                        <Text
                          textStyle="h6Light"
                          mb={mediaAsset[carouselIdx]?.title && 2}
                          color="$mode.400"
                        >
                          {mediaAsset[carouselIdx]?.title}
                        </Text>
                        <Text
                          fontSize="14px"
                          fontWeight="500"
                          lineHeight="18px"
                          color="$mode.400"
                        >
                          {mediaAsset[carouselIdx]?.description}
                        </Text>
                        {mediaAsset[carouselIdx]?.createdBy && (
                          <Text
                            fontSize="14px"
                            mt={`${
                              !mediaAsset[carouselIdx].title &&
                              !mediaAsset[carouselIdx].description
                                ? 0
                                : '8px'
                            }`}
                            lineHeight="18px"
                            color="gray.500"
                          >
                            Photo by{' '}
                            <span className="underline">
                              {mediaAsset[carouselIdx]?.createdBy}
                            </span>{' '}
                            on <span className="underline">Unsplash</span>
                          </Text>
                        )}
                      </Box>
                    )}
                  </Box>
                )} */}
              </Box>
              {!isTablet && <Box />}
              <style jsx global>{`
                .slider-frame {
                  overflow: unset !important;
                }
                .slider-list {
                  height: 100% !important;
                }
                .slider-slide {
                  opacity: 0.5;
                  height: 100vh !important;
                }
                .slider-slide > div {
                  width: 100%;
                  height: 100%;
                }
                .slider-slide > div img {
                  height: 100%;
                  margin: 0 auto;
                }
                .slide-current {
                  opacity: 1;
                }
                .slider-control-centerright {
                  right: 0 !important;
                  position: fixed !important;
                  height: 100%;
                  width: 10%;
                }
                .slider-control-centerleft {
                  left: 0 !important;
                  position: fixed !important;
                  height: 100%;
                  width: 10%;
                }
                .my-anchor-css-class {
                  color: #74787d;
                  font-size: 14px;
                  font-weight: 400;
                }
                .content-css {
                  color: #74787d;
                  font-weight: 400;
                  color: white;
                  font-size: 14px;
                }
                .chakra-modal__content {
                  position: relative;
                  padding: 0;
                }
                .chakra-modal__close-btn {
                  position: absolute;
                }
              `}</style>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <Box
      width="100%"
      maxWidth={EDITOR_CONFIG.maxWidth}
      m="auto"
      bgColor="$mode.100"
    >
      <AspectRatio ratio={16 / 9} position="relative">
        <ImageUploader
          dropzone={dropzone}
          uploading={isUploading}
          onDelete={() => {
            setImgURL(null);
          }}
          fontSize="32px"
          color="$mode.700"
          src={imgURL}
          style={{
            position: 'initial',
          }}
        >
          <div
            style={{ height: 'fit-content' }}
            className="absolute inset-0 m-auto w-max text-center"
          >
            <Text
              pt={2}
              textStyle="labelLight"
              color={imgURL ? `white` : `$mode.500`}
              whiteSpace="nowrap"
              fontWeight="500"
            >
              {t('Minimum dimensions')}: 900 x 600px
            </Text>
            <Text
              textStyle="labelLight"
              color={imgURL ? `white` : `$mode.500`}
              fontWeight="500"
            >
              (JPG, PNG, GIF {t('format less than')} 5MB)
            </Text>
            <ButtonGroup width="230px" mt={3} isAttached>
              <Button
                border="0.5px solid"
                borderColor="gray.200"
                borderRadius={100}
                bgColor="white"
                variant="outline"
                fontSize="14px"
                fontWeight="500"
                maxHeight="34px"
                onClick={dropzone.open}
              >
                {t('Upload')}
              </Button>
              <Button
                border="0.5px solid"
                borderLeft="none"
                variant="outline"
                borderColor="gray.200"
                borderRadius={100}
                bgColor="white"
                fontSize="14px"
                fontWeight="500"
                maxHeight="34px"
                onClick={unsplashModal.onOpen}
                ml="0 !important"
              >
                {t('Unsplash')}
              </Button>
            </ButtonGroup>
          </div>
        </ImageUploader>
      </AspectRatio>
      <OneTmModal
        modal={unsplashModal}
        okText="Confirm"
        title="Update Cover Image"
        showOk={false}
      >
        <UnsplashSelect
          height="420px"
          onChange={async (us) => {
            if (us) {
              unsplashModal.onClose();
              setImageToCrop(us.url);
              setIsNotResizable(false);
              cropModal.onOpen();
            }
          }}
        />
      </OneTmModal>
      {imageCropRendererAspect}
      <WarningModalWithoutCancel
        {...warningModal}
        title={warningModalTitle}
        text={warningModalText}
        okBtnText="Upload again"
      />
    </Box>
  );
}

function generateUniqueID() {
  const day = new Date();
  return day.getTime();
}
