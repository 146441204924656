import {
  Button,
  Center,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  UseDisclosureReturn,
  Flex,
  Textarea,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useState } from 'react';
import SvgClose from 'src/components/icons/close';

function SubmissionModal({
  isOpen,
  onClose,
  title,
  text,
  placeHolder,
  cancelBtnText = 'Cancel',
  okBtnText = 'Remove',
  onOk,
  isDecline = false,
}: UseDisclosureReturn & {
  title: string;
  text?: string;
  placeHolder?: string;
  cancelBtnText?: string;
  okBtnText?: string;
  onOk: (e: any) => void;
  isDecline?: boolean;
}) {
  const [useDescription, setDescription] = useState('');
  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay>
        <ModalContent bg="$mode.50" pos="relative">
          <Box
            pos="absolute"
            right={3}
            top={3}
            cursor="pointer"
            zIndex="5"
            onClick={onClose}
          >
            <SvgClose color="black" fontSize="16px" />
          </Box>
          <Center flexDirection="column" position="relative">
            <Box pt="50px" px="50px" pb={5} w="100%" className="bg-white">
              <div className="text-2xl font-semibold text-gray-900">
                {title}
              </div>
              <Flex direction="column">
                <Flex
                  justifyContent="space-between"
                  alignItems="flex-end"
                  mb={2}
                  w="100%"
                  position="relative"
                  paddingTop={4}
                >
                  <div className="font-normal text-sm text-greyChateau">
                    {text}
                  </div>
                </Flex>
                <InputGroup>
                  <Textarea
                    placeholder={placeHolder}
                    fontSize="sm"
                    minH="110px"
                    py="10px"
                    px="16px"
                    fontWeight="500"
                    lineHeight="19px"
                    border="1px solid"
                    borderColor="$mode.200"
                    borderRadius="4px"
                    resize="none"
                    _focus={{
                      border: '1px solid #E6E8E9',
                      '::placeholder': {
                        color: 'rgba(0,0,0,0)',
                      },
                    }}
                    value={useDescription}
                    onChange={(e) => {
                      setDescription((e.target as HTMLTextAreaElement).value);
                    }}
                    maxLength={500}
                  />
                  <InputRightElement height="100%">
                    {isDecline && (
                      <div
                        style={{ top: '-1.5rem' }}
                        className="font-bold text-xs text-gray-300 absolute right-0"
                      >
                        Required
                      </div>
                    )}
                    <Text
                      textStyle="labelLight"
                      color="$mode.500"
                      paddingRight={2}
                      className="absolute bottom-2"
                    >
                      {500 - useDescription?.length}
                    </Text>
                  </InputRightElement>
                </InputGroup>
              </Flex>
            </Box>
            <Box
              w="100%"
              borderTop="1px solid"
              borderColor="gray.200"
              bgColor="gray.50"
              py={3}
              px={5}
              className="flex justify-end"
            >
              <Button
                colorScheme="#FF6633"
                color="gray.500"
                variant="ghost"
                onClick={onClose}
              >
                {cancelBtnText}
              </Button>
              {isDecline && (
                <div
                  role="button"
                  tabIndex={0}
                  className={`px-4 py-2 cursor-pointer font-semibold rounded-full w-max text-white ${
                    useDescription.length > 0
                      ? 'bg-outrageousOrange-500'
                      : 'bg-outrageousOrange-200'
                  }`}
                  onClick={() => {
                    if (useDescription.length > 0) {
                      onOk(useDescription);
                      onClose();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter' && useDescription.length > 0) {
                      onOk(useDescription);
                      onClose();
                    }
                  }}
                >
                  {okBtnText}
                </div>
              )}
              {!isDecline && (
                <div
                  role="button"
                  tabIndex={0}
                  className="px-4 py-2 cursor-pointer font-semibold rounded-full w-max text-white bg-blue-500"
                  onClick={() => {
                    onOk(useDescription);
                    onClose();
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      onOk(useDescription);
                      onClose();
                    }
                  }}
                >
                  {okBtnText}
                </div>
              )}
            </Box>
          </Center>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default SubmissionModal;
