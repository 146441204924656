import * as React from 'react';
import { Svg } from '../svg';

function SvgCcNc({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#cc-nc_svg__clip0_7994_98950)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.986 3.833H20c.11 0 .218.002.326.004 4.527.08 8.357 1.685 11.49 4.817 3.233 3.215 4.85 7.163 4.85 11.846s-1.587 8.581-4.762 11.697c-3.222 3.165-6.997 4.818-11.323 4.96-.193.007-.386.01-.58.01h-.031c-2.82 0-5.402-.64-7.744-1.92a16.743 16.743 0 01-6.896-6.83C4 26.037 3.333 23.397 3.333 20.5c0-4.563 1.638-8.512 4.911-11.845C11.46 5.44 15.367 3.834 19.97 3.834h.016zM7.394 16.145a13.315 13.315 0 00-.727 4.355c0 7.364 5.97 13.334 13.333 13.334a13.33 13.33 0 0011.672-6.883l-5.929-2.642a4.296 4.296 0 01-1.592 2.575c-.845.664-1.84 1.047-2.991 1.145v2.56h-1.935v-2.56c-1.845-.018-3.532-.683-5.06-1.993l2.293-2.321c1.09 1.011 2.33 1.517 3.72 1.517.575 0 1.066-.128 1.474-.387.406-.257.61-.683.61-1.28 0-.417-.15-.754-.447-1.012l-1.607-.685-1.964-.893-2.649-1.16-8.2-3.67zm1.457-2.96A13.321 13.321 0 0120 7.167c7.363 0 13.332 5.97 13.332 13.333 0 1.18-.153 2.325-.44 3.415l-8.19-3.652-2.679-1.19-1.458-.655-2.113-.953a.715.715 0 01-.149-.417c0-.476.204-.813.61-1.012a3.119 3.119 0 011.384-.297c.973 0 1.924.327 2.857.982l2.173-2.233c-1.25-.952-2.638-1.467-4.166-1.547v-2.56h-1.935v2.56c-1.033.06-1.936.352-2.709.878a3.904 3.904 0 00-1.577 2.098l-6.09-2.732z"
          fill="#4F5356"
        />
      </g>
      <defs>
        <clipPath id="cc-nc_svg__clip0_7994_98950">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCcNc);
export default ForwardRef;
