import { Article, FullArticle } from 'src/app/article/types';
import { axios } from 'src/lib/axios';
import type { OutputData } from '@editorjs/editorjs';
import type { RestResponse } from 'src/types';
import type { ArticleSource, ArticlePostType } from 'src/app/article/enum';

interface PublishContent {
  title: string;
  description: string;
  content: OutputData;
  commentable: boolean;
  copyright: string;
  coAuthors: any[];
  contributors?: any[];
  hasSensitiveContent?: boolean;
  categories: string[];
  tags: string[];
  coverImgURL?: any;
  attachedImgIDs?: any[];
  username?: string;
  postType?: any;
  string?: any;
  explanation?: string;
  draft?: boolean;
}

export interface EpisodeRequest extends PublishContent {
  contentID?: string;
  sourceID?: string;
  source?: ArticleSource;
  postType: ArticlePostType;
  explanation: string;
  official?: boolean;
  projectType?: 'TOPIC';
  projectID?: string;
}

export const getEpisode = (id: string) =>
  axios
    .get<RestResponse<Article>>(`/episode-edit/${id}`)
    .then((res) => res.data)
    .then((res) => {
      if (res.code === 1000) {
        return res.data;
      }
    });

export const getFullEpisode = (id: string) =>
  axios
    .get<RestResponse<FullArticle>>(`/post/${id}`)
    .then((res) => res.data)
    .then((res) => {
      if (res.code === 1000) {
        return res.data;
      }
    });

export const createOrUpdateEpisode = (data: EpisodeRequest) =>
  axios.post<RestResponse>('/episode', data).then((res) => res.data);

export const partialUpdateEpisode = ({
  contentID,
  ...data
}: Omit<Partial<EpisodeRequest>, 'contentID' | 'reppl_id'> & {
  contentID: string;
  reppl_id: string;
}) =>
  axios
    .put<RestResponse>(`/episode/${contentID}`, data)
    .then((res) => res.data);

export const deleteEpisode = (id: string) =>
  axios.delete<RestResponse>(`/episode/${id}`).then((res) => res.data);
