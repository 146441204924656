import {
  Button,
  Center,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  Input,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import SvgWarning from 'src/components/icons/warning';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useLocalStorage } from 'src/hooks';
// import { API_V2 } from 'src/constants/env';

function SignInModal({
  isOpen,
  handleClose,
  title,
}: UseDisclosureReturn & {
  handleClose: () => void;
  title: string;
}) {
  const { t } = useTranslation();
  const [pass, setPass] = useState('');
  const [error, setError] = useState(false);
  const handlePass = (event) => setPass(event.target.value);
  const [currPass, setCurrPass] = useLocalStorage('visitorPass', '');
  const demoPassword = 'oneteam';

  useEffect(() => {
    if (currPass === demoPassword) {
      // API_V2 !== 'https://demo-api.1tm.io/v2' <-- for filtering only for demo
      handleClose();
    }
  }, [currPass, handleClose]);

  const handleSignin = () => {
    if (pass === demoPassword) {
      setCurrPass(pass);
      setError(false);
      handleClose();
    } else {
      setError(true);
    }
  };
  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      onClose={() => {}}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay>
        <ModalContent
          bg="$mode.50"
          pt="10px"
          px={{ base: '15px', md: '30px' }}
          pb="40px"
        >
          <Center flexDirection="column" id="signinModal">
            <div className="relative p-5">
              <SvgWarning
                fontSize="26px"
                position="absolute"
                top="calc(50% - 13px)"
                left="calc(50% - 13px)"
              />
              <Box
                bgColor="warning"
                borderRadius={99}
                opacity="0.1"
                margin="auto"
                w="75px"
                h="75px"
              />
            </div>
            <Heading as="h3" textStyle="h3" color="black">
              <Text noOfLines={2} textAlign="center">
                {title}
              </Text>
            </Heading>
            <Input
              placeholder="Please enter the password here"
              onChange={handlePass}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSignin();
                }
              }}
              className="my-4"
            />
            {error && (
              <div className="text-center mb-4">
                <span className="mx-1">
                  This password is incorrect. Please contact the person who
                  invited you, or contact us at
                </span>
                <span className="cursor-pointer">
                  <a href="mailto:workwithus@1tm.io">workwithus@1tm.io</a>
                </span>
                .
              </div>
            )}
            <Box className="text-center">
              <Button
                width="50%"
                colorScheme="#FF6633"
                bgColor="warning"
                rounded="ellipse"
                onClick={() => {
                  handleSignin();
                }}
              >
                {t('Enter')}
              </Button>
            </Box>
          </Center>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default SignInModal;
