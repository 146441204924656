import { DefaultSeo } from 'next-seo';

export const DEFAULT_META = {
  title:
    '1TM - A Collaborative Community for Creators, Enablers, and Enthusiasts',
  description:
    'Share, discover, and launch your ideas with the passionate community and make your ideas happen!',
  URL: 'https://www.1tm.io',
  theme: '#FFFFFF',
  image: 'https://1tmiopost.s3.ap-southeast-1.amazonaws.com/metalink.png', // '/images/logo/logo-L.png',
};

export function DefaultSEO() {
  return (
    <DefaultSeo
      title={DEFAULT_META.title}
      description={DEFAULT_META.description}
      openGraph={{
        type: 'website',
        site_name: DEFAULT_META.title,
        description: DEFAULT_META.description,
        url: DEFAULT_META.URL,
        images: [
          {
            url: DEFAULT_META.image,
          },
        ],
      }}
      additionalMetaTags={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0',
        },
      ]}
      twitter={{
        site: DEFAULT_META.URL,
        cardType: 'summary_large_image',
      }}
    />
  );
}
