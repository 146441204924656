import { axios } from 'src/lib/axios';
import { useInfiniteQuery } from 'react-query';
import type { FollowResponse } from './types';

export function createFollow(data: any) {
  return axios.post<FollowResponse>('/follow', data).then((res) => res.data);
}

export function deleteFollow(values: any) {
  return axios
    .delete<FollowResponse>('/follow', {
      data: {
        ...values,
      },
    })
    .then((res) => res.data);
}

export function useFetchFollowers(userId: string, query?: Partial<any>) {
  const { ...params } = query;
  return useFollowerQuery({
    userId,
    params: {
      sortType: 'recency',
      ...params,
    },
  });
}

export function useFetchFollowing(userId: string, query?: Partial<any>) {
  const { ...params } = query;
  return useFollowingQuery({
    userId,
    params: {
      sortType: 'recency',
      ...params,
    },
  });
}

export function useFetchFollows(group: any, query?: Partial<any>) {
  const { ...params } = query;
  return useFetchFollow({
    group,
    params: {
      ...params,
    },
  });
}

export const fetchFollow = ({ group, params }: { group: any; params: any }) => {
  return axios
    .get<any>(`/user/follow/${group.group}`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => res.data);
};

export function useFetchFollow(req) {
  return useInfiniteQuery(
    [req, 'follower'],
    ({ pageParam = 1 }) => {
      return fetchFollow({
        ...req,
        params: {
          ...req.params,
          pageNum: pageParam,
          pageSize: 5,
        },
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      getNextPageParam: ({ next, pageNum = 1 }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export const fetchFollowerList = ({
  userId,
  params,
}: {
  userId: string;
  params: any;
}) =>
  axios
    .get<any>(`follower/${userId}`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => res.data);

export function useFollowerQuery(req) {
  return useInfiniteQuery(
    [req, 'follower'],
    ({ pageParam = 1 }) => {
      return fetchFollowerList({
        ...req,
        params: {
          ...req.params,
          pageNum: pageParam,
        },
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      getNextPageParam: ({ next, pageNum = 1 }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export const fetchFollowingList = ({
  userId,
  params,
}: {
  userId: string;
  params: any;
}) =>
  axios
    .get<any>(`following/${userId}`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => res.data);

export function useFollowingQuery(req) {
  return useInfiniteQuery(
    [req, 'following'],
    ({ pageParam = 1 }) => {
      return fetchFollowingList({
        ...req,
        params: {
          ...req.params,
          pageNum: pageParam,
        },
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      getNextPageParam: ({ next, pageNum = 1 }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}
