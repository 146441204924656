/**
 * Internal dependencies
 */
import OneLayer from 'src/components/common/checkList/one-layer';

function CategoryFilterContent({ selectList, onFilterChange, list }) {
  return (
    <OneLayer
      action={(categoryId, groupId = null) => {
        onFilterChange({
          type: 'CATEGORIES',
          value: categoryId,
          groupId,
        });
      }}
      list={list}
      selectList={selectList}
      isSearchOn
    />
  );
}

export default CategoryFilterContent;
