import { useEffect, useRef } from 'react';

export function useWindowEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  callback: (
    ev: WindowEventMap[K],
    options?: boolean | AddEventListenerOptions
  ) => any,
  options?: boolean | AddEventListenerOptions
): void {
  const savedCallback = useRef<
    (ev: WindowEventMap[K], options?: boolean | AddEventListenerOptions) => any
  >();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(
    () => {
      const eventListener = (event: WindowEventMap[K]) => {
        if (savedCallback.current) {
          return savedCallback.current(event, options);
        }
      };

      window.addEventListener(eventName, eventListener);

      return () => {
        window.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, options] // Re-run if eventName or element changes
  );
}
