import * as React from 'react';
import { Svg } from '../svg';

function SvgEnthuseV2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}

      <path
        d="M7.08342 12.0833C7.63595 12.0833 8.16585 11.8638 8.55655 11.4731C8.94726 11.0824 9.16675 10.5525 9.16675 10C9.16675 8.85 8.75008 8.33333 8.33342 7.5C7.44008 5.71417 8.14675 4.12167 10.0001 2.5C10.4167 4.58333 11.6667 6.58333 13.3334 7.91667C15.0001 9.25 15.8334 10.8333 15.8334 12.5C15.8334 13.266 15.6825 14.0246 15.3894 14.7323C15.0962 15.4401 14.6665 16.0831 14.1249 16.6248C13.5832 17.1665 12.9401 17.5961 12.2324 17.8893C11.5247 18.1825 10.7661 18.3333 10.0001 18.3333C9.23404 18.3333 8.47549 18.1825 7.76776 17.8893C7.06003 17.5961 6.41697 17.1665 5.87529 16.6248C5.33362 16.0831 4.90394 15.4401 4.61078 14.7323C4.31763 14.0246 4.16675 13.266 4.16675 12.5C4.16675 11.5392 4.52758 10.5883 5.00008 10C5.00008 10.5525 5.21957 11.0824 5.61028 11.4731C6.00098 11.8638 6.53088 12.0833 7.08342 12.0833V12.0833Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEnthuseV2);
export default ForwardRef;
