import Button from './Button';
import Checkbox from './Checkbox';
import Heading from './Heading';
import Input from './Input';
import Link from './Link';
import Radio from './Radio';
import Select from './Select';
import Switch from './Switch';
import Tabs from './Tabs';
import Textarea from './Textarea';
import CloseButton from './close-button';
import Modal from './modal';
import Popover from './popover';
import Tag from './tag';
import Badge from './badge';
import FormLabel from './form-label';
import FormError from './form-error';
import Menu from './menu';

export default {
  Badge,
  Button,
  Checkbox,
  CloseButton,
  FormError,
  FormLabel,
  Heading,
  Menu,
  Modal,
  Input,
  Link,
  Radio,
  Select,
  Switch,
  Tabs,
  Tag,
  Textarea,
  Popover,
};
