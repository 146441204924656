import { Icon, Link, Stack, StackProps, Text } from '@chakra-ui/react';

// import { useIsMobile } from 'src/hooks/use-is-mobile';
import { LICENSE_FEATURES } from 'src/app/license/types';
import { isLicense } from 'src/app/license/license';

export function CopyrightFooter({
  copyright,
  ...props
}: { copyright?: string } & StackProps) {
  if (!copyright || !isLicense(copyright)) {
    return null;
  }

  const copyrightData = LICENSE_FEATURES[copyright];
  return (
    <Stack {...props} py={0}>
      <div className="flex gap-1">
        {copyrightData.icons.map((icon, i) => (
          <Icon
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            borderRadius="50%"
            as={icon}
            className="text-gray-500"
            boxSize="30px"
            fontWeight="500"
          />
        ))}
      </div>
      {copyrightData.name !== 'All Rights Reserved' && (
        <Text
          textStyle="labelLight"
          fontSize={props?.fontSize ? props?.fontSize : '14px'}
          fontWeight="500"
          className="text-gray-500 pt-2"
        >
          This content is licensed under a{' '}
        </Text>
      )}
      {copyrightData.url ? (
        <Link
          fontSize={props?.fontSize ? props?.fontSize : '14px'}
          isExternal
          margin="0px !important"
          href={copyrightData.url}
          fontWeight="500"
          textDecoration="none !important"
          color="#0278E4 !important"
        >
          {copyrightData.name}
        </Link>
      ) : (
        <Text
          fontSize={props?.fontSize ? props?.fontSize : '14px'}
          margin="0px !important"
          fontWeight="500"
          textDecoration="none !important"
          className={`text-gray-500 ${
            copyrightData.name === 'All Rights Reserved' ? 'pt-2' : ''
          }`}
        >
          {copyrightData.name}
        </Text>
      )}
      .
    </Stack>
  );
}
