import * as React from 'react';
import { Svg } from '../svg';

function SvgMenu2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 12 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6a1 1 0 100-2 1 1 0 000 2zm0 7a1 1 0 100-2 1 1 0 000 2zm1 6a1 1 0 11-2 0 1 1 0 012 0z"
        fill="#4F5356"
      />
      <path
        d="M6.25 5a.25.25 0 01-.25.25v1.5A1.75 1.75 0 007.75 5h-1.5zM6 4.75a.25.25 0 01.25.25h1.5A1.75 1.75 0 006 3.25v1.5zM5.75 5A.25.25 0 016 4.75v-1.5A1.75 1.75 0 004.25 5h1.5zm.25.25A.25.25 0 015.75 5h-1.5c0 .966.784 1.75 1.75 1.75v-1.5zM6.25 12a.25.25 0 01-.25.25v1.5A1.75 1.75 0 007.75 12h-1.5zM6 11.75a.25.25 0 01.25.25h1.5A1.75 1.75 0 006 10.25v1.5zm-.25.25a.25.25 0 01.25-.25v-1.5A1.75 1.75 0 004.25 12h1.5zm.25.25a.25.25 0 01-.25-.25h-1.5c0 .966.784 1.75 1.75 1.75v-1.5zm0 8.5A1.75 1.75 0 007.75 19h-1.5a.25.25 0 01-.25.25v1.5zM4.25 19c0 .966.784 1.75 1.75 1.75v-1.5a.25.25 0 01-.25-.25h-1.5zM6 17.25A1.75 1.75 0 004.25 19h1.5a.25.25 0 01.25-.25v-1.5zM7.75 19A1.75 1.75 0 006 17.25v1.5a.25.25 0 01.25.25h1.5z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgMenu2);
export default ForwardRef;
