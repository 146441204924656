import * as React from 'react';
import { Svg } from '../svg';

function SvgDecreasedRating({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M1.068 0a.5.5 0 00-.385.82l3.933 4.72a.5.5 0 00.768 0L9.317.82A.5.5 0 008.932 0H1.068z"
        fill="#F63"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgDecreasedRating);
export default ForwardRef;
