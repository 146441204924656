/**
 * Internal dependencies
 */
import {
  SearchResultAssets,
  SearchResultCreation,
  SearchResultUser,
  SearchResultSpace,
  SearchResultCollection,
  SearchResultTopic,
} from 'src/components/common/search/searchResult';

import { GenreConfigType } from './types';
import { FILTER_TYPES, SORT_TYPES } from './constants';
import { FILTER_NAME } from './filter/constants';

export const GENRES: GenreConfigType = {
  // id is same as filterGenre
  CREATIONS: {
    filterType: FILTER_TYPES.CREATIONS,
    tab: 'Folios',

    filter: [FILTER_NAME.CATEGORIES],
    sortProperty: {
      [SORT_TYPES.NAME]: 'title',
      [SORT_TYPES.RECENTLY]: 'updatedAt',
      [SORT_TYPES.POPULARITY]: 'enthuseCount',
    },
    facet: ['title', 'content', 'categories', 'tags'],

    cardComponent: SearchResultCreation,
    viewAllShowLimit: 4,
    showTab: true,
  },
  CREATIVE_ASSETS: {
    filterType: FILTER_TYPES.CREATIVE_ASSETS,
    tab: 'Assets',

    filter: [FILTER_NAME.CATEGORIES],
    sortProperty: {
      [SORT_TYPES.NAME]: 'name',
      [SORT_TYPES.RECENTLY]: 'createdAt',
      [SORT_TYPES.POPULARITY]: 'enthusesCount',
    },
    facet: ['name', 'description', 'categories', 'tags'],

    cardComponent: SearchResultAssets,
    viewAllShowLimit: 4,
    showTab: true,
  },
  USERS: {
    filterType: FILTER_TYPES.USERS,
    tab: 'People',

    filter: [
      FILTER_NAME.CATEGORIES,
      FILTER_NAME.LANGUAGES,
      FILTER_NAME.SERVICES,
      FILTER_NAME.SKILLS,
    ], // 'passions'
    sortProperty: {
      [SORT_TYPES.NAME]: 'name',
      [SORT_TYPES.RECENTLY]: 'createdAt',
      [SORT_TYPES.POPULARITY]: 'numOfFollowers',
    },
    facet: ['lastName', 'firstName', 'username', 'email'],

    cardComponent: SearchResultUser,
    viewAllShowLimit: 3,
    showTab: true,
  },
  SPACES: {
    filterType: FILTER_TYPES.SPACES,
    tab: 'Projects',

    filter: [FILTER_NAME.CATEGORIES],
    sortProperty: {
      [SORT_TYPES.NAME]: 'title',
      [SORT_TYPES.RECENTLY]: 'createdAt',
      [SORT_TYPES.POPULARITY]: 'followersCount',
    },
    facet: ['title', 'categories'],

    cardComponent: SearchResultSpace,
    viewAllShowLimit: 2,
    showTab: false,
  },
  COLLECTIONS: {
    filterType: FILTER_TYPES.COLLECTIONS,
    tab: 'Inspirations',

    filter: [],
    sortProperty: {},
    facet: [''],

    cardComponent: SearchResultCollection,
    viewAllShowLimit: 3,
    showTab: false,
  },
  TOPICS: {
    filterType: FILTER_TYPES.TOPICS,
    tab: 'Topics',

    filter: [],
    sortProperty: {
      [SORT_TYPES.NAME]: 'title',
      [SORT_TYPES.POPULARITY]: 'posts',
    },
    facet: [''],

    cardComponent: SearchResultTopic,
    viewAllShowLimit: 4,
    showTab: true,
  },
};
