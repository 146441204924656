import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useRouter } from 'next/router';
import { createStandaloneToast } from '@chakra-ui/react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { useContext } from 'react';
import useProfileQuery from 'src/app/profile/use-profile-query';
import { CacheKey } from 'src/constants/cache-key';
import { createFollow, deleteFollow } from 'src/app/follow/service';
import { deleteUploadImage, imageOptimize } from 'src/service/image';
import { standaloneToast } from 'src/components/toast';
import { useUploadImage } from 'src/hooks';
import type { FollowRequest } from 'src/app/follow/types';
import type { PageRequestQuery } from 'src/types';
import { axios } from 'src/lib/axios';
import { RestResponse, RestPageResponse } from 'src/types';
import { getCookie } from 'src/lib/cookies';
import { COOKIES_AUTH_TOKEN } from 'src/constants/cookies';
import { logger } from 'src/lib/logger';
import { deleteEpisode } from './episode/service';
import * as Service from './service';
import { SpaceResponse } from '../space/types';

export function useRepplQuery(id?: string) {
  const authToken = getCookie(COOKIES_AUTH_TOKEN);
  const renderType = authToken ? 'csr' : 'ssr';
  return useQuery(
    [CacheKey.reppl, id, renderType],
    () => Service.getReppl(id),
    {
      enabled: !!id,
    }
  );
}

export function useProjectQuery({ id, params = { pinned: false } }) {
  return useQuery([CacheKey.reppl, id, params], () =>
    Service.getSpaceProject(id, params)
  );
}

export function useSpaceQuery(id?: string) {
  const authToken = getCookie(COOKIES_AUTH_TOKEN);
  const renderType = authToken ? 'csr' : 'ssr';
  return useQuery(
    [CacheKey.spaces, id, renderType],
    () => Service.getSpaces(id),
    {
      enabled: !!id,
    }
  );
}

export function useRepplEpisodesQuery({
  repplId,
  params = { sortType: 'RECENCY', episodeType: 'ALL' },
  enabled = true,
}: {
  repplId?: string;
  params?: PageRequestQuery;
  enabled?: boolean;
} = {}) {
  return useInfiniteQuery(
    [CacheKey.episodeList, repplId, params],
    ({ pageParam = 1 }) =>
      Service.getRepplEpisodes({
        ...params,
        repplId,
        pageNum: pageParam,
      }),
    {
      enabled,
      getNextPageParam: (lastGroup) => {
        return lastGroup.pageNum;
      },
    }
  );
}

export function useRepplEpisodeDeleteMutation() {
  const queryClient = useQueryClient();
  return useMutation(deleteEpisode, {
    onSettled: () => {
      queryClient.invalidateQueries(CacheKey.episodeList);
    },
  });
}

export function useCreateRepplMutation() {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext) as UserContextType;
  const { data: profile } = useProfileQuery(user?.username);

  return useMutation(Service.createReppl, {
    onSuccess: (res, values) => {
      if (res.code === 1000 && res.data?.id) {
        queryClient.setQueryData<Service.RepplResponse>(
          [CacheKey.reppl, res.data.id],
          (p) => ({
            ...p,
            creator: {
              userID: profile.id,
              username: profile.username,
              profileImgURL: imageOptimize(profile.profileImgURL),
              userTitle: profile.title,
              firstName: profile.firstName,
              lastName: profile.lastName,
              motto: profile.motto,
              about: profile.about,
              isFollowed: profile.isFollowed,
              count: {
                episode: 0,
                episodeEnthuse: 0,
                follower: 0,
                studio: 0,
                studioEnthuse: 0,
              },
            },
            reppl: {
              ...p?.reppl,
              id: res.data.id,
              ...values,
              isOwner: true,
            },
          })
        );
      }
    },
    onError: () => {
      standaloneToast({
        title: '😢 Create Space failed, please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });
}

export function useCreateSpaceMutation() {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext) as UserContextType;
  const { data: profile } = useProfileQuery(user?.username);

  return useMutation(Service.createSpace, {
    onSuccess: (res, values) => {
      if (res.code === 1000 && res.data?.id) {
        queryClient.setQueryData<Service.SpaceResponse>(
          [CacheKey.reppl, res.data.id],
          (p) => ({
            ...p,
            creator: {
              userID: profile.id,
              username: profile.username,
              profileImgURL: imageOptimize(profile.profileImgURL),
              userTitle: profile.title,
              firstName: profile.firstName,
              lastName: profile.lastName,
              motto: profile.motto,
              about: profile.about,
              isFollowed: profile.isFollowed,
              count: {
                episode: 0,
                episodeEnthuse: 0,
                follower: 0,
                studio: 0,
                studioEnthuse: 0,
              },
            },
            reppl: {
              ...p?.reppl,
              id: res.data.id,
              ...values,
              isOwner: true,
            },
          })
        );
      }
    },
    onError: () => {
      standaloneToast({
        title: '😢 Create Space failed, please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });
}

export function useUpdateRepplMutation(rid: string) {
  const queryClient = useQueryClient();

  return useMutation(Service.updateReppl, {
    onSuccess: (res, values) => {
      if (res.code === 1000) {
        queryClient.setQueryData<Service.RepplResponse>(
          [CacheKey.reppl, values.id],
          (p) => ({
            ...p,
            reppl: {
              ...p?.reppl,
              ...values.data,
            },
          })
        );
        if (values.notify) {
          standaloneToast({
            position: 'bottom-right',
            title: '🎉 Announcement updated!',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([CacheKey.repplPublishable, rid]);
    },
  });
}

export const bottomRightToast = (title, description, status) => {
  const toast = createStandaloneToast();

  toast({
    title,
    description,
    status,
    duration: 3000,
    isClosable: true,
    position: 'bottom-right',
  });
};

export function useUpdateSpacesMutation() {
  return useMutation(
    (values: { id: string; data: any }) =>
      axios
        .put<RestResponse>(`/spaces/${values.id}`, values.data)
        .then((res) => res.data),
    {
      onSuccess: () => {
        bottomRightToast('', 'Update spaces successfully!', 'success');
      },
      onError: () => {
        bottomRightToast(
          'Failure 😢',
          'Update spaces failure, please check fields and try again.',
          'error'
        );
      },
    }
  );
}

export function useUpdatePinSpacesMutation(pin = false) {
  const pinStatus = pin ? 'Pin' : 'Unpin';
  return useMutation(
    (values: { id: string; data: any }) =>
      axios
        .patch<RestResponse>(`/topics/${values.id}/pin`, values.data)
        .then((res) => res.data),
    {
      onSuccess: () => {
        bottomRightToast('', `${pinStatus} project successfully!`, 'success');
      },
      onError: () => {
        bottomRightToast(
          'Failure 😢',
          `${pinStatus} project failure, please check fields and try again.`,
          'error'
        );
      },
    }
  );
}

export function useUpdateRepplStatus() {
  const queryClient = useQueryClient();
  return useMutation(Service.updateRepplStatus, {
    onSuccess: (res, values) => {
      if (res.code === 1000) {
        queryClient.setQueryData<Service.RepplResponse>(
          [CacheKey.reppl, values.id],
          (p) => ({
            ...p,
            reppl: {
              ...p.reppl,
              draft: values.draft,
            },
          })
        );
        bottomRightToast('🎉 Published successfully!', '', 'success');
      }
    },
  });
}

export function useRepplPublishableQuery(rid: string, isOwner: boolean) {
  return useQuery(
    [CacheKey.repplPublishable, rid],
    () => Service.getRepplPublishable(rid),
    {
      enabled: !!rid && isOwner,
    }
  );
}

export function useRepplAvatarMutation(rid: string) {
  const queryClient = useQueryClient();
  return useUploadImage(
    { id: rid, areaType: 'SPACE', imgType: 'space_avatar' },
    (res) => {
      queryClient.setQueryData<SpaceResponse>([CacheKey.space, rid], (p) => ({
        ...p,
        avatarImgURL: imageOptimize(res.imgURL),
      }));
    }
  );
}

export function useRepplCoverMutation(rid: string) {
  const queryClient = useQueryClient();
  return useUploadImage(
    { id: rid, areaType: 'SPACE', imgType: 'space_cover' },
    (res) => {
      queryClient.setQueryData<SpaceResponse>([CacheKey.space, rid], (p) => ({
        ...p,
        coverImgURL: imageOptimize(res.imgURL),
      }));
    }
  );
}

export function useRepplCoverDeleteMutation(rid: string) {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      deleteUploadImage({
        areaType: 'SPACE',
        imgType: 'space_cover',
        id: rid,
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries([CacheKey.space, rid]);
      },
    }
  );
}

export function useRepplFollowMutation(rid: string) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { isLogin: isAuth } = useContext(UserContext) as UserContextType;
  return useMutation(
    (values: FollowRequest) => {
      if (!isAuth) {
        router.push({ query: { ...router.query, page: 'signIn' } });
        return;
      }
      return values.follow ? createFollow(values) : deleteFollow(values);
    },
    {
      onMutate: (values) => {
        if (!isAuth) return;
        const queryKey = [CacheKey.reppl, rid];
        queryClient.cancelQueries(queryKey);

        const prevRepplCache = queryClient.getQueryData<Service.RepplResponse>(
          queryKey
        );
        const updater = (prevCache: Service.RepplResponse) => ({
          ...prevCache,
          reppl: {
            ...prevRepplCache?.reppl,
            isFollowed:
              values.group === 'REPPL' && !prevRepplCache?.reppl.isFollowed,
            followerCount:
              values.group === 'REPPL' && !prevRepplCache?.reppl.isFollowed
                ? prevRepplCache?.reppl.followerCount + 1
                : prevRepplCache?.reppl.followerCount - 1,
          },
        });

        queryClient.setQueryData(queryKey, updater);

        return () => queryClient.setQueryData(queryKey, prevRepplCache);
      },
    }
  );
}

export function useFollow(rid: string) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { isLogin: isAuth } = useContext(UserContext) as UserContextType;
  return useMutation(
    (values: FollowRequest) => {
      if (!isAuth) {
        router.push({ query: { ...router.query, page: 'signIn' } });
        return;
      }

      return values.follow ? createFollow(values) : deleteFollow(values);
    },
    {
      onSettled: () => {
        const queryKey = [CacheKey.reppl, rid];
        queryClient.invalidateQueries(queryKey);
      },
      onError: (err) => {
        logger.error(err);
      },
    }
  );
}

export function useRepplCreatorFollowMutation(rid: string) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { isLogin: isAuth } = useContext(UserContext) as UserContextType;
  return useMutation(
    (values: FollowRequest) => {
      if (!isAuth) {
        router.push({ query: { ...router.query, page: 'signIn' } });
        return;
      }

      return values.follow ? createFollow(values) : deleteFollow(values);
    },
    {
      onMutate: (values) => {
        if (!isAuth) return;

        const queryKey = [CacheKey.reppl, rid];
        queryClient.cancelQueries(queryKey);

        const prevRepplCache = queryClient.getQueryData<Service.RepplResponse>(
          queryKey
        );
        const updater = (prevCache: Service.RepplResponse) => ({
          ...prevCache,
          creator: {
            ...prevCache.creator,
            isFollowed:
              values.group === 'USER' && !prevCache.creator.isFollowed,
            count: {
              ...prevCache.creator.count,
              follower:
                values.group === 'USER' && !prevCache.creator.isFollowed
                  ? prevCache.creator.count.follower + 1
                  : prevCache.creator.count.follower - 1,
            },
          },
        });

        queryClient.setQueryData(queryKey, updater);

        return () => queryClient.setQueryData(queryKey, prevRepplCache);
      },
    }
  );
}

export function useLookupUserQuery({
  rid,
  isModalOpen = true,
  params,
}: {
  rid: string;
  isModalOpen?: boolean;
  params?: Service.LookupUserRequest;
}) {
  return useQuery(
    [CacheKey.lookupUsers, rid, params],
    ({ pageParam = 1 }) =>
      Service.getLookupUsers(rid, {
        ...params,
        pageNum: pageParam,
      }),
    { enabled: !!rid && isModalOpen }
  );
}

export function useInvitationMutation(rid: string) {
  const queryClient = useQueryClient();

  return useMutation(Service.createInvitation, {
    onSettled: () => {
      queryClient.invalidateQueries([CacheKey.invitationList, rid]);
    },
  });
}

export function useDeleteDeclinedInvitation(rid: string) {
  const queryClient = useQueryClient();

  return useMutation(Service.deleteDeclinedInvitation, {
    onSettled: () => {
      queryClient.invalidateQueries([CacheKey.invitationList, rid]);
    },
  });
}

export function useInvitationListQuery(rid: string) {
  return useQuery(
    [CacheKey.invitationList, rid],
    () => Service.getInvitationList({ rid }),
    { enabled: !!rid }
  );
}
export function useInvitesListQuery(rid: string) {
  return useQuery(
    [CacheKey.invitationList, rid],
    () => Service.getInvitesList({ rid }),
    { enabled: !!rid }
  );
}

export function useInvitesSelf(spaceId: string) {
  return useQuery(
    [CacheKey.spaces, spaceId],
    () => Service.getInvitesSelf({ spaceId }),
    {
      enabled: !!spaceId,
    }
  );
}

export function getSubmissions({
  rid,
  status,
  params,
}: {
  rid: string;
  status: string;
  params: PageRequestQuery & {
    projectID?: string;
  };
}) {
  return axios
    .get<RestPageResponse<any>>(`/creations/project/${rid}/${status}`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => ({
      ...res.data,
      pageNum: params?.pageNum,
    }));
}

export function inviteRespond(invite, accepted, queryClient) {
  return axios
    .put<RestResponse>(`/project/invite/respond`, {
      inviteID: +invite,
      accepted,
    })
    .then((res) => res.data)
    .then(() => {
      queryClient.invalidateQueries();
    });
}

export function useFetchSubmissions(req) {
  return useInfiniteQuery(
    [req],
    ({ pageParam = 1 }) => {
      if (!req.rid) {
        return;
      }
      return getSubmissions({
        ...req,
        params: {
          ...req.params,
          pageNum: pageParam,
        },
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: !!req.rid && !!req.status,
      // getNextPageParam: ({ next, pageNum = 1 }) => {
      //   if (next) {
      //     return pageNum + 1;
      //   }
      //   return undefined;
      // },
    }
  );
}
