/**
 * External dependencies
 */
import { useContext } from 'react';

/**
 * Internal dependencies
 */
import FolioContext from './context';
import { FolioContextType } from './types';

export default () => useContext(FolioContext) as FolioContextType;
