import * as React from 'react';
import { Svg } from '../svg';

function SvgLinkedinV3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.612 14.712v-3.904c0-2.091-1.116-3.065-2.605-3.065-1.201 0-1.74.661-2.04 1.125v-.965H8.704c.03.64 0 6.81 0 6.81h2.263V10.91c0-.203.015-.407.075-.552.163-.407.536-.828 1.16-.828.82 0 1.147.625 1.147 1.54v3.643h2.263zM6.32 6.974c.789 0 1.28-.523 1.28-1.177-.014-.668-.491-1.176-1.265-1.176-.774 0-1.28.508-1.28 1.176 0 .654.49 1.177 1.25 1.177h.015zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm-2.549-5.288V7.903H5.188v6.81h2.263z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgLinkedinV3);
export default ForwardRef;
