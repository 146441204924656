import * as React from 'react';
import { Svg } from '../svg';

function SvgGooglemaps({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#googlemaps_svg__clip0_4815_67667)">
        <path
          d="M11.283.392A8.407 8.407 0 008.754.004a8.36 8.36 0 00-6.412 2.988l3.954 3.325L11.283.392z"
          fill="#1A73E8"
        />
        <path
          d="M2.341 2.992a8.342 8.342 0 00-1.96 5.38c0 1.572.312 2.85.829 3.992l5.086-6.047L2.34 2.992z"
          fill="#EA4335"
        />
        <path
          d="M8.754 5.176a3.203 3.203 0 012.444 5.27l4.982-5.924A8.375 8.375 0 0011.277.397L6.301 6.322a3.192 3.192 0 012.453-1.146z"
          fill="#4285F4"
        />
        <path
          d="M8.754 11.578a3.203 3.203 0 01-3.201-3.201A3.173 3.173 0 016.3 6.32L1.21 12.37c.871 1.927 2.316 3.476 3.803 5.427l6.184-7.35a3.198 3.198 0 01-2.443 1.132z"
          fill="#FBBC04"
        />
        <path
          d="M11.075 19.8c2.794-4.367 6.047-6.351 6.047-11.428a8.334 8.334 0 00-.942-3.86L5.018 17.797c.473.62.951 1.28 1.416 2.008C8.129 22.428 7.66 24 8.754 24c1.094 0 .625-1.577 2.32-4.2z"
          fill="#34A853"
        />
      </g>
      <defs>
        <clipPath id="googlemaps_svg__clip0_4815_67667">
          <path fill="#fff" transform="translate(.375)" d="M0 0h16.75v24H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgGooglemaps);
export default ForwardRef;
