import * as React from 'react';
import { Svg } from '../svg';

function SvgNotVerified({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10 2.5a3 3 0 00-2.542 1.4 3 3 0 00-.75-.083 3 3 0 00-2.016.883 3 3 0 00-.784 2.767A3 3 0 002.5 10a3 3 0 001.408 2.542 3 3 0 00.792 2.766 3 3 0 002.792.8A3 3 0 0010 17.5a3 3 0 002.533-1.392 3 3 0 003.584-3.583 3 3 0 00-.009-5.058 3 3 0 00-3.583-3.584A3 3 0 0010 2.5z"
        stroke="#BBC0C5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 10l1.667 1.667 3.347-3.348"
        stroke="#BBC0C5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNotVerified);
export default ForwardRef;
