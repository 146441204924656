import * as React from 'react';
import { Svg } from '../svg';

function SvgEnthused({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M7 18.634A6.5 6.5 0 012.547 7.4C3.71 6.304 6.567 4.334 6.134 0c5.2 3.467 7.8 6.934 2.6 12.134.866 0 2.166 0 4.333-2.14.234.67.434 1.39.434 2.14a6.5 6.5 0 01-6.5 6.5z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEnthused);
export default ForwardRef;
