/* eslint-disable no-useless-escape */

import { Tweet } from './tweet/tweet';

/* eslint-disable no-param-reassign */
export const SERVICES = {
  vimeo: {
    regex: /(?:http[s]?:\/\/)?(?:www.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
    embedUrl:
      'https://player.vimeo.com/video/<%= remote_id %>?title=0&byline=0',
    html: '<iframe style="width:100%;" height="320" frameborder="0"></iframe>',
    height: 720,
    width: 1280,
  },
  youtube: {
    regex: /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/,
    embedUrl: 'https://www.youtube.com/embed/<%= remote_id %>',
    html:
      '<iframe frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>',
    height: 720,
    width: 1280,
    stretched: true,
    id: ([id, params]) => {
      if (!params && id) {
        return id;
      }

      const paramsMap = {
        start: 'start',
        end: 'end',
        t: 'start',
        // eslint-disable-next-line camelcase
        time_continue: 'start',
        list: 'list',
      };

      params = params
        .slice(1)
        .split('&')
        .map((param) => {
          const [name, value] = param.split('=');

          if (!id && name === 'v') {
            id = value;

            return;
          }

          if (!paramsMap[name]) {
            return;
          }

          return `${paramsMap[name]}=${value}`;
        })
        .filter((param) => !!param);

      return `${id}?${params.join('&')}`;
    },
  },
  codepen: {
    regex: /https?:\/\/codepen\.io\/([^\/\?\&]*)\/pen\/([^\/\?\&]*)/,
    embedUrl:
      'https://codepen.io/<%= remote_id %>?height=300&theme-id=0&default-tab=css,result&embed-version=2',
    html:
      "<iframe loading='lazy' height='300' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%;'></iframe>",
    height: 300,
    width: 600,
    id: (ids) => ids.join('/embed/'),
  },
  instagram: {
    regex: /https?:\/\/www\.instagram\.com\/p\/([^\/\?\&]+)\/?/,
    embedUrl: 'https://www.instagram.com/p/<%= remote_id %>/embed',
    html:
      '<iframe width="400" height="700" style="margin: 0 auto;" frameborder="0" scrolling="no" allowtransparency="true"></iframe>',
    height: 704,
    width: 400,
  },
  twitter: {
    regex: /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\/.*)?$/,
    render: Tweet,
    embedUrl: 'https://twitter.com/<%= remote_id %>',
    html:
      '<iframe width="600" height="600" style="margin: 0 auto;" frameborder="0" scrolling="no" allowtransparency="true"></iframe>',
    height: 300,
    width: 600,
    id: (ids) => ids.join('/status/'),
  },
  spotify: {
    regex: /^https?:\/\/open\.spotify\.com\/track\/(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/,
    embedUrl: 'https://open.spotify.com/embed/track/<%= remote_id %>',
    html:
      '<iframe width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>',
    height: 300,
    width: 600,
    id: ([id, params]) => {
      if (!params && id) {
        return id;
      }

      const paramsMap = {
        start: 'start',
        end: 'end',
        t: 'start',
        // eslint-disable-next-line camelcase
        time_continue: 'start',
        list: 'list',
      };

      params = params
        .slice(1)
        .split('&')
        .map((param) => {
          const [name, value] = param.split('=');

          if (!id && name === 'v') {
            id = value;

            return;
          }

          if (!paramsMap[name]) {
            return;
          }

          return `${paramsMap[name]}=${value}`;
        })
        .filter((param) => !!param);

      return `${id}?${params.join('&')}`;
    },
  },
} as const;

const prepare = () => {
  const entries = Object.entries(SERVICES);

  const services = entries.reduce((result, [key, service]) => {
    if (!(key in result)) {
      result[key] = service;

      return result;
    }

    result[key] = { ...result[key], ...service };

    return result;
  }, {});

  const patterns = entries.reduce((result, [key, item]) => {
    result[key] = item.regex;

    return result;
  }, {});

  return {
    services,
    patterns,
  };
};

const { services, patterns } = prepare();

export { services };

export const findPattern = (text: string) => {
  const pattern = Object.entries(patterns)
    .map(([key, value]) => ({ key, value }))
    .find((substitute) => {
      // @ts-ignore
      const execResult = substitute.value.exec(text);

      if (!execResult) {
        return false;
      }

      return text === execResult.shift();
    });

  if (!pattern) {
    return;
  }

  return { key: pattern.key, data: text };
};

export default SERVICES;
