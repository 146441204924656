/**
 * Internal dependencies
 */
import OneLayer from 'src/components/common/checkList/one-layer';

function LanguageFilterContent({ selectList, onFilterChange, list }) {
  return (
    <OneLayer
      action={(languageId) => {
        onFilterChange({
          type: 'LANGUAGES',
          value: languageId,
        });
      }}
      list={list}
      selectList={selectList}
      isSearchOn
    />
  );
}

export default LanguageFilterContent;
