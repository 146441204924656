import {
  Text,
  Box,
  Tab,
  Tabs,
  TabList,
  Skeleton,
  SkeletonCircle,
} from '@chakra-ui/react';
import SvgArrowRight from 'src/components/icons/arrow-right';
import { Avatar } from 'src/components/avatar/avatar';
import Link from 'next/link';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { useContext } from 'react';
import { imageOptimize } from 'src/service/image';
import dayHepler from 'src/heplers/day';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import categories from 'src/configs/categories';

export function AgoraMultiTab({
  windowTitle,
  tabIndex,
  tabHandler,
  tabs,
  tablist,
  withFollow = false,
  withSubtext = false,
  isLink = '',
  withAvatar = false,
  withEpcount = false,
  isAlgolia = false,
  isFlex = true,
  isFetching = false,
  path = false,
  withRanking = false,
  withPostCount = false,
  withFollowPost = false,
  onFollow,
}: {
  windowTitle: string;
  tabIndex?: number;
  tabHandler?: (value: any) => void;
  tabs?: any[];
  tablist?: any[];
  withFollow?: boolean;
  withSubtext?: boolean;
  isLink?: string;
  withAvatar?: boolean;
  withEpcount?: boolean;
  isAlgolia?: boolean;
  isFlex?: boolean;
  isFetching?: boolean;
  path?: boolean;
  withRanking?: boolean;
  withPostCount?: boolean;
  withFollowPost?: boolean;
  onFollow?: (any) => void;
}) {
  const { user } = useContext(UserContext) as UserContextType;
  const isScrollable = windowTitle === 'My Spaces' ? '250px' : '';
  const uiSettings = isFlex ? ['flex', 'px-3'] : [];
  const getTagLink = (data) => {
    let newData = data?.replace(/ /g, '-');
    newData = newData?.replace(/#/g, '');
    return newData;
  };
  function getPath(data) {
    if (windowTitle === 'Trending Users') {
      return `/${data?.username}`;
    }
    if (windowTitle === 'Your Following') {
      if (tabIndex === 0) {
        return `/${data?.username}`;
      }
      if (tabIndex === 1) {
        return `/space/${data?.id}`;
      }
      if (data?.areaType === 'REPPL') {
        return `/space/${data?.areaID}/collections/${data?.uuid}`;
      }
      return `/${data?.areaID}/collections/${data?.uuid}`;
    }
    if (windowTitle === 'Trending Spaces') {
      return `/space/${data?.id}`;
    }
    if (windowTitle === 'My Spaces') {
      return `/space/${data?.objectID}`;
    }
    if (windowTitle === 'Trending Tribes') {
      return `/feed/${getTagLink(data?.title)}`;
    }
    if (windowTitle === 'Following Tribes') {
      return `/feed/${getTagLink(data?.title)}`;
    }
  }

  const getHref = (data) => {
    if ('href' in data) {
      return data.href;
    }
    return `${window.location.origin}/${data.username}`;
  };

  function getTabInfo(data, ishref) {
    switch (tabIndex) {
      case 0:
        if (data === 'link') {
          if (ishref) {
            return '/';
          }
          return 'creators';
        }
        if (windowTitle === 'Recent Visits') {
          return 'anyone';
        }
        return 'any People';

      case 1:
        if (data === 'link') {
          if (ishref) {
            return '/space';
          }
          return 'Spaces';
        }
        return 'any Spaces';

      case 2:
        if (data === 'link') {
          if (ishref) {
            return '/';
          }
          return 'Collections';
        }
        return 'any Collection';

      default:
        if (data === 'link') {
          if (ishref) {
            return '/';
          }
          return 'creators';
        }
        return 'creators';
    }
  }

  function avatarByTitle(data) {
    if (windowTitle === 'My Spaces') {
      return imageOptimize({
        url: data?.repplCoverImgUrl,
        imgContainer: 'avatar',
      });
    }
    if (windowTitle === 'Trending Spaces') {
      return data?.profileImgURL
        ? imageOptimize({ url: data?.profileImgURL, imgContainer: 'avatar' })
        : imageOptimize({ url: data?.avatarURL, imgContainer: 'avatar' });
    }
    if (windowTitle === 'Your Following') {
      if (data?.alternate_images) {
        return data?.alternate_images;
      }
      if (data?.profileImgURL) {
        return imageOptimize({
          url: data?.profileImgURL,
          imgContainer: 'avatar',
        });
      }
      if (data?.avatarURL) {
        return imageOptimize({ url: data?.avatarURL, imgContainer: 'avatar' });
      }
    }
    return imageOptimize({
      url: data?.profileImgURL,
      imgContainer: 'avatar',
    });
  }

  function getRepplTitle(data) {
    if (windowTitle === 'My Spaces') {
      return data?.repplTitle;
    }
    if (windowTitle === 'Your Following' && tabIndex === 2) {
      return data?.name;
    }
    if (windowTitle === 'Following Tribes' && tabIndex === 0) {
      return `#${data?.title}`;
    }
    if (windowTitle === 'Recent Visits' && tabIndex === 2) {
      const is = categories.filter((cat) => cat.displayName === data?.title);
      if (is.length === 0) {
        return `#${data?.title}`;
      }
      return data?.title;
    }
    return data?.username ? data?.username : data?.title;
  }

  function getTitleUsername(data) {
    return getRepplTitle(data);
  }

  function getKey(data) {
    if (data?.username) {
      return data?.username + windowTitle;
    }
    if (data?.title) {
      return data?.title + windowTitle;
    }
    return JSON.stringify(data);
  }
  const checkTabValid = () => {
    if (!tablist && tabs?.length >= 0) {
      return true;
    }
    if (tablist && !tablist[0] && tabs?.length >= 0) {
      return true;
    }
    if (tablist && tablist?.length === 0 && tabs?.length >= 0) {
      return true;
    }
    return false;
  };
  const isMobile = useIsMobile();

  return (
    <Box
      className="bg-white min-h-0 p-6 sm:p-6 my-4 mb-6 dark:bg-gray-900"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06)"
      borderRadius={{ base: '0px', md: '16px', sm: '16px' }}
      mx={{ base: '0', md: '16px', sm: '16px' }}
    >
      {isLink !== '' ? (
        <Link prefetch={false} href={isLink}>
          <Box
            className="flex font-semibold pb-4 text-gray-900 leading-5 relative dark:text-white cursor-pointer"
            pb={windowTitle === 'Trending Spaces' && '26px'}
            p={windowTitle === 'Trending Users' && '0 0 26px 0'}
          >
            <Text
              fontSize={{ base: '14px', md: '16px' }}
              lineHeight={{ base: '18px', md: '20px' }}
              fontWeight={{ base: '700', md: '600' }}
            >
              {windowTitle}
            </Text>
            <SvgArrowRight position="absolute" right={0} w="25px" h="auto" />
          </Box>
        </Link>
      ) : (
        <Box
          className="flex mb-4 text-gray-900 leading-5 relative dark:text-white"
          pb={windowTitle === 'Trending Spaces' && '10px'}
          p={windowTitle === 'Trending Users' && '0 0 10px 0'}
        >
          <Text
            fontSize={{ base: '14px', md: '16px' }}
            lineHeight={{ base: '18px', md: '20px' }}
            fontWeight={{ base: '700', md: '600' }}
          >
            {windowTitle}
          </Text>
        </Box>
      )}
      <div className="overflow-auto" style={{ height: isScrollable }}>
        {tabs?.length > 0 && !isFetching && (
          <Box
            py={{ base: 0, md: 0 }}
            px={{ base: 0, md: 0 }}
            mb={{ base: '16px', md: '22px' }}
            width="100%"
          >
            <Tabs index={tabIndex} onChange={tabHandler}>
              <TabList marginTop={{ base: '7px', md: '0px' }} border="none">
                {tabs.map((tab) => (
                  <Tab
                    key={`${tab}${windowTitle}`}
                    _selected={{
                      color: '#242526',
                      border: '1px solid #242526',
                    }}
                    _active={{
                      backgroundColor: 'unset',
                    }}
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="white"
                    textStyle="labelLight"
                    color="#9B9FA4"
                    fontSize="12px"
                    fontWeight="700"
                    lineHeight="18px"
                    px="10px"
                    borderRadius="full"
                    h="26px"
                    mr="10px"
                    transition="0ms"
                  >
                    {tab}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </Box>
        )}
        <Box>
          {isFetching && (
            <>
              {[0, 1, 2, 3, 4].map((i, listIndex) => (
                <Box
                  key={getKey({ title: `${i}skeleon` })}
                  className={`${windowTitle !== 'Trending Tribes' && 'pt-4'} ${
                    windowTitle === 'Trending Users' && isMobile && 'pb-0'
                  } relative ${uiSettings[0]}`}
                  pb={windowTitle === 'Trending Spaces' && '0'}
                >
                  {withRanking && windowTitle !== 'Trending Tribes' && (
                    <div className="text-gray-300 text-xs font-bold pr-2">
                      0{listIndex + 1}
                    </div>
                  )}
                  {withAvatar && (
                    <SkeletonCircle width={40} height={40} mr={1} />
                  )}
                  <Box
                    width="75%"
                    className={`relative grid w-full ${uiSettings[1]} `}
                    pl={windowTitle === 'Trending Users' && '8px'}
                  >
                    {withPostCount ? (
                      <div className="my-1 flex">
                        {withRanking && (
                          <div className="text-gray-300 text-xs font-bold pr-4">
                            0{listIndex + 1}
                          </div>
                        )}
                        <Skeleton className="w-10/12" />
                      </div>
                    ) : (
                      <Skeleton height="12px" />
                    )}
                    {withSubtext && <Skeleton className="mt-2" height="10px" />}
                    {withFollowPost && (
                      <div
                        className={`flex justify-between relative py-2 ${
                          withPostCount && 'pl-8'
                        }`}
                      >
                        <div className="flex">
                          <Skeleton>
                            <span className="text-gray-500 text-xs" />
                          </Skeleton>
                          <span className="text-gray-500 mx-2 text-xs">•</span>
                          <Skeleton>
                            <span className="text-gray-500 text-xs" />
                          </Skeleton>
                        </div>
                      </div>
                    )}
                    {withFollow && (
                      <div
                        className={`${
                          windowTitle === 'Trending Users'
                            ? 'justify-between'
                            : 'justify-between'
                        } flex relative py-2 ${withPostCount && 'pl-8'}`}
                      >
                        {windowTitle !== 'Trending Users' ? (
                          <div className="flex">
                            <Skeleton width="60px" />
                            <span className="text-gray-500 mx-2 text-xs">
                              •
                            </span>
                            <Skeleton width="60px" />
                          </div>
                        ) : (
                          <div className="flex">
                            <Skeleton width="60px" />
                          </div>
                        )}
                      </div>
                    )}
                  </Box>
                </Box>
              ))}
            </>
          )}
          {tablist &&
            tablist[0] &&
            tablist?.map((list, listIndex) => {
              return (
                <Box
                  key={getKey(list)}
                  className={`${windowTitle !== 'Trending Tribes' && 'pt-4'} ${
                    windowTitle === 'Trending Users' && isMobile && 'pb-0'
                  } relative ${uiSettings[0]}`}
                  pb={windowTitle === 'Trending Spaces' && '0'}
                >
                  {withRanking && windowTitle !== 'Trending Tribes' && (
                    <div className="text-gray-300 text-xs font-bold pr-2">
                      0{listIndex + 1}
                    </div>
                  )}
                  {withAvatar && !isAlgolia && (
                    <>
                      {!(windowTitle === 'Recent Visits' && tabIndex === 2) && (
                        <Link
                          prefetch={false}
                          href={path ? getPath(list) : getHref(list)}
                        >
                          <div className="cursor-pointer">
                            <Avatar
                              name={avatarByTitle(list)}
                              data-testid={avatarByTitle(list)}
                              bg="$mode.400"
                              width={40}
                              author={list?.User ? list?.User : list}
                              height={40}
                              src={avatarByTitle(list)}
                              mr={1}
                            />
                          </div>
                        </Link>
                      )}
                    </>
                  )}
                  {withAvatar && isAlgolia && (
                    <Link
                      prefetch={false}
                      href={path ? getPath(list) : getHref(list)}
                    >
                      <div className="cursor-pointer">
                        <Avatar
                          name={
                            imageOptimize({
                              url: list?.profileImgURL,
                              imgContainer: 'avatar',
                            })
                              ? list?.username
                              : ''
                          }
                          data-testid={
                            imageOptimize(list?.profileImgURL)
                              ? imageOptimize({
                                  url: list?.profileImgURL,
                                  imgContainer: 'avatar',
                                })
                              : imageOptimize({
                                  url: list?.coverImgURL,
                                  imgContainer: 'avatar',
                                })
                          }
                          bg="$mode.400"
                          width={40}
                          height={40}
                          author={list?.User ? list?.User : list}
                          src={
                            imageOptimize(list?.profileImgURL)
                              ? imageOptimize({
                                  url: list?.profileImgURL,
                                  imgContainer: 'avatar',
                                })
                              : imageOptimize({
                                  url: list?.coverImgURL,
                                  imgContainer: 'avatar',
                                })
                          }
                          mr={1}
                        />
                      </div>
                    </Link>
                  )}
                  <Box
                    className={`relative grid w-full ${uiSettings[1]} `}
                    pl={windowTitle === 'Trending Users' && '8px'}
                  >
                    {withPostCount ? (
                      <div className="my-1 flex">
                        {withRanking && (
                          <div className="text-gray-300 text-xs font-bold pr-4">
                            0{listIndex + 1}
                          </div>
                        )}
                        <Link
                          prefetch={false}
                          href={path ? getPath(list) : getHref(list)}
                        >
                          <p className="text-gray-900 text-xs font-bold dark:text-white cursor-pointer w-10/12">
                            {withSubtext && withFollow
                              ? list?.username
                              : getTitleUsername(list)}
                          </p>
                        </Link>
                      </div>
                    ) : (
                      <>
                        <Link
                          prefetch={false}
                          href={path ? getPath(list) : getHref(list)}
                        >
                          <Text
                            className="text-gray-900 text-xs font-bold pb-2 dark:text-white cursor-pointer"
                            lineHeight="18px"
                          >
                            {withSubtext && withFollow
                              ? list?.username
                              : getTitleUsername(list)}
                          </Text>
                        </Link>
                        <div className="absolute right-0">
                          <span className="text-gray-500">{list?.posts}</span>
                        </div>
                      </>
                    )}
                    {withEpcount && (
                      <Text
                        color="gray.500"
                        fontSize="12px"
                        fontWeight="500"
                        noOfLines={2}
                        lineHeight="14px"
                      >
                        {list?.episodeCount || 0}{' '}
                        {list?.episodeCount > 1 ? 'episodes' : 'episode'}
                      </Text>
                    )}
                    {withSubtext && list?.about && (
                      <Text
                        color="gray.500"
                        fontSize="12px"
                        fontWeight="500"
                        noOfLines={2}
                        lineHeight="14px"
                      >
                        {list?.about ? list?.about : list?.description}
                      </Text>
                    )}
                    {withSubtext && list?.summary && (
                      <Text
                        color="gray.500"
                        fontSize="12px"
                        fontWeight="500"
                        noOfLines={2}
                        lineHeight="14px"
                      >
                        {list?.summary ? list?.summary : list?.description}
                      </Text>
                    )}
                    {withFollowPost && (
                      <div
                        className={`flex justify-between relative py-2 ${
                          withPostCount && 'pl-8'
                        }`}
                      >
                        <div className="flex">
                          <span className="text-gray-500 text-xs">
                            {list?.followersCount || 0}{' '}
                            {list?.followersCount > 1
                              ? 'followers'
                              : 'follower'}
                          </span>
                          <span className="text-gray-500 mx-2 text-xs">•</span>
                          <span className="text-gray-500 text-xs">
                            {list?.episodesCount || 0}{' '}
                            {list?.episodesCount > 1 ? 'creations' : 'creation'}
                          </span>
                        </div>
                      </div>
                    )}
                    {withFollow && (
                      <div
                        className={`${
                          windowTitle === 'Trending Users'
                            ? 'justify-between'
                            : 'justify-between'
                        } flex relative py-2 ${withPostCount && 'pl-8'}`}
                      >
                        {windowTitle !== 'Trending Users' ? (
                          <div className="flex">
                            <Text
                              className="text-gray-500 text-xs"
                              lineHeight="18px"
                              fontWeight="500"
                            >
                              {list?.followersCount || 0}{' '}
                              {list?.followersCount > 1
                                ? 'followers'
                                : 'follower'}
                            </Text>
                            <span className="text-gray-500 mx-2 text-xs">
                              •
                            </span>
                            <Text
                              className="text-gray-500 text-xs"
                              lineHeight="18px"
                              fontWeight="500"
                            >
                              {list?.episodesCount || 0}{' '}
                              {list?.episodesCount > 1
                                ? 'creations'
                                : 'creation'}
                            </Text>
                          </div>
                        ) : (
                          <div className="flex">
                            <Text
                              className="text-gray-300 text-xs"
                              lineHeight="18px"
                              fontWeight="500"
                            >
                              {list?.followers || 0}{' '}
                              {list?.followers > 1 ? 'followers' : 'follower'}
                            </Text>
                          </div>
                        )}
                        {list?.username !== user.username && (
                          <button
                            type="button"
                            className="relative text-blue-500 text-xs font-medium w-max"
                            onClick={() => {
                              onFollow(list);
                            }}
                          >
                            {list.followed ? 'Following' : 'Follow'}
                          </button>
                        )}
                      </div>
                    )}
                    {windowTitle === 'Recent Visits' && (
                      <div className="text-gray-500 text-xs pt-2">
                        {dayHepler.fromNow(list?.lastVisit, true)}
                      </div>
                    )}
                  </Box>
                </Box>
              );
            })}
        </Box>
        {checkTabValid() &&
          !isFetching &&
          (windowTitle === 'Your Following' ||
            windowTitle === 'Recent Visits') && (
            <div className="p-4 text-center font-medium">
              {getTabInfo('variable', false) === 'any Collection' ? (
                <div>{`You haven’t ${
                  windowTitle === 'Recent Visits' ? 'visited' : 'followed'
                } ${
                  windowTitle === 'Recent Visits'
                    ? 'any Tribe'
                    : 'any Collection'
                } yet`}</div>
              ) : (
                <>
                  <Text
                    fontSize={{ base: 'xs', md: 'md' }}
                    fontWeight={{ base: '500' }}
                    lineHeight={{ base: '18px', md: '20px' }}
                  >{`You haven't ${
                    windowTitle === 'Recent Visits' ? 'visited' : 'followed'
                  } ${getTabInfo('variable', false)} yet.`}</Text>
                  <Link prefetch={false} href={`${getTabInfo('link', true)}`}>
                    <Text
                      fontSize={{ base: 'xs', md: 'md' }}
                      fontWeight={{ base: '500' }}
                      lineHeight={{ base: '18px', md: '20px' }}
                      className="text-blue-500 cursor-pointer"
                    >
                      {`Explore ${getTabInfo('link', false)}.`}
                    </Text>
                  </Link>
                </>
              )}
            </div>
          )}
        {checkTabValid() && windowTitle === 'Following Tribes' && (
          <div className="p-4 text-center font-medium">
            {tabIndex === 0 ? (
              <Text
                fontSize={{ base: 'xs', md: 'md' }}
                fontWeight={{ base: '500' }}
                lineHeight={{ base: '18px', md: '20px' }}
              >
                You haven’t followed any hashtags yet
              </Text>
            ) : (
              <Text
                fontSize={{ base: 'xs', md: 'md' }}
                fontWeight={{ base: '500' }}
                lineHeight={{ base: '18px', md: '20px' }}
              >
                You haven’t followed any categories yet
              </Text>
            )}
          </div>
        )}
      </div>
    </Box>
  );
}
