export const BuildYourTeam1Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Start a Space
      </h1>
      <img
        src="/images/assets/tutorial/build-your-team-1.png"
        alt="build your team 1"
        className="w-full"
      />
    </>
  );
};

export const BuildYourTeam1Footer = ({
  setNextModal,
}: {
  setNextModal?: any;
}) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Invite Editors to Join
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Invite other members to your Space as an editor to expand your reach and
        connect with more creators! Having an experienced editor makes managing
        a Space much easier!
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('buildYourTeam2');
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Next
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        1 / 2
      </h6>
    </>
  );
};
