import React from 'react';
import SvgLogo from 'src/components/icons/logo-beta'; // 'src/components/icons/logo'
import cn from 'classnames';
import { useIsTablet, useIsMobile } from 'src/hooks/use-is-mobile';
import styles from './form-layout-v2.module.scss';

export interface FormLayoutProps {
  /** for meta title */
  title: string;
  children: React.ReactNode;
}

function FormLayoutV2({ children }: FormLayoutProps) {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  return (
    <div className={cn(styles.form_layout_v2_content)}>
      {!isMobile && (
        <SvgLogo
          alt="1TM Logo"
          className="text-6xl"
          style={{ position: 'absolute', top: '10px', left: '24px' }}
          color="#E4EEFF"
          width="85px"
        />
      )}
      {children}
      {!isTablet && (
        <div className="flex items-center flex-col sm:text-sm pb-6 sm:pb-10 pt-4 px-6 sm:px-9 bg-gray-100 sm:bg-transparent text-gray-600 sm:text-gray-300">
          <p style={{ color: '#E4EEFF' }} className="pb-6 mx-auto">
            Connecting Creativity and People, Making ideas happen!
          </p>
          <p style={{ color: '#E4EEFF' }} className=" mx-auto">
            Copyright @ 2022 1TM
          </p>
        </div>
      )}
    </div>
  );
}

export default FormLayoutV2;
