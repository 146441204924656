interface NavigationPanelPrpos {
  type: string;
  className?: string;
  items: object;
}
const NavigationPanel = ({
  items,
  type,
  className = '',
}: NavigationPanelPrpos) => {
  return (
    <div
      id="nav-panel"
      className={`bg-gray-900 fixed w-full text-white px-6 z-50 ${className}`}
    >
      <ul className="flex">
        {items[type]?.map((item, key) => {
          const mkey = `m-${key}`;
          return (
            <li className="mx-4 my-10" key={mkey}>
              <a href={item.link} className="block flex">
                <div className="mr-6 w-12 h-12 bg-black rounded-full text-center -mt-3 pt-2.5">
                  {item.icon}
                </div>
                <div className="text-left w-60 ">
                  <h5 className="font-semibold">{item.title}</h5>
                  <p className="text-gray-500 text-sm font-medium">
                    {item.describe}
                  </p>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavigationPanel;
