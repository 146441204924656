import * as React from 'react';
import { Svg } from '../svg';

function SvgPrezi({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#prezi_svg__clip0_4815_67458)">
        <path
          d="M6.816 12.022c-.04 2.977 2.5 5.461 5.47 5.461 2.97 0 5.403-2.483 5.403-5.461s-2.431-5.39-5.403-5.39c-3.933 0-5.442 3.216-5.47 5.39zm5.47-4.806c2.648 0 4.726 2.151 4.726 4.806 0 2.654-2.078 4.761-4.726 4.761s-4.793-2.107-4.793-4.761a4.799 4.799 0 014.793-4.806z"
          fill="#1966CC"
        />
        <path
          d="M5.483 12.022c0 3.851 3.22 6.727 6.803 6.727 3.582 0 6.711-2.96 6.711-6.727 0-3.767-3.156-6.76-6.711-6.76-3.556 0-6.803 2.745-6.803 6.76zm6.803-6.15c3.07 0 6.066 2.64 6.066 6.15 0 3.534-2.716 6.082-6.066 6.082-3.503 0-6.1-2.69-6.1-6.082 0-3.522 2.75-6.15 6.1-6.15z"
          fill="#1966CC"
        />
        <path
          d="M4.166 12.026c0 4.646 3.8 8.069 8.122 8.069 4.321 0 8.073-3.525 8.073-8.07 0-4.544-3.758-8.105-8.073-8.105s-8.122 3.304-8.122 8.106zm8.122-7.436c3.91 0 7.39 3.2 7.39 7.436 0 4.263-3.442 7.423-7.483 7.423-4.016 0-7.397-3.33-7.397-7.423 0-4.25 3.449-7.436 7.49-7.436z"
          fill="#1966CC"
        />
        <path
          d="M11.865 8.107h.856v7.808h-.856V8.107zM10.08 8.721c.343-.273.855-.407.855-.407v7.372c-.486-.115-.85-.396-.85-.396l-.006-6.569zM14.518 8.705c-.344-.274-.856-.407-.856-.407v7.372c.486-.116.85-.397.85-.397l.006-6.568zM9.136 9.614v4.75S8.35 13.593 8.35 12c0-1.591.787-2.387.787-2.387zM15.396 9.581v4.75s.787-.771.787-2.363c0-1.591-.787-2.387-.787-2.387zM16.296 4.015l1.13-2.168-1.05-.507-.9 2.292.82.383zM14.533 3.348L15.186.99 14.053.715l-.402 2.43.882.203zM12.707 3.064l.158-2.44L11.7.586l.104 2.46.904.018zM10.818 3.168l-.357-2.42-1.149.207.618 2.384.888-.171zM8.997 3.663L8.142 1.37l-1.08.443 1.102 2.202.833-.353zM7.321 4.526L6.007 2.463l-.964.659 1.538 1.923.74-.52zM5.89 5.692L4.18 3.942l-.808.842L5.27 6.352l.619-.66zM4.702 7.167L2.665 5.814l-.614.992 2.184 1.136.467-.775zM3.865 8.818L1.594 7.91l-.398 1.097 2.371.664.298-.854zM3.379 10.639l-2.411-.413-.16 1.156 2.457.154.114-.897zM3.281 12.483l-2.444.09.08 1.163 2.437-.351-.073-.902zM3.574 14.352l-2.37.6.321 1.121 2.31-.854-.26-.867zM4.25 16.114l-2.192 1.082.55 1.03 2.079-1.319-.436-.793zM5.28 17.694L3.36 19.21l.752.892 1.759-1.723-.592-.685zM6.585 19l-1.569 1.877.919.72 1.369-2.047-.72-.55zM8.17 20.033l-1.14 2.164 1.05.51.909-2.287-.819-.387zM9.946 20.713l-.659 2.355 1.133.278.408-2.428-.882-.205zM11.808 21.002l-.153 2.441 1.167.036-.109-2.46-.905-.017zM13.653 20.903l.35 2.42 1.148-.203-.61-2.386-.888.169zM15.48 20.412l.85 2.294 1.08-.44-1.095-2.205-.834.35zM17.16 19.551l1.31 2.066.965-.656-1.532-1.927-.743.517zM18.622 18.361l1.712 1.747.806-.843-1.9-1.565-.618.661zM19.782 16.923l2.033 1.36.617-.99-2.18-1.143-.47.773zM20.64 15.237l2.272.903.396-1.098-2.372-.66-.297.855zM21.125 13.402l2.412.405.157-1.156-2.458-.147-.11.898zM21.215 11.512l2.444-.11-.09-1.163-2.434.371.08.902zM20.908 9.65l2.367-.617-.33-1.12-2.303.872.266.865zM20.22 7.895l2.186-1.097-.557-1.026-2.07 1.333.441.79zM19.205 6.352l1.915-1.521-.755-.89-1.753 1.728.593.683zM17.86 5.02l1.554-1.889-.924-.712-1.353 2.058.724.543z"
          fill="#318BFF"
        />
      </g>
      <defs>
        <clipPath id="prezi_svg__clip0_4815_67458">
          <path fill="#fff" transform="translate(.25)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPrezi);
export default ForwardRef;
