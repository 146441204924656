import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query';
import { CacheKey } from 'src/constants/cache-key';
import { standaloneToast } from 'src/components/toast';
import { getCookie } from 'src/lib/cookies';
import { COOKIES_AUTH_TOKEN } from 'src/constants/cookies';
import { imageOptimize } from 'src/service/image';
import { useContext } from 'react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import useProfileQuery from 'src/app/profile/use-profile-query';
import * as Service from './service';
import {
  SpaceResponse,
  TopicListRequest,
  TopicListRequestQuery,
  TopicPostsListRequest,
  TopicPostsListRequestQuery,
  TopicsRequestQuery,
  TopicsRequest,
} from './types';
import {
  fetchTopicList,
  getTopicData,
  createSpace,
  deleteSpace,
  getSpace,
  updateSpace,
  fetchTopicPostsList,
  getAllTopics,
  getFullTopic,
  getSentInvitations,
  deleteTeamMember,
  fetchTopicsList,
  deleteuserInvitation,
} from './services';

export function useInvitationMutation(rid: string) {
  const queryClient = useQueryClient();

  return useMutation(Service.createInvitation, {
    onSettled: () => {
      queryClient.invalidateQueries([CacheKey.invitationList, rid]);
    },
    onError: () => {
      standaloneToast({
        title: '😢 Invite failed, please try again.',
        status: 'error',
        duration: 3000,
        position: 'bottom',
      });
    },
  });
}

export function useTopicQuery(id?: string) {
  return useQuery([CacheKey.topic, id], () => getTopicData(id), {
    enabled: !!id,
  });
}

export function useTopicFullQuery(id?: string) {
  return useQuery([CacheKey.topic, id], () => getFullTopic(id), {
    enabled: !!id,
  });
}

export function useTopicListQuery({
  req,
  params = {},
}: {
  req: TopicListRequest;
  params?: TopicListRequestQuery;
}) {
  return useInfiniteQuery(
    [CacheKey.topicList, { ...req, ...params }],
    ({ pageParam = 1 }) =>
      fetchTopicList({
        data: req,
        params: { ...params, pageNum: pageParam },
      }),
    {
      enabled: !!params && !!req.spaceID,
      getNextPageParam: ({ hasNext, pageNum = 1 }) => {
        if (hasNext) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export function useTopicsListQuery() {
  // Take Topics data according to page params.
  return useMutation(fetchTopicsList);
}

export function useCreateSpaceMutation() {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext) as UserContextType;
  const { data: profile } = useProfileQuery(user?.username);

  return useMutation(createSpace, {
    onSuccess: (res, values) => {
      if (res.code === 1000 && res.data?.id) {
        queryClient.setQueryData<SpaceResponse>(
          [CacheKey.space, res.data.id],
          (p) => ({
            ...p,
            creator: {
              userID: profile.id,
              username: profile.username,
              profileImgURL: imageOptimize(profile.profileImgURL),
              userTitle: profile.title,
              firstName: profile.firstName,
              lastName: profile.lastName,
              motto: profile.motto,
              about: profile.about,
              isFollowed: profile.isFollowed,
              count: {
                episode: 0,
                episodeEnthuse: 0,
                follower: 0,
                studio: 0,
                studioEnthuse: 0,
              },
            },
            space: {
              ...p?.space,
              id: res.data.id,
              ...values,
              isOwner: true,
            },
          })
        );
      }
    },
    onError: () => {
      standaloneToast({
        title: '😢 Create Space failed, please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });
}

export function useSpaceDeleteMutation(holdToast?: boolean) {
  return useMutation(deleteSpace, {
    onSuccess: () => {
      if (!holdToast) {
        standaloneToast({
          title: 'Delete success',
        });
      }
    },
  });
}

export function useSpaceQuery(id?: string) {
  const authToken = getCookie(COOKIES_AUTH_TOKEN);
  const renderType = authToken ? 'csr' : 'ssr';
  return useQuery([CacheKey.space, id, renderType], () => getSpace(id), {
    enabled: !!id,
  });
}

export function useUpdateSpaceMutation() {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext) as UserContextType;
  const { data: profile } = useProfileQuery(user?.username);

  return useMutation(updateSpace, {
    onSuccess: (res, values) => {
      if (res.code === 1000 && res.data?.id) {
        queryClient.setQueryData<SpaceResponse>(
          [CacheKey.space, res.data.id],
          (p) => ({
            ...p,
            creator: {
              userID: profile.id,
              username: profile.username,
              profileImgURL: imageOptimize(profile.profileImgURL),
              userTitle: profile.title,
              firstName: profile.firstName,
              lastName: profile.lastName,
              motto: profile.motto,
              about: profile.about,
              isFollowed: profile.isFollowed,
              count: {
                episode: 0,
                episodeEnthuse: 0,
                follower: 0,
                studio: 0,
                studioEnthuse: 0,
              },
            },
            space: {
              ...p?.space,
              id: res.data.id,
              ...values,
              isOwner: true,
            },
          })
        );
      }
    },
    onError: () => {
      standaloneToast({
        title: '😢 Update Space failed, please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });
}

export function useTopicPostsListQuery({
  req,
  params = {},
  enabled,
}: {
  req: TopicPostsListRequest;
  params?: TopicPostsListRequestQuery;
  enabled?: boolean;
}) {
  return useInfiniteQuery(
    [CacheKey.topicPostsList, { ...req, ...params }],
    ({ pageParam = 1 }) => {
      return fetchTopicPostsList(req, {
        ...params,
        pageNum: pageParam,
      });
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      getNextPageParam: ({ next, pageNum }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export function useTopicsQuery({
  req,
  params = {},
  enabled,
}: {
  req: TopicsRequest;
  params?: TopicsRequestQuery;
  enabled?: boolean;
}) {
  return useInfiniteQuery(
    [CacheKey.topics, { ...req, ...params }],
    () => {
      return getAllTopics(req, {
        ...params,
      });
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
}

export function useSentInvitationsQuery({
  id,
  modal,
  params = {},
  options = {},
}: {
  id: string;
  modal: string;
  params?: any;
  options?: any;
}) {
  return useInfiniteQuery(
    [CacheKey.sentInvitations, modal, { id }],
    ({ pageParam = 1 }) => {
      return getSentInvitations(id, { ...params, pageNum: pageParam });
    },
    {
      getNextPageParam: ({ next, pageNum }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
      ...options,
    }
  );
}

export function useDeleteTeamMemberMutation() {
  const queryClient = useQueryClient();

  return useMutation(deleteTeamMember, {
    onSuccess: (res, values) => {
      if (res.code === 1000) {
        queryClient.setQueryData([CacheKey.teamMember, values], null);
      }
    },
  });
}

export function useDeleteUserInvitationMutation() {
  const queryClient = useQueryClient();

  return useMutation(deleteuserInvitation, {
    onSuccess: (res, values) => {
      if (res.code === 1000) {
        queryClient.setQueryData([CacheKey.userInvitation, values], null);
      }
    },
  });
}
