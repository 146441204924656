import * as React from 'react';
import { Svg } from '../svg';

function SvgAddAvatar({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <rect y="0.75" width="40" height="40" rx="20" fill="#242526" />
      <g opacity="0.6">
        <path
          d="M20 16.0833V25.4166"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3333 20.75H24.6666"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgAddAvatar);
export default ForwardRef;
