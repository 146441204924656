import * as React from 'react';
import { Svg } from '../svg';

function SvgTiktok({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#tiktok_svg__clip0_4815_67412)">
        <path
          d="M9.522 9.452v-.935a6.902 6.902 0 00-.981-.084 7.275 7.275 0 00-4.17 13.246A7.267 7.267 0 019.52 9.452z"
          fill="#25F4EE"
        />
        <path
          d="M9.698 20.046a3.327 3.327 0 003.32-3.182V1.004h2.897A5.444 5.444 0 0115.831 0h-3.963v15.845a3.327 3.327 0 01-3.32 3.204 3.373 3.373 0 01-1.533-.383 3.327 3.327 0 002.683 1.38zm11.629-13.66v-.882a5.412 5.412 0 01-2.997-.905 5.497 5.497 0 002.997 1.786z"
          fill="#25F4EE"
        />
        <path
          d="M18.33 4.599A5.458 5.458 0 0116.973.996h-1.058A5.487 5.487 0 0018.33 4.6zm-9.79 7.796a3.327 3.327 0 00-1.532 6.27 3.319 3.319 0 012.69-5.266 3.5 3.5 0 01.981.153V9.52a6.963 6.963 0 00-.98-.076h-.177v3.066a3.412 3.412 0 00-.981-.115z"
          fill="#FE2C55"
        />
        <path
          d="M21.327 6.386v3.066a9.406 9.406 0 01-5.496-1.786v8.056a7.29 7.29 0 01-7.29 7.275 7.213 7.213 0 01-4.17-1.318 7.275 7.275 0 0012.602-4.952V8.693a9.42 9.42 0 005.504 1.763V6.508a5.619 5.619 0 01-1.15-.122z"
          fill="#FE2C55"
        />
        <path
          d="M15.83 15.722V7.665a9.406 9.406 0 005.505 1.763V6.362A5.497 5.497 0 0118.33 4.6 5.49 5.49 0 0115.915.996h-2.898v15.868A3.327 3.327 0 017 18.681a3.327 3.327 0 011.533-6.27c.333.002.663.054.981.153V9.497a7.267 7.267 0 00-5.151 12.265 7.213 7.213 0 004.178 1.235 7.29 7.29 0 007.29-7.275z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="tiktok_svg__clip0_4815_67412">
          <path fill="#fff" transform="translate(.25)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTiktok);
export default ForwardRef;
