import * as React from 'react';
import { Svg } from '../svg';

function SvgMoreV2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 13 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 6.5a1 1 0 100-2 1 1 0 000 2zm0 7a1 1 0 100-2 1 1 0 000 2zm1 6a1 1 0 11-2 0 1 1 0 012 0z"
        fill="#242526"
      />
      <path
        d="M7 5.5a.25.25 0 01-.25.25v1.5A1.75 1.75 0 008.5 5.5H7zm-.25-.25A.25.25 0 017 5.5h1.5a1.75 1.75 0 00-1.75-1.75v1.5zm-.25.25a.25.25 0 01.25-.25v-1.5A1.75 1.75 0 005 5.5h1.5zm.25.25a.25.25 0 01-.25-.25H5c0 .966.784 1.75 1.75 1.75v-1.5zM7 12.5a.25.25 0 01-.25.25v1.5A1.75 1.75 0 008.5 12.5H7zm-.25-.25a.25.25 0 01.25.25h1.5a1.75 1.75 0 00-1.75-1.75v1.5zm-.25.25a.25.25 0 01.25-.25v-1.5A1.75 1.75 0 005 12.5h1.5zm.25.25a.25.25 0 01-.25-.25H5c0 .966.784 1.75 1.75 1.75v-1.5zm0 8.5A1.75 1.75 0 008.5 19.5H7a.25.25 0 01-.25.25v1.5zM5 19.5c0 .966.784 1.75 1.75 1.75v-1.5a.25.25 0 01-.25-.25H5zm1.75-1.75A1.75 1.75 0 005 19.5h1.5a.25.25 0 01.25-.25v-1.5zM8.5 19.5a1.75 1.75 0 00-1.75-1.75v1.5a.25.25 0 01.25.25h1.5z"
        fill="#242526"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgMoreV2);
export default ForwardRef;
