import { useState } from 'react';
import SvgArrow from 'src/components/icons/arrow-forward';
import { Modal, ModalContent } from '@chakra-ui/react';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import homeIMG from './images/home.png';
import doorIMG from './images/door.png';
import plusIMG from './images/plus.png';
import refreshIMG from './images/refresh.png';
import metamaskIMG from './images/metamask.svg';
import chromeIMG from './images/chrome.svg';

import styles from './wallet-explainer-modal.module.scss';

export default function WalletExplainerModal({ closeModal, isOpen }) {
  const [modalHistory, setModalHistory] = useState(['WHAT_IS_A_WALLET']);

  function pushHistoryState(state: string) {
    const newState: Array<string> = [...modalHistory];
    newState.push(state);
    setModalHistory(newState);
  }

  function popHistoryState() {
    if (modalHistory.length === 1) return;
    const newState: Array<string> = [...modalHistory];
    newState.pop();
    setModalHistory(newState);
  }

  const modalHeights = {
    // to help CSS-transition smoothly between heights
    WHAT_IS_A_WALLET: '436px',
    GET_METAMASK: '452px',
    GET_STARTED_WITH_METAMASK: '496px',
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} allowPinchZoom>
      <ModalContent
        maxW="100vw"
        w="100vw"
        h="100vh"
        borderRadius="none"
        background="none"
      >
        <div className={styles.fadein}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,  jsx-a11y/click-events-have-key-events */}
          <div className={styles.overlay} onClick={closeModal}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,  jsx-a11y/click-events-have-key-events */}
            <div
              className={styles.content}
              style={{
                height: modalHeights[modalHistory[modalHistory.length - 1]],
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {modalHistory.length > 1 && (
                <button
                  type="button"
                  className={styles.back_button}
                  onClick={popHistoryState}
                >
                  <SvgArrow className={styles.back_icon} />
                </button>
              )}
              <button
                type="button"
                className={styles.close_button}
                onClick={closeModal}
              >
                <div className={styles.x1} />
                <div className={styles.x2} />
              </button>

              {modalHistory[modalHistory.length - 1] === 'WHAT_IS_A_WALLET' && (
                <WhatIsAWallet pushHistoryState={pushHistoryState} />
              )}
              {modalHistory[modalHistory.length - 1] === 'GET_METAMASK' && (
                <GetMetaMask pushHistoryState={pushHistoryState} />
              )}
              {modalHistory[modalHistory.length - 1] ===
                'GET_STARTED_WITH_METAMASK' && <GetStartedWithMetaMask />}
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}

function WhatIsAWallet(props: { pushHistoryState }) {
  const { pushHistoryState } = props;
  return (
    <div className={styles.fadein}>
      <h2>What is a Wallet?</h2>
      <div className={styles.section_container}>
        <img src={homeIMG} alt="home icon" className={styles.image} />
        <div className={styles.text_wrapper}>
          <h3>A home for your Digital Assets</h3>
          <p>
            Wallets are used to send, receive, store, and display digital assets
            like Ethereum and NFTs.
          </p>
        </div>
      </div>
      <div className={styles.section_container}>
        <img src={doorIMG} alt="door icon" className={styles.image} />
        <div className={styles.text_wrapper}>
          <h3>A New Way to Log In</h3>
          <p>
            Instead of creating new accounts and passwords on every website,
            just connect your wallet.
          </p>
        </div>
      </div>
      <div className={styles.buttons_container}>
        <button
          onClick={() => pushHistoryState('GET_METAMASK')}
          className={styles.get_wallet_button}
          type="button"
        >
          Get a wallet
        </button>
        <a
          href="https://hub.1tm.io/intro-to-web3/what-is-web3"
          target="_blank"
          className={styles.learn_more_button}
          rel="noreferrer"
        >
          Learn more
        </a>
      </div>
    </div>
  );
}

function GetMetaMask(props: { pushHistoryState }) {
  const { pushHistoryState } = props;
  const isMobile = useIsMobile();
  return (
    <div className={styles.fadein}>
      <h2>Get MetaMask</h2>
      <div className={styles.option_cards_container}>
        {!isMobile && (
          <div className={styles.option_card}>
            <img src={chromeIMG} alt="chrome" className={styles.image2} />
            <div className={styles.text_wrapper}>
              <h3>MetaMask for Chrome</h3>
              <p>Access your wallet right from your favorite web browser.</p>
              <a
                href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en"
                target="_blank"
                rel="noreferrer"
                className={styles.option_button}
                onClick={() => pushHistoryState('GET_STARTED_WITH_METAMASK')}
              >
                Add to Chrome
              </a>
            </div>
          </div>
        )}
        <div className={styles.option_card}>
          <img src={metamaskIMG} alt="metamask" className={styles.image2} />
          <div className={styles.text_wrapper}>
            <h3>MetaMask for Mobile</h3>
            <p>Use the mobile wallet to explore the world of Ethereum.</p>
            <a
              href="https://metamask.io/download/"
              target="_blank"
              rel="noreferrer"
              className={styles.option_button}
            >
              Add to Mobile
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function GetStartedWithMetaMask() {
  return (
    <div className={styles.fadein}>
      <h2>Get started with MetaMask</h2>
      <div className={styles.section_container}>
        <img src={metamaskIMG} alt="home icon" className={styles.image2} />
        <div className={styles.text_wrapper}>
          <h3>Install the MetaMask extension</h3>
          <p>
            We recommend pinning MetaMask to your taskbar for quicker access to
            your wallet.
          </p>
        </div>
      </div>
      <div className={styles.section_container}>
        <img src={plusIMG} alt="home icon" className={styles.image2} />
        <div className={styles.text_wrapper}>
          <h3>Create or Import a Wallet</h3>
          <p>
            Be sure to back up your wallet using a secure method. Never share
            your secret phrase with anyone.
          </p>
        </div>
      </div>
      <div className={styles.section_container}>
        <img src={refreshIMG} alt="home icon" className={styles.image2} />
        <div className={styles.text_wrapper}>
          <h3>Refresh your browser</h3>
          <p>
            Once you set up your wallet, click below to refresh the browser and
            load up the extension.
          </p>
        </div>
      </div>
      <div className={styles.buttons_container}>
        <button
          className={styles.get_wallet_button}
          type="button"
          onClick={() => window.location.reload()}
        >
          Refresh
        </button>
      </div>
    </div>
  );
}
