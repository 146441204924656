/**
 * External dependencies
 */
import is from 'is_js';

/**
 * Internal dependencies
 */
import algolia from 'src/lib/algolia';
import { imageOptimize } from 'src/service/image';

import { FILTER_TYPES } from './constants';

export const fetchSuggestions = async (keyword: string) =>
  algolia.suggestions(keyword);

export const fetchSearchLiteResult = async (searchTerm) => {
  const result = [];

  const hits = await algolia.search(searchTerm);

  if (is.not.empty(hits)) {
    hits.forEach((hit) => {
      if (hit['filterType'] === FILTER_TYPES.USERS) {
        result.push(hit['username']);
      }
      if (
        (hit['title'] &&
          hit['title'].toLowerCase().includes(searchTerm.toLowerCase())) ||
        (hit['tags'] && hit['tags'].includes(searchTerm))
      )
        result.push(hit['title']);
    });
  }

  return result;
};

const searchResultHandler = (data) => {
  // User
  let name = data.username;
  if (data.firstName) {
    name = (data.firstName + (data.lastName || '')).trim();
  }

  return {
    name,
  };
};

export const fetchSearchResult = async (searchTerm) => {
  const hits = await algolia.search(searchTerm);
  const result = Object.values(FILTER_TYPES).reduce(
    (acc, type) => ({ ...acc, [type]: [] }),
    {}
  );

  if (is.not.empty(hits) && hits !== undefined) {
    hits.forEach((hit: any) => {
      const { filterType } = hit;
      if (result[filterType] === undefined) {
        result[filterType] = [];
      }

      /** <-- Convert Result Data */
      const { name } = searchResultHandler(hit);
      /** Convert Result Data --> */

      switch (filterType) {
        case FILTER_TYPES.USERS:
          // creators -> users
          result[filterType].push({
            creationsImageUrl: [],
            creations: 0,
            ...hit,
            name,
          });
          break;
        case FILTER_TYPES.SPACES:
          // projects -> spaces
          result[filterType].push({
            followers: hit.followers || 0,
            status: hit.status || '',
            followersCount: hit.followerCount,
            ...hit,
            avatarUrl: [hit.avatarImgURL],
          });
          break;
        case FILTER_TYPES.CREATIONS:
          result[filterType].push({
            media: [
              {
                type: 'img',
                coverUrl: imageOptimize(hit.coverImgURL),
              },
            ],
            ...hit,
            avatarUrl: [imageOptimize(hit.profileImgURL)],
          });
          break;
        case FILTER_TYPES.COLLECTIONS:
          result[filterType].push(hit);
          break;
        case FILTER_TYPES.CREATIVE_ASSETS:
          result[filterType].push(hit);
          break;
        default:
          break;
      }
    });
  }
  return { result, total: hits.length };
};

// Tribes
export const fetchTopics = async (searchTerm) => {
  const topics = [];

  const tags = await algolia.searchTopic(searchTerm, 1000);

  if (is.not.empty(tags) && tags !== undefined) {
    tags.forEach((hit: any) => {
      topics.push({
        ...hit,
      });
    });
  }
  return [FILTER_TYPES.TOPICS, topics];
};
