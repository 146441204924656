import {
  Modal,
  ModalContent,
  ModalOverlay,
  Input,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SvgYoutube from 'src/components/icons/youtube';
import SvgTiktok from 'src/components/icons/tiktok';
import SvgTwitch from 'src/components/icons/twitch';
import SvgSpotify from 'src/components/icons/spotify';
import SvgFigma from 'src/components/icons/figma';
import SvgSketch from 'src/components/icons/sketch';
import SvgCanva from 'src/components/icons/canva';
import SvgDeviantart from 'src/components/icons/deviantart';
import SvgSketchfab from 'src/components/icons/sketchfab';
import SvgMatterport from 'src/components/icons/matterport';
import SvgP3din from 'src/components/icons/p3din';
import Svg3dCrafts from 'src/components/icons/3dcraft';
import SvgDrive from 'src/components/icons/googledrive';
import SvgPrezi from 'src/components/icons/prezi';
import SvgWhimsical from 'src/components/icons/whimsical';
import SvgAirtable from 'src/components/icons/airtable';
import SvgTypeform from 'src/components/icons/typeform';
import SvgCalendly from 'src/components/icons/calendly';
import SvgMaps from 'src/components/icons/googlemaps';
import SvgTrello from 'src/components/icons/trello';
import { CustomToast } from 'src/components/toast';

import cn from 'classnames';
import SvgInfo3 from '../icons/info3';

import styles from './Post_embed.module.scss';

export function PostEmbed({
  modal,
  onEmbed,
  type = 'others',
}: {
  modal: any;
  onEmbed: (value: any) => void;
  type: string;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const [embedValue, setEmbedValue] = useState<string>();
  const KEY = '5ed9b979aea3e8b1d33993';

  const errorStatusHandler = (status) => {
    if (status === 404 || status === 410) {
      return "The content is no longer available at the origin, or it's not open to public.";
    }
    if (status === 403 || status === 401) {
      return 'The destination URL is private and could not be processed';
    }
    if (status === 415) {
      return 'The destination URL has unsupported media types / requires own key';
    }
    if (status === 418) {
      return 'The destination URL has timed out';
    }
    return 'Something went wrong';
  };

  const fetchEmbedData = async () => {
    try {
      if (embedValue.includes('youtube')) {
        const newEmbed = embedValue.replace(/watch\?v=/g, 'embed/');
        onEmbed({
          html: `<iframe style="width:100%; height:100%; min-height:420px;" allow="autoplay *; accelerometer *; clipboard-write *; encrypted-media *; gyroscope *; picture-in-picture *;" src=${newEmbed} allowfullscreen="true" frameborder="0" scrolling="no"></iframe>`,
          type,
        });
        modal.onClose();
      } else {
        fetch(
          `https://cdn.iframe.ly/api/oembed?url=${encodeURIComponent(
            embedValue
          )}&api_key=${KEY}&omit_script=1&omit_css=1&_private=true
          `
        )
          .then((res) => res.json())
          .then((res) => {
            if (res.html) {
              onEmbed({ html: res.html, type });
            } else if (res.error) {
              toast({
                position: 'bottom-right',
                duration: 3000,
                render: (r) => (
                  <CustomToast
                    icon="error"
                    title={`${errorStatusHandler(res.status)}`}
                    {...r}
                  />
                ),
              });
            }
            modal.onClose();
          });
      }
    } catch (err) {
      return null;
    }
  };

  const modalSettings = {
    title: {
      video: 'a video or audio',
      document: 'a document',
      design: 'a design or prototype',
      '3d': 'a 3D artwork',
      others: 'others',
    },
    subtitle: {
      video: 'Embed_Subtitle_Video',
      document: 'Embed_Subtitle_Document',
      design: 'Embed_Subtitle_Design',
      '3d': 'Embed_Subtitle_3D',
      others: 'Embed_Subtitle_Others',
    },
    support: {
      video: [
        {
          title: 'Youtube',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <SvgYoutube
                style={{ height: '24px', width: '24px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Tiktok',
          icon: (
            <div style={{ width: '21.23px' }} className="mr-2 relative">
              <SvgTiktok
                style={{ height: '24px', width: '21.23px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Twitch',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <SvgTwitch
                style={{ height: '24px', width: '24px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Spotify',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <SvgSpotify
                style={{ height: '24px', width: '24px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
      ],
      document: [
        {
          title: 'Drive',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <SvgDrive
                style={{ height: '21.33px', width: '24px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Prezi',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <SvgPrezi
                style={{ height: '24px', width: '24px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Whimsical',
          icon: (
            <div style={{ width: '23px' }} className="mr-2 relative">
              <SvgWhimsical
                style={{ height: '22px', width: '23px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'AirTable',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <SvgAirtable
                style={{ height: '24px', width: '24px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
      ],
      design: [
        {
          title: 'Figma',
          icon: (
            <div style={{ width: '16px' }} className="mr-2 relative">
              <SvgFigma
                style={{ height: '24px', width: '16px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Sketch',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <SvgSketch
                style={{ height: '18.1px', width: '20px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Canva',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <SvgCanva
                style={{ height: '23.76px', width: '24px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Deviantart',
          icon: (
            <div style={{ width: '14.42px' }} className="mr-2 relative">
              <SvgDeviantart
                style={{ height: '24px', width: '14.42px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
      ],
      '3d': [
        {
          title: 'Sketchfab',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <SvgSketchfab
                style={{ height: '24px', width: '24px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Matterport',
          icon: (
            <div style={{ width: '23.76px' }} className="mr-2 relative">
              <SvgMatterport
                style={{ height: '24px', width: '23.76px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'p3d.in',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <SvgP3din
                style={{ height: '24px', width: '24px' }}
                className="m-auto"
              />
            </div>
          ),
        },
        {
          title: '3dCrafts',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <Svg3dCrafts
                style={{ height: '24px', width: '24px' }}
                className="m-auto"
              />
            </div>
          ),
        },
      ],
      others: [
        {
          title: 'Typeform',
          icon: (
            <div style={{ width: '23.91px' }} className="mr-2 relative">
              <SvgTypeform
                style={{ height: '24px', width: '23.91px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Calendly',
          icon: (
            <div style={{ width: '23.51px' }} className="mr-2 relative">
              <SvgCalendly
                style={{ height: '24px', width: '23.51px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Google Map',
          icon: (
            <div style={{ width: '16.75px' }} className="mr-2 relative">
              <SvgMaps
                style={{ height: '24px', width: '16.75px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
        {
          title: 'Trello',
          icon: (
            <div style={{ width: '24px' }} className="mr-2 relative">
              <SvgTrello
                style={{ height: '24px', width: '24px' }}
                className="m-auto absolute top-0 bottom-0"
              />
            </div>
          ),
        },
      ],
    },
    truncate: {
      video: 'Embed_Tooltip_Video',
      document: 'Embed_Tooltip_Document',
      design: 'Embed_Tooltip_Design',
      '3d': 'Embed_Tooltip_3D',
      others: 'Embed_Tooltip_Others',
    },
  };

  return (
    <Modal {...modal} isCentered>
      <ModalOverlay />
      <ModalContent maxW="640px" borderRadius="24px">
        <div className={`post-embed ${cn(styles.block, styles.block_layout)}`}>
          <px-posize
            track-style='{"overflow":"hidden","flexGrow":1}'
            area-style='{"overflow":"hidden"}'
            x="363fr clamp(640px, 46.85%, 46.85%) 363fr"
            y="372fr minmax(0px, max-content) 372fr"
          >
            <div className={cn(styles.block1, 'pt-10 relative')}>
              <button
                type="button"
                className="absolute top-5 right-6"
                onClick={() => modal.onClose()}
              >
                <img
                  src={
                    require('public/assets_pxcode/6fb52961dc5b298c6e539ee7e9d851e6.png')
                      .default
                  }
                  alt="alt text"
                  className={cn(styles.cover_group, styles.cover_group_layout)}
                />
              </button>

              <div className={cn(styles.flex, styles.flex_layout)}>
                <div className={cn(styles.flex_item)}>
                  <h3 className={cn(styles.subtitle, styles.subtitle_layout)}>
                    Embed {modalSettings.title[type]}
                  </h3>
                </div>
                <div className={cn(styles.flex_item)}>
                  <div
                    className={cn(
                      styles.paragraph_body,
                      styles.paragraph_body_layout
                    )}
                  >
                    {t(modalSettings.subtitle[type])}
                  </div>
                </div>
                <div className={cn(styles.flex_item)}>
                  <Input
                    borderColor="$mode.300"
                    borderRadius="4px"
                    fontSize="14px"
                    color="$mode.900"
                    fontWeight="500"
                    className={cn(styles.block2, styles.block2_layout)}
                    placeholder="Paste URL to embed contents"
                    backgroundColor="unset"
                    onChange={(e) => {
                      setEmbedValue(e.target.value);
                    }}
                    _focus={{
                      '::placeholder': {
                        color: 'rgba(0,0,0,0)',
                      },
                    }}
                  />
                </div>
                <div className="flex mt-2">
                  <SvgInfo3
                    style={{
                      height: '16px',
                      width: '16px',
                      marginRight: '6px',
                    }}
                  />
                  <div className="text-xs text-gray-400">
                    Make sure the content is available to public. Private
                    content may not show properly.
                  </div>
                </div>
                <div className="mt-10 font-medium">Supported platforms:</div>

                <div className={cn(styles.flex1, styles.flex1_layout)}>
                  {modalSettings.support[type].map((supportType) => (
                    <>
                      <div className={cn(styles.flex1_item)}>
                        <div
                          className={cn(styles.block3, styles.block3_layout)}
                        >
                          <div
                            className={cn(styles.flex2, styles.flex2_layout)}
                          >
                            {supportType.icon}
                            <div
                              className={cn(
                                styles.paragraph_body,
                                styles.paragraph_body_layout1
                              )}
                            >
                              {supportType.title}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={cn(styles.flex1_spacer)} />
                    </>
                  ))}
                  <Tooltip
                    hasArrow
                    label={t(modalSettings.truncate[type])}
                    placement="top-end"
                    bg="$mode.900"
                    color="$mode.200"
                    borderRadius="4px"
                    p="12px"
                  >
                    <div className={cn(styles.flex1_item3)}>
                      <div className={cn(styles.block7, styles.block7_layout)}>
                        <div
                          className={cn(
                            styles.paragraph_body,
                            styles.paragraph_body_layout5
                          )}
                        >
                          ...
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </div>
              </div>

              <div className={cn(styles.block8, styles.block8_layout)}>
                <div className={cn(styles.group, styles.group_layout)}>
                  <div
                    style={
                      {
                        '--src': `url(${
                          require('public/assets_pxcode/b63ea4cff89ea03db98937f41d84e391.png')
                            .default
                        })`,
                      } as React.CSSProperties
                    }
                    className={cn(styles.decorator, styles.decorator_layout)}
                  />

                  <div className={cn(styles.flex6, styles.flex6_layout)}>
                    <hr className={cn(styles.line, styles.line_layout)} />
                    <div className={cn(styles.flex6_item)}>
                      <div className={cn(styles.flex7, styles.flex7_layout)}>
                        <div
                          className={cn(styles.flex7_item)}
                          role="button"
                          tabIndex={0}
                          onClick={modal.onClose}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              modal.onClose();
                            }
                          }}
                        >
                          <h5
                            className={cn(
                              styles.highlights,
                              styles.highlights_layout
                            )}
                          >
                            Cancel
                          </h5>
                        </div>
                        <div className={cn(styles.flex7_spacer)} />
                        <div
                          className={cn(styles.flex7_item1)}
                          role="button"
                          tabIndex={0}
                          onClick={() => fetchEmbedData()}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              fetchEmbedData();
                            }
                          }}
                        >
                          <div
                            className={cn(styles.block9, styles.block9_layout)}
                          >
                            <h5
                              className={cn(
                                styles.highlights1,
                                styles.highlights1_layout
                              )}
                            >
                              Embed
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </px-posize>
        </div>
      </ModalContent>
    </Modal>
  );
}

PostEmbed.inStorybook = true;
