import { Grid, GridProps } from '@chakra-ui/react';
import { ReactNode, Children } from 'react';

interface CarouselProps {
  children: ReactNode;
  eachWidth?: string;
  scrollPadding?: number;
  scrollSnap?: boolean;
  /** padding for left and right  */
  space?: boolean;
}

export function Carousel({
  children = [],
  eachWidth = '1fr',
  scrollPadding = 24,
  scrollSnap = true,
  space,
  ...props
}: CarouselProps & GridProps) {
  const totalChildren = Children.count(children);
  let gridTemplate = `repeat(${totalChildren}, ${eachWidth})`;
  if (space) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    gridTemplate = `1px ${gridTemplate} 1px`;
  }
  return (
    <Grid
      className={
        scrollSnap ? 'scroll-container scroll-snap-x' : 'scroll-container'
      }
      gridAutoColumns="1fr"
      gridTemplateColumns={gridTemplate}
      overflowX="scroll"
      {...props}
    >
      {space && <div />}
      {children}
      {/* space */}
      {space && <div />}
      <style jsx global>{`
        .scroll-container::-webkit-scrollbar {
          display: none;
        }
        .scroll-container {
          -webkit-overflow-scrolling: touch;
          scrollbar-width: none;
        }
        .scroll-snap-x {
          scroll-snap-type: x mandatory;
          scroll-padding: ${scrollPadding}px;
        }

        .scroll-snap-x > * {
          scroll-snap-align: center;
          scroll-snap-stop: always;
        }
      `}</style>
    </Grid>
  );
}
