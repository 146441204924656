// import Link from 'next/link';
// import is from 'is_js';
import Router from 'next/router';

interface SearchPanelRecommendKeywordsProps {
  searchResult?: any;
  recent?: any;
  recommended?: any;
  className?: string;
  searchModal?: any;
}

const SearchPanelRecommendKeywords = ({
  searchResult,
  recent,
  recommended,
  className,
  searchModal,
}: SearchPanelRecommendKeywordsProps) => {
  function handleSearch(data) {
    Router.push(`/search/${data}`);
    document.body.style.overflow = 'auto';
    searchModal.onClose();
  }

  return (
    <div className={`w-full md:w-auto ${className}`}>
      {searchResult.length > 0 ? (
        <>
          {searchResult.map((keyword, key) => {
            const mapKey = `key_${key}`;

            return (
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleSearch(keyword)}
                onKeyDown={() => handleSearch(keyword)}
                key={mapKey}
              >
                <a className="block text-gray-900 text-sm mt-3">{keyword}</a>
              </div>
            );
          })}
        </>
      ) : (
        <div className="grid grid-cols-2">
          <div className="px-4">
            <h1 className="text-xs font-bold text-gray-400">RECENT</h1>

            {recent.map((keyword, key) => {
              const mapKey = `recent_key_${key}`;
              return (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSearch(keyword)}
                  onKeyDown={() => handleSearch(keyword)}
                  key={mapKey}
                >
                  <a className="block text-gray-900 text-sm mt-3">{keyword}</a>
                </div>
              );
            })}
          </div>
          <div className="px-4">
            <h1 className="text-xs font-bold text-gray-400">RECOMMENDED</h1>
            {recommended.map((keyword, key) => {
              const mapKey = `recommended_key_${key}`;
              return (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSearch(keyword)}
                  onKeyDown={() => handleSearch(keyword)}
                  key={mapKey}
                >
                  <a className="block text-grayy-900 text-sm mt-3">{keyword}</a>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default SearchPanelRecommendKeywords;
