import * as React from 'react';
import { Svg } from '../svg';

function SvgOrig({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 41 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.008.998l-1.93.848v.002l-.491.216A6 6 0 000 7.557v23.266l.047-.02a6 6 0 013.54-4.74L6.007 25V.998z"
        fill="url(#orig_svg__paint0_linear_7353_158208)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.008 88.628l-1.93-.847v-.003l-.491-.215A6 6 0 010 82.07V58.803l.047.02a6 6 0 003.54 4.74l2.42 1.063v24.002z"
        fill="url(#orig_svg__paint1_linear_7353_158208)"
      />
      <path
        d="M0 55.511a6 6 0 013.587-5.493l.49-.215V49.8l32.367-14.22a6 6 0 003.539-4.738l.047-.02v27.266a6 6 0 01-3.586 5.493l-.491.216v.002L3.587 78.019a6 6 0 00-3.54 4.738l-.047.02V55.512z"
        fill="url(#orig_svg__paint2_linear_7353_158208)"
      />
      <path
        d="M0 34.11a6 6 0 003.587 5.493l.49.216v.002l32.367 14.22a6 6 0 013.539 4.738l.047.02V31.534a6 6 0 00-3.586-5.493l-.491-.215v-.003L3.587 11.602a6 6 0 01-3.54-4.738L0 6.844V34.11z"
        fill="url(#orig_svg__paint3_linear_7353_158208)"
      />
      <path
        d="M4.71 27.017c0 1.139.035 1.91.103 2.31.037.214.083.392.137.533.06.137.126.28.198.431.242.46.63.866 1.163 1.217.524.364 1.178.556 1.961.574.793-.018 1.454-.21 1.983-.574.524-.351.902-.757 1.135-1.217.168-.242.284-.563.348-.964.06-.4.09-1.171.09-2.31 0-1.158-.03-1.933-.09-2.325-.063-.392-.18-.708-.348-.95-.233-.46-.611-.87-1.135-1.23-.529-.365-1.19-.552-1.983-.56-.783.008-1.437.195-1.961.56-.534.36-.921.77-1.163 1.23-.15.242-.262.558-.335.95-.068.392-.102 1.167-.102 2.325zm1.519 0c0-.98.036-1.648.109-2.003a1.65 1.65 0 01.39-.828c.159-.205.369-.375.628-.512a2.09 2.09 0 01.916-.212c.36.009.673.08.937.212.25.137.451.307.602.512.196.215.33.49.403.828.068.355.102 1.023.102 2.003 0 .98-.034 1.643-.102 1.989-.073.346-.207.627-.403.84-.15.206-.351.372-.602.5-.264.15-.577.225-.937.225-.355 0-.66-.075-.916-.225a1.768 1.768 0 01-.628-.5c-.196-.213-.326-.494-.39-.84-.073-.346-.11-1.01-.11-1.99z"
        fill="url(#orig_svg__paint4_linear_7353_158208)"
      />
      <path
        d="M16.064 27.38h2.365c.483 0 .852.102 1.107.308.324.232.49.608.5 1.127 0 .433-.14.798-.418 1.094-.282.32-.706.483-1.271.492h-2.284V27.38zM14.553 36h1.51v-4.259h1.928L20.05 36h1.798l-2.31-4.423c1.266-.488 1.909-1.408 1.927-2.762-.027-.92-.358-1.629-.991-2.126-.524-.437-1.201-.656-2.03-.656h-3.89V36z"
        fill="url(#orig_svg__paint5_linear_7353_158208)"
      />
      <path
        d="M24.053 40h1.51v-9.967h-1.51V40z"
        fill="url(#orig_svg__paint6_linear_7353_158208)"
      />
      <path
        d="M31.786 38.94h2.03v.519c-.009.624-.205 1.128-.588 1.51-.382.402-.868.602-1.456.602-.355 0-.66-.075-.916-.225a1.768 1.768 0 01-.629-.5c-.195-.213-.325-.494-.39-.84-.072-.346-.108-1.01-.108-1.99s.036-1.647.109-2.002a1.65 1.65 0 01.39-.828c.159-.205.369-.376.628-.512a2.09 2.09 0 01.916-.212c.483.009.894.148 1.23.417.325.282.548.624.67 1.025h1.6a3.76 3.76 0 00-1.148-2.098c-.606-.56-1.39-.846-2.352-.855-.783.01-1.437.196-1.961.56-.534.36-.921.77-1.163 1.231-.15.242-.262.559-.334.95-.069.392-.103 1.167-.103 2.325 0 1.139.034 1.91.103 2.31.036.214.081.392.136.533.06.137.126.28.198.431.242.46.63.866 1.163 1.217.524.364 1.178.556 1.962.574 1.016-.018 1.859-.362 2.529-1.032.665-.675 1.007-1.51 1.025-2.509v-2.03h-3.54v1.428z"
        fill="url(#orig_svg__paint7_linear_7353_158208)"
      />
      <defs>
        <linearGradient
          id="orig_svg__paint0_linear_7353_158208"
          x1={0}
          y1={10.629}
          x2={4.871}
          y2={10.419}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBDBDB" />
          <stop offset={1} stopColor="#9E9E9E" />
        </linearGradient>
        <linearGradient
          id="orig_svg__paint1_linear_7353_158208"
          x1={0}
          y1={78.997}
          x2={4.871}
          y2={79.207}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBDBDB" />
          <stop offset={1} stopColor="#9E9E9E" />
        </linearGradient>
        <linearGradient
          id="orig_svg__paint2_linear_7353_158208"
          x1={0}
          y1={66}
          x2={31.656}
          y2={71.226}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBDBDB" />
          <stop offset={1} stopColor="#9E9E9E" />
        </linearGradient>
        <linearGradient
          id="orig_svg__paint3_linear_7353_158208"
          x1={0}
          y1={10.664}
          x2={70.704}
          y2={20.326}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#D3D3D3" />
        </linearGradient>
        <linearGradient
          id="orig_svg__paint4_linear_7353_158208"
          x1={8.5}
          y1={18}
          x2={8.5}
          y2={36}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#987704" />
          <stop offset={0.26} stopColor="#DBAE0F" />
          <stop offset={0.578} stopColor="#EDCD5D" />
          <stop offset={0.844} stopColor="#DBAE0F" />
          <stop offset={1} stopColor="#785F05" />
        </linearGradient>
        <linearGradient
          id="orig_svg__paint5_linear_7353_158208"
          x1={18}
          y1={22}
          x2={18}
          y2={40}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#987704" />
          <stop offset={0.26} stopColor="#DBAE0F" />
          <stop offset={0.578} stopColor="#EDCD5D" />
          <stop offset={0.844} stopColor="#DBAE0F" />
          <stop offset={1} stopColor="#785F05" />
        </linearGradient>
        <linearGradient
          id="orig_svg__paint6_linear_7353_158208"
          x1={25}
          y1={26}
          x2={25}
          y2={44}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#987704" />
          <stop offset={0.26} stopColor="#DBAE0F" />
          <stop offset={0.578} stopColor="#EDCD5D" />
          <stop offset={0.844} stopColor="#DBAE0F" />
          <stop offset={1} stopColor="#785F05" />
        </linearGradient>
        <linearGradient
          id="orig_svg__paint7_linear_7353_158208"
          x1={32}
          y1={29}
          x2={32}
          y2={47}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#987704" />
          <stop offset={0.26} stopColor="#DBAE0F" />
          <stop offset={0.578} stopColor="#EDCD5D" />
          <stop offset={0.844} stopColor="#DBAE0F" />
          <stop offset={1} stopColor="#785F05" />
        </linearGradient>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgOrig);
export default ForwardRef;
