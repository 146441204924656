import * as React from 'react';
import { Svg } from '../svg';

function SvgOpensea({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#opensea_svg__clip0_6588_79579)">
        <path
          d="M24 12.553c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12c6.628 0 12 5.373 12 12z"
          fill="#2081E2"
        />
        <path
          d="M5.92 12.956l.052-.081L9.094 7.99a.107.107 0 01.187.014c.522 1.169.972 2.622.761 3.527-.09.373-.337.877-.614 1.343a2.457 2.457 0 01-.117.198.106.106 0 01-.089.047h-3.21a.106.106 0 01-.092-.164z"
          fill="#fff"
        />
        <path
          d="M19.835 13.863v.773a.111.111 0 01-.067.101c-.242.104-1.069.484-1.413.962-.878 1.222-1.548 2.969-3.048 2.969H9.053A4.02 4.02 0 015.04 14.64v-.071c0-.06.048-.108.107-.108h3.487c.069 0 .12.065.113.132a1.19 1.19 0 00.125.67c.207.42.636.683 1.1.683h1.726v-1.348H9.99a.11.11 0 01-.088-.172c.018-.029.039-.058.061-.092.162-.229.392-.585.622-.99a7.75 7.75 0 00.43-.86c.024-.053.044-.107.064-.16.033-.094.068-.182.092-.27.025-.073.045-.15.064-.224.058-.249.083-.512.083-.786 0-.107-.005-.22-.015-.327a4.263 4.263 0 00-.034-.351 3.591 3.591 0 00-.048-.314 5.247 5.247 0 00-.099-.468l-.013-.06c-.03-.106-.055-.209-.09-.316a11.935 11.935 0 00-.469-1.328c-.073-.176-.147-.336-.215-.488a6.88 6.88 0 01-.093-.196 7.002 7.002 0 00-.103-.215c-.024-.053-.053-.102-.072-.151l-.211-.39a.069.069 0 01.078-.1l1.319.358h.004l.004.001.174.048.191.054.07.02v-.784c0-.378.304-.685.679-.685a.67.67 0 01.48.2.689.689 0 01.198.485v1.164l.14.04a.111.111 0 01.032.015l.147.111c.05.04.103.088.167.137a10.021 10.021 0 01.572.503c.214.2.455.434.684.693.064.073.127.147.191.224.064.08.132.157.191.235.078.103.162.21.234.323.035.053.074.107.108.16.093.142.176.288.255.435.033.068.068.142.097.215.088.196.157.396.201.595.014.043.024.09.029.132v.01c.014.06.02.122.024.186a1.995 1.995 0 01-.107.873c-.03.084-.06.171-.097.254a3.427 3.427 0 01-.266.503 2.83 2.83 0 01-.112.181c-.043.063-.087.122-.127.18a3.787 3.787 0 01-.171.221 2.36 2.36 0 01-.167.21c-.082.097-.161.19-.244.278-.049.058-.102.117-.156.17-.053.06-.107.112-.157.162a6.422 6.422 0 01-.21.2l-.135.124a.11.11 0 01-.073.027h-1.05v1.348h1.322c.295 0 .577-.105.803-.297.078-.068.417-.362.818-.804a.103.103 0 01.05-.031l3.65-1.055a.108.108 0 01.138.103z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="opensea_svg__clip0_6588_79579">
          <path fill="#fff" transform="translate(0 .553)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgOpensea);
export default ForwardRef;
