import * as React from 'react';
import { Svg } from '../svg';

function SvgLogo2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M9.221 0v19.746H3.655V4.316H0V0h9.221zM16.595 10.55c-.9 0-1.659-.3-2.277-.903-.619-.601-.928-1.363-.928-2.285 0-.94.3-1.701.9-2.284.618-.583 1.387-.875 2.305-.875.937 0 1.706.292 2.305.875.6.583.9 1.344.9 2.284 0 .922-.31 1.684-.928 2.285-.6.602-1.359.903-2.277.903zm0 9.45c-.9 0-1.659-.3-2.277-.903-.619-.602-.928-1.363-.928-2.285 0-.94.3-1.702.9-2.285.618-.582 1.387-.874 2.305-.874.937 0 1.706.291 2.305.874.6.584.9 1.345.9 2.285 0 .922-.31 1.683-.928 2.285-.6.602-1.359.903-2.277.903z"
        fill="#242526"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgLogo2);
export default ForwardRef;
