import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useState } from 'react';

import {
  CollectionsStartBody,
  CollectionsStartFooter,
} from './collections-start';
import { CollectionsDoneBody, CollectionsDoneFooter } from './collections-done';

const Collections = ({ modal }: { modal: any }) => {
  const [tutorialModalContent, setTutorialModalContent] = useState(
    'collectionsStart'
  );

  return (
    <>
      <Modal
        isOpen={modal.isOpen}
        onClose={() => {
          modal.onClose();
          modal.openChecklistModal();
          setTutorialModalContent('collectionsStart');
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalCloseButton color="gray.500" />
          <ModalBody px="60px" pt="40px" pb="24px" bg="white">
            {tutorialModalContent === 'collectionsStart' && (
              <CollectionsStartBody />
            )}
            {tutorialModalContent === 'collectionsDone' && (
              <CollectionsDoneBody />
            )}
          </ModalBody>
          <ModalFooter
            flexDirection="column"
            alignItems="center"
            bg="gray.100"
            px="60px"
            py="25px"
          >
            {tutorialModalContent === 'collectionsStart' && (
              <CollectionsStartFooter setNextModal={setTutorialModalContent} />
            )}
            {tutorialModalContent === 'collectionsDone' && (
              <CollectionsDoneFooter
                completed={modal.completed}
                closeModal={() => {
                  modal.onClose();
                  modal.openChecklistModal();
                  setTutorialModalContent('collectionsStart');
                }}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Collections;
