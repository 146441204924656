import * as React from 'react';
import { Svg } from '../svg';

function SvgChecklistStartReppl({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 5v4H7v2h4v4h2v-4h4v-2h-4V7h-2zm-7 5c0 4.41 3.59 8 8 8s8-3.59 8-8-3.59-8-8-8-8 3.59-8 8z"
        fill="#191919"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgChecklistStartReppl);
export default ForwardRef;
