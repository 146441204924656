import * as React from 'react';
import { Svg } from '../svg';

function SvgCancel({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g filter="url(#cancel_svg__filter0_d_2969_46239)">
        <rect x={4} y={2} width={32} height={32} rx={16} fill="currentColor" />
        <path
          d="M25 13L15 23M15 13l10 10"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="cancel_svg__filter0_d_2969_46239"
          x={0}
          y={0}
          width={40}
          height={40}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2969_46239"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2969_46239"
            result="shape"
          />
        </filter>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCancel);
export default ForwardRef;
