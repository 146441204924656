import { Modal, ModalContent, useDisclosure } from '@chakra-ui/react';
import SvgSuccessIcon from 'src/components/icons/success-icon';
import SvgArrowRightV5 from 'src/components/icons/arrow-right-v5';
import AfterMintingModal from 'src/app/asset/AfterMintingModal';
import styles from './mint-successful-modal.module.scss';

export default function MintSuccessfulModal({ closeModal, isOpen }) {
  const afterMintingModal = useDisclosure();

  return (
    <Modal isOpen={isOpen} onClose={closeModal} allowPinchZoom>
      <ModalContent
        maxW="100vw"
        w="100vw"
        h="100vh"
        borderRadius="none"
        background="none"
      >
        <div className={styles.fadein}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,  jsx-a11y/click-events-have-key-events */}
          <div className={styles.overlay} onClick={closeModal}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,  jsx-a11y/click-events-have-key-events */}
            <div
              className={styles.content}
              onClick={(e) => e.stopPropagation()}
            >
              <SvgSuccessIcon fontSize="109px" />
              <h2>Congratulations!</h2>
              <p>
                Your minting is completed, now you can see it from your Studio
                and Opensea.
              </p>
              <div className={styles.main_button}>View it in your studio</div>
              <div className={styles.secondary_button}>See it on OpenSea</div>

              <div className={styles.bottom_section}>
                <button type="button" onClick={afterMintingModal.onOpen}>
                  <SvgArrowRightV5 fontSize="8px" />
                  Where can I find the minted NFT?
                </button>
                <button type="button" onClick={afterMintingModal.onOpen}>
                  <SvgArrowRightV5 fontSize="8px" />
                  What can I do with the minted NFT?
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
      <AfterMintingModal
        closeModal={afterMintingModal.onClose}
        isOpen={afterMintingModal.isOpen}
      />
    </Modal>
  );
}
