import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useState } from 'react';

import {
  HowToStartARepplStartBody,
  HowToStartARepplStartFooter,
} from './how-to-start-a-reppl';
import {
  HowToStartAReppl1Body,
  HowToStartAReppl1Footer,
} from './how-to-start-a-reppl-1';
import {
  HowToStartAReppl2Body,
  HowToStartAReppl2Footer,
} from './how-to-start-a-reppl-2';

const HowToStartAReppl = ({ modal }: { modal: any }) => {
  const [tutorialModalContent, setTutorialModalContent] = useState(
    'howToStartARepplStart'
  );

  return (
    <>
      <Modal
        isOpen={modal.isOpen}
        onClose={() => {
          modal.onClose();
          modal.openChecklistModal();
          setTutorialModalContent('howToStartARepplStart');
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalCloseButton color="gray.500" />
          <ModalBody px="60px" pt="40px" pb="24px" bg="white">
            {tutorialModalContent === 'howToStartARepplStart' && (
              <HowToStartARepplStartBody />
            )}
            {tutorialModalContent === 'howToStartAReppl1' && (
              <HowToStartAReppl1Body />
            )}
            {tutorialModalContent === 'howToStartAReppl2' && (
              <HowToStartAReppl2Body />
            )}
          </ModalBody>
          <ModalFooter
            flexDirection="column"
            alignItems="center"
            bg="gray.100"
            px="60px"
            py="25px"
          >
            {tutorialModalContent === 'howToStartARepplStart' && (
              <HowToStartARepplStartFooter
                setNextModal={setTutorialModalContent}
              />
            )}
            {tutorialModalContent === 'howToStartAReppl1' && (
              <HowToStartAReppl1Footer setNextModal={setTutorialModalContent} />
            )}
            {tutorialModalContent === 'howToStartAReppl2' && (
              <HowToStartAReppl2Footer
                completed={modal.completed}
                closeModal={() => {
                  modal.onClose();
                  modal.openChecklistModal();
                  setTutorialModalContent('howToStartARepplStart');
                }}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HowToStartAReppl;
