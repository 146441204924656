import * as React from 'react';
import { Svg } from '../svg';

function SvgWallet({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        opacity={0.75}
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20 11V7H4.25a2.4 2.4 0 01-1.591-.586A1.894 1.894 0 012 5c0-1.1 1.013-2 2.25-2h13.5v4" />
        <path d="M2 5v13.714C2 19.971 3.013 21 4.25 21H20v-4" />
        <path d="M18 11c-.53 0-1.04.316-1.414.879C16.21 12.44 16 13.204 16 14c0 1.65.9 3 2 3h4v-6h-4z" />
      </g>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgWallet);
export default ForwardRef;
