/* eslint-disable no-nested-ternary */
import { Flex, Text } from '@chakra-ui/react';
import { components } from 'react-select';

import { getProfileFullNameOrUsername } from 'src/app/profile/utils';

import SvgSearch from 'src/components/icons/search';
import SvgClearCircle from 'src/components/icons/clear-circle';
import SvgClose from 'src/components/icons/close';
import { useMemo } from 'react';
import { Avatar } from '../avatar';

const defaultSelectProps = {
  noOptionsMessage: () => 'No results found.',
  theme: (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: 'var(--mode50)',
      neutral0: 'var(--mode50)',
      neutral5: 'var(--mode50)',
      neutral10: 'var(--mode50)',
      primary75: 'var(--mode100)',
      primary50: 'var(--mode100)',
      primary25: 'var(--mode100)',
    },
  }),
};

const defaultAvatarProps = {
  noOptionsMessage: () => 'No results found.',
};

const defaultSideProps = {
  noOptionsMessage: () => 'No results found.',
  theme: (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      neutral0: 'var(--modeBg)',
      neutral5: 'var(--mode50)',
      neutral10: 'var(--mode50)',
      primary75: 'var(--mode100)',
      primary50: 'var(--mode100)',
      primary25: 'var(--mode100)',
    },
  }),
};

/**
 * React select custom component
 */
const ClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <div style={{ padding: '0px 5px' }}>
        <SvgClearCircle fontSize="20px" color="$mode.400" />
      </div>
    </div>
  );
};

const Placeholder = (props) => {
  const children = (
    <Flex color="#51535c">
      <SvgSearch fontSize="24px" /> {props.children}
    </Flex>
  );
  return <components.Placeholder {...props}>{children}</components.Placeholder>;
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <SvgClose fontSize="16px" />
    </components.MultiValueRemove>
  );
};

const AvatarMultiValueContainer = (props) => {
  return (
    <components.MultiValueContainer verticalAlign="middle" {...props}>
      <Avatar
        width={24}
        height={24}
        svgClassName="align-top -left-1 circle-wrapper"
        src={props.data?.profileImgURL}
        name={getProfileFullNameOrUsername(props.data)}
        author={props.data}
      />
      {props.children}
    </components.MultiValueContainer>
  );
};

const AvatarOption = (props) => (
  <components.Option {...props}>
    <Flex align="center">
      <Avatar
        width={24}
        height={24}
        src={props.data?.profileImgURL}
        name={getProfileFullNameOrUsername(props.data)}
        author={props.data}
      />
      <Text noOfLines={1} ml={2} textStyle="body2">
        {props.children}
      </Text>
    </Flex>
  </components.Option>
);

export const FlushPreset = ({
  error,
  px,
}: { error?: boolean; px?: number } = {}) =>
  ({
    components: {
      DropdownIndicator: null,
      ClearIndicator,
      Placeholder,
      MultiValueRemove,
    },
    ...defaultSelectProps,
    styles: {
      noOptionsMessage: (p) => ({
        ...p,
        backgroundColor: 'var(--mode900)',
        color: 'var(--mode100)',
      }),
      input: (p) => ({
        ...p,
        color: 'var(--mode900)',
      }),
      control: (p, { isFocused }) => ({
        ...p,
        borderRadius: 0,
        border: 'none',
        borderWidth: 0,
        backgroundColor: 'transparent',
        borderBottom: '2px solid',
        borderBottomColor: error
          ? '#EB5757'
          : isFocused
          ? 'var(--mode50)'
          : 'var(--mode200)',
        '&:hover': {
          borderBottomColor: error
            ? '#EB5757'
            : isFocused
            ? 'var(--mode50)'
            : 'var(--mode200)',
        },
        boxShadow: 'none',
      }),
      valueContainer: (p) => ({
        ...p,
        padding: px ? '0px 16px' : 0,
      }),
      clearIndicator: (provided) => ({
        ...provided,
        cursor: 'pointer',
      }),
      option: (style, { isFocused }) => ({
        ...style,
        backgroundColor: isFocused ? 'var(--mode100)' : 'var(--mode50)',
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'var(--mode50)',
        boxShadow: 'inset 0 0 0px 1px var(--mode300)',
        color: 'var(--mode500)',
        margin: 4,
      }),
      multiValueLabel: (p) => ({
        ...p,
        paddingLeft: 16,
        paddingTop: 7,
        paddingBottom: 7,
        color: 'var(--mode500)',
        fontWeight: 700,
      }),
      multiValueRemove: () => ({
        cursor: 'pointer',
        paddingLeft: 4,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 8,
        display: 'flex',
        alignItems: 'center',
      }),
      placeholder: (provided) => ({
        ...provided,
        fontWeight: 500,
        color: 'var(--text3)',
      }),
    },
  } as any);

export const SpacePreset = ({ error }: { error?: boolean } = {}) =>
  ({
    components: {
      DropdownIndicator: null,
      Placeholder,
      MultiValueRemove,
    },
    ...defaultSelectProps,
    styles: {
      noOptionsMessage: (p) => ({
        ...p,
        backgroundColor: 'var(--mode900)',
        color: 'var(--mode100)',
      }),
      input: (p) => ({
        ...p,
        color: '#afb1ba',
      }),
      control: (p, { isFocused }) => ({
        ...p,
        borderRadius: 4,
        border: `1px solid ${error ? '#EB5757' : '#313235'}`,
        borderWidth: 0,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        minHeight: '82px',
        borderColor: error
          ? '#EB5757'
          : isFocused
          ? 'var(--mode50)'
          : '#313235',
        '&:hover': {
          borderColor: error
            ? '#EB5757'
            : isFocused
            ? 'var(--mode50)'
            : 'var(--mode200)',
        },
      }),
      valueContainer: (p) => ({
        ...p,
        padding: '8px !important',
      }),
      option: (style, { isFocused }) => ({
        ...style,
        backgroundColor: isFocused ? 'var(--mode100)' : 'var(--mode50)',
      }),
      multiValue: (provided) => ({
        ...provided,
        border: '1px solid #8c8f9b',
        borderRadius: '13px 13px 13px 13px',
        backgroundColor: 'initial',
        color: 'var(--mode500)',
        margin: '4px',
      }),
      multiValueLabel: (p) => ({
        ...p,
        paddingLeft: 12,
        paddingTop: 4,
        paddingBottom: 4,
        font: '500 12px/1.5 "Montserrat", Helvetica, Arial, serif',
        color: '#afb1ba',
        letterSpacing: '0px',
      }),
      multiValueRemove: () => ({
        cursor: 'pointer',
        paddingRight: 8,
        paddingTop: 4,
        paddingBottom: 4,
        display: 'flex',
        alignItems: 'center',
        color: '#51535c',
      }),
      placeholder: (provided) => ({
        ...provided,
        fontWeight: 500,
        color: 'var(--text3)',
      }),
    },
  } as any);

export const GeneralPreset = ({
  error,
  px,
}: { error?: boolean; px?: number } = {}) =>
  ({
    components: {
      DropdownIndicator: null,
      ClearIndicator,
      Placeholder,
      MultiValueRemove,
    },
    ...defaultSelectProps,
    styles: {
      noOptionsMessage: (p) => ({
        ...p,
        backgroundColor: 'var(--mode900)',
        color: 'var(--mode100)',
      }),
      input: (p) => ({
        ...p,
        color: 'var(--mode900)',
      }),
      control: (p, { isFocused }) => ({
        ...p,
        borderRadius: 0,
        border: 'none',
        borderWidth: 0,
        backgroundColor: 'transparent',
        borderBottom: '2px solid',
        borderBottomColor: error
          ? '#EB5757'
          : isFocused
          ? 'var(--mode50)'
          : 'var(--mode200)',
        '&:hover': {
          borderBottomColor: error
            ? '#EB5757'
            : isFocused
            ? 'var(--mode50)'
            : 'var(--mode200)',
        },
        boxShadow: 'none',
      }),
      valueContainer: (p) => ({
        ...p,
        padding: px ? '0px 16px' : 0,
      }),
      clearIndicator: (provided) => ({
        ...provided,
        cursor: 'pointer',
      }),
      option: (style, { isFocused }) => ({
        ...style,
        backgroundColor: isFocused ? 'var(--mode100)' : 'var(--mode50)',
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#E1EFFC',
        boxShadow: 'inset 0 0 0px 1px #E1EFFC',
        color: '#81BCF2',
        margin: 4,
        borderRadius: '9999px',
      }),
      multiValueLabel: (p) => ({
        ...p,
        paddingLeft: 16,
        paddingTop: 7,
        paddingBottom: 7,
        color: '#0278E4',
        fontWeight: 700,
      }),
      multiValueRemove: () => ({
        cursor: 'pointer',
        paddingLeft: 4,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 8,
        display: 'flex',
        alignItems: 'center',
      }),
      placeholder: (provided) => ({
        ...provided,
        fontWeight: 500,
        color: 'var(--text3)',
      }),
    },
  } as any);

export const BasicPreset = ({ error }: { error?: boolean } = {}) => {
  return {
    components: {
      DropdownIndicator: null,
      ClearIndicator,
      MultiValueRemove,
    },
    ...defaultSelectProps,
    styles: {
      noOptionsMessage: (p) => ({
        ...p,
        backgroundColor: 'var(--mode50)',
        color: 'var(--mode900)',
      }),
      control: (p) => ({
        ...p,
        borderWidth: 'var(--mode-border-width)',
        borderColor: error ? '#EB5757' : 'var(--mode900)',
        backgroundColor: 'var(--modeBg)',
      }),
      singleValue: (p) => ({
        ...p,
        color: '$mode.900',
      }),
      valueContainer: (p) => ({
        ...p,
        padding: '0px 16px',
      }),
      clearIndicator: (provided) => ({
        ...provided,
        cursor: 'pointer',
      }),
      option: (style, { isFocused }) => ({
        ...style,
        backgroundColor: isFocused ? 'var(--mode50)' : 'var(--mode100)',
      }),
      input: (p) => ({
        ...p,
        color: 'var(--mode900)',
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'var(--mode900)',
        boxShadow: 'inset 0 0 0px 1px var(--mode300)',
        color: 'var(--mode500)',
        margin: 4,
      }),
      multiValueLabel: (p) => ({
        ...p,
        paddingLeft: 16,
        paddingTop: 7,
        paddingBottom: 7,
        color: 'var(--mode500)',
        fontWeight: 700,
      }),
      multiValueRemove: () => ({
        cursor: 'pointer',
        paddingLeft: 4,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 8,
        display: 'flex',
        alignItems: 'center',
      }),
      placeholder: (provided) => ({
        ...provided,
        fontWeight: 500,
        color: 'var(--mode300)',
      }),
    },
  } as any;
};

export const LicensePreset = () => {
  return {
    components: {
      MultiValueRemove,
    },
    ...defaultSideProps,
    styles: {
      indicatorSeparator: (p) => ({
        ...p,
        display: 'none',
      }),
      noOptionsMessage: (p) => ({
        ...p,
        backgroundColor: 'var(--mode50)',
        color: 'var(--mode900)',
      }),
      control: (p) => ({
        ...p,
        borderWidth: 'var(--mode-border-width)',
        borderColor: 'var(--mode200)',
        boxShadow: '#9B9FA4',
        '&:hover': {
          borderColor: 'var(--mode200)',
        },
      }),
      singleValue: (p) => ({
        ...p,
        color: '$mode.900',
      }),
      valueContainer: (p) => ({
        ...p,
        padding: '0px 16px',
        borderColor: 'var(--mode200)',
      }),
      clearIndicator: (provided) => ({
        ...provided,
        cursor: 'pointer',
      }),
      option: (style, { isFocused }) => ({
        ...style,
        backgroundColor: isFocused ? 'var(--mode50)' : 'var(--mode100)',
        color: 'inherit',
      }),
      input: (p) => ({
        ...p,
        color: 'var(--mode900)',
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#f3f3f4',
        color: '#BBC0C5',
        borderRadius: '14px',
      }),
      multiValueLabel: (p) => ({
        ...p,
        paddingLeft: 12,
        paddingTop: 4,
        paddingBottom: 4,
        color: '#288ce8',
        fontWeight: 500,
        fontSize: '12px',
        margin: 'auto',
      }),
      multiValueRemove: () => ({
        cursor: 'pointer',
        paddingLeft: 5,
        paddingRight: 9,
        paddingTop: 4,
        paddingBottom: 4,
        display: 'flex',
        alignItems: 'center',
      }),
      placeholder: (provided) => ({
        ...provided,
        fontWeight: 500,
        fontSize: 14,
        color: 'var(--mode400)',
      }),
    },
  } as any;
};

export const SidePreset = ({
  error,
  disabled,
  bgColor,
}: {
  error?: boolean;
  bgColor?: string;
  disabled?: boolean;
} = {}) => {
  const borderColor = useMemo(() => {
    if (disabled) return '#E6E8E9';
    if (!error) return '#D3D6D8';
    return bgColor || '#EB5757';
  }, [bgColor, error]);

  return {
    components: {
      DropdownIndicator: null,
      MultiValueRemove,
    },
    ...defaultSideProps,
    styles: {
      noOptionsMessage: (p) => ({
        ...p,
        backgroundColor: 'var(--mode50)',
        color: 'var(--mode900)',
      }),
      control: (p, { isFocused }) => ({
        ...p,
        borderWidth: 'var(--mode-border-width)',
        backgroundColor: disabled ? '#F3F3F4' : 'var(--mode50)',
        borderColor,
        boxShadow: '#9B9FA4',
        '&:hover': {
          borderColor: error ? '#D3D6D8' : isFocused ? '#9B9FA4' : '#D3D6D8',
        },
      }),
      singleValue: (p) => ({
        ...p,
        color: '$mode.900',
      }),
      valueContainer: (p) => ({
        ...p,
        padding: '0px 16px',
        borderColor: 'var(--mode200)',
      }),
      clearIndicator: (provided) => ({
        ...provided,
        cursor: 'pointer',
      }),
      option: (style, { isFocused }) => ({
        ...style,
        backgroundColor: isFocused ? 'var(--mode50)' : 'var(--mode100)',
        color: 'inherit',
      }),
      input: (p) => ({
        ...p,
        color: 'var(--mode900)',
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: disabled ? '#E6E8E9' : '#F3F3F4',
        color: disabled ? '#288CE8' : '#BBC0C5',
        borderRadius: '14px',
      }),
      multiValueLabel: (p) => ({
        ...p,
        paddingLeft: 12,
        paddingTop: 4,
        paddingBottom: 4,
        color: '#288ce8',
        fontWeight: 500,
        fontSize: '12px',
        margin: 'auto',
      }),
      multiValueRemove: () => ({
        cursor: 'pointer',
        paddingLeft: 5,
        paddingRight: 9,
        paddingTop: 4,
        paddingBottom: 4,
        display: 'flex',
        alignItems: 'center',
      }),
      placeholder: (provided) => ({
        ...provided,
        fontWeight: 500,
        fontSize: 14,
        color: 'var(--mode400)',
      }),
    },
  } as any;
};

export const AvatarPreset = () => ({
  theme: defaultSelectProps.theme,
  components: {
    Option: AvatarOption,
    DropdownIndicator: null,
    MultiValueRemove,
    MultiValueContainer: AvatarMultiValueContainer,
  },
  ...defaultAvatarProps,
  styles: {
    control: (p) => ({
      ...p,
      backgroundColor: 'var(--modeBg)',
      borderWidth: 'var(--mode-border-width)',
    }),
    input: (p) => ({
      ...p,
      color: 'var(--mode900)',
    }),
    option: (provided) => ({
      ...provided,
      color: 'var(--mode500)',
      '&:hover': {
        color: 'var(--mode700)',
      },
      fontWeight: 600,
      lineHeight: 2,
    }),
    multiValueLabel: (p) => ({
      ...p,
      color: 'var(--mode900)',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1,
      paddingTop: 2,
      paddingBottom: 2,
    }),
    valueContainer: (p) => ({
      ...p,
      borderColor: 'var(--mode300)',
    }),
    multiValue: (provided) => ({
      ...provided,
      boxShadow: 'none',
      height: '24px',
      borderRadius: '12px',
      border: '1px solid var(--mode200)',
      alignItems: 'center',
      backgroundColor: 'var(--mode100)',
      color: 'var(--mode900)',
      margin: 4,
    }),
    multiValueRemove: () => ({
      cursor: 'pointer',
      color: 'var(--mode400)',
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px',
      '&:hover': {
        background: 'transparent',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: 500,
      fontSize: 14,
      color: 'var(--mode400)',
    }),
  },
});

export const FlushAvatarPreset = () => {
  const avatarPreset = AvatarPreset();
  return {
    styles: {
      ...avatarPreset.styles,
      control: FlushPreset().styles.control,
    },
    components: {
      ...avatarPreset.components,
      Placeholder,
      DropdownIndicator: null,
      ClearIndicator,
    },
  };
};
