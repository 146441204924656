/**
 * Internal dependencies
 */
import { useIsMobile } from 'src/hooks/use-is-mobile';

import { CardGrid } from 'src/components/card/card-grid';
import CreatorGrid from 'src/components/common/card/creator-grid';

const SearchResultUserLayout = ({ onClick, showViewAll, children }) => {
  const isMobile = useIsMobile();
  return (
    <div>
      <div
        className={`relative flex text-gray-900 items-center justify-between ${
          isMobile && 'pb-4'
        }`}
      >
        <div
          className={`text-base text-gray-500 font-semibold pl-4 ${
            isMobile ? 'pt-4' : 'pt-6'
          }`}
        >
          Creators
        </div>
        {showViewAll && (
          <div
            className="text-sm font-medium pt-6 pr-4 text-gray-700 cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyDown={onClick}
          >
            View all
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

function SearchResultUser({ result, resultLimit = 3, isAllResult = false }) {
  const isMobile = useIsMobile();
  return (
    <CardGrid paddingTop={isMobile && !isAllResult ? '16px' : 0}>
      {result.slice(0, resultLimit).map((data, index) => {
        const key = `search_result_user_${index}`;
        return <CreatorGrid data={data} key={key} />;
      })}
    </CardGrid>
  );
}

SearchResultUser.Layout = SearchResultUserLayout;

export default SearchResultUser;
