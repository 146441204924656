import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import { Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import SvgCopy from 'src/components/icons/copy';
import SvgCopied from 'src/components/icons/copied';

export const ShareButtons = ({
  facebook = false,
  twitter = false,
  copyLink = false,
  url = window.location.href,
  hashtags,
  size = '2rem',
}: {
  facebook?: boolean;
  twitter?: boolean;
  copyLink?: boolean;
  url?: string;
  hashtags?: Array<string>;
  size?: string;
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const CopyLink = () => {
    const shareURL = url;
    copy(shareURL);
    setIsCopied(true);
  };

  useEffect(() => {
    return () => {
      setIsCopied(false);
    };
  }, []);

  return (
    <Stack spacing={3} direction="row">
      {facebook && (
        <FacebookShareButton url={url}>
          <FacebookIcon
            style={{ borderRadius: '100%', overflow: 'hidden' }}
            size={size}
          />
        </FacebookShareButton>
      )}
      {twitter && (
        <TwitterShareButton url={url} hashtags={hashtags}>
          <TwitterIcon
            style={{ borderRadius: '100%', overflow: 'hidden' }}
            size={size}
          />
        </TwitterShareButton>
      )}
      {copyLink && (
        <button type="button" onClick={CopyLink}>
          {isCopied ? (
            <SvgCopied
              style={{ borderRadius: '100%', overflow: 'hidden' }}
              fontSize={size}
              title="Link copied"
            />
          ) : (
            <SvgCopy
              style={{ borderRadius: '100%', overflow: 'hidden' }}
              fontSize={size}
              title="Copy link"
            />
          )}
        </button>
      )}
    </Stack>
  );
};
