import * as React from 'react';
import { Svg } from '../svg';

function SvgOrder({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M7.5 10.833a.833.833 0 100-1.667.833.833 0 000 1.667zM7.5 5a.833.833 0 100-1.666.833.833 0 000 1.667zM7.5 16.667A.833.833 0 107.5 15a.833.833 0 000 1.667zM12.5 10.833a.833.833 0 100-1.667.833.833 0 000 1.667zM12.5 5a.833.833 0 100-1.666.833.833 0 000 1.667zM12.5 16.667a.833.833 0 100-1.667.833.833 0 000 1.667z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgOrder);
export default ForwardRef;
