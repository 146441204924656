import * as React from 'react';
import { Svg } from '../svg';

function SvgArrowRightV2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M.295 1.41L4.875 6l-4.58 4.59L1.705 12l6-6-6-6-1.41 1.41z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowRightV2);
export default ForwardRef;
