import * as React from 'react';
import { Svg } from '../svg';

function SvgDots({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00008 3.00016C2.46032 3.00016 2.83341 2.62707 2.83341 2.16683C2.83341 1.70659 2.46032 1.3335 2.00008 1.3335C1.53984 1.3335 1.16675 1.70659 1.16675 2.16683C1.16675 2.62707 1.53984 3.00016 2.00008 3.00016ZM2.00008 8.8335C2.46032 8.8335 2.83341 8.4604 2.83341 8.00016C2.83341 7.53993 2.46032 7.16683 2.00008 7.16683C1.53984 7.16683 1.16675 7.53993 1.16675 8.00016C1.16675 8.4604 1.53984 8.8335 2.00008 8.8335ZM2.83341 13.8335C2.83341 14.2937 2.46032 14.6668 2.00008 14.6668C1.53984 14.6668 1.16675 14.2937 1.16675 13.8335C1.16675 13.3733 1.53984 13.0002 2.00008 13.0002C2.46032 13.0002 2.83341 13.3733 2.83341 13.8335Z"
        fill="#9B9FA4"
      />
      <path
        d="M2.08341 2.16683C2.08341 2.21285 2.0461 2.25016 2.00008 2.25016V3.75016C2.87453 3.75016 3.58341 3.04128 3.58341 2.16683H2.08341ZM2.00008 2.0835C2.0461 2.0835 2.08341 2.12081 2.08341 2.16683H3.58341C3.58341 1.29238 2.87453 0.583496 2.00008 0.583496V2.0835ZM1.91675 2.16683C1.91675 2.12081 1.95406 2.0835 2.00008 2.0835V0.583496C1.12563 0.583496 0.416748 1.29238 0.416748 2.16683H1.91675ZM2.00008 2.25016C1.95406 2.25016 1.91675 2.21285 1.91675 2.16683H0.416748C0.416748 3.04128 1.12563 3.75016 2.00008 3.75016V2.25016ZM2.08341 8.00016C2.08341 8.04619 2.0461 8.0835 2.00008 8.0835V9.5835C2.87453 9.5835 3.58341 8.87461 3.58341 8.00016H2.08341ZM2.00008 7.91683C2.0461 7.91683 2.08341 7.95414 2.08341 8.00016H3.58341C3.58341 7.12571 2.87453 6.41683 2.00008 6.41683V7.91683ZM1.91675 8.00016C1.91675 7.95414 1.95406 7.91683 2.00008 7.91683V6.41683C1.12563 6.41683 0.416748 7.12571 0.416748 8.00016H1.91675ZM2.00008 8.0835C1.95406 8.0835 1.91675 8.04619 1.91675 8.00016H0.416748C0.416748 8.87461 1.12563 9.5835 2.00008 9.5835V8.0835ZM2.00008 15.4168C2.87453 15.4168 3.58341 14.7079 3.58341 13.8335H2.08341C2.08341 13.8795 2.0461 13.9168 2.00008 13.9168V15.4168ZM0.416748 13.8335C0.416748 14.7079 1.12563 15.4168 2.00008 15.4168V13.9168C1.95406 13.9168 1.91675 13.8795 1.91675 13.8335H0.416748ZM2.00008 12.2502C1.12563 12.2502 0.416748 12.959 0.416748 13.8335H1.91675C1.91675 13.7875 1.95406 13.7502 2.00008 13.7502V12.2502ZM3.58341 13.8335C3.58341 12.959 2.87453 12.2502 2.00008 12.2502V13.7502C2.04611 13.7502 2.08341 13.7875 2.08341 13.8335H3.58341Z"
        fill="#9B9FA4"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgDots);
export default ForwardRef;
