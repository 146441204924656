import * as React from 'react';
import { Svg } from '../svg';

function SvgShareStudio({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        clipPath="url(#Share-studio_svg__clip0_5355_138420)"
        stroke="#0278E4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M18 8.53a3 3 0 100-6 3 3 0 000 6zM6 15.53a3 3 0 100-6 3 3 0 000 6zM18 22.53a3 3 0 100-6 3 3 0 000 6zM8.59 14.04l6.83 3.98M15.41 7.04l-6.82 3.98" />
      </g>
      <defs>
        <clipPath id="Share-studio_svg__clip0_5355_138420">
          <path fill="#fff" transform="translate(0 .53)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgShareStudio);
export default ForwardRef;
