import * as React from 'react';
import { Svg } from '../svg';

function SvgCc0({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#CC0_svg__clip0_7850_95707)">
        <path
          d="M20 3.334c9.2 0 16.667 7.467 16.667 16.667 0 9.2-7.467 16.666-16.667 16.666-9.2 0-16.666-7.466-16.666-16.666S10.8 3.334 20 3.334zm0 3.333c-7.358 0-13.333 5.975-13.333 13.334 0 7.358 5.975 13.333 13.333 13.333 7.358 0 13.334-5.975 13.334-13.333 0-7.359-5.976-13.334-13.334-13.334zm0 3.334c4.602 0 8.334 4.476 8.334 10 0 5.523-3.732 10-8.334 10-4.601 0-8.333-4.477-8.333-10 0-5.524 3.732-10 8.333-10zm3.875 5.786L17.94 26.076a3.96 3.96 0 002.061.591c2.762 0 5-2.983 5-6.666 0-1.599-.422-3.065-1.125-4.214zM20 13.334c-2.762 0-5 2.983-5 6.667 0 1.598.422 3.065 1.125 4.213l5.937-10.288A3.961 3.961 0 0020 13.334z"
          fill="#4F5356"
        />
      </g>
      <defs>
        <clipPath id="CC0_svg__clip0_7850_95707">
          <path fill="#fff" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCc0);
export default ForwardRef;
