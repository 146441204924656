import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import trans_en from '../public/locales/en/translation.json';
import trans_zh from '../public/locales/zh-tw/translation.json';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: trans_en,
  },
  zh_tw: {
    translation: trans_zh,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
