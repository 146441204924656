import SvgCcBy from 'src/components/icons/cc-by';
import SvgCcCc from 'src/components/icons/cc-cc';
import SvgCcNc from 'src/components/icons/cc-nc';
import SvgCcNd from 'src/components/icons/cc-nd';
import SvgCcSa from 'src/components/icons/cc-sa';

import SvgNcEx from 'src/components/icons/nc-ex';
import SvgNcFc from 'src/components/icons/nc-fc';
import SvgNcNe from 'src/components/icons/nc-ne';
import SvgNcNs from 'src/components/icons/nc-ns';
import SvgNcNt from 'src/components/icons/nc-nt';
import SvgNcNc from 'src/components/icons/nc-nc';

import SvgCC0 from 'src/components/icons/cc0';
import SvgARR from 'src/components/icons/arr';

export const LICENSE_FEATURES = {
  'CC0 1.0': {
    icons: [SvgCcCc, SvgCC0],
    license: 'CC0 1.0 Universal',
    url: 'https://creativecommons.org/publicdomain/zero/1.0/',
    adaptations: 'others',
    commercial: 'others',
    name: 'CC0 1.0',
  },
  'All Rights Reserved': {
    icons: [SvgARR],
    license: 'All Rights Reserved',
    url: '',
    adaptations: 'others',
    commercial: 'others',
    name: 'All Rights Reserved',
  },
  'CC BY 4.0': {
    icons: [SvgCcCc, SvgCcBy],
    license: 'Attribution 4.0 International',
    url: 'http://creativecommons.org/licenses/by/4.0/',
    adaptations: 'yes',
    commercial: 'yes',
    name: 'CC BY 4.0',
  },
  'CC BY-NC 4.0': {
    icons: [SvgCcCc, SvgCcBy, SvgCcNc],
    license: 'Attribution-NonCommercial 4.0 International',
    url: 'http://creativecommons.org/licenses/by-nc/4.0/',
    adaptations: 'yes',
    commercial: 'no',
    name: 'CC BY-NC 4.0',
  },
  'CC BY-ND 4.0': {
    icons: [SvgCcCc, SvgCcBy, SvgCcNd],
    license: 'Attribution-NoDerivatives 4.0 International',
    url: 'http://creativecommons.org/licenses/by-nd/4.0/',
    adaptations: 'no',
    commercial: 'yes',
    name: 'CC BY-ND 4.0',
  },
  'CC BY-NC-ND 4.0': {
    icons: [SvgCcCc, SvgCcBy, SvgCcNc, SvgCcNd],
    license: 'Attribution-NonCommercial-NoDerivatives 4.0 International',
    url: 'http://creativecommons.org/licenses/by-nc-nd/4.0/',
    adaptations: 'no',
    commercial: 'no',
    name: 'CC BY-NC-ND 4.0',
  },
  'CC BY-SA 4.0': {
    icons: [SvgCcCc, SvgCcBy, SvgCcSa],
    license: 'Attribution-ShareAlike 4.0 International',
    url: 'http://creativecommons.org/licenses/by-sa/4.0/',
    adaptations: 'others',
    commercial: 'yes',
    name: 'CC BY-SA 4.0',
  },
  'CC BY-NC-SA 4.0': {
    icons: [SvgCcCc, SvgCcBy, SvgCcNc, SvgCcSa],
    license: 'Attribution-NonCommercial-ShareAlike 4.0 International',
    url: 'http://creativecommons.org/licenses/by-nc-sa/4.0/',
    adaptations: 'others',
    commercial: 'no',
    name: 'CC BY-NC-SA 4.0',
  },
  'NC FC-EX 1.0': {
    icons: [SvgNcNc, SvgNcFc, SvgNcEx],
    license: 'NFT Commons Exclusive Full Copyright License for NFT',
    url: '',
    adaptations: 'n/a',
    commercial: 'n/a',
    name: 'NC FC-EX 1.0',
  },
  'NC FC-EX-NS 1.0': {
    icons: [SvgNcNc, SvgNcFc, SvgNcEx, SvgNcNs],
    license:
      'NFT Commons Exclusive but Non-Sublicensable Full Copyright License for NFT',
    url: '',
    adaptations: 'n/a',
    commercial: 'n/a',
    name: 'NC FC-EX-NS 1.0',
  },
  'NC FC-NE 1.0': {
    icons: [SvgNcNc, SvgNcFc, SvgNcNe],
    license: 'NFT Commons Non-Exclusive Copyright License for NFT',
    url: '',
    adaptations: 'n/a',
    commercial: 'n/a',
    name: 'NC FC-NE 1.0',
  },
  'NC FC-NE-NT 1.0': {
    icons: [SvgNcNc, SvgNcFc, SvgNcNe, SvgNcNt],
    license:
      'NFT Commons Non-Exclusive Non-Transferrable Copyright License for NFT',
    url: '',
    adaptations: 'n/a',
    commercial: 'n/a',
    name: 'NC FC-NE-NT 1.0',
  },
  'NC BY 1.0': {
    icons: [SvgNcNc, SvgCcBy],
    license: 'NFT Commons BY license',
    url: '',
    adaptations: 'n/a',
    commercial: 'n/a',
    name: 'NC BY 1.0',
  },
  'NC BY-SA 1.0': {
    icons: [SvgNcNc, SvgCcBy, SvgCcSa],
    license: 'NFT Commons BY-SA license',
    url: '',
    adaptations: 'n/a',
    commercial: 'n/a',
    name: 'NC BY-SA 1.0',
  },
  'NC BY-ND 1.0': {
    icons: [SvgNcNc, SvgCcBy, SvgCcNd],
    license: 'NFT Commons BY-ND license',
    url: '',
    adaptations: 'n/a',
    commercial: 'n/a',
    name: 'NC BY-ND 1.0',
  },
  'NC BY-NC 1.0': {
    icons: [SvgNcNc, SvgCcBy, SvgCcNc],
    license: 'NFT Commons BY-NC license',
    url: '',
    adaptations: 'n/a',
    commercial: 'n/a',
    name: 'NC BY-NC 1.0',
  },
  'NC BY-NC-SA 1.0': {
    icons: [SvgNcNc, SvgCcBy, SvgCcNc, SvgCcSa],
    license: 'NFT Commons BY-NC-SA license',
    url: '',
    adaptations: 'n/a',
    commercial: 'n/a',
    name: 'NC BY-NC-SA 1.0',
  },
  'NC BY-NC-ND 1.0': {
    icons: [SvgNcNc, SvgCcBy, SvgCcNc, SvgCcNd],
    license: 'NFT Commons BY-NC-ND license',
    url: '',
    adaptations: 'n/a',
    commercial: 'n/a',
    name: 'NC BY-NC-ND 1.0',
  },
};
