import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { ElementRef, useRef, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayHepler from 'src/heplers/day';
import { imageOptimize } from 'src/service/image';
import { Avatar } from 'src/components/avatar';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { ArticleDetailModal } from 'src/app/article/detail-modal';
import WarningModal from 'src/app/article/warning-modal';
import SvgArrowLeft from 'src/components/icons/arrow-left-v2-2x';
import SvgArrowRight from 'src/components/icons/arrow-right-v2';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  cancelSubmission,
  postSubmissionHistory,
  repplSubmissionHistory,
} from './hooks';

export function SubmissionHistoryModal({
  article,
  modal,
  isReppl = false,
  id,
}: {
  modal: UseDisclosureReturn;
  article: any;
  isReppl?: boolean;
  id?: string;
}) {
  const router = useRouter();
  const { user } = useContext(UserContext) as UserContextType;
  const modalRef = useRef<ElementRef<typeof ModalContent>>();
  const articleModal = useDisclosure();
  const warningModal = useDisclosure();
  const [selectedProject, setSelectedProject] = useState('All');
  const [currProj, setCurrProj] = useState<any>();
  const [repplListOver, setRepplListOver] = useState(false);

  const [translateX, setTranslateX] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rightLock, setRightLock] = useState(true);
  const [leftLock, setLeftLock] = useState(false);
  const [repplSubmission, setRepplSubmission] = useState<any>([]);

  let filteredSub;
  let repplList = [];

  const benchmark = 320;

  const offset = (direction) => {
    const repplListWidth = document.getElementById('repplList').offsetWidth;
    if (repplListWidth <= benchmark) return;
    if (direction === 'right' && rightLock) return;
    if (direction === 'left' && leftLock) return;

    const listWidth = repplList.length + 1;
    const perList = repplListWidth / listWidth;
    const offect = direction === 'left' ? -1 : 1;
    const x = perList * offect + translateX;
    setTranslateX(x);
    const nextIndex = currentIndex - offect;
    let right = false;
    let left = false;
    if (nextIndex === 0) {
      right = true;
    } else if (nextIndex * perList + benchmark >= repplListWidth) {
      left = true;
    }
    setRightLock(right);
    setLeftLock(left);
    setCurrentIndex(nextIndex);
  };

  useEffect(() => {
    async function getSub() {
      let history;
      if (isReppl) {
        history = await repplSubmissionHistory({
          eid: article?.episodeID,
          rid: article.repplID,
        });
      } else {
        history = await postSubmissionHistory(article?.episodeID);
      }
      history?.sort((a, b) => b.createdAt?.localeCompare(a.createdAt));
      setRepplSubmission(history);
      setCurrProj(history);
    }
    getSub();
  }, [article]);

  useEffect(() => {
    if (selectedProject === 'All') {
      if (repplSubmission.length > 0) {
        setCurrProj(repplSubmission);
      }
    } else {
      const filteredData = repplSubmission.filter(
        (subs) => subs.repplName === selectedProject
      );
      filteredData?.sort((a, b) => b.createdAt?.localeCompare(a.createdAt));
      setCurrProj(filteredData);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (document.getElementById('repplList')) {
      const repplListWidth = document.getElementById('repplList').offsetWidth;
      setRepplListOver(repplListWidth > 320);
    }
  });

  if (repplSubmission.length > 0) {
    filteredSub = repplSubmission.filter((submission, subIndex) => {
      return (
        subIndex ===
        repplSubmission.findIndex((subObj) => {
          return subObj.repplName === submission.repplName;
        })
      );
    });

    repplList = filteredSub.map((sub) => sub.repplName);
  }

  const getRepplName = (data) => {
    if (article?.status === 'APPROVED') {
      if (data === 'text') {
        return article?.repplName;
      }
      return `/space/${article.repplID}`;
    }
    if (article?.status === 'REMOVED' || article?.status === 'UNPUBLISHED') {
      if (data === 'text') {
        return 'drafts';
      }
      return `/${user.username}/drafts-submissions`;
    }
    if (data === 'text') {
      return 'Your Studio';
    }
    return `/${user.username}`;
  };

  const isOwner = (data) => {
    if (data === user.username) {
      return true;
    }
    return false;
  };

  const isAdmin = (data) => {
    if (data === 'Administrator') {
      return true;
    }
    return false;
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getStatusColor = (data, status) => {
    let classStatus = '';
    if (status === 'APPROVED') {
      if (data === 'text') {
        classStatus = 'seagreen-500';
      } else {
        classStatus = 'seagreen-100';
      }
    }
    if (status === 'SUBMITTED' || status === 'CANCELED') {
      if (data === 'text') {
        classStatus = 'dark-500';
      } else {
        classStatus = 'gray-100';
      }
    }
    if (
      status === 'UNPUBLISHED' ||
      status === 'UNLISTED' ||
      status === 'REMOVED' ||
      status === 'DECLINED'
    ) {
      if (data === 'text') {
        classStatus = 'outrageousOrange-500';
      } else {
        classStatus = 'outrageousOrange-100';
      }
    }

    if (data === 'text') {
      return `text-${classStatus}`;
    }
    return `bg-${classStatus}`;
  };

  const getStatusText = (data) => {
    if (data.status === 'SUBMITTED') {
      return `Submitted to ${data.repplName}`;
    }
    if (data.status === 'REMOVED') {
      return 'Removed the folio';
    }
    if (isOwner(data)) {
      return `has ${data.status.toLowerCase()} your folio`;
    }
    return `${getCorrectStatusName(data.status)} your folio`;
  };

  const statusData = {
    APPROVED: {
      hasText: false,
      statusText: 'Published',
      subText: 'Published in',
    },
    SUBMITTED: {
      hasText: true,
      desc:
        'This folio is pending review and only visible in your studio. Would you cancel submission so you can submit it elsewhere?',
      acceptBtn: 'Cancel submission',
      cancelBtn: 'Keep in pending',
      statusText: 'Pending review',
      subText: 'Published in',
      accept: () => {
        warningModal.onOpen();
      },
    },
    CANCELED: {
      hasText: false,
    },
    UNPUBLISHED: {
      hasText: false,
      statusText: 'Unpublished',
      subText: 'in',
    },
    UNLISTED: {
      hasText: true,
      desc:
        'This folio has been unlisted and only visible in your Studio. Would you edit and resubmit?',
      acceptBtn: 'Edit and Resubmit',
      cancelBtn: 'Keep it in my Studio',
      statusText: 'Unlisted',
      accept: () => {
        router.push(
          `/space/${article.repplID}/episode/${article.episodeID}/edit`
        );
      },
    },
    REMOVED: {
      hasText: false,
      statusText: 'Removed',
      subText: 'Published in',
    },
    DECLINED: {
      hasText: true,
      desc:
        'This folio has been declined by the reviewer. Would you edit and resubmit now?',
      acceptBtn: 'Edit and Resubmit',
      cancelBtn: 'Keep it in my Studio',
      statusText: 'Declined',
      subText: 'in',
      accept: () => {
        router.push(
          `/space/${article.repplID}/episode/${article.episodeID}/edit`
        );
      },
    },
  };

  const haveMore = (submission) => {
    const currSubmissions = repplSubmission?.sort((a, b) =>
      b.createdAt?.localeCompare(a.createdAt)
    );
    if (
      submission.status !== currSubmissions[0].status &&
      submission.id !== currSubmissions[0].id
    ) {
      return true;
    }
    return false;
  };

  function getCorrectStatusName(status) {
    if (status === 'CANCELED') {
      return 'Cancelled';
    }
    if (status === 'APPROVED') {
      return 'Published';
    }
    return capitalizeFirstLetter(status.toLowerCase());
  }

  const { t } = useTranslation();
  return (
    <>
      <Modal
        isCentered
        autoFocus={false}
        {...modal}
        blockScrollOnMount
        motionPreset="none"
      >
        <ModalOverlay>
          <ModalContent
            ref={modalRef}
            borderRadius="10px"
            maxWidth="726px"
            h="auto"
            minHeight="613px"
            position="relative"
          >
            <ModalCloseButton color="$mode.500" />
            <div className="p-8 py-10 bg-gray-100 shadow-md">
              <div className="border shadow-sm rounded-xl overflow-hidden bg-white">
                <div className="flex p-5 relative border-b">
                  <div
                    style={{ width: '120px', height: '80px' }}
                    className="cover-image-box rounded"
                  />
                  <div className="pl-5 py-1 relative">
                    {!isReppl && (
                      <div className="text-xs font-medium text-gray-900">
                        {article?.status
                          ? statusData[article?.status]?.statusText
                          : ''}
                      </div>
                    )}
                    <div
                      style={{ height: `${isReppl ? 'fit-content' : 'auto'}` }}
                      className={`w-max font-medium text-gray-900 ${
                        isReppl
                          ? 'absolute top-0 bottom-0 my-auto'
                          : 'pt-1 pb-2'
                      }`}
                    >
                      {article?.episodeTitle}
                    </div>
                    {!isReppl && (
                      <div>
                        <span className="text-xs text-gray-500 font-medium">
                          {article?.status
                            ? statusData[article?.status]?.subText
                            : ''}{' '}
                        </span>
                        <Link href={getRepplName('link')}>
                          <span className="text-xs text-gray-700 font-medium">
                            {getRepplName('text')}
                          </span>
                        </Link>
                      </div>
                    )}
                  </div>
                  <div
                    role="button"
                    tabIndex={0}
                    className="absolute top-5 right-6 text-blue-500 text-sm font-semibold"
                    onClick={() => articleModal.onOpen()}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        articleModal.onOpen();
                      }
                    }}
                  >
                    View
                  </div>
                </div>
                {!isReppl && (
                  <>
                    <div
                      className={`${
                        article?.status && !statusData[article?.status]?.hasText
                          ? 'hidden'
                          : ''
                      } p-5 text-sm text-gray-500 bg-gray-50`}
                    >
                      {article?.status ? statusData[article?.status]?.desc : ''}
                    </div>
                    <div
                      className={`${
                        article?.status && !statusData[article?.status]?.hasText
                          ? 'hidden'
                          : ''
                      } w-full border-t flex text-blue-500 bg-gray-50 text-center font-semibold text-sm`}
                    >
                      <div
                        role="button"
                        tabIndex={0}
                        className="w-full py-3 border-r border-gray-100"
                        onClick={modal.onClose}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            modal.onClose();
                          }
                        }}
                      >
                        {article?.status
                          ? statusData[article?.status]?.cancelBtn
                          : ''}
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        className="w-full py-3"
                        onClick={() => {
                          if (statusData[article?.status]?.accept) {
                            statusData[article?.status]?.accept();
                          }
                        }}
                        onKeyDown={(e) => {
                          if (
                            e.code === 'Enter' &&
                            statusData[article?.status]?.accept
                          ) {
                            statusData[article?.status]?.accept();
                          }
                        }}
                      >
                        {article?.status
                          ? statusData[article?.status]?.acceptBtn
                          : ''}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="p-8 pb-0 bg-gray-50">
              <div className="w-full flex text-lg font-semibold pb-2 relative">
                <div className="w-full">Submission History</div>
                {!isReppl && (
                  <div className="absolute right-6 flex">
                    {repplListOver && (
                      <div
                        aria-hidden="true"
                        onClick={() => {
                          offset('right');
                        }}
                        style={{ fontSize: '8px' }}
                        className={`${
                          rightLock ? 'text-gray-300' : 'text-gray-900'
                        } hidden bg-white top-20 border border-gray-300 rounded-full p-2 cursor-pointer md:block`}
                      >
                        <SvgArrowLeft />
                      </div>
                    )}
                    <div
                      style={{ maxWidth: '320px' }}
                      className="flex text-xs font-bold text-gray-700 overflow-hidden"
                    >
                      {repplList.length > 1 && (
                        <div
                          id="repplList"
                          className="flex"
                          style={{ transform: `translateX(${translateX}px)` }}
                        >
                          <div
                            role="button"
                            tabIndex={0}
                            className={`${
                              selectedProject === 'All' && 'border'
                            } rounded-full px-3 py-1 mx-1 border-gray-900 whitespace-nowrap`}
                            onClick={() => setSelectedProject('All')}
                            onKeyDown={(e) => {
                              if (e.code === 'Enter') {
                                setSelectedProject('All');
                              }
                            }}
                          >
                            All
                          </div>
                          {repplList.map((list) => (
                            <div
                              role="button"
                              tabIndex={0}
                              className={`${list === '' && 'hidden'} ${
                                list === selectedProject && 'border'
                              } rounded-full px-3 py-1 mx-1 border-gray-900 whitespace-nowrap`}
                              onClick={() => setSelectedProject(list)}
                              onKeyDown={(e) => {
                                if (e.code === 'Enter') {
                                  setSelectedProject(list);
                                }
                              }}
                            >
                              {list}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {repplListOver && (
                      <div
                        aria-hidden="true"
                        onClick={() => {
                          offset('left');
                        }}
                        style={{ fontSize: '8px' }}
                        className={`${
                          leftLock ? 'text-gray-300' : 'text-gray-900'
                        } hidden bg-white top-20 border border-gray-300 ml-1 rounded-full p-2 cursor-pointer md:block`}
                      >
                        <SvgArrowRight />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div
                style={{ height: '26rem' }}
                className="overflow-auto max-h-96"
              >
                {currProj?.map((submission, submissionIndex) => (
                  <div className="flex mx-5 mt-4 mb-10">
                    <div
                      style={{ width: '27%' }}
                      className="pt-6 text-xs text-gray-500 text-center"
                    >
                      <div
                        className={`w-full rounded-full shadow font-bold bg-white px-3 py-2 border ${getStatusColor(
                          'text',
                          submission.status
                        )}`}
                      >
                        {getCorrectStatusName(submission.status)}
                      </div>
                      <div
                        className={`w-full font-medium py-2 ${getStatusColor(
                          'text',
                          submission.status
                        )}`}
                      >
                        {dayHepler.localizedFormat(
                          submission?.createdAt,
                          'MMM DD'
                        )}
                      </div>
                    </div>
                    <div className="px-5 text-gray-400">
                      {haveMore(submission) ? (
                        <div
                          style={{ height: '25px' }}
                          className={`${
                            submissionIndex === 0
                              ? 'border-dashed'
                              : 'border-solid'
                          } border-l ml-1.5`}
                        />
                      ) : (
                        <div style={{ height: '25px' }} />
                      )}
                      <div
                        className={`text-5xl leading-7 ${getStatusColor(
                          'text',
                          submission.status
                        )}`}
                      >
                        •
                      </div>
                      {submissionIndex < currProj.length - 1 && (
                        <div className="h-full border-l ml-1.5" />
                      )}
                    </div>
                    <div className="w-full rounded-lg border overflow-hidden">
                      <div className="bg-white flex relative px-5 py-7">
                        <Avatar
                          src={imageOptimize({
                            url: submission.Sender.avatarUrl,
                            imgContainer: 'avatar',
                          })}
                          name={submission.Sender.username}
                          width={30}
                          height={30}
                        />
                        <div className="pl-4 text-sm font-medium">
                          <div className="py-1">
                            {submission.Sender.username}
                          </div>
                          <div className="py-1 text-gray-500">
                            {getStatusText(submission)}
                          </div>
                        </div>
                        {isOwner(submission.Sender.username) && (
                          <div className="absolute top-8 right-5 text-xs text-gray-500 font-medium">
                            {t('You')}
                          </div>
                        )}
                        {isAdmin(submission.Sender.username) && (
                          <div className="absolute top-8 right-5 text-xs text-gray-500 font-medium">
                            {t('Administrator')}
                          </div>
                        )}
                      </div>
                      {submission.notes && (
                        <div
                          className={`p-5 ${getStatusColor(
                            'bg',
                            submission.status
                          )}`}
                        >
                          <div
                            style={{ width: 'fit-content' }}
                            className="flex mx-auto font-medium text-sm text-gray-900"
                          >
                            <div
                              className={`text-3xl relative leading-6 text-gray-400 ${getStatusColor(
                                'text',
                                submission.status
                              )}`}
                            >
                              &quot;
                            </div>
                            <div
                              className={`flex-1 px-2 font-medium whitespace-pre-wrap text-sm ${getStatusColor(
                                'text',
                                submission.status
                              )}`}
                            >
                              {submission.notes}
                            </div>
                            <div
                              className={`text-3xl relative leading-6 text-gray-400 ${getStatusColor(
                                'text',
                                submission.status
                              )}`}
                            >
                              &quot;
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <style jsx global>
              {`
                .cover-image-box {
                  background-image: url('${imageOptimize({
                    url: article?.episodeCoverImgUrl,
                    imgContainer: 'avatar',
                  })}');
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center center;
                }
              `}
            </style>
          </ModalContent>
        </ModalOverlay>
      </Modal>
      <WarningModal
        {...warningModal}
        isFooterCaption
        title={t('Cancel submission')}
        text={t('Are you sure you want to cancel this submission?')}
        onOk={() => {
          cancelSubmission(article?.episodeID);
          modal.onClose();
        }}
        okBtnText={t('Cancel Submission')}
        cancelBtnText={t('Wait for review')}
        cancelBtnColor="#9B9FA4"
        cancelBtnScheme="#9B9FA4"
      />
      <ArticleDetailModal
        submissionStatus={isReppl ? article?.status : ''}
        modal={articleModal}
        publish
        id={article?.episodeID}
        rid={id}
      />
    </>
  );
}
