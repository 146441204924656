import {
  BoxProps,
  Flex,
  FlexProps,
  Heading,
  Stack,
  InputGroupProps,
} from '@chakra-ui/react';
import { ReactNode, useRef } from 'react';
import { useBreakpointValue } from '@chakra-ui/media-query';

import { useInfiniteScroller } from 'src/hooks';
import { useIsTablet } from 'src/hooks/use-is-mobile';

import { SearchInput } from '../search-input/search-input';

interface UseSelectListProps {
  fetchNextPage?: () => void;
  enabled?: boolean;
  setKeyword: (keyword: string) => void;
}

interface SelectListProps extends ReturnType<typeof useSelectList> {
  children?: ReactNode;
  listHeader?: ReactNode;
  footer?: ReactNode;
  title?: string;
  keyword?: string;
  subTitle?: ReactNode;
  emptySection?: ReactNode;
  input?: ReactNode;
}

export function useSelectList(props: UseSelectListProps) {
  const isTablet = useIsTablet();
  const listRef = useRef<HTMLDivElement>();
  const loadMoreRef = useInfiniteScroller({
    root: listRef,
    rootMargin: '50px 0px',
    onIntersect: props.fetchNextPage,
    enabled: props.enabled,
  });
  const headingStyle = useBreakpointValue({ base: 'h4', md: 'h3' });

  return {
    isMobile: isTablet,
    listRef,
    loadMoreRef,
    headingStyle,
    inputOnChange: props.setKeyword,
  };
}

export function SelectList(
  props: SelectListProps & { innerWrapperProps?: BoxProps } & {
    wrapperProps?: FlexProps;
  } & { inputWrapperProps?: InputGroupProps }
) {
  return (
    <Flex
      direction="column"
      layerStyle="layer3"
      p={{ base: 4, md: 6 }}
      h="100%"
      {...props.wrapperProps}
    >
      <Heading textStyle={props.headingStyle}>{props.title}</Heading>
      {props.subTitle}
      <Flex
        direction="column"
        {...props.innerWrapperProps}
        h="100%"
        overflow="hidden"
      >
        {props.emptySection || (
          <>
            {props.input || (
              <SearchInput
                wrapperProps={{
                  mt: 5,
                  mb: '24px',
                  ...props.inputWrapperProps,
                }}
                value={props.keyword}
                onChange={(e) => props.inputOnChange(e.target.value)}
                clear={() => props.inputOnChange('')}
              />
            )}
            {props.listHeader}

            <Stack
              display="block"
              flex={1}
              ref={props.listRef}
              direction="column"
              spacing={3}
              overflowY="scroll"
            >
              {props.children}
              <div ref={props.loadMoreRef} />
            </Stack>
          </>
        )}
      </Flex>
      {props.footer}
    </Flex>
  );
}

export function SelectListV2(
  props: SelectListProps & { innerWrapperProps?: BoxProps } & {
    wrapperProps?: FlexProps;
  } & { inputWrapperProps?: InputGroupProps }
) {
  return (
    <Flex
      direction="column"
      layerStyle="layer3"
      h="100%"
      {...props.wrapperProps}
    >
      <Heading textStyle={props.headingStyle}>{props.title}</Heading>
      {props.subTitle}
      <Flex
        direction="column"
        {...props.innerWrapperProps}
        h="100%"
        overflow="hidden"
      >
        {props.emptySection || (
          <>
            {props.input || (
              <SearchInput
                wrapperProps={{
                  mb: '24px',
                  ...props.inputWrapperProps,
                }}
                border="1px solid #E6E8E9"
                boxSizing="border-box"
                borderRadius="20px"
                placeholder="Search"
                value={props.keyword}
                onChange={(e) => props.inputOnChange(e.target.value)}
                clear={() => props.inputOnChange('')}
              />
            )}
            {props.listHeader}

            <Stack
              display="block"
              flex={1}
              ref={props.listRef}
              direction="column"
              spacing={3}
            >
              {props.children}
              <div ref={props.loadMoreRef} />
            </Stack>
          </>
        )}
      </Flex>
      {props.footer}
    </Flex>
  );
}
