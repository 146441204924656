/**
 * External dependencies
 */
import Router from 'next/router';
import { useState, useCallback, useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';

/**
 * Internal dependencies
 */
import { logger } from 'src/lib/logger';

import { ArticleDetailModal } from 'src/app/article/detail-modal';
import { fetchFullFolio } from 'src/service/post';

import Context from './context';
import { useFullFolioQuery } from './api';

function FolioProvider({ children }: { children: React.ReactNode }) {
  const [folioId, setFolioId] = useState('');
  const { data: folio, isLoading, isError, isSuccess } = useFullFolioQuery(
    folioId
  );

  const changeFolioId = useCallback((id) => {
    setFolioId(id);
  }, []);

  /** Modal State */
  const folioModal = useDisclosure();
  const { isOpen, onOpen, onClose } = folioModal;

  const handleOpenFolio = useCallback(({ username, id }) => {
    const historyState = window.history.state || {};
    window.history.pushState(historyState, '', `../${username}/${id}`);
  }, []);

  const onOpenFolioModal = useCallback(
    async ({ id }: { id: string }) => {
      changeFolioId(id);
      try {
        const newFolio = await fetchFullFolio(id);
        if (newFolio) {
          handleOpenFolio({ username: newFolio.author.username, id });
        }
        onOpen();
      } catch (error) {
        logger.error(error);
      }
    },
    [changeFolioId, handleOpenFolio, onOpen]
  );
  const onCloseFolioModal = useCallback(() => {
    changeFolioId('');
    onClose();
  }, [changeFolioId, onClose]);

  /** Handle Modal Open */
  const [currentLocation, setCurrentLocation] = useState('');
  useEffect(() => {
    setCurrentLocation(window.location.href);
    // change currentLocation on page change
    const handleRouteChange = (url) => {
      setCurrentLocation(url);
    };
    Router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  const state = {
    folioModal: {
      ...folioModal,
      isOpen: isOpen && !isLoading,
      onOpen: onOpenFolioModal,
      onClose: onCloseFolioModal,
    },

    folioId,
    folio,

    currentLocation,

    // fetch folio state
    isLoading,
    isError,
    isSuccess,

    changeFolioId,
  };

  return (
    <Context.Provider value={state}>
      {children}
      <ArticleDetailModal
        modal={folioModal}
        id={folioId}
        publish
        currentLocation={currentLocation}
        defaultArticle={folio}
      />
    </Context.Provider>
  );
}

export default FolioProvider;
