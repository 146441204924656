import * as React from 'react';
import { Svg } from '../svg';

function SvgCoin({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8.75 15a7 7 0 100-14 7 7 0 000 14zM20.25 9.94a7 7 0 11-9.56 9.56"
        stroke="#0278E4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.75 6h1v4M18.05 14.3l.7.7-2.8 2.8"
        stroke="#0278E4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCoin);
export default ForwardRef;
