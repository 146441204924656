import * as React from 'react';
import { Svg } from '../svg';

function SvgMediaPost2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M.167 1.327A.833.833 0 01.993.5h15.014c.456 0 .826.37.826.827v13.345a.833.833 0 01-.826.828H.993a.827.827 0 01-.826-.828V1.327zm1.666.84v11.666h13.334V2.167H1.833zM8.5 10.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0 1.667a4.167 4.167 0 110-8.334 4.167 4.167 0 010 8.334zM12.667 3h1.666v1.667h-1.666V3z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgMediaPost2);
export default ForwardRef;
