import Axios from 'axios';
import { COOKIES_AUTH_TOKEN } from 'src/constants/cookies';
import { destroyCookie, getCookie } from 'src/lib/cookies';
import { API_V2 } from 'src/constants/env';

export const { CancelToken } = Axios;

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const isTokenExpired = (token) => {
  if (token) {
    const jwt = parseJwt(token);
    if (Date.now() >= jwt.exp * 1000) {
      removeAxiosToken();
      destroyCookie(token);
      return '';
    }
    return token;
  }
};

export const axios = Axios.create({
  baseURL: API_V2,
  headers: {
    post: {
      'Content-Type': 'application/json',
    },
  },
});

axios.interceptors.request.use(
  (config) => {
    const token = isTokenExpired(getCookie(COOKIES_AUTH_TOKEN));
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export function setAxiosToken(t?: string) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${t}`;
}

export function getAxiosToken() {
  return axios.defaults.headers.common['Authorization'];
}

export function removeAxiosToken() {
  axios.defaults.headers.common['Authorization'] = null;
}
