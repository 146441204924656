import { AspectRatio } from '@chakra-ui/react';
import { css } from '@hexx/theme';
import { createElement } from 'react';
import { SERVICES } from './embed-service';

const iframeCss = css({
  margin: 'auto',
});

const styles = {
  wrapper: css({
    display: 'flex',
    background: '#fff',
    border: '1px solid rgba(201, 201, 204, 0.48)',
    boxShadow: '0 1px 3px rgba(0,0,0, .1)',
    borderRadius: 6,
    textDecoration: 'none',
    height: 135,
    overflow: 'hidden',
  }),
  left: css({
    padding: 24,
    flex: 1,
  }),
  image: css({
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: 240,
    height: 135,
    borderLeftTopRadius: 3,
    borderLeftBottomRadius: 3,
    flex: 1,
    flexShrink: 0,
  }),
  title: css({
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
    color: 'initial',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  }),
  desc: css({
    margin: '6px 0 6px 0',
    fontSize: '14px',
    lineHeight: '21px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    color: 'initial',
  }),
  anchor: css({
    display: 'block',
    fontSize: 12,
    lineHeight: '1em',
    color: '#9B9FA4',
    border: '0 !important',
    padding: '0 !important',
  }),
};

export type EmbedLink = {
  type: 'embedLink';
  data: {
    stretched?: boolean;
    link: string;
    caption: string;
    meta?: {
      image?: {
        url: string;
      };
      title?: string;
      description?: string;
    };
    embed?: {
      service: keyof typeof SERVICES;
      embed: string;
      width: number;
      height: number;
    };
  };
};

function isNonEmptyObject<T extends object>(obj?: T): obj is Required<T> {
  if (!obj) {
    return false;
  }
  return !(Object.keys(obj).length === 0 && obj.constructor === Object);
}

export const EmbedLinkRenderer = ({ data }: { data: EmbedLink['data'] }) => {
  if (isNonEmptyObject(data.embed)) {
    const isRender = 'render' in SERVICES[data.embed.service];
    if (isRender) {
      // @ts-ignore
      return createElement(SERVICES[data.embed.service].render, {
        url: data.embed.service === 'twitter' ? data.link : data.embed.embed,
      });
    }
    return (
      <AspectRatio
        ratio={data.embed.width / data.embed.height}
        maxW={data.stretched ? '100%' : data.embed.width}
        m="auto"
      >
        <figure>
          <iframe
            title="iframe"
            className={iframeCss}
            width="100%"
            height="100%"
            src={data.embed.embed}
          />
          {data.caption && <figcaption>{data.caption}</figcaption>}
        </figure>
      </AspectRatio>
    );
  }

  if (isNonEmptyObject(data.meta)) {
    let url = data.link;
    try {
      url = new URL(data.link).hostname;
    } catch (error) {
      url = data.link;
    }
    return (
      <a
        className={styles.wrapper}
        target="_blank"
        rel="noreferrer"
        href={data.link}
      >
        <div className={styles.left}>
          <div className={styles.title}>{data.meta.title}</div>
          <span className={styles.desc}>{data.meta.description}</span>
          <span className={styles.anchor}>{url}</span>
        </div>
        {data.meta?.image?.url && (
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${data.meta.image.url})`,
            }}
          />
        )}
      </a>
    );
  }

  return null;
};
