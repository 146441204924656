import * as React from 'react';
import { Svg } from '../svg';

function SvgNft({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M38 27.602V12.398c0-.666-.18-1.32-.522-1.898a3.846 3.846 0 00-1.422-1.39l-13.612-7.6a3.958 3.958 0 00-3.888 0L4.944 9.11a3.846 3.846 0 00-1.422 1.39A3.734 3.734 0 003 12.398v15.204c0 .666.18 1.32.522 1.898a3.845 3.845 0 001.422 1.39l13.612 7.6a3.958 3.958 0 003.888 0l13.612-7.6a3.845 3.845 0 001.422-1.39A3.734 3.734 0 0038 27.602z"
        fill="#98CCFD"
        stroke="#4788C7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 11l8.25 5 8.25 5 8.25-5L37 11M20.625 39V20"
        stroke="#4788C7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20.625 29v-5l-5.5-3.5 5.5 8.5z" fill="#DFF0FE" />
      <path d="M20.625 29v-5l5.5-3.5-5.5 8.5z" fill="#98CCFD" />
      <path
        d="M13.625 19.06l7-11.06 7 11.06-7 4.44-7-4.44z"
        fill="#DFF0FE"
        stroke="#4788C7"
        strokeLinejoin="round"
      />
      <path
        d="M13.625 19l6.998 11 7.002-11"
        stroke="#4788C7"
        strokeLinejoin="round"
      />
      <path d="M20.625 23l6.5-3.957-6.5-3.043v7z" fill="#98CCFD" />
      <path d="M20.625 23l-6.5-3.957 6.5-3.043v7z" fill="#DFF0FE" />
      <path d="M14.125 19l6.5-10v7l-6.5 3z" fill="#fff" />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNft);
export default ForwardRef;
