import { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsTablet } from 'src/hooks/use-is-mobile';
import cn from 'classnames';
import ModalSignInForm from 'src/app/auth/sign-in/modal-signin-form';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { useModal } from 'src/hooks/use-modal';
import SignUpForm from 'src/app/code/SignUpForm';
import SignInWithWallet from './sign-in-with-wallet';
import styles from './Sign_in_email.module.scss';
import SignInUpTabs from './SignInUpTabs';

export const SignInSection = ({ toggleForm }: { toggleForm: () => void }) => {
  const [activeBlock, setActiveBlock] = useState('email');
  const { isLogin } = useContext(UserContext) as UserContextType;
  const { t } = useTranslation();
  const signinModal = useModal();
  const [isSignInPage, setIsSignInPage] = useState(true);

  useEffect(() => {
    // window.history.replaceState('', '', '?signin=true');
    const usp = new URLSearchParams(document.location.search);
    usp.get('signin') === 'true' || usp.get('page') === 'signIn'
      ? setIsSignInPage(true)
      : setIsSignInPage(false);
  }, []);

  useEffect(() => {
    if (!isLogin) {
      signinModal.onSelect(true);
    } else {
      signinModal.modal.onClose();
    }
  }, [isLogin]);

  const isTablet = useIsTablet();

  const fadingElement = useRef() as React.MutableRefObject<HTMLInputElement>;
  const fadeTime = 200;
  function handleTransition() {
    fadeIn();
    setTimeout(() => {
      setIsSignInPage(!isSignInPage);
      fadeOut();
    }, fadeTime);
  }
  function fadeIn() {
    fadingElement.current.style.transition = `${fadeTime}ms`;
    fadingElement.current.style.opacity = '0';
  }

  function fadeOut() {
    fadingElement.current.style.opacity = '1';
  }

  return (
    <div className={cn(styles.main_container)}>
      {/* left section */}
      <div className={cn(styles.sign_in_up_container)} ref={fadingElement}>
        <div className={cn(styles.sign_in_up_width_adjust)}>
          {SignInUpHeading(t, isSignInPage, setIsSignInPage, handleTransition)}
          {isSignInPage
            ? SignIn(t, activeBlock, setActiveBlock, toggleForm)
            : SignUp(handleTransition)}
        </div>
        <span className={cn(styles.copyright)}>Copyright © 2022 1TM</span>
      </div>
      {/* right section */}
      {!isTablet && (
        <div className={cn(styles.secondary_section_container)}>
          <h3 className={cn(styles.secondary_section_section_heading)}>
            Welcome to the Alpha release of 1TM.
          </h3>
          <p className={styles.secondary_section_paragraph}>
            Here are the things you can do with the Alpha version.
          </p>
          <ul className={cn(styles.secondary_section_section_list)}>
            <li className={cn(styles.secondary_section_section_list_item)}>
              Showcase your works and share your profile in your <b>Studio</b>.
            </li>
            <li className={cn(styles.secondary_section_section_list_item)}>
              Write blogs, share your projects with <b>Folio</b>.
            </li>
            <li className={cn(styles.secondary_section_section_list_item)}>
              Publish your works as Creative <b>Assets</b>.
            </li>
            <li className={cn(styles.secondary_section_section_list_item)}>
              Find inspirations in <b>Topic feeds</b>.
            </li>
            <li className={cn(styles.secondary_section_section_list_item)}>
              Protect your authorship with <b>Authorship token</b>.
            </li>
            <li className={cn(styles.secondary_section_section_list_item)}>
              License your work to others with <b>NFT Commons</b>.
            </li>
          </ul>
          <span
            className={cn(styles.secondary_section_section_1tm_description)}
          >
            1TM is a Collaboration Network for Creators, Enablers and
            Enthusiasts
          </span>
        </div>
      )}
    </div>
  );
};

function SignUp(handleTransition) {
  return (
    <div className={cn(styles.signup_container)}>
      <SignUpForm transition={handleTransition} />
    </div>
  );
}

function SignInUpHeading(t, isSignInPage, setIsSignInPage, handleTransition) {
  return (
    <div className={cn(styles.main_section_heading_container)}>
      <h2 className={cn(styles.main_section_heading)}>
        {isSignInPage ? 'Sign in' : 'Get started'}
      </h2>
      <div className={cn(styles.main_section_sub_heading)}>
        <span>
          {isSignInPage
            ? 'Don’t have an account yet?'
            : 'Already have an account?'}
          &nbsp;
        </span>
        <span
          className={cn(styles.sign_in_up_switcher)}
          onClick={() => {
            handleTransition();
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              () => setIsSignInPage(!isSignInPage);
            }
          }}
        >
          {isSignInPage ? `${t('Create one.')}` : `${t('Sign in.')}`}
        </span>
      </div>
    </div>
  );
}

function SignIn(
  t,
  activeBlock: string,
  setActiveBlock: Function,
  toggleForm: () => void
) {
  return (
    <div className={cn(styles.main_section_container)}>
      <div className={cn(styles.main_section_content)}>
        <div className={cn(styles.sign_in_form)}>
          <SignInUpTabs
            setActiveBlock={setActiveBlock}
            activeBlock={activeBlock}
          />
          {activeBlock === 'wallet' && <SignInWithWallet />}
          {activeBlock === 'email' && (
            <ModalSignInForm toggleForm={toggleForm} />
          )}
        </div>
      </div>
    </div>
  );
}
