const Copyright = () => {
  return (
    <div className="policy text-sm">
      <h5 className="font-bold">Privacy Policy</h5>
      <p>
        This privacy policy will explain how 1TM PTE. LTD. (“Our Company”) uses
        the personal data collected from you when you use Our Company&apos;s
        website.
      </p>
      <p>Topics:</p>
      <ul>
        <li>
          <a
            href="#what-data-do-we-collect"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            What data do we collect?
          </a>
        </li>
        <li>
          <a
            href="#how-do-we-collect-your-data"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            How do we collect your data?
          </a>
        </li>
        <li>
          <a
            href="#how-will-we-use-your-data"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            How will we use your data?
          </a>
        </li>
        <li>
          <a
            href="#how-do-we-store-your-data"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            How do we store your data?
          </a>
        </li>
        <li>
          <a
            href="#marketing"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            Marketing
          </a>
        </li>
        <li>
          <a
            href="#what-are-your-data-protection-rights"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            What are your data protection rights?
          </a>
        </li>
        <li>
          <a
            href="#what-are-cookies"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            What are cookies?
          </a>
        </li>
        <li>
          <a
            href="#how-do-we-use-cookies"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            How do we use cookies?
          </a>
        </li>
        <li>
          <a
            href="#what-types-of-cookies-do-we-use"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            What types of cookies do we use?
          </a>
        </li>
        <li>
          <a
            href="#how-to-manage-cookies"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            How to manage your cookies
          </a>
        </li>
        <li>
          <a
            href="#privacy-policies-of-other-websites"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            Privacy policies of other websites
          </a>
        </li>
        <li>
          <a
            href="#changes-to-our-privacy-policy"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            Changes to our privacy policy
          </a>
        </li>
        <li>
          <a
            href="#how-to-contact-us"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            How to contact us
          </a>
        </li>
        <li>
          <a
            href="#how-to-contact-appropriate-authorities"
            style={{
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            How to contact the appropriate authorities
          </a>
        </li>
      </ul>

      <h5 className="font-bold" id="what-data-do-we-collect">
        What data do we collect?
      </h5>
      <p>Our Company collects the following data:</p>
      <ul>
        <li>
          Personal identification information (Name, email address, phone
          number, etc.)
        </li>
        <li>
          Financial information when you use our launch features or when you
          process payments if you make purchases (credit card and account
          information)
        </li>
        <li>
          Social media account information for link to 1TM platform purpose
        </li>
        <li>
          Personal preference information (passions, favorite brands, etc.)
        </li>
        <li>
          Information Technology Data (internet protocol (IP) address, login
          data, traffic data, weblogs and other communication data, browser type
          and version, time zone setting and location, browser plug-in types and
          versions, operating system and platform and other technology on the
          devices you use to access our website){' '}
        </li>
      </ul>

      <h5 className="font-bold" id="how-do-we-collect-your-data">
        How do we collect your data?
      </h5>
      <p>
        You directly provide Our Company with most of the data we collect. We
        collect data and process data when you:
      </p>
      <ul>
        <li>
          Register online or place an order for any of our products or services.
        </li>
        <li>
          Voluntarily complete a customer survey or provide feedback on any of
          our message boards or via email.
        </li>
        <li>
          Use or view our website via your browser&apos;s cookies. (traffic
          data, location data, weblogs, other communication data, etc)
        </li>
        <li>Subscribe to our mailing or newsletter services.</li>
        <li>Linking other social media accounts to our website.</li>
      </ul>
      <p>
        Our Company may also receive your data indirectly from the following
        sources:
      </p>
      <ul>
        <li>Automated technologies or interactions. </li>
        <li>Publicly available sources.</li>
        <li>
          Third parties, such as:
          <ul className="list-circle">
            <li>Analytics providers</li>
            <li>Service providers</li>
            <li>
              Providers of social media platforms (Linkedin, Facebook, Twitter
              and Instagram)
            </li>
            <li>Contact, and Transaction Data from providers of technical</li>
          </ul>
        </li>
      </ul>

      <h5 className="font-bold" id="how-will-we-use-your-data">
        How will we use your data?
      </h5>
      <p>Our Company collects your data so that we can:</p>
      <ul>
        <li>Manage your account and contact you if needed.</li>
        <li>Process your orders, if any.</li>
        <li>
          Email you with special offers on other products and services we think
          you might like.
        </li>
        <li>
          Administer and protect our platform and website (troubleshooting, data
          analysis, testing, system maintenance, support, reporting and hosting
          of data)
        </li>
        <li>
          Use data analytics to improve our platform and website,
          products/services, marketing, customer relationships and experiences.
        </li>
        <li>
          Make announcements and recommendations to you about services or events
          that may be of interest to you.
        </li>
      </ul>
      <p>
        If you agree, Our Company will share your data with our partner
        companies so that they may offer you their products and services.
      </p>
      <p>
        When Our Company processes your order, it may send your data to, and
        also use the resulting information from, credit reference agencies to
        prevent fraudulent purchases.
      </p>

      <h5 className="font-bold" id="how-do-we-store-your-data">
        How do we store your data?
      </h5>
      <p>
        Our Company securely stores your data at Amazon Web Services database
        (S3/Southeast) with SSO login that only 1TM employees can access.
      </p>
      <p>
        Our Company will keep your transient digital data for 90 days. Once this
        time period has expired, we will delete your data by removing all
        entries reasonably found from our servers and databases.
      </p>

      <h5 className="font-bold" id="marketing">
        Marketing
      </h5>
      <p>
        Our Company would like to send you information about products and
        services of ours that we think you might like, as well as those of our
        partner companies.
      </p>
      <p>
        If you have agreed to receive marketing, you may always opt out at a
        later date.
      </p>
      <p>
        You have the right at any time to stop Our Company from contacting you
        for marketing purposes or giving your data to other members of the Our
        Company Group.
      </p>
      <p>
        If you no longer wish to be contacted for marketing purposes, please
        click{' '}
        <a
          href="mailto:workwithus@1tm.io"
          style={{ textDecoration: 'underline', color: 'blue' }}
        >
          here
        </a>
        .
      </p>

      <h5 className="font-bold" id="what-are-your-data-protection-rights">
        What are your data protection rights?
      </h5>
      <p>
        Our Company would like to make sure you are fully aware of all of your
        data protection rights. Every user is entitled to the following:
      </p>
      <p>
        <b>The right to access</b>– You have the right to request Our Company
        for copies of your personal data. We may charge you a small fee for this
        service.
      </p>
      <p>
        <b>The right to rectification</b>– You have the right to request that
        Our Company correct any information you believe is inaccurate. You also
        have the right to request Our Company to complete the information you
        believe is incomplete.
      </p>
      <p>
        <b>The right to erasure</b>– You have the right to request that Our
        Company erase your personal data, under certain conditions.
      </p>
      <p>
        <b>The right to restrict processing</b>– You have the right to request
        that Our Company restrict the processing of your personal data, under
        certain conditions.
      </p>
      <p>
        <b>The right to object to processing</b>– You have the right to object
        to Our Company&apos;s processing of your personal data, under certain
        conditions.
      </p>
      <p>
        <b>The right to data portability</b>– You have the right to request that
        Our Company transfer the data that we have collected to another
        organization, or directly to you, under certain conditions.
      </p>

      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us at
        workwithus@1tm.io
      </p>
      <p>
        If you wish to make a request, please do so by emailing a request to us
        by copying and filling in the template as provided by the gdpr.eu{' '}
        <a href="https://gdpr.eu/right-to-be-forgotten/?cn-reloaded=1">
          website.
        </a>
      </p>

      <h5 className="font-bold" id="what-are-cookies">
        Cookies
      </h5>
      <p>
        Cookies are text files placed on your computer to collect standard
        Internet log information and visitor behavior information. When you
        visit our websites, we may collect information from you automatically
        through cookies or similar technology
      </p>
      <p>
        For further information, visit{' '}
        <a
          href="http://allaboutcookies.org"
          style={{ textDecoration: 'underline', color: 'blue' }}
        >
          allaboutcookies.org
        </a>
        .
      </p>

      <h5 className="font-bold" id="how-do-we-use-cookies">
        How do we use cookies?
      </h5>
      <p>
        Our Company uses cookies in a range of ways to improve your experience
        on our website, including:
      </p>
      <ul>
        <li>Keeping you signed in</li>
        <li>Understanding how you use our website</li>
      </ul>
      <h5 className="font-bold" id="what-types-of-cookies-do-we-use">
        What types of cookies do we use?
      </h5>
      <p>
        There are a number of different types of cookies, however, our website
        uses:
      </p>
      <ul>
        <li>
          Functionality – Our Company uses these cookies so that we recognize
          you on our website and remember your previously selected preferences.
          These could include what language you prefer and location you are in.
          A mix of first-party and third-party cookies are used.
        </li>
        <li>
          Advertising – Our Company uses these cookies to collect information
          about your visit to our website, the content you viewed, the links you
          followed and information about your browser, device, and your IP
          address. Our Company sometimes shares some limited aspects of this
          data with third parties for advertising purposes. We may also share
          online data collected through cookies with our advertising partners.
          This means that when you visit another website, you may be shown
          advertising based on your browsing patterns on our website.
        </li>
      </ul>

      <h5 className="font-bold" id="how-to-manage-cookies">
        How to manage cookies
      </h5>
      <p>
        You can set your browser not to accept cookies, and the above website
        tells you how to remove cookies from your browser. However, in a few
        cases, some of our website features may not function as a result.
      </p>

      <h5 className="font-bold" id="privacy-policies-of-other-websites">
        Privacy policies of other websites
      </h5>
      <p>
        The Our Company website contains links to other websites. Our privacy
        policy applies only to our website, so if you click on a link to another
        website, you should read their privacy policy.
      </p>

      <h5 className="font-bold" id="changes-to-our-privacy-policy">
        Changes to our privacy policy
      </h5>
      <p>
        Our Company keeps its privacy policy under regular review and places any
        updates on this web page. This privacy policy was last updated on 1
        August 2021.
      </p>

      <h5 className="font-bold" id="how-to-contact-us">
        How to contact us
      </h5>
      <p>
        If you have any questions about Our Company&apos;s privacy policy, the
        data we hold on you, or you would like to exercise one of your data
        protection rights, please do not hesitate to contact us.
      </p>

      <p>Email us at: workwithus@1tm.io </p>

      <h5 className="font-bold" id="how-to-contact-appropriate-authorities">
        How to contact the appropriate authority
      </h5>
      <p>
        Should you wish to report a complaint or if you feel that Our Company
        has not addressed your concern in a satisfactory manner, you may contact
        the Information Commissioner&apos;s Office.
      </p>
      <p>Email: workwithus@1tm.io</p>
    </div>
  );
};
export default Copyright;
