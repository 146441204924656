/**
 * Internal dependencies
 */
import updateSearchResult from './updateSearchResult';

function fetchResultSuccess(state, payload) {
  const result = updateSearchResult(state, payload);

  const { callbackAfterFetchSuccess } = payload;
  if (
    callbackAfterFetchSuccess &&
    typeof callbackAfterFetchSuccess === 'function'
  ) {
    callbackAfterFetchSuccess(result);
  }

  return {
    ...result,

    isFetching: false,
    isError: false,
  };
}

export default fetchResultSuccess;
