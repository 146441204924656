function updateSearchResult(state, { result, total }) {
  const updated = state.isSearchLite
    ? {
        resultLite: result,
      }
    : {
        totalResult: total ?? state.totalResult,
        result,
      };

  return {
    ...state,
    ...updated,
  };
}

export default updateSearchResult;
