import { FilterNameType } from './types';

export const INITIAL_STATE = {
  skills: {},
  categories: [],
  services: [],
  languages: [],

  hasFilter: false,
  result: {},
  totalResult: 0,
};

export const FILTER_NAME = FilterNameType;

export const MIGRATION_FILTER_NAME = {
  CATEGORY: 'CATEGORIES',
  SKILL: 'SKILLS',
  SERVICE: 'SERVICES',
  LANGUAGE: 'LANGUAGES',
};
