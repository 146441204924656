import {
  Select,
  useDisclosure,
  Box,
  Text,
  Button,
  Tabs,
  TabList,
  Tab,
  useColorMode,
  SkeletonCircle,
  SkeletonText,
  Skeleton,
} from '@chakra-ui/react';
import { Layout } from 'src/components/layout';
import { nanoid } from 'nanoid';
import React, {
  ReactNode,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import {
  useIntersectionObserver,
  useUploadImage,
  useLocalStorage,
  // useSessionStorage,
} from 'src/hooks';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import SvgAddImage2 from 'src/components/icons/add-image-2';
import SvgDiscussion from 'src/components/icons/discussion';
import SvgMedia from 'src/components/icons/media-post2';
import SvgStory from 'src/components/icons/story-post';
import SvgPoll from 'src/components/icons/poll';
import { Avatar } from 'src/components/avatar/avatar';
import AgoraPostModal from 'src/app/article/agora-post-modal';
import useProfileQuery, {
  fetchProfile,
} from 'src/app/profile/use-profile-query';
import { useModal } from 'src/hooks/use-modal';
import { useDropzone } from 'react-dropzone';
import { LIMIT_UPLOAD_IMAGE_FILE_ACCEPT } from 'src/constants/limit';
import {
  // useQuery,
  useQueryClient,
} from 'react-query';
import { CacheKey } from 'src/constants/cache-key';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { AgoraMultiTab } from 'src/app/explore/tab-window';
import {
  fetchExplore,
  // fetchParticipated,
  useFetchFeed,
  useFetchTribeFeed,
  useFetchTopCreators,
  // useFetchTopProjects,
  useFetchTopTags,
  // getTutorial,
  useFetchTopCategories,
  useFetchCommunityFeed,
} from 'src/app/explore/service';
import { CategoryCard } from 'src/app/explore/category-card';
import { useRouter } from 'next/router';
// import { UnsplashResponse } from 'src/pages/api/unsplash';
// import algolia from 'src/lib/algolia';
import { useFollowUserMutation } from 'src/app/profile/use-follow-user.mutation';
import { logger } from 'src/lib/logger';

import { AgoraFeed, AgoraFeatured } from 'src/app/explore/agora-feed';
import { GeneralFeed } from 'src/app/explore/general-feed';
import {
  createFollow,
  deleteFollow,
  useFetchFollows,
} from 'src/app/follow/service';
import WarningModal from 'src/app/article/warning-modal';
import { DeleteModal } from 'src/components/modal/delete-modal';
import categories from 'src/configs/categories';
import { useGeneralDeleteMutation } from 'src/app/studio/hooks/use-studio-mutation';
import SvgBoxStart from 'src/components/icons/box-start';
import SvgCameraV1 from 'src/components/icons/camera-v1';
import SvgDoorNonLogin from 'src/components/icons/door-nonlogin';
import TutorialChecklistModal from 'src/components/tutorial/tutorial-checklist-modal';
import { Metadata } from 'src/app/metadata';
// import SvgSortDesc from 'src/components/icons/sort-desc';
import { LinkTab, LinkTabs } from 'src/components/link-tabs/link-tabs';
// import * as ga from 'src/lib/ga';
import SvgArrowRightV4 from 'src/components/icons/arrow-right-v4';
import { getFullEpisode } from 'src/app/reppl/episode/service';
import { imageOptimize } from 'src/service/image';
import { useIsTablet, useIsLg } from 'src/hooks/use-is-mobile';
import SignInModal from 'src/app/auth/sign-in/sign-in-modal';
import { AnnouncementFeed } from './announcement';
import { AnnouncementUpdates, UpdatesPage } from './types';

// const searchImages = (term: string): Promise<{ images: UnsplashResponse[] }> =>
//   fetch(`/api/unsplash?term=${term}`).then((res) => res.json());

// function useUnsplash(term: string) {
//   return useQuery([CacheKey.unsplash, term], () => searchImages(term), {
//     keepPreviousData: true,
//     refetchOnMount: false,
//     refetchInterval: false,
//     refetchOnWindowFocus: false,
//   });
// }

const getImagePath = (term: string) => {
  return `/images/assets/categories/${term
    ?.split(' ')
    .join('')
    .split('-')
    .join('')
    .split('&')
    .join('')}.png`;
};

export function ExploreLayout({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  return (
    <>
      <Metadata title="Updates" description={t('agoraDescription')} />
      <Layout>
        <ExploreCover />
        {children}
      </Layout>
    </>
  );
}

export function ExploreCover() {
  const [usedcategories, setCategories] = useState([]);
  // const marquees6 = [
  //   'animate-marquee',
  //   'animate-marquee2',
  //   'animate-marquee3',
  //   'animate-marquee4',
  //   'animate-marquee5',
  //   'animate-marquee6',
  // ];
  const marquees2 = ['animate-marquee', 'animate-marquee2'];
  const [marquees] = useState(marquees2);
  const [dataUpdatesPage, setDataUpdatesPage] = useState<UpdatesPage>();

  useEffect(() => {
    fetch(
      `https://career.1tm.io/api-json/wp/v2/pages/73673?timestamp=${Date.now()}`
    )
      .then((res) => res.json())
      .then((result) => {
        const data = result?.acf;
        setDataUpdatesPage(data?.group1[0]);
      });

    async function getCategories() {
      const type = 'category';
      const fetchedData = await fetchExplore({ type });
      // const tribes = await algolia.search('', 5, '', 'categories', 10);
      const newFetch = [...categories];
      let dataFetched = [];
      if (fetchedData) {
        dataFetched = fetchedData;
      }
      if (dataFetched.length > 0) {
        const fetched = newFetch.map((cat) => {
          const fetchedSub = dataFetched.filter(
            (fetch) => cat.value === fetch.name
          );
          if (fetchedSub[0]) {
            return { ...cat, count: fetchedSub[0].count };
          }
          return cat;
        });
        setCategories(fetched);
      } else {
        setCategories(categories);
      }
      // if (newFetch?.length * 195 < window.innerWidth) {
      //   setMarquees(marquees6);
      // }
    }
    getCategories();
  }, []);

  return (
    <>
      {dataUpdatesPage?.display && (
        <Box
          className="relative w-full z-10 px-4 sm:px-16 pb-0 sm:pb-6 overflow-x-hidden"
          pt="40px"
          pb="20px"
        >
          <div className="hidden absolute left-0 right-0 z-50 marquee">
            {marquees.map((marquee) => (
              <div
                key={marquee}
                className={`${marquee} absolute top-0 flex whitespace-nowrap text-white w-max marquee-child`}
              >
                {usedcategories &&
                  usedcategories?.map((list) => (
                    <CategoryCard
                      key={list.value}
                      name={list.value}
                      count={list.count}
                    />
                  ))}
              </div>
            ))}
          </div>
          <div className="relative w-full flex items-center justify-center md:px-36">
            <div className="justify-around text-center lg:w-7/12">
              <Text
                className="sm:text-3xl link inline-block mx-4 dark:text-white"
                fontSize="1.5rem"
                fontWeight="600"
                lineHeight="1.875rem"
                pb="10px"
                color="#242526"
              >
                {dataUpdatesPage?.title}
              </Text>
              <Text
                className="text-xs sm:text-sm font-medium"
                lineHeight="20px"
                color="#4F5356"
              >
                {dataUpdatesPage?.description}
              </Text>
            </div>
          </div>
        </Box>
      )}
    </>
  );
}

export function TribeLayout({
  children,
  tribe,
}: {
  children: ReactNode;
  tribe: string;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Metadata
        title={`Tribe for ${tribe} | 1TM`}
        description={`${t('tribeDescription')} ${tribe}.`}
      />
      <Layout>
        <TribeCover />
        {children}
      </Layout>
    </>
  );
}

export function TribeCover() {
  const { t } = useTranslation();
  const router = useRouter();
  const image = useMemo(() => getImagePath(`${router.query.tribe}`), [
    router.query.tribe,
  ]);
  const [recentLocal, setRecentLocal] = useLocalStorage('recentTribes', []);
  const [tribeFormat, setTribeFormat] = useState<any>();
  const [isCat, setIsCat] = useState(false);

  useEffect(() => {
    const date = new Date();
    if (router) {
      const tribeSessions = recentLocal;
      if (router.query.tribe) {
        let tribeData = `${router.query.tribe}`;
        tribeData = tribeData.replace(/-/g, ' ');
        setTribeFormat(tribeData);
        const is = categories.filter((cat) => cat.displayName === tribeData);
        setIsCat(is.length > 0);
        const isSaved = tribeSessions
          .map(function mapReturn(e) {
            return e.id;
          })
          .indexOf(tribeData);
        if (isSaved < 0) {
          tribeSessions.push({
            title: tribeData,
            id: tribeData,
            href: `/feed/${tribeData}`,
            avatar: image || '',
            lastVisit: date,
          });
        }
      }
      if (tribeSessions.length > 5) {
        tribeSessions.shift();
      }
      setRecentLocal(recentLocal);
    }
  }, [image, recentLocal, router, setRecentLocal]);

  return (
    <div
      style={{ height: '250px' }}
      className="relative w-full z-10 px-16 pb-6 overflow-x-hidden div-offest"
    >
      <div className="absolute left-0 right-0 z-50 w-full h-full">
        {isCat ? (
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundColor: 'rgb(200, 200, 200)',
              backgroundBlendMode: 'multiply',
            }}
            className="relative py-2 w-full h-full"
          >
            <div
              style={{ height: 'fit-content' }}
              className="absolute inset-0 m-auto"
            >
              <div className="text-center font-medium text-xs text-white">
                {t('TRIBE')}
              </div>
              <div className="text-center font-bold text-4xl py-1 text-white">
                {tribeFormat}
              </div>
              <div className="hidden flex justify-center text-xs text-white">
                <span>1234 followers</span>
                <span className="mx-2">•</span>
                <span>3321 folios</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative py-2 w-full h-full bg-black">
            <div
              style={{ height: 'fit-content' }}
              className="absolute inset-0 m-auto"
            >
              <div className="text-center font-medium text-xs text-white">
                {t('TOPIC')}
              </div>
              <div className="text-center font-bold text-4xl py-1 text-white">
                #{tribeFormat}
              </div>
              <div className="hidden flex justify-center text-xs text-white">
                <span>1234 followers</span>
                <span className="mx-2">•</span>
                <span>3321 folios</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function Agora() {
  const { t } = useTranslation();
  const router = useRouter();
  const creationObserverRef = useRef();
  const isMobile = useIsTablet();
  const isTablet = useIsLg();
  const { user, isLogin, isFetching } = useContext(
    UserContext
  ) as UserContextType;
  const [trendingTribes, setTrendingTribes] = useState([]);
  const [hashTags, setHashTags] = useState([]);
  const [cats, setCats] = useState([]);
  // const [reppls, setReppls] = useState([]);
  // const [participated, setParticipated] = useState([]);
  const [topCreators, setTopCreators] = useState([]);
  const [tribeDataFollow, setTribeDataFollow] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [progress, setProgress] = useState<ProgressEvent | null>(null);
  const [lastUploadedFile, setLastUploadedFile] = useState<any>();
  const [recent, setRecent] = useState([]);
  // const [following, setFollowing] = useState([]);
  const [tribeFollowing, setTribeFollowing] = useState([]);
  const queryClient = useQueryClient();
  const postModal = useModal();
  const editModal = useModal();
  const deleteModal = useModal();
  const warningModal = useModal();
  const [currId, setCurrId] = useState('');
  const { data: profile } = useProfileQuery(user?.username);
  const followers: number | null = profile?.numOfFollowers;
  const creations: number | null = profile?.numOfEpisodes;
  const recentList = ['People', 'Tribe'];
  const trendingTab = ['Hashtags', 'Categories'];
  // const followingList = ['People', 'Spaces', 'Collections'];
  const localTribes = useLocalStorage('recentTribes', []);
  // const localReppls = useLocalStorage('recentReppls', []);
  const localPeople = useLocalStorage('recentPeople', []);
  // const [followTab, setFollowTab] = useState('USER');
  const [followTribeTab, setTribeFollowTab] = useState('TAG');
  const [tribeTab, setTribeTab] = useState(0);
  const [articleToEdit, setArticleToEdit] = useState<any>();
  const [forDeletion, setForDeletion] = useState<any>();
  const [isCat, setIsCat] = useState(false);
  const [routeData, setRouteData] = useState<any>();
  const [newFeedUpdates, setNewFeedUpdates] = useState<any>();
  const [nextPageFeed, setNextPageFeed] = useState<any>();
  const [topicData, setTopicData] = useState<any>();

  const signInModal = useDisclosure();
  // const { data: followData } = useFetchFollows({
  //   group: followTab,
  // });
  const { data: followTribeData } = useFetchFollows({
    group: followTribeTab,
  });
  const { data: followTribeCategoryData } = useFetchFollows({
    group: 'CATEGORY',
  });
  const { data: followTribeTagData } = useFetchFollows({
    group: 'TAG',
  });
  const { data: getTopCreators } = useFetchTopCreators();
  const { data: fetchFeed, fetchNextPage, hasNextPage } = useFetchFeed({
    sortType: 'MOST_LIKE',
    trendTime: 'DAILY',
  });
  const {
    data: fetchTribeFeed,
    fetchNextPage: fetchNextTribe,
    hasNextPage: hasNextTribe,
    refetch: tribeRefetch,
  } = useFetchTribeFeed({
    sortType: tribeTab === 0 ? 'RECENCY' : 'MOST_LIKE',
    kindTitle: `${router.query.tribe}`.replace(/-/g, ' '),
    kindType: `${isCat ? 'CATEGORY' : 'TAG'}`,
    trendTime: 'DAILY',
  });
  const {
    data: fetchCommunityFeed,
    fetchNextPage: fetchNextCommunity,
    hasNextPage: hasNextCommunity,
    refetch: communityRefetch,
  } = useFetchCommunityFeed({
    kindTitle: `${router.query.tribe}`.replace(/-/g, ' '),
  });
  // const { data: getTopProjects } = useFetchTopProjects();
  const { data: getTopTags } = useFetchTopTags();
  const { data: getTopCategories } = useFetchTopCategories();
  const [value, setValue] = useState('Recency');
  const [activeTribeTab, setActiveTribeTab] = useState('tribe');
  // const { mutate: deleteStudio } = useStudioDeleteMutation(true);
  const { mutate: deleteGeneralStudio } = useGeneralDeleteMutation(true);
  const { colorMode } = useColorMode();
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value);
  };

  const IntersectOrNext = (typeData) => {
    if (typeData.type === 'intersect') {
      return typeData.fetch;
    }
    return typeData.next;
  };

  const refetchFeed = () => {
    tribeRefetch();
    communityRefetch();
  };

  const checkInterSect = (type) => {
    if (router.query.tribe) {
      if (activeTribeTab === 'tribe') {
        return IntersectOrNext({
          type,
          fetch: fetchNextTribe,
          next: hasNextTribe,
        });
      }
      return IntersectOrNext({
        type,
        fetch: fetchNextCommunity,
        next: hasNextCommunity,
      });
    }
    return IntersectOrNext({
      type,
      fetch: fetchNextPage,
      next: hasNextPage,
    });
  };

  useIntersectionObserver({
    target: creationObserverRef,
    onIntersect: checkInterSect('intersect'),
    enabled: checkInterSect('next'),
  });

  const tutorialChecklistModal = useDisclosure();

  const { mutate: followUser } = useFollowUserMutation([
    CacheKey.profile,
    user?.username,
  ]);

  useEffect(() => {
    const uuid = nanoid();
    setCurrId(uuid);
  }, []);

  const dropzone = useDropzone({
    noClick: true,
    onDrop: async (files) => {
      const formData = new FormData();
      formData.set('file', files[0]);
      postModal.onSelect(true);
      setLastUploadedFile(files[0]);
      await uploadCover({ data: formData });
    },
  });

  const { mutateAsync: uploadCover, isLoading: isUploading } = useUploadImage(
    {
      areaType: 'EPISODE',
      imgType: 'episode_img',
      updateProgress: setProgress,
    },
    (response) => {
      const updater = (prevCache) => ({
        ...prevCache,
        uploadedImg: response.imgURL,
      });

      setUploadedFiles([
        {
          type: 'image',
          id: generateUniqueID(),
          imgURL: response.imgURL,
          file: lastUploadedFile,
          title: null,
          description: null,
        },
        ...uploadedFiles,
      ]);
      queryClient.setQueryData([CacheKey.creation, currId], updater);
    }
  );

  const [recentTab, setRecentTab] = useState(0);
  // const [recentFollowing, setFollowingTab] = useState(0);
  const [mainFeedLoad, setMainFeedLoad] = useState<any>();
  const [isTribeFollow, setIsTribeFollow] = useState(false);
  const [tribeIndex, setTribeIndex] = useState(0);
  const [recentTribeFollowing, setTribeFollowingTab] = useState(0);

  const setRecentPeople = useCallback(async () => {
    const updatedLocalPeople = await Promise.all(
      localPeople[0].map(async ({ username }) => fetchProfile(username))
    );
    setRecent(updatedLocalPeople);
  }, []);

  useEffect(() => {
    setRecentPeople();
  }, [setRecentPeople]);

  const handleRequests = (event) => {
    setRecentTab(event);
    if (event === 0) {
      setRecentPeople();
    }
    // if (event === 1) {
    //   setRecent(localReppls[0]);
    // }
    if (event === 1) {
      setRecent(localTribes[0]);
    }
  };

  const tribeHandler = (event) => {
    setTribeIndex(event);

    if (event === 0) {
      setTrendingTribes(hashTags);
    } else {
      setTrendingTribes(cats);
    }
    // set if hashtag or category
  };

  useEffect(() => {
    if (isCat) {
      const arrayTribeFollowCategoryData =
        followTribeCategoryData?.pages
          .flatMap((pages) => pages.data)
          .map((item) => item?.title) || [];

      setTribeDataFollow(arrayTribeFollowCategoryData);
    } else {
      const arrayTribeFollowTagData =
        followTribeTagData?.pages
          .flatMap((pages) => pages.data)
          .map((item) => item?.title) || [];

      setTribeDataFollow(arrayTribeFollowTagData);
    }
  }, [followTribeCategoryData?.pages, followTribeTagData?.pages]);

  // useEffect(() => {
  //   const newFollowData = followData?.pages.flatMap((pages) => pages.data);
  //   if (newFollowData?.length === 1) {
  //     if (newFollowData[0]) {
  //       setFollowing(newFollowData);
  //     } else {
  //       setFollowing([]);
  //     }
  //   } else {
  //     setFollowing(newFollowData);
  //   }
  // }, [followData]);

  useEffect(() => {
    const followingTribe =
      followTribeData?.pages.flatMap((pages) => pages.data) || [];
    setTribeFollowing(followingTribe);
  }, [followTribeData]);

  // const followingRequests = (event) => {
  //   setFollowingTab(event);
  //   if (event === 0) {
  //     setFollowTab('USER');
  //   } else if (event === 1) {
  //     setFollowTab('REPPL');
  //   } else if (event === 2) {
  //     setFollowTab('COLLECTION');
  //   }
  // };

  const tribeFollowingRequests = (event) => {
    setTribeFollowingTab(event);
    if (event === 0) {
      setTribeFollowTab('TAG');
    } else if (event === 1) {
      setTribeFollowTab('CATEGORY');
    }
  };

  // useEffect(() => {
  //   const newFeed = feed?.map((x) => x);
  //   // newFeed?.sort((a, b) => b.createdAt?.localeCompare(a.createdAt));
  //   setSortedFeed(newFeed);
  // }, [feed, setFeed]);

  const getTopicData = (dataType) => {
    if (topicData && topicData.length > 0) {
      return topicData[0][dataType];
    }
    return '0';
  };

  useEffect(() => {
    async function topProjectsCreators() {
      if (router.query.tribe) {
        let tribeData = `${router.query.tribe}`;
        tribeData = tribeData.replace(/-/g, ' ');
        const is = categories.filter((cat) => cat.displayName === tribeData);
        setIsCat(is.length > 0);
        setRouteData(tribeData);
        let currFeed;
        let nextPage;
        if (activeTribeTab === 'tribe') {
          currFeed = fetchTribeFeed?.pages?.flatMap((data) => data);
          nextPage = currFeed?.map((item) => item?.next)?.includes(false);
        } else {
          currFeed = fetchCommunityFeed?.pages?.flatMap((data) => data);
          nextPage = currFeed?.map((item) => item?.next)?.includes(false);
        }
        setNextPageFeed(nextPage);
        setMainFeedLoad(curateFeed(currFeed));
      } else if (router.pathname !== '/feed/[tribe]' && !router.query.tribe) {
        // mainFeed = await fetchFeed();
        const currFeed = fetchFeed?.pages?.flatMap((data) => data);
        const nextPage = currFeed?.map((item) => item?.next)?.includes(false);
        setNextPageFeed(nextPage);

        setMainFeedLoad(curateFeed(currFeed));
      }
    }

    topProjectsCreators();
  }, [
    router,
    isLogin,
    fetchFeed,
    fetchTribeFeed,
    activeTribeTab,
    fetchCommunityFeed,
  ]);

  useEffect(() => {
    async function getCategories() {
      const fetchedData = await fetchExplore({
        type: isCat ? 'category' : 'tag',
        data: `&name=${encodeURIComponent(routeData)}`,
      });
      setTopicData(fetchedData);
    }

    if (routeData) {
      getCategories();
    }
  }, [routeData]);

  const curateFeed = (data) => {
    if (!data) {
      return [];
    }
    const currData = {
      // featuredEpisodes: [],
      feedEpisodes: [],
    };
    data.forEach((cfeed) => {
      if (cfeed.data) {
        currData.feedEpisodes = [...currData.feedEpisodes, ...cfeed.data];
      }
    });
    return currData;
  };

  // useEffect(() => {
  //   async function feedCreator() {
  //     const participatedList = await fetchParticipated();
  //     setParticipated(participatedList);
  //   }
  //   feedCreator();
  // }, []);

  useEffect(() => {
    // if (getTopProjects) {
    //   const projects = getTopProjects.pages[0].data.flatMap((data) => data);
    //   setReppls(projects);
    // }
    if (getTopCreators) {
      const users = getTopCreators.pages[0].data.flatMap((data) => data);
      setTopCreators(users);
    }
    if (getTopTags) {
      const hashs = getTopTags.pages[0].data.flatMap((data) => {
        return { ...data, title: `#${data.title}`, value: data.title };
      });
      setHashTags(hashs);
      setTrendingTribes(hashs);
    }
    if (getTopCategories) {
      const categoriesData = getTopCategories.pages[0].data.flatMap(
        (data) => data
      );
      setCats(categoriesData);
    }
  }, [getTopCreators, getTopTags, getTopCategories]);

  const isTribeFollowInData = tribeDataFollow.includes(`${routeData}`);

  function tribeFollow() {
    const followValue = {
      targetID: routeData,
      group: isCat ? 'CATEGORY' : 'TAG',
    };
    if (!isLogin) {
      router.push({ query: { ...router.query, page: 'signIn' } });
    } else if (isTribeFollow || isTribeFollowInData) {
      localTotalFollow(topicData[0], 'followerCount', false);
      deleteFollow(followValue);
      setIsTribeFollow(false);
      setTribeDataFollow(tribeDataFollow.filter((e) => e !== `${routeData}`));
    } else {
      localTotalFollow(topicData[0], 'followerCount', true);
      createFollow(followValue);
      setIsTribeFollow(true);
    }
  }

  const openEditModal = (data) => {
    setArticleToEdit(data);
    editModal.onSelect(true);
  };

  const deleteGeneral = (data) => {
    setForDeletion(data);
    deleteModal.onSelect(true);
  };

  const deleteGeneralPost = () => {
    deleteGeneralStudio(forDeletion);
    // router.reload();
  };

  // const checkIsCat = (data) => {
  //   const is = categories.filter((cat) => cat.displayName === data);
  //   return is.length > 0;
  // };

  const [isLeave, setIsLeave] = useState(false);
  const [nextRoute, setNextRoute] = useState();

  useEffect(() => {
    const checkSession = () => {
      if (JSON.parse(window.sessionStorage.getItem('generalContent'))) {
        return true;
      }
      return false;
    };
    const handleRouteChange = (url) => {
      setNextRoute(url);
      if (checkSession()) {
        warningModal.onSelect(true);
        router.events.emit('routeChangeError');
        const err = 'routeChange aborted.';

        /* eslint-disable */
        throw err;
        /* eslint-enable */
      }
    };
    if (isLeave) {
      router.events.off('routeChangeStart', handleRouteChange);
      sessionStorage.removeItem('generalContent');
      warningModal.modal.onClose();
      router.push(nextRoute);
    } else {
      router.events.on('routeChangeStart', handleRouteChange);
    }
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [isLeave, setIsLeave]);

  // const [tutorialData, setTutorialData] = useState(null);
  // const [completedData, setCompletedData] = useState(null);

  // useEffect(() => {
  //   const getTutorialData = async () => {
  //     const data = await getTutorial();
  //     setTutorialData(data);
  //   };

  //   getTutorialData();
  // }, [tutorialChecklistModal.isOpen, isLogin]);

  // useEffect(() => {
  //   if (tutorialData !== null) {
  //     const completedSteps = tutorialData.map((step) => {
  //       return step.step;
  //     });
  //     setCompletedData(completedSteps);
  //   }
  // }, [tutorialData]);

  // const [showWhatIsAgora, setShowWhatIsAgora] = useSessionStorage(
  //   'ShowWhatIsAgora',
  //   true
  // );

  // useEffect(() => {
  //   const openTutorialModal = async () => {
  //     await router.push({
  //       pathname: `/feed`,
  //       query: { tutorialStep: 'whatIsAgora' },
  //     });
  //     setShowWhatIsAgora(false);
  //   };

  //   if (completedData !== null) {
  //     if (
  //       !completedData.includes('whats_agora') &&
  //       !isMobile &&
  //       showWhatIsAgora
  //     ) {
  //       openTutorialModal();
  //     }
  //   }
  // }, [completedData]);

  const updateFollow = (tribeOrUser, titleOrId, isFollowed) => {
    if (tribeOrUser === 'tribe') {
      mutateFollow(
        [...trendingTribes],
        setTrendingTribes,
        titleOrId,
        isFollowed,
        true
      );
    } else {
      mutateFollow(
        [...topCreators],
        setTopCreators,
        titleOrId,
        isFollowed,
        false
      );
    }
  };

  const localTotalFollow = (data, attrb, isfollowed = false) => {
    if (data.followed || isfollowed) {
      // eslint-disable-next-line no-param-reassign
      data[attrb] += 1;
    } else {
      // eslint-disable-next-line no-param-reassign
      data[attrb] -= 1;
    }
  };

  const mutateFollow = (
    defObject,
    setDefObject,
    titleOrId,
    isFollowed,
    isTitle
  ) => {
    const objAttribute = isTitle ? 'title' : 'userID';
    const mutatedData = defObject.filter(
      (obj) => obj[objAttribute] === titleOrId
    );
    mutatedData[0].followed = !isFollowed;
    if (mutatedData[0])
      if ('followersCount' in mutatedData[0]) {
        localTotalFollow(mutatedData[0], 'followersCount');
      } else {
        localTotalFollow(mutatedData[0], 'followers');
      }
    const updatedObject = [...defObject];
    setDefObject(updatedObject);
  };

  const [dataAnnouncement, setDataAnnouncement] = useState<
    AnnouncementUpdates
  >();

  const [dataFeaturePost, setDataFeaturePost] = useState<any>();
  const [isFeaturePost, setIsFeaturePost] = useState<any>();
  let idFeaturePost = '';

  useEffect(() => {
    fetch(
      `https://career.1tm.io/api-json/wp/v2/pages/73673?timestamp=${Date.now()}`
    )
      .then((res) => res.json())
      .then((result) => {
        const data = result?.acf;
        setDataAnnouncement(data?.group2[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        idFeaturePost = data?.group3[0]?.post_id;

        const getFeaturePost = async () => {
          const getIDFeaturePost = await getFullEpisode(idFeaturePost);
          setDataFeaturePost(getIDFeaturePost);
          setIsFeaturePost(data?.group3[0]?.display);
        };
        getFeaturePost();
      });
  }, []);

  const items = [
    {
      order: '0',
      item: (
        <>
          <div
            className={`${router.query.tribe && !isMobile ? '' : 'hidden'} ${
              activeTribeTab === 'tribe' && 'hidden'
            } my-4 p-2 mb-2 bg-white dark:bg-gray-900 rounded-2xl sm:rounded-3xl sm:rounded-0`}
          >
            <div className="h-auto p-2 flex">
              <div className="mx-2">
                <Avatar
                  name={user ? user?.username : ''}
                  data-testid={user ? user?.avatar : ''}
                  bg="$mode.400"
                  width={40}
                  height={40}
                  src={user ? user?.avatar : ''}
                  author={user}
                  mr={1}
                />
              </div>
              <div
                className="w-full rounded-full border-2 border-gray-100 p-2 mx-2 cursor-pointer"
                role="button"
                onClick={() => {
                  if (!isLogin) {
                    router.push({
                      query: { ...router.query, page: 'signIn' },
                    });
                  } else {
                    postModal.onSelect(true);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    if (!isLogin) {
                      router.push({
                        query: { ...router.query, page: 'signIn' },
                      });
                    } else {
                      postModal.onSelect(true);
                    }
                  }
                }}
                tabIndex={0}
              >
                <input
                  className="text-greyChateau pointer-events-none dark:bg-gray-900 dark:text-white"
                  placeholder="Write something..."
                />
              </div>
              <input
                {...dropzone.getInputProps({
                  accept: LIMIT_UPLOAD_IMAGE_FILE_ACCEPT,
                })}
                data-testid="hidden-upload-input"
              />
              <div
                className="relative h-10 w-12 rounded-full border-gray-400 border-2 mr-2 cursor-pointer hidden"
                role="button"
                onClick={() => dropzone.open()}
                onKeyDown={() => dropzone.open()}
                tabIndex={0}
              >
                <SvgAddImage2
                  color="gray.500"
                  width="100%"
                  height="1.75rem"
                  pos="absolute"
                  margin="auto"
                  top={0}
                  bottom={0}
                />
              </div>
            </div>
            <div className="hidden flex justify-center">
              <div className="flex py-3 px-5 text-sm text-gray-500">
                <SvgStory fontSize="18px" mx={1} className="text-gray-700" />
                Folio
              </div>
              <div className="flex py-3 px-5 text-sm text-gray-500">
                <SvgMedia fontSize="18px" mx={1} className="text-gray-700" />
                Folio
              </div>
              <div className="flex py-3 px-5 text-sm text-gray-500">
                <SvgDiscussion
                  fontSize="18px"
                  mx={1}
                  className="text-gray-700"
                />
                Discussion
              </div>
              <div className="flex py-3 px-5 text-sm text-gray-500">
                <SvgPoll fontSize="18px" mx={1} className="text-gray-700" />
                Poll
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      order: dataAnnouncement?.order || '1',
      item: (
        <>
          {' '}
          {newFeedUpdates && dataAnnouncement?.display && (
            <AnnouncementFeed dataAnnouncement={dataAnnouncement} />
          )}
        </>
      ),
    },
    {
      order: '2',
      item: (
        <>
          {isFetching ? (
            <></>
          ) : (
            <>
              {isLogin && newFeedUpdates?.length === 0 && (
                <Box
                  textAlign="center"
                  py={{ base: '40px', md: '64px' }}
                  px="24px"
                  bgColor="white"
                  borderRadius={{ base: '0px', md: '16px', sm: '16px' }}
                  boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06)"
                >
                  <Box w="100%" mb="16px">
                    <SvgBoxStart fontSize="60px" margin="0 auto" />
                  </Box>
                  <Text
                    fontWeight="400"
                    color="gray.500"
                    lineHeight="20px"
                    fontSize="sm"
                  >
                    Updates is where you find what recent folios from what you
                    follow.
                  </Text>
                  <Text
                    pb={{ base: '16px', md: '24px' }}
                    fontWeight="400"
                    color="gray.500"
                    lineHeight="20px"
                    fontSize="sm"
                  >
                    Explore to discover creators and projects to follow and stay
                    in the know!
                  </Text>
                  <Link prefetch={false} href="/">
                    <div className="cursor-pointer">
                      <Text
                        fontWeight="700"
                        color="blue.500"
                        lineHeight="18px"
                        fontSize="sm"
                        display="inline"
                        mr="4px"
                      >
                        Yes, let me Explore
                      </Text>
                      <SvgArrowRightV4
                        _hover={{ cursor: 'pointer' }}
                        w="16px"
                        h="16px"
                        display="inline"
                      />
                    </div>
                  </Link>
                </Box>
              )}
            </>
          )}
        </>
      ),
    },
    {
      order: dataFeaturePost?.order || '3',
      item: (
        <>
          {(newFeedUpdates?.length !== 0 || !isLogin) &&
            !router.query.tribe &&
            isFeaturePost &&
            dataFeaturePost && <AgoraFeatured recentFeed={dataFeaturePost} />}
        </>
      ),
    },
    {
      order: '4',
      item: (
        <>
          {' '}
          {isFetching ? (
            <></>
          ) : (
            <>
              {!isLogin && (
                <Box
                  textAlign="center"
                  p={{ base: '40px 24px', md: '40px' }}
                  bgColor="white"
                  className="rounded-3xl"
                  borderRadius={{ base: '0px', md: '16px', sm: '16px' }}
                  boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06)"
                >
                  <Box w="100%" mb="16px">
                    <SvgDoorNonLogin fontSize="60px" margin="0 auto" />
                  </Box>
                  <Text
                    pb="16px"
                    fontWeight="500"
                    color="gray.700"
                    lineHeight="24px"
                    fontSize="md"
                  >
                    Sign in to see what you follow
                  </Text>
                  <Text
                    fontWeight="500"
                    color="gray.500"
                    lineHeight={{ base: '18px', md: '20px' }}
                    fontSize={{ base: 'xs', md: 'sm' }}
                  >
                    Updates is where you find what recent folios from what you
                    follow.
                  </Text>
                  <Text
                    pb="24px"
                    fontWeight="500"
                    color="gray.500"
                    lineHeight={{ base: '18px', md: '20px' }}
                    fontSize={{ base: 'xs', md: 'sm' }}
                  >
                    Sign in or create an account, and stay in the know!
                  </Text>
                  <Button
                    fontWeight="700"
                    color="white"
                    lineHeight="18px"
                    fontSize="sm"
                    mr="4px"
                    p="7px 28px"
                    borderRadius="full"
                    onClick={() => {
                      signInModal.onOpen();
                      window.history.replaceState('', '', '?signin=true');
                    }}
                    h={{ base: '32px' }}
                  >
                    Sign in
                  </Button>
                  <Text
                    fontWeight="500"
                    color="blue.500"
                    lineHeight="20px"
                    fontSize="sm"
                    mt="8px"
                    onClick={() => {
                      signInModal.onOpen();
                      window.history.replaceState('', '', '?signin=false');
                    }}
                    cursor="pointer"
                  >
                    Sign up
                  </Text>
                </Box>
              )}
            </>
          )}
        </>
      ),
    },
    {
      order: '5',
      item: (
        <>
          {' '}
          {!newFeedUpdates ? (
            <>
              {[0, 1, 2, 3, 4].map((i) => (
                <AgoraFeed
                  key={`loop${i}`}
                  recentFeed={{ skeleton: true, postType: true }}
                  refetch={refetchFeed}
                />
              ))}
            </>
          ) : (
            <>
              {activeTribeTab === 'tribe' &&
                newFeedUpdates &&
                newFeedUpdates?.map((recentFeed) => (
                  <AgoraFeed
                    key={
                      recentFeed.contentID ||
                      recentFeed.reppl?.contentID ||
                      recentFeed.id
                    }
                    refetch={refetchFeed}
                    recentFeed={recentFeed}
                  />
                ))}
              {activeTribeTab !== 'tribe' &&
                newFeedUpdates?.length > 0 &&
                newFeedUpdates?.map((recentFeed) => (
                  <GeneralFeed
                    key={
                      recentFeed.contentID ||
                      recentFeed.reppl?.contentID ||
                      recentFeed.id
                    }
                    recentFeed={recentFeed}
                    isEdit={openEditModal}
                    isDelete={deleteGeneral}
                  />
                ))}
            </>
          )}
        </>
      ),
    },
  ];
  useEffect(() => {
    setNewFeedUpdates(mainFeedLoad?.feedEpisodes);
  }, [mainFeedLoad]);

  const sortOrderUpdatesFeed = items.sort((a, b) => {
    return Number(a?.order) - Number(b?.order);
  });

  const tribeTabHandler = (event) => {
    if (event === 2) {
      setActiveTribeTab('community');
    } else {
      setActiveTribeTab('tribe');
    }
    if (event.target?.value === undefined) {
      setTribeTab(event);
    } else {
      setTribeTab(+event.target.value);
    }
  };

  function LeftSide() {
    return (
      <>
        <Box
          mx={isTablet ? '0' : '8px'}
          width={isMobile ? 'clamp( 332px, 544px, 624px )' : '332px'}
          minWidth="332 !important"
        >
          {isFetching ? (
            <Box
              className="bg-white min-h-0 p-6 sm:p-6 my-4 dark:bg-gray-900 mb-6"
              boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06)"
              textAlign="center"
              mx={{ base: '0', md: '16px', sm: '16px' }}
              borderRadius={{ base: '0', md: '16px', sm: '16px' }}
              minWidth="300px"
            >
              <div>
                <SkeletonCircle width={56} height={56} margin="auto" />
                <Skeleton height="16px" mt="12px" mb="8px" />
                <SkeletonText />
              </div>
            </Box>
          ) : (
            <>
              {isLogin ? (
                <Box
                  className="bg-white min-h-0 p-6 sm:p-6 my-4 dark:bg-gray-900 mb-6"
                  boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06)"
                  textAlign="center"
                  mx={{ base: '0', md: '16px', sm: '16px' }}
                  borderRadius={{ base: '0', md: '16px', sm: '16px' }}
                  minWidth="300px"
                >
                  <div>
                    <Link prefetch={false} href={`/${user.username}`}>
                      <div className="cursor-pointer">
                        <Avatar
                          name={user.username}
                          data-testid={user.avatar}
                          bg="$mode.400"
                          width={56}
                          height={56}
                          src={imageOptimize({
                            url: user.avatar,
                            imgContainer: 'avatar',
                          })}
                          author={user}
                          mr={1}
                        />
                      </div>
                    </Link>
                    <Text
                      className="text-gray-900 font-semibold leading-5 pt-3 pb-2 dark:text-white"
                      lineHeight="18px"
                    >
                      {getProfileFullNameOrUsername(profile || user)}
                    </Text>
                    <div className="text-gray-500 font-medium text-xs">
                      {profile?.about}
                    </div>
                    <div className="flex relative pt-2 justify-center">
                      <Text
                        className="text-gray-900 font-medium text-xs dark:text-white"
                        lineHeight="18px"
                      >
                        {followers || 0}{' '}
                        {followers > 1 ? 'Followers' : 'Follower'}
                      </Text>
                      <span className="text-gray-900 font-medium text-sm mx-2 dark:text-white">
                        •
                      </span>
                      <Text
                        className="text-gray-900 font-medium text-xs dark:text-white"
                        lineHeight="18px"
                      >
                        {creations || 0}{' '}
                        {creations > 1 ? 'Creations' : 'Creation'}
                      </Text>
                    </div>
                  </div>
                </Box>
              ) : (
                <Box
                  className="bg-white min-h-0 p-6 sm:p-6 my-4 dark:bg-gray-900 mb-6"
                  boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06)"
                  textAlign="center"
                  mx={{ base: '0', md: '16px', sm: '16px' }}
                  borderRadius={{ base: '0px', md: '16px', sm: '16px' }}
                  minWidth="300px"
                >
                  <div className="flex font-semibold leading-5 pb-4 text-gray-900 relative dark:text-white flex-col">
                    <span>{t('Join 1TM')}</span>
                  </div>
                  <div>
                    <Text
                      className="text-sm"
                      lineHeight="20px"
                      fontWeight="500"
                      color="gray.500"
                    >
                      Sign up to start following and keep you posted.
                    </Text>
                    <button
                      type="button"
                      className="bg-blue-500 text-white rounded-full p-2 w-full mt-4 font-bold"
                      onClick={() => {
                        window.history.replaceState('', '', '?signin=true');
                        signInModal.onOpen();
                      }}
                    >
                      Sign In
                    </button>
                  </div>
                </Box>
              )}
            </>
          )}
          {/* {isLogin && participated?.length > 0 && (
            <AgoraMultiTab
              windowTitle="My Spaces"
              tablist={participated}
              path
              withAvatar
              withEpcount
              isFetching={isFetching}
            />
          )} */}
          <AgoraMultiTab
            windowTitle="Recent Visits"
            tabIndex={recentTab}
            tabHandler={handleRequests}
            tabs={recentList}
            tablist={recent}
            withAvatar
            withSubtext
            isFetching={isFetching}
          />
          {/* {!router.query.tribe && isLogin && (
            <AgoraMultiTab
              windowTitle="Your Following"
              path
              tabIndex={recentFollowing}
              tabHandler={followingRequests}
              tabs={followingList}
              tablist={following}
              withAvatar
              withSubtext
              isFetching={isFetching}
              // isLink="/following"
            />
          )} */}
          {!router.query.tribe && isLogin && (
            <AgoraMultiTab
              windowTitle="Following Tribes"
              path
              tabIndex={recentTribeFollowing}
              tabHandler={tribeFollowingRequests}
              tabs={trendingTab}
              tablist={tribeFollowing}
              withFollowPost
              isFetching={isFetching}
            />
          )}
        </Box>
      </>
    );
  }

  return (
    <Box px={{ base: '0', md: '16px', sm: '16px' }} pb="88px">
      <SignInModal
        modal={{
          ...signInModal,
          onClose: () => {
            router.push({ query: { ...router.query, page: '' } });
            signInModal.onClose();
          },
        }}
      />
      {router.query.tribe && (
        <Tabs
          index={tribeTab}
          onChange={tribeTabHandler}
          variant="line"
          width="max-content"
          className="m-auto pt-12 pb-6"
          colorScheme="black"
        >
          <TabList>
            <Tab
              className="font-semibold"
              height="50px"
              color="$mode.400"
              borderColor="#f3f3f4"
              _selected={
                colorMode === 'light'
                  ? { color: '$mode.900', borderColor: '#242526' }
                  : { color: '#fff', borderColor: '#fff' }
              }
            >
              News
            </Tab>
            <Tab
              height="50px"
              className="font-semibold"
              color="$mode.400"
              borderColor="#f3f3f4"
              _selected={
                colorMode === 'light'
                  ? { color: '$mode.900', borderColor: '#242526' }
                  : { color: '#fff', borderColor: '#fff' }
              }
            >
              Most enthused
            </Tab>
            {/* <Tab
              height="50px"
              className="font-semibold"
              color="$mode.400"
              borderColor="#f3f3f4"
              _selected={
                colorMode === 'light'
                  ? { color: '$mode.900', borderColor: '#242526' }
                  : { color: '#fff', borderColor: '#fff' }
              }
            >
              Community
            </Tab> */}
          </TabList>
        </Tabs>
      )}
      <div className="mobile-tabs w-full flex justify-center md:hidden">
        <LinkTabs
          marginTop="32px"
          marginBottom="10px"
          arrowBg="transparent"
          width="max-content"
        >
          <LinkTab
            href={`/feed${router.query.tribe ? `/${router.query.tribe}` : ''}`}
          >
            <span className="text-sm">{t('Creations')}</span>
          </LinkTab>
          <LinkTab
            href={`/feed${
              router.query.tribe ? `/${router.query.tribe}` : ''
            }?posts=trending`}
          >
            <span className="text-sm">{t('Trending')}</span>
          </LinkTab>
          <LinkTab
            href={`/feed${
              router.query.tribe ? `/${router.query.tribe}` : ''
            }?posts=activities`}
          >
            <Text className="text-sm">{t('Activities')}</Text>
          </LinkTab>
        </LinkTabs>
      </div>
      <div className="flex justify-center">
        {((!isTablet && !isMobile) ||
          (isMobile && router.query.posts === 'activities')) && <LeftSide />}
        {/* left component */}

        {(!isTablet || !isMobile || (isMobile && !router.query.posts)) && (
          <Box
            className="mx-0 sm:mx-2 w-full min-max-width-460-624"
            borderRadius={{ base: '0px', md: '16px', sm: '16px' }}
            px={{ base: '0', md: '0', sm: '6.4%' }}
            // ml={isTablet && isMobile ? '24px' : '8px'}
            ml={{ base: '0px', md: '24px', sm: '0px', lg: '8px' }}
          >
            {sortOrderUpdatesFeed?.map((i) => (
              <Box key={i?.order}>{i?.item}</Box>
            ))}
            {isLogin && newFeedUpdates?.length > 0 && nextPageFeed && (
              <Box
                textAlign="center"
                py={{ base: '40px', md: '36px' }}
                bgColor="white"
                borderRadius={{ base: '0px', md: '16px', sm: '16px' }}
                boxShadow="0px 200px 200px rgba(0, 0, 0, 0.06)"
              >
                <Box w="100%" mb="16px">
                  <SvgCameraV1 fontSize="60px" margin="0 auto" />
                </Box>
                <Text
                  pb="16px"
                  fontWeight="600"
                  color="gray.500"
                  lineHeight="20px"
                  fontSize="md"
                  px={{ base: '24px', md: '106.5px' }}
                >
                  Want more?
                </Text>
                <Text
                  fontWeight="500"
                  color="gray.500"
                  lineHeight="20px"
                  fontSize="sm"
                  px={{ base: '24px', md: '106.5px' }}
                >
                  What you see here could be only a small portion of 1TM!
                </Text>
                <Text
                  pb={{ base: '16px', md: '24px' }}
                  fontWeight="500"
                  color="gray.500"
                  lineHeight="20px"
                  fontSize="sm"
                  px={{ base: '24px', md: '106.5px' }}
                >
                  Explore to find more inspirations.
                </Text>
                <Link prefetch={false} href="/">
                  <div className="cursor-pointer">
                    <Text
                      fontWeight="700"
                      color="blue.500"
                      lineHeight="18px"
                      fontSize="sm"
                      display="inline"
                      mr="4px"
                    >
                      Yes, let me Explore
                    </Text>
                    <SvgArrowRightV4
                      _hover={{ cursor: 'pointer' }}
                      w="16px"
                      h="16px"
                      display="inline"
                    />
                  </div>
                </Link>
              </Box>
            )}

            <TutorialChecklistModal
              username={user.firstName || user?.username}
              user={user?.username}
              modal={{ ...tutorialChecklistModal }}
            />

            <div className="my-4">
              <div className="w-full relative pb-4 hidden">
                <Select
                  value={value}
                  onChange={handleChange}
                  p={0}
                  w={100}
                  variant="unstyled"
                  fontSize="14px"
                  fontWeight="bold"
                  textStyle="labelLight"
                  color="$mode.400"
                  minWidth={{ base: '107px', md: '90px' }}
                  cursor="pointer"
                  position="absolute"
                  right={2}
                >
                  <option value="Recency">Recency</option>
                  <option value="Unread">Unread</option>
                </Select>
              </div>
              {/* <div className="w-full p-4 block sm:hidden">
              <Select
                width="100%"
                border="none"
                bg="white"
                icon={<SvgSortDesc />}
              >
                <option value="Most Enthused">Most Enthused</option>
                <option value="Most Enthused">Most Enthused</option>
                <option value="Most Enthused">Most Enthused</option>
                <option value="Most Enthused">Most Enthused</option>
                <option value="Most Enthused">Most Enthused</option>
                <option value="Most Enthused">Most Enthused</option>
              </Select>
            </div> */}
            </div>
            <div ref={creationObserverRef} />
          </Box>
        )}
        {/* center component */}
        {(!isMobile || (isMobile && router.query.posts === 'trending')) && (
          <Box
            style={{
              minWidth: '332px',
            }}
            mx={{ base: '0', md: '8px', sm: '8px' }}
            width={isMobile ? 'clamp( 300px, 544px, 624px )' : '300px'}
          >
            {router.query.tribe && (
              <Box
                backgroundColor="white"
                borderRadius={{ base: '0px', md: '16px', sm: '16px' }}
                mx={{ base: '0', md: '16px', sm: '16px' }}
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06)"
              >
                <div className="min-h-0 p-6 sm:p-5 my-4 mb-6 dark:bg-gray-900">
                  <div className="flex font-bold pb-2 text-gray-900 relative dark:text-white">
                    <span>{routeData}</span>
                  </div>
                  <div className="flex mb-4">
                    <span className="text-gray-700 font-medium text-sm">
                      {getTopicData('followersCount')} followers
                    </span>
                    <span
                      style={{ lineHeight: '0' }}
                      className="text-gray-300 mx-2 my-auto text-xl"
                    >
                      •
                    </span>
                    <span className="text-gray-700 font-medium text-sm">
                      {getTopicData('creationsCount')} creations
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500 dark:text-white">
                      Tribes are topic based communities that connect on a
                      common topic. They are a hub for cross vertical
                      collaboration and enable creators to connect and
                      collaborate within the community!
                    </span>
                    <button
                      type="button"
                      className="rounded-full p-3 w-full mt-4"
                      style={{
                        height: '41px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderStyle: 'solid',
                        color: `${
                          isTribeFollow || isTribeFollowInData
                            ? '#0278E4'
                            : 'white'
                        }`,
                        backgroundColor: `${
                          isTribeFollow || isTribeFollowInData
                            ? 'unset'
                            : '#0278E4'
                        }`,
                        borderColor: `${
                          isTribeFollow || isTribeFollowInData
                            ? '#D3D6D8'
                            : 'unset'
                        }`,
                        borderWidth: '2px',
                        transition: '200ms',
                      }}
                      onClick={tribeFollow}
                    >
                      <span className="font-bold">
                        {isTribeFollow || isTribeFollowInData
                          ? 'Following'
                          : 'Follow'}
                      </span>
                    </button>
                  </div>
                </div>
              </Box>
            )}
            {isTablet && !isMobile && <LeftSide />}
            <AgoraMultiTab
              windowTitle="Trending Tribes"
              tablist={trendingTribes}
              tabIndex={tribeIndex}
              tabHandler={tribeHandler}
              tabs={trendingTab}
              withPostCount
              withRanking
              withFollow
              isFetching={isFetching}
              isFlex={false}
              path
              onFollow={async (data) => {
                const getdata = data.value ? data.value : data.title;
                const followValue = {
                  targetID: getdata,
                  group: tribeIndex === 1 ? 'CATEGORY' : 'TAG',
                };
                if (!isLogin) {
                  window.history.replaceState('', '', '?signin=true');
                  signInModal.onOpen();
                  return;
                }
                if (data.followed) {
                  deleteFollow(followValue);
                } else {
                  createFollow(followValue);
                }
                updateFollow('tribe', data?.title, data?.followed);
              }}
            />
            {!router.query.tribe && (
              <>
                <AgoraMultiTab
                  windowTitle="Trending Users"
                  tablist={topCreators}
                  withSubtext
                  withRanking
                  withAvatar
                  withFollow
                  isAlgolia
                  isFetching={isFetching}
                  path
                  onFollow={async (data) => {
                    try {
                      followUser({
                        targetID: data?.userID,
                        group: 'USER',
                        follow: !data?.followed,
                      });
                      if (isLogin) {
                        updateFollow('user', data?.userID, data?.followed);
                      }
                    } catch (err) {
                      logger.error(err);
                    }
                  }}
                />
                {/* <AgoraMultiTab
                  windowTitle="Trending Spaces"
                  tablist={reppls}
                  isFetching={isFetching}
                  withSubtext
                  withRanking
                  withAvatar
                  path
                /> */}
              </>
            )}
          </Box>
        )}
        {/* right component */}

        <style jsx global>{`
          @media (min-width: 640px) {
            .min-max-width-460-624 {
              min-width: 320px;
              max-width: 624px;
            }
          }
          .mobile-tabs > .chakra-stack > span {
            display: none;
          }
          .mobile-tabs > .chakra-stack::before {
            display: none;
          }
          .mobile-tabs > button {
            display: none;
          }
          .mobile-tabs .border-tab-mobile {
            padding-left: 16px !important;
            padding-right: 16px !important;
          }
        `}</style>
        {postModal.selected && (
          <div className="hidden">
            <AgoraPostModal
              {...postModal.modal}
              user={user}
              contentID={currId}
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
              imgProgress={progress}
              imgUploading={isUploading}
              hasCategory={isCat && `${router.query.tribe}`}
              hasHash={!isCat && router.query.tribe && `${router.query.tribe}`}
            />
          </div>
        )}
        {editModal.selected && (
          <div className="hidden">
            <AgoraPostModal
              {...editModal.modal}
              user={user}
              contentID={articleToEdit['contentID']}
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={[
                ...articleToEdit['content'].uploadedFiles,
                ...uploadedFiles,
              ]}
              editContent={articleToEdit}
              imgProgress={progress}
              imgUploading={isUploading}
            />
          </div>
        )}
        {deleteModal.selected && (
          <DeleteModal
            {...deleteModal.modal}
            title={`${t('sureDelete')}?`}
            onOk={() => deleteGeneralPost()}
            okBtnText="Delete"
          />
        )}
        {warningModal.selected && (
          <WarningModal
            {...warningModal.modal}
            title={t('postNotPublished')}
            text={t('allEditsMade')}
            onOk={() => setIsLeave(true)}
            onDecline={() => postModal.onSelect(true)}
            okBtnText={t('Leave')}
            cancelBtnText={t('backtoEdit')}
          />
        )}
      </div>
    </Box>
  );
}

function generateUniqueID() {
  const day = new Date();
  return day.getTime();
}
