export enum ArticlePostType {
  STORY = 'STORY',
  MEDIA = 'MEDIA',
  GENERAL = 'GENERAL',
}

export enum ArticleSource {
  STUDIO = 'STUDIO',
  REPPL = 'REPPL',
}

export type EnthuseObjectTypes =
  | 'STUDIO'
  | 'COMMENT'
  | 'EPISODE'
  | 'TOPIC'
  | 'GENERAL POST'
  | 'CREATIVE_ASSET'
  | 'NFT';

export type CommentAction =
  | 'COMMENT'
  | 'STUDIO'
  | 'EPISODE'
  | 'GENERAL POST'
  | 'CREATIVE_ASSET';
