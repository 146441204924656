import Link from 'next/link';
import { Avatar } from 'src/components/avatar';
// import Share from 'src/components/common/share/share';
import SvgIcSettings from 'src/components/icons/ic-settings';
import Dropdowns from 'src/components/common/dropdowns/dropdowns';
import is from 'is_js';
import { isDesktop } from 'react-device-detect';
import { imageOptimize } from 'src/service/image';

interface Props {
  data: Object;
  className?: string;
  fns?: Array<string>;
}

const Collection = ({ data, className = '', fns = [] }: Props) => {
  const link =
    data['areaType'] === 'REPPL'
      ? `/space/${data['areaID']}/collections/${data['uuid']}`
      : '';

  return (
    <section
      className={`relative overflow-hidden ${
        isDesktop ? 'card collection-card' : 'w-full'
      } rounded mt-4 mb-2 flex-shrink-0 ${className}`}
    >
      <div
        className={`rounded overflow-hidden collection-imgs-${data['alternateImages']?.length} h-full`}
      >
        {data['alternateImages']?.map((img, key) => {
          const mkey = `key_${key}`;
          return (
            <div key={mkey} className="img-wrap items-center">
              <img
                className="object-cover h-full w-full"
                alt=""
                src={imageOptimize({ url: img, imgContainer: 'postCover' })}
              />
            </div>
          );
        })}
      </div>
      <div className="text-white absolute w-full h-full left-0 top-0 bg-black bg-opacity-40">
        <Link href={link} prefetch={false}>
          <a className="block p-4">
            <div className="text-sm font-bold mb-1">{data['name']}</div>
            <div className="text-xs font-medium">
              {new Intl.NumberFormat().format(data['numOfPost'] || 0)} creations
            </div>
          </a>
        </Link>
        {is.not.empty(fns) && isDesktop && (
          <div className="absolute right-1 top-3 z-50">
            <Dropdowns
              className="text-sm"
              items={[fns]}
              dataID={data['id']}
              buttonComponent={
                <SvgIcSettings
                  fontSize="20px"
                  className="inline mr-2 align-text-bottom"
                />
              }
            />
          </div>
        )}
        <div className="flex justify-between absolute bottom-0 w-full p-4">
          <Link href={link} prefetch={false}>
            <a className="block flex">
              <Avatar width={30} height={30} />
              <div className="ml-2">
                <span className="font-bold block text-sm">
                  {data['userName'] || '1TM Member'}
                </span>
                <span className="font-medium block text-xs">
                  {data['role'] || 'Creator'}
                </span>
              </div>
            </a>
          </Link>
          {/* <Share link={link} /> */}
        </div>
      </div>
    </section>
  );
};
export default Collection;
