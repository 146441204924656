import { Flex, usePrevious } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { CacheKey } from 'src/constants/cache-key';
import { useScript } from 'src/hooks/use-script';
import { OEmbed } from 'src/lib/o-embed';

export function Tweet(props: { url: string }) {
  const status = useScript('https://platform.twitter.com/widgets.js');
  const previousStatus = usePrevious(status);
  const { data } = useQuery<OEmbed>(
    [CacheKey.twitterTweet, props.url],
    () =>
      fetch(
        `/api/fetch-url?url=${encodeURIComponent(props.url)}&type=twitter`
      ).then((res) => res.json()),
    {
      onSuccess: () => {
        // @ts-ignore
        window.twttr?.widgets?.load?.();
      },
      enabled: !!props.url,
      refetchInterval: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (previousStatus !== status && status === 'ready') {
      // @ts-ignore
      window.twttr?.widgets?.load?.();
    }
  }, [status, previousStatus]);

  if (data && status === 'ready') {
    return (
      <Flex
        justifyContent="center"
        dangerouslySetInnerHTML={{ __html: data.html }}
      />
    );
  }

  return <></>;
}
