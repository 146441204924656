import Checkbox from '../Checkbox';
import { toRem } from '../../tools';

const parts = ['control', 'label'];

function baseStyleControl(props) {
  const { control } = Checkbox.baseStyle(props);

  return {
    ...control,
    borderRadius: 'full',
    _checked: {
      ...control['_checked'],
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: '50%',
        h: '50%',
        borderRadius: '50%',
        bg: 'currentColor',
      },
    },
  };
}

const baseStyle = (props) => {
  return {
    label: Checkbox.baseStyle(props).label,
    control: baseStyleControl(props),
  };
};

const sizes = {
  sm: {
    control: { width: toRem(12), height: toRem(12) },
    label: { fontSize: 'xs' },
  },
  md: {
    control: { width: 3, height: 3 },
    label: { fontSize: 'sm' },
  },
  lg: {
    control: { width: toRem(20), height: toRem(20) },
    label: { fontSize: 'md' },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'blue',
};

export default {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
