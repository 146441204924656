/**
 * Internal dependencies
 */
import { axios } from 'src/lib/axios';
import { RestResponse } from 'src/types';

interface User {
  userID: number;
  username: string;
  profileImgURL: string;
  firstName: string;
  lastName: string;
  message: string;
  isFollowed: boolean;
}

interface Author extends Omit<User, 'message'> {
  userTitle: string;
  motto: string;
  about: string;
  socialMedia: null;

  count: {
    follower: number;
    episode: number;
    studio: number;
    episodeEnthuse: number;
    studioEnthuse: number;
  };
}

export interface FullFolio {
  source: string;
  SourceID: string;
  isInDraftReppl: boolean;
  contentID: string;
  roleInReppl: '' | 'STARTER' | 'EDITOR' | 'CREATOR' | 'NON_FOLLOWER';
  slug: string;
  title: string;
  coverImgURL: string;
  description: string;
  content: {};
  draft: boolean;
  publishStatus: string;
  classType: string;
  commentable: boolean;
  pinned: boolean;
  official: boolean;
  copyright: string;
  copyrightType: string;
  hasSensitiveContent: true;
  status: string;
  categories: string[];
  tags: string[];
  affiliatedBrands: string[];
  postType: string;
  createdAt: string;
  updatedAt: string;
  author: Author;
  enthuseCount: number;
  commentCount: number;
  coAuthors: User[];
  contributors: User[];
  comments: [];
  isEnthused: false;
}

export const fetchFullFolio = (id: string) => {
  return axios
    .get<RestResponse<FullFolio>>(`/post/${id}`)
    .then((res) => res.data)
    .then((res) => {
      if (res.code === 1000) {
        return res.data;
      }
      // TODO: handle Error
    });
};
