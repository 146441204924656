import * as React from 'react';
import { Svg } from '../svg';

function SvgArrowLeft({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M15.705 16.59L11.125 12l4.58-4.59L14.295 6l-6 6 6 6 1.41-1.41z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowLeft);
export default ForwardRef;
