import cn from 'classnames';
import { useState, useEffect, useReducer, useContext } from 'react';
import { ethers } from 'ethers';
import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { userWalletNonce, userWalletLogin } from 'src/app/auth/service';
import { Tooltip, useDisclosure } from '@chakra-ui/react';

import SvgWalletConnect from 'src/components/icons/wallet-connect';
import SvgMetaMask from 'src/components/icons/meta-mask';
import SvgClose from 'src/components/icons/close';
import { setAuthToken, setSentryUser } from 'src/app/auth/use-auth';
import { useRouter } from 'next/router';

import {
  userReducer,
  initState,
  UserContext,
  UserContextType,
} from 'src/contexts/UserContext';
import { useIsTablet, useIsMobile } from 'src/hooks/use-is-mobile';
import { fetchWallet } from 'src/app/code/service';

import SignInModal from 'src/app/article/sign-in-modal';
import { useModal } from 'src/hooks/use-modal';
import ErrorModal from 'src/app/code/ErrorModal/ErrorModal';
import styles from './Sign_in_wallet.module.scss';

function SignInWithWallet() {
  const router = useRouter();
  const noWalletFoundModal = useDisclosure();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const [signatures, setSignatures] = useState<any[]>([]);
  const [wc, setWc] = useState<any>();
  const [web3, setWeb3] = useState<any>();
  const [currUsername, setCurrUsername] = useState<string>();
  const [state, userDispatch] = useReducer(userReducer, initState);
  const [walletError, setWalletError] = useState<boolean>(false);

  const { isLogin } = useContext(UserContext) as UserContextType;
  const signinModal = useModal();

  useEffect(() => {
    if (!isLogin) {
      signinModal.onSelect(true);
    } else {
      signinModal.modal.onClose();
    }
  }, [isLogin]);

  function checkIsTabletMobile(
    baseSize: any,
    tabletSize?: any,
    mobileSize?: any
  ) {
    if (isMobile) {
      return mobileSize || tabletSize;
    }
    if (isTablet) {
      return tabletSize;
    }
    return baseSize;
  }

  useEffect(() => {
    if (wc) {
      wc.disconnect();
    }
    setSignatures([]);
  }, []);

  useEffect(() => {
    if (currUsername) {
      router.push(`${currUsername}`);
    }
  }, [currUsername]);

  useEffect(() => {
    const sigContinue = async () => {
      const newUserWallet = await userWalletLogin({
        signature: signatures[signatures.length - 1].signature,
        publicAddress: signatures[signatures.length - 1].address,
      });
      if (newUserWallet.code === 1000) {
        const { data: resData } = newUserWallet;
        setCurrUsername(resData.data.username);
        setAuthToken(resData.token);
        setSentryUser({
          id: resData.data.id,
          username: resData.data.username,
          firstName: resData.data.firstName,
          lastName: resData.data.lastName,
          email: resData.data.email,
          userRole: resData.data.userRole,
        });
        userDispatch({ type: 'FETCH_USER', data: resData.data });
        console.log(state);
      }
    };
    if (signatures && signatures.length > 0) {
      if (
        signatures[signatures.length - 1].address &&
        signatures[signatures.length - 1].signature
      ) {
        sigContinue();
      }
    }
  }, [signatures]);

  const signMessage = async () => {
    try {
      if (!window.ethereum)
        throw new Error('No crypto wallet found. Please install it.');

      await window.ethereum.send('eth_requestAccounts');
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      await fetchWallet(address).then((code) => {
        if (code.data && code.data.username) {
          userWalletNonce(address).then(async (res) => {
            if (res) {
              const signature = await signer.signMessage(res.nonce);
              if (signature && address) {
                if (signatures && signatures.length > 0) {
                  setSignatures([...signatures, { signature, address }]);
                } else {
                  setSignatures([{ signature, address }]);
                }
              }
            }
          });
        } else {
          setWalletError(true);
        }
      });
    } catch (err) {
      noWalletFoundModal.onOpen();
    }
  };

  const walletConnectEnable = async () => {
    const walletConnectProvider = new WalletConnectProvider({
      infuraId: '117199ece0414112b97d54fbe01f6b49',
    });
    setWc(walletConnectProvider);
    await walletConnectProvider.enable();
    setWeb3(new Web3(walletConnectProvider as any));
  };

  useEffect(() => {
    const web3Continue = async () => {
      const currAddress = await web3.eth.getAccounts();
      await fetchWallet(currAddress[0]).then((code) => {
        if (code.data && code.data.username) {
          userWalletNonce(currAddress[0]).then((res) => {
            if (res) {
              walletConnectSign(res.nonce, currAddress[0]);
            }
          });
        } else {
          setWalletError(true);
        }
      });
    };
    if (web3) {
      web3Continue();
    }
  }, [web3]);

  const walletConnectSign = async (nonce, address) => {
    const rawMessage = nonce;
    const rawMessageLength = new Blob([rawMessage]).size;
    let message = ethers.utils.toUtf8Bytes(
      `\x19Ethereum Signed Message:\n${rawMessageLength}${rawMessage}`
    );
    message = ethers.utils.keccak256(message) as any;
    const params = [address, message];
    const newSig = await wc.connector.signMessage(params);
    const sig = {
      signature: newSig,
      address,
    };
    if (signatures && signatures.length > 0) {
      setSignatures([...signatures, sig]);
    } else {
      setSignatures([sig]);
    }
  };

  return (
    <>
      <div className={cn(styles.wallet_selection_container)}>
        <div
          className={cn(styles.wallet_button)}
          role="button"
          tabIndex={0}
          onClick={() => signMessage()}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              signMessage();
            }
          }}
        >
          <div className={cn(styles.wallet_button_icon)}>
            <SvgMetaMask style={{ height: '20px', width: '20px' }} />
          </div>
          <span className={cn(styles.wallet_button_text)}>MetaMask</span>
        </div>
        <div
          className={cn(styles.wallet_button)}
          role="button"
          tabIndex={0}
          onClick={() => walletConnectEnable()}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              walletConnectEnable();
            }
          }}
        >
          <div className={cn(styles.wallet_button_icon)}>
            <SvgWalletConnect style={{ height: '24px', width: '24px' }} />
          </div>
          <span className={cn(styles.wallet_button_text)}>WalletConnect</span>
        </div>
      </div>

      <div className={cn(styles.walletConnect_help_container)}>
        <span className={cn(styles.walletConnect_help_text)}>
          Stuck with WalletConnect?
        </span>
        <button
          type="button"
          onClick={() => {
            if (wc) {
              wc.disconnect();
              setSignatures([]);
            }
          }}
          className={cn(styles.walletConnect_reset_button)}
        >
          Reset
        </button>

        <Tooltip
          label="This happens when you leave the signup/in process without completing signature."
          placement="right"
          bg="#ffffff"
          color="$mode.700"
          borderRadius="4px"
          border="1px solid #E6E8E9"
          maxWidth="230px"
          fontSize="12px"
          p="12px"
          alignItems="center"
        >
          <img
            src="images/assets/sign-in-with-wallet/b4f8554033335a0d0bbdc3673db1e4d2.svg"
            alt="help icon"
            className={cn(styles.walletConnect_help_icon)}
          />
        </Tooltip>
      </div>

      {walletError && (
        <div
          className={`fixed text-gray-900 z-50 ${checkIsTabletMobile(
            'bottom-16 right-5',
            'top-0 w-full'
          )}`}
        >
          <div className={checkIsTabletMobile('py-2', '')}>
            <div
              style={{ maxWidth: '386px', maxHeight: '186px' }}
              className={`relative px-6 py-8 bg-white ${checkIsTabletMobile(
                'rounded-lg w-auto',
                'w-full'
              )}`}
            >
              <div
                role="button"
                className="absolute top-4 right-4 text-gray-700"
                tabIndex={0}
                onClick={() => {
                  setWalletError(false);
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    setWalletError(false);
                  }
                }}
              >
                <SvgClose height="16px" width="16px" />
              </div>
              <div style={{ color: '#FF6633' }} className="font-semibold">
                Unregistered Wallet
              </div>
              <div className="py-4 text0-sm text-gray-500 font-medium">
                This wallet is not yet connected to any 1TM account. Make sure
                this is the wallet you used on 1TM. (Please disconnect this
                wallet first)
              </div>
            </div>
          </div>
        </div>
      )}
      <ErrorModal
        onClose={noWalletFoundModal.onClose}
        isOpen={noWalletFoundModal.isOpen}
        heading="No wallet found"
        paragraph="Please make sure you have a wallet installed."
        cancelButtonText="Cancel"
        confirmButtonText="Go to MetaMask"
        confirmButtonAction={() => {
          window.open('https://metamask.io/', '_blank');
        }}
      />
      {signinModal.selected && (
        <SignInModal
          isOpen={signinModal.modal.isOpen}
          onOpen={signinModal.modal.onOpen}
          onClose={() => {}}
          onToggle={signinModal.modal.onToggle}
          getButtonProps={signinModal.modal.getButtonProps}
          getDisclosureProps={signinModal.modal.getDisclosureProps}
          isControlled={signinModal.modal.isControlled}
          handleClose={signinModal.modal.onClose}
          title="This content is password protected. To view it please enter your password below:"
        />
      )}
    </>
  );
}

export default SignInWithWallet;
