import {
  Box,
  Button,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { ElementRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { GeneralFeed } from 'src/app/explore/general-feed';

import { Article, FullArticle } from 'src/app/article/types';
import { ArticleDetail } from 'src/app/article/detail';
import { ArticleSource } from 'src/app/article/enum';
import { useArticleFullQuery } from 'src/app/article/hooks/use-article-query';
import ConfirmModal from 'src/app/article/confirm-modal';
import SubmissionModal from 'src/app/article/submission-modal';
import WarningModal from 'src/app/article/warning-modal';

import { FullFolio } from 'src/service/post';
import { useModal } from 'src/hooks/use-modal';
import * as ga from 'src/lib/ga';

import {
  approveSubmission,
  declineSubmission,
} from 'src/components/drafts-submissions/hooks';
import SvgCloseModal from 'src/components/icons/close-modal';

export function DetailModal({
  article,
  modal,
  isLoading,
  publish,
  id,
  submissionStatus = '',
  refetch,
  rid,
  editRouter,
  editButton,
  isManagerSubmission,
  currentLocation,
}: {
  id?: string;
  rid?: string;
  modal: UseDisclosureReturn;
  article: FullArticle;
  isLoading: boolean;
  publish?: boolean;
  submissionStatus?: string;
  refetch?: any;
  editRouter?: string;
  editButton?: string;
  isManagerSubmission?: boolean;
  currentLocation?: string;
}) {
  const modalRef = useRef<ElementRef<typeof ModalContent>>();
  const router = useRouter();

  function closeModal() {
    if (publish) {
      if (currentLocation === window.location.href) {
        router.push('/feed');
      } else {
        const historyState = window.history.state || {};

        const handleSearchPageUrl = (url) => {
          const currentSearchTerm = window.history.state?.searchTerm;
          if (url?.match(/\.*\/search\/\.*/)) {
            if (currentSearchTerm) {
              return url.replace(/search\/.*/i, `search/${currentSearchTerm}`);
            }
          }
          return '';
        };

        const newUrl = handleSearchPageUrl(currentLocation) || currentLocation;
        window.history.pushState(historyState, '', `${newUrl || '../feed'}`);
      }
    }
    modal.onClose();
  }

  useEffect(() => {
    const handlePopState = () => {
      if (modal.isOpen) {
        modal.onClose();
      }
    };
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [modal]);

  const confirmModal = useModal();
  const submissionModal = useModal();
  const { t } = useTranslation();
  const [currArticle, setCurrArticle] = useState<any>();
  // const [hasHistory, setHasHistory] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState<any>([
    t('confirmDeclineTitle'),
    'hidden',
    t('confirmDecline'),
  ]);
  const [submissionModalData, setSubmissionModalData] = useState<any>([
    t('declineTitle'),
    t('declineText'),
    t('declinePlaceHolder'),
    'declineSubmission',
    t('decline'),
    'true',
  ]);
  // const notExcemptedPages = () => {
  //   if (
  //     router.pathname === '/feed' ||
  //     router.pathname === '/feed/[tribe]' ||
  //     router.pathname === '/[username]/drafts-submissions' ||
  //     router.pathname === '/space/[rid]/submissions'
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };
  useEffect(() => {
    setSubmissionModalData(t('declineTitle'));
    // if (window.history.length > 2 && !notExcemptedPages()) {
    //   setHasHistory(true);
    // }
  }, []);
  useEffect(() => {
    if (article && !isLoading) {
      setCurrArticle(article);
    }
  }, [article, isLoading]);
  useEffect(
    function scrollToTopWhenOpeningModal() {
      window.scrollTo({
        top: window.scrollY,
      });
      if (id && modalRef.current) {
        modalRef.current.scrollTop = 0;
      }
    },
    [id, modalRef]
  );
  function modalChange(data) {
    const { username } = data.author;
    const { contentID } = data;
    const link = `/${username}/${contentID}`;
    router.push(link);
  }

  useEffect(() => {
    if (typeof currArticle === 'object') {
      if (Object.keys(currArticle).length > 0) {
        if (currArticle.classType === 'REPPL') {
          const data = {
            title: currArticle.title,
            location: `/space/${currArticle.sourceID}/episode/${currArticle.contentID}`,
            path: '/space/[rid]/episode/[eid]',
          };
          ga.manualpageview(data);
        } else {
          const data = {
            title: currArticle.title,
            location: `/${currArticle?.author?.username}/${currArticle.contentID}`,
            path: '/[username]/[sid]',
          };
          ga.manualpageview(data);
        }
      }
    }
  }, [currArticle, setCurrArticle]);

  // const statusData = {
  //   APPROVED: {
  //     title: 'Approved',
  //     color: 'text-seagreen-500',
  //   },
  //   SUBMITTED: {
  //     title: 'Pending review',
  //     color: 'text-gray-900',
  //   },
  //   UNPUBLISHED: {
  //     title: 'Unpublished',
  //     color: 'text-outrageousOrange-900',
  //   },
  //   PUBLISHED: {
  //     title: 'Published',
  //     color: 'text-seagreen-500',
  //   },
  //   UNLISTED: {
  //     title: 'Unlisted',
  //     color: 'text-outrageousOrange-500',
  //   },
  //   REMOVED: {
  //     title: 'Removed',
  //     color: 'text-outrageousOrange-500',
  //   },
  //   DECLINED: {
  //     title: 'Declined',
  //     color: 'text-outrageousOrange-500',
  //   },
  // };

  const submissionFunc = {
    approve: (data: any) => {
      const content = {
        eid: article?.contentID,
        rid,
        note: data,
      };
      approveSubmission(content);
      setConfirmModalData([
        t('confirmApproveTitle'),
        t('confirmApprove'),
        t('confirmApproveCancel'),
      ]);
      confirmModal.onSelect(currArticle);
    },
    decline: (data: any) => {
      const content = {
        eid: article?.contentID,
        rid,
        note: data,
      };
      declineSubmission(content);
      setConfirmModalData([
        t('confirmDeclineTitle'),
        'hidden',
        t('confirmDecline'),
      ]);
      // postReviews(eid, content);
      // userSubmission(rid, eid, 'REJECTED');
      confirmModal.onSelect(currArticle);
    },
  };

  const renderACtion = () => {
    if (
      editButton &&
      !isManagerSubmission &&
      id &&
      false // hide this button everywhere for now
      // router.pathname !== '/[username]' &&
      // router.pathname !== '/feed/[tribe]' &&
      // router.pathname !== '/feed' &&
      // router.pathname !== '/space/[rid]/topic/[tid]' &&
      // router.pathname !== '/[username]/[sid]' &&
      // router.pathname !== '/search/[squery]'
    ) {
      return (
        <div
          style={{ right: '70px' }}
          className="p-2.5 fixed top-6 right-10 z-50"
        >
          <div
            style={{ height: '32px' }}
            className="flex px-5 justify-between cursor-pointer items-center text-blue-500 font-semibold border rounded-3xl"
          >
            <a href={editRouter}>{editButton}</a> {}
          </div>
        </div>
      );
    }
    if (isManagerSubmission && submissionStatus === 'SUBMITTED') {
      return (
        <div
          style={{ right: '70px' }}
          className="flex p-2.5 fixed top-6 right-10 z-50"
        >
          <div
            style={{ height: '32px' }}
            className="flex px-5 justify-between items-center text-seagreen-500 font-semibold cursor-pointer border rounded-3xl"
          >
            Approve
          </div>
          <div
            style={{ height: '32px' }}
            className="flex ml-5 px-5 justify-between items-center text-outrageousOrange-500 cursor-pointer font-semibold border rounded-3xl"
          >
            Decline
          </div>
        </div>
      );
    }
  };
  return (
    <Modal
      isCentered
      autoFocus={false}
      {...modal}
      blockScrollOnMount
      motionPreset="none"
    >
      <ModalOverlay>
        {!isLoading && currArticle && (
          <ModalContent
            ref={modalRef}
            borderRadius={
              currArticle?.postType === 'GENERAL'
                ? { base: '0', lg: 'xl' }
                : '0'
            }
            maxWidth={currArticle?.postType === 'GENERAL' ? '624px' : '100vw'}
            width={
              currArticle?.postType === 'GENERAL'
                ? { base: '100%', lg: '94%' }
                : '100vw'
            }
            h={currArticle?.postType === 'GENERAL' ? 'auto' : '100vh'}
            maxHeight="100vh"
            paddingBottom={{ base: '58px', sm: '58px', lg: '0' }}
            overflowX="hidden"
          >
            {renderACtion()}
            {publish && (
              <button
                type="button"
                className="p-2.5 fixed top-6 right-6 z-50"
                style={{
                  background: 'rgba(36, 37, 38, 0.3)',
                  borderRadius: '22px',
                }}
                onClick={() => {
                  closeModal();
                }}
              >
                <SvgCloseModal fontSize="24px" />
              </button>
            )}
            {!publish && (
              <Box pos="absolute" left="24px" top="24px" zIndex="99">
                <Button
                  rounded="full"
                  size="sm"
                  variant="outline"
                  onClick={() => closeModal()}
                >
                  {t('Back to draft')}
                </Button>
              </Box>
            )}
            {currArticle?.postType !== 'GENERAL' ? (
              <ArticleDetail
                publish={publish}
                refetch={refetch}
                source={
                  currArticle?.source ||
                  currArticle?.classType ||
                  currArticle?.articleType
                }
                article={currArticle}
                author={currArticle?.author}
                isLoading={isLoading}
                id={currArticle?.contentID}
                handleModalChange={(e) => modalChange(e)}
                modal={modal}
              />
            ) : (
              <GeneralFeed recentFeed={currArticle} modal />
            )}
            {/* {submissionStatus !== '' && (
              <div className="w-full sticky bottom-0 z-50">
                <div className="w-full bg-white flex h-20 border-t border-gray-100 p-5">
                  <div
                    style={{ height: 'fit-content' }}
                    className={`whitespace-nowrap border rounded-full text-xs font-bold shadow py-1 px-3 mt-1 ml-5 ${statusData[submissionStatus].color}`}
                  >
                    {statusData[submissionStatus].title}
                  </div>
                  {submissionStatus === 'SUBMITTED' && (
                    <div className="flex absolute right-10">
                      <div
                        role="button"
                        tabIndex={0}
                        className="text-outrageousOrange-500 font-semibold m-auto px-4 mx-2"
                        onClick={() => {
                          setSubmissionModalData([
                            t('declineTitle'),
                            t('declineText'),
                            t('declinePlaceHolder'),
                            'decline',
                            t('decline'),
                            'true',
                          ]);
                          submissionModal.onSelect(true);
                        }}
                        onKeyDown={(e) => {
                          if (e.code === 'Endter') {
                            setSubmissionModalData([
                              t('declineTitle'),
                              t('declineText'),
                              t('declinePlaceHolder'),
                              'decline',
                              t('decline'),
                              'true',
                            ]);
                            submissionModal.onSelect(true);
                          }
                        }}
                      >
                        Decline
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        className="bg-blue-500 rounded-full border py-1 text-white font-semibold m-auto px-4 mx-2"
                        onClick={() => {
                          setSubmissionModalData([
                            t('approveTitle'),
                            t('approveText'),
                            t('approvePlaceHolder'),
                            'approve',
                            t('approve'),
                            'false',
                          ]);
                          submissionModal.onSelect(true);
                        }}
                        onKeyDown={(e) => {
                          if (e.code === 'Endter') {
                            setSubmissionModalData([
                              t('approveTitle'),
                              t('approveText'),
                              t('approvePlaceHolder'),
                              'approve',
                              t('approve'),
                              'false',
                            ]);
                            submissionModal.onSelect(true);
                          }
                        }}
                      >
                        Approve
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )} */}
            {submissionModal.selected && (
              <SubmissionModal
                {...submissionModal.modal}
                title={`${submissionModalData[0]}`}
                text={`${submissionModalData[1]}`}
                placeHolder={`${submissionModalData[2]}`}
                onOk={(e: any) =>
                  submissionFunc[`${submissionModalData[3]}`](e)
                }
                okBtnText={`${submissionModalData[4]}`}
                isDecline={submissionModalData[5] === 'true'}
              />
            )}
            {confirmModal.selected && (
              <ConfirmModal
                {...confirmModal.modal}
                title={`${confirmModalData[0]}`}
                okBtnText={`${confirmModalData[1]}`}
                cancelBtnText={`${confirmModalData[2]}`}
                article={currArticle}
                rid={rid}
              />
            )}
          </ModalContent>
        )}
      </ModalOverlay>
    </Modal>
  );
}

export function ArticleDetailModal({
  modal,
  id,
  source = '',
  publish,
  defaultArticle,
  submissionStatus = '',
  rid,
  username,
  episodePostType,
  articleStatus,
  isManagerSubmission,
  currentLocation,
}: {
  modal: UseDisclosureReturn;
  id?: string;
  rid?: string;
  source?: ArticleSource | any;
  publish?: boolean;
  defaultArticle?: Partial<Article | FullFolio>;
  submissionStatus?: string;
  username?: string;
  episodePostType?: string;
  articleStatus?: string;
  isManagerSubmission?: boolean;
  currentLocation?: string;
}) {
  const { data: article, isLoading, refetch, isError } = useArticleFullQuery(
    source,
    modal.isOpen && id
  );
  const warningModal = useDisclosure();
  const editRouter =
    episodePostType === 'MEDIA'
      ? `/${username}/${id}/edit?type=mediaPost`
      : `/${username}/${id}/edit`;
  const renderEditButton = () => {
    switch (articleStatus) {
      case 'PENDING' || 'SUBMITTED':
        return '';
      case 'APPROVED':
        return 'Edit';

      default:
        return 'Edit and Resubmit';
    }
  };
  useEffect(() => {
    if (!defaultArticle || isError) {
      warningModal.onOpen();
    }
  }, [isLoading]);

  return (
    <>
      {defaultArticle || !isError ? (
        <DetailModal
          publish={publish}
          id={id}
          editRouter={editRouter}
          editButton={renderEditButton()}
          refetch={refetch}
          rid={rid}
          submissionStatus={submissionStatus}
          isManagerSubmission={isManagerSubmission}
          // @ts-ignore
          article={Object.assign(article || {}, defaultArticle)}
          isLoading={!defaultArticle && isLoading}
          modal={modal}
          currentLocation={currentLocation}
        />
      ) : (
        <WarningModal
          {...warningModal}
          title="Content unavailable!"
          text="The content you were trying to access may have been unpublished/removed."
          onOk={() => {
            modal.onClose();
          }}
          additionalModal={modal}
          okBtnText="Got it"
          noCancel
        />
      )}
    </>
  );
}
