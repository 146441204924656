import * as React from 'react';
import { Svg } from '../svg';

function SvgAirtable({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.966 2.215L2.02 5.918a.6.6 0 00.008 1.11l8.985 3.564c.79.313 1.669.313 2.458 0l8.985-3.563a.6.6 0 00.008-1.111l-8.947-3.703a3.334 3.334 0 00-2.55 0"
        fill="#FC0"
      />
      <path
        d="M13.039 12.537v8.902a.6.6 0 00.82.557l10.013-3.887a.599.599 0 00.378-.557V8.651a.6.6 0 00-.82-.557L13.416 11.98a.6.6 0 00-.378.557"
        fill="#31C2F2"
      />
      <path
        d="M10.701 12.996l-3.273 1.581-6.272 3.006c-.398.192-.906-.098-.906-.54V8.688c0-.16.082-.297.192-.4a.682.682 0 01.152-.115.64.64 0 01.546-.042l9.512 3.769c.487.192.525.87.05 1.097"
        fill="#ED3049"
      />
      <path
        d="M10.701 12.997L7.73 14.432.442 8.287a.682.682 0 01.152-.114.64.64 0 01.545-.042l9.512 3.769c.488.192.525.87.05 1.097"
        fill="#C62842"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgAirtable);
export default ForwardRef;
