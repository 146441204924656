import * as React from 'react';
import { Svg } from '../svg';

function SvgCcCc({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#cc-cc_svg__clip0_8008_101565)">
        <path
          d="M20 3.481c9.205 0 16.667 7.462 16.667 16.667 0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.666-7.462-16.666-16.667 0-9.205 7.461-16.667 16.666-16.667zm0 3.334a13.333 13.333 0 100 26.666 13.333 13.333 0 000-26.666zm-5 6.666c1.842 0 3.509.747 4.715 1.955l-2.357 2.357a3.333 3.333 0 10-.001 4.713l2.357 2.355a6.668 6.668 0 01-11.38-4.713A6.67 6.67 0 0115 13.481zm11.667 0c1.841 0 3.508.747 4.715 1.955l-2.357 2.357a3.333 3.333 0 10-.002 4.713l2.357 2.355A6.668 6.668 0 0120 20.148a6.67 6.67 0 016.667-6.667z"
          fill="#4F5356"
        />
      </g>
      <defs>
        <clipPath id="cc-cc_svg__clip0_8008_101565">
          <path fill="#fff" transform="translate(0 .148)" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCcCc);
export default ForwardRef;
