import * as React from 'react';
import { Svg } from '../svg';

function SvgCcBy({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#cc-by_svg__clip0_7994_98816)">
        <path
          d="M20 4.083c9.205 0 16.667 7.462 16.667 16.667 0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.666-7.462-16.666-16.667 0-9.205 7.461-16.667 16.666-16.667zm0 3.334a13.333 13.333 0 100 26.666 13.333 13.333 0 000-26.666zm3.334 10A1.667 1.667 0 0125 19.083v6.667h-2.5v6.667h-5V25.75H15v-6.667a1.667 1.667 0 011.667-1.666h6.666zM20 9.083a3.333 3.333 0 110 6.667 3.333 3.333 0 010-6.667z"
          fill="#4F5356"
        />
      </g>
      <defs>
        <clipPath id="cc-by_svg__clip0_7994_98816">
          <path fill="#fff" transform="translate(0 .75)" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCcBy);
export default ForwardRef;
