import * as React from 'react';
import { Svg } from '../svg';

function SvgAuthorshipToken({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.5 3a3.6 3.6 0 00-3.05 1.68 3.6 3.6 0 00-.9-.1 3.6 3.6 0 00-2.42 1.06 3.6 3.6 0 00-.94 3.32A3.6 3.6 0 003.5 12a3.6 3.6 0 001.69 3.05 3.6 3.6 0 00.95 3.32 3.6 3.6 0 003.35.96A3.6 3.6 0 0012.5 21a3.6 3.6 0 003.04-1.67 3.6 3.6 0 004.3-4.3 3.6 3.6 0 00-.01-6.07 3.6 3.6 0 00-4.3-4.3A3.6 3.6 0 0012.5 3z"
        stroke="#09F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.105 14h-3.25l-.428 1.036a.751.751 0 11-1.38-.593l2.384-5.35a1 1 0 01.913-.593h.303a1 1 0 01.913.592l2.381 5.325a.769.769 0 11-1.412.608L14.105 14zm-.51-1.23l-1.11-2.68-1.11 2.68h2.22z"
        fill="#09F"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgAuthorshipToken);
export default ForwardRef;
