import * as React from 'react';
import { Svg } from '../svg';

function SvgInfo3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10 18.333a8.333 8.333 0 100-16.667 8.333 8.333 0 000 16.667zM10 13.333V10M10 6.666h.008"
        stroke="#9B9FA4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgInfo3);
export default ForwardRef;
