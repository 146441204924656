import * as React from 'react';
import { Svg } from '../svg';

function SvgNcEx({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.333 20.5c0 7.364-5.97 13.333-13.333 13.333-7.364 0-13.333-5.97-13.333-13.333 0-7.364 5.97-13.333 13.333-13.333 7.364 0 13.333 5.97 13.333 13.333zm3.334 0c0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.667-7.462-16.667-16.667 0-9.205 7.462-16.667 16.667-16.667 9.205 0 16.667 7.462 16.667 16.667zM20 29.682l10.134-9.94-3.87-5.273H13.736L9.866 19.74 20 29.682zm3.82-7.871l-3.502 3.503-3.503-3.503 1.18-1.179 2.323 2.324 2.324-2.324 1.179 1.179z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNcEx);
export default ForwardRef;
