import React, { useState } from 'react';
import {
  Icon,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';

import SvgLinkedinV2 from 'src/components/icons/linkedin-v3';
import SvgInstagramV2 from 'src/components/icons/instagram-v3';
import SvgFacebookV2 from 'src/components/icons/facebook-v3';
import SvgDiscord from 'src/components/icons/discord-v3';
import SvgTwitter from 'src/components/icons/twitter-v3';
// import SvgLogoSm from 'src/components/icons/logo-sm';

import SvgLogo from 'src/components/icons/1tm-logo';
import { useRouter } from 'next/router';
import PageModal from './page-modal';

import Community from './community';
import Terms from './terms';
import Copyright from './copyright';
import Privacy from './privacy';

function Footer() {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [contentType, setContentType] = useState('');
  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeContent, setIframeContent] = useState('');
  const modalHandle = (type) => {
    setIsOpen(true);
    setContentType(type);
  };
  const iframeHandle = (type) => {
    setIframeOpen(true);
    setIframeContent(type);
  };
  const companyData = {
    title: 'COMPANY',
    items: [
      // { click: 'modal', value: 'Terms of Service' },
      // { click: 'modal', value: 'Community Guidelines' },
      // { click: 'modal', value: 'Privacy Policy' },
      {
        click: 'link',
        value: 'Work with us',
        link: 'mailto:workwithus@1tm.io',
      },
      { click: 'iframe', value: 'Careers' },
    ],
  };
  const tm = {
    title: '1TM',
    items: [
      {
        click: 'link',
        value: 'What is 1TM',
        link: '',
      },
      {
        click: 'link',
        value: '1TM for Creators',
        link: '',
      },
      {
        click: 'link',
        value: '1TM Gallery 1:',
        link: '',
      },
      {
        click: 'link',
        value: 'Together we create!',
        link: 'https://www.1tm.io/space/95bd9788-8c7d-46da-b52f-741465138e12',
      },
    ],
  };
  const cu = {
    title: '1CU',
    items: [
      {
        click: 'link',
        value: '1CU by 1TM',
        link: '',
      },
      {
        click: 'link',
        value: 'Tribe Clubs and NFT',
        link: '',
      },
      {
        click: 'link',
        value: 'Roadmap',
        link: '',
      },
      {
        click: 'link',
        value: '1CUC and DAO',
        link: '',
      },
      {
        click: 'link',
        value: 'White paper',
        link: '',
      },
    ],
  };
  // const officialData = {
  //   title: 'OFFICIAL SPACE',
  //   items: [
  //     {
  //       click: 'link',
  //       value: 'Collaboration - A 1TM Story',
  //       link: 'https://www.1tm.io/reppl/ddbe7a70-1003-418e-87b8-e0c7458b936g',
  //     },
  //     {
  //       click: 'link',
  //       value: 'Meet the Creators',
  //       link: 'https://www.1tm.io/reppl/e8ef83b2-e74e-4712-9bcd-24a14298c018',
  //     },
  //     {
  //       click: 'link',
  //       value: 'Together we create!',
  //       link: 'https://www.1tm.io/space/95bd9788-8c7d-46da-b52f-741465138e12',
  //     },
  //   ],
  // };
  // const helpData = {
  //   title: 'HELP & SUPPORT',
  //   items: [
  //     {
  //       click: 'link',
  //       value: 'Feedback and suggestions',
  //       link:
  //         'https://www.1tm.io/1TMofficial/a665c25c-0c2e-4edd-a1ea-0dfcd1aae42c',
  //     },
  //     // { click: 'iframe', value: 'Help Center' },
  //     {
  //       click: 'link',
  //       value: 'Contact us at',
  //       link: 'mailto:workwithus@1tm.io',
  //     },
  //   ],
  // };

  const checkClick = (data) => {
    if (data.click === 'modal') {
      modalHandle(data.value);
    } else if (data.click === 'iframe') {
      iframeHandle(data.value);
    } else if (data.click === 'link') {
      router.push(data.link);
    }
  };

  return (
    <div className="relative">
      <div
        style={{ minHeight: '355px' }}
        className="relative px-8 md:h-72 md:px-10 lg:px-28 bg-gray-900 border-t border-gray-300 pt-12 pb-14 mx-auto md:flex justify-between items-center text-gray-400"
      >
        <div style={{ height: '100%' }} className="w-full md:w-6/12">
          <div className="text-sm">
            <div className="mr-2 relative">
              <SvgLogo w="180px" h="27px" className="text-gray-400" />
              {/* <div
                s tyle={{ top: '-5px', left: '40px', fontSize: '11px' }}
                className="text-gray-400 font-bold absolute"
              >
                BETA
              </div> */}
            </div>
          </div>
          <div className="text-xs md:pr-6 mt-6 mb-10 md:mb-24 font-medium text-dark-900 w-full md:w-85">
            <div>
              1TM is a decentralized theme driven collaboration platform for
              creators and enthusiasts.
            </div>
          </div>
        </div>
        <div className="mb-16 md:mb-0 text-xs md:absolute md:bottom-14">
          <div>
            <div className="pb-4 font-bold text-dark-400 text-xs">CONNECT</div>
            <Link
              isExternal
              href="https://discord.gg/mqEYr43K"
              className="shadow-none"
            >
              <span className="text-dark-500 hover:text-dark-700">
                <Icon as={SvgDiscord} boxSize="20px" />
              </span>
            </Link>
            <Link
              isExternal
              href="https://twitter.com/1CUx1TM"
              className="shadow-none"
            >
              <span className="text-dark-500 hover:text-dark-700">
                <Icon className="mx-2" as={SvgTwitter} boxSize="20px" />
              </span>
            </Link>
            <Link
              isExternal
              href="https://linkedin.com/company/join1tm"
              className="shadow-none"
            >
              <span className="text-dark-500 hover:text-dark-700">
                <Icon as={SvgLinkedinV2} boxSize="20px" />
              </span>
            </Link>
            <Link
              isExternal
              href="https://www.facebook.com/oneteam1tm"
              className="shadow-none"
            >
              <span className="text-dark-500 hover:text-dark-700">
                <Icon as={SvgFacebookV2} className="mx-2" boxSize="20px" />
              </span>
            </Link>
            <Link
              isExternal
              href="https://www.instagram.com/1tm.io"
              className="shadow-none"
            >
              <span className="text-dark-500 hover:text-dark-700">
                <Icon as={SvgInstagramV2} boxSize="20px" />
              </span>
            </Link>
          </div>
        </div>
        <div
          style={{ height: 'inherit' }}
          className="grid grid-cols-2 mb-16 md:flex md:w-6/12 md:pt-9 inline-block"
        >
          <div className="text-xs mb-10 font-medium md:mr-6 lg:mr-16">
            <span className="text-dark-400 font-bold block text-xs">
              {tm.title}
            </span>
            {tm.items.map((items, key) => (
              <span
                key={Number(key)}
                style={{ height: '48px' }}
                aria-hidden="true"
                className="cursor-pointer block pt-4 text-dark-900"
                onClick={() => {
                  checkClick(items);
                }}
              >
                {items.value}
              </span>
            ))}
          </div>

          <div className="text-xs mb-10 font-medium md:mr-6 lg:mr-16">
            <span className="text-dark-400 font-bold block text-xs">
              {cu.title}
            </span>
            {cu.items.map((items, key) => (
              <span
                key={Number(key)}
                style={{ height: '48px' }}
                aria-hidden="true"
                className="cursor-pointer block pt-4 text-dark-900"
                onClick={() => {
                  checkClick(items);
                }}
              >
                {items.value}
              </span>
            ))}
          </div>

          <div className="text-xs mb-10 font-medium md:mr-6 lg:mr-16">
            <span className="text-dark-400 font-bold block text-xs">
              {companyData.title}
            </span>
            {companyData.items.map((items, key) => (
              <span
                key={Number(key)}
                style={{ height: '48px' }}
                aria-hidden="true"
                className="cursor-pointer block pt-4 text-dark-900"
                onClick={() => {
                  checkClick(items);
                }}
              >
                {items.value}
              </span>
            ))}
          </div>
          {/* <div className="text-xs mb-10 md:mr-16">
            <span className="text-gray-700 font-bold block">
              {officialData.title}
            </span>
            {officialData.items.map((items, key) => (
              <span
                key={Number(key)}
                aria-hidden="true"
                className="cursor-pointer block pt-4"
                onClick={() => {
                  checkClick(items);
                }}
              >
                {items.value}
              </span>
            ))}
          </div>
          <div className="text-xs mb-10 md:mr-16">
            <span className="text-gray-700 font-bold block">
              {helpData.title}
            </span>
            {helpData.items.map((items, key) => (
              <span
                key={Number(key)}
                aria-hidden="true"
                className="cursor-pointer block pt-4"
                onClick={() => {
                  checkClick(items);
                }}
              >
                {items.value === 'Contact us at' && (
                  <div>
                    {items.value}
                    <span className="text-blue-500 pl-1">workwithus@1tm.io</span>
                  </div>
                )}
                {items.value !== 'Contact us at' && <div>{items.value}</div>}
              </span>
            ))}
          </div> */}
        </div>
        <PageModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          contentType={contentType}
        >
          {contentType === 'Terms of Service' && <Terms />}
          {contentType === 'Community Guidelines' && <Community />}
          {contentType === 'copyright' && <Copyright />}
          {contentType === 'Privacy Policy' && <Privacy />}
        </PageModal>
        <IframeModal isOpen={iframeOpen} setIsOpen={setIframeOpen}>
          {iframeContent === 'Careers' && (
            <iframe
              title="carreers"
              src="https://www.1tm.io/careers/"
              width="100%"
              height="100%"
            />
          )}
          {iframeContent === 'Help Center' && (
            <iframe
              title="help"
              src="https://www.1tm.io/start-doing-together/"
              width="100%"
              height="100%"
            />
          )}
        </IframeModal>
      </div>
      <div className="w-full bg-dark-100 py-6">
        <div className="text-dark-600 text-center md:flex m-auto w-max font-medium text-xs">
          <div className="px-2.5">© 2022 1TM. All rights reserved</div>
          <div className="px-2.5 pt-2 md:pt-0">
            <div className="flex">
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  checkClick({ click: 'modal', value: 'Privacy Policy' });
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    checkClick({ click: 'modal', value: 'Privacy Policy' });
                  }
                }}
              >
                Privacy Policy
              </div>
              <div
                style={{ lineHeight: '19px' }}
                className="px-2 text-dark-400 text-base"
              >
                ∙
              </div>
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  checkClick({ click: 'modal', value: 'Terms of Service' });
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    checkClick({ click: 'modal', value: 'Terms of Service' });
                  }
                }}
              >
                Terms & Conditions
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const IframeModal = ({ children, isOpen, setIsOpen }) => {
  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay>
        <ModalCloseButton />
        <ModalContent bg="$mode.50" w="70%" maxW="1056px" h="90%" maxH="996px">
          <div className="w-full h-full">{children}</div>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default Footer;
