import {
  Box,
  Text,
  useDisclosure,
  SkeletonCircle,
  Skeleton,
  MenuItem,
} from '@chakra-ui/react';
// import copy from 'copy-to-clipboard';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
import { useModal } from 'src/hooks/use-modal';
import { ListType } from 'src/service/creation';
import { IconMenu } from 'src/components/icon-menu/icon-menu';
import { EnthuseButton } from 'src/app/article/components/enthuse-button/enthuse-button';
import { ArticleDetailModal } from 'src/app/article/detail-modal';
import { useAgoraFeedEnthuseMutation } from 'src/app/article/hooks/use-enthuse';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { Avatar } from 'src/components/avatar/avatar';
import SvgBookmarkV1 from 'src/components/icons/bookmark-v1';
import SvgCommentsV1 from 'src/components/icons/comments-v1';
import SvgCommentsV2 from 'src/components/icons/comments-v2';
import SvgDots from 'src/components/icons/dots';
// import SvgShareV1 from 'src/components/icons/share-v1';
// import SvgShareV2 from 'src/components/icons/share-v2';
import { ImageBox } from 'src/components/image-box';
import { useUnpublishEpisodeMutation } from 'src/app/studio/hooks/use-studio-mutation';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import dayHepler from 'src/heplers/day';
import { imageOptimize } from 'src/service/image';
import WarningModal from 'src/app/article/warning-modal';
import { useRouter, Router } from 'next/router';
import Asset from 'src/components/card/Asset';
import { AssetDetailModal } from 'src/app/asset/asset-modal';
import SvgIcMusicrecord from 'src/components/icons/ic-music-record';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { SharePopover } from 'src/components/share-buttons/share-popover';

export function AgoraFeatured({ recentFeed }: { recentFeed: any }) {
  const feedModal = useDisclosure();
  function openModal() {
    window.history.pushState(
      '',
      '',
      `${recentFeed?.author?.username}/${recentFeed?.contentID}`
    );
    feedModal.onOpen();
  }

  const [newData, setNewData] = useState(recentFeed);
  const isMobile = useIsMobile();

  const { mutateAsync: enthuse } = useAgoraFeedEnthuseMutation(
    newData,
    setNewData
  );

  const [coverImg, setCoverImg] = useState<any>();

  useEffect(() => {
    if (recentFeed) {
      setNewData(recentFeed);
    }
  }, [recentFeed]);

  useEffect(() => {
    setCoverImg(
      imageOptimize({ url: recentFeed.coverImgURL, imgContainer: 'agoraCover' })
    );
  }, []);

  const getTagLink = (data) => {
    return data.replace(/ /g, '-');
  };

  return (
    <>
      <Box>
        <div
          className={`featured-cover-img p-4 sm:p-5 xl:p-6 relative ${
            isMobile ? '0' : 'rounded-2xl'
          } w-full text-gray-900 bg-white dark:bg-gray-900 dark:text-white my-4 sm:my-4 overflow-hidden shadow-sm`}
        >
          <Box height="90%">
            <Box
              display="flex"
              justifyContent="space-between"
              className="relative mb-13"
            >
              <Box className="flex justify-start items-center">
                <Link href={`/${recentFeed?.author?.username}`}>
                  <Box
                    className="cursor-pointer mr-2 mb-1"
                    border="1px solid white"
                    borderRadius="20px"
                  >
                    <Avatar
                      name={recentFeed?.author?.username}
                      data-testid={imageOptimize(
                        recentFeed?.author?.profileImgURL
                      )}
                      bg="$mode.400"
                      width={40}
                      height={40}
                      author={recentFeed?.author}
                      src={imageOptimize({
                        url: recentFeed?.author?.profileImgURL,
                        imgContainer: 'avatar',
                      })}
                      mr={1}
                    />
                  </Box>
                </Link>
                <Box display="inline-block">
                  <Link href={`/${recentFeed?.author?.username}`}>
                    <div className="sm:m-auto cursor-pointer">
                      <Text
                        fontSize="sm"
                        fontWeight="700"
                        lineHeight="18px"
                        color="white"
                      >
                        {getProfileFullNameOrUsername(recentFeed?.author)}
                      </Text>
                      {/* <div className="hidden text-xs">
                      {recentFeed?.author?.role === 0 ? 'Author' : 'Editor'}
                    </div> */}
                    </div>
                  </Link>
                  <Text
                    fontSize="xs"
                    fontWeight="500"
                    lineHeight="18px"
                    color="white"
                    display="inline-block"
                  >
                    Published a new creation
                  </Text>
                </Box>
              </Box>
              <Box
                className="flex right-0 my-auto"
                mr="-10px"
                position="absolute"
              >
                <Text className="text-xs text-gray-500" lineHeight="18px">
                  {dayHepler.fromNow(recentFeed?.createdAt)}
                </Text>
                <SvgDots mt="2px" ml={{ base: '10px', md: '16px' }} />
              </Box>
            </Box>
            {/* <div
            className="my-2 h-80 cursor-pointer rounded-lg"
            role="button"
            aria-label="cover img"
            tabIndex={0}
            onClick={feedModal.onOpen}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                feedModal.onOpen();
              }
            }}
            style={{ border: '1px solid red' }}
          /> */}
            <Box
              role="button"
              tabIndex={0}
              onClick={openModal}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  openModal();
                }
              }}
              mb="8px"
            >
              <div className="pb-2 mt-2">
                <Text
                  className="text-bold text-base sm:text-2xl xl:text-2xl font-semibold"
                  color="white"
                  fontSize={{ base: '20px', md: '24px' }}
                  lineHeight={{ base: '24px', md: '32px' }}
                >
                  {recentFeed?.title}
                </Text>
              </div>
              <Text
                className="pb-2 text-xs sm:sm"
                color={{ base: 'gray.200' }}
                lineHeight="18px"
              >
                {recentFeed?.excerpts}
              </Text>
            </Box>
            {recentFeed?.tags?.length > 0 && (
              <div className="flex mb-1 w-auto overflow-hidden">
                {recentFeed?.tags
                  ?.filter((n) => n && n !== '#')
                  .map((tag, key) => {
                    const mkey = `key_${key}`;
                    return (
                      <Link
                        prefetch={false}
                        key={mkey}
                        href={`/feed/${getTagLink(tag)}`}
                      >
                        <Box
                          key={mkey}
                          className="whitespace-nowrap bg-blue-50 text-blue-500 px-3 py-2 rounded-full text-xs font-medium mr-2 cursor-pointer overflow-ellipsis overflow-hidden"
                          bgColor="rgba(255, 255, 255, 0.7);"
                          color="#191919"
                          p={{ base: '4px 10px' }}
                          lineHeight="18px"
                        >
                          {tag.substring(0, 1) === '#' ? tag : `#${tag}`}
                        </Box>
                      </Link>
                    );
                  })}
              </div>
            )}
          </Box>
          <Box className="">
            <div className="flex relative h-auto">
              <div className="flex">
                <Box
                  className="flex text-xs font-medium my-auto"
                  p="6px 8px 6px 0px"
                >
                  <EnthuseButton
                    width="20px"
                    height="20px"
                    fontSize={20}
                    minWidth="auto"
                    svgClass="text-gray-500"
                    isEnthuse={newData['isEnthused']}
                    onEnthuse={() => {
                      enthuse({
                        objectId: recentFeed['contentID'],
                        objectType: 'EPISODE',
                        ownerID: recentFeed['author']?.['userID'],
                      });
                    }}
                    likesCount={newData.enthuseCount}
                    isFeedPost
                  />
                </Box>
                <Box
                  className="flex px-2 text-xs font-medium m-auto cursor-pointer"
                  p="6px 8px 6px 0px"
                  role="button"
                  tabIndex={0}
                  onClick={openModal}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      openModal();
                    }
                  }}
                >
                  <SvgCommentsV2 fontSize="20px" />
                  <Text
                    className="m-auto px-1"
                    fontWeight="500"
                    lineHeight="20px"
                    fontSize="14px"
                    display="inline"
                    color="white"
                  >
                    {recentFeed?.commentCount}
                  </Text>
                </Box>
              </div>
              <Box className="flex absolute right-2 h-auto" alignItems="center">
                <Box display="none">
                  <SvgBookmarkV1 fontSize="20px" />
                </Box>

                <Box
                  className="flex justify-center items-center rounded-2xl cursor-pointer"
                  p="6px 6px 6px 6px"
                  mr="-6px"
                  _hover={{ background: 'rgba(0,0,0,0.15)' }}
                >
                  <SharePopover
                    hashtags={recentFeed['tags']}
                    url={`${`${window.location.origin}/${recentFeed?.author?.username}/${recentFeed?.contentID}`}`}
                  />
                </Box>
              </Box>
            </div>
          </Box>
          <ArticleDetailModal
            source={recentFeed?.source}
            modal={feedModal}
            publish
            id={recentFeed?.contentID}
          />
        </div>
      </Box>
      <style jsx>
        {`
          .featured-cover-img {
            background-image: url('${coverImg}');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 416px;
          }
        `}
      </style>
    </>
  );
}

export function AgoraFeed({
  recentFeed,
  refetch,
}: {
  recentFeed: any;
  refetch: () => void;
}) {
  const { user } = useContext(UserContext) as UserContextType;
  const feedModal = useDisclosure();
  const assetModal = useDisclosure();
  const unpublishModal = useModal<ListType>();
  const { mutate: unpublishEpisode } = useUnpublishEpisodeMutation();
  const editEpisodeModal = useDisclosure();
  const [currentAssetContent, setCurrentAssetContent] = useState<any>();
  const [currentLocation, setCurrentLocation] = useState('');

  function openModal() {
    window.history.pushState(
      '',
      '',
      `${recentFeed?.author?.username}/${recentFeed?.contentID}`
    );
    feedModal.onOpen();
  }

  const modalOpener = (data) => {
    if (data.contractAddress && data.tokenID) {
      window.history.replaceState(
        '',
        '',
        `/asset/${data.contractAddress}:${data.tokenID}`
      );
    }
    if (data.assetID) {
      window.history.replaceState('', '', `/asset/${data.assetID}`);
    }
    setCurrentAssetContent(data);
    assetModal.onOpen();
  };

  const [newData, setNewData] = useState(recentFeed);

  const { mutateAsync: enthuse } = useAgoraFeedEnthuseMutation(
    newData,
    setNewData
  );

  const [coverImg, setCoverImg] = useState<any>();

  useEffect(() => {
    setCurrentLocation(window.location.href);
    const handleRouteChange = (url) => {
      setCurrentLocation(url);
    };
    Router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (recentFeed.enthuseCount > 0) {
      setNewData(recentFeed);
    }
  }, [recentFeed]);

  useEffect(() => {
    setCoverImg(
      imageOptimize({ url: recentFeed.coverImgURL, imgContainer: 'agoraCover' })
    );

    setCurrentLocation(window.location.href);
    const handleRouteChange = (url) => {
      setCurrentLocation(url);
    };
    Router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  const getTagLink = (data) => {
    return data.replace(/ /g, '-');
  };
  const router = useRouter();
  const isMobile = useIsMobile();

  if (!recentFeed.postType) {
    return <></>;
  }

  if (recentFeed?.skeleton) {
    return (
      <Box
        className="p-6 sm:p-5 xl:p-6 relative w-full text-gray-900 bg-white dark:bg-gray-900 dark:text-white my-6 sm:my-6 overflow-hidden shadow-sm"
        borderRadius={{ base: '0px', md: '16px', sm: '16px' }}
      >
        <Box>
          <div className="sm:flex relative mb-4">
            <Box className="flex justify-start items-center">
              <div className="cursor-pointer mr-2 mb-1">
                <SkeletonCircle width={40} height={40} />
              </div>
              <Box>
                <Skeleton className="sm:m-auto cursor-pointer" mb="16px">
                  <Text
                    fontSize="sm"
                    fontWeight="700"
                    lineHeight="18px"
                    maxWidth="200px"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    Author
                  </Text>
                  <div className="hidden text-xs">Author</div>
                </Skeleton>
              </Box>
            </Box>
            <Skeleton
              className="flex right-0 my-auto"
              mr="-10px"
              position="absolute"
              top="0"
            >
              <Text className="text-xs text-gray-500" lineHeight="18px">
                a year ago
              </Text>
            </Skeleton>
          </div>
          <Skeleton
            className={`my-2 cursor-pointer ${
              isMobile ? 'rounded h-40' : 'rounded-lg h-80'
            }`}
          />

          <div>
            <Skeleton className="mb-2 mt-4">
              <Text
                lineHeight={{ base: '24px', md: '30px' }}
                fontWeight={{ base: '500', md: '600' }}
                fontSize={{ base: '16xp', md: '24px' }}
              >
                123
              </Text>
            </Skeleton>
            <Skeleton>
              <Text
                className="pb-2 text-xs sm:sm"
                color="gray.700"
                lineHeight="14px"
              >
                321
              </Text>
            </Skeleton>
          </div>
        </Box>
        <div className="">
          <div className="flex relative h-auto">
            <div className="flex">
              <Box
                className="flex text-xs font-medium my-auto"
                p="6px 8px 6px 0px"
              >
                <EnthuseButton
                  width="20px"
                  height="20px"
                  fontSize={20}
                  minWidth="auto"
                  svgClass="text-gray-500"
                  isEnthuse={false}
                  onEnthuse={() => {}}
                  likesCount={0}
                />
              </Box>
              <Box
                className="flex px-2 text-xs font-medium m-auto cursor-pointer"
                p="6px 8px 6px 0px"
              >
                <SvgCommentsV1 fontSize="20px" />
                <Text
                  className="m-auto px-1"
                  fontWeight="500"
                  lineHeight="20px"
                  fontSize="14px"
                  display="inline"
                  color="gray.500"
                >
                  0
                </Text>
              </Box>
            </div>
            <Box className="flex absolute right-2 h-auto" alignItems="center">
              <Box
                className="flex justify-center items-center rounded-2xl cursor-pointer"
                p="6px 6px 6px 6px"
                mr="-6px"
                _hover={{ background: 'rgba(0,0,0,0.15)' }}
              >
                <SharePopover hashtags={recentFeed['tags']} url="" />
              </Box>
            </Box>
          </div>
        </div>
      </Box>
    );
  }

  if (recentFeed.areaType) {
    return (
      <div className="w-full bg-white text-gray-900 p-4 sm:p-5 xl:p-8 relative my-4 sm:my-6 sm:rounded dark:bg-gray-900 dark:text-white shadow-sm">
        <Link
          prefetch={false}
          href={`/space/${recentFeed.areaID}/collections/${recentFeed.uuid}`}
        >
          <div>
            <div className="sm:flex relative">
              <div className="flex justify-start items-center">
                <div className="cursor-pointer">
                  <Avatar
                    name={user.username}
                    data-testid={user.avatar}
                    bg="$mode.400"
                    width={40}
                    height={40}
                    src=""
                    mr={1}
                  />
                </div>
                <div className="sm:m-auto pl-1 cursor-pointer">
                  <div className="font-medium text-xs sm:text-medium">
                    {recentFeed.name}
                  </div>
                  <div className="text-xs">A new collection created</div>
                </div>
              </div>
              <div className="flex sm:absolute right-2 my-auto">
                <div className="m-auto text-sm w-full sm:w-auto">
                  {dayHepler.localizedFormat(recentFeed?.createdAt, 'MMMM DD')}{' '}
                  at{' '}
                  {dayHepler.localizedFormat(recentFeed?.createdAt, 'h:mm A')}
                </div>
              </div>
            </div>
            <div className="py-5 xl:py-10">
              <div className="flex">
                <div style={{ width: '30%' }} className="my-auto">
                  <div style={{ width: '172px', height: '172px' }}>
                    <ImageBox ratio={1} src={recentFeed.alternateImages} />
                  </div>
                </div>
                <div style={{ width: '70%' }} className="my-auto ml-5">
                  <div className="font-medium text-base sm:text-2xl">
                    {recentFeed.name}
                  </div>
                  <div className="flex py-1">
                    <div className="hidden text-xs my-auto">
                      {recentFeed.followersCount} Followers
                    </div>
                    <div className="hidden text-xs my-auto mx-0.5 text-gray-300">
                      •
                    </div>
                    <div className="text-xs my-auto">
                      {recentFeed?.episodesCount} Creations
                    </div>
                    <button
                      type="button"
                      className="hidden mx-2 text-xs relative border border-gray-200 rounded-sm px-4 py-0.5 text-blue-500 text-sm font-medium"
                    >
                      Follow
                    </button>
                  </div>
                  {recentFeed?.description && (
                    <div className="text-xs text-gray-500">
                      {recentFeed.description}
                    </div>
                  )}
                  <div className="hidden flex mt-2">
                    <div className="cursor-pointer">
                      <Avatar
                        name={user.username}
                        data-testid={user.avatar}
                        bg="$mode.400"
                        width={30}
                        height={30}
                        src={user.avatar}
                        mr={1}
                      />
                    </div>
                    <div className="my-auto pl-1 text-sm cursor-pointer">
                      <div className="font-bold">
                        {getProfileFullNameOrUsername(user)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  if (recentFeed?.teamMember) {
    return (
      <div className="w-full bg-white text-gray-900 p-4 sm:p-5 xl:p-8 relative my-4 sm:my-6 sm:rounded dark:bg-gray-900 dark:text-white shadow-sm">
        <div>
          <div className="sm:flex relative">
            <div className="flex justify-start items-center">
              <div className="cursor-pointer">
                <Avatar
                  name={recentFeed.title}
                  data-testid={recentFeed.title}
                  bg="$mode.400"
                  width={40}
                  height={40}
                  src={imageOptimize({
                    url: recentFeed.avatarImgURL,
                    imgContainer: 'avatar',
                  })}
                  mr={1}
                />
              </div>
              <div className="sm:m-auto pl-1 cursor-pointer">
                <div className="font-medium text-xs sm:text-lg">
                  {recentFeed.title}
                </div>
                <div className="text-xs">
                  A new member has joined as an Editor
                </div>
              </div>
            </div>
            <div className="flex sm:absolute right-2 my-auto">
              {recentFeed?.createdAt && (
                <div className="m-auto text-sm w-full sm:w-auto">
                  {dayHepler.localizedFormat(recentFeed?.createdAt, 'MMMM DD')}{' '}
                  at{' '}
                  {dayHepler.localizedFormat(recentFeed?.createdAt, 'h:mm A')}
                </div>
              )}
            </div>
          </div>
          <Link href={`/${recentFeed?.username}`}>
            <div className="py-5 xl:py-10 cursor-pointer">
              <div className="border border-gray-200 bg-gray-50 rounded-xl h-36 flex">
                <div style={{ width: '18%' }} className="my-auto">
                  <div className="w-min m-auto">
                    <Avatar
                      name={recentFeed.teamMember.username}
                      data-testid={imageOptimize(
                        recentFeed.teamMember.profileImgURL
                      )}
                      bg="$mode.400"
                      width={72}
                      height={72}
                      author={recentFeed}
                      src={imageOptimize({
                        url: recentFeed.teamMember.profileImgURL,
                        imgContainer: 'avatar',
                      })}
                      mr={1}
                    />
                  </div>
                </div>
                <div style={{ width: '70%' }} className="my-auto">
                  <div className="font-medium text-base sm:text-2xl ">
                    {getProfileFullNameOrUsername(recentFeed.teamMember)}
                  </div>
                  <div className="hidden flex py-1">
                    <div className="text-xs my-auto">270 Followers</div>
                    <div className="text-xs my-auto mx-0.5 text-gray-300">
                      •
                    </div>
                    <div className="text-xs my-auto">36 Creations</div>
                    <button
                      type="button"
                      className="hidden mx-2 text-xs relative border border-gray-200 rounded-sm px-4 py-0.5 text-blue-500 text-sm font-medium"
                    >
                      Follow
                    </button>
                  </div>
                  <div className="hidden text-xs text-gray-500">
                    Designer based in Honolulu, HI and making things @Sedancon
                    Studio.
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="" />
      </div>
    );
  }

  if (recentFeed?.postType === 'CREATIVE_ASSET') {
    return (
      <div className="my-6">
        <Asset
          className="my-3 md:mx-3"
          animation_url={recentFeed.coverImgURL}
          imgURL={recentFeed.coverImgURL}
          title={recentFeed.title}
          excerpt={recentFeed.excerpts}
          author={recentFeed.author}
          isEnthused={recentFeed.isEnthused}
          enthusesCount={recentFeed.enthuseCount}
          categories={recentFeed.categories}
          tags={recentFeed.tags}
          contentID={recentFeed.contentID}
          modalOpener={(e) => modalOpener(e)}
        />
        {assetModal.isOpen && (
          <AssetDetailModal
            modal={assetModal}
            id={currentAssetContent?.assetID}
            contractAddress={currentAssetContent?.contractAddress}
            tokenID={currentAssetContent?.tokenID}
            currentLocation={currentLocation}
          />
        )}
      </div>
    );
  }

  return (
    <>
      <Box
        className="p-6 sm:p-5 xl:p-6 relative w-full text-gray-900 bg-white dark:bg-gray-900 dark:text-white my-6 sm:my-6 overflow-hidden shadow-sm"
        borderRadius={{ base: '0px', md: '16px', sm: '16px' }}
      >
        <Box>
          <div className="sm:flex relative mb-4">
            <Box className="flex justify-start items-center">
              <Link href={`/${recentFeed?.author?.username}`}>
                <div className="cursor-pointer mr-2 mb-1">
                  <Avatar
                    name={recentFeed?.author?.username}
                    data-testid={imageOptimize(
                      recentFeed?.author?.profileImgURL
                    )}
                    bg="$mode.400"
                    width={40}
                    height={40}
                    author={recentFeed?.author}
                    src={imageOptimize({
                      url: recentFeed?.author?.profileImgURL,
                      imgContainer: 'avatar',
                    })}
                    mr={1}
                  />
                </div>
              </Link>
              <Box>
                <Link href={`/${recentFeed?.author?.username}`}>
                  <Box
                    className="sm:m-auto cursor-pointer"
                    mb={!recentFeed?.reppl && '16px'}
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="700"
                      lineHeight="18px"
                      maxWidth="200px"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {getProfileFullNameOrUsername(recentFeed?.author)}
                    </Text>
                    <div className="hidden text-xs">
                      {recentFeed?.author?.role === 0 ? 'Author' : 'Editor'}
                    </div>
                  </Box>
                </Link>
                {recentFeed?.reppl && (
                  <Text
                    fontSize="xs"
                    fontWeight="500"
                    lineHeight="18px"
                    color="gray.500"
                    onClick={() =>
                      router.push(`/space/${recentFeed?.reppl?.id}/`)
                    }
                    cursor="pointer"
                  >
                    Published an episode in{' '}
                    <Text color="blue.500" display="inline">
                      {recentFeed?.reppl?.title}
                    </Text>
                  </Text>
                )}
              </Box>
            </Box>
            <Box
              className="flex right-0 my-auto"
              mr={recentFeed?.author.userId === user.id && '-10px'}
              position="absolute"
              top="0"
            >
              <Text className="text-xs text-gray-500" lineHeight="18px">
                {dayHepler.fromNow(recentFeed?.createdAt)}
              </Text>
              {recentFeed?.author.userId === user.id && (
                <>
                  <SvgDots
                    mt="2px"
                    ml={{ base: '10px', md: '16px' }}
                    className="absolute right-0"
                  />
                  <IconMenu>
                    {!isMobile && (
                      <MenuItem
                        w="100%"
                        onClick={() => {
                          if (recentFeed?.articleType === 'STUDIO') {
                            router.push(`${recentFeed.contentID}/edit`);
                          } else {
                            editEpisodeModal.onOpen();
                          }
                        }}
                      >
                        Edit
                      </MenuItem>
                    )}
                    <MenuItem
                      w="100%"
                      onClick={(e) => {
                        e.preventDefault();
                        unpublishModal.onSelect(recentFeed);
                      }}
                    >
                      Unpublish
                    </MenuItem>
                  </IconMenu>
                  <WarningModal
                    {...editEpisodeModal}
                    title="Edit a published folio"
                    text="Are you sure you want to edit this folio?"
                    onOk={() => {
                      router.push(`${recentFeed.contentID}/edit`);
                    }}
                    okBtnText="Edit"
                    cancelBtnText="No, keep it as it is"
                    cancelBtnColor="#9B9FA4"
                    cancelBtnScheme="#9B9FA4"
                  />
                  {unpublishModal.selected && (
                    <WarningModal
                      {...unpublishModal.modal}
                      isFooterCaption
                      title="Unpublish your folio "
                      text="Are you sure you want to unpublish this folio? Your folio will be not available for other users."
                      onOk={async () => {
                        await unpublishEpisode(
                          unpublishModal.selected.contentID
                        );
                        refetch();
                      }}
                      okBtnText="Unpublish"
                      cancelBtnText="Cancel"
                      cancelBtnColor="#9B9FA4"
                      cancelBtnScheme="#9B9FA4"
                      footerCaption="* All comments and enthuses will remain intact."
                    />
                  )}
                </>
              )}
            </Box>
          </div>
          {recentFeed?.coverImgURL === '' ? (
            <Box
              position="relative"
              className={`bg-gray-900 my-2 cursor-pointer ${
                isMobile ? 'rounded h-40' : 'rounded-lg h-80'
              } `}
              onClick={openModal}
            >
              <Box
                position="absolute"
                left="50%"
                top="50%"
                transform="translate(-50%,-50%)"
              >
                <SvgIcMusicrecord boxSize={38} />
              </Box>
            </Box>
          ) : (
            <div
              className={`my-2 cursor-pointer cover-image-box ${
                isMobile ? 'rounded h-40' : 'rounded-lg h-80'
              }`}
              role="button"
              aria-label="cover img"
              tabIndex={0}
              onClick={openModal}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  openModal();
                }
              }}
            />
          )}

          <div
            role="button"
            tabIndex={0}
            onClick={openModal}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                openModal();
              }
            }}
          >
            <div className="pb-2 mt-4">
              <Text
                lineHeight={{ base: '24px', md: '30px' }}
                fontWeight={{ base: '500', md: '600' }}
                fontSize={{ base: '16xp', md: '24px' }}
              >
                {recentFeed?.title}
              </Text>
            </div>
            <Text
              className="pb-2 text-xs sm:sm"
              color="gray.700"
              lineHeight="14px"
            >
              {recentFeed?.excerpts}
            </Text>
          </div>
          {recentFeed?.tags?.length > 0 && (
            <div className="flex mb-1 w-auto overflow-hidden">
              {recentFeed?.tags
                ?.filter((n) => n && n !== '#')
                .map((tag, key) => {
                  const mkey = `key_${key}`;
                  return (
                    <Link
                      prefetch={false}
                      key={mkey}
                      href={`/feed/${getTagLink(tag)}`}
                    >
                      <Box
                        key={mkey}
                        className="whitespace-nowrap bg-blue-50 text-blue-500 px-3 py-2 rounded-full text-xs font-medium mr-2 cursor-pointer overflow-ellipsis overflow-hidden"
                        p={{ base: '4px 10px' }}
                        lineHeight="18px"
                      >
                        {tag.substring(0, 1) === '#' ? tag : `#${tag}`}
                      </Box>
                    </Link>
                  );
                })}
            </div>
          )}
        </Box>
        <div className="">
          <div className="flex relative h-auto">
            <div className="flex">
              <Box
                className="flex text-xs font-medium my-auto"
                p="6px 8px 6px 0px"
              >
                <EnthuseButton
                  width="20px"
                  height="20px"
                  fontSize={20}
                  minWidth="auto"
                  svgClass={`text-gray-500 ${newData['isEnthused']}`}
                  isEnthuse={newData['isEnthused']}
                  onEnthuse={() => {
                    enthuse({
                      objectId: recentFeed['contentID'],
                      objectType: 'EPISODE',
                      ownerID: recentFeed['author']?.['userID'],
                    });
                  }}
                  likesCount={newData.enthuseCount}
                />
              </Box>
              <Box
                className="flex px-2 text-xs font-medium m-auto cursor-pointer"
                p="6px 8px 6px 0px"
                role="button"
                tabIndex={0}
                onClick={openModal}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    openModal();
                  }
                }}
              >
                <SvgCommentsV1 fontSize="20px" />
                <Text
                  className="m-auto px-1"
                  fontWeight="500"
                  lineHeight="20px"
                  fontSize="14px"
                  display="inline"
                  color="gray.500"
                >
                  {recentFeed?.commentCount}
                </Text>
              </Box>
            </div>
            <Box className="flex absolute right-2 h-auto" alignItems="center">
              <Box display="none">
                <SvgBookmarkV1 fontSize="20px" />
              </Box>

              <Box
                className="flex justify-center items-center rounded-2xl cursor-pointer"
                p="6px 6px 6px 6px"
                mr="-6px"
                _hover={{ background: 'rgba(0,0,0,0.15)' }}
              >
                <SharePopover
                  hashtags={recentFeed['tags']}
                  url={`${`${window.location.origin}/${recentFeed?.author?.username}/${recentFeed?.contentID}`}`}
                />
              </Box>
            </Box>
          </div>
        </div>
        <ArticleDetailModal
          source={recentFeed?.source}
          modal={feedModal}
          publish
          id={recentFeed?.contentID}
        />
      </Box>
      <style jsx>
        {`
          .cover-image-box {
            background-image: url('${coverImg}');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        `}
      </style>
    </>
  );
}
