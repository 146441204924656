import { useMemo } from 'react';
import Link from 'next/link';

interface CategoryProps {
  data: any;
  className?: string;
}
const Category = ({ data, className = '' }: CategoryProps) => {
  const { title, posts } = data;
  const img = useMemo(() => title.replace(' & ', '').replace(/\s/, ''), [
    title,
  ]);
  const link = useMemo(() => title.replace(/ /g, '-'), [title]);
  const postAmount = useMemo(() => new Intl.NumberFormat().format(posts), [
    posts,
  ]);
  return (
    <Link prefetch={false} href={`/feed/${link}`}>
      <section
        className={`tribe-category-card cursor-pointer w-full${className}`}
      >
        <div
          className={`w-full rounded-3xl px-40 py-20 bg-center bg-cover ${className}`}
          style={{
            backgroundImage: `url(/images/assets/categories/${img}.png)`,
          }}
        />
        <div className="flex flex-col justify-between items-start gap-3">
          <h4 className="font-bold text-xl mt-4">{title}</h4>
          <div className="text-sm desc leading-4.5">
            <div className="font-medium">
              {postAmount}
              <span className="ml-1 mr-4 pr-4">
                {posts > 1 ? 'creations' : 'creation'}
              </span>
            </div>
          </div>
        </div>
      </section>
    </Link>
  );
};
export default Category;
