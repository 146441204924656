import * as React from 'react';
import { Svg } from '../svg';

function SvgExcerptsInfo({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M9.167 5.833h1.666v1.666H9.167V5.833zM9.167 9.166h1.666v5H9.167v-5z"
        fill="#BBC0C5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.667 10C1.667 5.4 5.4 1.665 10 1.665s8.333 3.733 8.333 8.333S14.6 18.333 10 18.333a8.336 8.336 0 01-8.333-8.334zm1.666 0A6.675 6.675 0 0010 16.665a6.676 6.676 0 006.667-6.667A6.676 6.676 0 0010 3.333a6.676 6.676 0 00-6.667 6.666z"
        fill="#BBC0C5"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgExcerptsInfo);
export default ForwardRef;
