import { createContext, useEffect, useState, useContext } from 'react';
import {
  fetchInvitationTop,
  fetchNotification,
  fetchNotificationStats,
  fetchNotificationTop,
} from 'src/app/auth/service';
import { RestResponse } from 'src/types';
import { UserContext, UserContextType } from '../contexts/UserContext';

export const NotificationContext = createContext([]);

export const NotificationContextProvider = (props) => {
  const [value, setValue] = useState({});
  const { isLogin } = useContext(UserContext) as UserContextType;
  function cardFilter(typeValue, data) {
    const newnotif = data?.filter(function filterNotif(notifData) {
      return notifData.objectType === typeValue;
    });
    return newnotif;
  }

  useEffect(() => {
    async function getNotification() {
      try {
        const notification: RestResponse<any> = await fetchNotification();
        const invitation: RestResponse<any> = await fetchInvitationTop('7');

        const notificationTop: RestResponse<any> = await fetchNotificationTop();
        const notificationStats: RestResponse<any> = await fetchNotificationStats();

        let respond;
        if (notification.code === 200 || notification.code === 0) {
          const projectLength = cardFilter('Project', notification.data.data)
            .length;
          const postLength = cardFilter('Post', notification.data.data).length;
          respond = {
            project: projectLength,
            post: postLength,
            notifCount: notification?.data.totalUnreadCount,
            data: notification?.data?.data.map((notifData) => notifData),
          };
        }
        if (notificationStats.code === 1000) {
          respond = {
            ...respond,
            notificationStats: notificationStats.data,
          };
        }
        if (invitation.code === 1000) {
          respond = {
            ...respond,
            invitation: invitation.data.data,
          };
        }
        if (notificationTop.code === 200 || notificationTop.code === 0) {
          respond = {
            ...respond,
            notificationTop: notificationTop?.data?.data.map(
              (notifData) => notifData
            ),
          };
          setValue(respond);
        }
        return null;
      } catch (err) {
        return null;
      }
    }
    if (Object.keys(value).length === 0 && isLogin) {
      getNotification();
    }
  }, [value, isLogin]);

  return (
    <NotificationContext.Provider value={[value, setValue]}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export const recheckNotification = async (notification, event) => {
  const copyNotif = notification?.data;
  // eslint-disable-next-line no-param-reassign
  await copyNotif.forEach((item) => {
    if (item.id === event) {
      // eslint-disable-next-line no-param-reassign
      item.read = true;
    }
  });
  const copyNotifTop = notification.notificationTop;
  await copyNotifTop.forEach((item) => {
    if (item.id === event) {
      // eslint-disable-next-line no-param-reassign
      item.read = true;
    }
  });
  const invitationTop = notification.invitation;
  await invitationTop.forEach((item) => {
    if (item.id === event) {
      // eslint-disable-next-line no-param-reassign
      item.read = true;
    }
  });
  return { ...notification, copyNotif, copyNotifTop, invitationTop };
};
