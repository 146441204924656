import { axios, CancelToken } from 'src/lib/axios';
import { isMobile, isTablet } from 'react-device-detect';

export type ImgUploadResponse = {
  code: number;
  msg: string;
  imgURL: string;
};

export type ImgUploadAreaType =
  | 'PROFILE'
  | 'STUDIO'
  | 'REPPL'
  | 'EPISODE'
  | 'LABEL'
  | 'SPACE';
export type ImgUploadType =
  | 'avatar'
  | 'cover'
  | 'studio_cover' // legacy
  | 'reppl_avatar'
  | 'reppl_cover'
  | 'episode_img'
  | 'episode_cover'
  | 'about_img'
  | 'label_img'
  | 'space_avatar'
  | 'space_cover';

export type ImageRequest = {
  imgType: ImgUploadType;
  areaType: ImgUploadAreaType;
  id?: string;
  data: FormData;
  updateProgress?: (progress: ProgressEvent) => void;
  getCancelFunction?: (cancelFunction: Function) => void;
};

export const postUploadImage = (req: ImageRequest) => {
  const cancelTokenSource = CancelToken.source();

  req?.getCancelFunction?.(() => {
    cancelTokenSource.cancel();
  });
  const url =
    req?.areaType === 'SPACE'
      ? `/image/attached/${req.areaType}/${req.id}/${req.imgType}`
      : `/image/unattached/${req.areaType}/${req.imgType}`;

  return axios
    .post<ImgUploadResponse>(url, req.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: req.updateProgress,
      cancelToken: cancelTokenSource.token,
    })
    .then((res) => res.data);
};

export const deleteUploadImage = (req: Omit<ImageRequest, 'data'>) =>
  axios
    .delete(`/image/${req.areaType}/${req.id}/${req.imgType}`)
    .then((res) => res.data);

export const imageOptimize = (imgURL) => {
  const imageSizes = {
    avatar: { desktop: '176x176', tablet: '128x128', mobile: '128x128' },
    metadata: { desktop: '250x250', tablet: '250x250', mobile: '250x250' },
    studioCover: { desktop: '2280x576', tablet: '2048x410', mobile: '720x144' },
    projectCover: {
      desktop: '2560x640',
      tablet: '2048x512',
      mobile: '720x180',
    },
    postImage: { desktop: '1832x1030', tablet: '1268x713', mobile: '720x405' },
    postCover: { desktop: '600x338', tablet: '600x338', mobile: '600x338' },
    agoraCover: { desktop: '1152x648', tablet: '1268x713', mobile: '654x368' },
  };

  let newUrl = imgURL;

  if (newUrl?.imgContainer) {
    newUrl = imgURL?.url;
  }

  const getSize = (container) => {
    if (isTablet && isMobile) {
      return imageSizes[container].tablet;
    }
    if (isMobile && !isTablet) {
      return imageSizes[container].mobile;
    }
    return imageSizes[container].desktop;
  };

  if (
    newUrl &&
    newUrl.includes('https://1tmiopost.s3-ap-southeast-1.amazonaws.com')
  ) {
    if (imgURL?.imgContainer) {
      const newImage = newUrl?.replace(
        'https://1tmiopost.s3-ap-southeast-1.amazonaws.com',
        `https://d1scq3krmijrlx.cloudfront.net/filters:equalize()/fit-in/${getSize(
          imgURL.imgContainer
        )}`
      );
      return newImage;
    }
    const newImage = newUrl?.replace(
      'https://1tmiopost.s3-ap-southeast-1.amazonaws.com',
      'https://d1scq3krmijrlx.cloudfront.net/filters:equalize()'
    );
    return newImage;
  }
  return newUrl;
};
