import { Box, BoxProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

interface Props {
  viewBox?: string;
  xmlns?: string;
  xlinkHref?: string;
  xmlnsXlink?: string;
}

export const Svg = forwardRef((props: BoxProps & Props, ref) => (
  <Box as="svg" ref={ref as any} {...props} />
));
