import { useState, useContext } from 'react';
import {
  Button,
  Link as ChakraLink,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import SvgVisibilityOffOutlined from 'src/components/icons/visibility-off-outlined';
import SvgVisibilityOutlined from 'src/components/icons/visibility-outlined';

import { debounce, testEmailFormat } from 'src/app/auth/functions';
import { getLocalStorage } from 'src/heplers/localStorage';
import { UserContext, UserContextType } from '../../../contexts/UserContext';
import { SignInRequest } from '../service';

interface SignInFormProps {
  toggleForm: () => void;
}
export interface ErrorMessageProps {
  title: string;
  message: string;
}

const errorMessages = {
  email: 'Invalid email.',
  login: 'Invalid email or password.',
};

function ModalSignInForm({ toggleForm }: SignInFormProps) {
  const [visible, setVisible] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailInputEmpty, setIsEmailInputEmpty] = useState(true);
  const [showLoginError, setShowLoginError] = useState(false);

  const router = useRouter();
  const { register, handleSubmit } = useForm<SignInRequest>({
    mode: 'onBlur',
  });

  const testEmail = debounce((email) => {
    setIsEmailValid(testEmailFormat(email));
  });

  const { signIn } = useContext(UserContext) as UserContextType;

  const toggleVisible = () => setVisible((prevVisible) => !prevVisible);

  const onSubmit = handleSubmit(async (data) => {
    const signInResponse: any = await signIn(data);
    if (signInResponse.data.code === 1000) {
      if (!getLocalStorage('routeLink')) {
        if (signInResponse.status === 401) {
          router.push({
            query: {
              page: 'notverifidet',
              email: data.email,
            },
          });
        } else if (router.query?.['return_to']) {
          const returnTo = router.query.return_to as string;
          router.replace(returnTo);
        } else if (router.query.page !== 'signIn') {
          router.push('/');
        } else if (router.query.page === 'signIn') {
          router.push({ query: { ...router.query, page: '' } });
        }
      }
    } else if (signInResponse.status === 401) {
      setShowLoginError(true);
    }
  });

  const { t } = useTranslation();

  return (
    <form autoComplete="nope" onSubmit={onSubmit}>
      <Stack spacing={[3, 2, 4]}>
        <FormControl id="email" isInvalid={!isEmailValid && !isEmailInputEmpty}>
          <Input
            border="1px solid"
            borderRadius="4px"
            boxShadow="unset !important"
            marginTop="16px"
            _focus={{ borderColor: 'gray.400' }}
            _invalid={{
              backgroundColor: 'rgb(244, 229, 224)',
              borderColor: '#ff6633',
            }}
            name="email"
            autoComplete="nope"
            type="email"
            style={{
              fontFeatureSettings: `'calt' 0`,
            }}
            borderColor="gray.300"
            ref={register({
              required: true,
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t('Invalid Email Address'),
              },
            })}
            placeholder="Email"
            fontSize="14px"
            onChange={(e) => {
              const { value } = e.target;
              testEmail(value);
              debounce(() => setIsEmailInputEmpty(value.length === 0))();
              setShowLoginError(false);
            }}
          />
          <div
            style={{
              color: '#ff6633',
              fontSize: '0.75rem',
              height: '26px',
            }}
          >
            {!isEmailValid && !isEmailInputEmpty && <>{errorMessages.email}</>}
          </div>
        </FormControl>

        <FormControl id="password" marginTop="0 !important">
          <InputGroup>
            <Input
              border="1px solid"
              borderRadius="4px"
              boxShadow="unset !important"
              name="password"
              autoComplete="nope"
              _focus={{ borderColor: 'gray.400' }}
              _invalid={{
                backgroundColor: 'rgb(244, 229, 224)',
                borderColor: '#ff6633',
              }}
              type={visible ? 'text' : 'password'}
              borderColor="gray.300"
              ref={register({
                required: true,
              })}
              placeholder="Password"
              fontSize="14px"
              onChange={() => {
                setShowLoginError(false);
              }}
            />
            <InputRightElement cursor="pointer">
              <Icon
                as={visible ? SvgVisibilityOffOutlined : SvgVisibilityOutlined}
                boxSize="24px"
                color="#d3d6d8"
                onClick={toggleVisible}
              />
            </InputRightElement>
          </InputGroup>
          <FormLabel m={0} fontSize="sm">
            <Flex justifyContent="flex-end" mt={1} mr="6px">
              <ChakraLink onClick={toggleForm}>
                <div>
                  <span className="font-medium">{t('Forgot password')}</span>
                </div>
              </ChakraLink>
            </Flex>
          </FormLabel>
        </FormControl>
      </Stack>

      <Stack mt={3} spacing={3} alignItems={['center', 'flex-start']}>
        <div className="w-full flex justify-center lg:justify-start">
          <Button
            rounded="ellipse"
            type="submit"
            isDisabled={!isEmailValid}
            width="100%"
            px="32px"
            py="10px"
          >
            <span className="text-sm lg:text-base">{t('Sign in')}</span>
          </Button>
        </div>
        {showLoginError && (
          <span
            style={{
              color: '#ff6633',
              fontSize: '0.75rem',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '16px',
            }}
          >
            {errorMessages.login}
          </span>
        )}
      </Stack>
    </form>
  );
}

export default ModalSignInForm;
