/**
 * External dependencies
 */
import { useRef, useCallback, useEffect, useReducer } from 'react';
import debounce from 'lodash.debounce';

/**
 * Internal dependencies
 */
import { setLocalStorage, getLocalStorage } from 'src/heplers/localStorage';

import reducer from './reducer';
import * as types from '../reducers/types';
import { fetchSuggestions, fetchSearchLiteResult } from '../algoliaSearch';

import { INITIAL_STATE } from './constants';

function useSuggestionsReducer() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const suggestionInputRef = useRef() as React.MutableRefObject<
    HTMLInputElement
  >;

  const fetchSimilarSuggestions = useCallback(async (keyWord) => {
    const similarSuggestions = await fetchSuggestions(keyWord);
    dispatch({
      type: types.UPDATE_SIMILAR_SUGGESTIONS,
      payload: { similarSuggestions },
    });
  }, []);

  const fetchTrendingSuggestions = useCallback(async () => {
    const trendingSuggestions = await fetchSuggestions('');
    dispatch({
      type: types.UPDATE_TRENDING_SUGGESTIONS,
      payload: { trendingSuggestions },
    });
  }, []);

  const onClearSearchLite = useCallback(() => {
    if (suggestionInputRef.current) {
      suggestionInputRef.current.value = '';
    }

    dispatch({
      type: types.CLEAR_SEARCH,
      payload: { isSearchLite: true },
    });
  }, []);

  const searchLite = useCallback(async (searchTerm) => {
    try {
      dispatch({
        type: types.FETCH_SEARCH_START,
        payload: { isSearchLite: true },
      });

      const result = await fetchSearchLiteResult(searchTerm);

      dispatch({
        type: types.FETCH_SEARCH_SUCCESS,
        payload: {
          result,
        },
      });
    } catch (error) {
      dispatch({ type: types.FETCH_SEARCH_ERROR, payload: {} });
    }
  }, []);

  const onSearchSuggestions = useCallback(
    () =>
      debounce(() => {
        if (state.isFetching) return;
        const inputValue = suggestionInputRef?.current?.value || '';

        if (inputValue.match(/^\s*$/g)) return onClearSearchLite();

        dispatch({
          type: types.SET_SEARCH_TERM,
          payload: { searchTerm: inputValue },
        });

        fetchSimilarSuggestions(inputValue);
        searchLite(inputValue);
      }, 300)(),
    [state.isFetching, onClearSearchLite, searchLite, fetchSimilarSuggestions]
  );

  const onSaveSearchTerm = useCallback((searchTerm) => {
    debounce(() => {
      /** <-- Update LocalStorage */
      // update recent search keyword
      const keywordsRecord = getLocalStorage('search_keywords_record', true);
      let foundIndex;
      if (keywordsRecord)
        keywordsRecord?.forEach((keys, i) => {
          if (keys === searchTerm) {
            foundIndex = i;
          }
        });
      if (foundIndex) keywordsRecord?.splice(foundIndex, 1);
      const update = [searchTerm || ''].concat(keywordsRecord || []);
      const recentSearchTerms = update.slice(0, 10);
      setLocalStorage('search_keywords_record', recentSearchTerms, true);
      /** Update LocalStorage --> */
      dispatch({
        type: types.UPDATE_RECENT_SEARCH_TERMS,
        payload: { recentSearchTerms },
      });
    }, 300)();
  }, []);

  const suggest = useCallback(async () => fetchTrendingSuggestions(), [
    fetchTrendingSuggestions,
  ]);

  useEffect(() => {
    suggest();
    onSaveSearchTerm('');
    return function cleanup() {
      suggest();
      onSaveSearchTerm('');
    };
  }, [suggest, onSaveSearchTerm]);

  return {
    state,
    onSearchSuggestions,
    onClearSearchLite,
    onSaveSearchTerm,
    suggestionInputRef,
  };
}

export default useSuggestionsReducer;
