import * as React from 'react';
import { Svg } from '../svg';

function SvgUpdates({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        clipPath="url(#updates_svg__clip0_7547_77810)"
        stroke="#4F5356"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3.333 18.333a1.667 1.667 0 01-1.667-1.666v-7.5c0-.917.75-1.667 1.667-1.667H5M3.333 18.333h13.334a1.666 1.666 0 001.666-1.666V3.332a1.667 1.667 0 00-1.666-1.667h-10A1.667 1.667 0 005 3.333v13.334a1.667 1.667 0 01-1.667 1.666zM15 11.666H8.334M12.5 15H8.334" />
        <path d="M8.334 5H15v3.333H8.334V5z" />
      </g>
      <defs>
        <clipPath id="updates_svg__clip0_7547_77810">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgUpdates);
export default ForwardRef;
