import * as React from 'react';
import { Svg } from '../svg';

function SvgEnthuse({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8.5 22c2.121 0 4.156-.809 5.657-2.248 1.5-1.439 2.343-3.391 2.343-5.426 0-.887-.245-1.737-.533-2.528-1.779 1.685-3.129 2.528-4.054 2.528C16.175 7.163 13.833 4.093 7.433 0c.533 5.116-2.983 7.443-4.414 8.736a7.604 7.604 0 00-2.327 3.92 7.382 7.382 0 00.371 4.497 7.742 7.742 0 002.941 3.52A8.242 8.242 0 008.5 22zm.757-18.178c3.457 2.814 3.474 5 .803 9.49-.811 1.364.216 3.06 1.853 3.06.734 0 1.476-.205 2.26-.609a5.559 5.559 0 01-1.264 2.275 5.869 5.869 0 01-2.19 1.496 6.094 6.094 0 01-5.22-.376 5.764 5.764 0 01-1.932-1.792 5.482 5.482 0 01-.897-2.43 5.415 5.415 0 01.327-2.56 5.616 5.616 0 011.483-2.15c.135-.121.816-.702.846-.727.453-.389.825-.734 1.193-1.111C7.83 7.039 8.774 5.543 9.256 3.822h.001z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEnthuse);
export default ForwardRef;
