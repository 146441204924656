import { NextSeo, NextSeoProps } from 'next-seo';

export const DEFAULT_META = {
  title:
    '1TM - A Collaborative Community for Creators, Enablers, and Enthusiasts',
  description:
    'Share, discover, and launch your ideas with the passionate community and make your ideas happen!',
  URL: 'https://www.1tm.io',
  theme: '#FFFFFF',
  image: 'https://1tmiopost.s3.ap-southeast-1.amazonaws.com/metalink.png', // '/images/logo/logo-L.png',
};

type MetadataProps = NextSeoProps & {
  image?: string;
  URL?: string;
};

export function Metadata(props: MetadataProps) {
  const meta: NextSeoProps = {
    ...props,
    title: props.title ?? DEFAULT_META.title,
    description: props.description ?? DEFAULT_META.description,
    openGraph: {
      ...props.openGraph,
      images: [
        {
          url: props.image ?? DEFAULT_META.image,
        },
      ],
      url: props.URL ?? DEFAULT_META.URL,
    },
  };
  return <NextSeo {...meta} />;
}
