import {
  Box,
  IconButton,
  Stack,
  useBreakpointValue,
  Fade,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/react';

import { Children, useContext, useState, useEffect } from 'react';
import { ConditionalWrap } from 'src/components/conditional-wrap';

// import SvgBookmark from 'src/components/icons/bookmark';
// import SvgComment from 'src/components/icons/comment';
// import SvgArrow from 'src/components/icons/arrow';
import SvgShareNew from 'src/components/icons/share-new';
import SvgBookmarkNew from 'src/components/icons/bookmark-new';
import SvgCommentNew from 'src/components/icons/comments-new';
// import { useSafeModalContext } from 'src/hooks/use-safe-modal-context';
// import { useScrollDelta } from 'src/hooks/use-scroll-delta';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import SvgMenu2 from 'src/components/icons/menu-2';
import WarningModal from 'src/app/article/warning-modal';
import {
  useUnpublishEpisodeMutation,
  useUnlistEpisodeMutation,
} from 'src/app/studio/hooks/use-studio-mutation';
import { useRouter } from 'next/router';
import { NoteModal } from 'src/app/article/components/note-modal';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { useArticlePin, useArticlePick } from 'src/app/article/hooks/use-pin';
import { AddToLabelModal } from 'src/app/label/components/add-to-label-modal';
import { ShareButtons } from 'src/components/share-buttons';
import SignInModal from 'src/app/auth/sign-in/sign-in-modal';
import { setLocalStorage } from 'src/heplers/localStorage';
import { EnthuseIcon } from '../enthuse-button';
import { useAgoraFeedEnthuseMutation } from '../../hooks/use-enthuse';

interface StickyIconButtonListProps {
  authorID: number;
  authorUsername: string;
  contentID: string;
  isEnthused?: boolean;
  onCommentClick?: () => void;
  onSaveClick?: () => void;
  articleType: 'STUDIO' | 'EPISODE' | 'CREATIVE_ASSET' | 'NFT';
  collection?: any[];
  commentable?: boolean;
  commentCount?: number;
  left?: string;
  data?: any;
  refetch?: any;
  isFixedTab?: boolean;
  isFloater?: boolean;
  disabled?: boolean;
}

export function StickyIconButtonList(props: StickyIconButtonListProps) {
  const { commentable } = props;
  const { mutateAsync: enthuse } = useAgoraFeedEnthuseMutation(props.data);

  const { user } = useContext(UserContext) as UserContextType;
  const [isEnthused, setIsEnthused] = useState<any>(props.data?.isEnthused);
  const [enthuseCount, setEnthuseCount] = useState<any>(
    props?.data?.enthusesCount || props?.data?.enthuseCount || 0
  );
  const signInModal = useDisclosure();
  // const modalCtx = useSafeModalContext();
  // const { dir } = useScrollDelta(modalCtx?.dialogRef?.current);
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  // const [collect, setCollect] = useState([]);
  const isMobile = useIsMobile();
  const { mutate: unpublishEpisode } = useUnpublishEpisodeMutation();
  const { mutate: unlistEpisode } = useUnlistEpisodeMutation();
  const router = useRouter();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onOpenPopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const onClosePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  // useEffect(() => {
  //   setCollect(props.collection);
  // }, [props.collection]);

  useEffect(() => {
    setIsEnthused(props?.data?.isEnthused);
    if (props?.data?.enthusesCount || props?.data?.enthuseCount) {
      setEnthuseCount(props?.data?.enthusesCount || props?.data?.enthuseCount);
    }
  }, [props.data?.enthusesCount, props.data?.enthuseCount]);

  const shareButtonsModal = useDisclosure();
  const ellipsisButton = useDisclosure();
  const unpublishEpisodeModal = useDisclosure();
  const editEpisodeModal = useDisclosure();
  const unlistEpisodeModal = useDisclosure();
  const unlistNoteModal = useDisclosure();
  const creatorsPicksNoteModal = useDisclosure();
  const failedToUnlistModal = useDisclosure();

  const [unlistNoteText, setUnlistNoteText] = useState('');
  const [creatorsPicksNoteText, setCreatorsPicksNoteText] = useState('');

  function LinkHandle() {
    router.push(
      `/${props.data?.author.username}/${props.data?.contentID}/edit`
    );
    // if (props.data?.postType === 'MEDIA') {
    //   if (props.data?.classType === 'REPPL') {
    //     router.push({
    //       pathname: `/space/${props.data?.sourceID}/episode/${props.data?.contentID}/edit`,
    //       query: { type: 'mediaPost' },
    //     });
    //   } else {
    //     router.push({
    //       pathname: `/${props.data?.author.username}/${props.data?.contentID}/edit`,
    //       query: { type: 'mediaPost' },
    //     });
    //   }
    // } else if (props.data?.classType === 'REPPL') {
    //   router.push({
    //     pathname: `/space/${props?.data.sourceID}/episode/${props.data?.contentID}/edit`,
    //   });
    // } else {
    //   router.push({
    //     pathname: `/${props.data?.author.username}/${props.data?.contentID}/edit`,
    //   });
    // }
  }

  const labelModal = useDisclosure();

  const { mutateAsync: createPick } = useArticlePick();
  const { mutateAsync: createPin } = useArticlePin();

  const ICONS = [
    <button
      type="button"
      className={`m-auto flex justify-center items-center overflow-hidden relative w-auto h-6 font-medium ${
        !isMobile && !props?.isFixedTab && 'flex-col'
      } ${!isMobile && 'mr-8'}`}
      aria-label="enthuse"
      style={{
        borderRadius: '0',
        borderTop: isMobile || props?.isFixedTab ? 'none' : '1px solid #E6E8E9',
        borderBottom:
          isMobile || props?.isFixedTab ? 'none' : '1px solid #E6E8E9',
      }}
      onClick={() => {
        if (user && Object.keys(user).length === 0) {
          window.history.replaceState('', '', '?signin=true');
          if (props.articleType === 'STUDIO') {
            setLocalStorage(
              'routeLink',
              `/${props.data?.author.username}/${props.data?.contentID}`
            );
          } else {
            setLocalStorage('routeLink', `/asset/${props.contentID}`);
          }
          signInModal.onOpen();
        } else if (!props.disabled) {
          enthuse({
            enthuse: !isEnthused,
            objectId: props.contentID,
            objectType: props.articleType,
            ownerID: props.authorID,
          });
          setIsEnthused(!isEnthused);
          setEnthuseCount(isEnthused ? enthuseCount - 1 : enthuseCount + 1);
        }
      }}
    >
      <EnthuseIcon
        isEnthuse={isEnthused}
        fontSize={24}
        color={`${props.disabled ? 'gray.500' : 'gray.900'}`}
      />

      <span
        className={`text-sm font-medium text-gray-700 ${
          isMobile || props?.isFixedTab ? 'ml-1' : 'mt-1'
        }`}
      >
        {enthuseCount || ''}
      </span>
    </button>,

    <button
      type="button"
      className={`hidden flex justify-center items-center overflow-hidden relative w-6 h-6 ${
        !isMobile && 'mr-8'
      }`}
      aria-label="save"
      style={{
        borderRadius: '0',
        borderBottom:
          isMobile || props?.isFixedTab ? 'none' : '1px solid #E6E8E9',
      }}
      onClick={props.onSaveClick}
    >
      <SvgBookmarkNew
        fontSize={24}
        fill="none"
        color={isMobile ? 'gray.500' : 'gray.700'}
      />
    </button>,
  ];

  if (props.data?.publishStatus === 'PUBLISHED') {
    ICONS.push(
      <Popover
        placement="top"
        gutter={14}
        autoFocus={false}
        onClose={() => {
          onClosePopover();
          shareButtonsModal.onClose();
        }}
        onOpen={() => {
          onOpenPopover();
          shareButtonsModal.onOpen();
        }}
        isOpen={shareButtonsModal.isOpen}
      >
        <PopoverTrigger>
          <button
            type="button"
            className={`m-auto flex justify-center items-center overflow-hidden relative w-6 h-6 ${
              !isMobile && 'mr-8'
            }`}
            aria-label="save"
            style={{
              borderRadius: '0',
              borderBottom:
                isMobile || props?.isFixedTab ? 'none' : '1px solid #E6E8E9',
            }}
          >
            <SvgShareNew
              fill="none"
              fontSize={24}
              color={isMobile ? 'gray.500' : 'gray.700'}
            />
          </button>
        </PopoverTrigger>
        {shareButtonsModal.isOpen && (
          <PopoverContent bg="white">
            <PopoverArrow
              style={{
                width: '20px',
                height: '10px',
                boxShadow:
                  '0px 1px 0px #E6E8E9, 0px 3px 6px rgba(0, 0, 0, 0.08)',
              }}
            />
            <PopoverBody
              bg="white"
              padding="16px"
              borderRadius="8px"
              overflow="hidden"
              opacity="1"
            >
              <div className="flex flex-col justify-center items-center bg-white">
                <h1 className="text-center font-semibold text-sm font-medium mb-2 text-gray-900">
                  Share this folio
                </h1>
                <ShareButtons
                  facebook
                  twitter
                  copyLink
                  hashtags={props.data?.tags}
                />
              </div>
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    );
  }

  if (commentable) {
    ICONS.splice(
      1,
      0,
      <button
        type="button"
        className={`m-auto flex justify-center items-center overflow-hidden relative w-auto h-6 ${
          !isMobile && !props?.isFixedTab && 'flex-col'
        } ${!isMobile && 'mr-8'}`}
        aria-label="comment"
        style={{
          borderRadius: '0',
          borderBottom:
            isMobile || props?.isFixedTab ? 'none' : '1px solid #E6E8E9',
        }}
        onClick={() => {
          if (!props.disabled) {
            props.onCommentClick();
          }
        }}
      >
        <SvgCommentNew
          fontSize={24}
          fill="none"
          color={isMobile || props.disabled ? 'gray.500' : 'gray.700'}
        />

        <span
          className={`text-sm font-medium ${
            props.disabled ? 'text-gray-500' : 'text-gray-700'
          } ${isMobile || props?.isFixedTab ? 'ml-1' : 'mt-1'}`}
        >
          {props?.commentCount}
        </span>
      </button>
    );
  }

  if (
    !isMobile &&
    props?.data?.classType === 'STUDIO' &&
    props.data?.author?.userID === user.id
  ) {
    ICONS.push(
      <>
        <div className="ellipsis-menu mr-8">
          <Menu placement="top">
            <MenuButton
              as={IconButton}
              width="24px"
              height="24px"
              minWidth="unset"
              p={0}
              borderBottom={
                isMobile || props?.isFixedTab ? 'none' : '1px solid #E6E8E9'
              }
              borderRadius="0"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              position="relative"
              bg="transparent"
              colorScheme="whiteAlpha"
              icon={
                <SvgMenu2
                  color={isMobile ? 'gray.500' : 'gray.700'}
                  fill="none"
                  fontSize={24}
                />
              }
              onClick={() => {
                if (ellipsisButton.isOpen) {
                  ellipsisButton.onClose();
                } else {
                  ellipsisButton.onOpen();
                }
              }}
            />
            {ellipsisButton.isOpen && (
              <MenuList p={0} fontSize={14}>
                {props.data?.status !== 'NEVER' && (
                  <MenuItem
                    style={{ padding: '10px 1.5rem 0.3rem' }}
                    className="fontWeight-500"
                    onClick={() => {
                      router.push({
                        query: {
                          ...router.query,
                          submissionHistoryModal: 'open',
                          episode: props.data.contentID,
                        },
                      });
                    }}
                  >
                    Submission History
                  </MenuItem>
                )}
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={() => LinkHandle()}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={unpublishEpisodeModal.onOpen}
                >
                  Unpublish
                </MenuItem>
              </MenuList>
            )}
          </Menu>
        </div>
        <WarningModal
          {...unpublishEpisodeModal}
          isFooterCaption
          title="Unpublish your folio"
          text="Are you sure you want to unpublish this folio? Your folio will be not available for other users."
          onOk={async () => {
            await unpublishEpisode(props.contentID);
            router.back();
          }}
          okBtnText="Unpublish"
          cancelBtnText="Cancel"
          cancelBtnColor="#9B9FA4"
          cancelBtnScheme="#9B9FA4"
          footerCaption="* All comments and enthuses will remain intact."
        />
      </>
    );
  }

  if (
    !isMobile &&
    props?.data?.classType === 'REPPL' &&
    (props.data?.author?.userID === user.id ||
      props?.data.roleInReppl === 'STARTER' ||
      props?.data.roleInReppl === 'EDITOR')
  ) {
    ICONS.push(
      <>
        <div className="ellipsis-menu">
          <Menu placement="top">
            <MenuButton
              as={IconButton}
              width="24px"
              height="24px"
              p={0}
              borderBottom={
                isMobile || props?.isFixedTab ? 'none' : '1px solid #E6E8E9'
              }
              borderRadius="0"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              position="relative"
              bg="transparent"
              colorScheme="whiteAlpha"
              icon={
                <SvgMenu2
                  color={isMobile ? 'gray.500' : 'gray.700'}
                  fill="none"
                  fontSize={24}
                />
              }
            />
            <MenuList p={0} fontSize={14}>
              {props.data?.sourceID && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={() => {
                    router.push({
                      query: {
                        ...router.query,
                        submissionHistoryModal: 'open',
                        episode: props.data.contentID,
                      },
                    });
                  }}
                >
                  Submission History
                </MenuItem>
              )}
              {props.data?.author?.userID === user.id && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={() => LinkHandle()}
                >
                  Edit
                </MenuItem>
              )}
              {props?.data.roleInReppl === 'STARTER' && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={creatorsPicksNoteModal.onOpen}
                >
                  {props?.data.pinned
                    ? 'Remove from Starter’s Picks'
                    : 'Feature in Starter’s Picks'}
                </MenuItem>
              )}
              {(props?.data.roleInReppl === 'STARTER' ||
                props?.data.roleInReppl === 'EDITOR') && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={async () => {
                    await createPick({
                      dataset: [
                        {
                          contentID: props?.data.contentID,
                          pick: !props?.data.pick,
                          type: 'pick',
                        },
                      ],
                      projectID: props?.data.sourceID,
                      contentType: 'EPISODE',
                    });
                    if (props.refetch) {
                      await props.refetch();
                    }
                  }}
                >
                  {props?.data.pick ? 'Remove from Home' : 'Feature on Home'}
                </MenuItem>
              )}
              {(props?.data.roleInReppl === 'STARTER' ||
                props?.data.roleInReppl === 'EDITOR') && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={() => {
                    labelModal.onOpen();
                  }}
                >
                  Add to Space inspirations
                </MenuItem>
              )}
              {(props.data?.author?.userID === user.id ||
                props?.data.roleInReppl === 'STARTER') && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={() => {
                    if (
                      props?.data.pick &&
                      props?.data.roleInReppl === 'STARTER'
                    ) {
                      failedToUnlistModal.onOpen();
                    } else {
                      unlistEpisodeModal.onOpen();
                    }
                  }}
                >
                  Unlist
                </MenuItem>
              )}
              {props.data?.author?.userID === user.id && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={unpublishEpisodeModal.onOpen}
                >
                  Unpublish
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </div>
        {labelModal.isOpen && (
          <AddToLabelModal
            key={props?.data.contentID}
            {...labelModal}
            areaID={props?.data.sourceID}
            areaType={props?.data.classType}
            collectionItem={props?.data}
            onClose={() => {
              labelModal.onClose();
            }}
          />
        )}
        <WarningModal
          {...unpublishEpisodeModal}
          isFooterCaption
          title="Unpublish your folio"
          text="Are you sure you want to unpublish this folio? Your folio will be not available for other users."
          onOk={async () => {
            await unpublishEpisode(props.contentID);
            router.back();
          }}
          okBtnText="Unpublish"
          cancelBtnText="Cancel"
          cancelBtnColor="#9B9FA4"
          cancelBtnScheme="#9B9FA4"
          footerCaption="* All comments and enthuses will remain intact."
        />

        <WarningModal
          {...editEpisodeModal}
          isFooterCaption
          title="Edit a published folio"
          text="This folio is published in Space. If you edit the content, the reviewer will receive a notification for the change."
          onOk={() => {
            LinkHandle();
          }}
          okBtnText="Edit"
          cancelBtnText="No, keep it as it is"
          cancelBtnColor="#9B9FA4"
          cancelBtnScheme="#9B9FA4"
          footerCaption="* Please be reminded that the reviewer might unlist your folio if he/she thinks the change is inappropriate."
        />
        <WarningModal
          {...unlistEpisodeModal}
          title="Unlist your folio"
          text="Are you sure you want to remove your folio from the Space? Your folio will be moved to your studio, and all interaction records will be kept."
          onOk={() => {
            unlistNoteModal.onOpen();
          }}
          okBtnText="Unlist"
          cancelBtnText="Maybe later"
          cancelBtnColor="#9B9FA4"
          cancelBtnScheme="#9B9FA4"
        />
        <WarningModal
          {...failedToUnlistModal}
          onOk={failedToUnlistModal.onClose}
          title="Failed to unlist"
          text="This episode is featured in the home tab. Please unfeature it first to unlist from the Space."
          okBtnText="Got it"
          warningType="warning2"
          onOkBtnColor="#FFD557"
          noCancel
        />
        <NoteModal
          modal={unlistNoteModal}
          noteModalTitle="Unlist this episode"
          noteModalSubtitle="Let the creator know why you want to unlist this episode."
          okButtonText="Unlist"
          placeholder="This content of episode no longer fits the theme ofSpace..."
          updateNoteText={setUnlistNoteText}
          onOk={async () => {
            await unlistEpisode({
              episodeID: props.contentID,
              repplID: props.data?.sourceID,
              note: unlistNoteText,
            });
            router.back();
          }}
        />
        <NoteModal
          modal={creatorsPicksNoteModal}
          noteModalTitle="Feature this episode"
          noteModalSubtitle="Let the creator know why you want to feature this episode."
          okButtonText="Feature"
          placeholder="This episode is impressive, because..."
          updateNoteText={setCreatorsPicksNoteText}
          onOk={async () => {
            await createPin({
              dataset: [
                {
                  contentID: props?.data.contentID,
                  pinned: !props?.data.pinned,
                  pinnedMessage: creatorsPicksNoteText,
                  type: 'pin',
                },
              ],
              projectID: props?.data.sourceID,
              contentType: 'EPISODE',
            });
            if (props.refetch) {
              await props.refetch();
            }
          }}
        />
      </>
    );
  }

  if (props?.isFixedTab) {
    return (
      <div
        className={`flex justify-center bottom-0 right-0 ${
          props?.isFloater
            ? 'bg-white border rounded-full border-gray-200 m-auto'
            : 'bg-gray-50 border-t border-beaver-100'
        } md:bg-white`}
        style={{
          width: `${props?.isFloater ? 'max-content' : '100%'}`,
          paddingLeft: '2rem',
        }}
      >
        <Box className="flex justify-between items-center m-auto" h="56px">
          {(!isMobile &&
            props?.data?.classType === 'STUDIO' &&
            props.data?.author?.userID === user.id) ||
          (props?.data?.classType === 'REPPL' &&
            (props.data?.author?.userID === user.id ||
              props?.data.roleInReppl === 'STARTER' ||
              props?.data.roleInReppl === 'EDITOR')) ? (
            <>
              <div className="flex" style={{ height: 'max-content' }}>
                {ICONS.slice(0, ICONS.length - 1)}
              </div>
              <div className="flex" style={{ height: 'max-content' }}>
                {ICONS.slice(ICONS.length - 1)}
              </div>
            </>
          ) : (
            <div className="flex" style={{ height: 'max-content' }}>
              {Children.toArray(ICONS)}
            </div>
          )}
        </Box>
        <SignInModal
          modal={{
            ...signInModal,
            onClose: () => {
              router.push({ query: { ...router.query, page: '' } });
              signInModal.onClose();
            },
          }}
        />
      </div>
    );
  }

  return (
    <Fade in={!!user}>
      <ConditionalWrap
        condition={isDesktop}
        wrap={(c) => (
          <Box
            position="absolute"
            zIndex={50}
            left={props?.left || '-62px'}
            height="100%"
            borderRight={{
              base: 'none',
              lg: '1px solid #E6E8E9',
            }}
          >
            {c}
          </Box>
        )}
      >
        <Stack
          direction="column"
          spacing={0}
          top={{
            base: 'unset',
            lg: '100px',
          }}
          height="max-content"
          bottom={{
            base: '16px',
            lg: 'unset',
          }}
          position={{
            base: 'fixed',
            lg: 'sticky',
          }}
          {...(!isDesktop && {
            direction: 'row',
            boxShadow: '0px -1px 3px rgba(0, 0, 0, 0.06)',
            borderRadius: '0',
            height: 'auto',
            bg: '$mode.bg',
            zIndex: 99,
            paddingY: '16px',
            paddingX: '40px',
            width: 'fit-content',
            willChange: 'transform',
            transition: 'transform 0.2s ease-in',
            transform: 'translateX(-50%)',
            left: '50%',
            style: {
              transform: 'translateX(-50%)',
              width: '100%',
              bottom: 0,
              justifyContent: 'space-between',
              border: '1px solid #E6E8E9',
            },
          })}
        >
          {Children.toArray(ICONS)}
        </Stack>
      </ConditionalWrap>
      <SignInModal
        modal={{
          ...signInModal,
          onClose: () => {
            router.push({ query: { ...router.query, page: '' } });
            signInModal.onClose();
          },
        }}
      />
    </Fade>
  );
}
