import { BlockMap } from '@hexx/renderer';
import { BlockMap as EditorMap } from '@1tmio/editor-renderer';
import Image from 'next/image';
// import { ALLOW_IMAGE_HOSTNAME } from 'src/constants/allow-image-hostname';
import SvgHand from 'src/components/icons/hand';
import { EmbedLinkRenderer } from './blocks/embed-link/embed-link-renderer';
import {
  AspectRatioImage,
  Asset,
  GalleryRenderer,
} from './blocks/gallery/gallery-renderer';

export const PreferNextGalleryImage = ({ asset }: { asset: Asset }) => {
  let canUseNextImage = false;
  try {
    // const { hostname } = new URL(asset.url);
    // if (ALLOW_IMAGE_HOSTNAME.includes(hostname)) {
    //   if ('width' in asset && 'height' in asset) {
    //     canUseNextImage = true;
    //   }
    // }
    canUseNextImage = true;
  } catch (error) {
    //
  }

  if (canUseNextImage) {
    return (
      <div
        style={{
          position: 'relative',
          width: asset.displayWidth,
          height: '100%',
        }}
      >
        <Image
          src={asset.url}
          layout="responsive"
          width={asset.width}
          height={asset.height}
        />
      </div>
    );
  }

  return <AspectRatioImage data={asset} />;
};

export const WarningRender = ({ args }: { args: any }) => {
  function titleMarkup() {
    return { __html: args.data.title };
  }
  function messageMarkup() {
    return { __html: args.data.message };
  }
  return (
    <>
      <div className="cdx-warning px-5 border border-gray-200 rounded">
        <div
          style={{ height: 'auto', margin: 'auto' }}
          className="relative top-0 bottom-0 py-2"
        >
          <div className="flex">
            <SvgHand
              style={{ maxWidth: '18px', width: '18px' }}
              className="my-auto"
            />
            <div
              className="cdx-warning__title font-semibold mx-2"
              dangerouslySetInnerHTML={titleMarkup()}
            />
          </div>
          <div className="flex">
            <div style={{ maxWidth: '18px', width: '18px' }} />
            <div
              className="cdx-warning__message mx-2"
              dangerouslySetInnerHTML={messageMarkup()}
            />
          </div>
        </div>
      </div>
      <style jsx global>{`
        .cdx-warning {
          position: relative;
          background-color: #0000000f;
          min-height: 96px;
        }

        .cdx-warning::before {
          background: unset !important;
        }

        .cdx-warning__title,
        .cdx-warning__message {
          margin-top: auto !important;
          margin-bottom: auto !important;
          min-height: unset !important;
        }
      `}</style>
    </>
  );
};

export const CodeRenderer = ({ args }: { args: any }) => {
  return (
    <>
      <textarea className="ce-code__textarea cdx-input">
        {args.data.code}
      </textarea>
      <style jsx global>{`
        .ce-code__textarea {
          min-height: 200px;
          font-family: Menlo, Monaco, Consolas, Courier New, monospace;
          color: #41314e;
          line-height: 1.6em;
          font-size: 12px;
          background: #f8f7fa;
          border: 1px solid #f1f1f4;
          box-shadow: none;
          white-space: pre;
          word-wrap: normal;
          overflow-x: auto;
          resize: vertical;
        }
        .cdx-input {
          border-radius: 3px;
          padding: 10px 12px;
          outline: none;
          width: 100%;
          box-sizing: border-box;
        }
      `}</style>
    </>
  );
};

export const rendererBlockMaps = {
  ...BlockMap,
  header: EditorMap.header,
  gallery: (args) =>
    GalleryRenderer({
      renderImage: (asset) => <PreferNextGalleryImage asset={asset} />,
      ...args,
    }),
  embedLink: EmbedLinkRenderer,
  code: (args) => <CodeRenderer args={args} />,
  warning: (args) => <WarningRender args={args} />,
};
