/**
 * Internal dependencies
 */
import TwoLayer from 'src/components/common/checkList/two-layer';

function SkillFilterContent({ selectList, onFilterChange, list }) {
  return (
    <TwoLayer
      list={list}
      selectList={selectList}
      action={(skillId, groupId) => {
        onFilterChange({
          type: 'SKILLS',
          value: skillId,
          groupId,
        });
      }}
    />
  );
}

export default SkillFilterContent;
